import React, { useState, useEffect } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from './../../dashboard/mainContent/fundWallet/style';
import {
  ItemBox,
  SplitAccountSection,
  ImageStack,
  SplitUsersWrap,
  SplitUsersPopup,
  SplitAvatar,
  SingleInfoFlex,
} from 'app/components/split/style';
import { FlexedApart } from 'app/components/merch/singleMerch/style';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { MoonLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import { Extra } from 'app/components/transactions/style';
import CommaNumber from 'comma-number';
import { getRequest } from 'utils/request';

const AddSplitModal = ({ closeFunc, setSelected, selected }) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
  const [splits, setSplits] = useState<any>('');
  const [splitsMutable, setSplitsMutable] = useState<any>([]);
  const [hoveredItem, setHoveredItem] = useState(-1);

  const handleSetIndex = (index: number) => {
    setHoveredItem(index);
  };

  const handleRemoveIndex = () => {
    setHoveredItem(-1);
  };

  // Fetch Each Collaborator
  useEffect(() => {
    getRequest(`splits/collaborators?sortBy=createdAt:desc&paginate=false`)
      .then(res => {
        setAllCollaborators(res);
      })
      .catch(err => {});
  }, [Authorization]);

  const userBankAccount = JSON.parse(
    localStorage.getItem('accountInfo') || '{}',
  );

  const [finalCollaborators, setFinalCollaborators] = useState<any>([]);
  const [allCollaborators, setAllCollaborators] = useState<any>('');

  const fetchCollabDetails = item => {
    let mockData: Array<object> = [];
    if (allCollaborators && item && item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        if (item[i].collaboratorId === loggedUser.user.id) {
          mockData.unshift({
            name: userBankAccount.accountInfo.accountName,
            email: loggedUser.user.email,
            id: userBankAccount.user,
            accountInfo: {
              accountName: userBankAccount.accountInfo.accountName,
              accountNumber: userBankAccount.accountInfo.accountNumber,
              bankName: userBankAccount.accountInfo.bankName,
            },
            percentage: item[0].percentage,
          });
        }

        for (let k = 0; k < allCollaborators.length; k++) {
          if (allCollaborators[k].id === item[i].collaboratorId) {
            mockData.push({
              name: allCollaborators[k].name,
              email: allCollaborators[k].email,
              percentage: item[i].percentage,
            });
          }
        }
      }
      setFinalCollaborators(mockData);
    }
  };

  useEffect(() => {
    getRequest(`splits?sortBy=createdAt:desc&paginate=false&type=owner`)
      .then(res => {
        setSplits(res);
        setSplitsMutable(res);
      })
      .catch(err => {});
  }, []);

  const [accNoCopied, setAccNoCopied] = useState(false);
  function handleAccCopy(text) {
    navigator.clipboard.writeText(text);
    setAccNoCopied(!accNoCopied);
    setTimeout(() => {
      setAccNoCopied(false);
    }, 3000);
  }

  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={e => closeFunc(e)}>
            <Icon.X />
          </CloseBtn>
          <Header
            style={{
              textAlign: 'left',
              fontWeight: '800',
              color: '#323338',
              margin: 0,
            }}
          >
            <Icon.Zap size={15} /> Add Split
          </Header>
          <Extra
            style={{
              fontSize: '14px',
              color: '#191919',
              margin: '20px 0 2rem 0',
            }}
          >
            Select a split account to pay this invoice payment into. Go to
          </Extra>
          <form onSubmit={e => closeFunc(e)}>
            <div
              style={{
                height: '23rem',
                overflowY: 'auto',
              }}
            >
              {splits !== '' ? (
                <>
                  {splits.length !== 0 && splitsMutable.length !== 0 ? (
                    <>
                      {splitsMutable.map((item, index) => (
                        <ItemBox
                          onClick={() => {
                            setSelected(item);
                            setActiveIndex(index);
                          }}
                          key={index}
                          style={
                            activeIndex === index
                              ? {
                                  margin: '0 0 1rem 0',
                                  border: '1px solid #c82b38',
                                }
                              : {
                                  margin: '0 0 1rem 0',
                                  border: '1px solid #dadada',
                                }
                          }
                        >
                          <div>
                            <FlexedApart>
                              <h3>{item.name}</h3>
                            </FlexedApart>
                            <SplitAccountSection>
                              <div>
                                <p
                                  style={{
                                    fontSize: '12px',
                                    margin: '0 0 0.5rem 0',
                                  }}
                                >
                                  {item.accountInfo.accountName}
                                </p>
                                <h2>{item.accountInfo.accountNumber}</h2>
                                <p>{item.accountInfo.bankName}</p>
                              </div>
                              <Icon.Copy
                                size={20}
                                color="#757575"
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleAccCopy(item.accountInfo.accountNumber);
                                }}
                              />
                            </SplitAccountSection>
                          </div>
                          <FlexedApart
                            style={{
                              justifyContent: 'space-between',
                            }}
                          >
                            <ImageStack
                              style={{
                                width: '40%',
                              }}
                              onMouseOver={() => {
                                handleSetIndex(index);
                                fetchCollabDetails(item.collaborators);
                              }}
                              onMouseLeave={() => handleRemoveIndex()}
                            >
                              <p>
                                {item.collaborators
                                  ? item.collaborators.length
                                  : ''}{' '}
                                {item.collaborators &&
                                item.collaborators.length > 1
                                  ? 'collaborators'
                                  : 'collaborator'}
                              </p>
                            </ImageStack>
                            <SingleInfoFlex
                              style={{
                                width: '40%',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <div>
                                {item.splitType === 'time' ? (
                                  <p>
                                    <span>
                                      <Icon.Zap
                                        size={15}
                                        style={{
                                          position: 'relative',
                                          top: '0.05rem',
                                        }}
                                      />
                                      {item.frequency}
                                    </span>
                                  </p>
                                ) : null}
                                {item.splitType === 'limit' ? (
                                  <p>
                                    <span>
                                      <Icon.Zap
                                        size={15}
                                        style={{
                                          position: 'relative',
                                          top: '0.05rem',
                                        }}
                                      />
                                      up to {CommaNumber(item.limitValue)}
                                    </span>
                                  </p>
                                ) : null}
                                {item.splitType === 'instantly' ? (
                                  <p>
                                    <span>
                                      <Icon.Zap
                                        size={15}
                                        style={{
                                          position: 'relative',
                                          top: '0.05rem',
                                        }}
                                      />
                                      {item.splitType}
                                    </span>
                                  </p>
                                ) : null}
                              </div>
                            </SingleInfoFlex>
                          </FlexedApart>
                          {hoveredItem === index ? (
                            <>
                              {finalCollaborators &&
                              finalCollaborators.length > 0 ? (
                                <SplitUsersPopup>
                                  <h3>Split breakdown</h3>
                                  {finalCollaborators.map((item, index) => (
                                    <SplitUsersWrap key={index}>
                                      <SplitAvatar>
                                        {item.name ? item.name[0] : ''}
                                      </SplitAvatar>
                                      <div>
                                        <h3>{item.name}</h3>
                                        <p>{item.email}</p>
                                      </div>
                                      <p>{item.percentage}%</p>
                                    </SplitUsersWrap>
                                  ))}
                                </SplitUsersPopup>
                              ) : null}
                            </>
                          ) : null}
                        </ItemBox>
                      ))}
                    </>
                  ) : null}
                </>
              ) : (
                <CenteredAnimeWrap>
                  <MoonLoader size={20} color="#969696" />
                </CenteredAnimeWrap>
              )}
            </div>
            <BigBtn>
              <button
                disabled={!selected && Object.keys.length === 0 ? true : false}
                style={
                  !selected && Object.keys.length === 0
                    ? {
                        cursor: 'not-allowed',
                      }
                    : {
                        cursor: 'pointer',
                      }
                }
                type="submit"
              >
                Add Split
              </button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default AddSplitModal;
