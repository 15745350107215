import {
  configureStore,
  getDefaultMiddleware,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { createReducer } from './reducers';
import { firestoreReducer } from 'redux-firestore';
import benefitsReducer from './benefitsReducer';
import selectedBenefitReducer from './selectedBenefitReducer';
import masterCardReducer from './masterCardReducer';
import visacardReducer from './visacardReducer';

export interface RootState {
  benefits: ReturnType<typeof benefitsReducer>;
  selectedBenefit: ReturnType<typeof selectedBenefitReducer>;
  card: ReturnType<typeof masterCardReducer>;
  visa: ReturnType<typeof visacardReducer>;
}

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const rootReducer: any = {
    benefits: benefitsReducer,
    selectedBenefit: selectedBenefitReducer,
    card: masterCardReducer,
    visa: visacardReducer,
    firestore: firestoreReducer,
  };

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(rootReducer),
    middleware: [...getDefaultMiddleware(), ...middlewares],
    devTools:
      process.env.NODE_ENV !== 'production' ||
      process.env.PUBLIC_URL.length > 0,
    enhancers,
  });

  return store;
}
