import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../invoice/mainInvoice/style';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from './../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { InputWrap, BigBtn } from './../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import { ClipLoader } from 'react-spinners';
import EnterPin from './../dashboard/mainContent/fundWallet/enterPin';
import uuid from 'react-uuid';
import CommaNumber from 'comma-number';
import { numOnly } from 'utils/numOnly';
import {
  CollabSuggestWrap,
  SplitUsersWrap,
  SplitAvatar,
} from 'app/components/split/style';
import { useNavigate } from 'react-router';
import { NumericFormat } from 'react-number-format';
import { roundTo } from 'utils/roundFloat';
import { getRequest } from 'utils/request';

const SplitTransferComp = () => {
  const navigate = useNavigate();

  const width = window.innerWidth;
  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [selectedSplit, setSelectedSplit] = useState<any>({});
  const [balance, setBalance] = useState<any>('');
  const [amount, setAmount] = useState<any>(0);
  const [payloadAmount, setPayloadAmount] = useState<number>(amount);
  const [clientErr, setClientErr] = useState('');
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  // Get Balance
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/account-info?`, {
        headers: { Authorization },
      })
      .then(resp => {
        // setUserAccount(resp.data)
        setBalance(resp.data.balance.naira);
        localStorage.setItem('accountInfo', JSON.stringify(resp.data));
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [Authorization, navigate]);

  // Collect Information from Inputs
  const handleInput = (e, setParam, amount: boolean = false) => {
    if (amount) {
      let value = e.target.value;
      if (value.toString().indexOf('0') === 0) value = Number(value).toString();
      setParam(value);
    } else setParam(e.target.value);
  };

  // Reformat Amount
  useEffect(() => {
    if (amount) {
      setPayloadAmount(Number(amount ? amount.replace(',', '') : amount));
    }
  }, [amount]);

  // Payment Parameters
  const paymentParams: any = {
    splitId: selectedSplit.id,
    amount: `${payloadAmount}`,
    idempotentKey: uuid(),
  };

  // Enter Pin for transaction
  const [pin, setPin] = useState<any>('');

  // Set Pin
  const handlePinChange = pin => {
    setPin(pin);
  };

  const [pinModal, setPinModal] = useState(false);
  const handleClosePinModal = () => {
    setPinModal(false);
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [result, setResult] = useState<boolean | string>(false);

  const closeMessage = () => {
    setResult(false);
  };

  const [finalOnProcess, setFinalOnProcess] = useState(false);
  const handleFinalPay = () => {
    setFinalOnProcess(true);
    if (pin && pin.length === 4) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/auth/confirm-pin`,
          {
            pin,
            userId: loggedUser.user.id,
          },
          headers,
        )
        .then(res => {
          setPin('');
          if (res.data.status) {
            axios
              .post(
                `${process.env.REACT_APP_BASE_ENDPOINT}/payments/split-transfer`,
                paymentParams,
                headers,
              )
              .then(res => {
                setFinalOnProcess(false);
                setSuccess(true);
                localStorage.removeItem('selectedFreqBen');
                setTimeout(() => {
                  setSuccess(false);
                  setPinModal(false);
                  navigate('/');
                }, 5000);
              })
              .catch(err => {
                setFinalOnProcess(false);
                setError(err.response.data.message);
                setTimeout(() => {
                  setError('');
                }, 4000);
              });
          } else {
            setErrorMessage(
              "We couldn't confirm your transaction pin at the moment",
            );
            setFinalOnProcess(false);
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
          }
        })
        .catch(err => {
          setFinalOnProcess(false);
          setErrorMessage(err.response.data.message);
          setPin('');
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('Transaction pin is not correct!');
      setFinalOnProcess(false);
      setPin('');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  const handlePayment = async () => {
    setOnProcess(true);
    if (payloadAmount > 0) {
      if (payloadAmount && payloadAmount <= balance) {
        if (selectedSplit.id) {
          setPinModal(true);
          setOnProcess(false);
        } else {
          setClientErr('Please select a split!');
          setOnProcess(false);
          setTimeout(() => {
            setClientErr('');
          }, 2000);
        }
      } else {
        setOnProcess(false);
        setClientErr(
          'You do not have sufficient balance to make this transaction',
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else {
      setOnProcess(false);
      setClientErr(`Amount can't be empty and must be greater than 0`);
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  useEffect(() => {
    if (pin.length === 4) handleFinalPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const closeModal = () => {
    setError('');
  };

  const closeSuccess = () => {
    setSuccess(false);
    setPinModal(false);
    navigate('/');
  };

  const [splits, setSplits] = useState<any>([]);
  const [splitsModified, setSplitsModified] = useState<any>([]);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const handleInputFocus = () => {
    setIsInputFocus(true);
  };

  // Get Splits
  useEffect(() => {
    getRequest(`splits?&paginate=false&type=owner`)
      .then(res => {
        setSplits(res);
        setSplitsModified(res.reverse());
      })
      .catch(err => {});
  }, []);

  const handleSearch: any = e => {
    let new_item: any = [];
    if (splits !== '' && splits.length !== 0) {
      for (let i = 0; i < splits.length; i++) {
        if (
          splits[i].name.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          new_item.push(splits[i]);
        } else if (e.target.value === '') {
          setSplitsModified(splits);
        }
      }
      setSplitsModified(new_item);
    }
  };

  return (
    <>
      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
        onClick={() => setIsInputFocus(false)}
      >
        <FragmentHeader showBack={true} pageName="Send Money (Split)" />
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              top="-2rem"
              mobileTop="-2rem"
              style={{ margin: '0 auto' }}
            >
              <div>
                <div className="hide-amount-input">
                  <NumericFormat
                    value={amount}
                    thousandSeparator={true}
                    onValueChange={e => setAmount(e.formattedValue)}
                  />
                </div>
                <InputWrap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label>Amount(&#8358;)</label>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        letterSpacing: '-0.02em',
                        color: '#ABABAB',
                      }}
                    >
                      Balance: &#8358;{CommaNumber(roundTo(balance, 2))}
                    </p>
                  </div>
                  <input
                    type="text"
                    placeholder="Amount"
                    value={amount}
                    onKeyPress={e => numOnly(e)}
                    onChange={e => handleInput(e, setAmount, true)}
                  />
                </InputWrap>
                <div style={{ margin: '0 0 2rem 0', width: '100%' }}>
                  <h3
                    style={{
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#474747',
                      margin: '0rem 0 0 0',
                      position: 'relative',
                      bottom: '-1rem',
                    }}
                  >
                    Select Split
                  </h3>
                  <CollabSuggestWrap
                    style={{
                      margin: '1rem 0 0 0',
                      zIndex: '100',
                      width: width > 728 ? '20rem' : '100%',
                    }}
                  >
                    <input
                      onClick={e => {
                        e.stopPropagation();
                        handleInputFocus();
                      }}
                      style={{
                        width: '100%',
                        border: '0.5px solid #dadada',
                        padding: '0.5rem 5%',
                      }}
                      onChange={e => handleSearch(e)}
                      placeholder="Search existing splits"
                    />
                    {isInputFocus ? (
                      <div>
                        {splitsModified && splitsModified.length > 0 ? (
                          splitsModified.map((item, index) => (
                            <SplitUsersWrap
                              alignVertical="flex-start"
                              key={index}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setSelectedSplit(item);
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%',
                                  }}
                                >
                                  <SplitAvatar>
                                    <img
                                      src={
                                        item.avatar
                                          ? item.avatar
                                          : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                                      }
                                      alt={item.name}
                                    />
                                  </SplitAvatar>
                                  <div
                                    style={{
                                      width: '80%',
                                    }}
                                  >
                                    <h3
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        display: 'flex',
                                      }}
                                    >
                                      {item.name ? `${item.name}` : ''}
                                      <div
                                        style={{
                                          width: '5px',
                                          height: '5px',
                                          background: '#ababab',
                                          borderRadius: '10rem',
                                        }}
                                      ></div>
                                      {item.splitType === 'time' ? (
                                        <span>{item.frequency}</span>
                                      ) : (
                                        ''
                                      )}
                                      {item.splitType === 'limit' ? (
                                        <span>
                                          up to {CommaNumber(item.limitValue)}
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      {item.splitType === 'approval' ? (
                                        <span>On Approval</span>
                                      ) : (
                                        ''
                                      )}
                                      {item.splitType === 'instantly' ? (
                                        <span>{'Instantly'}</span>
                                      ) : (
                                        ''
                                      )}
                                    </h3>
                                    <p>
                                      <span>
                                        {item.collaborators
                                          ? item.collaborators.length
                                          : ''}{' '}
                                        {item.collaborators &&
                                        item.collaborators.length > 1
                                          ? 'recipients'
                                          : 'recipient'}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </SplitUsersWrap>
                          ))
                        ) : (
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '14px',
                              color: '#757575',
                              margin: '1rem 0 0 0',
                            }}
                          >
                            No Option.
                          </p>
                        )}
                      </div>
                    ) : null}
                  </CollabSuggestWrap>
                  {selectedSplit && Object.keys(selectedSplit).length > 0 ? (
                    <SplitUsersWrap
                      alignVertical="flex-start"
                      style={{
                        cursor: 'pointer',
                        margin: '0.5rem 0 0 0',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%',
                          }}
                        >
                          <SplitAvatar>
                            <img
                              src={
                                selectedSplit.avatar
                                  ? selectedSplit.avatar
                                  : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                              }
                              alt={selectedSplit.name}
                            />
                          </SplitAvatar>
                          <div
                            style={{
                              width: '80%',
                            }}
                          >
                            <h3
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                display: 'flex',
                              }}
                            >
                              {selectedSplit.name
                                ? `${selectedSplit.name}`
                                : ''}
                              <div
                                style={{
                                  width: '5px',
                                  height: '5px',
                                  background: '#ababab',
                                  borderRadius: '10rem',
                                }}
                              ></div>
                              {selectedSplit.splitType === 'time' ? (
                                <span>{selectedSplit.frequency}</span>
                              ) : (
                                ''
                              )}
                              {selectedSplit.splitType === 'limit' ? (
                                <span>
                                  up to {CommaNumber(selectedSplit.limitValue)}
                                </span>
                              ) : (
                                ''
                              )}
                              {selectedSplit.splitType === 'instantly' ? (
                                <span>{'Instantly'}</span>
                              ) : (
                                ''
                              )}
                            </h3>
                            <p>
                              <span>
                                {selectedSplit.collaborators
                                  ? selectedSplit.collaborators.length
                                  : ''}{' '}
                                {selectedSplit.collaborators &&
                                selectedSplit.collaborators.length > 1
                                  ? 'recipients'
                                  : 'recipient'}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </SplitUsersWrap>
                  ) : null}
                </div>
              </div>
              <p style={{ width: '20rem', fontSize: '0.8rem', color: 'red' }}>
                {clientErr}
              </p>
              <BigBtn>
                <button
                  onClick={e => handlePayment()}
                  disabled={onProcess ? true : false}
                >
                  {onProcess ? <ClipLoader color="#fff" /> : 'Send Money'}
                </button>
              </BigBtn>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>
      </MainWrapper>

      {pinModal ? (
        <EnterPin
          closeFunc={handleClosePinModal}
          prevFunc={handleClosePinModal}
          propPin={pin}
          errorMessage={errorMessage}
          handlePinChange={handlePinChange}
          handleFinalPay={handleFinalPay}
          onProcess={finalOnProcess}
          bank={'PAGA'}
          accountName={selectedSplit.accountInfo.accountName}
          accountNo={selectedSplit.accountInfo.accountNumber}
          amount={amount}
          mode="split"
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="Transaction Unsuccessful!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeSuccess}
          img="/success.png"
          message="Transaction successful"
          miniMessage={'Funds are on the way to the recipient '}
        />
      ) : null}

      {result === '' ? (
        <Alert
          closeFunc={closeMessage}
          img="/failed.png"
          message="Changes not successful!"
          miniMessage="Encountered an error while creating your transaction PIN"
        />
      ) : null}
    </>
  );
};

export default SplitTransferComp;
