import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
export async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const respJson = await response.json();
  if (respJson.message === 'You are not logged in. Please authenticate') {
    localStorage.clear();
    window.location.href = '/login';
  }
  const error = new ResponseError(response);
  error.response = response;
  error.message = respJson.message;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit,
): Promise<{} | { err: ResponseError }> {
  const fetchResponse = await fetch(url, options);
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}

/*
 * GET requests returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function getRequest(endpoint: string): Promise<any> {
  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
  const headers = { Authorization };
  const fetchResponse = await fetch(
    `${process.env.REACT_APP_BASE_ENDPOINT}/${endpoint}`,
    { headers },
  );
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}

export async function createRequest(
  endpoint: string,
  data: {},
  noAuth: boolean = false,
): Promise<any> {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (!noAuth) {
    let loggedUser: any = JSON.parse(
      localStorage.getItem('logged_user') || '{}',
    );
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
    // Handle user data place holders
    const toRemove: any = [];
    Object.keys(data).forEach(key => {
      if (
        typeof data[key] === 'string' &&
        data[key].indexOf('__active.') !== -1
      ) {
        data[key] = loggedUser[data[key].split('__active.')[1]];
      }
      if (!data[key].toString()) {
        toRemove.push(key);
      }
    });
    if (toRemove.length > 0) toRemove.forEach(key => delete data[key]);
    const hmacDigest = Base64.stringify(
      hmacSHA512(
        JSON.stringify(data),
        process.env.REACT_APP_SIGNATURE || 'iuhk',
      ),
    );
    headers['Authorization'] = Authorization;
    headers['x-payload-signature'] = hmacDigest;
  }

  const fetchResponse = await fetch(
    `${process.env.REACT_APP_BASE_ENDPOINT}/${endpoint}`,
    { headers, method: 'POST', body: JSON.stringify(data) },
  );
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}

export async function updateRequest(
  endpoint: string,
  data: {},
  noAuth: boolean = false,
): Promise<any> {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (!noAuth) {
    let loggedUser: any = JSON.parse(
      localStorage.getItem('logged_user') || '{}',
    );
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
    // Handle user data place holders
    const toRemove: any = [];
    Object.keys(data).forEach(key => {
      if (
        typeof data[key] === 'string' &&
        data[key].indexOf('__active.') !== -1
      ) {
        data[key] = loggedUser[data[key].split('__active.')[1]];
      }
      if (!data[key].toString()) {
        toRemove.push(key);
      }
    });
    if (toRemove.length > 0) toRemove.forEach(key => delete data[key]);
    const hmacDigest = Base64.stringify(
      hmacSHA512(
        JSON.stringify(data),
        process.env.REACT_APP_SIGNATURE || 'iuhk',
      ),
    );
    headers['Authorization'] = Authorization;
    headers['x-payload-signature'] = hmacDigest;
  }

  const fetchResponse = await fetch(
    `${process.env.REACT_APP_BASE_ENDPOINT}/${endpoint}`,
    { headers, method: 'PATCH', body: JSON.stringify(data) },
  );
  const response = await checkStatus(fetchResponse);
  return parseJSON(response);
}
