import React, { useState, useEffect } from 'react';
import {
  ModalArea,
  ModalChild,
  InputWrap,
  BigBtn,
  CloseBtn,
  SubHeader,
  SubHeaderChild,
} from './style';
import Select from 'react-select';
import axios from 'axios';
import Alert from './alert';
import { ClipLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import EnterPin from './enterPin';
import { getUserData } from 'utils/firebaseUser';
import uuid from 'react-uuid';
import { updateBeneficiaries } from 'utils/firebaseUser';
import CommaNumber from 'comma-number';
import { numOnly } from 'utils/numOnly';
import { CustomStyles } from 'utils/selectCustomStyle';

const TransferFinal = ({ closeFunc, balance, openSide }) => {
  const [amount, setAmount] = useState<any>(0);
  const [selectedBank, setSelectedBank] = useState<string>('');
  const [selectedBankId, setSelectedBankId] = useState<string>('');
  const [purpose, setPurpose] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [clientErr, setClientErr] = useState('');
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  const [banks, setBanks] = useState<any>([]);
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/bank-list?`, {
        headers: { Authorization: `Bearer ${loggedUser.tokens.access.token}` },
      })
      .then(res => {
        setBanks(res.data);
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
        }
      });
  }, [loggedUser]);

  // Collect Information from Inputs
  const handleInput = (e, setParam, amount: boolean = false) => {
    if (amount) {
      let value = e.target.value;
      if (value.toString().indexOf('0') === 0) value = Number(value).toString();
      setParam(value);
    } else setParam(e.target.value);
  };

  // Collect Frequent Beneficiary if exists
  const freqData: any = JSON.parse(
    localStorage.getItem('selectedFreqBen') || '{}',
  );

  useEffect(() => {
    if (Object.keys(freqData).length > 0) {
      setAccountName(freqData.fullName);
      setSelectedBankId(freqData.bankId);
      setSelectedBank(freqData.bankName);
      setAccountNumber(freqData.accountNumber);
    }
  }, [freqData]);

  // Payment Parameters
  const paymentParams: any = {
    bankId: selectedBankId,
    accountNumber: accountNumber,
    purpose: purpose,
    amount: amount,
    idempotentKey: uuid(),
  };

  // Enter Pin for transaction

  const [pin, setPin] = useState<any>('');

  // Set Pin
  const handlePinChange = pin => {
    setPin(pin);
  };

  const [pinModal, setPinModal] = useState(false);

  const handleClosePinModal = () => {
    setPinModal(false);
  };

  // Get User Data from Database
  const [userCloudInfo, setUserCloudInfo] = useState<any>({});
  // Read and Write User to Beneficiaries Function
  const [addToFB, setAddToFB] = useState<boolean>(false);
  const [beneficiaryData, setBeneficiaryData] = useState<any>([]);

  const handleAddBeneficiaries = () => {
    if (addToFB) {
      updateBeneficiaries(
        loggedUser.user.id,
        accountName,
        accountNumber,
        selectedBankId,
        selectedBank,
      );
    }
  };

  useEffect(() => {
    getUserData(loggedUser.user.id, setUserCloudInfo);
  }, [loggedUser]);

  // Check accountNUmber in Beneficiary Data
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    if (accountNumber.length === 10) {
      if (beneficiaryData && beneficiaryData.length > 0) {
        for (let i = 0; i < beneficiaryData.length; i++) {
          if (beneficiaryData[i].accountNumber === accountNumber) {
            setIsExist(true);
          } else {
            setIsExist(false);
          }
        }
      }
    }
  }, [accountNumber, beneficiaryData]);

  useEffect(() => {
    if (userCloudInfo) {
      setBeneficiaryData(userCloudInfo.frequent_beneficiaries);
    }
  }, [userCloudInfo]);

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [result, setResult] = useState<boolean | string>(false);

  const closeMessage = () => {
    setResult(false);
  };

  const [finalOnProcess, setFinalOnProcess] = useState(false);
  const handleFinalPay = () => {
    setFinalOnProcess(true);
    handleAddBeneficiaries();
    if (pin && pin.length === 4) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/auth/confirm-pin`,
          {
            pin,
            userId: loggedUser.user.id,
          },
          headers,
        )
        .then(res => {
          if (res.data.status) {
            setPin('');
            axios
              .post(
                `${process.env.REACT_APP_BASE_ENDPOINT}/payments/withdraw`,
                paymentParams,
                headers,
              )
              .then(res => {
                setFinalOnProcess(false);
                setSuccess(true);
                localStorage.removeItem('selectedFreqBen');
                setTimeout(() => {
                  setSuccess(false);
                  setPinModal(false);
                  closeFunc();
                }, 5000);
              })
              .catch(err => {
                setFinalOnProcess(false);
                setError(err.response.data.message);
                setTimeout(() => {
                  setError('');
                }, 4000);
              });
          } else {
            setFinalOnProcess(false);
            setErrorMessage(
              "We couldn't confirm transaction pin at the moment",
            );
            setPin('');
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
          }
        })
        .catch(err => {
          setFinalOnProcess(false);
          setErrorMessage(err.response.data.message);
          setPin('');
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('Transaction pin is not correct!');
      setFinalOnProcess(false);
      setPin('');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  const handlePayment = async () => {
    setOnProcess(true);
    if (amount > 0) {
      if (amount && amount <= balance) {
        if (selectedBankId !== '' && accountNumber !== '' && purpose !== '') {
          if (accountName !== '') {
            setPinModal(true);
            setOnProcess(false);
          } else {
            setClientErr(`Account name can't be empty!`);
            setOnProcess(false);
            setTimeout(() => {
              setClientErr('');
            }, 2000);
          }
        } else {
          setClientErr('Please fill all input fields!');
          setOnProcess(false);
          setTimeout(() => {
            setClientErr('');
          }, 2000);
        }
      } else {
        setOnProcess(false);
        setClientErr(
          'You do not have sufficient balance to make this transaction',
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else {
      setOnProcess(false);
      setClientErr(`Amount can't be empty and must be greater than 0`);
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  useEffect(() => {
    if (pin.length === 4) handleFinalPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const closeModal = () => {
    setError('');
  };

  const closeSuccess = () => {
    setSuccess(false);
    localStorage.removeItem('selectedFreqBen');
    setPinModal(false);
    closeFunc();
  };

  // Account Validation
  const ValidateParams = {
    bankId: selectedBankId,
    accountNumber: accountNumber,
  };

  const [nameProcess, setNameProcess] = useState(false);

  const handleGetAccName = e => {
    if (
      selectedBankId &&
      accountNumber &&
      selectedBankId.length > 0 &&
      accountNumber.length === 10
    ) {
      setAccountName('');
      setNameProcess(true);
      setTimeout(() => {
        setNameProcess(false);
      }, 15000);
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/validate-account`,
          ValidateParams,
          headers,
        )
        .then(res => {
          setAccountName(res.data.accountName);
          setNameProcess(false);
          setNameError(false);
        })
        .catch(err => {
          if (err.response.data.code === 400) {
            setNameProcess(false);
            setNameError(true);
            setTimeout(() => {
              setNameError(false);
            }, 6000);
          }
        });
    }
  };

  return (
    <>
      <ModalArea
        style={pinModal ? { visibility: 'hidden' } : { visibility: 'visible' }}
      >
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <SubHeader>
            <SubHeaderChild customBd="#191919">
              Bank account details
            </SubHeaderChild>
            <SubHeaderChild customBd="" onClick={() => openSide()}>
              Frequent Beneficiaries
            </SubHeaderChild>
          </SubHeader>
          <div>
            <InputWrap>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <label>Amount(&#8358;)</label>
                <p
                  style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '-0.02em',
                    color: '#ABABAB',
                  }}
                >
                  Balance: &#8358;{CommaNumber(balance)}
                </p>
              </div>
              <input
                type="number"
                placeholder="Amount"
                onChange={e => handleInput(e, setAmount, true)}
              />
            </InputWrap>
            <>
              <InputWrap>
                <label>Bank</label>
                <Select
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  options={banks}
                  placeholder="Search..."
                  styles={CustomStyles}
                  isSearchable
                  value={banks.filter(option => option.name === selectedBank)}
                  onChange={(e: any) => {
                    setSelectedBankId((e as any).uuid);
                    setSelectedBank((e as any).name);
                  }}
                />
              </InputWrap>
              <InputWrap>
                <label>Account number</label>
                <input
                  type="number"
                  placeholder="Account number"
                  maxLength={10}
                  value={accountNumber}
                  onKeyPress={e => numOnly(e)}
                  onChange={e => {
                    handleInput(e, setAccountNumber);
                    if (e.target.value.length < 10) {
                      setAccountName('');
                      setIsExist(false);
                    }
                  }}
                  onKeyUp={e => handleGetAccName(e)}
                />
              </InputWrap>
              {nameProcess ? (
                <ClipLoader size={15} />
              ) : (
                <>
                  {accountName !== '' ? (
                    <p style={{ color: '#c82b38', fontSize: '0.8rem' }}>
                      {accountName}
                    </p>
                  ) : null}

                  {nameError ? (
                    <p style={{ color: '#c82b38', fontSize: '0.8rem' }}>
                      Account not found!
                    </p>
                  ) : null}
                </>
              )}
            </>
            {/* )} */}

            <InputWrap>
              <label>Purpose</label>
              <input
                type="text"
                placeholder="Purpose"
                onChange={e => handleInput(e, setPurpose)}
              />
            </InputWrap>

            {/* This is for adding beneficiary for payments which would be integrated very soon. */}
            {accountNumber !== freqData.accountNumber && !isExist ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  type="checkbox"
                  onChange={e => setAddToFB(e.target.checked)}
                />
                <label
                  style={{
                    color: '#474747',
                    margin: '0 0 0 0.5rem',
                    fontSize: '0.8rem',
                  }}
                >
                  Add to Beneficiary
                </label>
              </div>
            ) : null}
          </div>
          <p style={{ width: '20rem', fontSize: '0.8rem', color: 'red' }}>
            {clientErr}
          </p>
          <BigBtn>
            <button
              onClick={e => handlePayment()}
              disabled={onProcess ? true : false}
            >
              {onProcess ? <ClipLoader color="#fff" /> : 'Send Money'}
            </button>
          </BigBtn>
        </ModalChild>
      </ModalArea>

      {pinModal ? (
        <EnterPin
          closeFunc={handleClosePinModal}
          prevFunc={handleClosePinModal}
          errorMessage={errorMessage}
          propPin={pin}
          handlePinChange={handlePinChange}
          handleFinalPay={handleFinalPay}
          onProcess={finalOnProcess}
          bank={selectedBank}
          accountName={accountName}
          accountNo={accountNumber}
          amount={amount}
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="Transaction Unsuccessful!!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeSuccess}
          img="/success.png"
          message="Transaction successful!!!"
          miniMessage={'You have successfully funded your account. '}
        />
      ) : null}

      {result === '' ? (
        <Alert
          closeFunc={closeMessage}
          img="/failed.png"
          message="Changes not successful!"
          miniMessage="Encountered an error while creating your transaction PIN"
        />
      ) : null}
    </>
  );
};

export default TransferFinal;
