import React, { useState, useEffect } from 'react';
import {
  DetailsModal,
  DetailsChild,
  CloseIcon,
  MainPart,
} from './../../components/transactions/style';
import * as Icon from 'react-feather';
import { CollabItem } from './style';
import CommaNumber from 'comma-number';
import { Header } from '../dashboard/mainContent/fundWallet/style';
import { NavTypeWrap, NavTypeItem } from '../../pages/SignUp/styles';

interface PropsArgs {
  closeFunc: any;
  collaborators: any;
}

const AllCollaborators = ({ closeFunc, collaborators }: PropsArgs) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [modified, setModified] = useState<any>([]);

  useEffect(() => {
    const mockData: any = [];
    if (collaborators) {
      for (let i = 0; i < collaborators.length; i++) {
        if (activeIndex === 0 && collaborators[i].locked) {
          mockData.push(collaborators[i]);
        } else if (activeIndex === 0 && !collaborators[i].locked) {
          mockData.push(collaborators[i]);
        }
      }
      setModified(mockData);
    }
  }, [collaborators, activeIndex]);

  return (
    <>
      <DetailsModal onClick={closeFunc}>
        <DetailsChild
          onClick={e => e.stopPropagation()}
          style={{
            background: '#F6F4FF',
          }}
        >
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <MainPart>
            <Header
              style={{
                padding: 0,
                margin: 0,
              }}
            >
              All Collaborators
            </Header>
            <p>
              {collaborators && collaborators.length > 0
                ? collaborators.length + ' collaborators'
                : null}
            </p>
            <NavTypeWrap>
              {accountType.map((item, index) => (
                <NavTypeItem
                  key={index}
                  borderRadius={item.borderRadius}
                  color={index === activeIndex ? '#fff' : ''}
                  background={index === activeIndex ? '#9B51E0' : ''}
                  onClick={e => setActiveIndex(index)}
                  style={{
                    fontSize: '12px',
                  }}
                >
                  {item.name}
                </NavTypeItem>
              ))}
            </NavTypeWrap>
            {modified && modified.length > 0 ? (
              modified.map((item, index) => (
                <CollabItem key={index}>
                  <div>{item.name ? item.name[0] : item.email[0]}</div>
                  <div>
                    <h3>{item.name ? item.name : item.email}</h3>
                    <p>
                      {item.percentage ? `${item.percentage}%` : null}
                      {item.amount ? (
                        <span> Up to &#8358;{CommaNumber(item.amount)}</span>
                      ) : null}
                    </p>
                  </div>
                </CollabItem>
              ))
            ) : (
              <p>No Collaborator in this category.</p>
            )}
          </MainPart>
        </DetailsChild>
      </DetailsModal>
    </>
  );
};

export default AllCollaborators;

const accountType = [
  {
    name: 'Joined',
    borderRadius: '10px 10px 10px 10px',
  },
  {
    name: 'Pending',
    borderRadius: '10px 10px 10px 10px',
  },
];
