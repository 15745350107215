import InputElement from 'app/components/InputElement';
import SubmitButton from 'app/components/SubmitButton';
import axios from 'axios';
import {
  FormWidget,
  MainWrapper,
  MerchroLogo,
  AuthHeader,
} from './../Login/styles';
import { useState } from 'react';
import { HeadText } from './../SignUp/styles';
import { BeatLoader } from 'react-spinners';
import { useNavigate } from 'react-router';
import { FloatLogOption } from '../SignUp/styles';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [userEmail, setUserEmail] = useState('');
  const [clientErr, setClientErr] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [alert, setAlert] = useState(false);

  const navigate = useNavigate();

  const submitHandler = e => {
    setOnProcess(true);
    setTimeout(() => {
      setOnProcess(false);
    }, 15000);
    e.preventDefault();
    axios
      .post(process.env.REACT_APP_BASE_ENDPOINT + '/auth/forgot-password', {
        email: userEmail,
      })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setOnProcess(false);
          setAlert(true);
          navigate(`/change-password/${userEmail}`);
        }
      })
      .catch(err => {
        setClientErr(err.response.data.message);
        setOnProcess(false);
      });
  };
  return (
    <>
      <MainWrapper>
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/sign-up">Sign Up</Link>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <form className="my-5 mx-4 pt-5" onSubmit={submitHandler}>
            <div className="mb-5 ml-1">
              <HeadText style={{ fontFamily: 'Raleway' }}>
                Forgot Password
              </HeadText>
              {clientErr && (
                <p className="text-sm text-red-500 font-medium ml-1 my-1">
                  {clientErr}
                </p>
              )}
              {alert && (
                <p className="text-sm text-green-500 font-medium ml-1 my-1">
                  Please check your inbox for the password recovery code{' '}
                </p>
              )}
            </div>
            <p
              className="my-4 ml-1 text-center text-lg text-gray-500"
              style={{
                fontSize: '16px',
              }}
            >
              Type in your email address below and we'll send you an email with
              instructions on how to create a new password
            </p>
            <InputElement
              inputType="email"
              defaultText="Enter email"
              value={userEmail}
              onChange={e => setUserEmail(e.target.value)}
            />

            <SubmitButton
              text={onProcess ? <BeatLoader color="white" /> : 'Reset Password'}
              disabled={onProcess}
              style={{ cursor: onProcess ? 'not-allowed' : 'pointer' }}
            />
          </form>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

export default ForgotPassword;
