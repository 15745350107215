import IdentityMain from 'app/components/editProfile/identity';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const Identity = () => {
  return (
    <>
      <MainWrapper>
        <IdentityMain />
      </MainWrapper>
    </>
  );
};

export default Identity;
