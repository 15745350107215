import React from 'react';
import {
  DetailsModal,
  DetailsChild,
  CloseIcon,
  MainPart,
  UserInfo,
  Avatar,
} from './../../../transactions/style';
import * as Icon from 'react-feather';
import { InfoIcons } from './styles';

interface PropsArgs {
  closeFunc: any;
  name: string;
  email: string;
  firstInitial: string;
  secondInitial: string;
  phoneNo: string;
}

const Details = ({
  name,
  email,
  firstInitial,
  secondInitial,
  phoneNo,
  closeFunc,
}: PropsArgs) => {
  return (
    <>
      <DetailsModal onClick={closeFunc}>
        <DetailsChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <MainPart>
            <UserInfo>
              <Avatar>
                {firstInitial} {secondInitial}
              </Avatar>
              <h3>{name}</h3>
            </UserInfo>
            <div
              style={{
                textAlign: 'center',
                color: '#757575',
                fontSize: '15px',
                margin: '2rem 0',
              }}
            >
              {email && email !== '' ? (
                <p>
                  <InfoIcons>
                    <div>
                      <a href={`mailto::${email}`}>
                        <i className="fi fi-rr-envelope"></i>
                      </a>
                    </div>
                    {email}
                  </InfoIcons>
                </p>
              ) : null}

              {phoneNo && phoneNo !== '' ? (
                <p>
                  <InfoIcons>
                    <div>
                      <a href={`tel:${phoneNo}`}>
                        <i className="fi fi-rr-call-outgoing"></i>
                      </a>
                    </div>
                    {phoneNo}
                  </InfoIcons>
                </p>
              ) : null}
            </div>
          </MainPart>
        </DetailsChild>
      </DetailsModal>
    </>
  );
};

export default Details;
