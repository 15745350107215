import { SearchArea } from './../../orderComp/mainContent/sectionOne/styles';
import { SearchPos } from 'app/components/transactions/style';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

const NavSection = ({ handleSearch }) => {
  const handleChange = e => {
    handleSearch(e);
  };

  return (
    <>
      <SearchPos>
        <Link to="/add-merch">
          <button
            style={{
              margin: '0 1rem 0 0',
              color: '#fff',
              padding: '0.5rem 2rem',
              background: '#191919',
              borderRadius: '4px',
              fontSize: '0.8rem',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
            Add product
          </button>
        </Link>
        <SearchArea>
          <i>
            <Icon.Search />
          </i>
          <input
            type="text"
            placeholder="Search your products"
            onChange={handleChange}
          />
        </SearchArea>
      </SearchPos>
    </>
  );
};

export default NavSection;
