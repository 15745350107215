import { MainWrapper } from '../../storeComp/mainContent/styles';
import OrderContent from './orderContent/orderContent';

const MainContent = () => {
  return (
    <>
      <MainWrapper>
        <OrderContent />
      </MainWrapper>
    </>
  );
};

export default MainContent;
