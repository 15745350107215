import React, { useEffect, useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';
import { numOnly } from 'utils/numOnly';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import Countries from 'countries.json';
import * as Icon from 'react-feather';
import { TicketInput } from '../paymentLink/style';

const BVNModal = ({ closeFunc }) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  // BVN Details
  const [bvn, setBvn] = useState('');
  const [zipCode, setZipCode] = useState('');

  const [dob, setDob] = useState('');
  const [states, setInStates] = useState<any>({});
  const [state, setState] = useState<any>('');
  const [street, setStreet] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('Nigeria');

  useEffect(() => {
    const country = 'Nigeria';
    for (let i = 0; i < Countries.length; i++) {
      if (Countries[i].name === country) {
        setInStates(Countries[i].states);
      }
    }
  }, []);

  useEffect(() => {
    if (loggedUser['user']['address']) {
      setZipCode(loggedUser.user.address.zipCode);
    }
  }, [loggedUser]);

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const apiData = {
    dob,
    address: {},
  };

  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  // Validate and send BVN Informations.
  const handleBvnChange = async e => {
    e.preventDefault();

    apiData.address['street'] = street;
    apiData.address['city'] = city;
    apiData.address['state'] = state;
    apiData.address['country'] = country;
    apiData.address['zipCode'] = zipCode;

    await axios
      .patch(
        process.env.REACT_APP_BASE_ENDPOINT + '/users/' + loggedUser.user.id,
        apiData,
        {
          headers: { Authorization },
        },
      )
      .then(res => {
        setOnProcess(false);
        if (res.status === 200) {
          if (res.data) {
            loggedUser['user'] = res.data;
            localStorage.setItem('logged_user', JSON.stringify(loggedUser));
          }
        }
      })
      .catch(err => {
        setOnProcess(false);
      });

    if (bvn && bvn !== '') {
      setOnProcess(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/users/kyc-bvn-update`,
          { bvn },
          headers,
        )
        .then(res => {
          setSuccess(true);
          setOnProcess(false);
        })
        .catch(err => {
          setErrorMessage(err.response.data.message);
          setOnProcess(false);
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    }
  };

  return (
    <>
      <ModalArea
        style={{
          zIndex: '100',
        }}
      >
        <ModalChild
          style={{
            zIndex: '100000000',
          }}
        >
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Verify with BVN</Header>
          <form onSubmit={e => handleBvnChange(e)}>
            <div>
              <TicketInput>
                <div>
                  <p>BVN</p>
                  <input
                    type="number"
                    placeholder="BVN"
                    onKeyPress={e => {
                      numOnly(e);
                    }}
                    onChange={e => {
                      if (e.target.value.length > 11) return false;
                      setBvn(e.target.value);
                    }}
                    value={bvn}
                    minLength={11}
                    maxLength={11}
                    required={true}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Date of birth</p>
                  <input
                    type="date"
                    placeholder="Date of birth"
                    onChange={e => setDob(e.target.value)}
                    required={true}
                    value={dob}
                    max={'31.12.2010'}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Street</p>
                  <input
                    type="text"
                    placeholder="Street"
                    onChange={e => setStreet(e.target.value)}
                    required={true}
                    value={street}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>City</p>
                  <input
                    type="text"
                    placeholder="City"
                    onChange={e => setCity(e.target.value)}
                    required={true}
                    value={city}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Country</p>
                  <select onChange={e => setCountry(e.target.value)}>
                    <option>Nigeria</option>
                  </select>
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>States</p>
                  <select onChange={e => setState(e.target.value)}>
                    <option value="">Select State</option>
                    {states && states.length > 0
                      ? states.map((item, index) => (
                          <option key={index}>{item.name}</option>
                        ))
                      : null}
                  </select>
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Postal Code</p>
                  <input
                    type="number"
                    placeholder="Postal code"
                    onChange={e => setZipCode(e.target.value)}
                    required={true}
                  />
                </div>
              </TicketInput>
              <p style={{ width: '20rem', fontSize: '11px', color: '#757575' }}>
                We use your BVN to verify your identity. The only data we are
                able to retrieve is your name, date of birth, and the mobile
                number attached to your BVN.
              </p>
            </div>
            {errorMessage !== '' ? (
              <p style={{ color: 'red', width: '20rem' }}>{errorMessage}</p>
            ) : null}
            <BigBtn>
              <button type="submit">
                {onProcess ? <BeatLoader color="#fff" /> : 'Submit'}
              </button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>

      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            closeFunc();
          }}
          img="/success.png"
          message="Changes made successfully!"
          miniMessage="You have successfully updated your BVN"
        />
      ) : null}
    </>
  );
};

export default BVNModal;
