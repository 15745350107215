import { FlexedApart } from 'app/components/storeComp/sideNav/style';
import axios from 'axios';
import AppHeader from './../../../storeComp/mainContent/header/header';
import moment from 'moment';
import { useEffect, useState } from 'react';
import EmptyState from './../../../emptyState/emptyState';
import {
  BreadcrumbArea,
  CustomerName,
  OrderCard,
  OrderInfo,
  OrderSecondLine,
  OrderWidget,
  Price,
  StateCard,
} from './styles';
import {
  PaginationCont,
  PaginationTextWrap,
  PaginationWrapper,
} from './../../../merch/productList/styles';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { MoonLoader } from 'react-spinners';
import SectionArea from './../sectionOne/sectionOne';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { InAppHeader } from 'app/components/storeComp/mainContent/styles';
import { useNavigate } from 'react-router';

const OrderContent = () => {
  const navigate = useNavigate();
  function handleBg(state: string) {
    if (state === 'Fulfilled'.toLowerCase()) {
      return '#327d57';
    } else if (state === 'Enroute'.toLowerCase()) {
      return '#FBC011';
    } else if (state === 'To pickup'.toLowerCase()) {
      return '#FF6626';
    } else if (state === 'Pre-order'.toLowerCase()) {
      return '#A5D0FF';
    } else if (state === 'Pending'.toLowerCase()) {
      return '#D0F4FF';
    } else if (state === 'Processing'.toLowerCase()) {
      return '#86F4FF';
    } else if (state === 'Completed'.toLowerCase()) {
      return '#33DF34';
    } else if (state === 'Unfufilled'.toLowerCase()) {
      return '#c82b38';
    } else if (state === 'failed'.toLowerCase()) {
      return '#c82b38';
    }
  }

  const [orders, setOrders] = useState<any>([]);
  const [orderResult, setOrderResult] = useState<any>([]);

  // Pagination Widget
  var [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [mainTotalResult, setMainTotalResult] = useState(0);
  const [totalResults, setTotalResult] = useState<any>(0);

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const options = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const options = {
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${loggedUser.tokens.access.token}`,
      },
    };

    axios
      .get(
        process.env.REACT_APP_BASE_ENDPOINT +
          `/creator-page/orders?include=user&sortBy=createdAt:desc`,
        options,
      )
      .then(res => {
        setOrders(res.data);
        setPage(res.data.page);
        setTotalPages(res.data.totalPages);
        setTotalResult(res.data.totalResults);
        setMainTotalResult(res.data.totalResults);
        setOrderResult(res.data.results);
        localStorage.setItem('orders', JSON.stringify(res.data.results));
      })
      .catch();
  }, []);

  const handleIncrement = () => {
    if (page !== totalPages) {
      setPage(++page);
      axios
        .get(
          process.env.REACT_APP_BASE_ENDPOINT +
            `/creator-page/orders?page=${page}&include=user&sortBy=createdAt:desc`,
          options,
        )
        .then(res => {
          setOrders(res.data);
          // // setPage(res.data.page);
          setTotalPages(res.data.totalPages);
          setTotalResult(res.data.totalResults);
          setMainTotalResult(res.data.totalResults);
          setOrderResult(res.data.results);
          localStorage.setItem('orders', JSON.stringify(res.data.results));
        })
        .catch();
    }
  };

  const handleDecrement = () => {
    if (page > 1) {
      setPage(--page);
      axios
        .get(
          process.env.REACT_APP_BASE_ENDPOINT +
            `/creator-page/orders?page=${page}&include=user&sortBy=createdAt:desc`,
          options,
        )
        .then(res => {
          setOrders(res.data);
          // // setPage(res.data.page);
          setTotalPages(res.data.totalPages);
          setTotalResult(res.data.totalResults);
          setMainTotalResult(res.data.totalResults);
          setOrderResult(res.data.results);
          localStorage.setItem('orders', JSON.stringify(res.data.results));
        })
        .catch();
    }
  };

  const handleSearch: any = e => {
    let new_item: any = [];
    if (orders !== '' && orders.results.length !== 0) {
      for (let i = 0; i < orders.results.length; i++) {
        if (
          orders.results[i].orderCode.includes(e.target.value) ||
          orders.results[i].user.firstName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          orders.results[i].user.lastName
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(orders.results[i]);
        } else if (e.target.value === '') {
          setOrderResult(orders.results);
          setTotalResult(mainTotalResult);
        }
      }
      setOrderResult(new_item);
      setTotalResult(new_item.length);
    }
  };

  const handleFilter: any = e => {
    let new_item: any = [];
    if (orders !== '' && orders.results.length !== 0) {
      for (let i = 0; i < orders.results.length; i++) {
        if (orders.results[i].status.toLowerCase() === e.name.toLowerCase()) {
          new_item.push(orders.results[i]);
        } else if (e.name === 'All') {
          new_item.push(orders.results[i]);
          setTotalResult(mainTotalResult);
        }
      }
      setOrderResult(new_item);
      setTotalResult(new_item.length);
    }
  };

  return (
    <>
      <AppHeader showBack={true} pageName={`Order (${totalResults})`} />
      <InAppHeader>
        <i>
          <Icon.ArrowLeft onClick={() => navigate(-1)} />
        </i>
        <h3>Orders</h3>
      </InAppHeader>
      <BreadcrumbArea>
        <SectionArea handleSearch={handleSearch} handleFilter={handleFilter} />
        <FlexedApart></FlexedApart>
        <OrderWidget>
          {orders.length !== 0 ? (
            <>
              {orders.results.length !== 0 && orderResult.length !== 0 ? (
                <>
                  {orderResult.map((item, index) => (
                    <Link to={`/single-order/${item.id}`} key={index}>
                      <OrderCard>
                        <FlexedApart>
                          <CustomerName>
                            {item.user.firstName} {item.user.lastName}
                          </CustomerName>
                          <Price>&#8358;{item.totalAmount.price}</Price>
                        </FlexedApart>
                        <OrderSecondLine>
                          <FlexedApart>
                            <OrderInfo>
                              <p>{item.orderCode}</p>
                              <p>
                                {moment(item.updatedAt).format(
                                  'MMMM Do YYYY, h:mm:ss a',
                                )}
                              </p>
                            </OrderInfo>
                            <StateCard
                              bg={
                                !item.status
                                  ? ''
                                  : handleBg(item.status.toLowerCase())
                              }
                            >
                              {item.status}
                            </StateCard>
                          </FlexedApart>
                        </OrderSecondLine>
                      </OrderCard>
                    </Link>
                  ))}
                  {totalPages > 1 && (
                    <PaginationWrapper>
                      <PaginationCont>
                        <p onClick={() => handleDecrement()}>
                          <Icon.ChevronLeft />
                        </p>
                        <PaginationTextWrap>
                          <h4>{page}</h4>
                          <span>of</span>
                          <h4>{totalPages}</h4>
                        </PaginationTextWrap>
                        <p onClick={() => handleIncrement()}>
                          <Icon.ChevronRight />
                        </p>
                      </PaginationCont>
                    </PaginationWrapper>
                  )}
                </>
              ) : (
                <EmptyState img={'order.svg'} />
              )}
            </>
          ) : (
            <CenteredAnimeWrap>
              <MoonLoader size={40} color="#969696" />
            </CenteredAnimeWrap>
          )}
        </OrderWidget>
      </BreadcrumbArea>
    </>
  );
};

export default OrderContent;
