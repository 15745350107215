/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from '../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import { BlueText } from 'app/components/editProfile/style';
import * as Icon from 'react-feather';

const VerifyModal = ({ closeFunc, afterSend }) => {
  const width = window.innerWidth;
  const inputStyle = {
    width: width > 728 ? '2.5rem' : '2.5rem',
    height: width > 728 ? '2.5rem' : '2.5rem',
    background: '#fff',
    border: '1px solid #191919',
    borderRadius: '4px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginRight: 15,
    color: 'black',
  };

  const [actualCode, setActualCode] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  let storeRequestCount: number = Number(localStorage.getItem('rte') || -1);
  let firstRequestTime = JSON.parse(localStorage.getItem('fret') || '{}');
  const TimeoutTime = 86400000;

  const [countDown, setCountDown] = useState<any>(0);
  const [runTimer, setRunTimer] = useState<any>(false);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 2 ** storeRequestCount);
      timerId = setInterval(() => {
        setCountDown(countDown => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer, storeRequestCount]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer: any = () => {
    if (storeRequestCount === -1) {
      localStorage.setItem('fret', JSON.stringify(Date.now() + TimeoutTime));
      setRunTimer(t => !t);
      localStorage.setItem('rte', (++storeRequestCount).toLocaleString());
    } else if (storeRequestCount < 10 && Date.now() <= firstRequestTime) {
      setRunTimer(t => !t);
      localStorage.setItem('rte', (++storeRequestCount).toLocaleString());
    } else if (Date.now() > firstRequestTime) {
      localStorage.setItem('fret', JSON.stringify(Date.now() + TimeoutTime));
    } else if (storeRequestCount === 10 && Date.now() < firstRequestTime) {
      setError(
        `Please try to reset your pin/password in ${Math.ceil(
          (firstRequestTime - Date.now()) / 1000 / 60 / 60,
        )} ${
          Math.ceil((firstRequestTime - Date.now()) / 1000 / 60 / 60) > 1
            ? 'hours'
            : 'hours'
        }`,
      );
    }
  };

  const seconds: any = String(countDown % 60);
  const minutes = String(Math.floor(countDown / 60));

  useEffect(() => {
    togglerTimer();
  }, []);

  // const options = {
  //   headers: {
  //     'Content-type': 'Application/json',
  //     Authorization: `Bearer ${loggedUser.tokens.access.token}`,
  //   },
  // };

  const sendCode = () => {
    setTimeout(() => {
      setOnProcess(false);
    }, 15000);
    axios
      .post(process.env.REACT_APP_BASE_ENDPOINT + '/auth/forgot-pin', {
        email: loggedUser.user.email,
      })
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          setOnProcess(false);
          setActualCode(res.data.token);
        }
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
      });
  };

  useEffect(() => {
    sendCode();
  }, []);

  const handleSend = e => {
    setOnProcess(true);
    e.preventDefault();
    if (code === actualCode) {
      setOnProcess(false);
      afterSend();
      closeFunc();
    } else {
      setOnProcess(false);
      setError('Wrong verification code provided!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const closeModal = () => {
    setSuccess(false);
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Verification Code</Header>
          <div>
            <PinInputWrap>
              <OtpInput
                value={code}
                onChange={code => setCode(code)}
                numInputs={6}
                inputStyle={inputStyle}
                isInputNum={true}
                shouldAutoFocus={true}
                isInputSecure={false}
              />
            </PinInputWrap>
            <p
              style={{
                margin: '0rem 0 1rem 0',
                color: 'grey',
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              <br />
              {Number(minutes) > 0 || Number(seconds) > 0 ? (
                <>
                  Code expires in{' '}
                  <b>
                    {minutes}:{seconds}
                  </b>
                  .
                </>
              ) : (
                <>Code has expired.</>
              )}
            </p>
            {storeRequestCount < 10 && Date.now() > firstRequestTime ? (
              <BlueText
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <button
                  type="button"
                  disabled={countDown > 0 ? true : false}
                  style={
                    countDown > 0
                      ? {
                          cursor: 'not-allowed',
                          fontSize: '12px',
                          opacity: '0',
                        }
                      : { cursor: 'pointer', fontSize: '12px' }
                  }
                  onClick={() => {
                    sendCode();
                    togglerTimer();
                  }}
                >
                  Resend Code
                </button>
              </BlueText>
            ) : null}
            {error !== '' ? (
              <p
                style={{
                  color: 'red',
                  width: '20rem',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                {error}
              </p>
            ) : null}
          </div>
          <BigBtn>
            <button
              onClick={e => handleSend(e)}
              disabled={onProcess}
              style={{ cursor: onProcess ? 'not-allowed' : 'pointer' }}
            >
              {onProcess ? <BeatLoader color="#fff" /> : 'Continue'}
            </button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
      {success ? (
        <Alert
          closeFunc={closeModal}
          img="success.png"
          message="Password changed!"
          miniMessage="You have successfully changed your password."
        />
      ) : null}
    </>
  );
};

export default VerifyModal;

const PinInputWrap = styled.div`
  width: 20rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 1.5rem 0;

  @media (max-width: 728px) {
    width: 100%;
  }
`;
