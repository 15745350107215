import SideNav from 'app/components/storeComp/sideNav/sideNav';
// import axios from 'axios';
import { useState } from 'react';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import {
  HeaderIconsWrap,
  HeaderNavWrap,
  MainHeadWrap,
  HeaderBackground,
  PageName,
} from './styles';
import { handleBg } from 'utils/handleOrderColor';
import { StateCard } from 'app/components/orderComp/mainContent/orderContent/styles';
import Warning from 'reusable/warning';

interface PropsArgs {
  pageName: string;
  showBack: any;
  className?: any;
  showHeaderCard?: any;
  status?: any;
  setCloseWarning?: any;
  backLink?: any;
  categories?: any;
  color?: string;
  iconColor?: string;
}

const FragmentHeader = ({
  pageName,
  showBack,
  className,
  showHeaderCard,
  status,
  setCloseWarning,
  backLink,
  categories,
  color,
  iconColor,
}: PropsArgs) => {
  const width = window.innerWidth;
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }
  const navigate = useNavigate();

  // Log out Functions
  const [warning, setWarning] = useState(false);

  const toggleWarning = () => {
    setWarning(!warning);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <>
      <HeaderBackground
        style={
          width > 728
            ? {
                background: color ? color : '#fbfbfb',
                borderBottom: '0.5px solid rgba(220, 220, 220, 0.5)',
              }
            : {
                background: '#191919',
              }
        }
      >
        <MainHeadWrap
          className={className}
          top="0"
          borderRad="0"
          borderBot=""
          padding="1.5rem 2%"
          style={
            width > 728
              ? {
                  background: color ? color : '#fbfbfb',
                }
              : {
                  background: '#191919',
                }
          }
        >
          <PageName
            onClick={() => {
              setCloseWarning
                ? setCloseWarning()
                : navigate(backLink ? backLink : '/');
            }}
            display={true}
          >
            {showBack ? (
              <Icon.X
                size={40}
                style={
                  width > 728
                    ? {
                        margin: '0rem 0.7rem 0 0',
                        color: iconColor ? iconColor : '#191919',
                        cursor: 'pointer',
                        zIndex: '10000000000',
                      }
                    : {
                        margin: '0rem 0.7rem 0 0',
                        color: '#fff',
                        cursor: 'pointer',
                        zIndex: '10000000000',
                      }
                }
              />
            ) : null}
            <h1
              style={
                width > 728
                  ? {
                      color: iconColor ? iconColor : '#191919',
                    }
                  : {
                      color: '#fff',
                    }
              }
            >
              {pageName}
            </h1>
            {showHeaderCard ? (
              <StateCard
                bg={handleBg(status)}
                style={{
                  width: 'auto',
                  textAlign: 'center',
                  margin: '0 0 0 1rem',
                }}
              >
                {status === 'general' ? 'project' : status}
              </StateCard>
            ) : null}
          </PageName>
          <HeaderIconsWrap>
            <div></div>
            <div className="mobile-nav" onClick={toggleModal}>
              <p>Menu</p>
              <Icon.Menu size={20} strokeWidth="1.5px" />
            </div>
          </HeaderIconsWrap>

          {/* Header Nav */}

          {showModal ? (
            <HeaderNavWrap onClick={toggleModal}>
              <i className="fas fa-times" onClick={toggleModal}></i>
              <SideNav mobileShow="block" />
            </HeaderNavWrap>
          ) : null}

          {/* Header Nav */}

          {showModal ? (
            <HeaderNavWrap onClick={toggleModal}>
              <i className="fas fa-times" onClick={toggleModal}></i>
              <SideNav mobileShow="block" />
            </HeaderNavWrap>
          ) : null}
        </MainHeadWrap>
      </HeaderBackground>

      {warning ? (
        <Warning
          closeFunc={toggleWarning}
          productForDelete={''}
          handleDelete={handleLogout}
          header="Sign Out"
          deleteInProcess={false}
          message="Do you want to sign out from your account?"
          actionText="Sign out"
        />
      ) : null}
    </>
  );
};

export default FragmentHeader;
