import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { AccountNoCenter, InputWrap, ProgressBar, ProgressLine } from './style';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { useNavigate } from 'react-router-dom';
import {
  StoreAvatar,
  BlueText,
  ImageSetUp,
} from '../invoice/mainInvoice/style';
import PopUpModal from '../merch/popupModal';
import { handleFileUpload } from 'utils/imageUpload';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

const MainContent = () => {
  const uninterceptedCall = axios.create();
  const navigate = useNavigate();
  const [musicName, setMusicName] = useState('');
  const [artisteName, setArtisteName] = useState('');
  const [isrc, setIsrc] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [fetchedMusic, setFetchedMusic] = useState(false);

  const [error, setError] = useState('');
  const [images, setImages] = useState<any>([]);
  const [returnedImages, setReturnedImages] = useState<any>('');

  const meta = {
    artistName: artisteName,
    musicName,
    isrc: isrc.length === 12 ? isrc : '',
    avatar: returnedImages,
    fetchedMusic,
  };

  const toNext = e => {
    e.preventDefault();
    localStorage.setItem('music', JSON.stringify(meta));
    navigate('/add-collaborators?type=music');
  };

  useEffect(() => {
    const musicData = JSON.parse(localStorage.getItem('music') || '{}');

    if (musicData && Object.keys(musicData).length > 0) {
      setIsrc(musicData.isrc);
      setArtisteName(musicData.artistName);
      setMusicName(musicData.musicName);
      setReturnedImages(musicData.avatar);
      setFetchedMusic(musicData.fetchedMusic);
    }
  }, []);
  const validateMusicCode = () => {
    const client_secret = process.env.REACT_APP_SPOTIFY_CLIENT_SECRET;
    const client_id = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
    const auth = btoa(`${client_id + ':' + client_secret}`);

    const headers: any = {
      headers: {
        Authorization: 'Basic ' + auth,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    const mainBody = 'grant_type=client_credentials';

    if (isrc.length === 12) {
      setOnProcess(true);
      uninterceptedCall
        .post('https://accounts.spotify.com/api/token', mainBody, headers)
        .then(response => {
          uninterceptedCall
            .get(
              `https://api.spotify.com/v1/search?type=track&q=isrc:${isrc}`,
              {
                headers: {
                  Authorization: `Bearer ${response.data.access_token}`,
                },
              },
            )
            .then(res => {
              setArtisteName(res.data.tracks.items[0].artists[0].name);
              setMusicName(res.data.tracks.items[0].name);
              setReturnedImages(res.data.tracks.items[0].album.images[0].url);
              setFetchedMusic(true);
              setOnProcess(false);
            })
            .catch(err => {
              setError(err.response.data.message);
              setOnProcess(false);
              setTimeout(() => {
                setError('');
              }, 4000);
            });
        })
        .catch(err => {
          setError(err.response.data.message);
          setOnProcess(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    } else {
      setError('Please, enter valid ISRC code');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setImages,
      setError,
      setReturnedImages,
    });
  };

  const handleClose = () => {
    setError('');
  };

  return (
    <>
      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      <MainWrapper style={{ width: '100%' }} left="0" top="4.5rem">
        <FragmentHeader showBack={true} pageName={`Music Split`} />
        <BreadcrumbArea
          style={{
            margin: '4rem 0 10rem 0',
          }}
        >
          <form onSubmit={toNext}>
            <ProgressBar>
              <ProgressLine bg="#191919"></ProgressLine>
              <ProgressLine></ProgressLine>
              <ProgressLine></ProgressLine>
            </ProgressBar>
            <ImageSetUp>
              {images && images.length > 0 && returnedImages === '' ? (
                <StoreAvatar src={URL.createObjectURL(images[0])} />
              ) : null}

              {images.length < 1 && returnedImages === '' ? (
                <StoreAvatar src="http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png" />
              ) : null}

              {returnedImages !== '' ? (
                <StoreAvatar src={returnedImages} />
              ) : null}

              {!fetchedMusic ? (
                <label htmlFor="main-input">
                  <BlueText
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {'Add Music Avatar'}
                  </BlueText>
                </label>
              ) : null}

              <input
                id="main-input"
                type="file"
                multiple={false}
                style={{ display: 'none' }}
                onChange={e => {
                  handleFile(e.target.files);
                }}
              ></input>
            </ImageSetUp>
            <AccountNoCenter
              style={{
                margin: '0',
              }}
            >
              <div>
                <h3>Royalties Split Settings</h3>
                <p
                  style={{
                    margin: '0',
                    padding: '0.5rem 0',
                  }}
                >
                  Invite co-writers and rights holders to join this split
                </p>
              </div>
            </AccountNoCenter>
            <MainFlex
              style={{
                margin: '0',
                top: 0,
                padding: 0,
              }}
            >
              <PairSection width="35%" mobileMargin="0">
                <div
                  style={{
                    boxShadow: 'none',
                    background: 'none',
                  }}
                >
                  <InputWrap
                    style={{
                      marginTop: '0',
                    }}
                  >
                    <label>Enter your ISRC</label>
                    <input
                      onChange={(e: any) => {
                        setIsrc(e.target.value);
                        if (e.target.value.length < 12) {
                          setFetchedMusic(false);
                          setReturnedImages('');
                          setArtisteName('');
                          setMusicName('');
                        }
                      }}
                      onKeyPress={(e: any) => {
                        if (e.target.value < 12) {
                          setFetchedMusic(false);
                          setReturnedImages('');
                          setArtisteName('');
                          setMusicName('');
                        }
                      }}
                      value={isrc}
                      maxLength={12}
                      onBlur={() => validateMusicCode()}
                    />
                  </InputWrap>
                  {onProcess ? <ClipLoader size={20} color="#161616" /> : null}
                  <InputWrap>
                    <label>Song Title</label>
                    <input
                      onChange={e => setMusicName(e.target.value)}
                      value={musicName}
                      minLength={5}
                      disabled={fetchedMusic}
                      required
                    />
                  </InputWrap>
                  <InputWrap>
                    <label>Artiste Name</label>
                    <input
                      onChange={e => setArtisteName(e.target.value)}
                      value={artisteName}
                      disabled={fetchedMusic}
                      required
                    />
                  </InputWrap>
                  <BigBtn
                    style={{
                      width: '100%',
                    }}
                  >
                    <button type="submit">Continue</button>
                  </BigBtn>
                </div>
              </PairSection>
            </MainFlex>
          </form>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default MainContent;

export const splitNav = [
  {
    name: 'Percentage',
    borderRadius: '4px 0 0 4px',
  },
  {
    name: 'Amount',
    borderRadius: '0 4px 4px 0',
  },
];
