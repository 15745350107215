import React from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
  InputWrap,
} from './style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import { BeatLoader } from 'react-spinners';

interface PropsArgs {
  closeFunc: any;
  startDate: string;
  endDate: string;
  setStartDate: any;
  setEndDate: any;
  action: any;
  onProcess: boolean;
}

const AccountStatement = ({
  closeFunc,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  action,
  onProcess,
}: PropsArgs) => {
  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <form onSubmit={e => action(e)}>
            <HeaderWrap>
              <h3
                style={{
                  textAlign: 'left',
                }}
              >
                Account Statement
              </h3>
              <p
                style={{
                  textAlign: 'left',
                }}
              >
                Choose a timeframe for your statement and select a format you
                want it in.
              </p>
            </HeaderWrap>
            <ModalMain borderTp="none" margin="none" padding="none">
              <InputWrap>
                <label>Start date</label>
                <input
                  type="date"
                  required={true}
                  value={startDate}
                  max={currentDate}
                  onChange={e => setStartDate(e.target.value)}
                />
              </InputWrap>
              <InputWrap>
                <label>End date</label>
                <input
                  type="date"
                  required={true}
                  value={endDate}
                  max={currentDate}
                  onChange={e => setEndDate(e.target.value)}
                />
              </InputWrap>
              <BigBtn>
                <button type="submit">
                  {onProcess ? <BeatLoader color="#fff" /> : 'Continue'}
                </button>
              </BigBtn>
            </ModalMain>
          </form>
        </ReportModalChild>
      </ReportModalWidget>
    </>
  );
};

export default AccountStatement;
