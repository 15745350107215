import AddItem from 'app/components/invoice/mainInvoice/addItem';
// import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const NewInvoice = () => {
  return (
    <>
      <MainWrapper
        className="no-scrollbar"
        style={{
          background: '#fff',
        }}
      >
        {/* <SideNav mobileShow="none" /> */}
        <AddItem />
      </MainWrapper>
    </>
  );
};

export default NewInvoice;
