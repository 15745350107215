import { useEffect, useState } from 'react';
import { MainWrapper } from '../../storeComp/mainContent/styles';
import { useParams } from 'react-router';
import axios from 'axios';
import {
  BigConstraint,
  Card,
  FlexedApart,
  ImageGrid,
  PaddedArea,
} from './style';
import { Line, SearchPos } from 'app/components/transactions/style';
// import {
//   FlexArea,
//   ProductInfo,
// } from './../../customers/mainContent/customersCont/styles';
// import { UserAvatar } from '../../storeComp/sideNav/style';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';
import Alert from 'app/components/dashboard/mainContent/fundWallet/alert';
import Warning from 'reusable/warning';
import { useNavigate } from 'react-router';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';

const SingleMerchComp = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  // States
  const [productInfo, setProductInfo] = useState<any>({});
  const [productImages, setProductImages] = useState<any>([]);
  const [productMeta, setProductMeta] = useState<any>({});
  const [productPrice, setProductPrice] = useState<any>({});
  const [productPreorder, setProductPreorder] = useState<any>({});

  useEffect(() => {
    var creator = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const option = {
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${creator.tokens.access.token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/merches/${id}`, option)
      .then(res => {
        setProductInfo(res.data);
        setProductImages(res.data.images);
        setProductMeta(res.data.metadata);
        setProductPrice(res.data.price);
        setProductPreorder(res.data.preOrder);
      })
      .catch(err => {});
  }, [id]);

  function StoreItem(item) {
    localStorage.setItem('editItem', JSON.stringify(item));
  }

  // Deletion of Items

  const [warning, setWarning] = useState(false);
  const [productForDelete, setProductForDelete] = useState<any>({});
  const [deleteInProcess, setDeleteInProcess] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleWarningModal = item => {
    setProductForDelete(item);
    setWarning(true);
  };

  const closeFunc = () => {
    setWarning(false);
  };

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const handleDelete = () => {
    // Delete Process
    setDeleteInProcess(true);
    setTimeout(() => {
      setDeleteInProcess(false);
    }, 15000);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/merches/${productForDelete.id}`,
        { headers: { Authorization } },
      )
      .then(res => {
        setSuccess(true);
        setWarning(false);
        axios
          .get(
            process.env.REACT_APP_BASE_ENDPOINT +
              `/creator-page/${loggedUser.user.creatorPage}/merches?sortBy=createdAt:desc`,
          )
          .then(resp => {
            setDeleteInProcess(false);
            navigate('/product-list');
          });
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      })
      .catch(err => {
        setDeleteInProcess(false);
      });
  };

  const closeModal = () => {
    setSuccess(false);
  };

  return (
    <>
      <MainWrapper style={{ width: '100%' }} left="0" top="3.5rem">
        <FragmentHeader showBack={true} pageName={`${productInfo.name}`} />
        <SearchPos position="fixed">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '20px',
              margin: '1rem 5% 0 0',
            }}
          >
            <Link to="/edit-merch">
              <Icon.Edit2
                color="#474747"
                size={20}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => StoreItem(productInfo)}
              />
            </Link>

            <Icon.Trash2
              color="#c82b38"
              size={20}
              onClick={() => handleWarningModal(productInfo)}
              style={{
                cursor: 'pointer',
              }}
            />
          </div>
        </SearchPos>
        <BigConstraint>
          <Card>
            <PaddedArea>
              <FlexedApart>
                <h2>Product Details</h2>
                <p>Price . &#8358;{productPrice.amount}</p>
              </FlexedApart>
            </PaddedArea>
            <Line />
            <PaddedArea>
              <h3>Product image(s)</h3>
              <ImageGrid>
                {productImages && productImages.length > 0
                  ? productImages.map((item, index) => (
                      <img key={index} src={item} alt="Product" />
                    ))
                  : null}
              </ImageGrid>
            </PaddedArea>
            {productInfo.description ? (
              <PaddedArea>
                <h3>Product Description</h3>
                <p>{productInfo.description}</p>
              </PaddedArea>
            ) : null}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: '5px',
              }}
            >
              {productMeta.colors ? (
                <PaddedArea>
                  <h3>
                    Colors:
                    <p>
                      {productMeta.colors && productMeta.colors.length > 0
                        ? productMeta.colors.map((item, index) => (
                            <span key={index}> {item},</span>
                          ))
                        : null}
                    </p>
                  </h3>
                </PaddedArea>
              ) : null}

              {productMeta.sizes ? (
                <PaddedArea>
                  <h3>
                    Sizes:
                    <p>
                      {productMeta.sizes && productMeta.sizes.length > 0
                        ? productMeta.sizes.map((item, index) => (
                            <span key={index}> {item}</span>
                          ))
                        : null}
                    </p>
                  </h3>
                </PaddedArea>
              ) : null}
            </div>

            <PaddedArea>
              <h3>Inventory</h3>
              <p>
                {productInfo.quantity ? productInfo.quantity : null} items in
                stock
              </p>
              <p>
                {productPreorder.maxNumOfPreOrders
                  ? productPreorder.maxNumOfPreOrders
                  : null}{' '}
                preorder
              </p>
            </PaddedArea>
          </Card>
          <Card>
            <PaddedArea>
              <FlexedApart>
                <h2>Product Sales</h2>
                {/* <p>Price . &#8358;{productPrice.amount}</p> */}
              </FlexedApart>
            </PaddedArea>
            <Line />
            <PaddedArea>
              <p>No Data available!</p>
              {/* <FlexArea>
                  <ProductInfo>
                    <UserAvatar
                      style={{
                        width: '3rem',
                        height: '3rem',
                        fontSize: '14px',
                        background: 'rgba(200, 43, 50, 0.08)',
                        color: '#71191D',
                        fontWeight: 'bolder',
                        border: 'none',
                      }}
                    >
                      {'A'}
                    </UserAvatar>
                    <div>
                      <p>
                        Daniel Adewale
                      </p>
                    </div>
                  </ProductInfo>
                  <div>
                    <div>
                      <h3>&#8358;{500}</h3>
                      <p>
                        From Stock
                      </p>
                    </div>
                  </div>
                </FlexArea> */}
            </PaddedArea>
          </Card>
        </BigConstraint>
      </MainWrapper>

      {warning ? (
        <Warning
          closeFunc={closeFunc}
          productForDelete={productForDelete}
          handleDelete={handleDelete}
          deleteInProcess={deleteInProcess}
          header="Delete Product"
          message="Are you sure you want to delete the added merch from your products?"
          actionText="Confirm Delete"
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Success!!!"
          miniMessage={'You have deleted an item from the store successfully!'}
        />
      ) : null}
    </>
  );
};

export default SingleMerchComp;
