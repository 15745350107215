const width = window.innerWidth;

export const CustomStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: width > 728 ? '14px' : '16px',
    borderBottom: '0.5px solid #dadada',
    background: '#fff',
    color: '#191919',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  },
  control: (provided, state) => ({
    ...provided,
    border: '0.5px solid #dadada',
    padding: '0.2rem 1%',
    margin: '0.4rem 0 0.2rem 0',
    borderRadius: '5px',
    background: '#ffffff',
    fontSize: width > 728 ? '14px' : '16px',
  }),
};
