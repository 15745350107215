import { BounceLoader } from 'react-spinners';

const WaitLoader = props => {
  const count: any = props.count || 5;
  const countArray: number[] = [];
  for (let i = 0; i < count; i++) {
    countArray.push(i);
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {countArray.map((x: any, index) => {
        return (
          <BounceLoader key={index} size={props.size || 16} color="#757575" />
        );
      })}
    </div>
  );
};

export default WaitLoader;
