import React, { useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from '../../dashboard/mainContent/fundWallet/style';
import * as Icon from 'react-feather';
import Capture from './capture';
import { BeatLoader } from 'react-spinners';

const CaptureImage = ({ closeFunc, onImage, error, onSubmit, onProcess }) => {
  const [passInstruction, setPassInstruction] = useState(false);
  const [image, setImage] = useState<any>(null);
  const submitSelfie = () => {
    if (image) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(image);
      const fileList = dataTransfer.files;
      onSubmit(fileList);
      onImage(fileList);
    }
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Photo Verification</Header>
          <h3
            style={{
              fontSize: '14px',
              fontWeight: 700,
              color: '#191919',
              margin: '1rem 0',
            }}
          >
            Take a selfie
          </h3>
          {!passInstruction ? (
            <>
              <p
                style={{
                  width: '20rem',
                  fontSize: '12px',
                  color: '#757575',
                  margin: '1rem 0',
                }}
              >
                <ul>
                  Please, Keep a straight face.
                  <br />
                  <br />
                  Hold your device at arm's length and look directly at the
                  front-facing camera
                  <br />
                  <br />
                  Make sure your face is fully visible and not obstructed by
                  anything, such as clothing, hats, or sunglasses.
                  <br />
                  <br />
                  Removing any obstructions, turn on more lighting to improve
                  the picture quality.
                </ul>
              </p>
              <BigBtn>
                <button
                  onClick={() => setPassInstruction(true)}
                  style={{
                    margin: '0.7rem 0 0 0',
                  }}
                >
                  Continue
                </button>
              </BigBtn>
            </>
          ) : (
            <>
              {image ? (
                <>
                  <img
                    height={300}
                    width={317}
                    src={URL.createObjectURL(image)}
                    alt=""
                    style={{ transform: 'scaleX(-1)' }}
                  />

                  {error ? (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#c82b32',
                      }}
                    >
                      {error}
                    </p>
                  ) : null}
                  <BigBtn>
                    <button
                      onClick={() => setImage(null)}
                      style={{
                        background: 'transparent',
                        color: '#191919',
                        border: '0.5px solid #191919',
                      }}
                    >
                      Retake Selfie
                    </button>
                  </BigBtn>
                  <BigBtn>
                    <button
                      onClick={submitSelfie}
                      style={
                        onProcess
                          ? {
                              margin: '0.7rem 0 0 0',
                              cursor: 'not-allowed',
                            }
                          : {
                              margin: '0.7rem 0 0 0',
                              cursor: 'pointer',
                            }
                      }
                      disabled={onProcess}
                    >
                      {onProcess ? (
                        <BeatLoader color="#fff" />
                      ) : (
                        'Submit Selfie'
                      )}
                    </button>
                  </BigBtn>
                </>
              ) : (
                <Capture onUpdate={image => setImage(image)} />
              )}
            </>
          )}
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default CaptureImage;
