import React, { useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  CloseBtn,
  Flexed,
  RightPart,
  InfoText,
  BankDetailsWrap,
} from './../dashboard/mainContent/fundWallet/style';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import * as Icon from 'react-feather';

const DollarBankTransfer = ({ closeFunc, accountNo }) => {
  const [copied, setCopied] = useState(false);
  function handleCopy(text) {
    navigator.clipboard.writeText(text);
    setCopied(!copied);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  // Success Response and Error Management

  const [sucess, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const closeModal = () => {
    setSuccess(false);
    setError(false);
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Fund Account</Header>
          <RightPart>
            <InfoText>
              <p>
                Make a transfer to the account below and your wallet will be
                funded immediately.
              </p>
            </InfoText>
            <BankDetailsWrap>
              <p>Routing Number</p>
              <Flexed
                style={{
                  background: '#f3f3f3',
                  padding: '0.4rem',
                  alignItems: 'center',
                  margin: '0.5rem 0 0 0',
                  borderRadius: '4px',
                  flexDirection: 'row',
                }}
              >
                <h3>051402372</h3>
                <Icon.Copy
                  onClick={() => handleCopy(accountNo)}
                  color="#ABABAB"
                  size={15}
                />
              </Flexed>
            </BankDetailsWrap>
            <BankDetailsWrap>
              <p>Account number</p>
              <Flexed
                style={{
                  background: '#f3f3f3',
                  padding: '0.4rem',
                  alignItems: 'center',
                  margin: '0.5rem 0 0 0',
                  borderRadius: '4px',
                  flexDirection: 'row',
                }}
              >
                <h3>8761091892</h3>
                <Icon.Copy
                  onClick={() => handleCopy(accountNo)}
                  color="#ABABAB"
                  size={15}
                />
              </Flexed>
            </BankDetailsWrap>
            <BankDetailsWrap>
              <p>Beneficiary Name</p>
              <Flexed
                style={{
                  background: '#f3f3f3',
                  padding: '0.4rem',
                  alignItems: 'center',
                  margin: '0.5rem 0 0 0',
                  borderRadius: '4px',
                  flexDirection: 'row',
                }}
              >
                <h3>Mage Inc</h3>
                <Icon.Copy
                  onClick={() => handleCopy(accountNo)}
                  color="#ABABAB"
                  size={15}
                />
              </Flexed>
            </BankDetailsWrap>
            <BankDetailsWrap>
              <p>Beneficiary Address</p>
              <Flexed
                style={{
                  background: '#f3f3f3',
                  padding: '0.4rem',
                  alignItems: 'center',
                  margin: '0.5rem 0 0 0',
                  borderRadius: '4px',
                  flexDirection: 'row',
                }}
              >
                <h3>8 The Green Dover, DE 19901, US</h3>
                <Icon.Copy
                  onClick={() => handleCopy(accountNo)}
                  color="#ABABAB"
                  size={15}
                />
              </Flexed>
            </BankDetailsWrap>
            <BankDetailsWrap>
              <p>Bank Name</p>
              <Flexed
                style={{
                  background: '#f3f3f3',
                  padding: '0.4rem',
                  alignItems: 'center',
                  margin: '0.5rem 0 0 0',
                  borderRadius: '4px',
                  flexDirection: 'row',
                }}
              >
                <h3>Blue Ridge Bank & Trust</h3>
                <Icon.Copy
                  onClick={() => handleCopy(accountNo)}
                  color="#ABABAB"
                  size={15}
                />
              </Flexed>
            </BankDetailsWrap>
            <BankDetailsWrap>
              <p>Bank Address</p>
              <Flexed
                style={{
                  background: '#f3f3f3',
                  padding: '0.4rem',
                  alignItems: 'center',
                  margin: '0.5rem 0 0 0',
                  borderRadius: '4px',
                  flexDirection: 'row',
                }}
              >
                <h3>17 West Main Street Luray, VA 22835</h3>
                <Icon.Copy
                  onClick={() => handleCopy(accountNo)}
                  color="#ABABAB"
                  size={15}
                />
              </Flexed>
            </BankDetailsWrap>
          </RightPart>
        </ModalChild>
        {copied ? (
          <p
            style={{
              width: '20rem',
              fontSize: '0.8rem',
              textAlign: 'center',
              position: 'fixed',
              top: '2rem',
              left: '50%',
              color: 'white',
              background: '#121212',
              transform: 'translate(-50%, 0)',
              boxShadow: '0 0 4px #cbced1',
              padding: '1rem 0',
              zIndex: '1000',
              borderRadius: '8px',
            }}
          >
            <i style={{ margin: '0 5% 0 0' }} className="fi fi-rr-check"></i>
            Copied to clipboard
          </p>
        ) : null}
      </ModalArea>

      {sucess ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Transaction successful!"
          miniMessage="You have successfully funded your Mage account."
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occured"
          miniMessage="An Error occured while funding your account. Please try again!"
        />
      ) : null}
    </>
  );
};

export default DollarBankTransfer;
