import styled from 'styled-components';

export const CardWrap = styled.section`
  width: 100%;
  padding: 1.2rem 0.7rem;
  background: #1a1a1a;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 13px;
  margin: 3rem 0 0 0;

  p {
    color: #ababab;
    fontsize: 11px;
  }

  div {
    margin: 0 0 0.2rem 0;
  }

  svg {
    cursor: pointer;

    :hover {
      opacity: 0.5;
    }
  }

  @media (max-width: 728px) {
    margin: 0;
  }
`;

export const CopyText = styled.div`
  display: flex;
  align-items: Center;
  gap: 7px;
`;

export const InformationWrap = styled.section`
  background: #f9f9f9;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  padding: 1.2rem 0.7rem;
  display: flex;
  gap: 25px 35px;
  flex-wrap: wrap;
  margin: 2rem 0 0 0;

  svg {
    cursor: pointer;

    :hover {
      opacity: 0.5;
    }
  }
`;
interface Props {
  width: string;
}
export const MaskedDiv = styled.div<Props>`
  width: ${p => p.width || '7rem'};
  height: 1.2rem;
  background: #2c2c2c;
  opacity: 0.6;
  border-radius: 5px;
`;

export const CardOption = styled.div`
  display: flex;
  gap: 10px;
  margin: 0 0 0 1rem;

  > section {
    width: 4rem;
    height: 4rem;
    border-radius: 10rem;
    background: #f8f8f8;
    border: 0.5px solid #dadada;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    :hover {
      opacity: 0.3;
    }

    > img {
      display: block;
      height: 1rem;
    }

    @media (max-width: 728px) {
      width: 3rem;
      height: 3rem;

      > img {
        height: 0.7rem;
      }
    }
  }
`;

export const CardListView = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 1500px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 560px) {
    grid-template-columns: repeat(1, 1fr);
  }
  padding: 20px 0;
  grid-gap: 40px;
  margin-top: 0px;
  position: relative;
  width: 100%;
`;

export const Card = styled.div`
  padding: 20px;
  border: 1px solid #00000020;
  border-radius: 10px;
  width: auto;
  cursor: pointer;
  .masked {
    font-size: 16px;
    position: relative;
    span {
      color: transparent;
      text-shadow: 0 0 6px rgba(0, 0, 30);
    }
  }
  div {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    position: relative;
    p {
      font-size: 16px;
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0px;
      height: 24px;
      width: auto;
    }
  }
`;
