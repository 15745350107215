import InputElement from 'app/components/InputElement';
import SubmitButton from 'app/components/SubmitButton';
import axios from 'axios';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthHeader, FormWidget, MainWrapper, MerchroLogo } from './styles';
import { FloatLogOption } from '../SignUp/styles';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/react';
import * as Icon from 'react-feather';
import { BlueText } from 'app/components/editProfile/style';
import Store from 'utils/store';
import { getRequest } from 'utils/request';

const override: any = css`
  margin-top: 1rem;
`;

const Login = () => {
  const history = useNavigate();
  const [revealPassword, setRevealPassword] = useState(false);
  const toggleHandler = e => {
    e.preventDefault();
    revealPassword ? setRevealPassword(false) : setRevealPassword(true);
  };

  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserpassword] = useState('');
  const [clientErr, setClientErr] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const TimeoutTime = 86400000;

  const inputModified = () => {
    setClientErr('');
  };

  const loginHandler = e => {
    e.preventDefault();
    setOnProcess(true);

    const authCredentials: { email: string; password: string } = {
      email: userEmail,
      password: userPassword,
    };

    axios
      .post(
        process.env.REACT_APP_BASE_ENDPOINT + '/auth/login',
        authCredentials,
      )
      .then((res: any) => {
        if (!res.data.user.emailVerified) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_ENDPOINT}/auth/resend-email-verification`,
              { email: userEmail },
            )
            .then(res => {
              if (res.status === 200) {
                history(
                  `/email-confirmation/${res.data.firstName}/${res.data.id}/${res.data.email}`,
                );
              }
            })
            .catch(err => {});
          return;
        }
        // Store UID and redirect to dashboard
        Store.create('logged_user', res.data);
        let date = new Date();
        const duration = 30 * 24 * 60 * 60 * 1000;
        date.setTime(date.getTime() + duration);
        document.cookie = `UID=${
          res.data.user.id
        }; expires=${date.toUTCString()}; path=/`;
        if ('creatorPage' in res.data.user) {
          if (res.data.user.pinSetup) {
            getRequest('kyc-status')
              .then(kyc => {
                Store.create('cky', kyc);
              })
              .catch(err => {
                console.log(err);
              });
            Store.create('expire_time', Date.now() + TimeoutTime);
            history('/');
          } else {
            history('/create-pin');
          }
        } else {
          history('/onboarding');
        }
      })
      .catch(err => {
        setOnProcess(false);
        setClientErr(
          err.response
            ? err.response.data.message
            : 'Error authenticating user',
        );
        if (err.response.data.message === 'User email is not verified') {
          axios
            .post(
              `${process.env.REACT_APP_BASE_ENDPOINT}/auth/resend-email-verification`,
              { email: userEmail },
            )
            .then(res => {
              if (res.status === 200) {
                history(
                  `/email-confirmation/${res.data.firstName}/${res.data.id}/${res.data.email}`,
                );
              }
            })
            .catch(err => {});
        }
      });
  };

  return (
    <>
      <MainWrapper>
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/sign-up">Sign Up</Link>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <form onSubmit={loginHandler}>
            <div className="mb-5 ml-1">
              <h1
                className="text-2xl font-bold text-baseDark"
                style={{
                  textAlign: 'center',
                  fontFamily: 'Satoshi',
                  fontWeight: 800,
                  color: '#191919',
                }}
              >
                Login to Mage
              </h1>
              {clientErr && (
                <p className="text-sm text-red-500 font-medium ml-1 my-1">
                  {clientErr}
                </p>
              )}
            </div>
            <InputElement
              inputType="email"
              defaultText="Email address"
              value={userEmail}
              onChange={e => {
                inputModified();
                setUserEmail(e.target.value);
              }}
            />
            <div className="relative">
              <InputElement
                inputType={revealPassword ? 'text' : 'password'}
                defaultText="Password"
                value={userPassword}
                onChange={e => {
                  inputModified();
                  setUserpassword(e.target.value);
                }}
              />

              <button
                className="absolute bottom-8 right-6"
                onClick={e => toggleHandler(e)}
              >
                <i>
                  {revealPassword ? (
                    <Icon.EyeOff size={18} />
                  ) : (
                    <Icon.Eye size={18} />
                  )}
                </i>
              </button>
            </div>
            <SubmitButton
              style={{
                position: 'relative',
                cursor: onProcess ? 'not-allowed' : 'pointer',
              }}
              text={
                onProcess ? (
                  <BeatLoader color="white" css={override} />
                ) : (
                  'Login'
                )
              }
              disabled={onProcess}
            />
            <Link to="/forgot-password">
              <BlueText
                style={{
                  margin: '1rem 0 2rem 0',
                }}
              >
                I forgot my password
              </BlueText>
            </Link>
          </form>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

export default Login;
