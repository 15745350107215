import React, { useState, useEffect } from 'react';
import AppHeader from '../storeComp/mainContent/header/header';
import { MainWrapper } from '../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from './../transactions/style';
import { InAppHeader } from '../storeComp/mainContent/styles';
import {
  ButtonWrap,
  CustomButtonDark,
  ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import { HeaderPopUp } from '../split/style';
import * as Icon from 'react-feather';
import { MainFlex, PairSection } from '../invoice/mainInvoice/singleStyle';
import { CardWrap, CopyText, InformationWrap } from './style';
import { FlexedApart } from '../merch/singleMerch/style';
import {
  Flexed,
  BankDetailsWrap,
} from './../dashboard/mainContent/fundWallet/style';
import { useNavigate } from 'react-router';
import axios from 'axios';
import CopiedNotifier from '../reusable/copied';
import PauseWarning from './pauseWarning';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { NavTypeWrap, NavTypeItem } from '../../pages/SignUp/styles';
import { MaskedDiv } from './style';
import { getSign } from 'utils/getCurrencySign';
import Store from 'utils/store';

const CardView = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [activeIndex, setActiveIndex] = useState(0);
  const [showActionPop, setShowActionPop] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [card, setActiveCard] = useState<any>({});
  const [brandMode, setBrandMode] = useState('MASTERCARD');
  const [brandImage, setBrandImage] = useState('');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const cardId = Store.get('activeCard');
    if (!cardId) {
      setTimeout(() => {
        navigate('/virtual-cards');
      }, 2000);
    } else {
      const card_ = Store.get('cards').find(x => x.id === cardId);
      if (!card_) {
        setTimeout(() => {
          navigate('/virtual-cards');
        }, 2000);
      }
      setActiveCard(card_);
      setBrandMode(card_.issuer);
      setBrandImage(`/${card_.issuer.toLocaleLowerCase()}.png`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  // Copy Action
  const [copied, setCopied] = useState(false);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  };

  const pauseFunction = () => {
    setOnProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/payments/cards/${card.id}/${
          card.status === 'DISABLED' ? 'unfreeze' : 'freeze'
        }`,
        {},
        headers,
      )
      .then(res => {
        setOnProcess(false);
        setOpenWarning(false);
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  return (
    <>
      {copied ? <CopiedNotifier /> : null}
      {openWarning ? (
        <PauseWarning
          status={card.status}
          handleAction={() => pauseFunction()}
          actionInProcess={onProcess}
          closeFunc={() => setOpenWarning(false)}
          actionText={card.status === 'DISABLED' ? 'Resume' : 'Pause'}
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={() => setError('')}
          img="/failed.png"
          message="An error occured"
          miniMessage={error}
        />
      ) : null}

      <MainWrapper
        className="no-scrollbar"
        mobileTop="4rem"
        onClick={() => {
          setShowActionPop(false);
        }}
      >
        <AppHeader showBack={true} pageName={`Card`} />
        <InAppHeader
          style={{
            display: 'flex',
          }}
          mobileTop="-1rem"
        >
          <h3>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/virtual-cards')}
            >
              Cards
            </span>
          </h3>
          {card.status === 'DISABLED' ? (
            <p
              style={{
                background: 'rgba(200, 43, 50, 0.03)',
                color: '#c82b32',
                padding: '4px 10px',
                margin: '0 0 0 5px',
                fontWeight: '500',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '7px',
              }}
            >
              Paused
              <Icon.PauseCircle />
            </p>
          ) : null}
        </InAppHeader>
        <SearchPos
          style={
            width < 728
              ? {
                  flexDirection: 'row',
                  top: '1rem',
                  margin: '0 auto',
                }
              : {}
          }
        >
          <ButtonWrap
            style={{ position: 'relative', right: -30 }}
            mobileHide={false}
            margin="0 0 5rem 0"
          >
            <CustomButtonDark
              onClick={e => {
                setShowActionPop(!showActionPop);
                e.stopPropagation();
              }}
              style={{
                right: 0,
              }}
            >
              <ButtonFlex>
                Action
                <Icon.ChevronDown
                  style={{
                    position: 'relative',
                    left: '0.5rem',
                  }}
                  size={13}
                />
              </ButtonFlex>
            </CustomButtonDark>
          </ButtonWrap>
          {showActionPop ? (
            <HeaderPopUp>
              <p
                onClick={() => {
                  navigate(`/fund-card/${card.id}?type=${brandMode}`);
                  setShowActionPop(!showActionPop);
                }}
              >
                Fund Card
              </p>
              {process.env.REACT_APP_WITHDRAW_CARD_ACTION !== 'false' ? (
                <p
                  onClick={() => {
                    navigate(`/withdraw-card/${card.id}?type=${brandMode}`);
                    setShowActionPop(!showActionPop);
                  }}
                >
                  Withdraw from Card
                </p>
              ) : null}
              {process.env.REACT_APP_PAUSE_CARD_ACTION !== 'false' ? (
                <p
                  onClick={() => {
                    setOpenWarning(true);
                    setShowActionPop(!showActionPop);
                  }}
                >
                  {card.status === 'DISABLED' ? 'Resume' : 'Pause'} Transactions
                </p>
              ) : null}
              <p
                onClick={() => {
                  navigate(`/card/transactions/${card.id}`);
                  setShowActionPop(!showActionPop);
                }}
              >
                View Transactions
              </p>
            </HeaderPopUp>
          ) : null}
        </SearchPos>
        <hr style={{ marginTop: 50 }} />
        <BreadcrumbArea>
          <>
            <div
              style={{
                width: '6rem',
                margin: '0 auto',
              }}
            >
              <NavTypeWrap
                style={{
                  padding: '5px',
                  borderRadius: '10rem',
                  height: '3rem',
                  justifyContent: 'space-between',
                  border: '0.5px solid #ABABAB',
                  background: '#f8f8f8',
                }}
              >
                {showType.map((item, index) => (
                  <NavTypeItem
                    key={index}
                    borderRadius={item.borderRadius}
                    color={index === activeIndex ? '#fff' : ''}
                    background={index === activeIndex ? '#191919' : ''}
                    onClick={e => {
                      setActiveIndex(index);
                    }}
                    style={{
                      width: '2.5rem',
                      height: '2.5rem',
                      fontSize: '12px',
                      textAlign: 'center',
                      padding: '7px 0px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <i>{React.createElement(item.name, { size: 15 })}</i>
                  </NavTypeItem>
                ))}
              </NavTypeWrap>
            </div>

            <MainFlex>
              <PairSection width="40%" mobileMargin="0">
                <CardWrap>
                  <FlexedApart>
                    {activeIndex === 1 ? (
                      <h3>
                        {getSign(card.currency)}
                        {card.balance}
                      </h3>
                    ) : (
                      <MaskedDiv width="4rem" />
                    )}
                    <img
                      src={brandImage}
                      alt={brandMode}
                      style={{
                        height: '1rem',
                      }}
                    />
                  </FlexedApart>
                  <hr
                    style={{
                      margin: '0.9rem 0',
                      border: 'none',
                      borderTop: '0.5px solid #757575',
                    }}
                  />
                  <FlexedApart>
                    <p>Card No</p>
                    <CopyText>
                      {activeIndex === 1 ? (
                        <h3>{card.masked_pan}</h3>
                      ) : (
                        <MaskedDiv width="8rem" />
                      )}
                      <Icon.Copy
                        size={14}
                        onClick={() => copyText(card.card_number)}
                      />
                    </CopyText>
                  </FlexedApart>
                  <FlexedApart>
                    <p>Expiry date</p>
                    <CopyText>
                      {activeIndex === 1 ? (
                        <h3>{card.expiry}</h3>
                      ) : (
                        <MaskedDiv width="5rem" />
                      )}
                      <Icon.Copy
                        size={14}
                        onClick={() => copyText(card.expiry)}
                      />
                    </CopyText>
                  </FlexedApart>
                  <FlexedApart>
                    <p>CVV</p>
                    <CopyText>
                      {activeIndex === 1 ? (
                        <h3>{card.cvv}</h3>
                      ) : (
                        <MaskedDiv width="3rem" />
                      )}
                      <Icon.Copy size={14} onClick={() => copyText(card.cvv)} />
                    </CopyText>
                  </FlexedApart>
                </CardWrap>

                {activeIndex === 1 ? (
                  <InformationWrap>
                    <BankDetailsWrap margin="0">
                      <p>Name on card</p>
                      <Flexed
                        style={{
                          background: '#fff',
                          padding: '0.4rem',
                          alignItems: 'center',
                          borderRadius: '10px',
                          flexDirection: 'row',
                        }}
                      >
                        <h3>{card.name}</h3>
                        <Icon.Copy
                          color="#ABABAB"
                          size={15}
                          onClick={() => copyText(card.name)}
                        />
                      </Flexed>
                    </BankDetailsWrap>
                    <BankDetailsWrap margin="0">
                      <p>Billing Address</p>
                      <Flexed
                        style={{
                          background: '#fff',
                          padding: '0.4rem',
                          alignItems: 'center',
                          borderRadius: '10px',
                          flexDirection: 'row',
                        }}
                      >
                        <h3>
                          {card.address.street}, {card.address.city},{' '}
                          {card.address.country}
                        </h3>
                        <Icon.Copy
                          color="#ABABAB"
                          size={15}
                          onClick={() =>
                            copyText(
                              `${card.address.street}, ${card.address.city}, ${card.address.country}`,
                            )
                          }
                        />
                      </Flexed>
                    </BankDetailsWrap>
                    <BankDetailsWrap width="30%" margin="0" mobileWidth="100%">
                      <p>City</p>
                      <Flexed
                        style={{
                          background: '#fff',
                          padding: '0.4rem',
                          alignItems: 'center',
                          borderRadius: '10px',
                          flexDirection: 'row',
                        }}
                      >
                        <h3>{card.address.city}</h3>
                        <Icon.Copy
                          color="#ABABAB"
                          size={15}
                          onClick={() => copyText(card.address.city)}
                        />
                      </Flexed>
                    </BankDetailsWrap>
                    <BankDetailsWrap width="31%" margin="0" mobileWidth="40%">
                      <p>State</p>
                      <Flexed
                        style={{
                          background: '#fff',
                          padding: '0.4rem',
                          alignItems: 'center',
                          borderRadius: '10px',
                          flexDirection: 'row',
                        }}
                      >
                        <h3>{card.address.state}</h3>
                        <Icon.Copy
                          color="#ABABAB"
                          size={15}
                          onClick={() => copyText(card.address.state)}
                        />
                      </Flexed>
                    </BankDetailsWrap>
                    <BankDetailsWrap width="25%" margin="0" mobileWidth="40%">
                      <p>Zip Code</p>
                      <Flexed
                        style={{
                          background: '#fff',
                          padding: '0.4rem',
                          alignItems: 'center',
                          borderRadius: '10px',
                          flexDirection: 'row',
                        }}
                      >
                        <h3>{card.address.postal_code}</h3>
                        <Icon.Copy
                          color="#ABABAB"
                          size={15}
                          onClick={() => copyText(card.address.postal_code)}
                        />
                      </Flexed>
                    </BankDetailsWrap>
                    <BankDetailsWrap width="100%" margin="0" mobileWidth="40%">
                      <p>Phone number (for Apple services only)</p>
                      <Flexed
                        style={{
                          background: '#fff',
                          padding: '0.4rem',
                          alignItems: 'center',
                          borderRadius: '10px',
                          flexDirection: 'row',
                        }}
                      >
                        <h3>{`+12252392278`}</h3>
                        <Icon.Copy
                          color="#ABABAB"
                          size={15}
                          onClick={() => copyText(`+12252392278`)}
                        />
                      </Flexed>
                    </BankDetailsWrap>
                  </InformationWrap>
                ) : null}
              </PairSection>
            </MainFlex>
          </>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default CardView;

const showType = [
  {
    name: Icon.EyeOff,
    borderRadius: '10rem',
  },
  {
    name: Icon.Eye,
    borderRadius: '10rem',
  },
];
