import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import { FloatLogOption } from './SignUp/styles';
import {
  AuthHeader,
  MainWrapper,
  FormWidget,
  MerchroLogo,
} from './Login/styles';
import { Link } from 'react-router-dom';

const CreatePin = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;

  const inputStyle = {
    width: width > 728 ? '1rem' : '1rem',
    height: width > 728 ? '1rem' : '1rem',
    borderRadius: '10rem',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 8,
    display: 'flex',
    alignitems: 'Center',
    justifyContent: 'center',
  };

  const [pin, setPin] = useState('');
  const [clientErr, setClientErr] = useState('');

  const handleNext = () => {
    if (pin && pin.length === 4) {
      navigate('/confirm-pin');
      localStorage.setItem('setpin', JSON.stringify(pin));
      setPin('');
    } else {
      setClientErr('Pin must be four characters!');
      setPin('');
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };
  const logOut = () => {
    localStorage.clear();
    navigate('/login');
  };
  return (
    <>
      <MainWrapper>
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/login" onClick={() => logOut()}>
              Log out
            </Link>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <div className="px-4">
            <h1
              className="text-2xl font-bold text-baseDarkk"
              style={{
                textAlign: 'center',
                fontFamily: 'Satoshi',
                fontWeight: 800,
                color: '#191919',
              }}
            >
              Create PIN
            </h1>
            {clientErr && (
              <p
                className="text-sm text-red-500 font-medium my-1"
                style={{
                  textAlign: 'center',
                }}
              >
                {clientErr}
              </p>
            )}
            <p
              style={{
                margin: '0 0 2rem 0',
                color: 'grey',
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              <br />
              Set up your 4-digit pin.
            </p>
            <PinInputWrap>
              <OtpInput
                value={pin}
                onChange={code => setPin(code)}
                className="otp-input"
                numInputs={4}
                inputStyle={inputStyle}
                isInputNum={true}
                shouldAutoFocus={true}
                // isInputSecure={true}
              />
            </PinInputWrap>
            <br />
            <br />
            <Button
              onClick={() => handleNext()}
              disabled={!pin || pin.length < 4 ? true : false}
            >
              Next
            </Button>
          </div>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

const PinInputWrap = styled.div`
  width: 10rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 0 0;
  background: #f3f3f3;
  border-radius: 35.6541px;

  @media (max-width: 728px) {
    width: 10rem;
  }
`;

export const Button = styled.button`
  width: 100%;
  background: #191919;
  padding: 3% 2%;
  border: none;
  color: #fff;
  border-radius: 0.3rem;
  cursor: pointer;
  margin: 1rem 0 0 0;

  :hover {
    border: 1px solid #111c30;
    opacity: 0.8;
  }

  @media (max-width: 728px) {
    padding: 3% 2%;
    margin: 1rem 0 1rem 0;
  }
`;

export default CreatePin;
