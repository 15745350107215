import styled from 'styled-components';

export const BigConstraint = styled.div`
  width: 45%;
  padding: 0;
  margin: 4rem auto;

  @media (max-width: 728px) {
    width: 100%;
    margin: 5rem 0 0 0;
  }
`;

export const Card = styled.div`
  border: 1px solid #f1f1f1;
  box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 1rem 0;
  margin: 0 0 2rem 0;

  h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #474747;
  }

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #474747;
    margin: 0 0 1rem 0;
  }

  h4 {
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #474747;
  }

  h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    display: flex;
    align-items: Center;
    color: #474747;
    margin: 0 0 1rem 0;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #ababab;

    > span {
      margin: 0 0 0 0.5rem;
    }
  }
`;

export const FlexedApart = styled.div`
  display: Flex;
  align-items: Center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
export const PaddedArea = styled.div`
  padding: 1.5rem 2rem;
`;

export const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 10px;

  > img {
    object-fit: cover;
    height: 8rem;
  }

  @media (max-width: 728px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
