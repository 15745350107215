import styled from 'styled-components';

interface Props {
  align: string;
}

export const MainWrapper = styled.div`
  margin: 0rem 0 0 0;
`;

export const MiniHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: Center;

  > h2 {
    font-size: 1.1rem;
    font-weight: 500;
    color: #191919;
  }

  > p {
    font-size: 0.8rem;
    color: #959595;
  }
`;

export const PreferenceBlock = styled.div`
  margin: 3rem 0 0 0;
`;

export const EachPreferenceBlock = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: ${p => p.align || 'center'};
  margin: 0 0 1.5rem 0;
  width: 85%;

  @media (max-width: 1028px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftPart = styled.p`
  width: 25%;
  font-size: 0.8rem;
  color: #474747;
  font-weight: 700;

  @media (max-width: 1028px) {
    width: 100%;
    margin: 0 0 0.5rem 0;
  }
`;

export const MiddlePart = styled.div`
  width: 40%;
  position: relative;

  @media (max-width: 1028px) {
    width: 100%;
  }
`;

export const RightPart = styled.p`
  width: 25%;
  font-size: 0.75rem;
  color: #969696;

  @media (max-width: 1028px) {
    width: 100%;
  }
`;

export const ImageDisplay = styled.div`
  position: relative;
  width: 100%;
`;

export const BlockImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  filter: brightness(0.5);
`;

export const CameraIconWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: Center;
  z-index: 2;

  > i {
    color: white;
    font-size: 1.5rem;
  }
`;

export const SelectInput = styled.select`
  width: 100%;
  padding: 0.5rem 2.5%;
  background: #ededed;
  border: none;
  border-radius: 0.3rem;
  color: #757575;
  font-family: 'Satoshi', 'sans-serif';
  font-size: 0.8rem;
`;

export const Important = styled.span`
  color: #ff2a2a;
`;

export const GenericInput = styled.input`
  width: 100%;
  padding: 0.5rem 2.5%;
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 5px;
  color: #757575;
  margin: 0.3rem 0 0 0;
  font-family: 'Satoshi', 'sans-serif';
  font-size: 14px;
  font-weight: 400;

  @media (max-width: 1028px) {
    font-size: 16px;
  }
`;

export const SaveButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0 0;

  > button {
    background: #191919;
    color: #fff;
    border: none;
    padding: 0.7rem 3%;
    border-radius: 0.2rem;
    margin: 0 0 0 0%;
  }

  @media (max-width: 728px) {
    width: 100%;

    > button {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export const UsernameFormWrap = styled.div`
  width: 100%;
  // border-radius: 10px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: 15px;

  @media screen and (max-width: 600px) {
    height: 55px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 400px) {
    margin-bottom: 15px;
  }
`;

export const UsernameInput = styled.input`
  width: 60%;
  // height: 100%;
  background: #fff;
  border: 1px solid #dadada;
  border-right: none;
  border-radius: 5px 0 0 5px;
  /* margin: 10px 0; */
  padding: 12px 7px;
  color: #191919;
  font-size: 14px;
  font-weight: 400;

  &::placeholder {
    color: #2b2b2b4d;
    font-weight: 400;
  }

  &:focus {
    outline-color: #b8b8b8;
  }

  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
    font-size: 11px;
    padding: 16px;
  }
  @media screen and (max-width: 400px) {
    width: 60%;
    font-size: 10px;
  }
`;

export const UsernameFormbox = styled.div`
  border: 0.5px solid #dadada;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #ffffff;
  padding: 13px 8px;
  display: flex;
  align-items: center;
  width: 40%;

  @media screen and (max-width: 400px) {
    width: 40%;
    padding: 14px 20px;
  }
`;

export const UsernameFormboxP = styled.p`
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #2b2b2b;

  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
    font-size: 11px;
  }
  @media screen and (max-width: 400px) {
    font-size: 10px;
  }
`;
