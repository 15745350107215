import AppHeader from './../../storeComp/mainContent/header/header';
import { MainWrapper } from './../../storeComp/mainContent/styles';
import CustomersCont from './customersCont/customersCont';

const MainContent = () => {
  return (
    <>
      <MainWrapper>
        <AppHeader showBack={true} pageName="Audience" />
        <CustomersCont />
      </MainWrapper>
    </>
  );
};

export default MainContent;
