import styled from 'styled-components';

export const Layer = styled.div`
  background: #f9f4ff;
  width: 100%;
  display: flex;
  justify-content: Center;
  padding: 2rem 0;

  @media (max-width: 1028px) {
    background: #ffffff;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const MainWrapper = styled.div`
  width: 49rem;
  height: auto;
  position: relative;
  padding: 2rem 3rem 2rem 3rem;
  background: #fff;
  box-shadow: 0px 0px 1px 2px rgba(244, 248, 255, 0.05);
  border-radius: 8px;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 100%;
`;

export const InvoiceNoWrap = styled.div`
  position: absolute;
  top: 4rem;
  right: 5%;
  text-align: right;

  h2 {
    font-size: 12px;
    font-weight: 800;
  }

  span {
    font-size: 11px;
    font-weight: 400;
  }
`;

export const UserInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;

  > img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }

  > h2 {
    font-size: 1.2rem;
    font-weight: bolder;
  }

  p {
    font-size: 0.8rem;
    color: #757575;
  }
`;

export const BillSection = styled.div`
  margin: 2.5rem 0;

  > div {
    width: 100%;
  }

  > div > div {
    position: relative;
    width: 40%;
    display: flex;
    flex-direction: column;

    > h2 {
      font-size: 14px;
      font-weight: 700;
      margin: 0.5rem 0 1rem 0;
      color: #191919;

      > span {
        font-weight: 400;
        font-size: 0.85rem;
      }
    }

    > h3 {
      font-size: 12px;
      font-weight: 400;
      color: #191919;
    }

    > p {
      font-size: 0.9rem;
      font-weight: 500;
    }

    > button {
      width: 100%;
      background: #191919;
      padding: 0.5rem 5%;
      border-radius: 8px;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      font-weight: bolder;

      > span {
        font-weight: 400;
      }
    }
  }

  > div > div:nth-child(2) {
    width: 30%;
  }
`;

export const FlexApart = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemsSection = styled.div`
  margin: 3rem 0;
`;
interface Props {
  customBg: any;
}

export const ItemRow = styled.div<Props>`
  display: Flex;
  justify-content: space-between;
  background: ${p => p.customBg || '#ffffff'};
  margin: 1rem 0 0 0;
  border-radius: 8px;
  color: #191919;

  h2 {
    font-size: 14px;
    font-weight: 800;
  }

  p {
    font-size: 12px;
  }
`;

export const Description = styled.div`
  width: 40%;
  padding: 2%;
`;

export const SmallColumn = styled.div`
  width: 20%;
  padding: 2%;
  text-align: center;
`;

export const BillingInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > div {
    width: 40%;

    > div {
      width: 100%;
      margin: 0 0 0.4rem 0;
      display: flex;
      justify-content: space-between;

      > p {
        font-size: 12px;
        font-weight: 400;
      }

      > h2 {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
`;

export const ActionBtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: Center;
  font-size: 0.9rem;
  margin: 6rem 0 0 0;
`;
interface PropsArgs {
  customBg: any;
  customCl: any;
}

export const ActionBtn = styled.div<PropsArgs>`
  width: 35%;
  border-radius: 8px;
  padding: 0.5rem 5%;
  text-align: Center;
  cursor: pointer;
  background: ${p => p.customBg || '#ffffff'};
  color: ${p => p.customCl || '#000000'};
`;

export const TradeMark = styled.div`
  width: 100%;
  margin: 2rem 0 1rem 0;
  display: flex;
  justify-content: Center;
  align-items: Center;

  > img {
    height: 2rem;
  }

  P {
    color: #757575;
    font-size: 0.8rem;
  }
`;

export const UserAvatar = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  /* margin: 0 1% 0 3%; */
  border-radius: 50%;
  border: 1px solid #191919;
  background: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`;
