import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import {
  AccountNoCenter,
  InputWrap,
  CollabSuggestWrap,
  SplitUsersWrap,
  InputActPlacehold,
} from './style';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import { FlexedApart } from '../merch/singleMerch/style';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { useNavigate, useSearchParams } from 'react-router-dom';
// import {
//   StoreAvatar,
//   BlueText,
//   ImageSetUp,
// } from '../invoice/mainInvoice/style';
import PopUpModal from '../merch/popupModal';
// import { handleFileUpload } from 'utils/imageUpload';
import axios from 'axios';
import { TicketInput } from '../paymentLink/style';
import ReactCountryFlag from 'react-country-flag';
import * as Icon from 'react-feather';
import { Countries } from 'utils/countryCode';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { numOnly } from 'utils/numOnly';

const JoinSplitIdentity = () => {
  const { id } = useParams();
  const [getQuery] = useSearchParams();
  const cId = getQuery.get('cId');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [ipi, setIpi] = useState('');
  const [pro, setPro] = useState('');
  const [country, setCountry] = useState('Nigeria');
  const [activeTag, setActiveTag] = useState('NG');
  const [onProcess, setOnProcess] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false);

  const [error, setError] = useState('');
  // const [images, setImages] = useState<any>([]);
  // const [returnedImages, setReturnedImages] = useState<any>('');

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators/${cId}`,
          headers,
        )
        .then(res => {
          setIpi(res.data.ipi);
          setPro(res.data.pro);
        })
        .catch(err => {});
    }, 1000);
  }, []); //eslint-disable-line
  useEffect(() => {
    setFullName(`${loggedUser.user.firstName} ${loggedUser.user.lastName}`);
  }, [loggedUser]);

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const patchDetailst = e => {
    e.preventDefault();
    setOnProcess(true);
    axios
      .patch(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators/${cId}`,
        {
          name: fullName,
          ipi,
          pro,
        },
        headers,
      )
      .then(res => {
        setOnProcess(false);
        navigate(`/split/contract/${id}?cId=${cId}`);
      })
      .catch(err => {
        setError(err.response.data.message);
        setOnProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  // const handleFile = async (files: any) => {
  //   handleFileUpload({
  //     files,
  //     setImages,
  //     setError,
  //     setReturnedImages,
  //   });
  // };

  const handleClose = () => {
    setError('');
  };

  return (
    <>
      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      <MainWrapper
        style={{ width: '100%' }}
        left="0"
        top="4.5rem"
        onClick={() => {
          setIsInputFocus(false);
        }}
      >
        <FragmentHeader showBack={true} pageName={`Personal Details`} />
        <BreadcrumbArea
          style={{
            margin: '4rem 0 10rem 0',
          }}
        >
          <form onSubmit={e => patchDetailst(e)}>
            <AccountNoCenter
              style={{
                margin: '0',
              }}
            >
              <div>
                <h3>Personal details</h3>
                <p
                  style={{
                    margin: '0',
                    padding: '0.5rem 0',
                  }}
                >
                  We need your personal details to continue
                </p>
              </div>
            </AccountNoCenter>
            {/* <ImageSetUp>
              {images && images.length > 0 && returnedImages === '' ? (
                <StoreAvatar src={URL.createObjectURL(images[0])} />
              ) : null}

              {images.length < 1 && returnedImages === '' ? (
                <StoreAvatar src="http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png" />
              ) : null}
              {returnedImages !== '' ? (
                <StoreAvatar src={returnedImages} />
              ) : null}
              {!fetchedMusic ? (
                <label htmlFor="main-input">
                  <BlueText
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {'Add Profile Photo'}
                  </BlueText>
                </label>
              ) : null}
              <input
                id="main-input"
                type="file"
                multiple={false}
                style={{ display: 'none' }}
                onChange={e => {
                  handleFile(e.target.files);
                }}
              ></input>
            </ImageSetUp> */}
            <MainFlex
              style={{
                margin: '0',
                top: 0,
                padding: 0,
              }}
            >
              <PairSection width="35%" mobileMargin="0">
                <div
                  style={{
                    boxShadow: 'none',
                    background: 'none',
                  }}
                >
                  <InputWrap
                    style={{
                      marginTop: '0',
                    }}
                  >
                    <label>Full Name</label>
                    <input
                      onChange={(e: any) => {
                        setFullName(e.target.value);
                      }}
                      value={fullName}
                    />
                  </InputWrap>
                  <div>
                    <FlexedApart
                      style={{
                        margin: '0 0 0.3rem 0',
                      }}
                    >
                      <h4
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        Country of Residence
                      </h4>
                    </FlexedApart>
                    <TicketInput>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <p>Country</p>
                        <InputActPlacehold
                          onClick={e => {
                            setIsInputFocus(!isInputFocus);
                            e.stopPropagation();
                          }}
                          onBlur={() => setIsInputFocus(false)}
                        >
                          <div>
                            <ReactCountryFlag
                              style={{
                                fontSize: '1.3rem',
                                borderRadius: '50%',
                              }}
                              countryCode={activeTag}
                              svg
                              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                              cdnSuffix="svg"
                            />
                            <p
                              style={{
                                color: '#191919',
                              }}
                            >
                              {country}
                            </p>
                          </div>
                          <i>
                            <Icon.ChevronDown size={15} strokeWidth={3} />
                          </i>
                        </InputActPlacehold>
                      </div>
                    </TicketInput>
                    <CollabSuggestWrap
                      style={{
                        margin: '0',
                        width: '100%',
                        zIndex: '10000000',
                        top: '-1.5rem',
                      }}
                    >
                      {isInputFocus ? (
                        <div>
                          {Countries && Countries.length > 0 ? (
                            Countries.map((item, index) => (
                              <SplitUsersWrap
                                alignVertical="flex-start"
                                key={index}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setActiveTag(item.code);
                                  setCountry(item.name);
                                }}
                              >
                                <div
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '10px',
                                      width: '100%',
                                    }}
                                  >
                                    <ReactCountryFlag
                                      style={{
                                        fontSize: '1.3rem',
                                        borderRadius: 'none',
                                      }}
                                      countryCode={item.code}
                                      svg
                                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                      cdnSuffix="svg"
                                      title={item.code}
                                    />
                                    <div
                                      style={{
                                        width: '70%',
                                      }}
                                    >
                                      <h3
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          width: '80%',
                                        }}
                                      >
                                        {item.name
                                          ? `${item.name}        `
                                          : ''}
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </SplitUsersWrap>
                            ))
                          ) : (
                            <p
                              style={{
                                textAlign: 'center',
                                fontSize: '14px',
                                color: '#757575',
                                margin: '1rem 0 0 0',
                              }}
                            >
                              No Option.
                            </p>
                          )}
                        </div>
                      ) : null}
                    </CollabSuggestWrap>
                  </div>
                  <InputWrap>
                    <label>Choose Society (PRO)</label>
                    <select
                      name=""
                      id=""
                      className="select"
                      onChange={e => setPro(e.target.value)}
                      value={pro}
                      required
                    >
                      {proList.map((item, index) => (
                        <option key={index}>{item}</option>
                      ))}
                    </select>
                  </InputWrap>
                  <InputWrap>
                    <label>IPI</label>
                    <input
                      onChange={e => setIpi(e.target.value)}
                      value={ipi}
                      type="text"
                      maxLength={11}
                      onKeyPress={e => {
                        numOnly(e);
                      }}
                      required
                    />
                  </InputWrap>
                  <BigBtn
                    style={{
                      width: '100%',
                    }}
                  >
                    <button type="submit" disabled={onProcess}>
                      {onProcess ? <BeatLoader color="#fff" /> : 'Continue'}
                    </button>
                  </BigBtn>
                </div>
              </PairSection>
            </MainFlex>
          </form>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default JoinSplitIdentity;

export const splitNav = [
  {
    name: 'Percentage',
    borderRadius: '4px 0 0 4px',
  },
  {
    name: 'Amount',
    borderRadius: '0 4px 4px 0',
  },
];

const proList = [
  'AACIMH',
  'AAS',
  'ABRAMUS',
  'ABYROY',
  'ACAM',
  'ACCS',
  'ACDAM',
  'ACUM',
  'ADDAF',
  'AEI-GUATEMALA',
  'AGADU',
  'AKKA-LAA',
  'AKM',
  'ALABAUTOR',
  'AMAR SOMBRAS',
  'AMCOS',
  'AMRA',
  'AMUS',
  'ANCO',
  'APA',
  'APDAYC',
  'APRA',
  'ARMAUTHOR NGO',
  'ARTISJUS',
  'ASCAP',
  'ASSIM',
  'AUTODIA',
  'BBDA',
  'BCDA',
  'BGDA',
  'BMDA',
  'BMI',
  'BSCAP',
  'BUBEDRA',
  'BUMA',
  'BURIDA',
  'BOTODRA',
  'CAPASSO',
  'CASH',
  'CMC',
  'CMRRA',
  'COMPASS',
  'COSBOTS',
  'COSCAP',
  'COSOMA',
  'COSON',
  'COSOTA',
  'COSOZA',
  'COTT',
  'CPSN',
  'EAU',
  'ECCO',
  'FILSCAP',
  'GCA',
  'GEMA',
  'GHAMRO',
  'GMR',
  'HDS-ZAMP',
  'IMPF',
  'IMRO',
  'IPRS',
  'JACAP',
  'JASRAC',
  'KazAK',
  'KODA',
  'KOMCA',
  'LATGA',
  'MACA',
  'MACP',
  'MASA',
];
