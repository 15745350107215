import React, { useState } from 'react';
import {
  AlertArea,
  ModalChild,
  CloseBtn,
  Center,
  SmallBtn,
} from './../dashboard/mainContent/fundWallet/style';
import * as Icon from 'react-feather';
import { AccountNoCenter, SplitAccountSection } from './style';
import { Link } from 'react-router-dom';
import CopiedNotifier from '../reusable/copied';

const SplitSuccess = ({ closeFunc, message, img, accNo, accName, bank }) => {
  const [accNoCopied, setAccNoCopied] = useState(false);
  function handleAccCopy(text) {
    navigator.clipboard.writeText(text);
    setAccNoCopied(!accNoCopied);
    setTimeout(() => {
      setAccNoCopied(false);
    }, 3000);
  }
  return (
    <>
      <AlertArea style={{ background: '#fff' }}>
        <ModalChild style={{ boxShadow: 'none' }}>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          {accNoCopied ? <CopiedNotifier /> : null}
          <Center>
            <img src={img} alt="Icon" />
            <h3>{message}</h3>
            {accNo || bank ? (
              <AccountNoCenter style={{ margin: '1rem 0 0 0' }}>
                <SplitAccountSection>
                  <div>
                    <h2>{accNo}</h2>
                    <p
                      style={{
                        justifyContent: 'center',
                        margin: '0 auto',
                      }}
                    >
                      {bank}
                    </p>
                  </div>
                  <Icon.Copy
                    size={20}
                    color="#757575"
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleAccCopy(accNo)}
                  />
                </SplitAccountSection>
              </AccountNoCenter>
            ) : null}
            <Link to="/split-payment">
              <SmallBtn>
                <button>Back to Dashboard</button>
              </SmallBtn>
            </Link>
          </Center>
        </ModalChild>
      </AlertArea>
    </>
  );
};

export default SplitSuccess;
