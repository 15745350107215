import React, { useState, createRef, useEffect } from 'react';
import AppHeader from './../storeComp/mainContent/header/header';
import { InAppHeader, MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import {
  TableWrap,
  TableHeader,
  NameWrap,
  PurposeWrap,
  DateWrap,
  AmountWrap,
  MenuWrap,
  MenuContent,
  SearchPos,
  MobileHistory,
} from '.././transactions/style';
import {
  RightWidget,
  RightCards,
  HistoryWidget,
  HistoryItemCard,
  HistoryInfoSec,
  HistoryAmount,
  HistoryTextWrap,
} from '../dashboard/mainContent/style';
import { SearchArea } from '../orderComp/mainContent/sectionOne/styles';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import EmptyState from '../emptyState/emptyState';
import { MoonLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import moment from 'moment';
import { titleCase } from 'utils/capitalizeFirst';
import CommaNumber from 'comma-number';
import { Link } from 'react-router-dom';
import {
  PaginationWrapper,
  PaginationCont,
  PaginationTextWrap,
} from '../merch/productList/styles';
import { useNavigate } from 'react-router';
import PaymentWarning from 'reusable/paymentWarning';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';

const MainContent = () => {
  const [toggleActive, setToggleActive] = useState(false);
  const [toggleId, setToggleId] = useState('');
  const navigate = useNavigate();
  const [links, setLinks] = useState<any>('');
  const [linksResult, setLinksResult] = useState<any>([]);
  var [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [totalResult, setTotalResult] = useState(0);
  const [selected, setSelected] = useState<any>({});
  const [onProcess, setOnProcess] = useState(false);

  const store = JSON.parse(localStorage.getItem('store') || '{}');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const handleToggleActive = id => {
    setToggleActive(true);
    setToggleId(id);
  };

  useEffect(() => {
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    fetch(
      `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/payment-link?exclude=event&sortBy=createdAt:desc`,
      {
        headers: { Authorization },
      },
    )
      .then(res => {
        res.json().then(resp => {
          setLinks(resp);
          setLinksResult(resp.results);
          setPage(resp.page);
          setTotalPages(resp.totalPages);
          setTotalResult(resp.totalResults);
        });
      })
      .catch(err => {});
  }, [loggedUser.tokens.access.token]);

  const handleIncrement = () => {
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    if (page !== totalPages) {
      setPage(++page);
      fetch(
        `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/payment-link?page=${page}&sortBy=createdAt:desc&exclude=event`,
        {
          headers: { Authorization },
        },
      )
        .then(res => {
          res.json().then(resp => {
            setLinks(resp);
            setLinksResult(resp.results);
            setPage(resp.page);
            setTotalPages(resp.totalPages);
            setTotalResult(resp.totalResults);
          });
        })
        .catch(err => {});
    }
  };

  const handleDecrement = () => {
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    if (page > 1) {
      setPage(--page);
      fetch(
        `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/payment-link?page=${page}&sortBy=createdAt:desc&exclude=event`,
        {
          headers: { Authorization },
        },
      )
        .then(res => {
          res.json().then(resp => {
            setLinks(resp);
            setLinksResult(resp.results);
            setPage(resp.page);
            setTotalPages(resp.totalPages);
            setTotalResult(resp.totalResults);
          });
        })
        .catch(err => {});
    }
  };

  // Delete Tab
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDelete = item => {
    setDeleteModal(true);
    setSelected(item);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  //Details Tab
  const [detailsModal, setDetailsModal] = useState(false);

  const toggleDetailsModal = item => {
    setDetailsModal(!detailsModal);
  };

  // Handle Search
  const handleSearch: any = e => {
    let new_item: any = [];
    if (links !== '' && links.results.length > 0) {
      for (let i = 0; i < links.results.length; i++) {
        if (
          links.results[i].pageName
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(links.results[i]);
        } else if (e.target.value === '') {
          setLinksResult(links.results);
        }
      }
      setLinksResult(new_item);
    }
  };

  // PDF Download

  const ref: any = createRef();
  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [11, 7],
  };

  const handlePdf = item => {};

  const setReceiptItem = item => {};

  // Handle Copy Payment Link
  const [copied, setCopied] = useState(false);

  function handleCopy(item) {
    navigator.clipboard.writeText(store === undefined ? '' : item);
    setCopied(!copied);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  // Set Item for Edit
  const handleEdit = async (item: any) => {
    localStorage.setItem('link', JSON.stringify(item));
    await navigate('/edit-payment-link');
  };

  const [success, setSuccess] = useState(false);

  // Handle Deleting of Payment Link
  const handleFinalDelete = () => {
    // Delete Process
    setOnProcess(true);
    setTimeout(() => {
      setOnProcess(false);
    }, 30000);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/payment-link/${selected.paymentCode}`,
        { headers: { Authorization } },
      )
      .then(res => {
        setOnProcess(false);
        setSuccess(true);
        setDeleteModal(false);
        fetch(
          `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/payment-link?exclude=event&sortBy=createdAt:desc`,
          {
            headers: { Authorization },
          },
        )
          .then(res => {
            res.json().then(resp => {
              setLinks(resp);
              setLinksResult(resp.results);
              setPage(resp.page);
              setTotalPages(resp.totalPages);
              setTotalResult(resp.totalResults);
            });
          })
          .catch(err => {});
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      })
      .catch(err => {
        setOnProcess(false);
      });
  };

  const closeModal = () => {
    setSuccess(false);
  };

  return (
    <>
      <MainWrapper onClick={() => setToggleActive(false)}>
        <AppHeader showBack={true} pageName={`Payment Links(${totalResult})`} />
        <InAppHeader>
          <h3>Payment Links</h3>
        </InAppHeader>
        <SearchPos>
          <Link to="/create-payment-link/one_time">
            <button
              style={{
                margin: '0 1rem 0 0',
                color: '#fff',
                padding: '0.5rem 2rem',
                background: '#191919',
                borderRadius: '4px',
                fontSize: '0.8rem',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
              New Payment Link
            </button>
          </Link>
          <SearchArea>
            <i>
              <Icon.Search />
            </i>
            <input
              type="text"
              placeholder="Search your payment links"
              onChange={e => handleSearch(e)}
            />
          </SearchArea>
        </SearchPos>
        <BreadcrumbArea>
          {copied ? (
            <p
              style={{
                width: '20rem',
                fontSize: '0.8rem',
                textAlign: 'center',
                position: 'fixed',
                top: '2rem',
                left: '50%',
                color: 'white',
                background: '#121212',
                transform: 'translate(-50%, 0)',
                boxShadow: '0 0 4px #cbced1',
                padding: '0.8rem 0',
                zIndex: '1000',
                borderRadius: '8px',
              }}
            >
              <i style={{ margin: '0 5% 0 0' }} className="fi fi-rr-check"></i>
              Copied to clipboard
            </p>
          ) : null}
          <TableWrap>
            <TableHeader bg="" border="none" style={{ margin: '3rem 0 0 0' }}>
              <NameWrap>PAGE NAME</NameWrap>
              <PurposeWrap>LINK TYPE</PurposeWrap>
              <DateWrap>DATE CREATED</DateWrap>
              <AmountWrap style={{ width: '10%' }}>AMOUNT</AmountWrap>
              <MenuWrap></MenuWrap>
            </TableHeader>
            <>
              {links && links !== '' ? (
                <>
                  {links.results.length !== 0 && linksResult.length !== 0 ? (
                    <>
                      {linksResult.map((item, index) => (
                        <>
                          <TransactionCard
                            item={item}
                            key={index}
                            id={item.id}
                            pageName={item.pageName}
                            linkType={item.paymentType}
                            updatedAt={item.updatedAt}
                            amount={item.amount}
                            handleCopy={handleCopy}
                            store={store}
                            openDetails={toggleDetailsModal}
                            handlePdf={handlePdf}
                            options={options}
                            propRefs={ref}
                            handleSelect={setReceiptItem}
                            toggleId={toggleId}
                            setToggleActive={setToggleActive}
                            setToggleId={setToggleId}
                            toggleActive={toggleActive}
                            toggleMenu={handleToggleActive}
                            setEdit={handleEdit}
                            handleDelete={handleDelete}
                          />
                        </>
                      ))}

                      {/* Mobile Section of History */}

                      <MobileHistory
                        style={{
                          margin: '5rem 0 0 0',
                        }}
                      >
                        <RightWidget>
                          <RightCards>
                            <HistoryWidget>
                              {linksResult.map((item, index) => (
                                <MobileTransCard
                                  item={item}
                                  key={index}
                                  id={item.id}
                                  pageName={item.pageName}
                                  linkType={item.paymentType}
                                  updatedAt={item.updatedAt}
                                  amount={item.amount}
                                  handleCopy={handleCopy}
                                  store={store}
                                  openDetails={toggleDetailsModal}
                                  handlePdf={handlePdf}
                                  options={options}
                                  propRefs={ref}
                                  handleSelect={setReceiptItem}
                                  toggleId={toggleId}
                                  setToggleActive={setToggleActive}
                                  setToggleId={setToggleId}
                                  toggleActive={toggleActive}
                                  toggleMenu={handleToggleActive}
                                  setEdit={handleEdit}
                                  handleDelete={handleDelete}
                                />
                              ))}
                            </HistoryWidget>
                          </RightCards>
                        </RightWidget>
                      </MobileHistory>

                      {totalPages > 1 && (
                        <PaginationWrapper>
                          <PaginationCont>
                            <p onClick={() => handleDecrement()}>
                              <Icon.ChevronLeft />
                            </p>
                            <PaginationTextWrap>
                              <h4>{page}</h4>
                              <span>of</span>
                              <h4>{totalPages}</h4>
                            </PaginationTextWrap>
                            <p onClick={() => handleIncrement()}>
                              <Icon.ChevronRight />
                            </p>
                          </PaginationCont>
                        </PaginationWrapper>
                      )}
                    </>
                  ) : (
                    <EmptyState
                      img="/invoice.svg"
                      header="Time to get paid!"
                      link="/create-payment-link/one_time"
                      linkMask="Create a payment link"
                      extraText="and get paid easily"
                    />
                  )}
                </>
              ) : (
                <CenteredAnimeWrap>
                  <MoonLoader size={40} color="#969696" />
                </CenteredAnimeWrap>
              )}
            </>
          </TableWrap>
        </BreadcrumbArea>
      </MainWrapper>

      {deleteModal ? (
        <PaymentWarning
          linkForAction={selected}
          handleAction={handleFinalDelete}
          deleteInProcess={onProcess}
          closeFunc={closeDeleteModal}
          message={'Are you sure you want to delete this payment link?'}
          actionText="Continue"
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Success!!!"
          miniMessage={'You have deleted this payment link successfully!'}
        />
      ) : null}
    </>
  );
};

export default MainContent;

interface PropsArgs {
  item: any;
  id: any;
  linkType: any;
  key: any;
  pageName: any;
  updatedAt: any;
  amount: any;
  store: any;
  handleCopy: any;
  openDetails: any;
  handlePdf: any;
  propRefs: any;
  options: any;
  handleSelect: any;
  toggleActive: any;
  toggleId: any;
  setToggleId: any;
  setToggleActive: any;
  toggleMenu: any;
  setEdit: any;
  handleDelete: any;
}

const MobileTransCard = ({
  item,
  pageName,
  key,
  id,
  linkType,
  updatedAt,
  amount,
  handleCopy,
  store,
  openDetails,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  toggleId,
  setToggleId,
  setToggleActive,
  toggleMenu,
  handleDelete,
  setEdit,
}: PropsArgs) => {
  return (
    <>
      <HistoryItemCard key={key}>
        <HistoryTextWrap>
          <h4>{pageName ? titleCase(pageName) : 'null'}</h4>
          <p>{moment(updatedAt).format('LL')}</p>
        </HistoryTextWrap>
        <HistoryInfoSec>
          <HistoryAmount>
            {linkType === 'event' ? (
              'Tickets'
            ) : (
              <>&#8358;{CommaNumber(amount)}</>
            )}
          </HistoryAmount>
        </HistoryInfoSec>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="3rem"
            onClick={e => {
              setToggleActive(false);
            }}
          >
            <p>
              <a
                href={`https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/p/${item.slug}`}
                target="_blank"
                rel="noreferrer"
              >
                Preview page
              </a>
            </p>
            <p
              onClick={e =>
                handleCopy(
                  `https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/p/${item.slug}`,
                )
              }
            >
              Copy link
            </p>
            <p onClick={() => setEdit(item)}>Edit Payment Link</p>
            <p>
              <a href="mailto:hello@trymage.com">Report issues</a>
            </p>
            <p
              style={{
                color: '#c82b38',
              }}
              onClick={() => handleDelete(item)}
            >
              Delete link
            </p>
          </MenuContent>
        ) : null}
      </HistoryItemCard>
    </>
  );
};

const TransactionCard = ({
  item,
  key,
  id,
  pageName,
  linkType,
  updatedAt,
  amount,
  store,
  handleCopy,
  openDetails,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  setToggleActive,
  toggleMenu,
  toggleId,
  setToggleId,
  handleDelete,
  setEdit,
}: PropsArgs) => {
  return (
    <>
      <TableHeader
        bg="#fff"
        border=""
        key={key}
        onClick={() => setToggleActive(false)}
      >
        <NameWrap>
          <h4>{pageName ? titleCase(pageName) : 'No name attached'}</h4>
        </NameWrap>
        <PurposeWrap>
          {'paymentType' in item ? linkType : 'No memo attached'}
        </PurposeWrap>
        <DateWrap>{moment(updatedAt).format('LL')}</DateWrap>
        <AmountWrap style={{ width: '10%' }}>
          {linkType === 'event' ? 'Tickets' : <>&#8358;{CommaNumber(amount)}</>}
        </AmountWrap>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="6rem"
            onClick={e => {
              setToggleActive(false);
            }}
          >
            <p>
              <a
                href={`https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/p/${item.slug}`}
                target="_blank"
                rel="noreferrer"
              >
                Preview page
              </a>
            </p>
            <p
              onClick={e =>
                handleCopy(
                  `https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/p/${item.slug}`,
                )
              }
            >
              Copy link
            </p>
            <p onClick={() => setEdit(item)}>Edit Payment Link</p>
            <p>
              <a href="mailto:hello@trymage.com">Report issues</a>
            </p>
            <p
              style={{
                color: '#c82b38',
              }}
              onClick={() => handleDelete(item)}
            >
              Delete link
            </p>
          </MenuContent>
        ) : null}
      </TableHeader>
    </>
  );
};
