import React, { useState } from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
  InputWrap,
  ReportActionBtn,
  ReportBtn,
} from './../../transactions/style';
import axios from 'axios';
import Alert from '../../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';

interface PropsArgs {
  props: any;
  closeFunc: any;
  header: any;
  subText?: any;
}

const ReportInvoice = ({ props, closeFunc, header, subText }: PropsArgs) => {
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const apiPayload = {
    invoice: props.id,
    // amountPaid: props.amountPaid,
    // status: props.status,
    reasonForReport: reason,
    description: description,
  };

  const handleSendIssue = async () => {
    setOnProcess(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/issue`,
        apiPayload,
        option,
      )
      .then(res => {
        setOnProcess(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          closeFunc();
        }, 4000);
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const closeModal = () => {
    setError('');
    setSuccess(false);
  };

  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3>{header}</h3>
            <p>{subText}</p>
          </HeaderWrap>
          <ModalMain>
            <InputWrap>
              <label>Reason for reporting</label>
              <input
                type="text"
                placeholder="Reason"
                onChange={e => setReason(e.target.value)}
              />
            </InputWrap>
            <InputWrap>
              <label>Additional Information</label>
              <textarea
                onChange={e => setDescription(e.target.value)}
              ></textarea>
            </InputWrap>
            <ReportActionBtn>
              <ReportBtn bg="" border="" cl="" onClick={() => closeFunc()}>
                Cancel
              </ReportBtn>
              <ReportBtn
                bg="#c82b38"
                border="none"
                cl="#fff"
                onClick={() => handleSendIssue()}
              >
                {onProcess ? <BeatLoader /> : 'Submit'}
              </ReportBtn>
            </ReportActionBtn>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>

      {error !== '' ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occurred!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Sent successfully!!!"
          miniMessage={
            'Your issue have been sent successfully. It will be addressed as soon as possible.!  '
          }
        />
      ) : null}
    </>
  );
};

export default ReportInvoice;
