import styled from 'styled-components';

const InputElement = ({ inputType, defaultText, ...rest }: any) => {
  return (
    <section>
      <Input
        type={inputType}
        placeholder={defaultText}
        required={!rest.required ? true : rest.required}
        autoComplete={inputType === 'password' ? 'off' : 'on'}
        {...rest}
      />
    </section>
  );
};

const Input = styled.input`
  padding: 0.8rem 3%;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 5px;
  margin: 0 0 1rem 0;
  border-radius: 5px;
  font-size: 14px;

  :focus {
    border: 0.5px solid #757575;
  }

  @media (max-width: 728px) {
    padding: 0.8rem 3%;
    font-size: 16px;
  }
`;

export default InputElement;
