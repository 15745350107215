// import React, {useState} from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  CloseBtn,
  Flexed,
  LeftPart,
  RightPart,
  Navigation,
  InfoText,
  AlignedFlex,
  BackNavigate,
} from './style';
import * as Icon from 'react-feather';

const PaymentMethod = ({ closeFunc, prevFunc, otherOption, amount }) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Flexed>
            <LeftPart>
              <Header style={{ fontSize: '0.9rem' }}>
                <BackNavigate onClick={() => prevFunc()}>
                  <Icon.ArrowLeft />
                </BackNavigate>
                Select your payment method
              </Header>
              <div>
                <Navigation customBg="rgba(200, 43, 50, 0.08)">
                  Debit card
                </Navigation>
                <Navigation customBg="" onClick={() => otherOption()}>
                  Bank transfer
                </Navigation>
              </div>
            </LeftPart>
            <RightPart>
              <InfoText>
                Enter your card information to fund your Mage wallet with N
                {amount}
              </InfoText>
              <InputWrap>
                <label>CARD NUMBER</label>
                <input type="number" placeholder="1234 5678 1906 1230" />
              </InputWrap>
              <InputWrap>
                <label>VALID TILL</label>
                <input type="number" maxLength={5} placeholder="mm/yy" />
              </InputWrap>
              <InputWrap>
                <label>CVV</label>
                <input type="number" maxLength={3} placeholder="cvv" />
              </InputWrap>
              <AlignedFlex>
                <input type="checkbox" />
                <p>Remember card for next time.</p>
              </AlignedFlex>
            </RightPart>
          </Flexed>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default PaymentMethod;
