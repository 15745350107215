import React from 'react';
import MainContent from 'app/components/split/editFirstStep';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const EditSplitFirstPage = () => {
  return (
    <>
      <MainWrapper
        className="no-scrollbar"
        style={{
          background: '#fff',
        }}
      >
        <MainContent />
      </MainWrapper>
    </>
  );
};

export default EditSplitFirstPage;
