import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { BeatLoader } from 'react-spinners';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
} from '../dashboard/mainContent/fundWallet/style';
import CommaNumber from 'comma-number';
import { Disclaimer } from './style';
import { SplitAvatar, SplitUsersWrap } from './style';
import axios from 'axios';
import { getSign } from 'utils/getCurrencySign';

const ApproveWarning = ({
  handleAction,
  actionInProcess,
  closeFunc,
  balance,
  splitName,
  actionText,
  error,
  selectedCollaborator,
  currency,
}) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const [fee, setFee] = useState<any>({});

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/fees`, {
        headers: { Authorization },
      })
      .then(res => {
        if (res.data) {
          setFee(res.data);
        }
      })
      .catch(err => {});
  }, []);

  return (
    <ModalArea
      style={{
        zIndex: '100000000000000',
      }}
    >
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <h3
            style={{
              width: '20rem',
              margin: '2rem 0 0 0',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            {selectedCollaborator.length > 0
              ? 'Are you sure you want to approve this Split?'
              : 'Approve splits to all recipients?'}
          </h3>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              color: '#757575',
              fontSize: '12px',
              margin: '0.5rem 0',
            }}
          >
            <Icon.Zap
              size={15}
              style={{
                position: 'relative',
                top: '0.05rem',
              }}
            />
            On Approval
          </p>
          {selectedCollaborator.length > 0 ? (
            selectedCollaborator.map((collab, index) => (
              <SplitUsersWrap
                style={{
                  margin: '1rem 0',
                }}
                key={index}
              >
                <SplitAvatar>{collab.name ? collab.name[0] : ''}</SplitAvatar>
                <div
                  style={{
                    width: '58%',
                  }}
                >
                  <h3>
                    {collab.name}{' '}
                    {loggedUser.user.id === collab.id ? (
                      <span> . Owner</span>
                    ) : null}
                  </h3>
                  <p>{collab.email}</p>
                </div>
                <p>
                  {collab.percentage ? `${collab.percentage}%` : null}
                  {collab.amount ? (
                    <span>
                      {' '}
                      {getSign(currency)}
                      {CommaNumber(collab.amount)}
                    </span>
                  ) : null}
                </p>
              </SplitUsersWrap>
            ))
          ) : (
            <>
              <h3
                style={{
                  width: '20rem',
                  margin: '1rem 0 0 0',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontWeight: 600,
                }}
              >
                {splitName}
              </h3>
              <p
                style={{
                  textAlign: 'center',
                  color: '#757575',
                  fontSize: '12px',
                  margin: '0.5rem 0',
                }}
              >
                {CommaNumber(balance)} NGN
              </p>
            </>
          )}

          <Disclaimer>
            <Icon.Info />
            <div>
              <p>
                A fee of &#8358;{Number(fee.outboundTransfer)} will be charged
                for this split. Recipients will receive their split accordingly.
              </p>
            </div>
          </Disclaimer>
          {error ? (
            <p
              style={{
                width: '20rem',
                color: '#c82b32',
                fontSize: '12px',
                margin: '1rem 0 0 0',
              }}
            >
              {error}
            </p>
          ) : null}
          <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
              }}
            >
              {'Cancel'}
            </button>
            <button
              onClick={() => handleAction()}
              style={{ width: '9rem', margin: '2rem 0 0 0' }}
            >
              {actionInProcess ? <BeatLoader color="#fff" /> : actionText}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default ApproveWarning;
