import { useState, useEffect } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import {
  TableWrap,
  TableHeader,
  PurposeWrap,
  DateWrap,
  StatusWrap,
  MenuWrap,
  MenuContent,
  SearchPos,
} from 'app/components/transactions/style';
import {
  HeaderPopUp,
  SingleFlex,
  SplitImage,
  // CollaboratorSpace,
  MenuHeader,
  MenuHeaderText,
  ScrollDiv,
} from '../split/style';
import {
  ButtonWrap,
  CustomButtonDark,
  ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
import EditSplit from '../split/editSplit';
import Details from '../transactions/details';
import DeleteWarning from '../split/deleteWarning';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import Alert from '../dashboard/mainContent/fundWallet/alert';

import TransactionDetails from '../split/transactionDetail';
import CommaNumber from 'comma-number';
import WithdrawWarning from '../split/withdrawWarning';
import uuid from 'react-uuid';

import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import FundSplitAmount from '../split/fundAmount';
import { Header } from '../dashboard/mainContent/fundWallet/style';
import 'react-multi-carousel/lib/styles.css';
import EmptyState from '../emptyState/emptyState';
import AllCollaborators from '../split/allCollaborators';
import { FlexedApart } from '../storeComp/sideNav/style';
import { MusicInfo } from '../distributors/style';
import moment from 'moment';

import { getSign } from 'utils/getCurrencySign';
import { getRequest } from 'utils/request';

const MainContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const userBankAccount = JSON.parse(
    localStorage.getItem('accountInfo') || '{}',
  );

  const [showCollaborators, setShowCOllaborators] = useState(false);
  const [activeHeader, setActiveHeader] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openTransactionDetails, setOpenTransactionDetails] = useState(false);

  // Note: will be used later
  const [showFundSplit, setShowFundSplit] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const mainData = {} as any;
  const artistName = [] as any;
  const balanceInfo = {} as any;
  const accountInfo = {} as any;
  const collaborators = [] as any;

  // Note: will be used later
  const payments = [];
  const loadedTransaction = [];
  const [approvedSuccess, setApprovedSuccess] = useState(false);
  const [toggleId, setToggleId] = useState('');
  const [toggleActive, setToggleActive] = useState(false);
  const currency = '';

  // Handle Delete
  const [deleteInProcess, setDeleteInProcess] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [benefitName, setBenefitName] = useState('');
  const [benefitBalance, setBenefitBalance] = useState('');

  // Functions

  // get single Benefit by Id

  useEffect(() => {
    getRequest(`benefits/${id}?include=benefitType`)
      .then(res => {
        setBenefitName(res.benefitType.name);
        setBenefitBalance(res.cashFlow.balance);
      })
      .catch(_err => {});
  }, [id]);

  const filteredTransactions = loadedTransaction.filter(
    (transaction: any) => !(transaction.splitFee && transaction.amount === 0),
  );

  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);

  // Get Transactions Information

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const handleClose = () => {
    setError('');
    setSuccess(false);
    setWithdrawWarning(false);
    setWithdrawSuccess(false);
    setApprovedSuccess(false);
  };

  const handleDelete = async () => {
    setDeleteInProcess(true);
    await axios
      .delete(`${process.env.REACT_APP_BASE_ENDPOINT}/benefits/${id}`, headers)
      .then(_res => {
        setSuccess(true);
        navigate('/split-payment');
        setDeleteInProcess(false);
      })
      .catch(err => {
        setError(err.response.data.message);
        setDeleteInProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  // Handle Actions
  const [showActionPop, setShowActionPop] = useState(false);
  const [withdrawWarning, setWithdrawWarning] = useState(false);
  const [onProcess, setOnProcess] = useState(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);

  const payload = {
    amount: balanceInfo.balance - 100,
    bankId: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA',
    accountNumber: userBankAccount.accountInfo.accountNumber,
    idempotentKey: uuid(),
    purpose: 'withdraw split to account',
  };

  const withdrawBalAction = async () => {
    setOnProcess(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/${mainData.id}/withdrawal`,
        payload,
        headers,
      )
      .then(_res => {
        setWithdrawSuccess(true);
        setOnProcess(false);
      })
      .catch(err => {
        setError(err.response.data.message);
        setOnProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  // Display single item transaction from split payout
  const openSplitTransact = (_item, index) => {
    setOpenDetails(true);
    setOpenTransactionDetails(false);
    setSelected({
      accountName: selectedTransaction.name,
      amount: selectedTransaction.trxRecords[index].amount,
      meta: {
        accountName: selectedTransaction.name,
        fee: 0,
        bankName:
          selectedTransaction.splitData.collaboratorId.accountInfo.bankName,
        accountNumber:
          selectedTransaction.splitData.collaboratorId.accountInfo
            .accountNumber,
      },
      type: 'debit',
      updatedAt: selectedTransaction.trxRecords[index].updatedAt,
      purpose: 'Split payout',
    });
  };

  const handleToggleActive = id => {
    setToggleActive(true);
    setToggleId(id);
  };

  return (
    <>
      {withdrawWarning ? (
        <WithdrawWarning
          closeFunc={() => setWithdrawWarning(false)}
          handleAction={withdrawBalAction}
          balance={balanceInfo.balance}
          actionText={'Withdraw'}
          actionInProcess={onProcess}
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={handleClose}
          img="/failed.png"
          message="An error occurred"
          miniMessage={error}
        />
      ) : null}

      {approvedSuccess ? (
        <Alert
          closeFunc={() => {
            handleClose();
          }}
          img="/success.png"
          message="Approval successful!"
          miniMessage={'Funds are on its way to recipients'}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={handleClose}
          img="/success.png"
          message="Split deleted successfully"
          miniMessage={'You have successfully deleted your split.'}
        />
      ) : null}

      {withdrawSuccess ? (
        <Alert
          closeFunc={handleClose}
          img="/success.png"
          message="Withdraw success"
          miniMessage={`Your Mage Account has been credited with ${CommaNumber(
            balanceInfo.balance,
          )}`}
        />
      ) : null}

      {openDetails ? (
        <Details props={selected} closeFunc={() => setOpenDetails(false)} />
      ) : null}

      {openTransactionDetails ? (
        <TransactionDetails
          props={selectedTransaction}
          closeFunc={() => {
            setOpenTransactionDetails(false);
            setSelectedTransaction([]);
          }}
          openFunc={openSplitTransact}
        />
      ) : null}

      {openEdit ? (
        <EditSplit
          closeFunc={() => setOpenEdit(false)}
          id={mainData.id}
          name={mainData.name}
          startDate={mainData.startDate}
          endDate={mainData.endDate}
          type={mainData.splitType}
          frequency={mainData.frequency}
          limitValue={mainData.limitValue}
        />
      ) : null}

      {openDelete ? (
        <DeleteWarning
          handleAction={() => handleDelete()}
          deleteInProcess={deleteInProcess}
          closeFunc={() => setOpenDelete(false)}
          message={'Are you sure you want to delete this Benefit?'}
          actionText={'Delete Benefit'}
          splitName={mainData.name}
        />
      ) : null}

      <MainWrapper
        padding="1px"
        style={{ width: '100%' }}
        left="0"
        top="0rem"
        onClick={() => {
          setShowActionPop(false);
        }}
      >
        <FragmentHeader
          showBack={true}
          pageName={mainData.name}
          backLink="/split-payment"
        />
        <SearchPos position="fixed">
          <>
            <ButtonWrap mobileHide={false} style={{ gap: '10px' }}>
              <CustomButtonDark
                onClick={e => {
                  setShowActionPop(!showActionPop);
                  e.stopPropagation();
                }}
              >
                <ButtonFlex>
                  Action
                  <Icon.ChevronDown
                    size={16}
                    style={{
                      position: 'relative',
                      left: '0.5rem',
                    }}
                  />
                </ButtonFlex>
              </CustomButtonDark>
            </ButtonWrap>
          </>
          {showActionPop ? (
            <HeaderPopUp>
              <p onClick={() => setOpenDelete(true)}>Delete Benefit</p>
            </HeaderPopUp>
          ) : null}
        </SearchPos>
        <BreadcrumbArea
          style={{
            marginTop: '0rem',
          }}
        >
          <SingleFlex
            style={{
              height: '90.1vh',
              marginTop: '4.6rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid #ebe6e6',
                  padding: '20px',
                }}
              >
                <FlexedApart>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        color: '#828282',
                      }}
                    >
                      Wallet
                    </p>
                    <span
                      style={{
                        padding: '10px',
                        backgroundColor: '#f2f2f2',
                        borderRadius: '30px',
                      }}
                    >
                      10.0%
                    </span>
                  </div>
                </FlexedApart>
                <div style={{ width: '100%' }}>
                  <h3
                    style={{
                      marginTop: '10px',
                      fontSize: '28px',
                      fontWeight: '700',
                      margin: '0 0 1rem 0',
                    }}
                  >
                    ₦{CommaNumber(benefitBalance)}
                  </h3>
                  <p>Benefit Name: {benefitName || ''}</p>
                </div>
              </div>

              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <SplitImage
                  src={
                    mainData.avatar
                      ? mainData.avatar
                      : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                  }
                  alt="Display"
                />
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Header
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    {mainData.name}
                  </Header>
                  <p style={{ marginTop: '-30px' }}>{artistName.artistName}</p>
                  <p style={{ marginTop: '110px' }}>{}</p>
                </div>
              </div>
            </div>

            <div>
              <FlexedApart
                style={{
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    cursor: 'pointer',
                    fontWeight: '400',
                  }}
                  onClick={() => setShowCOllaborators(false)}
                ></p>
              </FlexedApart>

              <MenuHeader
                style={{
                  margin: '3.5rem 0 0 0',
                }}
              >
                {filterArray.map((item, index) => (
                  <MenuHeaderText
                    key={index}
                    onClick={() => setActiveHeader(index)}
                    color={activeHeader === index ? '#161616' : '#b0b0b0'}
                    border={
                      activeHeader === index ? '3px solid #191919' : 'none'
                    }
                  >
                    {item.name}
                  </MenuHeaderText>
                ))}
              </MenuHeader>
              <TableWrap>
                <TableHeader
                  bg=""
                  border="none"
                  style={{ margin: '1rem 0 0 0' }}
                >
                  <MenuWrap>#</MenuWrap>
                  <MusicInfo width="30%">Payout</MusicInfo>
                  <PurposeWrap>%Royalties</PurposeWrap>
                  <DateWrap>Date</DateWrap>
                  <StatusWrap>Status</StatusWrap>
                  <DateWrap>Amount</DateWrap>
                  <MenuWrap></MenuWrap>
                </TableHeader>

                <ScrollDiv>
                  {activeHeader === 0 ? (
                    <>
                      {payments && payments.length > 0 ? (
                        <>
                          {payments.map((item: any, index) => (
                            <>
                              <TransactionCard
                                item={item}
                                key={index}
                                index={index}
                                name={item.meta.payerName}
                                image={item.albumArt}
                                artist={item.artistName}
                                status={item.meta.transactionType}
                                createdAt={item.createdAt}
                                updatedAt={item.updatedAt}
                                amount={item.amount}
                                toggleId={toggleId}
                                setToggleId={setToggleId}
                                toggleActive={toggleActive}
                                toggleMenu={handleToggleActive}
                              />
                            </>
                          ))}
                        </>
                      ) : (
                        <EmptyState
                          img="/split-empty.png"
                          header={`No Benefits Records Available`}
                          extraText=" "
                          link=" "
                          linkMask=" "
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {filteredTransactions &&
                      filteredTransactions.length > 0 ? (
                        <>
                          {filteredTransactions.map(
                            (transaction: any, index: number) => (
                              <>
                                <TableHeader
                                  key={transaction.id}
                                  bg="#fff"
                                  border=""
                                >
                                  <MenuWrap>{index + 1}</MenuWrap>
                                  <MusicInfo width="30%">
                                    {transaction.meta.accountName}
                                  </MusicInfo>
                                  <PurposeWrap>
                                    {transaction.splitData &&
                                    transaction.splitData.percentage != null
                                      ? `${transaction.splitData.percentage}%`
                                      : '100%'}
                                  </PurposeWrap>
                                  <DateWrap>
                                    {moment(transaction.createdAt).format('LL')}
                                  </DateWrap>
                                  <StatusWrap>
                                    <span
                                      style={{
                                        padding: '5px',
                                        border:
                                          transaction.status === 'Pending'
                                            ? '1px solid ##45a1bd'
                                            : '1px solid green',

                                        borderRadius: '5px',
                                        backgroundColor:
                                          transaction.status === 'Pending'
                                            ? '#6ac2dd68'
                                            : 'rgba(204, 234, 185, 0.30)',

                                        color:
                                          transaction.status === 'Pending'
                                            ? '#4fadc9'
                                            : 'green',
                                      }}
                                    >
                                      {transaction.status}
                                    </span>
                                  </StatusWrap>
                                  <DateWrap>
                                    {getSign(transaction.currency)}
                                    {CommaNumber(transaction.amount)}
                                  </DateWrap>
                                  <MenuWrap></MenuWrap>
                                </TableHeader>
                              </>
                            ),
                          )}
                        </>
                      ) : (
                        <EmptyState
                          img="/split-empty.png"
                          header="No Payout Data Available"
                          extraText=" "
                          link=" "
                          linkMask=" "
                        />
                      )}
                    </>
                  )}
                </ScrollDiv>
              </TableWrap>
            </div>
          </SingleFlex>
        </BreadcrumbArea>
      </MainWrapper>

      {showFundSplit ? (
        <FundSplitAmount
          closeFunc={() => setShowFundSplit(false)}
          splitId={id}
          accountInfo={accountInfo}
          splitName={mainData.name}
          loadAction={''}
          currency={currency}
          bankName={undefined}
          accountName={undefined}
          accountNo={undefined}
          showAccount={undefined}
        />
      ) : null}
      {showCollaborators ? (
        <AllCollaborators
          closeFunc={() => {
            setShowCOllaborators(false);
          }}
          collaborators={collaborators}
        />
      ) : null}
    </>
  );
};

export default MainContent;

const filterArray = [
  {
    name: 'Money In',
  },
  {
    name: 'Money Out',
  },
];

interface PropsArgs {
  item: any;
  key: number;
  index?: number;
  updatedAt?: any;
  createdAt?: string;
  artist?: string;
  image?: string;
  name?: string;
  status?: any;
  id?: any;
  invoiceNo?: any;
  amount?: any;
  toggleActive: any;
  toggleId: any;
  setToggleId: any;
  toggleMenu: any;
}

const TransactionCard = ({
  item,
  key,
  index,
  id,
  updatedAt,
  image,
  name,

  createdAt,

  artist,
  status,
  amount,
  invoiceNo,
  toggleActive,
  toggleId,
  setToggleId,
  toggleMenu,
}: PropsArgs) => {
  return (
    <>
      <TableHeader bg="#fff" border="" key={item.id}>
        <MenuWrap>{Number(index) + 1}</MenuWrap>
        <MusicInfo width="30%">
          <div>
            <h3>{name}</h3>
          </div>
        </MusicInfo>
        <PurposeWrap>100%</PurposeWrap>
        <DateWrap>{moment(createdAt).format('LL')}</DateWrap>
        <StatusWrap>
          <span
            style={{
              padding: '5px',
              border: '1px solid green',
              borderRadius: '5px',
              backgroundColor: 'rgba(204, 234, 185, 0.30)',
              color: 'green',
            }}
          >
            {status}
          </span>
        </StatusWrap>
        <DateWrap>
          {getSign(item.currency)}
          {CommaNumber(amount)}
        </DateWrap>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="2rem"
            onClick={e => {
              setToggleId(false);
              e.stopPropagation();
            }}
            style={{
              color: '#161616',
            }}
          ></MenuContent>
        ) : null}
      </TableHeader>
    </>
  );
};
