import React, { useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { AccountNoCenter, InputWrap, ProgressBar, ProgressLine } from './style';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { useNavigate } from 'react-router-dom';
import { StoreAvatar, ImageSetUp } from '../invoice/mainInvoice/style';
import PopUpModal from '../merch/popupModal';
import { handleFileUpload } from 'utils/imageUpload';

const MainContent = () => {
  const navigate = useNavigate();
  const [musicName, setMusicName] = useState('');

  const [error, setError] = useState('');
  const [images, setImages] = useState<any>([]);
  const [returnedImages, setReturnedImages] = useState<any>('');

  const meta = {
    musicName,
    isrc: '',
    avatar: returnedImages,
  };

  const toNext = e => {
    e.preventDefault();
    localStorage.setItem('music', JSON.stringify(meta));
    navigate('/add-collaborators?type=project');
  };

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setImages,
      setError,
      setReturnedImages,
    });
  };

  const handleClose = () => {
    setError('');
  };

  return (
    <>
      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      <MainWrapper style={{ width: '100%' }} left="0" top="4.5rem">
        <FragmentHeader showBack={true} pageName={`Project Split`} />
        <BreadcrumbArea
          style={{
            margin: '4rem 0 10rem 0',
          }}
        >
          <form onSubmit={toNext}>
            <ProgressBar>
              <ProgressLine bg="#191919"></ProgressLine>
              <ProgressLine></ProgressLine>
              <ProgressLine></ProgressLine>
            </ProgressBar>
            <AccountNoCenter
              style={{
                margin: '0',
              }}
            >
              <div>
                <h3>Customize your Project</h3>
                <p
                  style={{
                    margin: '0',
                    padding: '0.5rem 0',
                  }}
                >
                  Add a name and description to your project
                </p>
              </div>
            </AccountNoCenter>
            <ImageSetUp>
              {images && images.length > 0 && returnedImages === '' ? (
                <StoreAvatar src={URL.createObjectURL(images[0])} />
              ) : null}

              {images.length < 1 && returnedImages === '' ? (
                <StoreAvatar src="http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png" />
              ) : null}

              {returnedImages !== '' ? (
                <StoreAvatar src={returnedImages} />
              ) : null}

              <input
                id="main-input"
                type="file"
                multiple={false}
                style={{ display: 'none' }}
                onChange={(e: any) => {
                  handleFile(e.target.files);
                }}
              ></input>
            </ImageSetUp>

            <MainFlex
              style={{
                margin: '0',
                top: 0,
                padding: 0,
              }}
            >
              <PairSection width="35%" mobileMargin="0">
                <div
                  style={{
                    boxShadow: 'none',
                    background: 'none',
                  }}
                >
                  <InputWrap>
                    <label>Project Title</label>
                    <input
                      onChange={(e: any) => setMusicName(e.target.value)}
                      value={musicName}
                      maxLength={20}
                      required
                    />
                  </InputWrap>

                  <InputWrap>
                    <label>Project Description</label>
                    <textarea required />
                  </InputWrap>

                  <BigBtn
                    style={{
                      width: '100%',
                    }}
                  >
                    <button type="submit">Continue</button>
                  </BigBtn>
                </div>
              </PairSection>
            </MainFlex>
          </form>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default MainContent;

export const splitNav = [
  {
    name: 'Percentage',
    borderRadius: '4px 0 0 4px',
  },
  {
    name: 'Amount',
    borderRadius: '0 4px 4px 0',
  },
];
