import {
  InfoNumber,
  // ActiveBar,
  // DesktopShow,
  // BelowCardWrappers,
  ToggleBalanceWrap,
} from './style';
import { useNavigate } from 'react-router';
import ReactCountryFlag from 'react-country-flag';
import CommaNumber from 'comma-number';
import * as Icon from 'react-feather';
import { getSign } from 'utils/getCurrencySign';
import styled from 'styled-components';

interface PropsArgs {
  currencyTag: string;
  currencyName: string;
  isShowing: boolean;
  index: number;
  setActiveIndex: any;
  activeIndex: number;
  balance: number | string | any;
  handleToggle: any;
  toggleBank;
}

const NewWalletCard = ({
  currencyTag,
  currencyName,
  isShowing,
  balance,
  index,
  setActiveIndex,
  activeIndex,
  handleToggle,
  toggleBank,
}: PropsArgs) => {
  const navigate = useNavigate();
  const balanceSplit = balance.toString().split('.');
  return (
    <>
      <InfoCards
        customBg={'#000000'}
        onClick={e => {
          navigate('/transactions');
          localStorage.setItem('activeTag', JSON.stringify(currencyTag));
        }}
      >
        <BalanceFlags>
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <ReactCountryFlag
              style={{
                fontSize: '1.3rem',
                borderRadius: '50%',
              }}
              countryCode={currencyTag}
              svg
              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
              cdnSuffix="svg"
            />
            <p>{currencyName}</p>
          </div>
          <PercentageNumber>
            <Icon.ArrowDown color="red" size={14} />
            <span>0.00%</span>
          </PercentageNumber>
        </BalanceFlags>
        <InfoNumber>
          <CurrencyAmount>
            {isShowing ? (
              <>
                <SubscriptCurrencySign>
                  {getSign(currencyName)}
                </SubscriptCurrencySign>
                {CommaNumber(balanceSplit[0]) || '0'}
                <DecimalPart>.{balanceSplit[1] || '0'}</DecimalPart>
              </>
            ) : (
              '***'
            )}
          </CurrencyAmount>

          <ToggleBalanceWrap
            style={{
              padding: '7px',
              borderRadius: '7px',
              background: 'rgba(207, 207, 207, 0.123)',
            }}
          >
            <div
              onClick={e => {
                handleToggle();
                e.stopPropagation();
                setActiveIndex(index);
              }}
            >
              {isShowing ? (
                <Icon.EyeOff
                  style={{
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <Icon.Eye
                  style={{
                    cursor: 'pointer',
                  }}
                />
              )}
            </div>
          </ToggleBalanceWrap>
        </InfoNumber>
      </InfoCards>
    </>
  );
};

export default NewWalletCard;

const SubscriptCurrencySign = styled.sup`
  font-size: 14px;
  font-weight: 400;
  color: #161616;
`;

const CurrencyAmount = styled.h1`
  font-size: 22px;
  font-weight: 700;
  display: inline-block;
  margin-right: 4px;
`;

const DecimalPart = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: #757575;
`;

interface Props {
  customBg: any;
}

const InfoCards = styled.div<Props>`
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  margin: 0 auto 0.5rem auto;
  height: 8rem;
  box-shadow: 0 0 1.5px #cbced1;
  background: #fff;
  cursor: pointer;

  :hover {
    opacity: 0.7;
    color: #000000;
  }

  @media (max-width: 1028px) {
    width: 95%;
    margin: 0 20px 1rem 0;

    :hover {
      background: #ffffff;
      color: #000000;
    }

    :hover h2 {
      color: #000000;
    }

    :hover h1 {
      color: #000000;
    }
  }
`;

const BalanceFlags = styled.div`
  display: flex;
  align-items: Center;
  gap: 5px;
  display: flex;
  justify-content: space-between;

  > p {
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #474747;
  }
`;

const PercentageNumber = styled.div`
  width: auto;
  padding: 3px;
  padding-right: 7px;
  padding-left: 7px;
  display: flex;
  border-radius: 50px;
  background: rgba(255, 0, 0, 0.1);
  align-items: center;
  justify-content: center;

  span {
    color: red;
    font-size: 12px;
  }
`;
