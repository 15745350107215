import styled from 'styled-components';

export const SuccessContainer = styled.div`
  width: 100%;
  height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
`;

export const AprrovePayment = styled.div`
  width: 7rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: 1px solid #34c77d;
    background-color: #16b464;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 3rem;
    }
  }
`;

export const ApproveCongratulation = styled.div`
  width: 60%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 728px) {
    width: 100%;
  }

  h3 {
    color: #161616;
    text-align: center;
    font-size: 30px;
    font-weight: 700;

    @media (max-width: 728px) {
      font-size: 20px;
    }
  }
`;

export const ReferenceNumber = styled.div`
  width: auto;
  height: auto;

  p {
    color: #64676c;
    text-align: center;
    font-size: 20px;
    font-weight: 500;

    @media (max-width: 728px) {
      font-size: 16px;
    }
  }
`;

export const BorderBottom = styled.div`
  width: 100%;
  border-bottom: 1px dashed black;
`;

export const MoreFeatures = styled.div`
  width: 100%;
  height: 5.5rem;
  padding: 8px;
  border-radius: 8px;
  border: 0.2px solid #ababab;
  background-color: white;
`;

export const FeatureItem = styled.div`
  width: auto;
  height: auto;
  display: flex;
  justify-content: space-between;
`;

export const Holder = styled.div`
  display: flex;
  gap: 10px;
`;

export const ImageFeatures = styled.div`
  width: 4.5rem;
  border-radius: 8px;

  img {
    width: 4.5rem;
    height: 4rem;
    object-fit: cover;
  }
`;

export const FeaturedText = styled.div`
  width: auto;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;

  p {
    color: #161616;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;

    @media (max-width: 728px) {
      font-size: 14px;
    }
  }

  h3 {
    color: #ababab;
    text-align: center;
    font-size: 20px;
    font-weight: 400;

    @media (max-width: 728px) {
      font-size: 12px;
    }
  }

  span {
    color: #64676c;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
`;
