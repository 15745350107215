import React from 'react';
import * as Icon from 'react-feather';
import { BeatLoader } from 'react-spinners';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
} from 'app/components/dashboard/mainContent/fundWallet/style';
import {
  ProductInfo,
  ProductImg,
} from 'app/components/merch/productList/styles';
import { CustomerName } from 'app/components/orderComp/mainContent/orderContent/styles';
import { FlexedApart } from 'app/components/storeComp/sideNav/style';
import { Centered } from './style';

const Warning = ({
  productForDelete,
  handleDelete,
  deleteInProcess,
  closeFunc,
  header,
  message,
  actionText,
}) => {
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <Centered>
            <img
              src="/warning.png"
              alt="Warning Icon"
              style={{ width: '2rem', height: '2rem' }}
            />
          </Centered>
          <h3
            style={{
              fontWeight: 700,
              fontSize: '16px',
              textAlign: 'center',
              margin: '1rem 0 0 0',
              color: '#161616',
            }}
          >
            {header}
          </h3>
          <p
            style={{
              width: '20rem',
              margin: '1rem 0 0 0',
              textAlign: 'center',
              fontSize: '12px',
              color: '#161616',
            }}
          >
            {message}
          </p>
          <br />{' '}
          {productForDelete ? (
            <FlexedApart>
              <ProductInfo style={{ justifyContent: 'center' }}>
                {productForDelete && productForDelete !== '' ? (
                  <ProductImg src={productForDelete.images[0]} />
                ) : null}
                {productForDelete && productForDelete !== '' ? (
                  <div>
                    <CustomerName>{productForDelete.name}</CustomerName>
                    <p>{productForDelete.quantity} Items in stock.</p>
                  </div>
                ) : null}
              </ProductInfo>
            </FlexedApart>
          ) : null}
          <ActionBtn style={{ justifyContent: 'center' }}>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
                margin: '2rem 1rem 0 0',
              }}
            >
              {'Cancel'}
            </button>
            <button onClick={() => handleDelete()} style={{ width: '9rem' }}>
              {deleteInProcess ? <BeatLoader /> : actionText}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default Warning;
