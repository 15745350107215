import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../invoice/mainInvoice/style';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from './../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import {
  SubHeader,
  SubHeaderChild,
  UserAvatarWrap,
  UserCont,
  UserInfo,
} from './../dashboard/mainContent/fundWallet/style';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import * as Icon from 'react-feather';
import { useSearchParams } from 'react-router-dom';
import { getRequest } from 'utils/request';

const BeneficiaryComp = () => {
  const navigate = useNavigate();
  const { amount, sourceAmount, point } = useParams();

  const [searchParams] = useSearchParams();
  const currencyPair = searchParams.get('currencyPair');
  const exchangeType = searchParams.get('exchangeType');
  const [beneficiaryData, setBeneficiaryData] = useState<any>([]);

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getBeneficiaries = () => {
    getRequest(
      `payments/beneficiaries?paginate=true&limit=80&sortBy=createdAtDesc`,
    )
      .then(res => {
        setBeneficiaryData(res.results);
      })
      .catch(err => {});
  };

  useEffect(() => {
    getBeneficiaries();
  }, [getBeneficiaries]);

  const selectFreqBen = async item => {
    await localStorage.setItem('selectedFreqBen', JSON.stringify(item));
    navigate(
      `/ngn-transfer/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}`,
    );
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  // Remove Beneficiary from Client side
  const removeBen = async (item, index) => {
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/payments/beneficiaries/${item.id}`,
        headers,
      )
      .then(res => {
        beneficiaryData.splice(index, 1);
        setBeneficiaryData([...beneficiaryData]);
      })
      .catch(err => {});
  };

  return (
    <>
      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
      >
        <FragmentHeader showBack={true} pageName="Send Money" />
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              top="-2rem"
              mobileTop="-5rem"
              style={{ margin: '0 auto' }}
            >
              <SubHeader>
                <SubHeaderChild
                  customBd=""
                  onClick={() =>
                    navigate(
                      `/ngn-transfer/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}`,
                    )
                  }
                >
                  Bank account details
                </SubHeaderChild>
                <SubHeaderChild customBd="#191919">
                  Beneficiaries
                </SubHeaderChild>
              </SubHeader>
              <div>
                {beneficiaryData && beneficiaryData.length > 0 ? (
                  <>
                    {beneficiaryData.map((item, index) => (
                      <UserCont key={index} onClick={e => selectFreqBen(item)}>
                        <UserInfo>
                          <UserAvatarWrap>
                            {item.accountName ? item.accountName[0] : ''}
                          </UserAvatarWrap>
                          <div
                            style={{
                              width: '85%',
                            }}
                          >
                            <h3>{item.accountName}</h3>
                            <p>
                              {item.bankName} - {item.accountNumber}
                            </p>
                          </div>
                        </UserInfo>
                        <i
                          onClick={async e => {
                            e.stopPropagation();
                            await removeBen(item, index);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <Icon.Trash2 color="#c82b32" size={16} />
                        </i>
                      </UserCont>
                    ))}
                  </>
                ) : (
                  <p
                    style={{
                      textAlign: 'center',
                      fontSize: '0.7rem',
                      fontWeight: '100',
                    }}
                  >
                    No beneficiaries.
                  </p>
                )}
              </div>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default BeneficiaryComp;
