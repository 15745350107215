import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
  InputWrap,
} from './../../transactions/style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import Select from 'react-select';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import Alert from '../../dashboard/mainContent/fundWallet/alert';
import { CustomStyles } from 'utils/selectCustomStyle';

interface PropsArgs {
  props: any;
  closeFunc: any;
  header: any;
  subText?: any;
  setFunc: any;
}

const Update = ({ props, closeFunc, header, subText, setFunc }: PropsArgs) => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);
  const [status, setStatus] = useState('');
  const [amount, setAmount] = useState('');
  const [dueDate, setDueDate] = useState('');

  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  useEffect(() => {
    setAmount(props.amountPaid);
    setStatus(props.status);
    setDueDate(props.dueDate);
  }, [props.amountPaid, props.status, props.dueDate]);

  const apiPayload = {
    status: status,
    amountPaid: amount,
    datePaid: dueDate,
  };

  const handlePatch = async () => {
    setOnProcess(true);
    await axios
      .patch(
        `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/${props.id}`,
        apiPayload,
        option,
      )
      .then(res => {
        setOnProcess(false);
        setSuccess(true);
        setFunc();
        setTimeout(() => {
          setSuccess(false);
          closeFunc();
        }, 4000);
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const closeModal = () => {
    setError('');
    setSuccess(false);
  };

  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3>{header}</h3>
            <p>{subText}</p>
          </HeaderWrap>
          <ModalMain borderTp="none" margin="none" padding="none">
            <InputWrap>
              <label>Status</label>
              <Select
                options={statusArray}
                getOptionLabel={p => p.name}
                styles={CustomStyles}
                onChange={e => setStatus((e as HTMLSelectElement).value)}
                defaultValue={statusArray.filter(
                  p => p.value === props.status.toLowerCase(),
                )}
              />
            </InputWrap>
            <InputWrap>
              <label>Amount</label>
              <input
                type="number"
                placeholder="&#8358;"
                onChange={e => setAmount(e.target.value)}
                defaultValue={props.amountPaid}
              />
            </InputWrap>
            <InputWrap>
              <label>Date Paid</label>
              <input type="date" onChange={e => setDueDate(e.target.value)} />
            </InputWrap>
            <BigBtn>
              <button onClick={() => handlePatch()}>
                {onProcess ? <BeatLoader color="#fff" /> : 'Update Invoice'}
              </button>
            </BigBtn>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>

      {error !== '' ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occurred!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Update successful!!!"
          miniMessage={'You changes have been made successfully!  '}
        />
      ) : null}
    </>
  );
};

export default Update;

const statusArray = [
  {
    name: 'PAID IN FULL',
    value: 'fully_paid',
  },
  {
    name: 'PARTIALLY PAID',
    value: 'partially_paid',
  },
];
