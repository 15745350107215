import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
} from './../../dashboard/mainContent/fundWallet/style';

const ShareDoc = ({ closeFunc }) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn
            className="fas fa-times"
            onClick={() => closeFunc()}
          ></CloseBtn>
          <Header>Share document</Header>
          <div>
            <InputWrap>
              <label>Who are you sending to?</label>
              <input type="email" placeholder="Email" />
            </InputWrap>
          </div>
          <BigBtn>
            <button>Share</button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default ShareDoc;
