import React, { useEffect, useState, createRef } from 'react';
import AppHeader from './../storeComp/mainContent/header/header';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import {
  TableWrap,
  TableHeader,
  IconWrap,
  NameWrap,
  PurposeWrap,
  DateWrap,
  AmountWrap,
  MenuWrap,
  MenuContent,
  SearchPos,
  MobileHistory,
  StatusWrap,
  ViewInfoWrapper,
  ViewInfoChild,
  PercentWrap,
} from './style';
import {
  RightWidget,
  RightCards,
  HistoryWidget,
  HistoryItemCard,
  HistoryInfoSec,
  HistoryAmount,
  HistoryTextWrap,
} from '../dashboard/mainContent/style';
import {
  handleStatusBg,
  handleColor,
} from './../invoice/mainInvoice/colorHandle';
import {
  PaginationWrapper,
  PaginationCont,
  PaginationTextWrap,
} from '../merch/productList/styles';
import { SearchArea } from '../orderComp/mainContent/sectionOne/styles';
import { HistoryItemIconWrap } from '../dashboard/mainContent/style';
import * as Icon from 'react-feather';
import { handleBg } from 'utils/handleTransIcon';
import axios from 'axios';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { MoonLoader } from 'react-spinners';
import moment from 'moment';
import ReportTransaction from './report';
import Details from './details';
import Receipt from './receipt';
import generatePDF from 'react-to-pdf';
import { titleCase } from 'utils/capitalizeFirst';
import CommaNumber from 'comma-number';
import EmptyState from '../emptyState/emptyState';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { responsive } from './overviewCarousel';
import ButtonGroup from './customArrow';
import { InAppHeader } from './../storeComp/mainContent/styles';
import ReactCountryFlag from 'react-country-flag';
import AccountStatement from './requestStatement';
import StatementPreview from './statement';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { FlexedApart } from '../merch/singleMerch/style';
import BankTransfer from '../dashboard/mainContent/fundWallet/bankTransfer';
import TransferOption from './transferOption';
import DollarBankTransfer from './dollarBankTransfer';
import DollarTransferOption from './dollarTransferption';
import {
  InputActPlacehold,
  CollabSuggestWrap,
  SplitUsersWrap,
  HeaderPopUp,
} from '../split/style';
import { MobileSelect, MobileInputWrap } from 'app/pages/SignUp/styles';
import {
  ButtonWrap,
  CustomButtonDark,
  ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import { useNavigate } from 'react-router';
import Blocked from './blocked';
import { getSign } from 'utils/getCurrencySign';

const MainContent = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [showActionPop, setShowActionPop] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [toggleActive, setToggleActive] = useState(false);
  const [toggleId, setToggleId] = useState('');
  const [showBank, setShowBank] = useState<boolean>(false);
  const [showTransferOption, setTransferOption] = useState(false);
  const [showDollarBank, setShowDollarBank] = useState(false);
  const [showDollarTransferOption, setShowDollarTransferOption] =
    useState(false);
  const [activeTag, setActiveTag] = useState('');
  const [activeCurrency, setActiveCurrency] = useState('');

  // Transaction History
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [fetching, setFetching] = useState(false);
  const [period, setPeriod] = useState('today');
  const [transactionOverview, setTransactionOverview] = useState<any>({});
  const [history, setHistory] = useState<any>('');
  const [historyResult, setHistoryResult] = useState<any>([]);

  var [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [totalResults, setTotalResults] = useState(0);

  const [bvnKycStatus, setBvnKycStatus] = useState<any>({});
  const [idKycStatus, setIdKycStatus] = useState<any>({});
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/users/kyc-status`, {
        headers: { Authorization },
      })
      .then(res => {
        setBvnKycStatus(res.data.bvn);
        setIdKycStatus(res.data.document);
      })
      .catch(err => {});
  }, [Authorization]);

  useEffect(() => {
    const savedActiveTag = JSON.parse(
      localStorage.getItem('activeTag') || '{}',
    );
    if (savedActiveTag) {
      setActiveTag(savedActiveTag);
      if (savedActiveTag === 'NG') {
        setActiveTag('NG');
        setActiveCurrency('NGN Balance');
      } else if (savedActiveTag === 'US') {
        setActiveTag('US');
        setActiveCurrency('USD Balance');
      } else if (savedActiveTag === 'GB') {
        setActiveTag('GB');
        setActiveCurrency('GBP Balance');
      } else if (savedActiveTag === 'EU') {
        setActiveTag('EU');
        setActiveCurrency('EUR Balance');
      }
    }
  }, []);

  // Get currency name from tag
  const getCurrencyName = tag => {
    try {
      switch (tag) {
        case 'NG':
          return 'NGN';
        case 'US':
          return 'USD';
        case 'GB':
          return 'GBP';
        case 'EU':
          return 'EUR';
      }
    } catch {}
  };

  const handleToggleActive = id => {
    setToggleActive(true);
    setToggleId(id);
  };

  // Get Transaction Overview

  useEffect(() => {
    if (activeTag) {
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_ENDPOINT
          }/payments/transaction-overview?period=${period}&currency=${getCurrencyName(
            activeTag,
          )}`,
          { headers: { Authorization } },
        )
        .then(res => {
          setTransactionOverview(res.data);
        })
        .catch(err => {});
    }
  }, [period, Authorization, activeTag, activeCurrency]);

  const handleIncrement = () => {
    if (page !== totalPages) {
      setPage(++page);
      setFetching(true);
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_ENDPOINT
          }/payments/transactions?include=user&page=${page}&sortBy=createdAt:desc&currency=${getCurrencyName(
            activeTag,
          )}`,
          { headers: { Authorization } },
        )
        .then(res => {
          setFetching(false);
          setHistory(res.data.results);
          setHistoryResult(res.data.results);
          setTotalPages(res.data.totalPages);
          setTotalResults(res.data.totalResults);
        })
        .catch(err => {});
    }
  };

  const handleDecrement = () => {
    if (page > 1) {
      setPage(--page);
      setFetching(true);
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_ENDPOINT
          }/payments/transactions?include=user&page=${page}&sortBy=createdAt:desc&currency=${getCurrencyName(
            activeTag,
          )}`,
          { headers: { Authorization } },
        )
        .then(res => {
          setFetching(false);
          setHistory(res.data.results);
          setHistoryResult(res.data.results);
          setTotalPages(res.data.totalPages);
          setTotalResults(res.data.totalResults);
        })
        .catch(err => {});
    }
  };

  useEffect(() => {
    if (activeTag) {
      setFetching(true);
      axios
        .get(
          `${
            process.env.REACT_APP_BASE_ENDPOINT
          }/payments/transactions?include=user&sortBy=createdAt:desc&currency=${getCurrencyName(
            activeTag,
          )}`,
          { headers: { Authorization } },
        )
        .then(res => {
          setFetching(false);
          setHistory(res.data.results);
          setHistoryResult(res.data.results);
          setTotalPages(res.data.totalPages);
          setTotalResults(res.data.totalResults);
        })
        .catch(err => {});
    }
  }, [Authorization, activeTag]);

  // Handle Search

  const [searchText, setSearchText] = useState('');
  const newSearch = () => {
    setFetching(true);
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_ENDPOINT
        }/payments/transactions?include=user&sortBy=createdAt:desc&text=${searchText}&currency=${getCurrencyName(
          activeTag,
        )}`,
        { headers: { Authorization } },
      )
      .then(res => {
        setFetching(false);
        setHistory(res.data.results);
        setHistoryResult(res.data.results);
        setTotalPages(res.data.totalPages);
        setTotalResults(res.data.totalResults);
      })
      .catch(err => {});
  };

  // Report Tab
  const [reportModal, setReportModal] = useState(false);

  // const

  const toggleReportModal = item => {
    setReportModal(!reportModal);
    setSelected(item);
  };

  const closeReportModal = () => {
    setReportModal(false);
  };

  //Details Tab
  const [detailsModal, setDetailsModal] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const [accountInfo, setAccountInfo] = useState<any>({});

  useEffect(() => {
    const accountInfo = JSON.parse(localStorage.getItem('accountInfo') || '{}');
    setAccountInfo(accountInfo.accountInfo);
  }, []);

  const toggleDetailsModal = item => {
    setDetailsModal(!detailsModal);
    setSelected(item);
  };

  // PDF Download

  const ref: any = createRef();
  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [11, 7],
  };

  const handlePdf = item => {
    setSelected(item);
  };

  const setReceiptItem = item => {
    setSelected(item);
  };

  // Account Statement Section
  const [openStatementModal, setOpenStatementModal] = useState(false);

  // Account Statement States
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const statementPayload = {
    startDate,
    endDate,
    meta: {
      startDate,
      endDate,
      accountNumber: accountInfo ? accountInfo.accountNUmber : '',
      fullName: loggedUser.user.firstName + ' ' + loggedUser.user.lastName,
    },
  };

  const handleSendStatement = e => {
    e.preventDefault();
    setOnProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/payments/account-statement`,
        statementPayload,
        headers,
      )
      .then(res => {
        setSuccess(true);
        setOnProcess(false);
        setOpenStatementModal(false);
      })
      .catch(err => {
        setError(err.response.data.message);
        setOnProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const closeModal = () => {
    setError('');
    setSuccess(false);
  };

  // Check Currency and Funding method to populate Fund Modals
  const checkFundMethod = () => {
    if (activeTag === 'NG') {
      setShowBank(true);
    } else if (activeTag === 'US') {
      setShowDollarBank(true);
    } else {
      return;
    }
  };

  const checkSendMethod = () => {
    if (activeTag === 'NG') {
      navigate('/select-currency?tag=NG');
    } else if (activeTag === 'US') {
      navigate('/select-currency?tag=US');
    } else {
      return;
    }
  };

  return (
    <>
      <StatementPreview />

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An Error Occured!!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Sent!"
          miniMessage={'Your account statement has been sent to your inbox.'}
        />
      ) : null}

      {showBank ? (
        <BankTransfer
          closeFunc={() => setShowBank(false)}
          accountName={accountInfo.accountName}
          accountNo={accountInfo.accountNumber}
          bankName={accountInfo.accountBank}
        />
      ) : null}

      {showDollarBank ? (
        <DollarBankTransfer
          closeFunc={() => setShowDollarBank(false)}
          accountNo={''}
        />
      ) : null}

      {showDollarTransferOption ? (
        <DollarTransferOption
          closeFunc={() => setShowDollarTransferOption(false)}
        />
      ) : null}

      {showTransferOption ? (
        <TransferOption closeFunc={() => setTransferOption(false)} />
      ) : null}

      <MainWrapper
        className="no-scrollbar"
        onClick={() => {
          setToggleActive(false);
          setIsInputFocus(false);
          setShowActionPop(false);
        }}
        mobileTop="4rem"
      >
        <AppHeader
          showBack={true}
          pageName={`Transactions (${totalResults})`}
        />
        <InAppHeader
          style={{
            display: 'flex',
          }}
          mobileTop="-1rem"
        >
          <MobileInputWrap>
            <MobileSelect>
              <div
                style={{
                  width: '10rem',
                }}
              >
                <InputActPlacehold
                  onClick={e => {
                    setIsInputFocus(!isInputFocus);
                    e.stopPropagation();
                  }}
                  onBlur={() => setIsInputFocus(false)}
                >
                  <div>
                    <ReactCountryFlag
                      style={{
                        fontSize: '1.3rem',
                        borderRadius: '50%',
                      }}
                      countryCode={activeTag}
                      svg
                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                      cdnSuffix="svg"
                    />
                    <p
                      style={{
                        color: '#191919',
                        fontSize: '16px',
                        fontWeight: 700,
                      }}
                    >
                      {activeCurrency}
                    </p>
                  </div>
                  <i>
                    <Icon.ChevronDown size={15} strokeWidth={3} />
                  </i>
                </InputActPlacehold>
              </div>
            </MobileSelect>
            <CollabSuggestWrap
              style={{
                margin: '0rem 0 0 0',
                width: '11rem',
                zIndex: '1000000000',
                top: '2rem',
              }}
            >
              {isInputFocus ? (
                <div>
                  {CurrenciesItem && CurrenciesItem.length > 0 ? (
                    CurrenciesItem.slice(0, 2).map((item, index) => (
                      <SplitUsersWrap
                        alignVertical="flex-start"
                        key={index}
                        style={{
                          cursor: 'pointer',
                          width: '100%',
                        }}
                        onClick={() => {
                          setActiveTag(item.tag);
                          setActiveCurrency(item.name);
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                              width: '100%',
                            }}
                          >
                            <ReactCountryFlag
                              style={{
                                fontSize: '1.3rem',
                                borderRadius: 'none',
                              }}
                              countryCode={item.tag}
                              svg
                              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                              cdnSuffix="svg"
                              title={item.tag}
                            />
                            <h3
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '65%',
                              }}
                            >
                              {item.name ? `${item.name}` : ''}
                            </h3>
                          </div>
                        </div>
                      </SplitUsersWrap>
                    ))
                  ) : (
                    <p
                      style={{
                        textAlign: 'center',
                        fontSize: '14px',
                        color: '#757575',
                        margin: '1rem 0 0 0',
                      }}
                    >
                      No Option.
                    </p>
                  )}
                </div>
              ) : null}
            </CollabSuggestWrap>
          </MobileInputWrap>
        </InAppHeader>
        <SearchPos
          style={
            width < 728
              ? {
                  flexDirection: 'row',
                  top: '1rem',
                  margin: '0 auto',
                  zIndex: '10',
                }
              : {
                  alignItems: 'flex-end',
                  zIndex: '10',
                }
          }
        >
          <ButtonWrap
            mobileHide={false}
            style={
              activeTag === 'US' && width < 728
                ? {
                    position: 'relative',
                    top: '-2.5rem',
                  }
                : {}
            }
          >
            <CustomButtonDark
              onClick={e => {
                if (
                  bvnKycStatus.status.toLowerCase() === 'verified' &&
                  idKycStatus.status.toLowerCase() === 'verified'
                ) {
                  setShowActionPop(!showActionPop);
                  e.stopPropagation();
                } else {
                  setBlocked(true);
                }
              }}
              style={{
                right: 0,
              }}
            >
              <ButtonFlex>
                Action
                <Icon.ChevronDown
                  size={16}
                  style={{
                    position: 'relative',
                    left: '0.5rem',
                  }}
                />
              </ButtonFlex>
            </CustomButtonDark>
          </ButtonWrap>
          {showActionPop ? (
            <HeaderPopUp>
              {activeTag === 'NG' ? (
                <>
                  <p
                    onClick={() => {
                      checkFundMethod();
                      setShowActionPop(!showActionPop);
                    }}
                  >
                    Fund Accountant
                  </p>
                </>
              ) : null}
              <p
                onClick={() => {
                  checkSendMethod();
                }}
              >
                Send Money
              </p>

              <p
                onClick={() => {
                  navigate(`/swap-currency/${activeCurrency.split(' ')[0]}`);
                  setShowActionPop(!showActionPop);
                }}
              >
                Swap Currency
              </p>
            </HeaderPopUp>
          ) : null}
        </SearchPos>
        <BreadcrumbArea>
          {/* Commented because the data is not connected yet. Expecting the backend. */}
          {activeTag === 'NG' || activeTag === 'US' ? (
            <ViewInfoWrapper>
              <Carousel
                responsive={responsive}
                showDots={false}
                removeArrowOnDeviceType={[
                  'desktop',
                  'superLargeDesktop',
                  'mobile',
                  'tablet',
                ]}
                itemClass="carousel-item-padding-40-px"
                customButtonGroup={<ButtonGroup />}
              >
                <ViewInfoChild>
                  <div>
                    <p>Money In</p>
                    <PercentWrap
                      bg={
                        transactionOverview.percentageChangeForMoneyIn > 0
                          ? 'rgba(107, 211, 43, 0.1)'
                          : ''
                      }
                      color={
                        transactionOverview.percentageChangeForMoneyIn > 0
                          ? '#6BD32B'
                          : ''
                      }
                    >
                      <Icon.ArrowDown size={10} />
                      {transactionOverview.percentageChangeForMoneyIn}%
                    </PercentWrap>
                  </div>
                  <h2>
                    {getSign(
                      activeCurrency ? activeCurrency.split(' ')[0] : '',
                    )}
                    {CommaNumber(transactionOverview.moneyIn)}
                  </h2>
                  <select
                    style={{
                      width: '10rem',
                      color: '#1f1f1f',
                      fontSize: '14px',
                    }}
                    onChange={e => setPeriod(e.target.value)}
                  >
                    <option value="today">Today</option>
                    <option value="week">Last 7 days</option>
                    <option value="month">Last 30 days</option>
                    <option value="year">Last 12 months</option>
                  </select>
                </ViewInfoChild>
                <ViewInfoChild>
                  <div>
                    <p>Money Out</p>
                    <PercentWrap
                      bg={
                        transactionOverview.percentageChangeForMoneyOut > 0
                          ? 'rgba(255, 0, 0, 0.1)'
                          : ''
                      }
                      color={
                        transactionOverview.percentageChangeForMoneyOut > 0
                          ? '#ff0000'
                          : ''
                      }
                    >
                      <Icon.ArrowUp size={10} />
                      {transactionOverview.percentageChangeForMoneyOut}%
                    </PercentWrap>
                  </div>
                  <h2>
                    {getSign(
                      activeCurrency ? activeCurrency.split(' ')[0] : '',
                    )}
                    {CommaNumber(transactionOverview.moneyOut)}
                  </h2>
                </ViewInfoChild>
                <ViewInfoChild>
                  <div>
                    <p>Profit</p>
                  </div>
                  {transactionOverview.profit < 0 ? (
                    <h2>
                      -
                      {getSign(
                        activeCurrency ? activeCurrency.split(' ')[0] : '',
                      )}
                      {CommaNumber(
                        transactionOverview.profit.toString().replace('-', ''),
                      )}
                    </h2>
                  ) : (
                    <h2>
                      {getSign(
                        activeCurrency ? activeCurrency.split(' ')[0] : '',
                      )}
                      {CommaNumber(transactionOverview.profit)}
                    </h2>
                  )}
                </ViewInfoChild>
              </Carousel>
            </ViewInfoWrapper>
          ) : null}
          <SearchPos
            style={{
              top: '1.5rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <FlexedApart
              style={
                width < 728
                  ? {
                      width: '100%',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }
                  : {
                      width: '40%',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }
              }
            >
              <SearchArea
                style={{
                  width: '90%',
                  display: 'block',
                  position: 'relative',
                }}
              >
                <i onClick={() => newSearch()}>
                  <Icon.Search />
                </i>
                <input
                  type="text"
                  placeholder="Search your transactions"
                  onChange={e => setSearchText(e.target.value)}
                />
              </SearchArea>
              <Icon.Calendar
                onClick={() => setOpenStatementModal(true)}
                color="#474747"
                style={{
                  position: 'relative',
                  // right: '1rem',
                  cursor: 'pointer',
                }}
              />
            </FlexedApart>
          </SearchPos>
          <TableWrap>
            <TableHeader bg="" border="none" style={{ margin: '3rem 0 0 0' }}>
              <IconWrap></IconWrap>
              <NameWrap>COUNTERPARTY</NameWrap>
              <PurposeWrap>TRANSACTION MEMO</PurposeWrap>
              <DateWrap>DATE</DateWrap>
              <AmountWrap>AMOUNT</AmountWrap>
              <StatusWrap>STATUS</StatusWrap>
              <MenuWrap></MenuWrap>
            </TableHeader>
            {!fetching ? (
              <>
                {history !== '' && historyResult.length > 0 ? (
                  <>
                    {historyResult.map((item, index) => (
                      <>
                        <TransactionCard
                          item={item}
                          key={index}
                          reference={item.reference}
                          id={item.id}
                          type={item.type}
                          payerName={item.meta.payerName}
                          accountName={item.meta.accountName}
                          purpose={item.purpose}
                          updatedAt={item.updatedAt}
                          amount={item.amount}
                          openReport={toggleReportModal}
                          openDetails={toggleDetailsModal}
                          handlePdf={handlePdf}
                          options={options}
                          propRefs={ref}
                          handleSelect={setReceiptItem}
                          toggleId={toggleId}
                          setToggleActive={setToggleActive}
                          setToggleId={setToggleId}
                          toggleActive={toggleActive}
                          toggleMenu={handleToggleActive}
                        />
                      </>
                    ))}

                    {/* Mobile Section of History */}

                    <MobileHistory>
                      <RightWidget>
                        <RightCards>
                          <HistoryWidget>
                            {historyResult.map((item, index) => (
                              <MobileTransCard
                                item={item}
                                key={index}
                                reference={item.reference}
                                type={item.type}
                                id={item.id}
                                payerName={item.meta.payerName}
                                accountName={item.meta.accountName}
                                purpose={item.purpose}
                                updatedAt={item.updatedAt}
                                amount={item.amount}
                                openReport={toggleReportModal}
                                openDetails={toggleDetailsModal}
                                handlePdf={handlePdf}
                                options={options}
                                propRefs={ref}
                                handleSelect={setReceiptItem}
                                toggleId={toggleId}
                                setToggleActive={setToggleActive}
                                setToggleId={setToggleId}
                                toggleActive={toggleActive}
                                toggleMenu={handleToggleActive}
                              />
                            ))}
                          </HistoryWidget>
                        </RightCards>
                      </RightWidget>
                    </MobileHistory>

                    {totalPages > 1 && (
                      <PaginationWrapper>
                        <PaginationCont>
                          <p onClick={() => handleDecrement()}>
                            <Icon.ChevronLeft />
                          </p>
                          <PaginationTextWrap>
                            <h4>{page}</h4>
                            <span>of</span>
                            <h4>{totalPages}</h4>
                          </PaginationTextWrap>
                          <p onClick={() => handleIncrement()}>
                            <Icon.ChevronRight />
                          </p>
                        </PaginationCont>
                      </PaginationWrapper>
                    )}
                  </>
                ) : (
                  <>
                    <EmptyState
                      img="/flat-money.png"
                      header="No transaction here!"
                      linkMask=" "
                      extraText=" "
                    />
                  </>
                )}
              </>
            ) : (
              <CenteredAnimeWrap>
                <MoonLoader size={40} color="#969696" />
              </CenteredAnimeWrap>
            )}
          </TableWrap>
        </BreadcrumbArea>

        {reportModal ? (
          <ReportTransaction
            closeFunc={closeReportModal}
            header="Report Transaction"
            subText="Tell us what went wrong with this transaction"
            props={selected}
          />
        ) : null}

        {detailsModal ? (
          <Details props={selected} closeFunc={toggleDetailsModal} />
        ) : null}

        <Receipt
          props={selected}
          propRef={ref}
          accountNumber={accountInfo ? accountInfo.accountNumber : ''}
          accountBank={accountInfo ? accountInfo.bankName : ''}
          firstName={loggedUser.user.firstName}
          lastName={loggedUser.user.lastName}
        />

        {openStatementModal ? (
          <AccountStatement
            closeFunc={() => setOpenStatementModal(false)}
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            action={handleSendStatement}
            onProcess={onProcess}
          />
        ) : null}
        {blocked ? <Blocked closeFunc={() => setBlocked(false)} /> : null}
      </MainWrapper>
    </>
  );
};

export default MainContent;

interface PropsArgs {
  item: any;
  type: any;
  id: any;
  reference: string;
  key: any;
  payerName: any;
  accountName: any;
  purpose: any;
  updatedAt: any;
  amount: any;
  openReport: any;
  openDetails: any;
  handlePdf: any;
  propRefs: any;
  options: any;
  handleSelect: any;
  toggleActive;
  toggleId;
  setToggleId;
  setToggleActive;
  toggleMenu;
}

const MobileTransCard = ({
  item,
  type,
  payerName,
  key,
  reference,
  accountName,
  updatedAt,
  amount,
  openReport,
  openDetails,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  toggleId,
  setToggleId,
  setToggleActive,
  toggleMenu,
}: PropsArgs) => {
  return (
    <>
      <HistoryItemCard key={key} onClick={() => openDetails(item)}>
        <HistoryItemIconWrap
          customBg={() => handleBg(type.toLocaleLowerCase(), item.source)}
        >
          {type === 'debit' ? (
            <Icon.ArrowUpRight color="rgba(200, 43, 50, 1)" />
          ) : (
            <>
              {item.source === 'exchange' ? (
                <Icon.Repeat color="#8975F3" />
              ) : (
                <Icon.ArrowDownLeft color="rgba(107, 211, 43, 1)" />
              )}
            </>
          )}
        </HistoryItemIconWrap>
        <HistoryTextWrap>
          {payerName !== undefined ? (
            <h4>{payerName ? titleCase(payerName) : 'No name attached'}</h4>
          ) : (
            <h4>{accountName ? titleCase(accountName) : 'No name attached'}</h4>
          )}
          <p>{moment(updatedAt).format('LLL')}</p>
        </HistoryTextWrap>
        <HistoryInfoSec>
          <HistoryAmount>
            {getSign(item.currency)}
            {CommaNumber(amount)}
          </HistoryAmount>
        </HistoryInfoSec>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="3rem"
            onClick={e => {
              setToggleActive(false);
            }}
          >
            <p
              onClick={() => {
                generatePDF(propRefs, { filename: `mage-${reference}.pdf` });
                handleSelect(item);
              }}
            >
              Download receipt
            </p>
            <p>
              <a href="mailto:hello@trymage.com">Report transactions</a>
            </p>

            <p onClick={() => openDetails(item)}>More details</p>
          </MenuContent>
        ) : null}
      </HistoryItemCard>
    </>
  );
};

const TransactionCard = ({
  item,
  type,
  id,
  reference,
  key,
  payerName,
  accountName,
  purpose,
  updatedAt,
  amount,
  openReport,
  openDetails,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  toggleId,
  setToggleId,
  setToggleActive,
  toggleMenu,
}: PropsArgs) => {
  return (
    <>
      <TableHeader
        bg="#fff"
        border=""
        key={key}
        onClick={() => openDetails(item)}
      >
        <IconWrap>
          <HistoryItemIconWrap
            customBg={() => handleBg(type.toLocaleLowerCase(), item.source)}
          >
            {type === 'debit' ? (
              <Icon.ArrowUpRight color="rgba(200, 43, 50, 1)" />
            ) : (
              <>
                {item.source === 'exchange' ? (
                  <Icon.Repeat color="#8975F3" />
                ) : (
                  <Icon.ArrowDownLeft color="rgba(107, 211, 43, 1)" />
                )}
              </>
            )}
          </HistoryItemIconWrap>
        </IconWrap>
        <NameWrap>
          {payerName !== undefined ? (
            <h4>{payerName ? titleCase(payerName) : 'No name attached'}</h4>
          ) : (
            <h4>{accountName ? titleCase(accountName) : 'No name attached'}</h4>
          )}
        </NameWrap>
        <PurposeWrap>
          {'purpose' in item ? purpose : 'No memo attached'}
        </PurposeWrap>
        <DateWrap>{moment(updatedAt).format('LLL')}</DateWrap>
        <AmountWrap>
          {getSign(item.currency)}
          {CommaNumber(amount)}
        </AmountWrap>
        {'status' in item ? (
          <StatusWrap>
            <div
              style={{
                background: handleStatusBg(item.status),
                color: handleColor(item.status),
              }}
            >
              {item.status
                .replace('_', ' ')
                .replace(item.status[0], item.status[0].toUpperCase())}
            </div>
          </StatusWrap>
        ) : (
          <StatusWrap></StatusWrap>
        )}
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="6rem"
            onClick={e => {
              setToggleActive(false);
            }}
          >
            <p
              onClick={() => {
                generatePDF(propRefs, { filename: `mage-${reference}.pdf` });
                handleSelect(item);
              }}
            >
              Download receipt
            </p>
            <p>
              <a href="mailto:hello@trymage.com">Report transactions</a>
            </p>
            <p onClick={() => openDetails(item)}>More details</p>
          </MenuContent>
        ) : null}
      </TableHeader>
    </>
  );
};

export const CurrenciesItem = [
  {
    name: 'NGN Balance',
    tag: 'NG',
  },
  {
    name: 'USD Balance',
    tag: 'US',
  },
  {
    name: 'GBP Balance',
    tag: 'GB',
  },
  {
    name: 'EUR Balance',
    tag: 'EU',
  },
];
