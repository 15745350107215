import React, { useState, useEffect } from 'react';
import { FixedRightBottom, ActionHeader } from './style';
import { FlexedApart } from '../storeComp/sideNav/style';
import { TicketInput } from '../paymentLink/style';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { Line } from '../invoice/mainInvoice/style';
import { BeatLoader } from 'react-spinners';
import Select from 'react-select';
import axios from 'axios';
import { CustomStyles } from 'utils/selectCustomStyle';
import { numOnly } from 'utils/numOnly';

const CollaboratorSettings = ({
  closeFunc,
  item,
  checkCanView,
  index,
  email,
  setEmail,
  role,
  setRole,
  handleCreate,
  createOnProcess,
  openCreate,
  accountDetails,
}) => {
  // const [showPermission, setShowPermission] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(true);
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
  const [selectedBank, setSelectedBank] = useState<string>('');
  const [selectedBankId, setSelectedBankId] = useState<string>('');
  const [banks, setBanks] = useState<any>([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/bank-list?`, {
        headers: { Authorization },
      })
      .then(res => {
        setBanks(res.data);
      })
      .catch(err => {});
  }, [Authorization]);

  const handleInput = (e, setParam, amount: boolean = false) => {
    if (amount) {
      let value = e.target.value;
      if (value.toString().indexOf('0') === 0) value = Number(value).toString();
      setParam(value);
    } else setParam(e.target.value);
  };

  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [number, setNumber] = useState(0);
  const [name, setName] = useState('');

  const ValidateParams = {
    bankId: selectedBankId,
    accountNumber: number,
  };

  const handleGetAccName = e => {
    if (
      selectedBankId &&
      number &&
      selectedBankId.length > 0 &&
      number.toString().length === 10
    ) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/validate-account`,
          ValidateParams,
          headers,
        )
        .then(res => {
          setName(res.data.accountName);
        })
        .catch(err => {
          if (err.response.data.code === 400) {
          }
        });
    }
  };

  const [bankInfo, setBankInfo] = useState({
    name: '',
    number: 0,
    selectedBankId: '',
    selectedBank: '',
  });

  useEffect(() => {
    if (name && number && selectedBank && selectedBankId) {
      setBankInfo({
        name,
        number,
        selectedBankId,
        selectedBank,
      });
    }
  }, [bankInfo, name, number, selectedBank, selectedBankId]);

  useEffect(() => {
    accountDetails(bankInfo);
  }, [bankInfo, accountDetails]);

  return (
    <FixedRightBottom
      onClick={e => {
        e.stopPropagation();
        closeFunc();
      }}
    >
      <div
        onClick={e => e.stopPropagation()}
        onKeyPress={e => {
          if (e.key === 'Enter') {
            handleCreate();
          }
        }}
      >
        <FlexedApart
          style={{
            position: 'relative',
            top: '0',
            width: '100%',
            border: 'none',
            padding: '15px 5%',
          }}
        >
          <span style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
            <Icon.ArrowLeft
              onClick={() => {
                openCreate(true);
                closeFunc();
              }}
              style={{
                cursor: 'pointer',
              }}
            />
            <h2>
              <b>Add New Collaborator</b>
            </h2>
          </span>
        </FlexedApart>
        <Line />
        <div>
          <ActionHeader>
            <i onClick={() => setShowSendEmail(!showSendEmail)}>
              {showSendEmail ? (
                <Icon.ChevronUp color="#474747" />
              ) : (
                <Icon.ChevronDown color="#474747" />
              )}
            </i>
            <div onClick={() => setShowSendEmail(!showSendEmail)}>
              <h3>Send Invite</h3>
            </div>
            <i>
              <IconSax.TickCircle color="#34A853" />
            </i>
          </ActionHeader>
          {showSendEmail ? (
            <>
              <TicketInput>
                <div style={{ width: '100%' }}>
                  <p>Email Address</p>
                  <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    style={{ color: '#757575, cursor: not-allowed' }}
                  />
                </div>
              </TicketInput>
              <p
                style={{
                  fontSize: '12px',
                  color: '#ababab',
                  position: 'relative',
                  top: '-0.8rem',
                }}
              >
                {email ? `An email invite will be sent to ${email}.` : null}
              </p>
            </>
          ) : null}

          <ActionHeader>
            <i onClick={() => setShowAccountDetails(!showAccountDetails)}>
              {showAccountDetails ? (
                <Icon.ChevronUp color="#474747" />
              ) : (
                <Icon.ChevronDown color="#474747" />
              )}
            </i>
            <div onClick={() => setShowAccountDetails(!showAccountDetails)}>
              <h3>Payment details</h3>
            </div>
            <i>
              <IconSax.TickCircle color="#34A853" />
            </i>
          </ActionHeader>

          {showAccountDetails ? (
            <>
              <TicketInput>
                <div style={{ width: '100%' }}>
                  <p>Country</p>
                  <input
                    type="text"
                    value="Nigeria"
                    onChange={e => setEmail(e.target.value)}
                    style={{ color: '#757575, cursor: not-allowed' }}
                  />
                </div>
              </TicketInput>

              <TicketInput>
                <div style={{ width: '100%' }}>
                  <p>Bank</p>
                  <Select
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.uuid}
                    options={banks}
                    placeholder="Search..."
                    styles={CustomStyles}
                    isSearchable
                    value={banks.filter(option => option.name === selectedBank)}
                    onChange={(e: any) => {
                      setSelectedBankId((e as any).uuid);
                      setSelectedBank((e as any).name);
                    }}
                  />
                </div>
              </TicketInput>

              <TicketInput>
                <div style={{ width: '100%' }}>
                  <p>Account Number</p>
                  <input
                    type="number"
                    placeholder="Account number"
                    value={number}
                    maxLength={10}
                    onKeyPress={e => numOnly(e)}
                    onChange={e => {
                      handleInput(e, setNumber);
                      if (e.target.value.length < 10) {
                      }
                    }}
                    onKeyUp={e => handleGetAccName(e)}
                    style={{ color: '#757575, cursor: not-allowed' }}
                  />
                </div>
              </TicketInput>

              <TicketInput>
                <div style={{ width: '100%' }}>
                  <p>Recipient's Name</p>
                  <input
                    type="name"
                    value={name}
                    disabled
                    style={{ color: '#757575, cursor: not-allowed' }}
                  />
                </div>
              </TicketInput>
            </>
          ) : null}
        </div>

        <BigBtn
          style={{
            width: '90%',
            position: 'absolute',
            bottom: '1rem',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <button
            type="button"
            onClick={() => handleCreate()}
            disabled={email === ''}
          >
            {createOnProcess ? <BeatLoader color="#fff" /> : 'Save Changes'}
          </button>
        </BigBtn>
      </div>
    </FixedRightBottom>
  );
};

export default CollaboratorSettings;
