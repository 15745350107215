import { getRequest } from 'utils/request';

const IsUsernameAvailable = async (slug): Promise<any> => {
  try {
    await getRequest(`creator-page/slug/${slug}`);
    return false;
  } catch (e: any) {
    return true;
  }
};

export default IsUsernameAvailable;
