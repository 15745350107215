import React, { useEffect, useState } from 'react';
import {
  CollabSuggestWrap,
  SplitUsersWrap,
  RoleCardWrap,
  RoleCard,
} from './style';
import { FlexedApart } from '../storeComp/sideNav/style';
import { TicketInput } from '../paymentLink/style';
import * as Icon from 'react-feather';

const RoleInput = ({
  index,
  addRole,
  isInputFocus,
  setInputFocus,
  RoleItems,
  deleteRole,
}) => {
  const [mockArray, setMockArray] = useState<any>([]);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    setMockArray(array);
  }, []);

  const handleSearch: any = e => {
    let new_item: any = [];
    if (array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (
          array[i].name.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          new_item.push(array[i]);
        } else if (e.target.value === '') {
          setMockArray(array);
        }
      }
      setMockArray(new_item);
    }
  };

  return (
    <>
      <RoleCardWrap>
        {RoleItems && RoleItems.length > 0
          ? RoleItems.map((item, itemIndex) => (
              <RoleCard key={itemIndex}>
                {item}
                <Icon.X
                  size={17}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    deleteRole(itemIndex, index);
                  }}
                />
              </RoleCard>
            ))
          : null}
        <RoleCard
          color="#007AFF"
          bg="fafafa"
          border="1px solid #007AFF"
          onClick={() => setShowInput(true)}
        >
          <Icon.Plus size={17} />
          Add Role
        </RoleCard>
      </RoleCardWrap>
      <CollabSuggestWrap
        style={{
          position: 'relative',
          left: '0%',
          margin: 0,
          width: '100%',
        }}
      >
        <FlexedApart
          style={{
            position: 'relative',
            top: '0',
            width: '100%',
            border: 'none',
            padding: '0 0 15px 0',
          }}
        ></FlexedApart>
        {showInput ? (
          <TicketInput>
            <div
              style={{
                width: '100%',
              }}
            >
              <p>Search</p>
              <input
                onClick={e => {
                  e.stopPropagation();
                  setInputFocus(true);
                }}
                onChange={e => handleSearch(e)}
              />
            </div>
          </TicketInput>
        ) : null}
        {isInputFocus ? (
          <div
            style={{
              top: '3rem',
            }}
          >
            <p
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '10px',
                cursor: 'pointer',
                margin: '1rem 5%',
                color: '#757575',
              }}
            >
              Available Roles
            </p>
            {mockArray && mockArray.length > 0 ? (
              mockArray.map((item, itemIndex) => (
                <SplitUsersWrap
                  alignVertical="flex-start"
                  key={itemIndex}
                  style={{
                    cursor: 'pointer',
                    padding: '0.8rem 3%',
                  }}
                  hover
                  onClick={() => {
                    addRole(item.name, index);
                    setInputFocus(false);
                    setShowInput(false);
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'space-between',
                        gap: '10px',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          width: '70%',
                        }}
                      >
                        <h3
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '95%',
                          }}
                        >
                          {item.name ? `${item.name}` : ''}
                        </h3>
                      </div>
                    </div>
                  </div>
                </SplitUsersWrap>
              ))
            ) : (
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                  color: '#757575',
                  margin: '1rem 0 0 0',
                }}
              >
                No Option.
              </p>
            )}
          </div>
        ) : null}
      </CollabSuggestWrap>
    </>
  );
};

export default RoleInput;

const array: any = [
  {
    name: 'Artist',
  },
  {
    name: 'Instrumentalist',
  },
  {
    name: 'Session Contributer',
  },
  {
    name: 'Songwriter',
  },
  {
    name: 'Mastering Engineer',
  },
  {
    name: 'Mixing Engineer',
  },
  {
    name: 'Music Producer',
  },
  {
    name: 'Others',
  },
];
