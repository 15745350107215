import React from 'react';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  BigBtn,
} from './../dashboard/mainContent/fundWallet/style';
import * as FeatherIcon from 'react-feather';
import { Link } from 'react-router-dom';

const Blocked = ({ closeFunc }) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <FeatherIcon.X />
          </CloseBtn>
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <img
              style={{
                width: '6rem',
                margin: '1rem auto',
                display: 'block',
              }}
              src="/blocked.png"
              alt="KYC"
            />
            <h3>Identity not verified</h3>
            <p
              style={{
                width: '20rem',
                fontSize: '12px',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              You need to complete your Idenity verification.
            </p>
            <Link to="/set-identity">
              <BigBtn>
                <button>Complete KYC Verification</button>
              </BigBtn>
            </Link>
          </div>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default Blocked;
