const Store = {
  get: key => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  },
  create: (key, body) => {
    localStorage.setItem(key, JSON.stringify(body));
    return body;
  },
  updateObject: (key, body) => {
    const item = localStorage.getItem(key);
    const parsedItem = item ? JSON.parse(item) : {};
    Object.assign(parsedItem, body);
    localStorage.setItem(key, JSON.stringify(parsedItem));
    return parsedItem;
  },
  pushItem: (key, body) => {
    const item = localStorage.getItem(key);
    const parsedItem = item ? JSON.parse(item) : [];
    parsedItem.push(body);
    localStorage.setItem(key, JSON.stringify(parsedItem));
    return parsedItem;
  },
  removeItem: (key, body) => {
    const item = localStorage.getItem(key);
    const parsedItem = item ? JSON.parse(item) : [];
    parsedItem.splice(
      parsedItem.findIndex(x => x === body),
      1,
    );
    localStorage.setItem(key, JSON.stringify(parsedItem));
    return parsedItem;
  },
  delete: key => {
    localStorage.removeItem(key);
    return true;
  },
};

export default Store;
