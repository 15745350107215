import Benefit from '../app/components/Benefits/interface';
import { setBenefits } from './benefitAction';

export interface BenefitsState {
  benefits: Benefit[];
}

const initialState: BenefitsState = {
  benefits: [],
};

type ActionTypes = ReturnType<typeof setBenefits>;

const benefitsReducer = (
  state: BenefitsState = initialState,
  action: ActionTypes,
): BenefitsState => {
  switch (action.type) {
    case 'SET_BENEFITS':
      return {
        ...state,
        benefits: action.payload,
      };
    default:
      return state;
  }
};

export default benefitsReducer;
