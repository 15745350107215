import React, { useEffect, useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
  SubHeader,
  SwipeFund,
  BankDetails,
} from './../dashboard/mainContent/fundWallet/style';
import EnterPin from './../dashboard/mainContent/fundWallet/enterPin';
import { numOnly } from 'utils/numOnly';
import * as Icon from 'react-feather';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import CommaNumber from 'comma-number';
import { roundTo } from 'utils/roundFloat';
import uuid from 'react-uuid';
import { getSign } from 'utils/getCurrencySign';
import {
  RightPart,
  InfoText,
  BankDetailsWrap,
  Flexed,
  CopyBtn,
} from './../dashboard/mainContent/fundWallet/style';
import CopiedNotifier from '../reusable/copied';

const FundSplitAmount = ({
  closeFunc,
  splitId,
  accountInfo,
  splitName,
  loadAction,
  currency,
  bankName,
  accountName,
  accountNo,
  showAccount,
}) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [balance, setBalance] = useState('');
  const [amount, setAmount] = useState<string>('0');
  const [onProcess, setOnProcess] = useState(false);
  const [clientErr, setClientErr] = useState('');
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [copied, setCopied] = useState(false);

  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  // Get Balance
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/account-info?`, {
        headers: { Authorization },
      })
      .then(resp => {
        // setUserAccount(resp.data)
        let balanceValue =
          currency === 'USD'
            ? resp.data.balance.dollar
            : resp.data.balance.naira;
        setBalance(balanceValue);
        localStorage.setItem('accountInfo', JSON.stringify(resp.data));
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
        }
      });
  }, [Authorization, currency]);

  // Enter Pin for transaction

  const [pin, setPin] = useState<any>('');

  // Set Pin
  const handlePinChange = pin => {
    setPin(pin);
  };

  const [pinModal, setPinModal] = useState(false);

  const handleClosePinModal = () => {
    setPinModal(false);
  };

  const [result, setResult] = useState<boolean | string>(false);

  const handlePayment = () => {
    setOnProcess(true);
    if (Number(amount) > 0) {
      if (amount && Number(amount) <= Number(balance)) {
        if (Number(amount) > 0) {
          setPinModal(true);
          setOnProcess(false);
        } else {
          setClientErr('Please fill all input fields!');
          setOnProcess(false);
          setTimeout(() => {
            setClientErr('');
          }, 2000);
        }
      } else {
        setOnProcess(false);
        setClientErr(
          'You do not have sufficient balance to make this transaction',
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else {
      setOnProcess(false);
      setClientErr(`Amount can't be empty and must be greater than 0`);
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  // Payment Parameters
  const paymentParams: any = {
    splitId: splitId,
    amount: `${amount}`,
    idempotentKey: uuid(),
    currency: currency,
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [finalOnProcess, setFinalOnProcess] = useState(false);
  const handleFinalPay = () => {
    setFinalOnProcess(true);
    if (pin && pin.length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/auth/confirm-pin`,
          {
            pin,
            userId: loggedUser.user.id,
          },
          headers,
        )
        .then(res => {
          if (res.data.status) {
            setFinalOnProcess(false);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              setPinModal(false);
              closeFunc();
            }, 5000);
            axios
              .post(
                `${process.env.REACT_APP_BASE_ENDPOINT}/payments/split-transfer`,
                paymentParams,
                headers,
              )
              .then(res => {
                setFinalOnProcess(false);
                setSuccess(true);
                loadAction();
                setTimeout(() => {
                  setSuccess(false);
                  setPinModal(false);
                  closeFunc();
                }, 5000);
              })
              .catch(err => {
                setFinalOnProcess(false);
                setError(err.response.data.message);
                setTimeout(() => {
                  setError('');
                }, 4000);
              });
          } else {
            setFinalOnProcess(false);
            setErrorMessage('Unable to confirm pin at the moment');
            setPin('');
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
          }
        })
        .catch(err => {
          setFinalOnProcess(false);
          setErrorMessage(err.response.data.message);
          setPin('');
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('Transaction pin is not correct!');
      setPin('');
      setFinalOnProcess(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  useEffect(() => {
    if (pin.length === 4) handleFinalPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const closeModal = () => {
    setError('');
  };

  const closeSuccess = () => {
    setSuccess(false);
    setPinModal(false);
    closeFunc();
  };

  const closeMessage = () => {
    setResult(false);
  };

  const [wallet, setWallet] = useState(true);
  const [bankAccount, setBankAccount] = useState(false);

  const handleWallet = () => {
    setWallet(true);
    setBankAccount(false);
  };

  const handleBankAccount = () => {
    setWallet(false);
    setBankAccount(true);
  };

  const handleCopy = text => {
    navigator.clipboard.writeText(text);
    setCopied(!copied);
    setTimeout(() => {
      setCopied(false);
    }, 7000);
  };

  return (
    <>
      <ModalArea
        style={pinModal ? { visibility: 'hidden' } : { visibility: 'visible' }}
      >
        {copied ? <CopiedNotifier /> : null}
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Fund Split</Header>
          <SubHeader>
            <span>
              <b>Split:</b> {''}
              <span> {`  ${splitName}`}</span>
            </span>
          </SubHeader>
          <SwipeFund>
            <p
              onClick={handleWallet}
              style={{
                borderBottom: wallet ? '2px solid black' : '',
              }}
            >
              Wallet
            </p>
            {showAccount ? (
              <>
                <p
                  onClick={handleBankAccount}
                  style={{
                    borderBottom: bankAccount ? '2px solid black' : '',
                  }}
                >
                  Account Info
                </p>
              </>
            ) : null}
          </SwipeFund>

          {wallet ? (
            <>
              <div>
                <InputWrap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label>Enter Amount {getSign(currency)}</label>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        letterSpacing: '-0.02em',
                        color: '#ABABAB',
                      }}
                    >
                      Balance:
                      {getSign(currency)}
                      {CommaNumber(roundTo(balance, 2))}
                    </p>
                  </div>
                  <input
                    type="text"
                    placeholder="Amount"
                    onKeyPress={e => numOnly(e)}
                    onChange={e => setAmount(e.target.value)}
                  />
                </InputWrap>
              </div>
              <p style={{ width: '20rem', fontSize: '0.8rem', color: 'red' }}>
                {clientErr}
              </p>
              <BigBtn>
                <button
                  onClick={e => handlePayment()}
                  disabled={onProcess ? true : false}
                >
                  {onProcess ? <ClipLoader color="#fff" /> : 'Continue'}
                </button>
              </BigBtn>
            </>
          ) : null}

          {bankAccount ? (
            <>
              <BankDetails>
                {showAccount ? (
                  <>
                    <div>
                      <Header>Add by transfer</Header>
                      <RightPart>
                        <InfoText>
                          <p>
                            Use the details below to send money to your Mage
                            account from any bank’s app through internet
                            banking.
                          </p>
                        </InfoText>
                        <BankDetailsWrap>
                          <p>Bank</p>
                          <h3>{bankName}</h3>
                        </BankDetailsWrap>
                        <BankDetailsWrap>
                          <p>Account Name</p>
                          <h3>{accountName}</h3>
                        </BankDetailsWrap>
                        <BankDetailsWrap>
                          <p>Account Number</p>
                          <Flexed>
                            <h3>{accountNo}</h3>
                            <CopyBtn onClick={() => handleCopy(accountNo)}>
                              Copy
                            </CopyBtn>
                          </Flexed>
                        </BankDetailsWrap>
                      </RightPart>
                    </div>
                  </>
                ) : null}
              </BankDetails>
            </>
          ) : null}
        </ModalChild>
      </ModalArea>
      {pinModal ? (
        <EnterPin
          closeFunc={handleClosePinModal}
          prevFunc={handleClosePinModal}
          propPin={pin}
          errorMessage={errorMessage}
          handlePinChange={handlePinChange}
          handleFinalPay={handleFinalPay}
          onProcess={finalOnProcess}
          amount={amount}
          type="split"
          mode={'split'}
          accountName={`Split (${splitName})`}
          accountNo={accountInfo && accountInfo.accountNumber}
          bank={accountInfo && accountInfo.bankName}
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="Transaction Unsuccessful!!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeSuccess}
          img="/success.png"
          message="Transaction successful!!!"
          miniMessage={'Split funding was successful'}
        />
      ) : null}

      {result === '' ? (
        <Alert
          closeFunc={closeMessage}
          img="/failed.png"
          message="Changes not successful!"
          miniMessage="Encountered an error while creating your transaction PIN"
        />
      ) : null}
    </>
  );
};

export default FundSplitAmount;
