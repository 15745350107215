import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from 'app/components/dashboard/mainContent/fundWallet/style';
import { numOnly } from 'utils/numOnly';
import * as Icon from 'react-feather';
import { TicketInput } from './../../../paymentLink/style';
import Select from 'react-select';
import { CustomStyles } from 'utils/selectCustomStyle';

const ContactSettings = ({
  closeFunc,
  setFunc,
  setCity,
  setAddress,
  setPhone,
  phone,
  address,
  city,
  SelectInputsArray,
}) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header
            style={{
              textAlign: 'left',
            }}
          >
            Contact Details
          </Header>
          <form onSubmit={setFunc}>
            <div>
              {SelectInputsArray.map((item, index) => (
                <div
                  key={index}
                  style={{
                    margin: '0 0 1rem 0',
                  }}
                >
                  <Select
                    options={item.options}
                    getOptionLabel={(p: any) => p.name}
                    value={
                      item.options
                        ? item.options.filter(p => p.name === item.state)
                        : ''
                    }
                    isSearchable
                    placeholder="Search..."
                    styles={CustomStyles}
                    onChange={(e: any) =>
                      item.handleInputFunc((e as HTMLSelectElement).name)
                    }
                  />
                </div>
              ))}
              {/* <TicketInput>
                <div>
                  <p>City</p>
                  <input
                    onChange={e => setCity(e.target.value)}
                    value={city}
                    placeholder="Your city"
                  />
                </div>
              </TicketInput> */}
              <TicketInput>
                <div>
                  <p>Address*</p>
                  <input
                    onChange={e => setAddress(e.target.value)}
                    value={address}
                    required={true}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Phone number*</p>
                  <input
                    onChange={e => setPhone(e.target.value)}
                    maxLength={11}
                    value={phone}
                    required={true}
                    onKeyPress={e => numOnly(e)}
                    type="text"
                  />
                </div>
              </TicketInput>
            </div>
            <BigBtn>
              <button type="submit">Save Ticket</button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default ContactSettings;
