import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  ActionBtn,
  CloseBtn,
} from './style';
import { numOnly } from 'utils/numOnly';
import * as Icon from 'react-feather';

const FundWallet = ({ closeFunc, openNext, setAmount, amountErr, amount }) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Fund Wallet</Header>
          <div>
            <InputWrap>
              <label>Amount</label>
              <h4>&#8358;</h4>
              <input
                type="number"
                placeholder="Amount"
                defaultValue={amount}
                onChange={e => setAmount(e.target.value)}
                style={{ padding: '0.5rem 10%' }}
                onKeyPress={e => {
                  numOnly(e);
                }}
              />
            </InputWrap>
            <p style={{ fontSize: '0.8rem', color: 'red', width: '20rem' }}>
              {amountErr}
            </p>
          </div>
          <ActionBtn>
            <button onClick={() => openNext()}>Continue</button>
          </ActionBtn>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default FundWallet;
