import React, { useState, useEffect } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';
import { getUserData } from 'utils/firebaseUser';

const BvnCheckModal = ({ openNext, closeFunc }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [userCloudInfo, setUserCloudInfo] = useState<any>({});
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [bvn, setBvn] = useState<any>('');
  const [bvnData, setBvnData] = useState<any>({});
  const [date, setDate] = useState('');
  const [inputedBvn, setInputedBvn] = useState('');

  // Get User Data from Database
  useEffect(() => {
    getUserData(loggedUser.user.id, setUserCloudInfo);
  }, [loggedUser.user.id]);

  useEffect(() => {
    if (userCloudInfo) {
      setBvnData(userCloudInfo.bvn);
    }
  }, [userCloudInfo]);

  useEffect(() => {
    if (bvnData) {
      setBvn(bvnData.number);
    }
  }, [bvnData]);

  // Check Bvn
  const checkBvn = () => {
    if (inputedBvn !== '' && date !== '') {
      if (bvn === inputedBvn) {
        openNext();
      } else {
        setErrorMessage('The entered BVN number is not correct.');
        setTimeout(() => {
          setErrorMessage('');
        }, 4000);
      }
    } else {
      setErrorMessage('Please fill all input field!');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn
            className="fas fa-times"
            onClick={() => closeFunc()}
          ></CloseBtn>
          <Header>Provide Bvn details</Header>
          <div>
            <InputWrap>
              <label>BVN</label>
              <input
                type="number"
                placeholder="BVN"
                onChange={e => setInputedBvn(e.target.value)}
              />
            </InputWrap>
            <InputWrap>
              <label>Date</label>
              <input type="date" onChange={e => setDate(e.target.value)} />
            </InputWrap>
          </div>
          {errorMessage !== '' ? (
            <p style={{ color: 'red', width: '20rem' }}>{errorMessage}</p>
          ) : null}
          <BigBtn>
            <button onClick={() => checkBvn()}>Continue</button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default BvnCheckModal;
