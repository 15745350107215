import React, { useEffect, useState, useMemo } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { MainFlex } from './../invoice/mainInvoice/singleStyle';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { useNavigate } from 'react-router-dom';
import PopUpModal from '../merch/popupModal';
import axios from 'axios';
import { useParams, useSearchParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const ContractDisplay = () => {
  const { id } = useParams();
  const [getQuery] = useSearchParams();
  const cId = getQuery.get('cId');
  const navigate = useNavigate();
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [acceptInProcess, setAcceptInProcess] = useState(false);
  const [contract, setContract] = useState('');
  const [acceptSuccess, setAcceptSuccess] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const [showCount, setShowCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let options = {
    cMapUrl: contract,
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const renderPdf = useMemo(() => {
    return (
      <Document
        file={{ url: contract }}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map(page => (
            <div
              style={{
                margin: '2rem 0 0 0',
              }}
            >
              <Page pageNumber={page} />
            </div>
          ))}
      </Document>
    );
  }, [contract, numPages, options]);

  useEffect(() => {
    setShowCount(value => ++value);
  }, []);

  const [error, setError] = useState('');

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborations/${id}`,
          headers,
        )
        .then(res => {
          setContract(res.data.contract);
        })
        .catch(err => {});
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptSplit = e => {
    e.preventDefault();
    setAcceptInProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborations/${id}/agreement`,
        {
          status: 'accepted',
        },
        headers,
      )
      .then(res => {
        setAcceptInProcess(false);
        setAcceptSuccess(true);
      })
      .catch(err => {
        setError(err.response.data.message);
        setAcceptInProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
        return showCount;
      });
  };

  // const handleFile = async (files: any) => {
  //   handleFileUpload({
  //     files,
  //     setImages,
  //     setError,
  //     setReturnedImages,
  //   });
  // };

  const handleClose = () => {
    setError('');
  };

  return (
    <>
      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      {acceptSuccess ? (
        <Alert
          closeFunc={() => {
            handleClose();
            navigate('/split-payment');
          }}
          img="/success.png"
          message="Hooray!!! 🎉"
          miniMessage={`You have successfully joined this split`}
        />
      ) : null}

      <MainWrapper style={{ width: '100%' }} left="0" top="4.5rem">
        <FragmentHeader
          showBack={true}
          pageName={`Split Contract Sheet`}
          backLink={`/split/verify/${id}?cId=${cId}`}
        />
        <BreadcrumbArea
          style={{
            margin: '4rem 0 10rem 0',
          }}
        >
          <form onSubmit={e => acceptSplit(e)}>
            <MainFlex
              style={{
                margin: '0',
                top: 0,
                padding: 0,
              }}
            >
              <div>
                {contract ? (
                  <>{renderPdf}</>
                ) : (
                  <>
                    <p>No Contract Available for this split</p>
                  </>
                )}
              </div>
            </MainFlex>

            {contract && contract !== '' ? (
              <BigBtn
                style={{
                  width: '100%',
                  maxWidth: '500px',
                  margin: '2rem auto',
                }}
              >
                <button type="submit" disabled={acceptInProcess}>
                  {acceptInProcess ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    'Agree and Join Split'
                  )}
                </button>
              </BigBtn>
            ) : (
              <>
                <BigBtn
                  style={{
                    width: '100%',
                    maxWidth: '500px',
                    margin: '2rem auto',
                  }}
                >
                  <button type="submit" disabled={acceptInProcess}>
                    {acceptInProcess ? (
                      <BeatLoader color="#fff" />
                    ) : (
                      'Agree and Join Split'
                    )}
                  </button>
                </BigBtn>
              </>
            )}
          </form>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default ContractDisplay;
