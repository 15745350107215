export const intervalOption = [
  {
    name: 'One time',
    value: 'one_time',
  },
  {
    name: 'Every week',
    value: 'weekly',
  },
  {
    name: 'Every 2 weeks',
    value: 'bi_weekly',
  },
  {
    name: 'Every month',
    value: 'monthly',
  },
  {
    name: 'Every 2 month',
    value: 'bi_monthly',
  },
  {
    name: 'Every Quarter',
    value: 'quaterly',
  },
  {
    name: 'Every Year',
    value: 'yearly',
  },
  {
    name: 'Every Two Years',
    value: 'bi_annually',
  },
];
