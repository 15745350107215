export function handleStatusBg(state: string) {
  if (
    state === 'fully_paid'.toLowerCase() ||
    state === 'active'.toLowerCase() ||
    (state && state.toLowerCase() === 'completed') ||
    (state && state.toLowerCase() === 'successful')
  ) {
    return 'rgba(204, 234, 185, 0.68)';
  } else if (
    state === 'partially_paid'.toLowerCase() ||
    (state && state.toLowerCase() === 'pending'.toLowerCase())
  ) {
    return '#E9F1FB';
  } else if (
    state === 'unpaid'.toLowerCase() ||
    state === 'inactive'.toLowerCase() ||
    (state && state.toLowerCase() === 'failed'.toLowerCase()) ||
    (state && state.toLowerCase() === 'declined'.toLowerCase())
  ) {
    return '#FDEAE1';
  }
}

export function handleColor(state: string) {
  if (
    state === 'fully_paid'.toLowerCase() ||
    state === 'active'.toLowerCase() ||
    (state && state.toLowerCase() === 'completed') ||
    (state && state.toLowerCase() === 'successful')
  ) {
    return '#498C20';
  } else if (
    state === 'partially_paid'.toLowerCase() ||
    (state && state.toLowerCase() === 'pending'.toLowerCase())
  ) {
    return '#1F6C84';
  } else if (
    state === 'unpaid'.toLowerCase() ||
    state === 'inactive'.toLowerCase() ||
    (state && state.toLowerCase() === 'failed'.toLowerCase()) ||
    (state && state.toLowerCase() === 'declined'.toLowerCase())
  ) {
    return '#B8491A';
  }
}
