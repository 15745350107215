import styled from 'styled-components';

interface MenuHeaderProps {
  color?: string;
  border?: string;
}

export const MenuHeader = styled.div`
  display: flex;
  align-items: Center;
  gap: 30px;

  @media (max-width: 728px) {
    margin: 1rem 0 0 0;
  }
`;

export const MenuHeaderText = styled.div<MenuHeaderProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  padding: 0.3rem 0;
  color: ${p => p.color || '#b0b0b0'};
  border-bottom: ${p => p.border || 'none'};
  transition: 0.2s;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const ItemGrid = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 50px;
  margin: 27px 0;

  @media (max-width: 728px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ItemBox = styled.div`
  background: #ffffff;
  box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 11px;
  position: relative;
  cursor: pointer;

  > img {
    width: 100%;
    height: 142px;
    object-fit: cover;
    border-radius: 3px 3px 0px 0px;
  }

  > div {
    padding: 10px 0;

    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #161616;
    }
  }
`;

export const DotNotifier = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10rem;
  background: #e5e0ff;
  display: flex;
  align-items: Center;
  justify-content: Center;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;

  > div {
    width: 13px;
    height: 13px;
    border-radius: 10rem;
    background: #8975f4;
    animation-name: pause;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-direction: alternate;
  }
`;

export const SplitAccountSection = styled.div`
  display: flex;
  align-items: Center;
  gap: 11.33px;
  background: linear-gradient(
    263.37deg,
    rgba(249, 193, 193, 0.05) 55.47%,
    rgba(206, 42, 49, 0.05) 96.63%
  );
  border-radius: 6px;
  margin: 1rem 0;
  padding: 10px;
  width: 150px;

  h2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #474747;
  }

  p {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #474747;
  }
`;

export const ImageStack = styled.div`
  display: flex;
  align-items: Center;
  gap: -100px;
  position: relative;
  width: 80%;
  cursor: pointer;
  width: 50%;

  img {
    width: 28px;
    height: 28px;
    border-radius: 50px;
  }

  img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 1rem;
  }

  img:nth-child(3) {
    position: absolute;
    top: 0;
    left: 2rem;
  }

  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.04em;
    color: #ababab;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0rem;
  }
`;

export const SplitUsersPopup = styled.section`
  position: absolute;
  top: 95%;
  left: -10%;
  background: #ffffff;
  box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 15px 2rem;
  width: 20rem;
  z-index: 1000;

  > h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.001em;
    color: #757575;
  }

  @media (max-width: 728px) {
    left: -5%;
  }
`;

interface SplitUsersWrapProps {
  alignVertical?: string;
  hover?: boolean;
}

export const SplitUserMainWrap = styled.div`
  border-bottom: 0.5px solid #f0f0f0;
  position: relative;
  padding: 10px 3% 10px 3%;
  border: 0.3px solid #dadada80;
  border-radius: 16px;
  margin-bottom: 1rem;
  cursor: pointer;
  background: #fff;
  border: 0.3px solid #dadada80;
  box-shadow: 1px 2px 10px 1px #00000008;
`;

export const SplitAvatar = styled.section`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: Center;
  justify-content: Center;
  border-radius: 10rem;
  color: #fff;
  border: none;
  background: #c82b38;

  > img {
    width: 100%;
    border-radius: 50%;
  }
`;

export const SplitUsersWrap = styled.div<SplitUsersWrapProps>`
  display: flex;
  align-items: ${p => p.alignVertical || 'Center'};
  justify-content: space-between;
  padding: 0.7rem 0 0.7rem 0;
  position: relative;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  > div > input {
    width: 50%;
    padding: 5px 10px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    border-radius: 6px;
  }

  > div {
    width: 55%;

    h3 {
      width: auto;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.01em;
      color: #191919;
      display: flex;
      align-items: center;
      gap: 10px;

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: -0.01em;
        color: #ababab;
      }
    }

    p {
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: -0.01em;
      color: #757575;
    }
  }

  > p {
    width: 30%;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #474747;
  }

  @media (max-width: 728px) {
    > div {
      width: 50%;

      h3 {
        width: 70%;
      }
    }
  }

  :hover {
    opacity: ${p => (p.hover ? '0.6' : '1')};
  }
`;

interface AccountNoCenterProps {
  justify?: string;
}

export const AccountNoCenter = styled.section<AccountNoCenterProps>`
  margin: 0 auto 1rem auto;
  display: flex;
  justify-content: ${p => p.justify || 'center'};

  > div > h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #191919;
  }

  > div > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.01em;
    color: #757575;
    margin: 1rem 0 3rem 0;
  }

  @media (max-width: 728px) {
    margin: 8rem 0 0 0;
  }
`;

export const MiniHeader = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #ababab;
  margin: 1rem 0 0 0;
`;

export const SingleInfoFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  > section {
    width: 30%;
    border: 1px solid #191919;
    border-radius: 4px;
    padding: 1px;
    position: relative;

    > img {
      width: 100%;
      height: 4rem;
      object-fit: cover;
      opacity: 0.8;
    }

    > i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      background: rgba(0, 0, 0, 0.7);
      display: flex;
      align-items: Center;
      justify-content: Center;
      border-radius: 10rem;
      transform: translate(-50%, -50%);

      :hover {
        opacity: 0.5;
      }
    }
  }

  > img {
    width: 100%;
    height: 4rem;
    object-fit: cover;
    opacity: 0.7;
  }

  > div {
    width: 65%;

    > h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.01em;
      color: #474747;
    }

    > p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      margin: 0.5rem 0 0 0;
      color: #757575;
      display: flex;
      gap: 10px;

      > span {
        display: flex;
        gap: 5px;
      }
    }
  }
`;

export const SmallInputWrap = styled.section`
  position: relative;
  width: 35%;

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 10px;

    > input {
      width: 70%;
      padding: 5px 5px;
      background: #ffffff;
      border: 0.5px solid #d9d9d9;
      border-radius: 4px;
      text-align: center;
    }
  }

  > p {
    font-weight: 300;
    font-size: 10px;
    margin: 1rem 0 0 0;
    line-height: 12px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #ababab;
  }

  @media (max-width: 728px) {
    width: 48%;
  }
`;

export const PercentageInput = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  align-items: Center;
  justify-content: Center;
  gap: 2px;
  border: 0.5px solid #d9d9d9;
  background: #ffffff;
  padding: 5px 4px 5px 4px;
  border-radius: 4px;

  > input {
    width: 90%;
    padding: 5px 0;
    text-align: right;
  }
`;

export const AmountInput = styled.div`
  display: flex;
  flex-direction: row-reverse;
  /* margin: 0 0 1rem 0; */
  padding: 5px 4px 5px 4px;
  /* gap: 10px; */
  width: 80%;

  > label {
    font-size: 12px;
    font-weight: 400;
    color: #ababab;
  }

  > div {
    width: 95%;
    border: 1px solid #dadada;
    border-radius: 4px;
    display: flex;
    align-items: Center;
    gap: 10px;
    padding: 4px 5px 4px 5px;
  }

  input {
    width: 100%;
    padding: 2px 0px 2px 2px;
  }
`;

export const SettingPopup = styled.section`
  position:absolute;
  top:1.5rem;
  width:15rem;
  right:-50%;
  z-index:100;
  background:#fff;
  border: 0.2px solid #FBFBFB;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding:10px;

  > div {
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin 0 0 0.5rem 0;
    position:relative;

    > h3 {
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #000000;
    }
  }

  @media (max-width:728px){
    bottom:-5rem;
    right:0;
  }
`;

interface InputWrapProp {
  hasLabel?: boolean;
}

export const InputWrap = styled.section<InputWrapProp>`
  position: relative;
  margin: 1rem 0 1rem 0;

  > h4 {
    margin: 0 0 0.5rem 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #191919;
  }

  > label {
    font-weight: 400;
    font-size: 14px;
    padding: 0.5rem 0 0.5rem 0;
    letter-spacing: -0.02em;
    color: #161616;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  > p {
    font-weight: 400;
    font-size: 10px;
    padding: 0.5rem 0 0 0;
    text-align: right;
    letter-spacing: -0.02em;

    color: #ababab;
  }

  h5 {
    font-weight: 200;
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%);
  }

  > input {
    display: block;
    width: 100%;
    padding: ${p => (p.hasLabel ? '8px 5% 8px 20%' : '8px 5%')};
    background: #ffffff;
    border: 0.5px solid #dadada;
    border-radius: 5px;
  }

  > textarea {
    resize: none;
    display: block;
    width: 100%;
    height: 60px;
    padding: ${p => (p.hasLabel ? '8px 5% 8px 20%' : '8px 5%')};
    background: #ffffff;
    border: 0.5px solid #dadada;
    border-radius: 5px;
  }

  > div > input {
    display: block;
    width: 100%;
    padding: 8px 5%;
    background: #ffffff;
    border: 0.5px solid #dadada;
    border-radius: 5px;
  }

  select {
    width: 47%;
    padding: 8px 5%;
    background: #ffffff;
    border: 0.5px solid #dadada;
    border-radius: 5px;
    margin: 0 3% 0 0;
    font-size: 14px;
  }

  .select {
    display: block;
    width: 100%;
    padding: 8px 5%;
    background: #ffffff;
    border: 0.5px solid #dadada;
    border-radius: 5px;
  }

  option {
    font-size: 13px;
  }
`;

export const SuggestionWrap = styled.div`
  position: absolute;
  top: 80%;
  padding: 4px;
  left: 0;
  background: #ffffff;
  border: 0.2px solid #ababab;
  border-radius: 6px;
`;

export const ProgressBar = styled.div`
  width: 6rem;
  margin: 2rem auto;
  display: flex;
  align-items: Center;
  justify-content: Center;
  gap: 5px;
  z-index: 10;
  position: relative;

  @media (max-width: 728px) {
    width: 50%;
    margin: 3rem auto 0 auto;
    top: 1.5rem;
  }
`;
interface ProgressLineProps {
  bg?: string;
}

export const ProgressLine = styled.div<ProgressLineProps>`
  flex: 1;
  height: 10px;
  background: ${p => p.bg || '#dadada'};
  border-radius: 8px;
`;

export const CollabSuggestWrap = styled.div`
  position: relative;
  height: auto;
  width: 75%;
  margin: 1rem 0 0 0;

  > input {
    width: 70%;
    padding: 5px 5% 5px 2%;
    background: #ffffff;
    border: 0.2px solid #ababab;
    border-radius: 6px;
    font-size: 14px;
  }

  @media (max-width: 728px) {
    > input {
      font-size: 16px;
    }
  }

  > p {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #757575;
    margin: 0.5rem 0 0 0;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    background: #ffffff;
    padding: 10px 7px;
    margin: 0.7rem 0 0 0;
    border: 0.2px solid #dadada;
    border-radius: 6px;
    max-height: 14rem;
    overflow-y: auto;
  }

  @media (max-width: 728px) {
    width: 100%;
  }
`;

interface SplitDataProps {
  top?: string;
}

export const SplitDataToolTip = styled.div<SplitDataProps>`
  position: absolute;
  top: ${p => p.top || '80%'};
  transform: translate(0, -50%);
  right: -16rem;
  background: url('/test/tooltip-right.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 5px 13px;
  width: 15rem;
  height: 4rem;
  color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;

  > p {
    width: 45%;
    font-size: 8px;
  }

  @media (max-width: 728px) {
    display: none;
    top: 100%;
    left: 0;
    background: url('/test/tooltip-mobile.png');
    background-repeat: no-repeat;

    > p {
      margin: 0.7rem 0 0 0.5rem;
    }
  }
`;

interface AccountTooltipProps {
  showMobile?: boolean;
}

export const AccountToolTip = styled.div<AccountTooltipProps>`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -8rem;
  background: url('/test/tooltip.png');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10px;
  width: 15rem;
  height: 4rem;
  color: #fff;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;

  > p {
    width: 50%;
    font-size: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 728px) {
    display: ${p => (p.showMobile ? 'flex' : 'none')};
    top: 100%;
    left: 0;
    background: url('/test/tooltip-mobile.png');
    background-repeat: no-repeat;

    > p {
      margin: 0.7rem 0 0 0.5rem;
    }
  }
`;

export const ModalDataTable = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-around;
  width: 100%;
  cursor: pointer;
  margin: 0 0 1.6rem 0;

  // > section:first-child {
  //   text-align: left;
  // }
  // > section:nth-child(2) {
  //   text-align: center;
  // }
  // > section:nth-child(3) {
  //   text-align: right;
  // }

  > section {
    margin: 0 0 0rem 0;
    text-align: center;

    > h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #191919;
    }

    p {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #191919;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

interface TopFlexProps {
  mobileMargin?: string;
}

export const TopFlex = styled.div<TopFlexProps>`
  display: flex;
  align-items: Center;
  justify-content: space-between;
  width: 65%;
  margin: 1rem auto;

  @media (max-width: 728px) {
    width: 100%;
    margin: ${p => p.mobileMargin || '9rem 0 0 0'};
    flex-direction: column;
  }
`;

export const ViewText = styled.p`
  color: #c82b38;

  :hover {
    opacity: 0.4;
  }
`;

export const InputActPlacehold = styled.div`
  width: 100%;
  padding: 0.2rem 0%;
  position: relative;
  color: #191919;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }

  > div {
    width: 100%;
    display: flex;
    align-items: Center;
    gap: 10px;
  }

  > p {
    font-size: 12px;
    color: #191919;
  }

  > i {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(0, -50%);
    font-size: 10px;
  }
`;

export const DualWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const DualTypeWrap = styled.div`
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: flex-end;
  margin: 1rem 0;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  position: relative;
  right: 7.5%;
`;

interface DualTypeItemProps {
  borderRadius: string;
  background: string;
  color: string;
}

export const DualTypeItem = styled.div<DualTypeItemProps>`
  flex: 1;
  background: ${p => p.background || '#dedede'};
  color: ${p => p.color || '#000'};
  border-radius: ${p => p.borderRadius || 'none'};
  padding: 5px 10px;
  font-weight: 200;
  font-size: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  position: relative;

  @media (max-width: 728px) {
    padding: 5px 15px;
  }
`;

export const HeaderPopUp = styled.div`
  position: absolute;
  top: 2.5rem;
  right: 3%;
  width: 13rem;
  background: #fff;
  padding: 1rem;
  border: 0.2px solid #fbfbfb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;

  > p {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    padding: 0 0 0.6rem 0;
    letter-spacing: -0.02em;
    cursor: pointer;

    > hover {
      opacity: 0.6;
    }
  }
`;

export const FundOverview = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
  background: #fbfbfb;
  border: 0.3px solid #d9d9d9;
  padding: 10px 30px;
  margin: 1rem 0;
  color: #161616;

  > div {
    flex: 1;
    padding: 1rem 0;
  }

  > div:first-child {
    border-right: 1px solid #dadada;
  }

  > div:nth-child(2) {
    padding: 0 0 0 10%;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #757575;
  }
`;

interface BtnProps {
  bg?: string;
}
export const ApproveBtn = styled.button<BtnProps>`
  background: ${p => p.bg || '#161616'};
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  padding: 7px 15px;
  border: none;
`;

interface DisclaimerProps {
  width?: string;
}

export const Disclaimer = styled.div<DisclaimerProps>`
  display: flex;
  align-items: Center;
  width: ${p => p.width || '20rem'};
  gap: 5px;
  background: #fbfbfb;
  border: 0.3px solid #d9d9d9;
  padding: 10px;

  > div {
    width: 95%;

    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #474747;
    }

    b {
      font-weight: 500;
    }
  }
`;

export const FixedRightBottom = styled.main`
  position: fixed;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1000000000000000;
  cursor: auto;

  > div {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 23rem;
    height: 90%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: Center;
    justify-content: flex-start;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-direction: alternate;

    > div {
      width: 90%;
    }

    > main {
      width: 90%;
      margin: 0 auto;
      height: 90%;
      overflow-y: auto;
    }

    > div > div {
      width: 90%;
    }
  }

  > form {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    width: 23rem;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: Center;
    justify-content: flex-start;
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-direction: alternate;

    > div {
      width: 90%;
      overflow-y: auto;
    }
  }
`;

export const TypeCard = styled.div`
  position: relative;
  padding: 2rem 10px;
  display: grid;
  gap: 15px;
  width: 30%;
  margin: 0 auto;

  > div {
    background: #fff;
    border: 1px solid #dadada;
    padding: 15px;
    display: flex;
    align-items: Center;
    gap: 9px;
    border-radius: 10px;
    cursor: pointer;

    > img {
      width: 30px;
    }

    > div {
      width: 90%;

      h3 {
        font-weight: 500;
      }

      p {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const ActionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;

  i {
    cursor: pointer;
  }

  > div {
    width: 85%;

    h3 {
      font-weight: 600;
    }
  }
`;

export const JoinSplitImage = styled.img`
  width: 130px;
  height: 98px;
  object-fit: Cover;
  border-radius: 8px;
  border: 0.5px solid #c3c5c1;
  display: block;
  margin: 1rem auto;
`;

export const Checklist = styled.div`
  position: relative;
  display: grid;
  grid-gap: 10px;
  margin: 2rem 0 0 0;
  color: #4f4f4f;

  > div {
    display: flex;
    gap: 8px;

    > p {
      width: 90%;
      font-weight: 200;
    }
  }
`;
export const RoleCardWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

interface RoleCardProps {
  color?: string;
  bg?: string;
  border?: string;
}

export const RoleCard = styled.div<RoleCardProps>`
  padding: 7px 20px;
  background: ${p => p.bg || '#8975f4'};
  border-radius: 20px;
  color: ${p => p.color || '#fff'};
  border: ${p => p.border || 'none'};
  font-size: 11px;
  display: flex;
  align-items: Center;
  gap: 7px;
  max-width: 250px;
  cursor: pointer;

  > p {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  :hover {
    opacity: 0.6;
  }
`;

export const ContractTypeList = styled.div`
  position: relative;
  margin: 1rem 0 0 0;

  > div {
    display: flex;
    gap: 4px;
    margin: 1rem 0;
    align-items: Center;
    font-size: 11px;
  }
`;

export const ContractMainWrap = styled.section`
  width: 74.5%;
  height: 90%;
  overflow-y: auto;
  position: absolute;
  top: 4.73rem;
  left: 0;
  background: #f3f3f3;
  padding: 2rem 0;
`;

export const ContractCentered = styled.div`
  width: 60%;
  margin: 2rem auto;
  padding: 20px;
  background: #fff;

  > h3 {
    color: #333333;
    font-weight: 900;
  }

  > p {
    color: #333333;
    font-weight: 300;
    font-size: 13px;
    line-height: 25px;
  }

  table {
    width: 100%;
    margin: 2rem 0 0 0;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th {
    font-weight: 500;
    text-align: left;
  }

  th,
  td {
    padding: 5px;
  }

  td {
    color: #333333;
    font-weight: 300;
    font-size: 13px;
  }

  tr {
    background-color: #f4f4f4;
  }

  tr:nth-child(even) {
    background-color: #e8e8e8;
  }
`;

export const SignSection = styled.div`
  margin: 3rem 0;

  > div {
    display: flex;
    align-items: Center;
    justify-content: space-between;
    margin: 5rem 0 0 0;
  }
`;

export const SignCard = styled.div`
  width: 65%;

  p {
    padding: 12px 0 0 0;
    font-size: 13px;
  }
`;

export const DateCard = styled.div`
  width: 30%;

  p {
    padding: 12px 0 0 0;
    font-size: 13px;
  }
`;

// New Single Split
export const SingleFlex = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;

  > div:first-child {
    width: 25%;
    border-right: 0.5px solid #dcdcdc;

    @media (max-width: 728px) {
      display: none;
    }
  }

  > div:nth-child(2) {
    width: 70%;
    padding: 0 2rem;
  }

  @media (max-width: 728px) {
    flex-direction: column;
    margin: 10rem 0 0 0;

    > div:first-child {
      width: 100%;
      border-right: none;
    }

    > div:nth-child(2) {
      width: 100%;
      padding: 0;
    }
  }
`;

export const SplitImage = styled.img`
  width: 253px;
  height: 296px;
  object-fit: cover;
  border-radius: 35px;
  box-shadow: 0px 0.9625px 1.79219px 0px rgba(119, 119, 119, 0.04),
    0px 4.4px 5.0375px 0px rgba(119, 119, 119, 0.05),
    0px 11.1375px 13.51406px 0px rgba(119, 119, 119, 0.07),
    0px 22px 31px 0px rgba(119, 119, 119, 0.11);

  @media (max-width: 728px) {
    width: 100%;
    height: auto;
  }
`;

export const CollaboratorSpace = styled.div`
  position: relative;

  > div {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    position: relative;
    left: calc(50% - 50px);
    align-items: Center;
    justify-content: center;
    background: #262626;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    margin-top: 20px;
  }

  > p {
    width: 100%;
    padding: 1rem 0 0 0;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
  }

  @media (max-width: 728px) {
    margin: 3rem 0 0 0;

    > div {
      width: 110px;
      margin: 0 auto;
    }
  }
`;

export const OwnWrap = styled.div`
  margin: 4rem auto 2rem 0;
  width: 72%;
  position: relative;
  border-radius: 20px;
  border: 1px solid #f6f6f6;
  background: #fbfbfb;
  box-shadow: -0.0329255685210228px 0.42803236842155457px 2.085156202316284px -3px
      rgba(0, 0, 0, 0.01),
    -0.12247472256422043px 1.5921714305877686px 3.9312500953674316px -3px
      rgba(0, 0, 0, 0.01),
    -0.25480613112449646px 3.3124797344207764px 6.57421875px -3px
      rgba(0, 0, 0, 0.02),
    -0.41607850790023804px 5.40902042388916px 11.050000190734863px -3px
      rgba(0, 0, 0, 0.02),
    -0.5924504995346069px 7.70185661315918px 18.39453125px -3px
      rgba(0, 0, 0, 0.03),
    -0.7700808048248291px 10.0110502243042px 29.643749237060547px -3px
      rgba(0, 0, 0, 0.03),
    -0.9351280331611633px 12.156664848327637px 45.833595275878906px -3px
      rgba(0, 0, 0, 0.03),
    -1.0737509727478027px 13.958763122558594px 68px -3px rgba(0, 0, 0, 0.04);
  background: #fff;
  padding: 15px 15px 15px 110px;

  > img {
    position: absolute;
    top: 50%;
    left: -1rem;
    transform: translate(0, -50%);
    width: 105px;
  }

  > div {
    position: absolute;
    top: 50%;
    left: -1rem;
    transform: translate(0, -50%);
  }
`;

export const CollabItem = styled.div`
  display: flex;
  align-items: Center;
  gap: 10px;
  margin: 2rem 0;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #ababab;

  > div:first-child {
    width: 80px;
    height: 80px;
    border-radius: 10rem;
    display: flex;
    align-items: Center;
    justify-content: center;
    background: #262626;
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    margin: 0 auto;
  }

  > div:nth-child(2) {
    width: 70%;

    > h3 {
      font-size: 18px;
      font-weight: 500;
    }

    > p {
      font-size: 12px;
      font-weight: 300;
    }
  }
`;

export const ScrollDiv = styled.div`
  width: auto;
  min-height: 18rem;
  max-height: 25rem;
  overflow: auto;
`;

export const FrequencyPayment = styled.div`
  width: auto;
  margin-top: 10px;
  padding-top: 15px;
`;

export const FrequenctWrapper = styled.div`
  width: auto;
  height: 65px;
  margin-bottom: 10px;

  h3 {
    margin-bottom: 5px;
    color: black;
  }
`;

export const UniqueAccount = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ToggleButtons = styled.div`
  width: 100px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
