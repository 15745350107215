import React from 'react';
import styled from 'styled-components';
import * as Icon from 'react-feather';

interface Props {
  next?: any;
  previous?: any;
  goToSlide?: any;
  rest?: any;
  carouselState?: any;
}

const ButtonGroup = ({ next, previous, goToSlide, ...rest }: Props) => {
  const {
    carouselState: { currentSlide },
  } = rest;

  return (
    <ButtonWrap className="carousel-button-group">
      <button
        className={currentSlide === 0 ? 'disable' : ''}
        onClick={() => previous()}
      >
        <Icon.ChevronLeft size={14} />
      </button>
      <button onClick={() => next()}>
        <Icon.ChevronRight size={14} />
      </button>
      {/* <ButtonThree onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </ButtonThree> */}
    </ButtonWrap>
  );
};

export default ButtonGroup;

const ButtonWrap = styled.div`
  display: none;
  width: 100%;
  gap: 5px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);

  > button:first-child {
    width: 2rem;
    height: 2rem;
    border-radius: 10rem;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: Center;
    justify-content: Center;
    background: rgba(0, 0, 0, 0.1);
  }

  > button:nth-child(2) {
    width: 2rem;
    height: 2rem;
    border-radius: 10rem;
    position: absolute;
    right: 0rem;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: Center;
    justify-content: Center;
    background: rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 728px) {
    display: flex;
  }
`;
