import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  AlignedFlex,
  BasicCard,
  CloseBtn,
  OptionImg,
} from './../dashboard/mainContent/fundWallet/style';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';

interface PropsArgs {
  amount?: string | number;
  point?: string;
  to?: string;
  sourceAmount?: string | number;
  exchangeType?: string;
  currencyPair?: string;
  closeFunc: any;
}

const TransferOption = ({
  closeFunc,
  amount,
  sourceAmount,
  point,
  to,
  exchangeType,
  currencyPair,
}: PropsArgs) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header
            style={{
              justifyContent: 'center',
            }}
          >
            <p>Send funds to</p>
          </Header>
          <p
            style={{
              width: '20rem',
              fontSize: '12px',
              color: 'rgba(0, 0, 0, 0.6)',
            }}
          >
            Please select an option below for fund transfer
          </p>
          {to === 'NGN' ? (
            <div>
              <Link
                to={`/ngn-transfer/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}`}
              >
                <BasicCard>
                  <AlignedFlex>
                    <OptionImg>
                      <p>&#8358;</p>
                    </OptionImg>
                    <div
                      style={{
                        width: '80%',
                      }}
                    >
                      <p>NGN Account</p>
                      <span>Transfer funds to a Nigerian bank account</span>
                    </div>
                  </AlignedFlex>
                </BasicCard>
              </Link>
              <Link
                to={`/mage-transfer/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}&to=${to}`}
              >
                <BasicCard>
                  <AlignedFlex>
                    <OptionImg>
                      <p>&#8358;</p>
                    </OptionImg>
                    <div
                      style={{
                        width: '80%',
                      }}
                    >
                      <p>Mage User</p>
                      <span>Transfer funds to a Mage user</span>
                    </div>
                  </AlignedFlex>
                </BasicCard>
              </Link>
            </div>
          ) : null}
          {to === 'USD' ? (
            <div>
              <Link
                to={`/dom-transfer/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}`}
              >
                <BasicCard>
                  <AlignedFlex>
                    <OptionImg>
                      <p>&#36;</p>
                    </OptionImg>
                    <div
                      style={{
                        width: '80%',
                      }}
                    >
                      <p>DOM</p>
                      <span>Transfer funds to a Domiciliary account</span>
                    </div>
                  </AlignedFlex>
                </BasicCard>
              </Link>
              <Link
                to={`/wire-transfer/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}`}
              >
                <BasicCard>
                  <AlignedFlex>
                    <OptionImg>
                      <p>&#36;</p>
                    </OptionImg>
                    <div
                      style={{
                        width: '80%',
                      }}
                    >
                      <p>Wire Transfer</p>
                      <span>Transfer funds through wire transfer</span>
                    </div>
                  </AlignedFlex>
                </BasicCard>
              </Link>
              <Link
                to={`/mage-transfer/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}&to=${to}`}
              >
                <BasicCard>
                  <AlignedFlex>
                    <OptionImg>
                      <p>&#36;</p>
                    </OptionImg>
                    <div
                      style={{
                        width: '80%',
                      }}
                    >
                      <p>Mage User</p>
                      <span>Transfer funds to a Merchro user</span>
                    </div>
                  </AlignedFlex>
                </BasicCard>
              </Link>
            </div>
          ) : null}
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default TransferOption;
