import React, { useEffect, useState, createRef } from 'react';
import AppHeader from './../storeComp/mainContent/header/header';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import {
  TableWrap,
  TableHeader,
  IconWrap,
  NameWrap,
  PurposeWrap,
  DateWrap,
  AmountWrap,
  MenuWrap,
  MenuContent,
  SearchPos,
  MobileHistory,
} from './../transactions/style';
import {
  RightWidget,
  RightCards,
  HistoryWidget,
  HistoryItemCard,
  HistoryInfoSec,
  HistoryAmount,
  HistoryTextWrap,
} from '../dashboard/mainContent/style';
import {
  PaginationWrapper,
  PaginationCont,
  PaginationTextWrap,
} from '../merch/productList/styles';
import { SearchArea } from '../orderComp/mainContent/sectionOne/styles';
import { HistoryItemIconWrap } from '../dashboard/mainContent/style';
import * as Icon from 'react-feather';
import { handleBg } from 'utils/handleTransIcon';
import axios from 'axios';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { MoonLoader } from 'react-spinners';
import moment from 'moment';
import ReportTransaction from './../transactions/report';
import Details from './../transactions/details';
import Receipt from './../transactions/receipt';
import generatePDF from 'react-to-pdf';
import CommaNumber from 'comma-number';
import EmptyState from '../emptyState/emptyState';
import 'react-multi-carousel/lib/styles.css';
import { InAppHeader } from './../storeComp/mainContent/styles';
import StatementPreview from './../transactions/statement';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { FlexedApart } from '../merch/singleMerch/style';
import BankTransfer from '../dashboard/mainContent/fundWallet/bankTransfer';
import TransferOption from './../transactions/transferOption';
import DollarBankTransfer from './../transactions/dollarBankTransfer';
import DollarTransferOption from './../transactions/dollarTransferption';
import { useParams } from 'react-router';
import { getRequest } from 'utils/request';

const CardTransaction = () => {
  const { id } = useParams();
  const width = window.innerWidth;
  const [toggleActive, setToggleActive] = useState(false);
  const [toggleId, setToggleId] = useState('');
  const [showBank, setShowBank] = useState<boolean>(false);
  const [showTransferOption, setTransferOption] = useState(false);
  const [showDollarBank, setShowDollarBank] = useState(false);
  const [showDollarTransferOption, setShowDollarTransferOption] =
    useState(false);

  // Transaction History
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [history, setHistory] = useState<any>('');
  const [historyResult, setHistoryResult] = useState<any>([]);

  var [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [totalResults, setTotalResults] = useState(0);

  const handleToggleActive = id => {
    setToggleActive(true);
    setToggleId(id);
  };

  const handleIncrement = () => {
    if (page !== totalPages) {
      setPage(++page);
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/transactions?include=user&page=${page}&sortBy=createdAt:desc`,
          { headers: { Authorization } },
        )
        .then(res => {
          setHistory(res.data.results);
          setHistoryResult(res.data.results);
          setTotalPages(res.data.totalPages);
          setTotalResults(res.data.totalResults);
        })
        .catch(err => {});
    }
  };

  const handleDecrement = () => {
    if (page > 1) {
      setPage(--page);
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/transactions?include=user&page=${page}&sortBy=createdAt:desc`,
          { headers: { Authorization } },
        )
        .then(res => {
          setHistory(res.data.results);
          setHistoryResult(res.data.results);
          setTotalPages(res.data.totalPages);
          setTotalResults(res.data.totalResults);
        })
        .catch(err => {});
    }
  };

  useEffect(() => {
    getRequest(`payments/cards/${id}/transactions&sortBy=createdAt:desc`)
      .then(res => {
        setHistory(res);
        setHistoryResult(res);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
      })
      .catch(err => {});
  }, [id]);

  // Handle Search

  const [isFound, setIsFound] = useState(false);
  const [searchText, setSearchText] = useState('');
  const newSearch = () => {
    getRequest(
      `payments/transactions?include=user&sortBy=createdAt:desc&text=${searchText}`,
    )
      .then(res => {
        if (res.results.length > 0) {
          setIsFound(true);
          return isFound;
        }
        setHistory(res.results);
        setHistoryResult(res.results);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
      })
      .catch(err => {});
  };

  // Report Tab
  const [reportModal, setReportModal] = useState(false);

  // const

  const toggleReportModal = item => {
    setReportModal(!reportModal);
    setSelected(item);
  };

  const closeReportModal = () => {
    setReportModal(false);
  };

  //Details Tab
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo') || '{}');
  const [detailsModal, setDetailsModal] = useState(false);
  const [selected, setSelected] = useState<any>({});

  const toggleDetailsModal = item => {
    setDetailsModal(!detailsModal);
    setSelected(item);
  };

  // PDF Download

  const ref: any = createRef();
  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: [11, 7],
  };

  const handlePdf = item => {
    setSelected(item);
  };

  const setReceiptItem = item => {
    setSelected(item);
  };

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const closeModal = () => {
    setError('');
    setSuccess(false);
  };

  return (
    <>
      <StatementPreview />

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An Error Occured!!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Sent!"
          miniMessage={'Your account statement has been sent to your inbox.'}
        />
      ) : null}

      {showBank ? (
        <BankTransfer
          closeFunc={() => setShowBank(false)}
          accountName={accountInfo.accountInfo.accountName}
          accountNo={accountInfo.accountInfo.accountNumber}
          bankName={accountInfo.accountInfo.accountBank}
        />
      ) : null}

      {showDollarBank ? (
        <DollarBankTransfer
          closeFunc={() => setShowDollarBank(false)}
          accountNo={''}
        />
      ) : null}

      {showDollarTransferOption ? (
        <DollarTransferOption
          closeFunc={() => setShowDollarTransferOption(false)}
        />
      ) : null}

      {showTransferOption ? (
        <TransferOption closeFunc={() => setTransferOption(false)} />
      ) : null}

      <MainWrapper
        onClick={() => {
          setToggleActive(false);
        }}
        mobileTop="4rem"
      >
        <AppHeader
          showBack={true}
          pageName={`Transactions (${totalResults})`}
        />
        <InAppHeader
          style={{
            display: 'flex',
          }}
          mobileTop="-1rem"
        >
          <h3>Card Transactions</h3>
        </InAppHeader>
        <BreadcrumbArea>
          <SearchPos
            style={{
              top: '1.5rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <FlexedApart
              style={
                width < 728
                  ? {
                      width: '100%',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }
                  : {
                      width: '40%',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }
              }
            >
              <SearchArea
                style={{
                  width: '90%',
                  display: 'block',
                  position: 'relative',
                }}
              >
                <i onClick={() => newSearch()}>
                  <Icon.Search />
                </i>
                <input
                  type="text"
                  placeholder="Search your transactions"
                  onChange={e => setSearchText(e.target.value)}
                />
              </SearchArea>
            </FlexedApart>
          </SearchPos>
          <TableWrap>
            <TableHeader bg="" border="none" style={{ margin: '3rem 0 0 0' }}>
              <IconWrap></IconWrap>
              <PurposeWrap>TRANSACTION MEMO</PurposeWrap>
              <DateWrap>DATE</DateWrap>
              <NameWrap>Status</NameWrap>
              <AmountWrap>AMOUNT</AmountWrap>
              <MenuWrap></MenuWrap>
            </TableHeader>
            {history !== '' ? (
              <>
                {history !== '' && historyResult.length > 0 ? (
                  <>
                    {historyResult.map((item, index) => (
                      <>
                        <TransactionCard
                          item={item}
                          key={index}
                          reference={item.reference}
                          id={item.id}
                          type={item.entry}
                          payerName={item.meta.payerName}
                          accountName={item.meta.accountName}
                          purpose={item.purpose}
                          updatedAt={item.updatedAt}
                          amount={item.amount}
                          openReport={toggleReportModal}
                          openDetails={toggleDetailsModal}
                          handlePdf={handlePdf}
                          options={options}
                          propRefs={ref}
                          handleSelect={setReceiptItem}
                          toggleId={toggleId}
                          setToggleActive={setToggleActive}
                          setToggleId={setToggleId}
                          toggleActive={toggleActive}
                          toggleMenu={handleToggleActive}
                        />
                      </>
                    ))}

                    {/* Mobile Section of History */}

                    <MobileHistory>
                      <RightWidget>
                        <RightCards>
                          <HistoryWidget>
                            {historyResult.map((item, index) => (
                              <MobileTransCard
                                item={item}
                                key={index}
                                reference={item.reference}
                                type={item.entry}
                                id={item.id}
                                payerName={item.meta.payerName}
                                accountName={item.meta.accountName}
                                purpose={item.purpose}
                                updatedAt={item.updatedAt}
                                amount={item.amount}
                                openReport={toggleReportModal}
                                openDetails={toggleDetailsModal}
                                handlePdf={handlePdf}
                                options={options}
                                propRefs={ref}
                                handleSelect={setReceiptItem}
                                toggleId={toggleId}
                                setToggleActive={setToggleActive}
                                setToggleId={setToggleId}
                                toggleActive={toggleActive}
                                toggleMenu={handleToggleActive}
                              />
                            ))}
                          </HistoryWidget>
                        </RightCards>
                      </RightWidget>
                    </MobileHistory>

                    {totalPages > 1 && (
                      <PaginationWrapper>
                        <PaginationCont>
                          <p onClick={() => handleDecrement()}>
                            <Icon.ChevronLeft />
                          </p>
                          <PaginationTextWrap>
                            <h4>{page}</h4>
                            <span>of</span>
                            <h4>{totalPages}</h4>
                          </PaginationTextWrap>
                          <p onClick={() => handleIncrement()}>
                            <Icon.ChevronRight />
                          </p>
                        </PaginationCont>
                      </PaginationWrapper>
                    )}
                  </>
                ) : (
                  <>
                    <EmptyState
                      img="/invoice.svg"
                      header=""
                      extraText=" "
                      linkMask=" "
                      link=" "
                    />
                  </>
                )}
              </>
            ) : (
              <CenteredAnimeWrap>
                <MoonLoader size={40} color="#c82b32" />
              </CenteredAnimeWrap>
            )}
          </TableWrap>
        </BreadcrumbArea>

        {reportModal ? (
          <ReportTransaction
            closeFunc={closeReportModal}
            header="Report Transaction"
            subText="Tell us what went wrong with this transaction"
            props={selected}
          />
        ) : null}

        {detailsModal ? (
          <Details props={selected} closeFunc={toggleDetailsModal} />
        ) : null}

        <Receipt
          props={selected}
          propRef={ref}
          accountNumber={accountInfo.accountInfo.accountNumber}
          accountBank={accountInfo.accountInfo.bankName}
          firstName={loggedUser.user.firstName}
          lastName={loggedUser.user.lastName}
        />
      </MainWrapper>
    </>
  );
};

export default CardTransaction;

interface PropsArgs {
  item: any;
  type: any;
  id: any;
  reference: string;
  key: any;
  payerName: any;
  accountName: any;
  purpose: any;
  updatedAt: any;
  amount: any;
  openReport: any;
  openDetails: any;
  handlePdf: any;
  propRefs: any;
  options: any;
  handleSelect: any;
  toggleActive;
  toggleId;
  setToggleId;
  setToggleActive;
  toggleMenu;
}

const MobileTransCard = ({
  item,
  type,
  payerName,
  key,
  reference,
  accountName,
  updatedAt,
  amount,
  openReport,
  openDetails,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  toggleId,
  setToggleId,
  setToggleActive,
  toggleMenu,
}: PropsArgs) => {
  return (
    <>
      <HistoryItemCard key={key} onClick={() => openDetails(item)}>
        <HistoryItemIconWrap
          customBg={() => handleBg(type.toLocaleLowerCase(), '')}
        >
          {type === 'DEBIT' ? (
            <Icon.ArrowUpRight color="rgba(200, 43, 50, 1)" />
          ) : (
            <Icon.ArrowDownLeft color="rgba(107, 211, 43, 1)" />
          )}
        </HistoryItemIconWrap>
        <HistoryTextWrap>
          {item.description}
          <p>{moment(item.createdAt).format('LLL')}</p>
        </HistoryTextWrap>
        <HistoryInfoSec>
          <HistoryAmount>
            {item.currency === 'USD' ? '$' : <span>&#8358;</span>}
            {CommaNumber(amount)}
          </HistoryAmount>
        </HistoryInfoSec>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="3rem"
            onClick={e => {
              setToggleActive(false);
            }}
          >
            <p
              onClick={() => {
                generatePDF(propRefs, { filename: `mage-${reference}.pdf` });
                handleSelect(item);
              }}
            >
              Download receipt
            </p>
            <p>
              <a href="mailto:hello@trymage.com">Report transactions</a>
            </p>

            <p onClick={() => openDetails(item)}>More details</p>
          </MenuContent>
        ) : null}
      </HistoryItemCard>
    </>
  );
};

const TransactionCard = ({
  item,
  type,
  id,
  reference,
  key,
  payerName,
  accountName,
  purpose,
  updatedAt,
  amount,
  openReport,
  openDetails,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  toggleId,
  setToggleId,
  setToggleActive,
  toggleMenu,
}: PropsArgs) => {
  return (
    <>
      <TableHeader
        bg="#fff"
        border=""
        key={key}
        onClick={() => openDetails(item)}
      >
        <IconWrap>
          <HistoryItemIconWrap
            customBg={() => handleBg(type.toLocaleLowerCase(), '')}
          >
            {type === 'DEBIT' ? (
              <Icon.ArrowUpRight color="rgba(200, 43, 50, 1)" />
            ) : (
              <Icon.ArrowDownLeft color="rgba(107, 211, 43, 1)" />
            )}
          </HistoryItemIconWrap>
        </IconWrap>
        <PurposeWrap>
          {'description' in item ? item.description : 'No memo attached'}
        </PurposeWrap>
        <DateWrap>{moment(item.createdAt).format('LLL')}</DateWrap>
        <AmountWrap>
          {item.currency === 'USD' ? '$' : <span>&#8358;</span>}
          {CommaNumber(amount)}
        </AmountWrap>
        <NameWrap>
          <p>{item.status}</p>
        </NameWrap>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="6rem"
            onClick={e => {
              setToggleActive(false);
            }}
          >
            <p
              onClick={() => {
                generatePDF(propRefs, { filename: `mage-${reference}.pdf` });
                handleSelect(item);
              }}
            >
              Download receipt
            </p>
            <p>
              <a href="mailto:hello@trymage.com">Report transactions</a>
            </p>
            <p onClick={() => openDetails(item)}>More details</p>
          </MenuContent>
        ) : null}
      </TableHeader>
    </>
  );
};
