import React, { useEffect } from 'react';
import {
  MainWrapper,
  FlexApart,
  Constraint,
  BlueText,
  // Upload,
} from './style';
import { BreadcrumbArea } from './../../orderComp/mainContent/orderContent/styles';
import {
  PreferenceBlock,
  Important,
} from '../../Preference/mainContent/preferenceContent/styles';
import {
  ButtonFlex,
  CustomButtonLight,
  FlexedArea,
  ButtonWrap,
  CustomButtonDark,
} from './../../storeComp/mainContent/sectionOne/styles';
import { MainFlex, PairSection } from './singleStyle';
import NewClient from './newClient';
import { useState, createRef } from 'react';
import * as Icons from 'react-feather';
import Preview from './preview';
import generatePDF from 'react-to-pdf';
import Reminder from './reminder';
import axios from 'axios';
import PopUpModal from '../../merch/popupModal';
import { useNavigate } from 'react-router';
import CreateReminder from './createReminder';
import { TicketInput } from 'app/components/paymentLink/style';
import { GenericBtn } from 'app/components/paymentLink/style';
import InvoiceNote from './invoiceNote';
import OptionalCost from './optionalCost';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import CreateWarning from './createWarning';
import { numOnly } from 'utils/numOnly';
import html2canvas from 'html2canvas';
import AddSplitModal from './splitsModal';
import { FlexedApart } from 'app/components/merch/singleMerch/style';
import * as Icon from 'react-feather';
import CommaNumber from 'comma-number';
import Alert from 'app/components/dashboard/mainContent/fundWallet/alert';
import {
  CollabSuggestWrap,
  SplitUsersWrap,
  SplitAvatar,
  SplitUsersPopup,
} from 'app/components/split/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { SearchPos } from 'app/components/transactions/style';
import CreateProduct from './createProduct';
import EditProduct from './editProduct';
import { QuantityToggleWrap } from 'app/components/merch/addMerch/styles';
import { getRequest } from 'utils/request';

const AddItem = () => {
  const width = window.innerWidth;
  const navigate = useNavigate();
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [clientErr, setClientErr] = useState('');
  // Toggle Modals
  const [openAddSplit, setOpenAddSplit] = useState(false);
  const [openInvoiceNote, setOpenInvoiceNote] = useState(false);
  const [optionalCost, setOptionalCost] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);

  const toggleOpenNote = () => {
    setOpenInvoiceNote(!openInvoiceNote);
  };

  const toggleOptionalCost = () => {
    setOptionalCost(!optionalCost);
  };

  const toggleOpenWarning = () => {
    if (clientName !== '' && dueDate !== '') {
      setOpenWarning(!openWarning);
      generateImage();
    } else {
      setOnProcess(false);
      setErrorMessage('Please fill all required input fields!');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  // Handle CLients
  const store = JSON.parse(localStorage.getItem('store') || '{}');
  const [clients, setClients] = useState<any>([]);
  const [clientId, setClientId] = useState('');
  const [openClient, setOpenClient] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/invoices/client`, {
        headers: { Authorization },
      })
      .then(res => {
        setClients(res.data);
        setClientsModified(res.data);
      })
      .catch(err => {});
  }, [Authorization, openClient]);

  // Reminder Section
  const [openReminder, setOpenReminder] = useState(false);
  const [openCreateReminder, setOpenCreateReminder] = useState(false);

  const toggleReminder = () => {
    setOpenReminder(!openReminder);
  };

  const toggleCreateReminder = () => {
    setOpenCreateReminder(!openCreateReminder);
  };

  const handleClientModal = () => {
    setOpenClient(!openClient);
  };

  const [itemArray, setItemsArray] = useState<any>([
    { itemDetails: '', quantity: 1, amount: 0 },
  ]);

  let [itemsNumber, setItemsNumber] = useState<number>(1);
  const handleItemIncrement = () => {
    if (itemsNumber <= 10) {
      let subArray = [...itemArray];
      setItemsNumber(++itemsNumber);
      subArray.push({ itemDetails: '', quantity: 1, amount: 0 });
      setItemsArray([...subArray]);
    }
  };

  const handleDelete = index => {
    let mockData: any = [];
    if (itemArray.length > 1) {
      for (let i = 0; i < itemArray.length; i++) {
        if (i !== index) {
          mockData.push(itemArray[i]);
        }
      }
      setItemsArray(mockData);
    }
  };

  // Get Input

  const addName = (e, index) => {
    itemArray[index].itemDetails = e;
    setItemsArray([...itemArray]);
  };

  const addquantity = (e, index) => {
    itemArray[index].quantity = e.target.value;
    setItemsArray([...itemArray]);
  };

  const addamount = (e, index) => {
    itemArray[index].amount = e;
    setItemsArray([...itemArray]);
  };

  const addProductToArray = (itemName, itemCost, index) => {
    if (itemArray.length > 0) {
      for (let i = 0; i < itemArray.length; i++) {
        if (itemArray[i].itemDetails !== itemName) {
          addamount(itemCost, index);
          addName(itemName, index);
          return;
        } else {
          setClientErr(
            'This item is in the list already. You cannot add it twice.',
          );
          setTimeout(() => {
            setClientErr('');
          }, 4000);
          return;
        }
      }
    } else {
      addamount(itemCost, index);
      addName(itemName, index);
    }
  };

  function sum(input) {
    if (toString.call(input) !== '[object Array]') return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }

  let newItem: any = [];

  if (itemArray && itemArray.length > 0) {
    for (var i = 0; i < itemArray.length; i++) {
      newItem.push(itemArray[i].amount * itemArray[i].quantity);
    }
  }

  const currentDate = new Date().toISOString().split('T')[0];
  const [beforeDueDate, setBeforeDueDate] = useState<any>('');
  const [onDueDate, setOnDueDate] = useState<any>('');
  const [afterDueDate, setAfterDueDate] = useState<any>('');

  // State Update
  const [clientActionMode, setClientActionMode] = useState<string>('create');
  const [clientSelectedForEdit, setClientSelectedForEdit] = useState<any>({});
  const [clientInputValue, setClientInputValue] = useState<string>('');
  const [clientName, setClientName] = useState('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [country, setCountry] = useState('');
  const [discount, setDiscount] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const [taxType, setTaxType] = useState('%');
  const [discountType, setDiscountType] = useState('%');
  const [shipping, setShipping] = useState<number>(0);

  // Get All Total
  const subTotal: any = sum(newItem);

  const taxPercent: any = () => {
    if (taxType === '%') {
      return (tax / 100) * (subTotal + shipping - discountPercent());
    } else if (taxType === 'NGN') {
      return tax;
    }
  };

  const discountPercent: any = () => {
    if (discountType === '%') {
      return (discount / 100) * subTotal;
    } else if (discountType === 'NGN') {
      return discount;
    }
  };

  const grandTotal = subTotal + shipping - discountPercent() + taxPercent();

  // PDF Download

  const ref: any = createRef();

  /**
   * Geneamount random characters with specified length
   * @param {Int16Array} length
   * @param {string} type e.g "num" - Numbers only, "alpha" - letters only (upper & lower), "upper" - Uppercase letters only, "lower" - Lowercase letters only, "upper-num" - A mix of uppercase letters & number, "lower-num" - A mix of lowercase letters and numbers, "alpha-num" - A mix of letters and numbers
   * @returns {string} e.g 'som3RandomStr1ng';
   */

  const geneamountRandomChar = (length = 32, type = 'alpha-num') => {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    if (type === 'num') characters = '0123456789';
    if (type === 'upper-num')
      characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ0123456789';
    if (type === 'lower-num')
      characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    if (type === 'upper') characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (type === 'lower') characters = 'abcdefghijklmnopqrstuvwxyz';
    if (type === 'alpha')
      characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const [invoiceCode, setInvoiceCode] = useState<string>('');
  const [refNo, setRefNo] = useState<string>('');

  const [noItem, setNoItem] = useState(false);

  const handleNoItem = (action: any) => {
    if (itemArray[0].name !== '') {
      action();
      setInvoiceCode(`INV-${geneamountRandomChar(10, 'upper-num')}`);
      setRefNo(`${geneamountRandomChar(16, 'num')}`);
    } else {
      setNoItem(true);
      setTimeout(() => {
        setNoItem(false);
      }, 4000);
    }
  };

  const closeModal = () => {
    setNoItem(false);
  };

  // Handle Send Invoice

  const [onProcess, setOnProcess] = useState(false);
  const [response, setResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [invoiceNote, setInvoiceNote] = useState('');
  const [dueDate, setDueDate] = useState<any>('');
  const [beforeDate, setBeforeDate] = useState('');
  const [afterDate, setAfterDate] = useState('');

  useEffect(() => {
    if (dueDate && beforeDueDate && dueDate !== '' && beforeDueDate !== '') {
      let targetDate = new Date(dueDate);
      targetDate.setDate(targetDate.getDate() - beforeDueDate);
      let dd = targetDate.getDate();
      let mm = targetDate.getMonth() + 1;
      let yyyy = targetDate.getFullYear();
      setBeforeDate(yyyy + '-' + mm + '-' + dd);
    }
  }, [dueDate, beforeDueDate]);

  const generateImage = () => {
    const input: any = document.getElementById('divToPrint');
    html2canvas(input).then(canvas => {
      const imgData = canvas.toDataURL('application/pdf');

      const formData = new FormData();
      formData.append('file', imgData);
      formData.append(
        'upload_preset',
        `${process.env.REACT_APP_UPLOAD_PRESET}`,
      );

      axios
        .post(
          `${process.env.REACT_APP_CLOUDINARY_URL}/${process.env.REACT_APP_CLOUDINARY_USERNAME}/image/upload`,
          formData,
        )
        .then(res => {
          return res.data.secure_url;
        })
        .catch(err => {});
    });
  };

  useEffect(() => {
    if (dueDate && afterDueDate && dueDate !== '' && afterDueDate !== '') {
      let targetDate = new Date(dueDate);
      targetDate.setDate(targetDate.getDate() + afterDueDate);
      let dd = targetDate.getDate();
      let mm = targetDate.getMonth() + 1;
      let yyyy = targetDate.getFullYear();
      setAfterDate(yyyy + '-' + mm + '-' + dd);
    }
  }, [dueDate, afterDueDate]);

  const handleSendInvoice = async () => {
    const option = {
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${loggedUser.tokens.access.token}`,
      },
    };

    const apiPayload: any = {
      client: clientId,
      items: itemArray,
      discount: discount,
      tax: tax,
      shipping: shipping,
      dueDate: dueDate,
      reminder: {},
      redirectUrl: `https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/invoice-validate`,
    };

    setOnProcess(true);

    if (invoiceNote !== '') {
      apiPayload.invoiceNote = invoiceNote;
    }
    if (beforeDate !== '') {
      apiPayload.reminder['beforeDueDate'] = beforeDate;
    }
    if (onDueDate !== '') {
      apiPayload.reminder['onDueDate'] = dueDate;
    }
    if (afterDate !== '') {
      apiPayload.reminder['afterDueDate'] = afterDate;
    }
    if (beforeDueDate !== '' || onDueDate !== '' || afterDueDate !== '') {
      apiPayload['sendInvoiceNow'] = true;
    }
    if (invoiceNote !== '') {
      apiPayload['invoiceNote'] = invoiceNote;
    }
    if (selectedSplit && Object.keys(selectedSplit).length > 0) {
      apiPayload['split'] = selectedSplit.id;
    }

    if (clientName !== '' && dueDate !== '') {
      await axios
        .post(
          process.env.REACT_APP_BASE_ENDPOINT + '/invoices',
          apiPayload,
          option,
        )
        .then(res => {
          setOnProcess(false);
          setOpenWarning(false);
          setResponse(true);
          setTimeout(() => {
            navigate('/invoice');
            setResponse(false);
          }, 4000);
        })
        .catch(err => {
          setOnProcess(false);
          setErrorMessage(err.response.data.message);
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setOnProcess(false);
      setErrorMessage('Please fill all required input fields!');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  const handleClose = () => {
    setResponse(false);
    setProductCreateSuccess(false);
    setErrorMessage('');
  };

  const [dateDiff, setDateDiff] = useState(0);

  useEffect(() => {
    let modifiedDate: any = new Date(dueDate);
    let createdDate: any = new Date();
    const diffTime = Math.abs(createdDate - modifiedDate);
    setDateDiff(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
  }, [dueDate]);

  // Save Single Split Data
  const [selectedSplit, setSelectedSplit] = useState<any>({});

  const closeSplitModal = e => {
    e.preventDefault();
    setOpenAddSplit(false);
  };

  const [clientsModified, setClientsModified] = useState<any>([]);
  const [isInputFocus, setIsInputFocus] = useState(false);
  const handleInputFocus = () => {
    setIsInputFocus(true);
  };

  const handleSearch: any = e => {
    let new_item: any = [];
    if (clients !== '' && clients.length !== 0) {
      for (let i = 0; i < clients.length; i++) {
        if (
          clients[i].name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          clients[i].email.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          new_item.push(clients[i]);
        } else if (e.target.value === '') {
          setClientsModified(clients);
        }
      }
      setClientsModified(new_item);
    }
  };

  // Products Section

  useEffect(() => {
    getRequest(`invoices/products?sortBy=createdAt:desc&paginate=false`)
      .then(res => {
        setproductModified(res);
        setProducts(res);
      })
      .catch(err => {});
  }, []);

  const [lineActiveIndex, setLineActiveIndex] = useState(-1);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [products, setProducts] = useState<any>([]);
  const [productModified, setproductModified] = useState<any>([]);
  const [productInputFocus, setProductInputFocus] = useState(false);
  const [productCreateSuccess, setProductCreateSuccess] =
    useState<boolean>(false);
  const [editSuccess, setEditSuccess] = useState(false);

  const afterProductCreate = () => {
    getRequest(`invoices/products?sortBy=createdAt:desc&paginate=false`)
      .then(res => {
        setproductModified(res);
        setProducts(res);
      })
      .catch(err => {});
  };

  const handleProductInputFocus = () => {
    setProductInputFocus(true);
  };

  const handleProductSearch: any = e => {
    let new_item: any = [];
    if (products !== '' && products.length !== 0) {
      for (let i = 0; i < products.length; i++) {
        if (
          products[i].name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          products[i].cost.includes(e.target.value.toLowerCase())
        ) {
          new_item.push(products[i]);
        } else if (e.target.value === '') {
          setproductModified(products);
        }
      }
      setproductModified(new_item);
    }
  };

  const IncrementQuantity = index => {
    if (itemArray[index].quantity === null) {
      itemArray[index].quantity = 0;
      itemArray[index].quantity = Number(itemArray[index].quantity) + 1;
    } else if (itemArray[index].quantity >= 0) {
      itemArray[index].quantity = Number(itemArray[index].quantity) + 1;
    }
    setItemsArray([...itemArray]);
  };

  const DecrementQuantity = index => {
    if (Number(itemArray[index].quantity) >= 1) {
      itemArray[index].quantity = Number(itemArray[index].quantity) - 1;
    }
    setItemsArray([...itemArray]);
  };

  // Handle Edit Product
  const [selectedproduct, setSelectedProduct] = useState<any>({});
  const [showEditModal, setShowEditModal] = useState(false);

  // Get Splits
  useEffect(() => {
    getRequest(`splits?sortBy=createdAt:desc&paginate=false&type=owner`)
      .then(res => {
        setSplitsModified(res.reverse());
      })
      .catch(err => {});
  }, []);

  const [splitsModified, setSplitsModified] = useState<any>([]);
  const [showSplit, setShowSplit] = useState(false);

  // Fetch Collaborator Information

  const [showBreakdown, setShowBreakdown] = useState(false);

  const handleRemoveIndex = () => {
    setShowBreakdown(false);
  };

  const userBankAccount = JSON.parse(
    localStorage.getItem('accountInfo') || '{}',
  );

  // Fetch Each Collaborator
  useEffect(() => {
    getRequest(`splits/collaborators?sortBy=createdAt:desc&paginate=false`)
      .then(res => {
        setAllCollaborators(res);
      })
      .catch(err => {});
  }, []);

  const [finalCollaborators, setFinalCollaborators] = useState<any>([]);
  const [allCollaborators, setAllCollaborators] = useState<any>('');

  const fetchCollabDetails = item => {
    let mockData: Array<object> = [];
    if (allCollaborators && item && item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        if (item[i].collaboratorId === loggedUser.user.id) {
          mockData.unshift({
            name: userBankAccount.accountInfo.accountName,
            email: loggedUser.user.email,
            id: userBankAccount.user,
            accountInfo: {
              accountName: userBankAccount.accountInfo.accountName,
              accountNumber: userBankAccount.accountInfo.accountNumber,
              bankName: userBankAccount.accountInfo.bankName,
            },
            percentage: item[0].percentage,
            amount: item[0].amount,
          });
        }

        for (let k = 0; k < allCollaborators.length; k++) {
          if (allCollaborators[k].id === item[i].collaboratorId) {
            mockData.push({
              name: allCollaborators[k].name,
              email: allCollaborators[k].email,
              percentage: item[i].percentage,
              amount: item[i].amount,
            });
          }
        }
      }
      setFinalCollaborators(mockData);
    }
  };
  return (
    <>
      {clientErr !== '' ? (
        <PopUpModal
          handleClose={() => setClientErr('')}
          msg="An Error occured!"
          extraMsg={clientErr}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}
      {openProductModal ? (
        <CreateProduct
          closeFunc={() => setOpenProductModal(false)}
          setSuccess={setProductCreateSuccess}
          afterCreate={() => afterProductCreate()}
        />
      ) : null}
      {showEditModal ? (
        <EditProduct
          closeFunc={() => setShowEditModal(false)}
          setSuccess={setEditSuccess}
          afterEdit={() => afterProductCreate()}
          item={selectedproduct}
        />
      ) : null}
      {openAddSplit ? (
        <AddSplitModal
          closeFunc={e => closeSplitModal(e)}
          setSelected={setSelectedSplit}
          selected={selectedSplit}
        />
      ) : null}
      {noItem ? (
        <PopUpModal
          handleClose={closeModal}
          msg="An Error occured!"
          extraMsg={'Please add Item to invoice!'}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      {response ? (
        <Alert
          closeFunc={handleClose}
          message="Successful!"
          miniMessage="You have created an invoice successfully!"
          img="/success.png"
        />
      ) : null}

      {productCreateSuccess ? (
        <Alert
          closeFunc={handleClose}
          message="Product created successfully"
          miniMessage="You have created a product successfully, you can add the product to this invoice"
          img="/success.png"
        />
      ) : null}

      {editSuccess ? (
        <Alert
          closeFunc={handleClose}
          message="Changes made successfully"
          miniMessage="You have edited a product successfully, you can add the product to this invoice"
          img="/success.png"
        />
      ) : null}

      {errorMessage !== '' ? (
        <Alert
          closeFunc={handleClose}
          message="An Error occured!"
          miniMessage={errorMessage}
          img="/failed.png"
        />
      ) : null}

      <MainWrapper
        width="100%"
        onClick={() => {
          setIsInputFocus(false);
          setProductInputFocus(false);
          setShowSplit(false);
        }}
      >
        <FragmentHeader
          showBack={true}
          pageName="Create invoice"
          backLink="/invoice"
        />
        <SearchPos position="fixed" mobileTop="-1rem">
          <ButtonWrap mobileHide={false}>
            <CustomButtonLight>
              <ButtonFlex
                onClick={() => {
                  handleNoItem(generatePDF(ref, { filename: 'invoice.pdf' }));
                }}
              >
                Preview Invoice
              </ButtonFlex>
            </CustomButtonLight>
            <CustomButtonDark
              disabled={
                !onProcess && clientName !== '' && dueDate !== '' ? false : true
              }
              style={
                !onProcess && clientName !== '' && dueDate !== ''
                  ? {}
                  : {
                      cursor: 'not-allowed',
                    }
              }
              onClick={() => toggleOpenWarning()}
            >
              <ButtonFlex>Send Invoice</ButtonFlex>
            </CustomButtonDark>
          </ButtonWrap>
        </SearchPos>
        <BreadcrumbArea>
          <PreferenceBlock>
            <MainFlex>
              <PairSection width="32%" mobileMargin="5rem 0 0 0">
                <Constraint
                  width="100%"
                  mobileTop="-6rem"
                  style={{ margin: '0 auto' }}
                >
                  <div style={{ margin: '0 0 2rem 0', width: '100%' }}>
                    <h3
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        margin: '0rem 0 0rem 0',
                        position: 'relative',
                        bottom: '-1rem',
                      }}
                    >
                      Client Name <Important>*</Important>
                    </h3>
                    <CollabSuggestWrap
                      style={{
                        margin: '2rem 0 0 0',
                        zIndex: '1',
                        width: '100%',
                      }}
                    >
                      <input
                        onClick={e => {
                          e.stopPropagation();
                          handleInputFocus();
                        }}
                        value={clientInputValue}
                        style={{
                          width: '100%',
                          border: '0.5px solid #dadada',
                        }}
                        onChange={e => {
                          handleSearch(e);
                          setClientInputValue(e.target.value);
                        }}
                      />
                      <p>Search existing clients</p>
                      {isInputFocus ? (
                        <div>
                          <BlueText
                            onClick={() => {
                              handleClientModal();
                              setClientActionMode('create');
                            }}
                            style={{
                              margin: '1rem 0 0 1rem',
                              width: '10rem',
                            }}
                          >
                            + Add Client
                          </BlueText>
                          {clientsModified && clientsModified.length > 0 ? (
                            clientsModified.map((item, index) => (
                              <SplitUsersWrap
                                alignVertical="flex-start"
                                key={index}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  setClientName(item.name);
                                  setClientId(item.id);
                                  setPhone(item.phoneNumber);
                                  setEmail(item.email);
                                  setAddress(item.address);
                                  setState(item.state);
                                  setCountry(item.country);
                                  setClientInputValue(
                                    `${item.name} (${item.email})`,
                                  );
                                }}
                              >
                                <div
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '10px',
                                      width: '100%',
                                    }}
                                  >
                                    <SplitAvatar>
                                      {item.name ? item.name[0] : ''}
                                    </SplitAvatar>
                                    <div
                                      style={{
                                        width: '80%',
                                      }}
                                    >
                                      <h3
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          width: '80%',
                                        }}
                                      >
                                        {item.name
                                          ? `${item.name}        `
                                          : ''}
                                      </h3>
                                      <p>
                                        <span>{`${
                                          item.email ? item.email : ''
                                        }`}</span>
                                      </p>
                                    </div>
                                    <div>
                                      <BlueText
                                        onClick={() => {
                                          setClientSelectedForEdit(item);
                                          handleClientModal();
                                          setClientActionMode('edit');
                                        }}
                                      >
                                        Edit
                                      </BlueText>
                                    </div>
                                  </div>
                                </div>
                              </SplitUsersWrap>
                            ))
                          ) : (
                            <p
                              style={{
                                textAlign: 'center',
                                fontSize: '14px',
                                color: '#757575',
                                margin: '1rem 0 0 0',
                              }}
                            >
                              No Option.
                            </p>
                          )}
                        </div>
                      ) : null}
                    </CollabSuggestWrap>
                  </div>
                  <div>
                    <h3
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        margin: '51px 0 1rem 0',
                      }}
                    >
                      Product/Service <Important>*</Important>
                    </h3>
                    {itemArray.slice(0, itemsNumber).map((item, index) => (
                      <>
                        <FlexApart
                          key={index}
                          style={{
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            position: 'relative',
                            margin: '0 0 1rem 0',
                          }}
                        >
                          {index !== 0 ? (
                            <Icon.X
                              color="#757575"
                              size={18}
                              onClick={() => handleDelete(index)}
                              style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '20%',
                                translate: 'transform(-50%, -50%)',
                                left: '-1.3rem',
                              }}
                            />
                          ) : null}
                          <TicketInput style={{ width: '60%' }}>
                            <div style={{ width: '100%' }}>
                              <p>Item details</p>
                              <input
                                onClick={e => {
                                  e.stopPropagation();
                                  handleProductInputFocus();
                                  setLineActiveIndex(index);
                                }}
                                value={`${item.itemDetails} ${
                                  item.amount > 0 ? `(₦${item.amount})` : ''
                                }`}
                                onKeyPress={e => {
                                  handleProductSearch(e);
                                }}
                                required={true}
                              />
                            </div>
                          </TicketInput>
                          <QuantityToggleWrap
                            style={{
                              width: '30%',
                              gap: '5px',
                            }}
                          >
                            <TicketInput style={{ width: '80%' }}>
                              <div style={{ width: '100%' }}>
                                <p>Quantity</p>
                                <input
                                  onChange={e => addquantity(e, index)}
                                  required={true}
                                  type="number"
                                  value={item.quantity}
                                  onKeyPress={e => numOnly(e)}
                                />
                              </div>
                            </TicketInput>
                            <div>
                              <Icon.Plus
                                onClick={() => IncrementQuantity(index)}
                                size={20}
                                style={{
                                  position: 'relative',
                                  top: '-0.5rem',
                                  cursor: 'pointer',
                                  background: '#ababab',
                                  color: '#fff',
                                  marginBottom: '0.3rem',
                                }}
                              />
                              <Icon.Minus
                                onClick={() => DecrementQuantity(index)}
                                size={20}
                                style={{
                                  position: 'relative',
                                  top: '-0.5rem',
                                  cursor: 'pointer',
                                  background: '#ababab',
                                  color: '#fff',
                                }}
                              />
                            </div>
                          </QuantityToggleWrap>
                        </FlexApart>
                        <CollabSuggestWrap
                          style={{
                            margin: '-1rem 0 0 0',
                            zIndex: '1',
                            width: '100%',
                          }}
                        >
                          {productInputFocus && lineActiveIndex === index ? (
                            <div>
                              <BlueText
                                onClick={() => setOpenProductModal(true)}
                                style={{
                                  margin: '1rem 0 0 1rem',
                                  width: '10rem',
                                }}
                              >
                                + Add new product
                              </BlueText>
                              {productModified && productModified.length > 0 ? (
                                productModified.map((itemp, indexp) => (
                                  <SplitUsersWrap
                                    alignVertical="flex-start"
                                    key={indexp}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      addProductToArray(
                                        itemp.name,
                                        itemp.cost,
                                        index,
                                      );
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '10px',
                                          width: '100%',
                                        }}
                                      >
                                        {itemp.avatar ? (
                                          <SplitAvatar>
                                            <img
                                              src={
                                                itemp.avatar
                                                  ? itemp.avatar
                                                  : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                                              }
                                              alt={item.name}
                                            />
                                          </SplitAvatar>
                                        ) : (
                                          <SplitAvatar>
                                            {itemp.name ? itemp.name[0] : ''}
                                          </SplitAvatar>
                                        )}
                                        <div
                                          style={{
                                            width: '80%',
                                          }}
                                        >
                                          <h3
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              width: '80%',
                                            }}
                                          >
                                            {itemp.name ? `${itemp.name}` : ''}
                                            <div
                                              style={{
                                                width: '5px',
                                                height: '5px',
                                                background: '#ababab',
                                                borderRadius: '10rem',
                                              }}
                                            ></div>
                                            <span>
                                              &#8358;
                                              {`${
                                                itemp.cost
                                                  ? CommaNumber(itemp.cost)
                                                  : ''
                                              }`}
                                            </span>
                                          </h3>
                                          <p></p>
                                        </div>
                                        <div>
                                          <BlueText
                                            onClick={() => {
                                              setSelectedProduct(itemp);
                                              setShowEditModal(true);
                                            }}
                                          >
                                            Edit
                                          </BlueText>
                                        </div>
                                      </div>
                                    </div>
                                  </SplitUsersWrap>
                                ))
                              ) : (
                                <p
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    color: '#757575',
                                    margin: '1rem 0 0 0',
                                  }}
                                >
                                  No Option.
                                </p>
                              )}
                            </div>
                          ) : null}
                        </CollabSuggestWrap>
                      </>
                    ))}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        margin: '2rem 0',
                      }}
                    >
                      <BlueText onClick={() => handleItemIncrement()}>
                        + Add another item
                      </BlueText>
                      <p
                        style={{
                          letterSpacing: '-0.02em',
                          color: '#64676C',
                          fontSize: '14px',
                          fontWeight: '400',
                        }}
                      >
                        &#8358;{CommaNumber(subTotal)}
                      </p>
                    </div>

                    <h3
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        margin: '49px 0 1rem 0',
                      }}
                    >
                      Due Date <Important>*</Important>
                    </h3>
                    <TicketInput style={{ width: '100%' }}>
                      <div style={{ width: '100%' }}>
                        <p>Due Date</p>
                        <input
                          type="date"
                          onChange={e => setDueDate(e.target.value)}
                          required={true}
                          min={currentDate}
                        />
                      </div>
                    </TicketInput>
                    <GenericBtn
                      onClick={() => toggleCreateReminder()}
                      style={
                        beforeDueDate !== '' ||
                        onDueDate !== '' ||
                        afterDueDate !== ''
                          ? {
                              border: '1px solid #34A853',
                            }
                          : {}
                      }
                    >
                      Payment Reminder
                      <i>
                        <Icons.ChevronRight size={20} />
                      </i>
                    </GenericBtn>
                  </div>
                </Constraint>
              </PairSection>
              <PairSection width="32%" mobileMargin="0">
                <Constraint width="100%" mobileTop="-5rem">
                  <FlexedApart>
                    <h3
                      style={{
                        fontSize: '0.9rem',
                        fontWeight: 600,
                        margin: '5px 0 1rem 0',
                      }}
                    >
                      Payout Settings
                    </h3>
                  </FlexedApart>
                  <GenericBtn
                    onClick={e => {
                      e.stopPropagation();
                      setShowSplit(true);
                    }}
                  >
                    <Icon.Zap
                      color="#191919"
                      style={{
                        margin: '0 1rem 0 0',
                      }}
                    />{' '}
                    Split this payment
                    <i>
                      <Icons.ChevronRight size={20} />
                    </i>
                  </GenericBtn>
                  <CollabSuggestWrap
                    style={{
                      margin: '-0.5rem 0 0 0',
                      zIndex: '100',
                      width: '100%',
                    }}
                  >
                    {showSplit ? (
                      <div>
                        {splitsModified && splitsModified.length > 0 ? (
                          splitsModified.map((item, index) => (
                            <SplitUsersWrap
                              alignVertical="flex-start"
                              key={index}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setSelectedSplit(item);
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%',
                                  }}
                                >
                                  <SplitAvatar>
                                    <img
                                      src={
                                        item.avatar
                                          ? item.avatar
                                          : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                                      }
                                      alt={item.name}
                                    />
                                  </SplitAvatar>
                                  <div
                                    style={{
                                      width: '80%',
                                    }}
                                  >
                                    <h3
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {item.name ? `${item.name}` : ''}
                                      <div
                                        style={{
                                          width: '5px',
                                          height: '5px',
                                          background: '#ababab',
                                          borderRadius: '10rem',
                                        }}
                                      ></div>
                                      {item.splitType === 'time' ? (
                                        <span>{item.frequency}</span>
                                      ) : (
                                        ''
                                      )}
                                      {item.splitType === 'approval' ? (
                                        <span>On approval</span>
                                      ) : (
                                        ''
                                      )}
                                      {item.splitType === 'limit' ? (
                                        <span>
                                          up to {CommaNumber(item.limitValue)}
                                        </span>
                                      ) : (
                                        ''
                                      )}
                                      {item.splitType === 'instantly' ? (
                                        <span>{'Instantly'}</span>
                                      ) : (
                                        ''
                                      )}
                                    </h3>
                                    <p>
                                      <span>
                                        {item.collaborators
                                          ? item.collaborators.length
                                          : ''}{' '}
                                        {item.collaborators &&
                                        item.collaborators.length > 1
                                          ? 'recipients'
                                          : 'recipient'}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </SplitUsersWrap>
                          ))
                        ) : (
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '14px',
                              color: '#757575',
                              margin: '1rem 0 0 0',
                            }}
                          >
                            No Option.
                          </p>
                        )}
                      </div>
                    ) : null}
                  </CollabSuggestWrap>
                  {selectedSplit && Object.keys(selectedSplit).length > 0 ? (
                    <SplitUsersWrap
                      alignVertical="flex-start"
                      style={{
                        cursor: 'pointer',
                        margin: '2rem 0 0 0',
                      }}
                      onMouseOver={() => {
                        setShowBreakdown(true);
                        fetchCollabDetails(selectedSplit.collaborators);
                      }}
                      onMouseLeave={() => handleRemoveIndex()}
                    >
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            width: '100%',
                          }}
                        >
                          <Icon.X
                            color="#757575"
                            onClick={() => setSelectedSplit({})}
                          />
                          <SplitAvatar>
                            <img
                              src={
                                selectedSplit.avatar
                                  ? selectedSplit.avatar
                                  : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                              }
                              alt={selectedSplit.name}
                            />
                          </SplitAvatar>
                          <div
                            style={{
                              width: '80%',
                            }}
                          >
                            <h3
                              style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {selectedSplit.name
                                ? `${selectedSplit.name}`
                                : ''}
                              <div
                                style={{
                                  width: '5px',
                                  height: '5px',
                                  background: '#ababab',
                                  borderRadius: '10rem',
                                }}
                              ></div>
                              {selectedSplit.splitType === 'time' ? (
                                <span>{selectedSplit.frequency}</span>
                              ) : (
                                ''
                              )}
                              {selectedSplit.splitType === 'limit' ? (
                                <span>
                                  up to {CommaNumber(selectedSplit.limitValue)}
                                </span>
                              ) : (
                                ''
                              )}
                              {selectedSplit.splitType === 'instantly' ? (
                                <span>{'Instantly'}</span>
                              ) : (
                                ''
                              )}
                            </h3>
                            <p>
                              <span>
                                {selectedSplit.collaborators
                                  ? selectedSplit.collaborators.length
                                  : ''}{' '}
                                {selectedSplit.collaborators &&
                                selectedSplit.collaborators.length > 1
                                  ? 'recipients'
                                  : 'recipient'}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      {showBreakdown ? (
                        <>
                          {finalCollaborators &&
                          finalCollaborators.length > 0 ? (
                            <SplitUsersPopup
                              style={{
                                left: '0',
                                boxShadow: '0 0 5px #cbced1',
                              }}
                            >
                              <h3>Split breakdown</h3>
                              {finalCollaborators.map((item, index) => (
                                <SplitUsersWrap key={index}>
                                  <SplitAvatar>
                                    {item.name ? item.name[0] : ''}
                                  </SplitAvatar>
                                  <div>
                                    <h3>{item.name}</h3>
                                    <p>{item.email}</p>
                                  </div>
                                  {item.percentage || !item.amount ? (
                                    <p>{item.percentage}%</p>
                                  ) : (
                                    <p>&#8358;{item.amount}</p>
                                  )}
                                </SplitUsersWrap>
                              ))}
                            </SplitUsersPopup>
                          ) : null}
                        </>
                      ) : null}
                    </SplitUsersWrap>
                  ) : null}
                  {/* More Options Section */}
                  <h3
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      margin: '32px 0 1rem 0',
                    }}
                  >
                    More options
                  </h3>
                  <GenericBtn
                    onClick={() => toggleOptionalCost()}
                    style={
                      discount > 0 || tax > 0 || shipping > 0
                        ? {
                            border: '1px solid #34A853',
                          }
                        : {}
                    }
                  >
                    Discount, Tax, Shipping
                    <i>
                      <Icons.ChevronRight size={20} />
                    </i>
                  </GenericBtn>

                  {discount && discount > 0 ? (
                    <p
                      style={{
                        letterSpacing: '-0.02em',
                        color: '#64676C',
                        fontSize: '14px',
                        fontWeight: '400',
                        margin: '1rem 0',
                      }}
                    >
                      Discount -
                      {discountType === '%' ? (
                        `${discount}%`
                      ) : (
                        <> &#8358;{discount}</>
                      )}
                    </p>
                  ) : null}

                  {tax && tax > 0 ? (
                    <p
                      style={{
                        letterSpacing: '-0.02em',
                        color: '#64676C',
                        fontSize: '14px',
                        fontWeight: '400',
                        margin: '1rem 0',
                      }}
                    >
                      Tax -{taxType === '%' ? `${tax}%` : <> &#8358;{tax}</>}
                    </p>
                  ) : null}
                  {shipping && shipping > 0 ? (
                    <p
                      style={{
                        letterSpacing: '-0.02em',
                        color: '#64676C',
                        fontSize: '14px',
                        fontWeight: '400',
                        margin: '1rem 0',
                      }}
                    >
                      Shipping - &#8358;{shipping}
                    </p>
                  ) : null}
                  <GenericBtn
                    onClick={() => toggleOpenNote()}
                    style={
                      invoiceNote !== ''
                        ? {
                            border: '1px solid #34A853',
                          }
                        : {}
                    }
                  >
                    Invoice Note
                    <i>
                      <Icons.ChevronRight size={20} />
                    </i>
                  </GenericBtn>
                  <FlexedArea>
                    <p
                      style={{
                        margin: '0 auto',
                        fontSize: '14px',
                        textAlign: 'center',
                      }}
                    >
                      Total:
                      <br />
                      <span
                        style={{
                          fontSize: '25px',
                          fontWeight: 600,
                        }}
                      >
                        {' '}
                        &#8358;{grandTotal}
                      </span>
                    </p>
                  </FlexedArea>
                  {width < 728 ? (
                    <BigBtn>
                      <button
                        disabled={
                          !onProcess && clientName !== '' && dueDate !== ''
                            ? false
                            : true
                        }
                        style={
                          !onProcess && clientName !== '' && dueDate !== ''
                            ? {}
                            : {
                                cursor: 'not-allowed',
                              }
                        }
                        onClick={() => toggleOpenWarning()}
                      >
                        Send Invoice
                      </button>
                    </BigBtn>
                  ) : null}
                </Constraint>
              </PairSection>
            </MainFlex>
          </PreferenceBlock>
        </BreadcrumbArea>

        {/* Modals */}

        {openWarning ? (
          <CreateWarning
            handleAction={handleSendInvoice}
            deleteInProcess={onProcess}
            closeFunc={toggleOpenWarning}
            message={'Are you sure you want to send invoice to this client?'}
            actionText={'Send Invoice'}
            clientName={clientName}
            grandTotal={grandTotal}
          />
        ) : null}

        {optionalCost ? (
          <OptionalCost
            closeFunc={toggleOptionalCost}
            header={'Optional Cost'}
            setTaxUnit={setTaxType}
            setDiscountUnit={setDiscountType}
            setDiscount={setDiscount}
            setTax={setTax}
            setShipping={setShipping}
            discountUnit={discountType}
            taxUnit={taxType}
            tax={tax}
            discount={discount}
            shipping={shipping}
          />
        ) : null}

        {openInvoiceNote ? (
          <InvoiceNote
            closeFunc={toggleOpenNote}
            header="Invoice Note"
            subText="Add notes to add to your invoice"
            invoiceNote={invoiceNote}
            setInvoiceNote={setInvoiceNote}
          />
        ) : null}

        <Preview
          propRef={ref}
          clientName={clientName}
          email={email}
          address={address}
          state={state}
          dueDate={dueDate}
          country={country}
          shipping={shipping}
          discount={discount}
          phone={phone}
          tax={tax}
          items={itemArray}
          taxPercent={taxPercent}
          taxType={taxType}
          discountType={discountType}
          discountPercent={discountPercent}
          grandTotal={grandTotal}
          invoiceCode={invoiceCode}
          refNo={refNo}
          invoiceNote={invoiceNote}
        />
      </MainWrapper>

      {/* New Client Modal */}
      {openClient ? (
        <NewClient
          closeFunc={handleClientModal}
          propSetName={setClientName}
          propSetId={setClientId}
          propSetPhone={setPhone}
          propSetEmail={setEmail}
          propSetAddress={setAddress}
          propSetState={setState}
          propSetCountry={setCountry}
          editProps={clientSelectedForEdit}
          mode={clientActionMode}
        />
      ) : null}

      {openReminder ? (
        <Reminder
          closeFunc={toggleReminder}
          header="Set Reminder"
          dueDate={dueDate}
          dateDiff={dateDiff}
        />
      ) : null}

      {openCreateReminder ? (
        <CreateReminder
          closeFunc={toggleCreateReminder}
          header="Set Reminder"
          beforeDueDate={beforeDueDate}
          onDueDate={onDueDate}
          afterDueDate={afterDueDate}
          setBeforeDueDate={setBeforeDueDate}
          setOnDueDate={setOnDueDate}
          setAfterDueDate={setAfterDueDate}
          dateDiff={dateDiff}
        />
      ) : null}
    </>
  );
};

export default AddItem;
