import { setSelectedBenefit } from './benefitAction';

interface OptionsType {
  name: string;
  enum: EnumType[];
}

interface EnumType {
  name: string;
  amount: number;
  frequency: string;
  id: string;
}

interface SelectedBenefitState {
  frequencies: string[];
  endDate: boolean;
  name: string;
  moreInfo: string;
  description: string;
  interestRate: number;
  user: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  id: string;
  options?: OptionsType; // Make options field optional
}

const initialState: SelectedBenefitState = {
  frequencies: [],
  endDate: false,
  name: '',
  moreInfo: '',
  description: '',
  interestRate: 0,
  user: '',
  createdBy: '',
  createdAt: '',
  updatedAt: '',
  id: '',
};

type Action = ReturnType<typeof setSelectedBenefit>;

const selectedBenefitReducer = (
  state: SelectedBenefitState = initialState,
  action: Action,
): SelectedBenefitState => {
  switch (action.type) {
    case 'SET_SELECTED_BENEFIT': {
      const { options, ...rest } = action.payload;
      if (options) {
        return {
          ...state,
          ...rest,
          options,
        };
      } else {
        const { options: omitOptions, ...restWithoutOptions } = state;
        return {
          ...restWithoutOptions,
          ...rest,
        };
      }
    }
    default:
      return state;
  }
};

export default selectedBenefitReducer;
