import React, { createRef } from 'react';
import {
  DetailsModal,
  DetailsChild,
  CloseIcon,
  MainPart,
  UserInfo,
  Avatar,
  MainSection,
} from './style';
import { FlexApart } from '../orderComp/mainContent/orderContent/styles';
import * as Icon from 'react-feather';
import moment from 'moment';
import { StatusWrap } from 'app/components/transactions/style';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import CommaNumber from 'comma-number';
import Receipt from './receipt';
import generatePDF from 'react-to-pdf';
import { getSign } from 'utils/getCurrencySign';

interface PropsArgs {
  closeFunc: any;
  props: any;
}

const Details = ({ props, closeFunc }: PropsArgs) => {
  // Transaction History
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo') || '{}');

  // PDF Download

  const ref: any = createRef();

  return (
    <>
      <DetailsModal onClick={closeFunc}>
        <DetailsChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <MainPart>
            <UserInfo>
              <Avatar>
                {'payerName' in props.meta &&
                (props.type === 'credit' ||
                  props.meta.transactionType === 'Deposit') &&
                props.meta.payerName !== '' &&
                props.meta.payerName !== undefined
                  ? props.meta.payerName[0]
                  : ''}

                {'accountName' in props.meta &&
                (props.type === 'debit' ||
                  props.meta.transactionType === 'Split') &&
                props.meta.accountName !== null
                  ? props.meta.accountName[0]
                  : ''}
              </Avatar>
              <h3>
                {getSign(props.currency)}
                {CommaNumber(props.amount)}
              </h3>
              <p>
                {'payerName' in props.meta &&
                props.type === 'credit' &&
                props.meta.payerName !== null
                  ? props.meta.payerName
                  : ''}

                {'accountName' in props.meta &&
                props.type === 'debit' &&
                props.meta.accountName !== null
                  ? props.meta.accountName
                  : ''}
              </p>
            </UserInfo>
            <MainSection>
              <h3>
                <b>TRANSACTION DETAILS</b>
              </h3>
              <FlexApart>
                <h3>Amount</h3>
                <h3
                  style={
                    props.type === 'debit'
                      ? { color: '#FF2A2A' }
                      : { color: '#34A853' }
                  }
                >
                  {getSign(props.currency)}
                  {CommaNumber(props.amount)}
                </h3>
              </FlexApart>
              <FlexApart>
                <h3>Fee</h3>
                <h3>&#8358;{'fee' in props.meta ? props.meta.fee : 0}</h3>
              </FlexApart>
              <FlexApart>
                <h3>Date</h3>
                <h3>
                  {moment(props.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
                </h3>
              </FlexApart>
              <FlexApart>
                <h3>Counterparty</h3>
                <h3>
                  {'payerName' in props.meta
                    ? props.meta.payerName
                    : props.meta.accountName}
                </h3>
              </FlexApart>
              {'bankName' in props.meta ? (
                <FlexApart>
                  <h3>Bank name</h3>
                  <h3>{props.meta.bankName}</h3>
                </FlexApart>
              ) : null}
              <FlexApart>
                <h3>Account number</h3>
                <h3>{props.meta.accountNumber}</h3>
              </FlexApart>
              <FlexApart>
                <h3>Transaction Reference</h3>
                <h3>{props.reference}</h3>
              </FlexApart>
              <FlexApart>
                <h3>Type</h3>
                <h3>{props.type}</h3>
              </FlexApart>
              <h3>
                <b>TRANSACTION MEMO</b>
              </h3>
              <h3>{'purpose' in props ? props.purpose : 'No Memo attached'}</h3>
            </MainSection>
          </MainPart>
          <CenteredAnimeWrap
            style={{
              position: 'relative',
              top: '4rem',
              left: '50%',
              transform: 'translate(-50%, -0)',
            }}
          >
            <StatusWrap
              style={{
                background: '#161616',
                color: 'fff',
                width: '10rem',
                padding: '0.6rem 1rem',
                fontSize: '16px',
                cursor: 'pointer',
              }}
              onClick={() => {
                generatePDF(ref, {
                  filename: `mage-${
                    'payerName' in props.meta
                      ? props.meta.payerName
                      : props.meta.accountName
                  }.pdf`,
                });
              }}
            >
              <div
                style={{
                  background: '#161616',
                  color: '#fff',
                }}
              >
                Download Receipt
              </div>
            </StatusWrap>
          </CenteredAnimeWrap>
          {/* <CenteredAnimeWrap
            style={{
              position: 'relative',
              top: '0rem',
              left: '50%',
              transform: 'translate(-50%, -0)',
            }}
          >
            <StatusWrap
              style={{
                background: handleStatusBg('inactive'),
                color: handleColor('inactive'),
                width: '10rem',
                padding: '0.6rem 1rem',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              <div>
                <a href="mailto:hello@trymage.com">Report issues</a>
              </div>
            </StatusWrap>
          </CenteredAnimeWrap> */}
        </DetailsChild>
      </DetailsModal>
      <Receipt
        props={props}
        propRef={ref}
        accountNumber={
          accountInfo.accountInfo && accountInfo.accountInfo.accountNumber
            ? accountInfo.accountInfo.accountNumber
            : ''
        }
        accountBank={
          accountInfo.accountInfo && accountInfo.accountInfo.bankName
            ? accountInfo.accountInfo.bankName
            : ''
        }
        firstName={loggedUser.user.firstName}
        lastName={loggedUser.user.lastName}
      />
    </>
  );
};

export default Details;
