import styled from 'styled-components';

export const HeaderBackground = styled.div`
  width: 100%;
  position: fixed;
  background: #161616;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: Center;
  gap: 7px;
  padding: 0 1%;
  height: auto;

  @media (max-width: 1028px) {
    width: 100%;
    top: 0;
    gap: 0;
  }
`;
interface MainHeaderWrapProps {
  width?: string;
  top?: string;
  borderRad?: string;
  borderBot?: string;
  padding?: string;
}

export const MainHeadWrap = styled.div<MainHeaderWrapProps>`
  width: ${p => p.width || '100%'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Satoshi', 'sans-serif';
  background: #191919;
  padding: ${p => p.padding || '1rem 2%'};
  margin: 0;
  position: relative;
  top: ${p => p.top || '0rem'};
  left: 0;
  border-radius: ${p => p.borderRad || '20px'};
  border-bottom: ${p => p.borderBot || 'none'};

  @media (max-width: 1028px) {
    width: 100%;
    top: 0;
    border-radius: 0;
  }
`;

export const WrapMerchroLogo = styled.div`
  display: block;

  @media (max-width: 1028px) {
    display: none;
  }
`;

export const UserSection = styled.div<MainHeaderWrapProps>`
  width: 22%;
  display: flex;
  justify-content: flex-end;
  gap: 70px;
  align-items: center;
  font-family: 'Satoshi', 'sans-serif';
  background: #161616;
  padding: 1rem 2%;
  margin: 0 0;
  position: relative;
  top: 0rem;
  left: 0;
  border-radius: 20px;
  cursor: pointer;

  @media (max-width: 728px) {
    width: 100%;
    top: 0;
    border-radius: 0;
    gap: 15px;
    display: none;
  }
`;

interface PageNameProps {
  display?: boolean;
}

export const PageName = styled.div<PageNameProps>`
  width: auto;
  font-size: 1.1rem;
  font-weight: 500;
  color: #191919;
  display: ${p => (p.display ? 'flex' : 'none')};
  align-items: center;
  cursor: pointer;

  > h1 {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Satoshi', sans-serif;
    font-weight: 800;
  }

  @media (max-width: 1028px) {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    color: #fff;
  }
`;

export const HeaderIconsWrap = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1028px) {
    width: 40%;
  }

  > div {
    position: relative;
    margin: 0 0 0 4%;
  }

  i {
    font-size: 1.3rem;
    color: #fff;
  }

  > div > span {
    position: absolute;
    top: 2%;
    right: 2%;
    background: #d71b1b;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 10rem;
  }
`;

export const HeaderNavWrap = styled.div`
  display: none;

  @media (max-width: 1028px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    z-index: 100;
    color: #fff;

    > div {
      width: 90%;
    }

    > i {
      position: absolute;
      color: #fff;
      z-index: 1000;
      top: 20px;
      right: 15%;
      font-size: 1.3rem;
      display: flex;
      align-items: Center;
      gap: 10px;

      > span {
        font-size: 14px;
        font-style: normal;
      }
    }
  }
`;

export const HeaderPopUp = styled.div`
  position: absolute;
  top: 4rem;
  width: 16rem;
  padding: 1rem;
  background: #fff;
  right: 0;
  border: 0.2px solid #fbfbfb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 5px;

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    gap: 10px;
    color: #444444;
    cursor: pointer;
    margin: 0 0 0.5rem 0;
  }

  p:first-child {
    :hover {
      opacity: 0.4;
    }
  }

  > p {
    font-weight: 300;
  }

  @media (max-width: 728px) {
    display: block;
    top: 5rem;
    left: 2rem;
    height: auto;
  }
`;
