import AppHeader from './../../storeComp/mainContent/header/header';
import { GenericHeaderItem, MainWrapper } from './style';
import { BreadcrumbArea } from './../../orderComp/mainContent/orderContent/styles';
import { GenericHeader } from './style';
import {
  EachPreferenceBlock,
  GenericInput,
  Important,
  LeftPart,
  MiddlePart,
  PreferenceBlock,
  RightPart,
  SaveButtonWrap,
  SelectInput,
} from '../../Preference/mainContent/preferenceContent/styles';
import { Link } from 'react-router-dom';

const CreateInv = () => {
  return (
    <>
      <MainWrapper>
        <AppHeader showBack={true} pageName="Create invoice" />
        <BreadcrumbArea>
          <GenericHeader>
            <GenericHeaderItem customCl="#000000">
              <Link to="/create-invoice">
                <i className="fi fi-rr-users"></i>
              </Link>
              <Link to="/create-invoice">
                <h4>Client Details</h4>
              </Link>
            </GenericHeaderItem>
            <GenericHeaderItem customCl="#f1f1f1">
              <Link to="/add-item">
                <i className="fas fa-cubes"></i>
              </Link>
              <Link to="/add-item">
                <h4>Add Product/Service</h4>
              </Link>
            </GenericHeaderItem>
          </GenericHeader>
          <PreferenceBlock>
            <EachPreferenceBlock align="center">
              <LeftPart>
                Client Name<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <GenericInput placeholder="Full Name" />
              </MiddlePart>
              <RightPart>Not your name? Edit</RightPart>
            </EachPreferenceBlock>
            <EachPreferenceBlock align="center">
              <LeftPart>
                Client Email<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <GenericInput placeholder="Email Address" />
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>
            <EachPreferenceBlock align="center">
              <LeftPart>
                Phone Number<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <GenericInput type="number" placeholder="Phone Number" />
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>
            <EachPreferenceBlock align="center">
              <LeftPart>
                Country<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <SelectInput>
                  <option>Nigeria</option>
                </SelectInput>
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>
            <EachPreferenceBlock align="center">
              <LeftPart>State</LeftPart>
              <MiddlePart>
                <SelectInput>
                  <option>Lagos</option>
                </SelectInput>
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>
            <EachPreferenceBlock align="center">
              <LeftPart>Address</LeftPart>
              <MiddlePart>
                <GenericInput placeholder="Address" />
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>
          </PreferenceBlock>

          {/* Save  */}
          <SaveButtonWrap>
            <button>Save invoice</button>
          </SaveButtonWrap>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default CreateInv;
