import AppHeader from './../../storeComp/mainContent/header/header';
import {
  MainWrapper,
  FlexApart,
  GridContainer,
  GridItem,
  GridItemImg,
  GridTextWrap,
} from './style';
import { BreadcrumbArea } from './../../orderComp/mainContent/orderContent/styles';
import { useState } from 'react';
import ShareDoc from './shareDocument';

const Documents = () => {
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <MainWrapper>
        <AppHeader showBack={true} pageName="Documents" />
        <BreadcrumbArea>
          <GridContainer>
            {documentsArray.map((item, index) => (
              <GridItem key={index} onClick={() => toggleModal()}>
                <GridItemImg src={item.img} alt={item.title} />
                <GridTextWrap>
                  <div>
                    <FlexApart>
                      <h2>{item.title}</h2>
                      <i className="fi fi-rr-menu-dots"></i>
                    </FlexApart>
                  </div>
                  <p>
                    <i className="fi fi-rr-user"></i>Shared with{' '}
                    {item.sharedWith} people.
                  </p>
                </GridTextWrap>
              </GridItem>
            ))}
          </GridContainer>
        </BreadcrumbArea>
        {openModal ? <ShareDoc closeFunc={toggleModal} /> : null}
      </MainWrapper>
    </>
  );
};

export default Documents;

const documentsArray = [
  {
    title: 'Performance Agreement',
    sharedWith: 3,
    img: '/test/9mobile.png',
  },
  {
    title: 'Influencer doc',
    sharedWith: 5,
    img: '/test/influence.png',
  },
  {
    title: 'Performance Agreement',
    sharedWith: 100,
    img: '/test/hie.png',
  },
  {
    title: 'Influencer doc',
    sharedWith: 5,
    img: '/test/influence.png',
  },
];
