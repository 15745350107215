import { createGlobalStyle } from 'styled-components';
// import { StyleConstants } from './StyleConstants';
/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  // @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
  @import url('https://fonts.cdnfonts.com/css/satoshi');
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html,
  body {
    line-height: 1.5;
    scroll-behavior:smooth;
    
  }

  body {
    font-family: 'Satoshi', sans-serif;
    background-color: #fff;
  }

  @media (max-width:728px){
    body{
      background:#fff;
    }
  }

  body.fontLoaded {
    font-family: 'Satoshi', 'sans-serif';
  }

  button:hover{
    opacity:0.8;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Satoshi', sans-serif;
  }

  .hide-amount-input{
    display:none;
  }

  .otp-input input{
    background: #353535;
    color: #353535;
  }
  
  .otp-input input[value=""]{
    background:#C3C5C1;
    color: #c3c5c1;
  }

  .otp-input input:focus{
    background:grey;
    color: grey;
  }

  .otp-input input:active{
    background:grey;
    color: grey;
  }

  p,
  label {
    line-height: 1.5em;
  }

  .custom-dot{
    position:sticky;
    top: -10rem;
    left: 1rem;
  }

  a{
    -webkit-tap-highlight-color:transparent;
    text-decoration: none;
    color:inherit;
  }

  input, select, button, textarea {
    font-family: inherit;
    font-size: inherit;
    outline:none;
    font-size:16px;
  }

  i {
    cursor: pointer;
  }

  /* Improvisations */
  .disabled-input{
    color:#ababab;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  i {
    cursor: pointer;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  i {
    cursor: pointer;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  /* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f8f8f8;
  cursor:pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ededed;
  width: 20px;
  cursor:pointer;
}

@media (max-width:1028px){
  ::-webkit-scrollbar-thumb{
      height:1px;
      display:none;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height:1px;
    display:none;
    border-radius: 1rem;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background:none;
    display:none;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: grey;
    display:none;
  }
}

.go-icon:hover{
  opacity:0.7;
}

  .hide-header {
    display:none;
  }

  @media (max-width:400px){
    .hide-header {
      display:flex;
    }
  }

  @media (max-width:600px){
    .hide-header {
      display:flex;
    }
  }
`;
