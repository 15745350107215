import { Card } from './interface';
import { SaveMastercard } from './benefitAction';

export interface MasterCardState {
  card: Card;
}

const initialState: MasterCardState = {
  card: {
    id: '',
    name: '',
    card_number: '',
    masked_pan: '',
    expiry: '',
    cvv: '',
    status: '',
    type: '',
    issuer: '',
    currency: '',
    balance: '',
    balance_updated_at: '',
    address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
    created_at: '',
    updated_at: '',
  },
};

type ActionTypes = ReturnType<typeof SaveMastercard>;

const masterCardReducer = (
  state: MasterCardState = initialState,
  action: ActionTypes,
): MasterCardState => {
  switch (action.type) {
    case 'MASTERCARD':
      return { ...state, card: action.payload };
    default:
      return state;
  }
};

export default masterCardReducer;
