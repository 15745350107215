// import React, { useState, useEffect } from 'react';
import {
  MainWrapper,
  InvoiceNoWrap,
  UserInfo,
  TradeMark,
  // ActionBtnWrap,
  // ActionBtn,
  BillSection,
  ItemsSection,
  UnderText,
  ItemRow,
  Description,
  SmallColumn,
  StatementHeader,
} from './statementStyle';
import { LogoImage } from './style';

interface PropsArgs {
  propRef?: any;
  clientName?: string;
  email?: string;
  address?: string;
  dueDate?: any;
  phone?: string;
  state?: string;
  country?: string;
  discount?: any;
  tax?: any;
  shipping?: any;
  discountPercent?: any;
  discountType?: string;
  fulfilmentLink?: string;
  taxType?: string;
  taxPercent?: any;
  grandTotal?: number;
  items?: any;
  invoiceCode?: string;
  refNo?: string;
}

const StatementPreview = ({ propRef, items }: PropsArgs) => {
  // Get Invoice Setup Data

  return (
    <>
      <MainWrapper id="divToPrint" ref={propRef}>
        <StatementHeader>
          <LogoImage src="/logosvg.svg" alt="Mage" />
          <h2>
            <b>Statement</b>
          </h2>
        </StatementHeader>
        <InvoiceNoWrap>
          <div style={{ alignItems: 'flex-end' }}>
            <h2>Account</h2>
            <span>2001785201</span>
          </div>
          <div style={{ alignItems: 'flex-end' }}>
            <h2>Date</h2>
            <span>03/02/2023 - 05/02/2023</span>
          </div>
          <div style={{ alignItems: 'flex-end' }}>
            <h2>Opening Balance</h2>
            <span>₦-48,428.421</span>
          </div>
          <div style={{ alignItems: 'flex-end' }}>
            <h2>Closing Balance</h2>
            <span>₦-41,166.24</span>
          </div>
        </InvoiceNoWrap>
        <UserInfo>
          <h2>{'Toby Maguire'}</h2>
          <p>
            34 Brown Street, VI
            <br />
            Lagos Island, Lagos
          </p>
        </UserInfo>
        <BillSection>
          <h3>
            <b>Summary</b>
          </h3>
          <div>
            <div style={{ alignItems: 'flex-end' }}>
              <h2>Money In</h2>
              <span>₦48,428.421</span>
            </div>
            <div style={{ alignItems: 'flex-end' }}>
              <h2>Money Out</h2>
              <span>₦-41,166.24</span>
            </div>
          </div>
        </BillSection>
        <ItemsSection>
          <ItemRow customBg="#dadada">
            <SmallColumn>
              <h2>Date</h2>
            </SmallColumn>
            <SmallColumn>
              <h2>Money In</h2>
            </SmallColumn>
            <SmallColumn>
              <h2>Money Out</h2>
            </SmallColumn>
            <SmallColumn>
              <h2>Category</h2>
            </SmallColumn>
            <Description>
              <h2>To/From</h2>
            </Description>
            <Description>
              <h2>Description</h2>
            </Description>
            <SmallColumn>
              <h2>Balance</h2>
            </SmallColumn>
          </ItemRow>
          <ItemRow customBg="#fff">
            <SmallColumn>
              <h2>11:15:26</h2>
            </SmallColumn>
            <SmallColumn>
              <h2
                style={{
                  color: '#34A853',
                }}
              >
                &#8358;2,500.00
              </h2>
            </SmallColumn>
            <SmallColumn>
              <h2
                style={{
                  color: '#FF6626',
                }}
              >
                &#8358;2,500.00
              </h2>
            </SmallColumn>
            <SmallColumn>
              <h2>Inward</h2>
            </SmallColumn>
            <Description>
              <h2>Mage/3464194508/Paga</h2>
            </Description>
            <Description>
              <h2>kip:100002/mage/merchr o/adebowale adebusuyi/39</h2>
            </Description>
            <SmallColumn>
              <h2>&#8358;5000</h2>
            </SmallColumn>
          </ItemRow>
        </ItemsSection>
        <UnderText>
          Mage (RC1920412). All rights reserved. Mage is a financial technology
          company and is not a bank. Banking services provided in collaboration
          with Kuda MFB and Paga. “Mage” and “Mechro Pay” are trademarks of Mage
          Technologies LTD. 34 Solanke Street, Yaba, Lagos. Nigeria. 8 The
          Green, Ste A, Dover DE, 19901, USA
        </UnderText>
        <TradeMark>
          <img src="/test/stamp.png" alt="Mage Logo" />
        </TradeMark>
      </MainWrapper>
      {/* </Layer>
      </Wrapper> */}
    </>
  );
};

export default StatementPreview;
