import React from 'react';
import {
  EmptyWrapper,
  MainWrapper,
  MoonImage,
  BlueText,
  TextWrap,
} from './style';
import { Link } from 'react-router-dom';

interface PropsArgs {
  img: string;
  header?: string;
  link?: string;
  linkMask?: string;
  extraText?: string;
  imgSize?: string;
}

const EmptyState = ({
  img,
  header,
  link,
  imgSize,
  linkMask,
  extraText,
}: PropsArgs) => {
  return (
    <>
      <EmptyWrapper>
        <MainWrapper>
          <MoonImage
            src={img}
            alt="Moon Image"
            style={
              imgSize
                ? {
                    width: imgSize,
                  }
                : {}
            }
          />
          <TextWrap>
            <p>
              {header && header !== ''
                ? header
                : `It's a little bit quiet here!`}
            </p>
            <p>
              <BlueText>
                <Link to={link && link !== '' ? link : '/add-merch'}>
                  {linkMask && linkMask !== '' ? linkMask : 'Add Product'}
                </Link>
              </BlueText>{' '}
              {extraText && extraText !== '' ? extraText : 'to start selling!'}
            </p>
          </TextWrap>
        </MainWrapper>
      </EmptyWrapper>
    </>
  );
};

export default EmptyState;
