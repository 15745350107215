import React from 'react';
import MainContent from 'app/components/split/setCurrency';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const SetCurrency = () => {
  return (
    <>
      <MainWrapper className="no-scrollbar">
        <MainContent />
      </MainWrapper>
    </>
  );
};

export default SetCurrency;
