import styled from 'styled-components';

export const InputFlex = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
  margin: 0 0 1rem 0;

  > input {
    font-size: 14px;
    padding: 0.8rem 3%;
    width: 48%;
    background: #ffffff;
    border: 0.5px solid #dadada;
    border-radius: 5px;

    @media (max-width: 728px) {
      font-size: 16px;
    }

    :focus {
      border: 0.5px solid rgba(22, 22, 22, 0.3);
    }
  }
`;

export const HeadText = styled.h1`
  font-size: 1.6rem;
  font-weight: bolder;
  padding: 0 0 0.8rem 0;
  text-align: center;
  color: #191919;

  @media (max-width: 1028px) {
    font-size: 1.5rem;
    padding: 0 0 2rem 0;
  }
`;

export const PwdGuideWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 46%;
    display: flex;
    align-items: flex-start;
    margin: 0 0 0.4rem 0;
    gap: 5px;

    > div {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 10rem;
      background: #f2f2f2;
      margin: 0.2rem 0 0 0;
    }

    > p {
      width: 90%;
      color: #474747;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export const SignupOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 2rem 0;
  margin: 0 auto;

  > div {
    display: flex;
    align-items: center;

    > img {
      width: auto;
      margin: 1rem 0.5rem 0 0.5rem;
    }
  }
`;

export const NavTypeWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem auto;
  border-radius: 10px;
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
`;

interface NavTypeItemProps {
  borderRadius: string;
  background: string;
  color: string;
}

export const NavTypeItem = styled.div<NavTypeItemProps>`
  width: 50%;
  background: ${p => p.background || '#f3f3f3'};
  color: ${p => p.color || '#ababab'};
  border-radius: ${p => p.borderRadius || 'none'};
  padding: 3px 20px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
  position: relative;

  @media (max-width: 728px) {
    padding: 3px 15px;
  }
`;

export const MobileInputWrap = styled.div`
  position: relative;
  margin: 0 0 1rem 0;
`;

interface PropsArgs2 {
  width?: string;
  border?: string;
}
export const MobileInput = styled.input<PropsArgs2>`
  width: ${p => p.width || '100%'};
  padding: 0.7rem 1rem 0.7rem 7rem;
  margin: 0rem 0 0 0;
  background: #ffffff;
  border: ${p => p.border || '1px solid #DADADA'};
  border-radius: 5px;
`;

export const MobileSelect = styled.div`
  border: none;
  background: transparent;
  color: #757575;
  position: absolute;
  font-size: 13px;
  top: 50%;
  width: 6.2rem;
  height: 50%;
  transform: translate(0%, -50%);
  left: 0.5rem;
  border-right: 1px solid #dadada;
  appearance: none;
  cursor: pointer;
  outline: none;
`;

export const FloatLogOption = styled.div`
  width: 40%;
  position: relative;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: Center;
  justify-content: flex-end;
  gap: 30px;

  a {
    width: auto;

    :hover {
      opacity: 0.8;
    }
  }
`;

export const FlagDisplay = styled.div`
  width: 1.6rem;
  position: absolute;
  left: 5.2rem;
  top: 50%;
  transform: translate(0, -50%;

  svg {
    width:1rem;
    height:1rem;
  }
`;
