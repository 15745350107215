import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  margin: 0rem 0% 0 0%;
  background: #fff;
  height: 100vh;
  overflow-y: auto;

  @media (max-width: 1028px) {
    width: 100%;
    padding: 00% 0;
    margin: 0rem;
  }
`;
export const LogoWrapper = styled.div`
  position: relative;
  margin: 0 0 1rem 0;

  @media (max-width: 728px) {
    display: flex;
    justify-content: flex-start;
    margin: 0rem 0 1rem 0;
  }
`;
export const MerchroLogo = styled.img`
  height: 2rem;
`;

export const FormWidget = styled.div`
  position: relative;
  width: 400px;
  margin: 0 auto;
  padding: 2%;
  background: #fff;
  border-top: 0.5px solid rgba(0, 0, 0, 0.05);
  border-left: 0.5px solid rgba(0, 0, 0, 0.05);
  border-right: 8px solid #8975f3;
  border-bottom: 8px solid #8975f3;
  border-radius: 10px;
  margin-top: 5rem;
  z-index: 10000;

  @media (max-width: 1028px) {
    width: 95%;
    margin: 3rem auto;
    padding: 2% 20px;
    box-shadow: none;
    // border: none;
  }
`;

export const AuthHeader = styled.div`
  background: #191919;
  padding: 20px 50px;
  display: flex;
  align-items: Center;
  justify-content: space-between;

  @media (max-width: 728px) {
    padding: 20px;
  }
`;
