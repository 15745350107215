import React, { useState } from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
} from './../../transactions/style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import { GenericTextArea } from './style';
import { TicketInput } from 'app/components/paymentLink/style';
import {
  AddPhotoBox,
  AddPhotoWrapper,
  PreviewImg,
} from './../../merch/addMerch/styles';
import { numOnly } from 'utils/numOnly';
import axios from 'axios';
import Alert from 'app/components/dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';
import { handleFileUpload } from 'utils/imageUpload';

interface PropsArgs {
  closeFunc: any;
  setSuccess: any;
  afterCreate: any;
}

const CreateProduct = ({ closeFunc, setSuccess, afterCreate }: PropsArgs) => {
  let creator = JSON.parse(localStorage.getItem('logged_user') || '{}');

  // States
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [cost, setCost] = useState<number>();
  // const [category, setCategory] = useState('')
  const [error, setError] = useState('');
  const [onProcess, setOnProcess] = useState<boolean>(false);

  const basicPayload = {
    name,
    cost,
    description,
    currency: 'NGN',
  };

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${creator.tokens.access.token}`,
    },
  };

  // Create Product Function
  const createProduct = async e => {
    e.preventDefault();

    if (returnedImages) {
      basicPayload['avatar'] = returnedImages;
    }
    setOnProcess(true);
    await axios
      .post(
        process.env.REACT_APP_BASE_ENDPOINT + '/invoices/products',
        basicPayload,
        option,
      )
      .then(res => {
        setSuccess(true);
        afterCreate();
        setOnProcess(false);
        setTimeout(() => {
          setSuccess(false);
          closeFunc();
        }, 4000);
      })
      .catch(err => {
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
        setOnProcess(false);
      });
  };

  const [images, setImages] = useState<any>([]);
  const [returnedImages, setReturnedImages] = useState<any>('');

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setImages,
      setError,
      setReturnedImages,
    });
  };

  const handleRemoveImage = index => {
    images.splice(index, 1);
    setImages([...images]);
    setReturnedImages('');
  };

  return (
    <>
      {error !== '' ? (
        <Alert
          closeFunc={() => setError('')}
          message="An Error occured!"
          miniMessage={error}
          img="/failed.png"
        />
      ) : null}

      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3 style={{ textAlign: 'left', fontSize: '20px' }}>
              Add New Product
            </h3>
          </HeaderWrap>
          <ModalMain borderTp="none" margin="none" padding="none">
            <form onSubmit={e => createProduct(e)}>
              <AddPhotoWrapper
                style={{
                  margin: '0 0 0.5rem 0',
                }}
              >
                {returnedImages === '' || images.length < 1 ? (
                  <AddPhotoBox
                    id="drop_zone"
                    style={{
                      height: '6rem',
                    }}
                  >
                    <label htmlFor="main-input">
                      <Icon.Image color="#757575" />
                      <p>Upload image</p>
                    </label>

                    <input
                      id="main-input"
                      type="file"
                      multiple={false}
                      style={{ display: 'none' }}
                      onChange={e => {
                        handleFile(e.target.files);
                      }}
                    ></input>
                  </AddPhotoBox>
                ) : null}
                {images && images.length > 0 ? (
                  <>
                    {images.map((item, index) => (
                      <AddPhotoBox
                        key={index}
                        style={{
                          height: '6rem',
                        }}
                      >
                        <div>
                          <Icon.Trash2
                            color="#fff"
                            onClick={() => handleRemoveImage(index)}
                          />
                        </div>
                        <PreviewImg src={URL.createObjectURL(item)} />
                      </AddPhotoBox>
                    ))}
                  </>
                ) : null}
              </AddPhotoWrapper>
              <TicketInput style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <p>Product/Service name</p>
                  <input
                    required={true}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                </div>
              </TicketInput>
              <TicketInput style={{ width: '100%' }}>
                <div style={{ width: '100%' }}>
                  <p>Product category</p>
                  <select>
                    <option>Select Category</option>
                    <option>Service</option>
                    <option>Physical Product</option>
                    <option>Digital Product</option>
                  </select>
                </div>
              </TicketInput>
              <TicketInput hasLabel={true}>
                <div style={{ width: '100%' }}>
                  <p>Price</p>
                  <label>&#8358;</label>
                  <input
                    type="number"
                    required={true}
                    value={cost}
                    onKeyPress={e => numOnly(e)}
                    onChange={e => setCost(Number(e.target.value))}
                  />
                </div>
              </TicketInput>
              <GenericTextArea
                placeholder="Product Description"
                value={description}
                required={true}
                onChange={e => setDescription(e.target.value)}
              ></GenericTextArea>
              <BigBtn>
                <button type="submit">
                  {onProcess ? <BeatLoader color="#fff" /> : 'Create Product'}
                </button>
              </BigBtn>
            </form>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>
    </>
  );
};

export default CreateProduct;
