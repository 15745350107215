import React, { useState, useEffect } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
  // BackNavigate,
} from './style';
import { ClipLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import axios from 'axios';
import { getSign } from 'utils/getCurrencySign';

interface PropsArgs {
  closeFunc: any;
  prevFunc: any;
  propPin: any;
  errorMessage?: any;
  onProcess: any;
  handleFinalPay: any;
  handlePinChange: any;
  accountNo?: any;
  accountName?: any;
  bank?: any;
  phoneNumber?: any;
  amount: any;
  type?: string;
  mode?: string;
  currency?: string;
}

const EnterPin = ({
  closeFunc,
  prevFunc,
  propPin,
  onProcess,
  handleFinalPay,
  handlePinChange,
  errorMessage,
  accountNo,
  accountName,
  bank,
  amount,
  phoneNumber,
  type,
  mode,
  currency,
}: PropsArgs) => {
  const inputStyle = {
    width: '1rem',
    height: '1rem',
    borderRadius: '10rem',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 8,
    display: 'flex',
    alignitems: 'Center',
    justifyContent: 'center',
  };

  const [fee, setFee] = useState<any>({});

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/fees`, {
        headers: { Authorization },
      })
      .then(res => {
        if (res.data) {
          setFee(res.data);
        }
      })
      .catch(err => {});
  }, []);

  const getFeeType = () => {
    if (mode && fee) {
      switch (mode) {
        case 'ngn':
          return fee.outboundTransfer;
        case 'usd':
          return 0;
        case 'split':
          return fee.splitFeeMerchroTransfer;
        case 'airtime':
          return fee.splitFeeMerchroTransfer;
        case 'merchro':
          return fee.merchroTransfer;
        default:
          return fee.outboundTransfer;
      }
    }
  };
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header></Header>
          <div>
            <h1
              className="my-5  text-center text-xl font-bold text-baseDark"
              style={{ fontFamily: 'Raleway' }}
            >
              Enter PIN
            </h1>
            <p
              style={{
                width: '20rem',
                margin: '0 0 2rem 0',
                color: 'grey',
                textAlign: 'center',
              }}
            >
              Confirm{' '}
              <span style={{ color: '#c82b38' }}>
                {getSign(currency || 'NGN')}
                {amount}
              </span>{' '}
              {type === 'airtime' ? 'airtime to' : 'payment to'}
              <span style={{ color: '#c82b38' }}>
                {' '}
                {type === 'airtime'
                  ? phoneNumber
                  : `${accountName} - ${accountNo} - ${bank}`}
              </span>
            </p>
            <p
              style={{
                width: '10rem',
                margin: '0 auto 2rem auto',
                color: '#898A8D',
                fontSize: '12px',
                textAlign: 'center',
                background: '#F4F6F6',
                borderRadius: '50px',
                padding: '0.3rem',
              }}
            >
              Transfer Fee:{' '}
              <b>
                {getSign(currency)}
                {getFeeType()}
              </b>
            </p>
            <PinInputWrap>
              <OtpInput
                value={propPin}
                onChange={handlePinChange}
                numInputs={4}
                inputStyle={inputStyle}
                isInputNum={true}
                shouldAutoFocus={true}
                //isInputSecure={true}
                className="otp-input"
              />
            </PinInputWrap>
            <p
              style={{
                color: '#c82b38',
                textAlign: 'center',
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {errorMessage}
            </p>
          </div>
          <BigBtn>
            <button
              onClick={e => handleFinalPay()}
              disabled={onProcess || propPin.length < 4 ? true : false}
            >
              {onProcess ? (
                <ClipLoader color="#fff" />
              ) : type === 'airtime' ? (
                'Buy airtime'
              ) : (
                'Send Money'
              )}
            </button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default EnterPin;

const PinInputWrap = styled.div`
  width: 10rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 0 0;
  background: #f3f3f3;
  border-radius: 35.6541px;

  @media (max-width: 728px) {
    width: 10remrea;
  }
`;
