import React, { useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { BottomProgress, UploadArea, UploadBoxContent } from './../songs/style';
// import { handleFileUpload } from 'utils/imageUpload';
import { handleAudioUpload } from 'utils/musicUpload';
import PopUpModal from '../merch/popupModal';
import { SearchPos } from '../transactions/style';

const AddData = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [returnedImages, setReturnedImages] = useState<any>('');
  const [isOver, setIsOver] = useState(false);
  const [musicLink, setMusicLink] = useState<any>('');
  const [length, setLength] = useState('00:00:00');
  const musicData: any = localStorage.getItem('song');
  const updateMusicData = musicData ? JSON.parse(musicData) : {};
  const [uploadProgress, setUploadProgress] = useState(0);

  function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${hours}:${minutes}:${remainingSeconds}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const meta = {
    ...updateMusicData,
    link: musicLink,
    length: length,
  };

  const toNext = async e => {
    e.preventDefault();
  };

  let musicUrls: any = [...returnedImages];

  const extraMusicFunc = data => {
    musicUrls.push(data);
    setMusicLink(musicUrls[0]);
    const durationInSeconds = musicUrls[1];
    const formattedDuration = secondsToHHMMSS(durationInSeconds);
    setLength(formattedDuration);
  };

  const handleFile = async (files: any) => {
    handleAudioUpload({
      files,
      setError: setErrorMessage,
      setReturnedImages,
      extraFunc: extraMusicFunc,
      setUploadInProcess: setUploadInProgress,
      setUploadProgress,
    });
  };

  function dropHandler(ev) {
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          let mockData: any = [];
          const file = item.getAsFile();
          mockData.push(file);
          handleFile(mockData);
        }
      });
    } else {
      [...ev.dataTransfer.files].forEach((file, i) => {
        handleFile(file);
      });
    }
  }

  function dragOverHandler(ev) {
    ev.preventDefault();
  }

  const handleClose = () => {
    setErrorMessage('');
  };

  return (
    <>
      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}
      <MainWrapper
        style={{ width: '100%' }}
        left="0"
        top="4.5rem"
        mobileTop="4rem"
      >
        <FragmentHeader
          showBack={true}
          pageName={
            updateMusicData && updateMusicData.title
              ? updateMusicData.title
              : `Add data`
          }
          backLink={'/split-payment'}
        />
        <SearchPos position="fixed"></SearchPos>
        <BreadcrumbArea
          style={{
            margin: '4rem 0 10rem 0',
          }}
        >
          <form onSubmit={toNext}>
            {!uploadInProgress && !returnedImages ? (
              <UploadArea
                id="drop_zone"
                onDrop={e => {
                  dropHandler(e);
                  setIsOver(false);
                }}
                onDragOver={e => {
                  dragOverHandler(e);
                  setIsOver(true);
                }}
                style={
                  isOver
                    ? {
                        opacity: 0.5,
                      }
                    : {}
                }
              >
                <UploadBoxContent>
                  <img src="/upload-linear.png" alt="Upload" />
                  <p>Drag and drop your royalty report here</p>
                  <input
                    id="main-input"
                    type="file"
                    multiple={false}
                    style={{ display: 'none' }}
                    accept={'.csv'}
                    onChange={e => {
                      handleFile(e.target.files);
                    }}
                  ></input>
                  <p>or</p>
                  <label htmlFor="main-input">
                    <p>Browse</p>
                  </label>
                </UploadBoxContent>
              </UploadArea>
            ) : null}
            <MainFlex
              style={{
                margin: '0',
                top: 0,
                padding: 0,
              }}
            >
              <PairSection width="35%" mobileMargin="0">
                <div
                  style={{
                    boxShadow: 'none',
                    background: 'none',
                  }}
                >
                  {returnedImages ? (
                    <BigBtn
                      style={{
                        width: '100%',
                      }}
                    >
                      <button
                        type="submit"
                        style={
                          returnedImages
                            ? {}
                            : {
                                background: '#757575',
                              }
                        }
                        disabled={returnedImages ? false : true}
                      >
                        Continue
                      </button>
                    </BigBtn>
                  ) : null}
                </div>
              </PairSection>
            </MainFlex>
          </form>
          {uploadInProgress ? (
            <BottomProgress text={`Uploading ${uploadProgress}%`}>
              <p>Uploading {uploadProgress}%</p>
              <progress value={uploadProgress} max="100">
                {uploadProgress}
              </progress>
            </BottomProgress>
          ) : null}
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default AddData;
