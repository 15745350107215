import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';

interface PropsArgs {
  setRelationship: any;
  setFirstName: any;
  setLastName: any;
  setEmail: any;
  setPhoneNo: any;
  setAddress: any;
  closeFunc: any;
}

const NextOfKinModal = ({
  closeFunc,
  setRelationship,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNo,
  setAddress,
}: PropsArgs) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn
            className="fas fa-times"
            onClick={() => closeFunc()}
          ></CloseBtn>
          <Header>Add next of kin</Header>
          <form onSubmit={closeFunc}>
            <div>
              <InputWrap>
                <label>Relationship</label>
                <select
                  required={true}
                  placeholder="Relationship"
                  onChange={e => setRelationship(e.target.value)}
                >
                  <option>Father</option>
                  <option>Mother</option>
                  <option>Brother</option>
                  <option>Sister</option>
                  <option>Husband</option>
                  <option>Wife</option>
                  <option>Grandparent</option>
                  <option>Other</option>
                </select>
              </InputWrap>
              <InputWrap>
                <input
                  type="text"
                  required={true}
                  placeholder="First name"
                  onChange={e => setFirstName(e.target.value)}
                />
              </InputWrap>
              <InputWrap>
                <input
                  type="text"
                  required={true}
                  placeholder="Last name"
                  onChange={e => setLastName(e.target.value)}
                />
              </InputWrap>
              <InputWrap>
                <input
                  type="email"
                  required={true}
                  placeholder="Email"
                  onChange={e => setEmail(e.target.value)}
                />
              </InputWrap>
              <InputWrap>
                <input
                  type="text"
                  required={true}
                  maxLength={11}
                  minLength={11}
                  placeholder="Phone number"
                  onChange={e => setPhoneNo(e.target.value)}
                />
              </InputWrap>
              <InputWrap>
                <input
                  type="text"
                  required={true}
                  placeholder="Address"
                  onChange={e => setAddress(e.target.value)}
                />
              </InputWrap>
            </div>
            <BigBtn>
              <button type="submit">Continue</button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default NextOfKinModal;
