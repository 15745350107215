import React, { useEffect, useState } from 'react';
import { MainWrapper } from '../invoice/mainInvoice/style';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from '../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { InputWrap, BigBtn } from '../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { numOnly } from 'utils/numOnly';
import CommaNumber from 'comma-number';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';
import {
  MobileInputWrap,
  MobileInput,
  MobileSelect,
} from './../../pages/SignUp/styles';
import {
  InputActPlacehold,
  CollabSuggestWrap,
  SplitUsersWrap,
} from './../../components/split/style';
import ReactCountryFlag from 'react-country-flag';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { fixedNum, roundTo } from 'utils/roundFloat';
import { NumericFormat } from 'react-number-format';
import { InputTag, SwapInfoCurrency } from './style';
import TransferOption from './transferOption';
import { handleZero } from 'utils/handleZero';
import { useSearchParams } from 'react-router-dom';

const WithdrawPoint = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const baseCurrency = search.get('tag');

  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [nairaBalance, setNairaBalance] = useState<any>('');
  const [dollarBalance, setDollarBalance] = useState<any>('');
  const [poundBalance, setPoundBalance] = useState<string | number>('');
  const [euroBalance, setEuroBalance] = useState<string | number>();

  useEffect(() => {
    if (baseCurrency === 'US') {
      setFirstCurrency('USD');
      setFirstFlag('US');
      setSecondCurrency('USD');
      setSecondFlag('US');
    }
  }, [baseCurrency]);

  // Get Balance
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/account-info?`, {
        headers: { Authorization },
      })
      .then(resp => {
        setNairaBalance(resp.data.balance.naira);
        setDollarBalance(resp.data.balance.dollar);
        setPoundBalance(resp.data.balance.pound);
        setEuroBalance(resp.data.balance.euro);
        localStorage.setItem('accountInfo', JSON.stringify(resp.data));
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [Authorization, navigate]);

  // Get Country Flag
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [secondInputFocus, setSecondInputFocus] = useState(false);
  const [firstFlag, setFirstFlag] = useState('NG');
  const [firstCurrency, setFirstCurrency] = useState('NGN');
  const [secondFlag, setSecondFlag] = useState('NG');
  const [secondCurrency, setSecondCurrency] = useState('NGN');
  const [sourceAmount, setSourceAmount] = useState<any>(0);
  const [sourcePayloadAmount, setSourcePayloadAmount] = useState<number>(
    sourceAmount.toString(),
  );

  const [targetAmount, setTargetAmount] = useState<any>(0);
  const [targetPayloadAmount, setTargetPayloadAmount] = useState<number>(
    targetAmount.toString(),
  );
  const [success, setSuccess] = useState(false);
  const [clientErr, setClientErr] = useState('');
  const [error, setError] = useState('');
  const [rate, setRate] = useState<any>({});
  const [baseRate, setBaseRate] = useState<any>({});
  const [changePoint, setChangePoint] = useState('');
  const [openTransferOption, setOpenTransferOption] = useState(false);

  const getSign = currency => {
    try {
      switch (currency) {
        case 'USD':
          return '$';
        case 'NGN':
          return '₦';
        case 'GBP':
          return '£';
        case 'EUR':
          return '€';
        default:
          break;
      }
    } catch {}
  };

  const renderRateInfo = () => {
    if (
      rate &&
      Object.keys(rate).length > 0 &&
      Object.keys(baseRate).length > 0
    ) {
      if (
        Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)
      ) {
        return `${getSign(firstCurrency)}1 = ${getSign(
          secondCurrency,
        )}${roundTo(1 / rate.buy, 5)}`;
      }
      if (
        Object.keys(baseRate).includes(`${secondCurrency}/${firstCurrency}`)
      ) {
        return `${getSign(firstCurrency)}1 = ${getSign(secondCurrency)}${
          rate.sell
        }`;
      }
    }
  };

  // Reformat Amount
  useEffect(() => {
    // if (Number(sourceAmount) >) {
    setSourcePayloadAmount(
      Number(
        sourceAmount.toLocaleString().includes(',')
          ? sourceAmount.toLocaleString().replace(new RegExp(',', 'g'), '')
          : sourceAmount,
      ),
    );
    // }
  }, [sourceAmount]);

  // Reformat Amount
  useEffect(() => {
    // if (Number(targetAmount) > 0) {
    setTargetPayloadAmount(
      Number(
        targetAmount.toLocaleString().includes(',')
          ? targetAmount.toLocaleString().replace(new RegExp(',', 'g'), '')
          : targetAmount,
      ),
    );
    // }
  }, [targetAmount]);

  // Get Currency Pair
  const getPair = () => {
    if (Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)) {
      return `${firstCurrency}/${secondCurrency}`;
    } else if (firstCurrency === secondCurrency) {
      return 'null';
    } else {
      return `${secondCurrency}/${firstCurrency}`;
    }
  };

  const getAction = () => {
    if (Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)) {
      return `buy`;
    } else if (firstCurrency === secondCurrency) {
      return 'null';
    } else {
      return `sell`;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/exchange-rate`, {
        headers: { Authorization },
      })
      .then(res => {
        setBaseRate(res.data);
        setRate(res.data['NGN/USD']);
      })
      .catch(err => {});
  }, [Authorization]);

  // Get and Calculate Target Value

  // Rate based on currency pair
  useEffect(() => {
    try {
      if (firstCurrency === 'NGN') {
        setRate(baseRate[`NGN/${secondCurrency}`]);
      } else if (secondCurrency === 'NGN') {
        setRate(baseRate[`NGN/${firstCurrency}`]);
      } else if (firstCurrency !== 'NGN' && secondCurrency !== 'NGN') {
        if (
          Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)
        ) {
          setRate(baseRate[`${firstCurrency}/${secondCurrency}`]);
        } else {
          setRate(baseRate[`${secondCurrency}/${firstCurrency}`]);
        }
      }
    } catch {}
  }, [firstCurrency, secondCurrency, baseRate]);

  // Get and Calculate Target Value

  useEffect(() => {
    if (changePoint === 'first') {
      if (
        Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)
      ) {
        if (sourceAmount && sourceAmount.includes(',')) {
          if (rate?.buy) {
            setTargetAmount(
              fixedNum(Number(sourcePayloadAmount) * (1 / rate?.buy), 2),
            );
          }
        } else {
          setTargetAmount(fixedNum(Number(sourceAmount) * (1 / rate?.buy), 2));
        }
      } else if (
        Object.keys(baseRate).includes(`${secondCurrency}/${firstCurrency}`)
      ) {
        if (sourceAmount && sourceAmount.includes(',')) {
          if (rate?.sell) {
            setTargetAmount(
              fixedNum(Number(sourcePayloadAmount) * rate?.sell, 2),
            );
          }
        } else {
          setTargetAmount(fixedNum(Number(sourceAmount) * rate?.sell, 2));
        }
      }
    }
  }, [
    rate,
    baseRate,
    changePoint,
    firstCurrency,
    secondCurrency,
    sourceAmount,
    sourcePayloadAmount,
  ]);

  const secondCalculation = e => {
    if (Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)) {
      if (e && e.includes(',')) {
        setSourceAmount(
          fixedNum(Number(e.replace(new RegExp(',', 'g'), '')) * rate.buy, 2),
        );
      } else {
        setSourceAmount(fixedNum(Number(e) * rate.buy, 2));
      }
    } else if (
      Object.keys(baseRate).includes(`${secondCurrency}/${firstCurrency}`)
    ) {
      if (e && e.includes(',')) {
        setSourceAmount(
          fixedNum(
            Number(e.replace(new RegExp(',', 'g'), '')) * (1 / rate.sell),
            2,
          ),
        );
      } else {
        setSourceAmount(fixedNum(Number(e) * (1 / rate.sell), 2));
      }
    }
  };

  const continuePayment = e => {
    e.preventDefault();
    if (firstCurrency === 'NGN') {
      if (Number(sourcePayloadAmount) > 100) {
        if (Number(sourcePayloadAmount) <= Number(nairaBalance)) {
          setOpenTransferOption(true);
        } else {
          setError(`Not enough balance to perform this transaction!`);
          setTimeout(() => {
            setError('');
          }, 4000);
        }
      } else {
        setClientErr(`Amount must be greater than ₦100

`);
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else if (firstCurrency === 'USD') {
      if (Number(sourcePayloadAmount) > 1) {
        if (Number(sourcePayloadAmount) <= Number(dollarBalance)) {
          setOpenTransferOption(true);
        } else {
          setError(`Not enough balance to perform this transaction!`);
          setTimeout(() => {
            setError('');
          }, 4000);
        }
      } else {
        setClientErr(`Amount must be greater than $1`);
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    }
  };

  useEffect(() => {
    if (firstCurrency === secondCurrency) {
      if (changePoint === 'first') {
        setTargetAmount(sourceAmount);
      } else {
        setSourceAmount(targetAmount);
      }
    }
  }, [changePoint, firstCurrency, secondCurrency, sourceAmount, targetAmount]);

  // Get Balance Display
  const getBalance = () => {
    try {
      switch (firstCurrency) {
        case 'USD':
          return dollarBalance;
        case 'NGN':
          return nairaBalance;
        case 'GBP':
          return poundBalance;
        case 'EUR':
          return euroBalance;
        default:
          break;
      }
    } catch {}
  };

  return (
    <>
      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            navigate('/');
          }}
          img="/success.png"
          message="Success!"
          miniMessage={`Your currency swap from ${firstCurrency} to ${secondCurrency} was successful! `}
        />
      ) : null}

      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
        onClick={() => {
          setIsInputFocus(false);
          setSecondInputFocus(false);
        }}
      >
        <FragmentHeader showBack={true} pageName="Select Currency/Amount" />
        <BreadcrumbArea>
          <form onSubmit={e => continuePayment(e)}>
            <PreferenceBlock>
              <Constraint
                top="-2rem"
                mobileTop="-2rem"
                style={{ margin: '0 auto' }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label></label>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        letterSpacing: '-0.02em',
                        color: '#ABABAB',
                      }}
                    >
                      <span>
                        Balance: {getSign(firstCurrency)}
                        {CommaNumber(roundTo(getBalance(), 2))}
                      </span>
                    </p>
                  </div>

                  {baseCurrency !== 'US' && (
                    <>
                      <MobileInputWrap>
                        <MobileSelect>
                          <div
                            style={{
                              width: '6rem',
                            }}
                          >
                            <InputActPlacehold
                              onClick={e => {
                                setIsInputFocus(!isInputFocus);
                                e.stopPropagation();
                              }}
                              onBlur={() => setIsInputFocus(false)}
                            >
                              <div>
                                <ReactCountryFlag
                                  style={{
                                    fontSize: '1.3rem',
                                    borderRadius: '50%',
                                  }}
                                  countryCode={firstFlag}
                                  svg
                                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                  cdnSuffix="svg"
                                />
                                <p
                                  style={{
                                    color: '#191919',
                                  }}
                                >
                                  {firstCurrency}
                                </p>
                              </div>
                              <i
                                style={{
                                  marginLeft: '1rem',
                                }}
                              >
                                <Icon.ChevronDown size={15} strokeWidth={3} />
                              </i>
                            </InputActPlacehold>
                          </div>
                        </MobileSelect>
                        <CollabSuggestWrap
                          style={{
                            margin: '0rem 0 0 0',
                            width: '8rem',
                            zIndex: '1000000000',
                            top: '2rem',
                          }}
                        >
                          {isInputFocus ? (
                            <div>
                              {Countries && Countries.length > 0 ? (
                                Countries.slice(0, 2).map((item, index) => (
                                  <SplitUsersWrap
                                    alignVertical="flex-start"
                                    key={index}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                      setFirstFlag(item.code);
                                      setFirstCurrency(item.name);
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: '10px',
                                          width: '100%',
                                        }}
                                      >
                                        <ReactCountryFlag
                                          style={{
                                            fontSize: '1.3rem',
                                            borderRadius: 'none',
                                          }}
                                          countryCode={item.code}
                                          svg
                                          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                          cdnSuffix="svg"
                                          title={item.code}
                                        />
                                        <h3
                                          style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            width: '25%',
                                          }}
                                        >
                                          {item.name
                                            ? `${item.name}        `
                                            : ''}
                                        </h3>
                                      </div>
                                    </div>
                                  </SplitUsersWrap>
                                ))
                              ) : (
                                <p
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    color: '#757575',
                                    margin: '1rem 0 0 0',
                                  }}
                                >
                                  No Option.
                                </p>
                              )}
                            </div>
                          ) : null}
                        </CollabSuggestWrap>
                        <div className="hide-amount-input">
                          <NumericFormat
                            value={sourceAmount}
                            thousandSeparator={true}
                            onValueChange={e =>
                              setSourceAmount(e.formattedValue)
                            }
                          />
                        </div>
                        <MobileInput
                          type="text"
                          value={sourceAmount}
                          onKeyPress={e => {
                            numOnly(e);
                            handleZero(e);
                          }}
                          onChange={e => setSourceAmount(e.target.value)}
                          onFocus={() => setChangePoint('first')}
                          required={true}
                          autoComplete={'off'}
                        />
                        <InputTag>Send from</InputTag>
                      </MobileInputWrap>
                    </>
                  )}
                  {baseCurrency !== 'US' && (
                    <SwapInfoCurrency>
                      <section></section>
                      {firstCurrency !== secondCurrency ? (
                        <div>
                          <hr />
                          <div>
                            <IconSax.Repeat size={11} color="#ababab" />
                          </div>
                          <p>{renderRateInfo()}</p>
                        </div>
                      ) : null}

                      <div>
                        <hr />
                        <div>
                          <Icon.X size={11} color="#ababab" />
                        </div>
                        <p>Fee = ₦0</p>
                      </div>
                    </SwapInfoCurrency>
                  )}
                  {/* Other Currency (Swap To) */}
                  <>
                    <InputWrap>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <label></label>
                      </div>
                    </InputWrap>
                    <MobileInputWrap>
                      <MobileSelect>
                        <div
                          style={{
                            width: '6rem',
                          }}
                        >
                          <InputActPlacehold
                            onClick={e => {
                              setSecondInputFocus(!secondInputFocus);
                              e.stopPropagation();
                            }}
                            onBlur={() => setSecondInputFocus(false)}
                          >
                            <div>
                              <ReactCountryFlag
                                style={{
                                  fontSize: '1.3rem',
                                  borderRadius: '50%',
                                }}
                                countryCode={secondFlag}
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                cdnSuffix="svg"
                              />
                              <p
                                style={{
                                  color: '#191919',
                                }}
                              >
                                {secondCurrency}
                              </p>
                            </div>
                            <i>
                              <Icon.ChevronDown size={15} strokeWidth={3} />
                            </i>
                          </InputActPlacehold>
                        </div>
                      </MobileSelect>
                      <div className="hide-amount-input">
                        <NumericFormat
                          value={targetAmount}
                          thousandSeparator={true}
                          onValueChange={e => setTargetAmount(e.formattedValue)}
                        />
                      </div>
                      <CollabSuggestWrap
                        style={{
                          margin: '0rem 0 0 0',
                          width: '8rem',
                          zIndex: '1000000000',
                          top: '2rem',
                        }}
                      >
                        {secondInputFocus ? (
                          <div>
                            {Countries && Countries.length > 0 ? (
                              Countries.slice(0, 2).map((item, index) => (
                                <SplitUsersWrap
                                  alignVertical="flex-start"
                                  key={index}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setSecondFlag(item.code);
                                    setSecondCurrency(item.name);
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        width: '100%',
                                      }}
                                    >
                                      <ReactCountryFlag
                                        style={{
                                          fontSize: '1.3rem',
                                          borderRadius: 'none',
                                        }}
                                        countryCode={item.code}
                                        svg
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        cdnSuffix="svg"
                                        title={item.code}
                                      />
                                      <h3
                                        style={{
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          whiteSpace: 'nowrap',
                                          width: '25%',
                                        }}
                                      >
                                        {item.name
                                          ? `${item.name}        `
                                          : ''}
                                      </h3>
                                    </div>
                                  </div>
                                </SplitUsersWrap>
                              ))
                            ) : (
                              <p
                                style={{
                                  textAlign: 'center',
                                  fontSize: '14px',
                                  color: '#757575',
                                  margin: '1rem 0 0 0',
                                }}
                              >
                                No Option.
                              </p>
                            )}
                          </div>
                        ) : null}
                      </CollabSuggestWrap>
                      {baseCurrency === 'NG' ? (
                        <>
                          <MobileInput
                            type="text"
                            value={targetAmount}
                            onKeyPress={e => {
                              numOnly(e);
                              handleZero(e);
                            }}
                            onChange={e => {
                              setTargetAmount(e.target.value);
                              secondCalculation(e.target.value);
                            }}
                            onFocus={() => setChangePoint('second')}
                            required={true}
                            autoComplete={'off'}
                            disabled
                          />
                        </>
                      ) : (
                        <>
                          <MobileInput
                            type="text"
                            value={targetAmount}
                            onKeyPress={e => {
                              numOnly(e);
                              handleZero(e);
                            }}
                            onChange={e => {
                              setTargetAmount(e.target.value);
                              secondCalculation(e.target.value);
                            }}
                            onFocus={() => setChangePoint('second')}
                            required={true}
                            autoComplete={'off'}
                          />
                        </>
                      )}
                      <InputTag>Recipient receives</InputTag>
                    </MobileInputWrap>
                  </>
                </div>
                {clientErr || error ? (
                  <p
                    style={{
                      color: '#c82b32',
                      fontSize: '12px',
                      margin: '2rem 0 0 0',
                      textAlign: 'center',
                    }}
                  >
                    {clientErr || error}
                  </p>
                ) : null}
                <BigBtn width="100%">
                  <button type="submit">Continue</button>
                </BigBtn>
              </Constraint>
            </PreferenceBlock>
          </form>
        </BreadcrumbArea>
      </MainWrapper>

      {openTransferOption ? (
        <TransferOption
          amount={targetPayloadAmount}
          sourceAmount={sourcePayloadAmount}
          closeFunc={() => setOpenTransferOption(false)}
          point={firstCurrency}
          to={secondCurrency}
          currencyPair={getPair()}
          exchangeType={getAction()}
        />
      ) : null}
    </>
  );
};

export default WithdrawPoint;

export const Countries = [
  {
    name: 'NGN',
    code: 'NG',
  },
  {
    name: 'USD',
    code: 'US',
  },
  {
    name: 'GBP',
    code: 'GB',
  },
  {
    name: 'EUR',
    code: 'EU',
  },
];
