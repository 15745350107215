import {
  InAppHeader,
  MainWrapper,
} from 'app/components/storeComp/mainContent/styles';
import DealList from './DealList';
import SideNav from 'app/components/storeComp/sideNav/sideNav';
import AppHeader from 'app/components/storeComp/mainContent/header/header';
import { useEffect, useState } from 'react';

const Deal = () => {
  const [size, setSize] = useState(window.innerWidth);
  useEffect(() => {
    setSize(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);
  return (
    <div style={{ background: '#151515', height: '100vh' }}>
      <MainWrapper className="no-scrollbar" style={{ marginLeft: 11.5 }}>
        {size > 1028 && <SideNav mobileShow="none" />}
        <AppHeader pageName="Loan Leads" showBack={true} />
        <InAppHeader
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h3 style={{ width: '100%' }}>Loan Leads</h3>
          <p style={{ color: '#161616', fontSize: 14 }}>
            Click on a lead to see full business profile and attached documents
            and statement analysis.
          </p>
        </InAppHeader>
        <br /> <br />
        <DealList />
      </MainWrapper>
    </div>
  );
};

export default Deal;
