import CreateInv from 'app/components/invoice/mainInvoice/createInvoice';
import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const CreateInvInv = () => {
  return (
    <>
      <MainWrapper className="no-scrollbar">
        <SideNav mobileShow="none" />
        <CreateInv />
      </MainWrapper>
    </>
  );
};

export default CreateInvInv;
