import React from 'react';
import { BigBtn } from '../../dashboard/mainContent/fundWallet/style';
import Webcam from 'react-webcam';

const videoConstraints = {
  width: 310,
  height: 310,
  facingMode: 'user',
};

const Capture = ({ onUpdate }) => {
  const convertBase64ToFile = (base64, fileName) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays: Uint8Array[] = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers: number[] = [];
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: 'image/jpeg' });
    const file = new File([blob], fileName, { type: 'image/jpeg' });
    return file;
  };

  const webcamRef = React.useRef<any>(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const file = convertBase64ToFile(imageSrc, 'capturedImage.jpeg');
    onUpdate(file);
  }, [onUpdate]);

  return (
    <>
      <Webcam
        audio={false}
        height={300}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={317}
        style={{ transform: 'scaleX(-1)' }}
        videoConstraints={videoConstraints}
      />

      <BigBtn>
        <button onClick={capture}>Capture photo</button>
      </BigBtn>
    </>
  );
};

export default Capture;
