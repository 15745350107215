import React from 'react';
import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';
import CardView from 'app/components/card/cardView';

const CardViewPage = () => {
  return (
    <>
      <MainWrapper className="no-scrollbar">
        <SideNav mobileShow="none" />
        <CardView />
      </MainWrapper>
    </>
  );
};

export default CardViewPage;
