import React from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
} from './../../transactions/style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import { GenericTextArea } from './style';

interface PropsArgs {
  closeFunc: any;
  header: any;
  subText?: any;
  setInvoiceNote: any;
  invoiceNote: any;
}

const InvoiceNote = ({
  closeFunc,
  header,
  subText,
  setInvoiceNote,
  invoiceNote,
}: PropsArgs) => {
  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3 style={{ textAlign: 'left', fontSize: '20px' }}>{header}</h3>
            <p style={{ textAlign: 'left', margin: '0 0 1rem 0' }}>{subText}</p>
          </HeaderWrap>
          <ModalMain borderTp="none" margin="none" padding="none">
            <form onSubmit={() => closeFunc()}>
              <GenericTextArea
                placeholder="Write something!"
                onChange={e => setInvoiceNote(e.target.value)}
                value={invoiceNote}
                required={true}
              ></GenericTextArea>
              <BigBtn>
                <button type="submit">Add Note</button>
              </BigBtn>
            </form>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>
    </>
  );
};

export default InvoiceNote;
