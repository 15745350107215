import React, { useEffect, useState } from 'react';
import { MainWrapper } from '../invoice/mainInvoice/style';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from '../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { InputWrap, BigBtn } from '../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { numOnly } from 'utils/numOnly';
import CommaNumber from 'comma-number';
import {
  MobileInputWrap,
  MobileInput,
  MobileSelect,
} from './../../pages/SignUp/styles';
import { InputActPlacehold } from './../../components/split/style';
import ReactCountryFlag from 'react-country-flag';
import uuid from 'react-uuid';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';

const WithdrawFundComp = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [amount, setAmount] = useState('');
  const [cardInformation, setCardInformation] = useState<any>({});
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // Get Country Flag
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [flag, setFlag] = useState('US');
  const [activeCurrency, setActiveCurrency] = useState('USD');

  // Get Card Information
  const getCard = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/cards/${id}`, {
        headers: { Authorization },
      })
      .then(res => {
        setCardInformation(res.data);
        if (res.data.currency === 'USD') {
          setFlag('US');
          setActiveCurrency('USD');
        } else if (res.data.currency === 'NGN') {
          setFlag('NG');
          setActiveCurrency('NGN');
        }
      })
      .catch(err => {});
  };

  useEffect(() => {
    getCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const payload = {
    amount: amount,
    idempotentKey: uuid(),
  };

  const postFunction = () => {
    setOnProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/payments/cards/${id}/withdraw`,
        payload,
        headers,
      )
      .then(res => {
        setOnProcess(false);
        setSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 6000);
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };
  const withdraw = e => {
    e.preventDefault();
    if (Number(amount) <= Number(cardInformation.balance)) {
      postFunction();
    } else {
      setError(`Sorry, you don't have enough to perform this transaction!`);
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  return (
    <>
      {error ? (
        <Alert
          closeFunc={() => setError('')}
          img="/failed.png"
          message="An error occured"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            navigate('/');
          }}
          img="/success.png"
          message="Success!"
          miniMessage={`Your have successfully withdrawn funds from your virtual card! `}
        />
      ) : null}
      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
        onClick={() => {
          setIsInputFocus(false);
        }}
      >
        <FragmentHeader
          showBack={true}
          pageName="Withdraw Card"
          backLink={`/view-card`}
        />
        <BreadcrumbArea>
          <form onSubmit={e => withdraw(e)}>
            <PreferenceBlock>
              <Constraint
                top="4rem"
                mobileTop="-2rem"
                style={{ margin: '0 auto' }}
              >
                <div>
                  <div className="hide-amount-input">
                    {/* <NumericFormatProps
                    thousandSeparator={true}
                  /> */}
                  </div>
                  <InputWrap>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label>Enter Amount ($)</label>
                      <p
                        style={{
                          fontWeight: '500',
                          fontSize: '12px',
                          lineHeight: '16px',
                          letterSpacing: '-0.02em',
                          color: '#ABABAB',
                        }}
                      >
                        Balance:
                        {activeCurrency === 'USD' ? '$' : <span>&#8358;</span>}
                        {CommaNumber(cardInformation.balance)}
                      </p>
                    </div>
                  </InputWrap>
                  <MobileInputWrap>
                    <MobileSelect>
                      <div
                        style={{
                          width: '6rem',
                        }}
                      >
                        <InputActPlacehold
                          onClick={e => {
                            setIsInputFocus(!isInputFocus);
                            e.stopPropagation();
                          }}
                          onBlur={() => setIsInputFocus(false)}
                        >
                          <div>
                            <ReactCountryFlag
                              style={{
                                fontSize: '1.3rem',
                                borderRadius: '50%',
                              }}
                              countryCode={flag}
                              svg
                              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                              cdnSuffix="svg"
                            />
                            <p
                              style={{
                                color: '#191919',
                              }}
                            >
                              {activeCurrency}
                            </p>
                          </div>
                        </InputActPlacehold>
                      </div>
                    </MobileSelect>
                    <MobileInput
                      type="number"
                      onKeyPress={e => {
                        numOnly(e);
                      }}
                      onChange={e => setAmount(e.target.value)}
                      required={true}
                      autoComplete={'off'}
                    />
                  </MobileInputWrap>
                  <p
                    style={{
                      fontSize: '11px',
                      color: '#898A8D',
                      padding: '0.3rem',
                      background: '#F4F6F6',
                      borderRadius: '50px',
                      display: 'inline-block',
                    }}
                  >
                    Card balance after withdrawal will be{' '}
                    {Number(cardInformation.balance) - Number(amount)}
                  </p>
                </div>
                <BigBtn width="100%">
                  <button
                    type="submit"
                    disabled={onProcess}
                    style={
                      onProcess
                        ? {
                            cursor: 'not-allowed',
                          }
                        : {
                            cursor: 'pointer',
                          }
                    }
                  >
                    {onProcess ? <BeatLoader color="#fff" /> : 'Withdraw Card'}
                  </button>
                </BigBtn>
              </Constraint>
            </PreferenceBlock>
          </form>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default WithdrawFundComp;

export const Countries = [
  {
    name: 'NGN',
    code: 'NG',
  },
  {
    name: 'USD',
    code: 'US',
  },
];
