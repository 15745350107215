import React from 'react';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

import SuccessPage from 'app/components/paymentSuccess';

const PaymentSuccess = () => {
  return (
    <>
      <MainWrapper className="no-scrollbar">
        <SuccessPage />
      </MainWrapper>
    </>
  );
};

export default PaymentSuccess;
