import { useState, useEffect } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import {
  TableWrap,
  TableHeader,
  PurposeWrap,
  DateWrap,
  StatusWrap,
  MenuWrap,
  MenuContent,
  SearchPos,
} from 'app/components/transactions/style';
import {
  HeaderPopUp,
  SingleFlex,
  SplitImage,
  CollaboratorSpace,
  MenuHeader,
  MenuHeaderText,
  ScrollDiv,
} from './style';
import {
  ButtonWrap,
  CustomButtonDark,
  ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
import EditSplit from './editSplit';
import Details from '../transactions/details';
import DeleteWarning from './deleteWarning';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import Alert from '../dashboard/mainContent/fundWallet/alert';

import TransactionDetails from './transactionDetail';
import CommaNumber from 'comma-number';
import WithdrawWarning from './withdrawWarning';
import uuid from 'react-uuid';

import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import ApproveModal from './approveModal';

import FundSplitAmount from './fundAmount';
import { Header } from '../dashboard/mainContent/fundWallet/style';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import EmptyState from '../emptyState/emptyState';
import AllCollaborators from './allCollaborators';
import { FlexedApart } from '../storeComp/sideNav/style';
import { DistributeLink, MusicInfo } from '../distributors/style';
import moment from 'moment';
import {
  HistoryItemCard,
  HistoryInfoSec,
  HistoryAmount,
  HistoryTextWrap,
  HistoryItemIconWrap,
} from '../dashboard/mainContent/style';
import { titleCase } from 'utils/capitalizeFirst';
import { getSign } from 'utils/getCurrencySign';
import { getRequest } from 'utils/request';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const MainContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const userBankAccount = JSON.parse(
    localStorage.getItem('accountInfo') || '{}',
  );

  const activeIndex = JSON.parse(localStorage.getItem('deixn') || '{}');

  const [showCollaborators, setShowCOllaborators] = useState(false);
  const [activeHeader, setActiveHeader] = useState(0);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openTransactionDetails, setOpenTransactionDetails] = useState(false);

  const [showFundSplit, setShowFundSplit] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const [mainData, setMainData] = useState<any>({});
  const [artistName, setArtistName] = useState<any>([]);
  const [balanceInfo, setBalanceInfo] = useState<any>({});
  const [accountInfo, setAccountInfo] = useState<any>({});
  const [collaborators, setCollaborators] = useState<any>([]);
  const [finalCollaborators, setFinalCollaborators] = useState<any>([]);

  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [allCollaborators, setAllCollaborators] = useState<any>('');
  const [payments, setPayments] = useState<any>([]);
  const [loadedTransaction, setLoadedTransaction] = useState<any>([]);
  const [transactions, setTransactions] = useState<any>([]);
  const [modifiedtransactions, setModifiedTransactions] = useState<any>([]);
  const [approvedSuccess, setApprovedSuccess] = useState(false);
  const [toggleId, setToggleId] = useState('');
  const [toggleActive, setToggleActive] = useState(false);
  const [catergories, setCategories] = useState('');
  const [currency, setCurrency] = useState('');

  // Handle Delete
  const [deleteInProcess, setDeleteInProcess] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getRequest(`splits/collaborators?sortBy=createdAt:desc&paginate=false`)
      .then(res => {
        setAllCollaborators(res);
      })
      .catch(_err => {});
  }, [Authorization]);

  // Functions
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getTransactions = () => {
    getRequest(`splits/${id}/transactions?paginate=false`)
      .then(res => {
        setLoadedTransaction(res);
      })
      .catch(_err => {});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getIncome = () => {
    getRequest(`splits/${id}/income`)
      .then(res => {
        setPayments(res.results);
      })
      .catch(_err => {});
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSplit = () => {
    let mockData: any = [];
    getRequest(`splits/${id}?include=collaborators.collaboratorId`)
      .then(res => {
        setBalanceInfo(res.cashFlow);
        setAccountInfo(res.accountInfo);
        if (res.collaborators.length > 0) {
          for (let i = 0; i < res.collaborators.length; i++) {
            mockData.push({
              name: res.collaborators[i].collaboratorId.name,
              email: res.collaborators[i].collaboratorId.email,
              ...res.collaborators[i],
            });
          }
        }
        setCollaborators(mockData);
        setMainData(res.data);
        if (res.data.meta) {
          setArtistName(res.data.meta);
        }
        if (res.data.splitCategory) {
          setCategories(res.data.splitCategory);
        } else {
          setCategories('custom');
        }
        setCurrency(res.data.currency);
      })
      .catch(_err => {});
  };

  useEffect(() => {
    let mockData: any = [];
    getRequest(`splits/${id}?include=collaborators.collaboratorId`)
      .then(res => {
        setBalanceInfo(res.cashFlow);
        setAccountInfo(res.accountInfo);
        if (res.collaborators.length > 0) {
          for (let i = 0; i < res.collaborators.length; i++) {
            mockData.push({
              name: res.collaborators[i].collaboratorId.name,
              email: res.collaborators[i].collaboratorId.email,
              ...res.collaborators[i],
            });
          }
        }
        setCollaborators(mockData);
        setMainData(res);
        if (res.meta) {
          setArtistName(res.meta);
        }
        if (res.splitCategory) {
          setCategories(res.splitCategory);
        } else {
          setCategories('custom');
        }
        setCurrency(res.currency);
      })
      .catch(_err => {});
  }, [Authorization, id]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/splits/${id}/income`, {
        headers: { Authorization },
      })
      .then(res => {
        setPayments(res.data.results);
      })
      .catch(_err => {});
  }, [Authorization, id, showFundSplit]);

  useEffect(() => {
    getRequest(`splits/${id}/transactions?paginate=false`)
      .then(res => {
        setLoadedTransaction(res);
      })
      .catch(_err => {});
  }, [id]);

  useEffect(() => {
    let mockData: any = [];
    if (loadedTransaction && loadedTransaction.length > 0) {
      for (let i = 0; i < loadedTransaction.length; i++) {
        if (!loadedTransaction[i].splitFee) {
          mockData.push(loadedTransaction[i]);
        }
      }
      setTransactions(mockData);
    }
  }, [loadedTransaction, showFundSplit, success]);

  const filteredTransactions = loadedTransaction.filter(
    (transaction: any) => !(transaction.splitFee && transaction.amount === 0),
  );

  useEffect(() => {
    const uniqueCollaborators: any = [];
    if (transactions.length > 0 && collaborators.length > 0) {
      transactions.forEach(trx => {
        if (trx.collaborator && trx.collaborator !== undefined) {
          const index = uniqueCollaborators.findIndex(
            x => x.collaborator === trx.collaborator,
          );
          if (index !== -1) {
            uniqueCollaborators[index].name =
              activeIndex === 1
                ? (
                    collaborators.find(
                      p =>
                        p.collaboratorId &&
                        p.collaboratorId.id === trx.collaborator,
                    ) || {}
                  ).name || trx.meta.accountName
                : (allCollaborators.find(p => p.id === trx.collaborator) || {})
                    .name || trx.meta.accountName;
            uniqueCollaborators[index].totalAmount += trx.amount;
            uniqueCollaborators[index].trxRecords.push({
              amount: trx.amount,
              updatedAt: trx.createdAt,
              status: trx.status,
            });
          } else {
            uniqueCollaborators.push({
              collaborator: trx.collaborator,
              trxRecords: [
                {
                  amount: trx.amount,
                  updatedAt: trx.createdAt,
                  status: trx.status,
                },
              ],
              totalAmount: trx.amount,
              name:
                activeIndex === 1
                  ? (
                      collaborators.find(
                        p =>
                          p.collaboratorId &&
                          p.collaboratorId.id === trx.collaborator,
                      ) || {}
                    ).name || trx.meta.accountName
                  : (
                      allCollaborators.find(p => p.id === trx.collaborator) ||
                      {}
                    ).name || trx.meta.accountName,
              splitData: collaborators.find(
                p =>
                  p.collaboratorId && p.collaboratorId.id === trx.collaborator,
              ),
            });
          }
        }
      });

      setModifiedTransactions(uniqueCollaborators);
    }
  }, [transactions, collaborators, allCollaborators, activeIndex]);

  const [selectedTransaction, setSelectedTransaction] = useState<any>([]);

  // Get Transactions Information
  const getTransactionsCount = (collaboratorId, propTransaction) => {
    let count: number = 0;
    propTransaction.forEach(trx => {
      if (trx.collaborator === collaboratorId) {
        count++;
      }
    });
    return count;
  };

  useEffect(() => {
    let mockData: Array<object> = [];
    if (allCollaborators && collaborators && collaborators.length > 0) {
      for (let i = 0; i < collaborators.length; i++) {
        for (let k = 0; k < allCollaborators.length; k++) {
          if (allCollaborators[k].id === collaborators[i].collaboratorId.id) {
            mockData.push({
              name: collaborators[i].collaboratorId.name,
              email: collaborators[i].collaboratorId.email,
              percentage: collaborators[i].percentage,
              amount: collaborators[i].amount,
              id: collaborators[i].collaboratorId.id,
              transactionCount: getTransactionsCount(
                collaborators[i].collaboratorId.id,
                transactions,
              ),
            });
          }
        }
      }
      setFinalCollaborators(mockData);
    }
  }, [allCollaborators, collaborators, transactions]);

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loggedCollaborator, setLoggedCollaborator] = useState<any>({});

  useEffect(() => {
    if (collaborators && collaborators.length > 0) {
      setLoggedCollaborator(
        collaborators.find(p => p.email === loggedUser.user.email),
      );
    }
  }, [collaborators, loggedUser.user.email]);

  const handleClose = () => {
    setError('');
    setSuccess(false);
    setWithdrawWarning(false);
    setWithdrawSuccess(false);
    setApprovedSuccess(false);
  };

  const handleDelete = async () => {
    setDeleteInProcess(true);
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/${mainData.id}`,
        headers,
      )
      .then(_res => {
        setSuccess(true);
        navigate('/split-payment');
        setDeleteInProcess(false);
      })
      .catch(err => {
        setError(err.response.data.message);
        setDeleteInProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const afterSuccess = () => {
    getTransactions();
    getSplit();
    getIncome();
  };

  // Handle Actions
  const [showActionPop, setShowActionPop] = useState(false);
  const [withdrawWarning, setWithdrawWarning] = useState(false);
  const [onProcess, setOnProcess] = useState(false);
  const [withdrawSuccess, setWithdrawSuccess] = useState(false);
  const [openApprovalModal, setOpenApprovalModal] = useState(false);

  const handleWithdrawal = () => {
    if (balanceInfo.balance > 0) {
      setWithdrawWarning(true);
    } else {
      setError('There is nothing to withdraw!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const payload = {
    amount: balanceInfo.balance - 100,
    bankId: 'AAAAAAAA-AAAA-AAAA-AAAA-AAAAAAAAAAAA',
    accountNumber: userBankAccount.accountInfo.accountNumber,
    idempotentKey: uuid(),
    purpose: 'withdraw split to account',
  };

  const withdrawBalAction = async () => {
    setOnProcess(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/${mainData.id}/withdrawal`,
        payload,
        headers,
      )
      .then(_res => {
        setWithdrawSuccess(true);
        setOnProcess(false);
      })
      .catch(err => {
        setError(err.response.data.message);
        setOnProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  // Display single item transaction from split payout
  const openSplitTransact = (_item, index) => {
    setOpenDetails(true);
    setOpenTransactionDetails(false);
    setSelected({
      accountName: selectedTransaction.name,
      amount: selectedTransaction.trxRecords[index].amount,
      meta: {
        accountName: selectedTransaction.name,
        fee: 0,
        bankName:
          selectedTransaction.splitData.collaboratorId.accountInfo.bankName,
        accountNumber:
          selectedTransaction.splitData.collaboratorId.accountInfo
            .accountNumber,
      },
      type: 'debit',
      updatedAt: selectedTransaction.trxRecords[index].updatedAt,
      purpose: 'Split payout',
    });
  };

  const handleToggleActive = id => {
    setToggleActive(true);
    setToggleId(id);
  };

  const toggleDetailsModal = item => {
    setOpenDetails(!openDetails);
    setSelected(item);
  };

  const handlePdf = item => {
    setSelected(item);
  };

  return (
    <>
      {withdrawWarning ? (
        <WithdrawWarning
          closeFunc={() => setWithdrawWarning(false)}
          handleAction={withdrawBalAction}
          balance={balanceInfo.balance}
          actionText={'Withdraw'}
          actionInProcess={onProcess}
        />
      ) : null}
      {openApprovalModal ? (
        <ApproveModal
          closeFunc={() => setOpenApprovalModal(false)}
          collaborators={finalCollaborators}
          balance={balanceInfo.balance}
          splitName={mainData.name}
          mainData={mainData}
          afterSuccess={afterSuccess}
          setSuccess={setApprovedSuccess}
          transactions={modifiedtransactions}
          currency={currency}
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={handleClose}
          img="/failed.png"
          message="An error occurred"
          miniMessage={error}
        />
      ) : null}

      {approvedSuccess ? (
        <Alert
          closeFunc={() => {
            handleClose();
            getTransactions();
          }}
          img="/success.png"
          message="Approval successful!"
          miniMessage={'Funds are on its way to recipients'}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={handleClose}
          img="/success.png"
          message="Split deleted successfully"
          miniMessage={'You have successfully deleted your split.'}
        />
      ) : null}

      {withdrawSuccess ? (
        <Alert
          closeFunc={handleClose}
          img="/success.png"
          message="Withdraw success"
          miniMessage={`Your Mage Account has been credited with ${CommaNumber(
            balanceInfo.balance,
          )}`}
        />
      ) : null}

      {openDetails ? (
        <Details props={selected} closeFunc={() => setOpenDetails(false)} />
      ) : null}

      {openTransactionDetails ? (
        <TransactionDetails
          props={selectedTransaction}
          closeFunc={() => {
            setOpenTransactionDetails(false);
            setSelectedTransaction([]);
          }}
          openFunc={openSplitTransact}
        />
      ) : null}

      {openEdit ? (
        <EditSplit
          closeFunc={() => setOpenEdit(false)}
          id={mainData.id}
          name={mainData.name}
          startDate={mainData.startDate}
          endDate={mainData.endDate}
          type={mainData.splitType}
          frequency={mainData.frequency}
          limitValue={mainData.limitValue}
        />
      ) : null}

      {openDelete ? (
        <DeleteWarning
          handleAction={() => handleDelete()}
          deleteInProcess={deleteInProcess}
          closeFunc={() => setOpenDelete(false)}
          message={'Are you sure you want to delete this split?'}
          actionText={'Delete Split'}
          splitName={mainData.name}
        />
      ) : null}

      <MainWrapper
        className="no-scrollbar"
        padding="1px"
        style={{ width: '100%' }}
        left="0"
        top="0rem"
        onClick={() => {
          setShowActionPop(false);
        }}
      >
        <FragmentHeader
          showBack={true}
          pageName={mainData.name}
          backLink="/split-payment"
          showHeaderCard={true}
          status={catergories}
        />
        <SearchPos position="fixed">
          {activeIndex === 0 ? (
            <ButtonWrap mobileHide={false} style={{ gap: '10px' }}>
              {catergories === 'music' && (
                <>
                  <DistributeLink
                    style={{
                      width: '8rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate('/split/add-data')}
                  >
                    <Icon.Database color="#6E5FBB" />
                    <p
                      style={{
                        color: '#6E5FBB',
                        width: 'auto',
                        padding: '13px 5px',
                      }}
                    >
                      Add Data
                    </p>
                  </DistributeLink>
                </>
              )}
              <CustomButtonDark
                onClick={e => {
                  setShowActionPop(!showActionPop);
                  e.stopPropagation();
                }}
              >
                <ButtonFlex>
                  Action
                  <Icon.ChevronDown
                    size={16}
                    style={{
                      position: 'relative',
                      left: '0.5rem',
                    }}
                  />
                </ButtonFlex>
              </CustomButtonDark>
            </ButtonWrap>
          ) : null}
          {showActionPop ? (
            <HeaderPopUp>
              {mainData.splitType === 'approval' ? (
                <p onClick={() => setOpenApprovalModal(true)}>Approve Split</p>
              ) : null}
              <p
                onClick={() => {
                  setShowFundSplit(true);
                }}
              >
                Fund Split
              </p>
              {mainData.category ? (
                <p onClick={() => handleWithdrawal()}>Withdraw Balance</p>
              ) : null}
              <p
                onClick={() => {
                  navigate('/edit-collaborators');
                  localStorage.setItem('split', JSON.stringify(mainData));
                }}
              >
                Edit Split
              </p>
              <p onClick={() => setOpenDelete(true)}>Delete Split</p>
            </HeaderPopUp>
          ) : null}
        </SearchPos>
        <BreadcrumbArea
          style={{
            marginTop: '0.5rem',
          }}
        >
          {/* <PreferenceBlock> */}
          <SingleFlex
            style={{
              height: '90.1vh',
              marginTop: '5.6rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <div
                style={{
                  width: '100%',
                  borderBottom: '1px solid #ebe6e6',
                  padding: '10px',
                  paddingBottom: '2px',
                }}
              >
                <FlexedApart>
                  <div
                    style={{
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        color: '#828282',
                      }}
                    >
                      Wallet
                    </p>
                  </div>
                </FlexedApart>
                <div style={{ width: '100%' }}>
                  <h3
                    style={{
                      marginTop: '10px',
                      fontSize: '28px',
                      fontWeight: '700',
                      margin: '0 0 1rem 0',
                    }}
                  >
                    {getSign(currency)}
                    {CommaNumber(balanceInfo.balance)}
                  </h3>
                </div>
              </div>

              <div
                style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <SplitImage
                  src={
                    mainData.avatar
                      ? mainData.avatar
                      : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                  }
                  alt="Display"
                />
                <br />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Header
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    {mainData.name}
                  </Header>
                  <p style={{ marginTop: '-30px' }}>{artistName.artistName}</p>
                  <p style={{ marginTop: '110px' }}>{}</p>
                </div>
              </div>
            </div>

            <div>
              <FlexedApart
                style={{
                  alignItems: 'center',
                }}
              >
                <p
                  style={{
                    cursor: 'pointer',
                    fontWeight: '400',
                  }}
                  onClick={() => setShowCOllaborators(false)}
                >
                  {/* View All {''} */}
                </p>
              </FlexedApart>

              {collaborators && collaborators.length > 0 ? (
                <Carousel responsive={responsive}>
                  {collaborators.map((item, index) => (
                    <CollaboratorSpace key={index}>
                      <div>{item.name ? item.name[0] : item.email[0]}</div>
                      <p>{item.name ? item.name : item.email}</p>
                      <>
                        {catergories === 'music' && (
                          <>
                            <p
                              style={{
                                padding: 0,
                              }}
                            >
                              Owns {item.percentage}%
                            </p>
                          </>
                        )}
                      </>
                    </CollaboratorSpace>
                  ))}
                </Carousel>
              ) : null}
              <MenuHeader
                style={{
                  margin: '3.5rem 0 0 0',
                }}
              >
                {filterArray.map((item, index) => (
                  <MenuHeaderText
                    key={index}
                    onClick={() => setActiveHeader(index)}
                    color={activeHeader === index ? '#161616' : '#b0b0b0'}
                    border={
                      activeHeader === index ? '3px solid #191919' : 'none'
                    }
                  >
                    {item.name}
                  </MenuHeaderText>
                ))}
              </MenuHeader>
              <TableWrap>
                <TableHeader
                  bg=""
                  border="none"
                  style={{ margin: '1rem 0 0 0' }}
                >
                  <MenuWrap>#</MenuWrap>
                  <MusicInfo width="30%">Payout</MusicInfo>
                  {catergories === 'music' && (
                    <>
                      <PurposeWrap>%Royalties</PurposeWrap>
                    </>
                  )}
                  <DateWrap>Date</DateWrap>
                  <StatusWrap>Status</StatusWrap>
                  <DateWrap>Amount</DateWrap>
                  <MenuWrap></MenuWrap>
                </TableHeader>

                <ScrollDiv>
                  {activeHeader === 0 ? (
                    <>
                      {payments && payments.length > 0 ? (
                        <>
                          {payments.map((item, index) => (
                            <>
                              <TransactionCard
                                item={item}
                                key={index.id}
                                index={index}
                                name={item.meta.payerName}
                                image={item.albumArt}
                                artist={item.artistName}
                                status={item.meta.transactionType}
                                // handleSelect={handleSelect}
                                createdAt={item.createdAt}
                                updatedAt={item.updatedAt}
                                amount={item.amount}
                                // openReport={toggleReportModal}
                                // openUpdate={toggleUpdateModal}
                                openDetails={toggleDetailsModal}
                                // openReminder={toggleReminder}
                                // options={options}
                                handlePdf={handlePdf}
                                // propRefs={ref}
                                toggleId={toggleId}
                                // setToggleActive={setToggleActive}
                                setToggleId={setToggleId}
                                toggleActive={toggleActive}
                                toggleMenu={handleToggleActive}
                                catergories={catergories}
                              />
                            </>
                          ))}
                        </>
                      ) : (
                        <EmptyState
                          img="/split-empty.png"
                          header={`No Data Available`}
                          extraText=" "
                          link=" "
                          linkMask=" "
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {filteredTransactions &&
                      filteredTransactions.length > 0 ? (
                        <>
                          {filteredTransactions.map(
                            (transaction: any, index: number) => (
                              <>
                                <TableHeader
                                  key={transaction.id}
                                  bg="#fff"
                                  border=""
                                  onClick={() => {
                                    toggleDetailsModal(transaction);
                                  }}
                                >
                                  <MenuWrap>{index + 1}</MenuWrap>
                                  <MusicInfo width="30%">
                                    {transaction.meta.accountName}
                                  </MusicInfo>
                                  {catergories === 'music' && (
                                    <>
                                      <PurposeWrap>
                                        {transaction.splitData &&
                                        transaction.splitData.percentage != null
                                          ? `${transaction.splitData.percentage}%`
                                          : '100%'}
                                      </PurposeWrap>
                                    </>
                                  )}

                                  <DateWrap>
                                    {moment(transaction.createdAt).format('LL')}
                                  </DateWrap>
                                  <StatusWrap>
                                    <span
                                      style={{
                                        padding: '5px',
                                        border:
                                          transaction.status === 'Pending'
                                            ? '1px solid ##45a1bd'
                                            : '1px solid green',

                                        borderRadius: '5px',
                                        backgroundColor:
                                          transaction.status === 'Pending'
                                            ? '#6ac2dd68'
                                            : 'rgba(204, 234, 185, 0.30)',

                                        color:
                                          transaction.status === 'Pending'
                                            ? '#4fadc9'
                                            : 'green',
                                      }}
                                    >
                                      {transaction.status}
                                    </span>
                                  </StatusWrap>
                                  <DateWrap>
                                    {getSign(transaction.currency)}
                                    {CommaNumber(transaction.amount)}
                                  </DateWrap>
                                  <MenuWrap></MenuWrap>
                                </TableHeader>
                              </>
                            ),
                          )}
                        </>
                      ) : (
                        <EmptyState
                          img="/split-empty.png"
                          header="No Payout Data Available"
                          extraText=" "
                          link=" "
                          linkMask=" "
                        />
                      )}
                    </>
                  )}
                </ScrollDiv>
              </TableWrap>
            </div>
          </SingleFlex>

          {/* </PreferenceBlock> */}
        </BreadcrumbArea>
      </MainWrapper>

      {showFundSplit ? (
        <FundSplitAmount
          closeFunc={() => setShowFundSplit(false)}
          splitId={id}
          accountInfo={accountInfo}
          splitName={mainData.name}
          loadAction={getSplit}
          currency={currency}
          bankName={
            mainData.accountInfo ? mainData.accountInfo.bankName || '' : ''
          }
          accountName={
            mainData.accountInfo ? mainData.accountInfo.accountName || '' : ''
          }
          accountNo={
            mainData.accountInfo ? mainData.accountInfo.accountNumber || '' : ''
          }
          showAccount={mainData.accountInfo ? mainData.accountInfo || '' : ''}
        />
      ) : null}
      {showCollaborators ? (
        <AllCollaborators
          closeFunc={() => {
            setShowCOllaborators(false);
          }}
          collaborators={collaborators}
        />
      ) : null}
    </>
  );
};

export default MainContent;

const filterArray = [
  {
    name: 'Money In',
  },
  {
    name: 'Money Out',
  },
];

interface PropsArgs {
  item: any;
  key: number;
  index?: number;
  updatedAt?: any;
  createdAt?: string;
  artist?: string;
  // activeSongIndex?: number | string;
  image?: string;
  name?: string;
  status?: any;
  // handleShare?: any;
  // handleRelease?: any;
  id?: any;
  invoiceNo?: any;
  amount?: any;
  // openReport?: any;
  // openReminder?: any;
  // openUpdate?: any;
  openDetails?: any;
  // openDelete?: any;
  handlePdf?: any;
  // propRefs?: any;
  // options?: any;
  // handleSelect?: any;
  toggleActive: any;
  toggleId: any;
  setToggleId: any;
  // setToggleActive: any;
  toggleMenu: any;
  catergories: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MobileTransCard = ({
  item,
  key,
  index,
  id,
  updatedAt,
  image,
  name,
  // handleRelease,
  createdAt,
  // activeSongIndex,
  openDetails,
  handlePdf,
  // handleSelect,
  // handleShare,
  toggleActive,
  toggleId,
  setToggleId,
  // setToggleActive,
  toggleMenu,
}: // openDelete,
PropsArgs) => {
  return (
    <>
      <HistoryItemCard
        key={key}
        onClick={() => {
          openDetails(item);
        }}
      >
        <HistoryItemIconWrap
          customBg="rgba(200, 43, 50, 0.08)"
          style={{
            color: '#71191D',
          }}
        >
          {name && name[0]}
        </HistoryItemIconWrap>
        <HistoryTextWrap>
          <h4>{name ? titleCase(name) : 'No name attached'}</h4>
          <p>{moment(updatedAt).format('LL')}</p>
        </HistoryTextWrap>
        <HistoryInfoSec>
          <HistoryAmount>{}</HistoryAmount>
          <p></p>
        </HistoryInfoSec>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="2rem"
            onClick={e => {
              setToggleId(false);
              e.stopPropagation();
            }}
          >
            <p
              style={{
                color: '#c82b32',
              }}
              // onClick={() => openDelete(item)}
            >
              Download receipt
            </p>
            <p onClick={() => openDetails(item)}>More details</p>
          </MenuContent>
        ) : null}
      </HistoryItemCard>
    </>
  );
};

const TransactionCard = ({
  item,
  key,
  index,
  id,
  updatedAt,
  image,
  name,

  createdAt,
  // activeSongIndex,
  // handleShare,
  // handleRelease,

  artist,
  status,
  amount,
  invoiceNo,
  // openUpdate,
  // openDelete,
  handlePdf,
  // propRefs,
  // options,
  // handleSelect,
  openDetails,
  toggleActive,
  toggleId,
  setToggleId,
  toggleMenu,
  catergories,
}: PropsArgs) => {
  return (
    <>
      <TableHeader
        bg="#fff"
        border=""
        key={item.id}
        onClick={() => {
          openDetails(item);
        }}
      >
        <MenuWrap>{Number(index) + 1}</MenuWrap>
        <MusicInfo width="30%">
          <div>
            <h3>{name}</h3>
          </div>
        </MusicInfo>
        {catergories === 'music' && (
          <>
            <PurposeWrap>100%</PurposeWrap>
          </>
        )}

        <DateWrap>{moment(createdAt).format('LL')}</DateWrap>
        <StatusWrap>
          <span
            style={{
              padding: '5px',
              border: '1px solid green',
              borderRadius: '5px',
              backgroundColor: 'rgba(204, 234, 185, 0.30)',
              color: 'green',
            }}
          >
            {status}
          </span>
        </StatusWrap>
        <DateWrap>
          {getSign(item.currency)}
          {CommaNumber(amount)}
        </DateWrap>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="2rem"
            onClick={e => {
              setToggleId(false);
              e.stopPropagation();
            }}
            style={{
              color: '#161616',
            }}
          >
            <p onClick={() => openDetails(item)}>More details</p>
          </MenuContent>
        ) : null}
      </TableHeader>
    </>
  );
};
