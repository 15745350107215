// import axios from 'axios';
import { useState } from 'react';
import * as Icon from 'react-feather';
import { PageLink, CopyFlex, CopyIconsWrap } from './styles';
import { SearchPos } from 'app/components/transactions/style';
import { InAppHeader } from '../styles';
import { Link } from 'react-router-dom';

const SectionOne = () => {
  const store = JSON.parse(localStorage.getItem('store') || '{}');
  var loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [copied, setCopied] = useState(false);
  function handleCopy() {
    navigator.clipboard.writeText(
      store === undefined
        ? ''
        : `${store.slug}${process.env.REACT_APP_PAGE_LINK}/store`,
    );
    setCopied(!copied);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  return (
    <>
      {/* <FlexedArea> */}
      <InAppHeader
        style={{
          display: 'flex',
        }}
      >
        <h3>Webstore</h3>
        <span
          style={{
            color: '#E1E1E1',
          }}
        >
          |
        </span>
        <Link to="/update-store">
          <p
            style={{
              fontSize: '12px',
              color: '#c82b38',
            }}
          >
            Edit store
          </p>
        </Link>
      </InAppHeader>
      <SearchPos>
        {store !== undefined ? (
          <>
            <CopyFlex>
              <PageLink>
                {store.slug + process.env.REACT_APP_PAGE_LINK + '/store'}
              </PageLink>
              <CopyIconsWrap>
                <Icon.Copy onClick={() => handleCopy()} size={15} />
              </CopyIconsWrap>
              <CopyIconsWrap>
                <a
                  href={
                    'https://' +
                    store.slug +
                    process.env.REACT_APP_PAGE_LINK +
                    '/store?token=' +
                    loggedUser.tokens.access.token
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon.ArrowRight size={15} />
                </a>
              </CopyIconsWrap>
              {copied ? (
                <p
                  style={{
                    width: '20rem',
                    fontSize: '0.8rem',
                    textAlign: 'center',
                    position: 'fixed',
                    top: '2rem',
                    left: '50%',
                    color: 'white',
                    background: '#121212',
                    transform: 'translate(-50%, 0)',
                    boxShadow: '0 0 4px #cbced1',
                    padding: '0.8rem 0',
                    zIndex: '1000',
                    borderRadius: '8px',
                  }}
                >
                  <i
                    style={{ margin: '0 5% 0 0' }}
                    className="fi fi-rr-check"
                  ></i>
                  Copied to clipboard
                </p>
              ) : null}
            </CopyFlex>
          </>
        ) : null}
      </SearchPos>
      {/* </FlexedArea> */}
    </>
  );
};

export default SectionOne;
