import AddMerchComp from 'app/components/merch/addMerch/addMerchComp';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const AddMerch = () => {
  return (
    <>
      <MainWrapper>
        <AddMerchComp />
      </MainWrapper>
    </>
  );
};

export default AddMerch;
