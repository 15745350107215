import styled from 'styled-components';

interface LeftProps {
  margin?: string;
  mobileDir?: string;
}

export const HeaderFlex = styled.div<LeftProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 728px) {
    margin: ${p => p.margin || '0rem 0 0 0'};
    flex-direction: ${p => p.mobileDir};
  }
`;

export const LeftWrap = styled.div<LeftProps>`
  width: 60%;
  display: flex;
  align-items: center;

  @media (max-width: 728px) {
    width: 100%;
    gap: 10px;
    justify-content: center;
    margin: ${p => p.margin || '1.6rem 0 0 0'};
  }
`;

export const RightWrap = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 728px) {
    width: 100%;
    margin: 1.6rem 0 0 0;
    justify-content: center;
  }
`;

// Table Section

export const TableWrap = styled.div`
  margin: 0rem 0;

  @media (max-width: 728px) {
    width: 100%;
    overflow-x: auto;
  }
`;

interface PropsArgs {
  border: string;
  bg: string;
}

export const TableHeader = styled.div<PropsArgs>`
  background: ${p => p.bg || 'rgba(230, 233, 239, 0.2)'};
  border-bottom: ${p => p.border || '1px solid #f2f2f2'};
  display: flex;
  align-items: center;
  padding: 2%;
  gap: 20px;
  font-size: 0.8rem;
  position: relative;
  cursor: pointer;
  text-transform: capitalize;

  :hover {
    opacity: 0.95;
  }

  @media (max-width: 728px) {
    width: 50rem;
    padding: 1rem 2%;
    display: none;
  }
`;

export const MobileHistory = styled.div`
  display: none;

  @media (max-width: 728px) {
    display: block;
    margin: 2rem 0 0 0;
  }
`;

export const IconWrap = styled.div`
  width: 5%;
`;

export const NameWrap = styled.div`
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;

export const PurposeWrap = styled.div`
  width: 25%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const DateWrap = styled.div`
  width: 25%;
`;

export const StatusWrap = styled.div`
  width: 15%;

  > div {
    width: 80%;
    padding: 0.4rem 2%;
    border-radius: 10px;
    letter-spacing: -0.02em;
    text-align: Center;
    font-weight: 700;
    font-size: 10px;
  }

  @media (max-width: 728px) {
    margin: 0rem 0 0 0;
    width: 100%;
  }
`;

export const AmountWrap = styled.div`
  width: 20%;
`;

export const MenuWrap = styled.div`
  width: 2%;

  i {
    cursor: pointer;
  }
`;

interface PropsArgs3 {
  right?: any;
}

export const MenuContent = styled.div<PropsArgs3>`
  width: 10rem;
  background: #fff;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  right: ${p => p.right || '3rem'};
  border: 0.2px solid #fbfbfb;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  z-index: 100;

  p {
    margin: 0.5rem 0;
    font-size: 0.8rem;
    cursor: pointer;

    :hover {
      opacity: 0.5;
    }
  }

  @media (max-width: 728px) {
    right: 3rem;
    width: 12rem;
    bottom: auto;
    top: -1rem;
  }
`;

export const FilterSelect = styled.select`
  padding: 0.6rem 0.9rem;
  margin: 0 1rem 0 0;
  width: 8rem;
  color: #757575;
  font-size: 0.8rem;
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 5px;

  @media (max-width: 728px) {
    width: 6.5rem;
    margin: 0;
  }
`;

export const FilterHeader = styled.p`
  font-size: 0.8rem;
  color: #757575;
  padding: 0.1rem 0 0.1rem 0rem;

  @media (max-width: 728px) {
    margin: 0rem 0 0 0;
  }
`;

export const FilterInput = styled.input`
  border-radius: 4px;
  padding: 0.6rem 0.9rem;
  margin: 0 1rem 0 0;
  width: 8rem;
  color: #757575;
  font-size: 0.8rem;
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 5px;

  @media (max-width: 728px) {
    width: 6.5rem;
    margin: 0;
  }
`;

interface FilterProps {
  bg: string;
  color?: string;
}

export const FilterActionBtn = styled.button<FilterProps>`
  padding: 0.6rem 1rem;
  width: auto;
  background: ${p => p.bg || 'transparent'};
  border-radius: 4px;
  color: ${p => p.color || '#757575'};
  font-size: 0.8rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  gap: 6px;

  @media (max-width: 728px) {
    font-size: 0.55rem;
  }
`;

interface SearchProps {
  hideMobile?: boolean;
  position?: string;
  mobileTop?: string;
}

export const SplitTypeBorder = styled.div`
  width: 100px;
  height: auto;
`;

export const SplitType = styled.div<SearchProps>`
  width: ${p => (p.position ? '10%' : '100%')};
  position: ${p => p.position || 'relative'};
  top: ${p => (p.position ? '1.5rem' : '2rem')};
  left: ${p => (p.position ? '15rem' : 0)};
  display: flex;
  justify-content: flex-start;
  align-items: Center;
  z-index: ${p => (p.position ? '10000' : '1')};

  @media (max-width: 728px) {
    width: 95%;
    margin: 0 auto;
    top: ${p => p.mobileTop || '3.5rem'};
    right: 0;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
    flex-direction: column;
    gap: 15px;
    z-index: 1;
    display: ${p => (p.hideMobile ? 'none' : 'flex')};
  }
`;

export const SearchPos = styled.div<SearchProps>`
  width: ${p => (p.position ? '50%' : '100%')};
  position: ${p => p.position || 'relative'};
  top: ${p => (p.position ? '1rem' : '2rem')};
  right: ${p => (p.position ? '1rem' : 0)};
  display: flex;
  justify-content: flex-end;
  align-items: Center;
  z-index: ${p => (p.position ? '10000' : '1')};

  @media (max-width: 728px) {
    width: 95%;
    margin: 0 auto;
    top: ${p => p.mobileTop || '3.5rem'};
    right: 0;
    left: 50%;
    position: relative;
    transform: translate(-50%, 0);
    flex-direction: column;
    gap: 15px;
    z-index: 1;
    display: ${p => (p.hideMobile ? 'none' : 'flex')};
  }
`;

// Report Transaction

export const ReportModalWidget = styled.div`
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  @media (max-width: 728px) {
    align-items: flex-start;
  }
`;

export const ReportModalChild = styled.div`
  width: 24rem;
  background: #fff;
  border-radius: 8px;
  padding: 1rem 2rem;
  position: relative;

  @media (max-width: 728px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const CloseIcon = styled.i`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const HeaderWrap = styled.div`
  text-align: Center;
  padding: 2rem 2% 0 2%;

  > h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
    color: #121212;
  }

  > p {
    font-size: 0.8rem;
    font-weight: 300;
  }
`;

interface PropsArgs2 {
  borderTp?: string;
  margin?: string;
  padding?: string;
}

export const ModalMain = styled.div<PropsArgs2>`
  margin: ${p => p.margin || '1rem 0'};
  padding: ${p => p.padding || '1.5rem 0 0 0'};
  border-top: ${p => p.borderTp || '0.5px solid rgba(0, 0, 0, 0.1)'};
`;

export const InputWrap = styled.div`
  width: 20rem;
  margin: 1.5rem 0 0 0;
  position: relative;

  > h4 {
    position: absolute;
    top: 2.3rem;
    left: 5%;

    @media (max-width: 728px) {
      top: 2.3rem;
    }
  }

  @media (max-width: 1028px) {
    width: 100%;
  }

  > label {
    font-size: 13px;
    font-weight: 500;
    color: #474747;
  }

  > input {
    width: 100%;
    background: #ffffff;
    border: 0.1px solid #dadada;
    border-radius: 5px;
    padding: 0.5rem 5%;
    font-size: 14px;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;

    @media (max-width: 1028px) {
      padding: 0.6rem 5%;
      font-size: 16px;
    }
  }

  > select {
    width: 100%;
    border-radius: 4px;
    padding: 0.5rem 5%;
    font-size: 0.9rem;
    font-weight: 300;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    // border: 1px solid #c5c7d0;
    box-shadow: 0 0 3px #cbced1;

    > option {
      color: red;
      margin: 1rem 0;
      padding: 4rem 0;
    }

    @media (max-width: 1028px) {
      padding: 0.6rem 5%;
    }
  }

  > textarea {
    background: #ffffff;
    border: 0.1px solid #dadada;
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 0.5rem;
    font-size: 14px;
    resize: none;
    height: 6rem;

    @media (max-width: 728px) {
      font-size: 16px;
    }
  }
`;

export const ReportActionBtn = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0 0;
`;

interface BtnProps {
  bg: string;
  border: string;
  cl: string;
}
export const ReportBtn = styled.button<BtnProps>`
  width: 48%;
  background: ${p => p.bg || 'transparent'};
  border: ${p => p.border || '0.5px solid #c82b38'};
  color: ${p => p.cl || '#c82b38'};
  padding: 0.3rem 2%;
  border-radius: 4px;
  text-align: center;
`;

// Details Section
export const DetailsModal = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1000000000;
`;

export const DetailsChild = styled.div`
  padding: 2% 2% 3rem 2%;
  width: 25rem;
  height: 100%;
  background: #fff;
  overflow-y: auto;
  position: relative;

  @media (max-width: 728px) {
    width: 100%;
    padding: 1rem;
  }
`;

export const FixedBottom = styled.div`
  position: fixed;
  bottom: 0;
  height: 4rem;
  background: #fff;
  display: flex;
  align-items: Center;
  justify-content: Center;
  gap: 20px;
  width: 25%;

  > button {
    border: none;
  }

  @media (max-width: 728px) {
    width: 92%;
  }
`;

export const MainPart = styled.div`
  margin: 3rem 0;
`;

export const UserInfo = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-weight: bolder;
    font-size: 0.9rem;
    margin: 1rem 0 0 0;
  }

  p {
    font-size: 0.8rem;
    color: #474747;
  }

  @media (max-width: 728px) {
  }
`;

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 10rem;
  background: rgba(200, 43, 50, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(113, 25, 29, 1);
  font-weight: bold;
`;
export const MainSection = styled.div`
  margin: 2rem 0;

  h3 {
    font-size: 0.8rem;
    margin: 0 0 1rem 0;
  }
`;

// Receipt Style
export const LogoImage = styled.img`
  height: 2rem;
`;

export const DateInfo = styled.div`
  margin: 1rem 0;
  padding: 1rem 0 0.3rem 0;

  h3 {
    font-weight: 300;
  }
`;

export const MiniSection = styled.div`
  margin: 1.5rem 0;
`;

export const Extra = styled.div`
  margin: 4rem 0 0 0;
`;

export const Center = styled.div`
  text-align: center;
  margin: 1rem 0;
  font-weight: 400;
`;

export const Line = styled.hr`
  border-top: 0.5px solid #f2f2f2;
`;

export const ViewInfoWrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  padding: 1rem 5%;
  background: #ffffff;
  border: 1px solid rgba(117, 117, 117, 0.1);
  border-radius: 20px;

  > div > ul > li {
    border-right: 1px dashed #dcdcdc;
  }

  > div > ul > li:nth-child(3) {
    border-right: none;
  }

  @media (max-width: 728px) {
    grid-template-columns: repeat(3, 1fr);
    margin: 5rem 0 0 0;
    padding: 0 5%;
    overflow: auto;
    grid-gap: 40px;
  }
`;

export const ViewInfoChild = styled.div`
  padding: 1rem 1rem 1rem 2rem;
  border-right: 1px dashed #dcdcdc;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 728px) {
    padding: 1rem 0rem 1rem 2rem;
    margin: 0 auto;
  }

  > div {
    display: flex;
    align-items: center;
  }

  :nth-last-child(1) {
    border-right: none;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #7d7d7d;
    margin: 0 1rem 0 0;
  }

  > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.02em;
    color: #191919;
  }
`;

interface percentProps {
  bg?: any;
  color?: any;
}

export const PercentWrap = styled.div<percentProps>`
  background: ${p => p.bg || '#f4f6f6'};
  color: ${p => p.color || '#000000'};
  padding: 0.3rem 0.7rem;
  border-radius: 50px;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const InputTag = styled.div`
  display: inline-block;
  width: auto;
  padding: 2px 5px;
  background: #f1e9fd;
  border: 0.5px solid #6e5fbb;
  border-radius: 8px;
  color: #9747ff;
  font-size: 10px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 13px;
`;

export const SwapInfoCurrency = styled.div`
  position:relative;

  > section {
    // position:absolute;
    width:5rem
    height:15rem;
    border-left:5px solid red;
  }

  > div {
    display:flex;
    align-items:Center;
    gap:5px;
    margin:0.5rem 0;

    hr {
      transform: rotate(90deg);
      position:absolute;
      top:0;
      left:12px;
    }

    > div {
      width:24px;
      height:24px;
      display:flex;
      align-items:Center;
      justify-content:Center;
      background: #F0F0F0;
      border: 0.5px solid #ABABAB;
      border-radius:10rem;
    }

    > p {
      font-size: 11px;
      color: #898A8D;
      padding: 0.3rem;
      borderRadius: 50px;
      display: inline-block;
    }
  }
`;
