import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';
import ProductListComp from '../../components/merch/productList/productListComp';

const ProductList = () => {
  return (
    <>
      <MainWrapper>
        <SideNav mobileShow="none" />
        <ProductListComp />
      </MainWrapper>
    </>
  );
};

export default ProductList;
