import { useState, useEffect } from 'react';
import {
  Constraint,
  // StoreAvatar,
} from './../invoice/mainInvoice/style';
import { MainWrapper } from '../storeComp/mainContent/styles';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { GenericAppHeader } from './style';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
// import { GenericBtn } from './style';
import AddressModal from './addressModal';
import NextOfKinModal from './nextOfKin';
import axios from 'axios';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';
import { numOnly } from 'utils/numOnly';
import { nationalities } from 'app/pages/Onboarding/nationalties';
// import Select from 'react-select';
// import { checkDatefunction } from 'utils/dateVerify';
import * as Icon from 'react-feather';
// import { CustomStyles } from 'utils/selectCustomStyle';
import AppHeader from '../storeComp/mainContent/header/header';
import { useNavigate } from 'react-router';
import { InputWrap } from '../dashboard/mainContent/fundWallet/style';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import RESERVED_NAMES from 'reservedNames';
import IsUsernameAvailable from 'utils/apis/IsUsernameAvailable';
import {
  UsernameValidationWrap,
  UVUserNameAvailability,
  UVUserName,
} from 'app/pages/Onboarding';
import constraints from 'utils/constraints';

const NewMain = () => {
  const history = useNavigate();
  const [openAddress, setOpenAddress] = useState<any>(false);
  const [openNextOfKin, setOpenNextOfKin] = useState<any>(false);
  const [bvnKycStatus, setBvnKycStatus] = useState<any>({});

  function toggleAddress() {
    setOpenAddress(!openAddress);
  }
  function toggleNextOfKin() {
    setOpenNextOfKin(!openNextOfKin);
  }
  const getStatus = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/users/kyc-status`, {
        headers: { Authorization },
      })
      .then(res => {
        setBvnKycStatus(res.data.bvn);
      })
      .catch(err => {});
  };

  useEffect(() => {
    getStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myStore = JSON.parse(localStorage.getItem('store') || '{}');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [firstName, setFirstName] = useState<any>('');
  const [lastName, setLastName] = useState<any>('');
  const [username, setUsername] = useState<any>('');
  const [usernameSlug, setUsernameSlug] = useState<string>('');
  const [phoneNo, setPhoneNo] = useState<any>('');
  const [countryCode, setCountryCode] = useState<any>('');
  const [zipCode, setZipCode] = useState('');

  const [gender, setGender] = useState<any>('');

  // next of kin section

  const [relationship, setRelationship] = useState('');
  const [nFirstName, setNFirstName] = useState('');
  const [nLastName, setNLastName] = useState('');
  const [email, setEmail] = useState('');
  const [nPhoneNo, setNPhoneNo] = useState('');
  const [address, setAddress] = useState('');

  // address section
  const [street, setStreet] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState<any>('');
  const [country, setCountry] = useState<any>('');

  useEffect(() => {
    const newNation: any = [];
    for (let i = 0; i < nationalities.length; i++) {
      newNation.push({ value: nationalities[i] });
    }
    return newNation;
  }, []);

  const [error, setError] = useState<any>('');
  const [msg, setMsg] = useState<any>(false);
  const [onProcess, setOnProcess] = useState(false);

  const apiData = {
    firstName: firstName,
    lastName: lastName,
    phoneNumber: phoneNo,
    countryCode: countryCode,
    address: {},
    nextOfKin: {},
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('logged_user') || '{}');
    setFirstName(userData.user.firstName);
    setLastName(userData.user.lastName);
    setPhoneNo(userData.user.phoneNumber);
    setCountryCode(userData.user.countryCode);
    if ('gender' in userData.user) {
      setGender(userData.user.gender);
    }
    if ('slug' in myStore) {
      setUsername(myStore.slug);
    }
    if (userData.user.address && 'address' in userData.user) {
      setStreet(userData.user.address.street);
      setCity(userData.user.address.city);
      setState(userData.user.address.state);
      setCountry(userData.user.address.country);
      setZipCode(userData.user.address.zipCode);
    }
    if (userData.user.nextOfKin && 'nextOfKin' in userData.user) {
      setNFirstName(userData.user.nextOfKin.firstName);
      setNLastName(userData.user.nextOfKin.lastName);
      setRelationship(userData.user.nextOfKin.relationship);
      setEmail(userData.user.nextOfKin.email);
      setNPhoneNo(userData.user.nextOfKin.phoneNumber);
      setAddress(userData.user.nextOfKin.address);
    }
  }, [myStore]);

  const handleZero = e => {
    if (e.target.value.charAt(0) === '0') {
      e.target.value = e.target.value.slice(1);
    }
    if (e.target.value.length === 10) {
      e.target.value = e.target.value.slice(0, 10);
    }
  };

  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const [isAvailable, setIsAvailable] = useState(false);
  const [isValidatingUsername, setIsValidatingUsername] = useState(false);
  const checkUsernameAvailability = async e => {
    if (!e) return;
    e = e.trim();
    if (e.length < 4 || RESERVED_NAMES.includes(e.toLowerCase())) {
      setIsAvailable(false);
      setIsValidatingUsername(true);
      return;
    }
    const isAvailable = await IsUsernameAvailable(e.toLowerCase());
    setIsAvailable(isAvailable);
    setIsValidatingUsername(true);
  };

  useEffect(() => {
    if (username) {
      let slug = username
        .trim()
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('/', '')
        .replaceAll('.', '')
        .toLowerCase()
        .replace(/ /g, '-');
      if (slug[slug.length - 1] === '-') {
        let slug_ = slug.split('');
        slug_.pop();
        slug = slug_.join('');
      }
      setUsernameSlug(slug);
      checkUsernameAvailability(slug);
    }
  }, [username]);

  const handlePatch = async () => {
    setOnProcess(true);
    setTimeout(() => {
      setOnProcess(false);
    }, 15000);

    if (gender && gender !== '') {
      apiData['gender'] = gender;
    }
    if (state && state !== '' && country && country !== '') {
      apiData.address['street'] = street;
      apiData.address['city'] = city;
      apiData.address['state'] = state;
      apiData.address['country'] = country;
    }
    if (zipCode) {
      apiData.address['zipCode'] = zipCode;
    }
    if (
      email &&
      nFirstName &&
      nLastName &&
      nFirstName !== '' &&
      nLastName !== ''
    ) {
      apiData.nextOfKin['firstName'] = nFirstName;
      apiData.nextOfKin['lastName'] = nLastName;
      apiData.nextOfKin['relationship'] = relationship;
      apiData.nextOfKin['email'] = email;
      apiData.nextOfKin['phoneNumber'] = nPhoneNo;
      apiData.nextOfKin['address'] = address;
    }

    await axios
      .patch(
        process.env.REACT_APP_BASE_ENDPOINT + '/users/' + loggedUser.user.id,
        apiData,
        {
          headers: { Authorization },
        },
      )
      .then(res => {
        setOnProcess(false);
        if (res.status === 200) {
          setMsg(true);
          if (res.data) {
            loggedUser['user'] = res.data;
            localStorage.setItem('logged_user', JSON.stringify(loggedUser));
          }
          setTimeout(() => {
            setMsg(false);
          }, 4000);
        }
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });

    await axios
      .patch(
        `${process.env.REACT_APP_BASE_ENDPOINT}/creator-page/${myStore.id}`,
        { slug: username },
        {
          headers: {
            Authorization: Authorization,
          },
        },
      )
      .then(res => {
        setOnProcess(false);
        myStore['slug'] = res.data.slug;
        localStorage.setItem('store', JSON.stringify(myStore));
      })
      .catch(err => {});
  };

  const closeMessage = () => {
    setMsg(false);
  };

  const backNav = () => {
    history(-1);
  };

  return (
    <>
      <MainWrapper style={{ width: '100%' }} left="0" bg="#fff">
        <AppHeader showBack={true} pageName={`Profile`} backLink="/profile" />
        <GenericAppHeader onClick={() => backNav()}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '10px',
            }}
          >
            <Icon.ChevronLeft />
            <h3>Personal details</h3>
          </div>
        </GenericAppHeader>
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              width="30%"
              top="-4rem"
              style={{
                boxShadow: 'none',
              }}
            >
              <InputWrap>
                <label>First Name</label>
                <input
                  placeholder="First Name"
                  defaultValue={`${loggedUser.user.firstName}`}
                  onChange={e => setFirstName(e.target.value)}
                  className={
                    bvnKycStatus.status &&
                    bvnKycStatus.status.toLowerCase() === 'pending'
                      ? ''
                      : 'disabled-input'
                  }
                  disabled={
                    bvnKycStatus.status &&
                    bvnKycStatus.status.toLowerCase() === 'pending'
                      ? false
                      : true
                  }
                />
              </InputWrap>
              <InputWrap>
                <label>Last Name</label>
                <input
                  placeholder="Last Name"
                  defaultValue={`${loggedUser.user.lastName}`}
                  onChange={e => setLastName(e.target.value)}
                  className={
                    bvnKycStatus.status &&
                    bvnKycStatus.status.toLowerCase() === 'pending'
                      ? ''
                      : 'disabled-input'
                  }
                  disabled={
                    bvnKycStatus.status &&
                    bvnKycStatus.status.toLowerCase() === 'pending'
                      ? false
                      : true
                  }
                />
              </InputWrap>
              <p style={{ fontSize: '12px', color: 'rgba(0,0,0,0.4)' }}>
                Contact
                <span style={{ color: '#000000', fontWeight: '500' }}>
                  <a href="mailto:hello@trymage.com"> hello@trymage.com </a>
                </span>
                to change your name.
              </p>
              <InputWrap>
                <label>Email</label>
                <input
                  placeholder="Email Address"
                  defaultValue={loggedUser.user.email}
                  className="disabled-input"
                  disabled
                />
              </InputWrap>
              <InputWrap>
                <label>Username</label>
                <input
                  placeholder="Username"
                  defaultValue={myStore.slug}
                  className=""
                  name="username"
                  id="username"
                  value={username}
                  onKeyPress={e => {
                    if (constraints.exemptChars(numbers).includes(e.key)) {
                      e.preventDefault();
                    } else if (
                      ['-', '/', '(', ')', ' '].includes(e.key) &&
                      (`${username}${e.key}`.indexOf(`${e.key}${e.key}`) !==
                        -1 ||
                        !username)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={e => {
                    setUsername(e.target.value);
                  }}
                  required
                />
              </InputWrap>
              {isValidatingUsername &&
                username &&
                myStore.slug !== usernameSlug && (
                  <UsernameValidationWrap
                    style={{
                      margin: '1.5rem 0 0 0',
                    }}
                  >
                    <UVUserName>
                      {usernameSlug}
                      {process.env.REACT_APP_PAGE_LINK}
                    </UVUserName>
                    &nbsp;
                    <UVUserNameAvailability isAvailable={isAvailable}>
                      {isAvailable ? 'is available' : 'is not available'}
                    </UVUserNameAvailability>
                  </UsernameValidationWrap>
                )}
              <InputWrap>
                <label>Phone number</label>
                <input
                  type="text"
                  maxLength={10}
                  placeholder="Phone Number"
                  defaultValue={
                    loggedUser.user.phoneNumber
                      ? loggedUser.user.phoneNumber
                      : ''
                  }
                  onChange={e => setPhoneNo(e.target.value)}
                  onKeyPress={e => {
                    handleZero(e);
                    numOnly(e);
                  }}
                />
              </InputWrap>
              <p style={{ fontSize: '12px', color: 'rgba(0,0,0,0.4)' }}>
                if you have any issues with your information above, please send
                a message to{' '}
                <span style={{ color: '#000000', fontWeight: '500' }}>
                  <a href="mailto:hello@trymage.com">hello@trymage.com</a>
                </span>
              </p>
              <BigBtn>
                <button onClick={() => handlePatch()}>
                  {onProcess ? (
                    <BeatLoader size={15} color={'#fff'} />
                  ) : (
                    'Save and continue'
                  )}
                </button>
              </BigBtn>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>

        {/* Modals */}
        {openAddress ? (
          <AddressModal
            closeFunc={toggleAddress}
            setStreet={setStreet}
            setState={setState}
            setCity={setCity}
            state={state}
            country={country}
            setCountry={setCountry}
          />
        ) : null}
        {openNextOfKin ? (
          <NextOfKinModal
            closeFunc={toggleNextOfKin}
            setRelationship={setRelationship}
            setFirstName={setNFirstName}
            setLastName={setNLastName}
            setEmail={setEmail}
            setPhoneNo={setNPhoneNo}
            setAddress={setAddress}
          />
        ) : null}
        {msg ? (
          <Alert
            closeFunc={closeMessage}
            img="/success.png"
            message="Changes made successfully!"
            miniMessage="You have successfully updated your profile informations."
          />
        ) : null}
        {error !== '' ? (
          <Alert
            closeFunc={closeMessage}
            img="/failed.png"
            message="An error occurred!"
            miniMessage={error}
          />
        ) : null}
      </MainWrapper>
    </>
  );
};

export default NewMain;

// const genders = [{ value: 'Male' }, { value: 'Female' }];

// const mStatus = [
//   { value: 'Single' },
//   { value: 'Married' },
//   { value: 'Divorced' },
//   { value: 'Separated' },
//   { value: 'Widowed' },
// ];
