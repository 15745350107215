import React, { useState, useEffect } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from '../dashboard/mainContent/fundWallet/style';
import * as Icon from 'react-feather';
import { TicketInput } from './../paymentLink/style';
import { numOnly } from 'utils/numOnly';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { InputWrap } from './style';
import Select from 'react-select';
import { CustomStyles } from 'utils/selectCustomStyle';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';
import { NavTypeWrap, NavTypeItem } from '../../pages/SignUp/styles';
import ReactCountryFlag from 'react-country-flag';
import { InputActPlacehold, CollabSuggestWrap, SplitUsersWrap } from './style';

const AddCollaborator = ({ closeFunc, setFunc }) => {
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  // Data and States
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currency, setCurrency] = useState('NGN');
  const [currencyDisplay, setCurrencyDisplay] = useState(
    'Naira (Bank Transfer)',
  );
  const [currencyCode, setCurrencyCode] = useState('NG');
  const [username, setUsername] = useState('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [bank, setBank] = useState('');
  const [bankId, setBankId] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  // US State and Payload
  const [swiftCode, setSwiftCode] = useState('');
  const [routing, setRouting] = useState('');
  const [bankAddress, setBankAddress] = useState('');

  // GHS States and Payload

  const [banks, setBanks] = useState<any>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getBanks = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/bank-list?`, {
        headers: { Authorization: `Bearer ${loggedUser.tokens.access.token}` },
      })
      .then(res => {
        setBanks(res.data);
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
        }
      });
  };

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  // Validation of Bank Account Information

  // Account Validation
  const ValidateParams = {
    bankId,
    accountNumber: accountNumber,
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [nameProcess, setNameProcess] = useState(false);

  const handleGetAccName = e => {
    if (
      bankId &&
      accountNumber &&
      bankId.length > 0 &&
      accountNumber.length === 10
    ) {
      setAccountName('');
      setNameProcess(true);
      setTimeout(() => {
        setNameProcess(false);
      }, 15000);

      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/validate-account`,
          ValidateParams,
          headers,
        )
        .then(res => {
          setAccountName(res.data.accountName);
          setName(res.data.accountName);
          setNameProcess(false);
          setNameError(false);
        })
        .catch(err => {
          if (err.response.data.code === 400) {
            setNameProcess(false);
            setNameError(true);
            setTimeout(() => {
              setNameError(false);
            }, 6000);
          }
        });
    }
  };

  // Select Payload
  const selectPayload = () => {
    if (activeIndex === 1) {
      if (currency === 'NGN') {
        return {
          name: name,
          email: email ? email : undefined,
          accountInfo: {
            currency,
            bankName: bank,
            bankId,
            accountName,
            accountNumber,
            paymentMethod: 'transfer',
          },
        };
      } else if (currency === 'USD') {
        return {
          name: name,
          email,
          accountInfo: {
            currency,
            accountName,
            accountNumber,
            paymentMethod: 'wire',
            swiftCode: swiftCode,
            routing,
            address: bankAddress,
          },
        };
      } else if (currency === 'GHS') {
        return {
          name: name,
          email,
          accountInfo: {
            currency,
            accountName: name,
            accountNumber,
            paymentMethod: 'momo',
          },
        };
      }
    } else if (activeIndex === 0) {
      return { username: username };
    }
  };

  // Create Collaborator
  const handleCreate = async e => {
    e.preventDefault();

    try {
      if (activeIndex === 0) {
        setOnProcess(true);
        axios
          .post(
            `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators`,
            selectPayload(),
            headers,
          )
          .then(res => {
            setSuccess(true);
            setOnProcess(false);
            setFunc(res.data);
          })
          .catch(err => {
            setError(err.response.data.message);
            setOnProcess(false);
            setTimeout(() => {
              setError('');
            }, 4000);
          });
      } else if (
        currency === 'GHS' ||
        (currency === 'NGN' && bank !== '' && activeIndex === 1)
      ) {
        if (currency === 'GHS' || (currency === 'NGN' && activeIndex === 1)) {
          setOnProcess(true);
          axios
            .post(
              `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators`,
              selectPayload(),
              headers,
            )
            .then(res => {
              setSuccess(true);
              setOnProcess(false);
              setFunc(res.data);
            })
            .catch(err => {
              setError(err.response.data.message);
              setOnProcess(false);
              setTimeout(() => {
                setError('');
              }, 4000);
            });
        } else {
          setError('Account name cannot be empty');
          setOnProcess(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        }
      } else {
        setError('Please enter your bank informations');
        setOnProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    } catch {}
  };

  const closeModal = () => {
    setError('');
  };

  const closeSuccess = () => {
    setSuccess(false);
    closeFunc();
  };

  return (
    <>
      <ModalArea onClick={() => setIsInputFocus(false)}>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header
            style={{
              textAlign: 'left',
            }}
          >
            New Recipient
          </Header>
          <NavTypeWrap>
            {accountType.map((item, index) => (
              <NavTypeItem
                key={index}
                borderRadius={item.borderRadius}
                color={index === activeIndex ? '#191919' : ''}
                background={index === activeIndex ? '#fff' : ''}
                onClick={e => setActiveIndex(index)}
                style={{
                  fontSize: '12px',
                }}
              >
                {item.name}
              </NavTypeItem>
            ))}
          </NavTypeWrap>
          <form onSubmit={e => handleCreate(e)}>
            <div>
              {activeIndex === 0 ? (
                <TicketInput hasLabel={true}>
                  <div>
                    <p>Username</p>
                    <label>@</label>
                    <input
                      minLength={3}
                      required={true}
                      onChange={e => setUsername(e.target.value)}
                    />
                  </div>
                </TicketInput>
              ) : null}

              {activeIndex === 1 ? (
                <>
                  <TicketInput>
                    <div
                      style={{
                        width: '100%',
                        position: 'relative',
                        top: '1rem',
                      }}
                    >
                      <p>Currency</p>
                      <InputActPlacehold
                        onClick={e => {
                          setIsInputFocus(!isInputFocus);
                          e.stopPropagation();
                        }}
                        onBlur={() => setIsInputFocus(false)}
                      >
                        <div>
                          <ReactCountryFlag
                            style={{
                              fontSize: '1.3rem',
                              borderRadius: '50%',
                            }}
                            countryCode={currencyCode}
                            svg
                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                            cdnSuffix="svg"
                          />
                          <p
                            style={{
                              color: '#191919',
                            }}
                          >
                            {currencyDisplay}
                          </p>
                        </div>
                        <i>
                          <Icon.ChevronDown size={15} strokeWidth={3} />
                        </i>
                      </InputActPlacehold>
                    </div>
                  </TicketInput>
                  <CollabSuggestWrap
                    style={{
                      margin: '0',
                      width: '100%',
                      zIndex: '100000000',
                      top: '-1rem',
                    }}
                  >
                    {isInputFocus ? (
                      <div>
                        {Countries && Countries.length > 0 ? (
                          Countries.map((item, index) => (
                            <SplitUsersWrap
                              alignVertical="flex-start"
                              key={index}
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (item.name === 'NGN') {
                                  setAccountName('');
                                }
                                setCurrency(item.name);
                                setCurrencyCode(item.tag);
                                setCurrencyDisplay(item.display);
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%',
                                  }}
                                >
                                  <ReactCountryFlag
                                    style={{
                                      fontSize: '1.3rem',
                                      borderRadius: 'none',
                                    }}
                                    countryCode={item.tag}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    title={item.tag}
                                  />
                                  <div
                                    style={{
                                      width: '70%',
                                    }}
                                  >
                                    <h3
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '80%',
                                      }}
                                    >
                                      {item.display
                                        ? `${item.display}        `
                                        : ''}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </SplitUsersWrap>
                          ))
                        ) : (
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '14px',
                              color: '#757575',
                              margin: '1rem 0 0 0',
                            }}
                          >
                            No Option.
                          </p>
                        )}
                      </div>
                    ) : null}
                  </CollabSuggestWrap>
                  {currency === 'NGN' ? (
                    <>
                      <InputWrap>
                        <label
                          style={{
                            fontSize: '12px',
                            color: '#757575',
                          }}
                        >
                          Bank
                        </label>
                        <Select
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          options={banks}
                          placeholder="Search..."
                          styles={CustomStyles}
                          isSearchable
                          onChange={(e: any) => {
                            setBankId((e as any).uuid);
                            setBank((e as any).name);
                          }}
                        />
                      </InputWrap>
                      <TicketInput>
                        <div>
                          <p>Account Number</p>
                          <input
                            required={true}
                            type="number"
                            onKeyPress={e => numOnly(e)}
                            onChange={e => {
                              setAccountNumber(e.target.value);
                              if (e.target.value.length < 10) {
                                setAccountName('');
                                setName('');
                              }
                            }}
                            onKeyUp={e => handleGetAccName(e)}
                          />
                        </div>
                      </TicketInput>
                      {nameProcess ? (
                        <ClipLoader size={15} />
                      ) : (
                        <>
                          {nameError ? (
                            <p style={{ color: '#c82b38', fontSize: '0.8rem' }}>
                              Account not found!!!
                            </p>
                          ) : null}
                        </>
                      )}
                      <TicketInput>
                        <div>
                          <p>Name</p>
                          <input
                            required={true}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Email</p>
                          <input
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </TicketInput>
                    </>
                  ) : null}

                  {/* USD Inputs Section */}
                  {currency === 'USD' ? (
                    <>
                      <TicketInput>
                        <div>
                          <p>Email</p>
                          <input
                            required={true}
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Account Name</p>
                          <input
                            required={true}
                            type="text"
                            onChange={e => {
                              setName(e.target.value);
                              setAccountName(e.target.value);
                            }}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Bank Name</p>
                          <input
                            required={true}
                            type="text"
                            onChange={e => {
                              setBank(e.target.value);
                            }}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Account Number</p>
                          <input
                            required={true}
                            type="number"
                            onKeyPress={e => numOnly(e)}
                            onChange={e => {
                              setAccountNumber(e.target.value);
                            }}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>SWIFT Code</p>
                          <input
                            required={true}
                            type="text"
                            onChange={e => {
                              setSwiftCode(e.target.value);
                            }}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Routing</p>
                          <input
                            required={true}
                            type="number"
                            onKeyPress={e => numOnly(e)}
                            onChange={e => {
                              setRouting(e.target.value);
                            }}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Bank Address</p>
                          <input
                            required={true}
                            type="text"
                            onChange={e => {
                              setBankAddress(e.target.value);
                            }}
                          />
                        </div>
                      </TicketInput>
                    </>
                  ) : null}

                  {/* GHS Inputs Section */}
                  {currency === 'GHS' ? (
                    <>
                      <TicketInput>
                        <div>
                          <p>Name</p>
                          <input
                            required={true}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Email</p>
                          <input
                            required={true}
                            type="email"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div>
                          <p>Number</p>
                          <input
                            required={true}
                            type="number"
                            onKeyPress={e => numOnly(e)}
                            onChange={e => {
                              setAccountNumber(e.target.value);
                            }}
                          />
                        </div>
                      </TicketInput>
                    </>
                  ) : null}
                </>
              ) : null}
            </div>
            <BigBtn>
              <button type="submit">
                {onProcess ? <BeatLoader color="#fff" /> : 'Save Recipient'}
              </button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occurred!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeSuccess}
          img="/success.png"
          message="Collaborator created successfully!"
          miniMessage={
            'You have successfully created a collaborator for your split payment. '
          }
        />
      ) : null}
    </>
  );
};

export default AddCollaborator;

const accountType = [
  {
    name: 'Mage User',
    borderRadius: '10px 10px 10px 10px',
  },
  {
    name: 'Non-Mage User',
    borderRadius: '10px 10px 10px 10px',
  },
];

const Countries = [
  {
    display: 'Naira (Bank Transfer)',
    name: 'NGN',
    tag: 'NG',
  },
  // {
  //   display: 'US Dollar (Wire Transfer)',
  //   name: 'USD',
  //   tag: 'US',
  // },
  // {
  //   display: 'Ghana Cedis (MTN Momo)',
  //   name: 'GHS',
  //   tag: 'GH',
  // },
];
