import styled from 'styled-components';

interface PropsArgs {
  hideMobile?: boolean;
  mobWidth?: string;
}

export const SearchArea = styled.div<PropsArgs>`
  width: 20rem;
  position: relative;

  @media (max-width: 1028px) {
    width: ${p => p.mobWidth || '95%'};
    display: ${p => (p.hideMobile ? 'none' : 'block')};
  }

  > i {
    position: absolute;
    top: 15%;
    right: 5%;
    color: #757575;

    @media (max-width: 1028px) {
      right: 7%;
    }
  }

  > input {
    width: 100%;
    padding: 0.5rem 3% 0.5rem 5%;
    background: #fffff;
    border: 1px solid #eaecee;
    border-radius: 8px;
    font-size: 14px;

    @media (max-width: 1028px) {
      width: 100%;
      font-size: 16px;
    }
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const FilterArea = styled.div`
  width: 25%;
  position: relative;
  display: flex;
  align-items: Center;
  // justify-content: flex-end;

  > span {
    margin: 0 1rem 0 0;
  }

  @media (max-width: 1028px) {
    width: 100%;
    margin: 4rem 0 0 0;
  }
`;
