import React, { useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from 'app/components/transactions/style';
import {
  AccountNoCenter,
  InputActPlacehold,
  ProgressBar,
  ProgressLine,
  CollabSuggestWrap,
  SplitUsersWrap,
  // AccountToolTip,
} from './style';
import {
  ButtonWrap,
  CustomButtonDark,
  ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import { FlexedApart } from '../merch/singleMerch/style';
import { useNavigate } from 'react-router';
import { TicketInput } from '../paymentLink/style';
import ReactCountryFlag from 'react-country-flag';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import CloseWarning from './closeWarning';

const MainContent = () => {
  const navigate = useNavigate();

  const [isInputFocus, setIsInputFocus] = useState(false);

  const navigatorAction = () => {
    navigate('/edit-collaborators');
  };

  const [closePageWarning, setClosePageWarning] = useState(false);

  const closeAction = () => {
    navigate('/split-payment');
  };

  return (
    <>
      {closePageWarning ? (
        <CloseWarning
          handleAction={closeAction}
          closeFunc={() => setClosePageWarning(false)}
        />
      ) : null}

      <MainWrapper
        className="no-scrollbar"
        style={{ width: '100%' }}
        left="0"
        top="4.5rem"
        onClick={() => setIsInputFocus(false)}
      >
        <FragmentHeader
          showBack={true}
          pageName={`Edit Currency`}
          setCloseWarning={() => setClosePageWarning(true)}
        />
        <form>
          <SearchPos position="fixed">
            <ButtonWrap mobileHide={false}>
              <CustomButtonDark type="submit" onClick={() => navigatorAction()}>
                <ButtonFlex>Add recipients</ButtonFlex>
              </CustomButtonDark>
            </ButtonWrap>
          </SearchPos>
          <BreadcrumbArea
            style={{
              margin: '1rem 0 10rem 0',
            }}
          >
            <ProgressBar>
              <ProgressLine bg="#191919"></ProgressLine>
              <ProgressLine></ProgressLine>
              <ProgressLine></ProgressLine>
            </ProgressBar>
            <AccountNoCenter>
              <div>
                <h3>Select Currency</h3>
                <p>What currency do you want to receive payments in?</p>
              </div>
            </AccountNoCenter>
            <MainFlex>
              <PairSection width="32%" mobileMargin="0">
                <div>
                  <FlexedApart
                    style={{
                      margin: '0 0 2rem 0',
                    }}
                  >
                    <h3>Recieve payment in</h3>
                  </FlexedApart>
                  <TicketInput>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <p>Currency</p>
                      <InputActPlacehold
                        onClick={e => {
                          setIsInputFocus(!isInputFocus);
                          e.stopPropagation();
                        }}
                        onBlur={() => setIsInputFocus(false)}
                      >
                        <div>
                          <ReactCountryFlag
                            style={{
                              fontSize: '1.3rem',
                              borderRadius: '50%',
                            }}
                            countryCode="NG"
                            svg
                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                            cdnSuffix="svg"
                            title="US"
                          />
                          <p
                            style={{
                              color: '#191919',
                            }}
                          >
                            NGN
                          </p>
                        </div>
                        <i>
                          <Icon.ChevronDown size={15} strokeWidth={3} />
                        </i>
                      </InputActPlacehold>
                    </div>
                  </TicketInput>
                  <CollabSuggestWrap
                    style={{
                      margin: '0',
                      width: '100%',
                    }}
                  >
                    {isInputFocus ? (
                      <div>
                        {Countries && Countries.length > 0 ? (
                          Countries.map((item, index) => (
                            <SplitUsersWrap
                              alignVertical="flex-start"
                              key={index}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%',
                                  }}
                                >
                                  <ReactCountryFlag
                                    style={{
                                      fontSize: '1.3rem',
                                      borderRadius: 'none',
                                    }}
                                    countryCode={item.tag}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    title={item.tag}
                                  />
                                  <div
                                    style={{
                                      width: '70%',
                                    }}
                                  >
                                    <h3
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '80%',
                                      }}
                                    >
                                      {item.name ? `${item.name}        ` : ''}
                                    </h3>
                                  </div>
                                  {item.tag !== 'NG' ? (
                                    <p>Coming soon</p>
                                  ) : null}
                                </div>
                              </div>
                            </SplitUsersWrap>
                          ))
                        ) : (
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '14px',
                              color: '#757575',
                              margin: '1rem 0 0 0',
                            }}
                          >
                            No Option.
                          </p>
                        )}
                      </div>
                    ) : null}
                  </CollabSuggestWrap>
                </div>
              </PairSection>
            </MainFlex>
          </BreadcrumbArea>
        </form>
      </MainWrapper>
    </>
  );
};

export default MainContent;

const Countries = [
  {
    name: 'NGN',
    tag: 'NG',
  },
  {
    name: 'USD',
    tag: 'US',
  },
  {
    name: 'GBP',
    tag: 'GB',
  },
  {
    name: 'GHC',
    tag: 'GH',
  },
  {
    name: 'CNY',
    tag: 'CN',
  },
  {
    name: 'KES',
    tag: 'KE',
  },
];
