import React, { useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import PopUpModal from '../merch/popupModal';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import * as Icon from 'react-feather';
import { TicketInput } from '../paymentLink/style';
import { AddPhotoWrapper, AddPhotoBox } from '../merch/addMerch/styles';
import { BeatLoader } from 'react-spinners';
import { handleFileUpload } from 'utils/imageUpload';

const IDModal = ({ closeFunc, nextFunc }) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const [idType, setIdType] = useState('');
  const [idNumber, setIdNumber] = useState('');

  // Image Format and more.
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  // const [images, setImages] = useState<any>([]);
  const [selectPoint, setSelectPoint] = useState('');
  const [returnedImages, setReturnedImages] = useState<any>('');
  const [secondReturnedImages, setSecondReturnedImages] = useState<any>('');

  const extraImageFunc = data => {
    if (selectPoint === 'first') {
      setReturnedImages(data);
    } else if (selectPoint === 'second') {
      setSecondReturnedImages(data);
    }
  };

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setError: setErrorMessage,
      setReturnedImages,
      extraFunc: extraImageFunc,
    });
  };

  const getTypeLength = (type: string) => {
    if (type === 'Voters_card') {
      return 15;
    } else if (type === 'NIN') {
      return 11;
    } else if (type === 'Drivers_lincense') {
      return 12;
    } else if (type === 'Passport') {
      return 9;
    }
  };

  const handleClose = () => {
    setErrorMessage('');
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const basicPayload = {
    number: idNumber,
    type: idType,
    image: `${returnedImages}${
      secondReturnedImages !== '' ? `,${secondReturnedImages}` : ''
    }`,
  };

  // Verification of Id and Sending
  const handleUpdateId = e => {
    e.preventDefault();
    if (
      idType &&
      idType !== '' &&
      idNumber &&
      idNumber !== '' &&
      returnedImages &&
      returnedImages !== ''
    ) {
      setOnProcess(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/users/kyc-document-update`,
          basicPayload,
          headers,
        )
        .then(res => {
          closeFunc();
          nextFunc();
          setOnProcess(false);
        })
        .catch(err => {
          setErrorMessage(err.response.data.message);
          setOnProcess(false);
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('Please fill all input fields!');
    }
  };

  return (
    <>
      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Verify your ID</Header>
          <form onSubmit={e => handleUpdateId(e)}>
            <div>
              <TicketInput>
                <div>
                  <p>ID Type</p>
                  <select
                    onChange={e => setIdType(e.target.value)}
                    value={idType}
                    required={true}
                  >
                    <option value="">Select type</option>
                    <option value="NIN">National ID</option>
                    <option value="Passport">Passport</option>
                    <option value="Drivers_license">Driver's License</option>
                    <option value="Voters_card">Voter's Card</option>
                  </select>
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>ID Number</p>
                  <input
                    type="text"
                    placeholder="ID"
                    onChange={e => setIdNumber(e.target.value)}
                    value={idNumber}
                    required={true}
                    maxLength={getTypeLength(idType)}
                  />
                </div>
              </TicketInput>
              <AddPhotoWrapper
                style={
                  returnedImages !== ''
                    ? {
                        backgroundImage: `url(${returnedImages})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        margin: '0 0 0.5rem 0',
                        width: '100%',
                        justifyContent: 'center',
                        height: '10rem',
                        position: 'relative',
                      }
                    : {
                        margin: '0 0 0.5rem 0',
                        width: '100%',
                        justifyContent: 'center',
                        height: '7rem',
                        position: 'relative',
                        background: '#f9f9f9',
                      }
                }
              >
                <AddPhotoBox
                  id="drop_zone"
                  style={{
                    height: '3rem',
                    width: '3rem',
                    background: 'rgba(0, 0, 0, 0.4)',
                    border: 'none',
                    borderRadius: '10rem',
                    position: 'absolute',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    cursor: 'pointer',
                  }}
                >
                  <label
                    htmlFor="main-input"
                    onClick={() => setSelectPoint('first')}
                  >
                    <Icon.Camera color="#fff" />
                  </label>
                  <input
                    id="main-input"
                    type="file"
                    multiple={false}
                    style={{ display: 'none' }}
                    onChange={e => {
                      handleFile(e.target.files);
                    }}
                  ></input>
                </AddPhotoBox>
                <p
                  style={{
                    position: 'absolute',
                    bottom: '1rem',
                    fontSize: '12px',
                  }}
                >
                  Upload front page{' '}
                  {idType !== ''
                    ? `of ${idType && idType.replace('_', ' ')}`
                    : ''}
                </p>
              </AddPhotoWrapper>
              {idType !== 'Passport' && idType !== '' ? (
                <AddPhotoWrapper
                  style={
                    secondReturnedImages !== ''
                      ? {
                          backgroundImage: `url(${secondReturnedImages})`,
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                          margin: '0 0 0.5rem 0',
                          width: '100%',
                          justifyContent: 'center',
                          height: '10rem',
                          position: 'relative',
                        }
                      : {
                          margin: '0 0 0.5rem 0',
                          width: '100%',
                          justifyContent: 'center',
                          height: '7rem',
                          position: 'relative',
                          background: '#f9f9f9',
                        }
                  }
                >
                  <AddPhotoBox
                    id="drop_zone"
                    style={{
                      height: '3rem',
                      width: '3rem',
                      background: 'rgba(0, 0, 0, 0.4)',
                      border: 'none',
                      borderRadius: '10rem',
                      position: 'absolute',
                      top: '40%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      cursor: 'pointer',
                    }}
                  >
                    <label
                      htmlFor="main-input"
                      onClick={() => setSelectPoint('second')}
                    >
                      <Icon.Camera color="#fff" />
                    </label>
                    <input
                      id="main-input"
                      type="file"
                      multiple={false}
                      style={{ display: 'none' }}
                      onChange={e => {
                        handleFile(e.target.files);
                      }}
                    ></input>
                  </AddPhotoBox>
                  <p
                    style={{
                      position: 'absolute',
                      bottom: '1rem',
                      fontSize: '12px',
                    }}
                  >
                    Upload back page{' '}
                    {idType !== ''
                      ? `of ${idType && idType.replace('_', ' ')}`
                      : ''}{' '}
                  </p>
                </AddPhotoWrapper>
              ) : null}
            </div>
            {errorMessage !== '' ? (
              <p style={{ color: 'red', width: '20rem' }}>{errorMessage}</p>
            ) : null}
            <BigBtn>
              <button type="submit">
                {onProcess ? <BeatLoader color="#fff" /> : 'Submit'}
              </button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>

      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            closeFunc();
            nextFunc();
          }}
          img="success.png"
          message="Changes made successfully!"
          miniMessage="You have successfully updated your profile informations."
        />
      ) : null}
    </>
  );
};

export default IDModal;
