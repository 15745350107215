import React, { useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import OtpInput from 'react-otp-input';
import styled from 'styled-components';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';

const SetPin = ({ closeFunc }) => {
  const navigate = useNavigate();
  const inputStyle = {
    width: '1rem',
    height: '1rem',
    borderRadius: '10rem',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 8,
    display: 'flex',
    alignitems: 'Center',
    justifyContent: 'center',
  };

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState<boolean | string>(false);
  const [pin, setPin] = useState('');
  const [onProcess, setOnProcess] = useState(false);

  // Set Pin

  const handlePinChange = () => {
    if (pin && pin.length === 4) {
      setOnProcess(true);
      axios
        .patch(
          `${process.env.REACT_APP_BASE_ENDPOINT}/users/${loggedUser.user.id}`,
          { pin },
          {
            headers: {
              Authorization: Authorization,
            },
          },
        )
        .then(res => {
          setOnProcess(false);
          setSuccess(true);
          setTimeout(() => {
            navigate(`/`);
          }, 4000);
        })
        .catch(err => {
          setOnProcess(false);
          setErrorMessage(err.response.data.message);
          setPin('');
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('PIN length must be equal to 4.');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Change Pin</Header>
          <div>
            <div>
              <p
                style={{
                  width: '20rem',
                  margin: '0 0 2rem 0',
                  color: 'grey',
                  textAlign: 'left',
                }}
              >
                Please enter a new pin for transactions.
              </p>
              <PinInputWrap>
                <OtpInput
                  value={pin}
                  onChange={code => setPin(code)}
                  numInputs={4}
                  inputStyle={inputStyle}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  isInputSecure={true}
                  className="otp-input"
                />
              </PinInputWrap>
            </div>
            {errorMessage !== '' ? (
              <p style={{ color: 'red', width: '20rem' }}>{errorMessage}</p>
            ) : null}
          </div>
          <BigBtn>
            <button onClick={() => handlePinChange()}>
              {onProcess ? <BeatLoader color="#fff" /> : 'Save Changes'}
            </button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
      {success ? (
        <Alert
          closeFunc={() => navigate('/')}
          img="success.png"
          message="Changes made successfully!"
          miniMessage="You have successfully updated your PIN."
        />
      ) : null}
    </>
  );
};

export default SetPin;

const PinInputWrap = styled.div`
  width: 10rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 0 0;
  background: #f3f3f3;
  border-radius: 35.6541px;

  @media (max-width: 728px) {
    width: 10remrea;
  }
`;
