import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 90%;
  padding: 3rem 3%;

  @media (max-width: 1028px) {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 5%;
  }
`;

export const Header = styled.h1`
  text-align: center;
  margin: 2rem 0;
  text-decoration: underline;
  font-weight: 800;
`;

export const Section = styled.div`
  padding: 0.5rem 0;
  margin: 1rem 0;
  color: #474747;
`;

export const PriceSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 3rem 0 0 0;

  > div {
    width: 40%;
    margin: 0 0 1rem 0;
    display: flex;
    align-items: Center;
    justify-content: space-between;
  }

  @media (max-width: 728px) {
    > div {
      width: 80%;
    }
  }
`;

export const InfoIcons = styled.div`
  // width: 50%;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin: 0 0 1rem 0;

  @media (max-width: 1028px) {
    width: 100%;
  }

  > div {
    width: 3rem;
    height: 3rem;
    border-radius: 10rem;
    margin: 0 0 0 2%;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    color: #757575;
  }
`;
