import { getSign } from './getCurrencySign';

const Helper = {
  formatMoney: amount =>
    `${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,

  formatCurrency: (amount, currency = 'NGN') =>
    `${getSign(currency)}${amount
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`,
  clipText: ($string = '', $count = 50) => {
    if (!$string) {
      return 'Some text...';
    }
    let countCheck = 0;
    const elem = $string.split(' ');
    let text = '';
    let exit = 'false';
    let val = '';
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < elem.length; i++) {
      if (elem.length === 1) {
        // eslint-disable-next-line no-plusplus
        for (let a = 0; a < elem[i].length; a++) {
          if ($count === 0 || a === elem[i].length - 1) {
            exit = 'trueCase1';
            break;
          }
          val += elem[i][a];
          $count -= 1;
          countCheck += 1;
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let a = 0; a < elem[i].length; a++) {
          if ($count === 1 && a < elem[i].length - 1) {
            exit = 'true';
            break;
          } else if ($count === 1 && a === elem[i].length - 1) {
            exit = 'truePlus';
          } else if ($string.length - 1 === countCheck + elem.length) {
            exit = 'truePlus';
            break;
          } else {
            $count -= 1;
            countCheck += 1;
          }
        }
      }
      if (exit === 'false') {
        text += `${elem[i]} `;
      } else if (exit === 'truePlus') {
        text += elem[i];
        break;
      } else if (exit === 'trueCase1') {
        text = val;
        break;
      } else {
        break;
      }
    }

    if (countCheck + elem.length + 1 < $string.length) {
      text = text.trim();
      text += '...';
    } else {
      text = text.trim();
    }

    return text;
  },
  clipTextV2: (text, length = 50) => {
    if (text && text.length > length) {
      return (
        text
          .split('')
          .filter((x, index) => index < length)
          .join('') + '...'
      );
    }
    return text;
  },
  textSeparator: (text, delimeter = 4) => {
    let updatedText = '';
    let x = 0;
    text.split('').forEach((c, i) => {
      updatedText += c;
      x += 1;
      if (x === delimeter && i < text.split('').length - 1) {
        updatedText += ' ';
        x = 0;
      }
    });
    return updatedText;
  },
};

export default Helper;
