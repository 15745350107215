import React, { useState, useEffect } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from 'app/components/transactions/style';
import {
  SplitUsersWrap,
  AccountNoCenter,
  MiniHeader,
  SingleInfoFlex,
  InputWrap,
  SplitAvatar,
  ProgressBar,
  ProgressLine,
  InputActPlacehold,
  CollabSuggestWrap,
  Disclaimer,
} from './style';
import {
  ButtonWrap,
  CustomButtonDark,
  ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import { Line } from 'app/components/transactions/style';
import { numOnly } from 'utils/numOnly';
import { TicketInput } from '../paymentLink/style';
import axios from 'axios';
import CreateWarning from './createWarning';
import SplitSuccess from './success';
import { useNavigate } from 'react-router';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import CommaNumber from 'comma-number';
import SendUpdateModal from './sendUpdate';
import { FixedWrap } from 'app/pages/Onboarding/style';
import MerchroImg from '../../../img/merchro-logo.png';
import { LogoImage } from 'app/pages/Onboarding';
import { Link } from 'react-router-dom';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import CloseWarning from './closeWarning';
import ReactCountryFlag from 'react-country-flag';
import { Countries } from './setCurrency';
import { sum } from 'utils/sum';
import PopUpModal from '../merch/popupModal';
import { handleFileUpload } from 'utils/imageUpload';

const MainContent = () => {
  const activeSplitIndex = JSON.parse(localStorage.getItem('deixn') || '{}');
  const navigate = useNavigate();
  const collaborators = JSON.parse(
    localStorage.getItem('editcollabData') || '{}',
  );
  const splitData = JSON.parse(localStorage.getItem('split') || '{}');
  const [finalCollaborators, setFinalCollaborators] = useState([]);

  useEffect(() => {
    const splitData = JSON.parse(localStorage.getItem('split') || '{}');
    setName(splitData.name);
    setSplitType(splitData.splitType);
    setLimitValue(splitData.limitValue);
    setFrequency(splitData.frequency);
    setReturnedImages(
      splitData.avatar
        ? splitData.avatar
        : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png',
    );
  }, []);

  // Modals Managament
  const [openWarning, setOpenWarning] = useState(false);
  const [openSendUpdate, setOpenSendUpdate] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false);

  // Split Final Data
  const [name, setName] = useState('');
  const [splitType, setSplitType] = useState('time');
  const [limitValue, setLimitValue] = useState<any>(0);
  const [frequency, setFrequency] = useState('daily');
  const [returnedImages, setReturnedImages] = useState<any>(
    'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png',
  );
  const [totalAmount, setTotalAmount] = useState<any>();
  const [amountArray, setAmountArray] = useState<any>([]);
  const [error, setError] = useState('');
  const [clientErr, setClientErr] = useState('');
  const [success, setSuccess] = useState(false);
  const [hasPercent, setHasPercent] = useState(false);

  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const [fee, setFee] = useState<any>({});

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/fees`, {
        headers: { Authorization },
      })
      .then(res => {
        if (res.data) {
          setFee(res.data);
        }
      })
      .catch(err => {});
  }, []);

  // Get Collaborator Data ready for Payload
  useEffect(() => {
    const collaborators = JSON.parse(
      localStorage.getItem('editcollabData') || '{}',
    );
    let mockData: any = [];

    if (collaborators && collaborators.length > 0) {
      for (let i = 0; i < collaborators.length; i++) {
        mockData.push({
          collaboratorId: collaborators[i].id,
          cap: collaborators[i].cap,
          amount: collaborators[i].amount,
          canView: collaborators[i].canView,
          percentage: collaborators[i].amount ? 0 : collaborators[i].percentage,
        });

        if (collaborators[i].amount) {
          setAmountArray(item => item.concat(collaborators[i].amount));
        }
        if (collaborators[i].percentage > 0) {
          setHasPercent(true);
        }
      }
      setFinalCollaborators(mockData);
    }
  }, []);

  useEffect(() => {
    if (hasPercent && amountArray && amountArray.length > 0) {
      setTotalAmount(Number(sum(amountArray)) + 2000);
    } else if (amountArray && amountArray.length > 0) {
      setTotalAmount(sum(amountArray));
    }
  }, [amountArray, fee]); //eslint-disable-line

  useEffect(() => {
    if (finalCollaborators && finalCollaborators.length > 0) {
      if (totalAmount > limitValue) {
        setLimitValue(
          totalAmount +
            finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      } else if (!totalAmount && !limitValue) {
        setLimitValue(
          2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      } else if (totalAmount > limitValue && totalAmount < 2000) {
        setLimitValue(
          2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      }
    }
  }, [totalAmount, finalCollaborators]); //eslint-disable-line

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setError: setClientErr,
      setReturnedImages,
    });
  };

  const handleClose = () => {
    setError('');
    setSuccess(false);
  };

  // Create Split Functions

  const basicPayload = {
    name,
    avatar: returnedImages,
    splitType,
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const createSplitWarning = e => {
    e.preventDefault();
    if (name.length >= 5) {
      if (!limitValue && !totalAmount) {
        setOpenWarning(true);
      } else if (limitValue > 0 && limitValue >= 2000) {
        if (limitValue >= totalAmount) {
          setOpenWarning(true);
        } else {
          setClientErr(
            "The minimum amount cannot be lower than the sum of recipient's amount",
          );
          setLimitValue(
            totalAmount +
              finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
          setTimeout(() => {
            setClientErr('');
          }, 4000);
        }
      } else if (
        limitValue > 0 &&
        limitValue < 2000 &&
        limitValue < totalAmount
      ) {
        if (
          limitValue <= totalAmount &&
          totalAmount < 2000 &&
          limitValue < 2000
        ) {
          setClientErr(`The minimum amount is NGN${CommaNumber(2000)}`);
          setLimitValue(
            2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
        } else if (limitValue < totalAmount && totalAmount > 2000) {
          setClientErr(`The minimum amount is NGN${CommaNumber(totalAmount)}`);
          setLimitValue(
            totalAmount +
              finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
        }
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      } else {
        setClientErr('The minimum amount is #2,000');
        setLimitValue(
          totalAmount +
            finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    }
  };

  // Check Amount Logic
  const [isValid, setIsValid] = useState<boolean | string>('');

  const checkAmountValidation = () => {
    if (limitValue > 0 && limitValue >= 2000) {
      if (limitValue >= totalAmount) {
        setIsValid(true);
      } else {
        setClientErr(
          "The minimum amount cannot be lower than the sum of recipient's amount",
        );
        setLimitValue(
          totalAmount +
            finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else if (
      limitValue > 0 &&
      limitValue < 2000 &&
      limitValue < totalAmount
    ) {
      if (
        limitValue <= totalAmount &&
        totalAmount < 2000 &&
        limitValue < 2000
      ) {
        setClientErr(`The minimum amount is NGN${CommaNumber(2000)}`);
        setLimitValue(
          2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      } else if (limitValue < totalAmount && totalAmount > 2000) {
        setClientErr(`The minimum amount is NGN${CommaNumber(totalAmount)}`);
        setLimitValue(
          totalAmount +
            finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      }
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    } else {
      setClientErr('The minimum amount is #2,000');
      setLimitValue(
        totalAmount +
          finalCollaborators.length * Number(fee.outboundTransfer | 0),
      );
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  const [splitBank, setSplitBank] = useState('');
  const [splitAccNo, setSplitAccNo] = useState('');
  const [splitAccName, setSplitAccName] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [onProcessOnNotSend, setOnProcessOnNotSend] = useState(false);

  const sendUpdate = () => {
    setOpenSendUpdate(true);
    setOpenWarning(false);
  };

  const closeSendUpdateModal = () => {
    setOpenWarning(true);
    setOpenSendUpdate(false);
  };

  const createSplit = async (sendboolValue: boolean) => {
    setOnProcess(true);
    setOnProcessOnNotSend(true);
    try {
      if (limitValue > 0) {
        basicPayload['limitValue'] = limitValue;
      }
      if (splitType === 'time') {
        basicPayload['frequency'] = frequency;
      }
      basicPayload['sendUpdate'] = sendboolValue;

      await axios
        .patch(
          `${process.env.REACT_APP_BASE_ENDPOINT}/splits/${splitData.id}`,
          basicPayload,
          headers,
        )
        .then(res => {
          if (res.data.accountInfo) {
            setSplitAccNo(res.data.accountInfo.accountNumber);
            setSplitBank(res.data.accountInfo.bankName);
            setSplitAccName(res.data.accountInfo.accountName);
          }
          setOpenWarning(false);
          setSuccess(true);
          setOnProcess(false);
          setOnProcessOnNotSend(false);
          setTimeout(() => {
            setSuccess(false);
            navigate('/split-payment');
          }, 1500);
        })
        .catch(err => {
          setError(err.response.data.message);
          setOnProcess(false);
          setOnProcessOnNotSend(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    } catch {}
  };

  const [closePageWarning, setClosePageWarning] = useState(false);

  const closeAction = () => {
    navigate('/split-payment');
    localStorage.removeItem('collabData');
  };

  return (
    <>
      {activeSplitIndex === 0 ? (
        <>
          {success ? (
            <SplitSuccess
              closeFunc={() => navigate('/split-payment')}
              img="/success.png"
              message="Split has been edited successfully"
              accNo={splitAccNo}
              bank={splitBank}
              accName={splitAccName}
            />
          ) : null}

          {closePageWarning ? (
            <CloseWarning
              handleAction={closeAction}
              closeFunc={() => setClosePageWarning(false)}
            />
          ) : null}

          {error !== '' ? (
            <Alert
              closeFunc={handleClose}
              message="An Error occured!"
              miniMessage={error}
              img="/failed.png"
            />
          ) : null}

          {openWarning ? (
            <CreateWarning
              closeFunc={() => setOpenWarning(false)}
              actionInProcess={onProcess}
              handleAction={() => sendUpdate()}
              message="Are you sure you want to save these changes?"
              actionText="Save Changes"
              splitName={name}
              splitType={splitType}
              frequency={frequency}
              limitValue={limitValue}
              avatar={returnedImages}
              collaborators={collaborators}
              currency={''}
            />
          ) : null}

          {clientErr !== '' ? (
            <PopUpModal
              handleClose={handleClose}
              msg="An Error occured"
              extraMsg={clientErr}
              icon="fas fa-exclamation"
              iconColor="#c82b32"
            />
          ) : null}

          {openSendUpdate ? (
            <SendUpdateModal
              onProcessOnNotSend={onProcessOnNotSend}
              closeFunc={closeSendUpdateModal}
              rejectSend={() => createSplit(false)}
              actionInProcess={onProcess}
              handleAction={() => createSplit(true)}
              message="Do you want to send update emails to the recipients of this split?"
              actionText="Send Email"
            />
          ) : null}

          <MainWrapper
            className="no-scrollbar"
            style={{ width: '100%' }}
            left="0"
            top="2.5rem"
            onClick={() => setIsInputFocus(false)}
          >
            <FragmentHeader
              showBack={true}
              pageName={`Edit Split`}
              setCloseWarning={() => setClosePageWarning(true)}
            />
            <form onSubmit={e => createSplitWarning(e)}>
              <SearchPos position="fixed">
                <ButtonWrap mobileHide={false}>
                  <CustomButtonDark type="submit">
                    <ButtonFlex>Save Changes</ButtonFlex>
                  </CustomButtonDark>
                </ButtonWrap>
              </SearchPos>
              <BreadcrumbArea>
                {/* <PreferenceBlock> */}
                <ProgressBar>
                  <ProgressLine
                    onClick={() => navigate('/edit-collaborators')}
                    style={{ cursor: 'pointer' }}
                  ></ProgressLine>
                  <ProgressLine bg="#191919"></ProgressLine>
                </ProgressBar>
                <AccountNoCenter>
                  <div>
                    <h3>You’re almost done!</h3>
                    <p>Just some final details left</p>
                  </div>
                </AccountNoCenter>
                <MainFlex>
                  <PairSection width="32%" mobileMargin="0">
                    <div>
                      <h3>Split Details</h3>
                      <SingleInfoFlex>
                        <section>
                          <img src={returnedImages} alt="Display" />
                          <i>
                            <label htmlFor="file-input">
                              <Icon.Camera
                                size={20}
                                color="#fff"
                                style={{
                                  cursor: 'pointer',
                                }}
                              />
                            </label>
                          </i>

                          <input
                            type="file"
                            id="file-input"
                            multiple={false}
                            style={{ display: 'none' }}
                            onChange={e => {
                              handleFile(e.target.files);
                            }}
                          />
                        </section>
                        <div>
                          <h4>{name}</h4>
                          {splitType === 'time' ? (
                            <p>
                              <span>
                                <Icon.Zap
                                  size={15}
                                  style={{
                                    position: 'relative',
                                    top: '0.05rem',
                                  }}
                                />
                                {frequency}
                              </span>
                            </p>
                          ) : null}
                          {splitType === 'approval' ? (
                            <p>
                              <span>
                                <Icon.Zap
                                  size={15}
                                  style={{
                                    position: 'relative',
                                    top: '0.05rem',
                                  }}
                                />
                                On Approval
                              </span>
                            </p>
                          ) : null}
                          {splitType === 'instantly' ? (
                            <p>
                              <span>
                                <Icon.Zap
                                  size={15}
                                  style={{
                                    position: 'relative',
                                    top: '0.05rem',
                                  }}
                                />
                                {splitType}
                              </span>
                            </p>
                          ) : null}
                        </div>
                      </SingleInfoFlex>
                      <Line />
                      <MiniHeader>Split breakdown</MiniHeader>
                      {collaborators && collaborators.length > 0
                        ? collaborators.map((item, index) => (
                            <SplitUsersWrap key={index}>
                              <SplitAvatar>
                                {item.name ? item.name[0] : ''}
                              </SplitAvatar>
                              <div
                                style={{
                                  width: '58%',
                                }}
                              >
                                <h3>
                                  {item.name}{' '}
                                  {index === 0 ? <span> . Owner</span> : ''}
                                </h3>
                                <p>{item.email}</p>
                              </div>
                              {item.percentage || !item.amount ? (
                                <p>{item.percentage}%</p>
                              ) : (
                                <p>&#8358;{CommaNumber(item.amount)}</p>
                              )}
                            </SplitUsersWrap>
                          ))
                        : null}
                    </div>
                    <Disclaimer width="100%">
                      <Icon.Info />
                      <div>
                        <p>
                          Split to each recipient will cost &#8358;
                          {Number(fee.outboundTransfer | 0)}. The total cost to
                          split payments to {finalCollaborators.length}{' '}
                          recipients will be &#8358;
                          {finalCollaborators.length *
                            Number(fee.outboundTransfer | 0)}
                        </p>
                      </div>
                    </Disclaimer>
                  </PairSection>
                  <PairSection width="32%">
                    <div>
                      <TicketInput>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <p>Split Name</p>
                          <input
                            placeholder="Split name"
                            required
                            value={name}
                            minLength={5}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                      </TicketInput>
                      <TicketInput>
                        <div
                          style={{
                            width: '100%',
                          }}
                        >
                          <p>Receive payments in</p>
                          <InputActPlacehold
                            onClick={e => {
                              setIsInputFocus(!isInputFocus);
                              e.stopPropagation();
                            }}
                            onBlur={() => setIsInputFocus(false)}
                          >
                            <div>
                              <ReactCountryFlag
                                style={{
                                  fontSize: '1.3rem',
                                  borderRadius: '50%',
                                }}
                                countryCode="NG"
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                cdnSuffix="svg"
                              />
                              <p
                                style={{
                                  color: '#191919',
                                  fontSize: '14px',
                                }}
                              >
                                NGN
                              </p>
                            </div>
                            <i>
                              <Icon.ChevronDown size={15} strokeWidth={3} />
                            </i>
                          </InputActPlacehold>
                        </div>
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#ababab',
                            textAlign: 'right',
                            position: 'relative',
                            top: '0rem',
                          }}
                        >
                          A unique NGN account will be generated
                        </p>
                      </TicketInput>
                      <CollabSuggestWrap
                        style={{
                          margin: '0',
                          top: '-2.3rem',
                          width: '100%',
                          zIndex: '1000',
                        }}
                      >
                        {isInputFocus ? (
                          <div>
                            {Countries && Countries.length > 0 ? (
                              Countries.map((item, index) => (
                                <SplitUsersWrap
                                  alignVertical="flex-start"
                                  key={index}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                        width: '100%',
                                      }}
                                    >
                                      <ReactCountryFlag
                                        style={{
                                          fontSize: '1.3rem',
                                          borderRadius: 'none',
                                        }}
                                        countryCode={item.tag}
                                        svg
                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                        cdnSuffix="svg"
                                        title={item.tag}
                                      />
                                      <div
                                        style={{
                                          width: '70%',
                                        }}
                                      >
                                        <h3
                                          style={{
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            width: '80%',
                                          }}
                                        >
                                          {item.name
                                            ? `${item.name}        `
                                            : ''}
                                        </h3>
                                      </div>
                                      {item.tag !== 'NG' ? (
                                        <p>Coming soon</p>
                                      ) : null}
                                    </div>
                                  </div>
                                </SplitUsersWrap>
                              ))
                            ) : (
                              <p
                                style={{
                                  textAlign: 'center',
                                  fontSize: '14px',
                                  color: '#757575',
                                  margin: '1rem 0 0 0',
                                }}
                              >
                                No Option.
                              </p>
                            )}
                          </div>
                        ) : null}
                      </CollabSuggestWrap>
                      <InputWrap>
                        <h4>Split Method</h4>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                          }}
                        >
                          <select
                            style={
                              splitType === 'time'
                                ? {
                                    width: '47%',
                                    margin: '0.1rem 0 0 0',
                                  }
                                : {
                                    width: '100%',
                                    margin: '0.1rem 0 0 0',
                                  }
                            }
                            value={splitType}
                            onChange={e => setSplitType(e.target.value)}
                            required
                          >
                            <option value="time">Time based</option>
                            <option value="approval">On Approval</option>
                            <option value="instantly">Instant</option>
                          </select>
                          {splitType === 'time' ? (
                            <select
                              style={{
                                margin: '0.1rem 0 0 0',
                              }}
                              required
                              value={frequency}
                              onChange={e =>
                                setFrequency(e.target.value.toLocaleLowerCase())
                              }
                            >
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                              <option value="quarterly">Quarterly</option>
                              <option value="yearly">Yearly</option>
                            </select>
                          ) : null}

                          {splitType === 'limit' ? (
                            <TicketInput
                              style={{
                                width: '47%',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <p>Limit(&#8358;)</p>
                                <input
                                  type="number"
                                  required
                                  onChange={e =>
                                    setLimitValue(Number(e.target.value))
                                  }
                                  onKeyPress={e => numOnly(e)}
                                />
                              </div>
                            </TicketInput>
                          ) : null}
                        </div>
                      </InputWrap>
                      {splitType === 'instantly' ? (
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#ababab',
                            textAlign: 'right',
                            position: 'relative',
                            top: '-0.8rem',
                          }}
                        >
                          Split is made immediately any amount is paid
                        </p>
                      ) : null}
                      {splitType === 'time' ? (
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#ababab',
                            textAlign: 'right',
                            position: 'relative',
                            top: '-0.8rem',
                          }}
                        >
                          Split is made {frequency}
                        </p>
                      ) : null}
                      {splitType === 'limit' ? (
                        <p
                          style={{
                            fontSize: '12px',
                            color: '#ababab',
                            textAlign: 'right',
                            position: 'relative',
                            top: '-0.8rem',
                          }}
                        >
                          Split is made when the money in the account gets to
                          &#8358;{limitValue}
                        </p>
                      ) : null}
                      <TicketInput>
                        <div
                          style={
                            isValid || isValid === ''
                              ? {
                                  border: '0.5px solid #dadada',
                                  width: '100%',
                                }
                              : {
                                  border: '0.5px solid #c82b38',
                                  width: '100%',
                                }
                          }
                        >
                          <p>Minimum amount required</p>
                          <input
                            placeholder="Minimum amount"
                            required
                            type="number"
                            value={limitValue}
                            onKeyPress={e => numOnly(e)}
                            onBlur={() => checkAmountValidation()}
                            onChange={e =>
                              setLimitValue(Number(e.target.value))
                            }
                          />
                        </div>
                      </TicketInput>
                      <p
                        style={{
                          fontSize: '12px',
                          color: '#ababab',
                          textAlign: 'right',
                          position: 'relative',
                          top: '-0.8rem',
                        }}
                      >
                        Minimum amount required to enable split is &#8358;
                        {limitValue}
                      </p>
                    </div>
                  </PairSection>
                </MainFlex>
                {/* </PreferenceBlock> */}
              </BreadcrumbArea>
            </form>
          </MainWrapper>
        </>
      ) : (
        <FixedWrap>
          <Link to="/">
            <LogoImage src={MerchroImg} alt="logo" />
          </Link>
          <div>
            <img src="/failed.png" alt="Unauthorized" />
            <h3>No Access</h3>
            <p>Sorry, you don't have access to this page.</p>
            <Link to="/split-payment">
              <button
                style={{
                  display: 'block',
                  margin: '2rem auto',
                  padding: '10px 50px',
                  background: '#c82b38',
                  color: '#fff',
                  fontWeight: '600',
                  cursor: 'pointer',
                  borderRadius: '10px',
                }}
              >
                Go Back
              </button>
            </Link>
          </div>
        </FixedWrap>
      )}
    </>
  );
};

export default MainContent;
