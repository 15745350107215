import {
  getDatabase,
  onValue,
  ref,
  update,
  child,
  get,
} from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'utils/firebaseConfig';

const app = initializeApp(firebaseConfig);

// Update Pin Function
export function changeUserPin(userId, pin, setResult) {
  const db = getDatabase(app);

  const updates = {};

  updates[
    `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/` +
      userId +
      '/pin'
  ] = pin;
  const starCountRef = ref(db, 'userData/' + userId);

  onValue(starCountRef, snapshot => {
    if (snapshot) {
      setResult(true);
    } else {
      setResult('');
      setTimeout(() => {
        setResult(false);
      }, 4000);
    }
  });

  return update(ref(db), updates);
}

// Update BVN Function

export function updateBvn(userId, number, fullName, setResult) {
  const db = getDatabase(app);

  const bvnData = {
    number: number,
    fullName: fullName,
    verified: false,
  };

  const updates = {};

  updates[
    `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/` +
      userId +
      '/bvn'
  ] = bvnData;
  const starCountRef = ref(db, 'userData/' + userId);

  onValue(starCountRef, snapshot => {
    if (snapshot) {
      setResult(true);
    } else {
      setResult('');
      setTimeout(() => {
        setResult(false);
      }, 4000);
    }
  });

  return update(ref(db), updates);
}

// Update Identity

export function updateIdentity(userId, type, code, imageUrl, setResult) {
  const db = getDatabase(app);

  const identityData = {
    type: type,
    code: code,
    imageUrl: imageUrl,
    verified: false,
  };

  const updates = {};

  updates[
    `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/` +
      userId +
      '/identity'
  ] = identityData;
  const starCountRef = ref(db, 'userData/' + userId);

  update(ref(db), updates);

  onValue(starCountRef, snapshot => {
    if (snapshot) {
      setResult(true);
    } else {
      setResult('');
      setTimeout(() => {
        setResult(false);
      }, 4000);
    }
  });
}

// Update WIthdraw Beneficiaries

export async function updateBeneficiaries(
  userId,
  fullName,
  accountNo,
  bankId,
  bankName,
) {
  const db = getDatabase(app);
  const dbRef = ref(getDatabase());
  let finalData: any = [];

  const BeneficiaryDetail: any = {
    fullName: fullName,
    accountNumber: accountNo,
    bankId: bankId,
    isPayload: true,
    bankName: bankName,
  };

  await get(
    child(
      dbRef,
      `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/${userId}`,
    ),
  )
    .then(snapshot => {
      if (snapshot.exists()) {
        if (snapshot.val().frequent_beneficiaries) {
          finalData = snapshot.val().frequent_beneficiaries;
          let mockData = snapshot.val().frequent_beneficiaries;
          let old: boolean = false;
          for (let i = 0; i < mockData.length; i++) {
            if (mockData[i].accountNumber === accountNo) {
              old = true;
            }
          }
          if (!old) {
            finalData.push(BeneficiaryDetail);
          }
        } else {
          finalData = [];
          finalData.push(BeneficiaryDetail);
        }
      }
    })
    .catch(err => {});

  const updates = {};
  updates[
    `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/` +
      userId +
      '/frequent_beneficiaries'
  ] = finalData;
  const starCountRef = ref(db, 'userData/' + userId);

  await update(ref(db), updates);

  onValue(starCountRef, snapshot => {
    if (snapshot.exists()) {
      return true;
    } else {
      return false;
    }
  });
}

// Delete Beneficiary
export async function DeleteBeneficiaries(userId, index) {
  const db = getDatabase(app);
  const dbRef = ref(getDatabase());
  let finalData: any = [];

  await get(
    child(
      dbRef,
      `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/${userId}`,
    ),
  )
    .then(snapshot => {
      if (snapshot.exists()) {
        if (snapshot.val().frequent_beneficiaries) {
          finalData = snapshot.val().frequent_beneficiaries;
          finalData.splice(index, 1);
        }
      }
    })
    .catch(err => {});

  const updates = {};
  updates[
    `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/` +
      userId +
      '/frequent_beneficiaries'
  ] = finalData;
  const starCountRef = ref(db, 'userData/' + userId);

  await update(ref(db), updates);

  onValue(starCountRef, snapshot => {
    if (snapshot.exists()) {
      return true;
    } else {
      return false;
    }
  });
}

// Get User Data Info from Database
export function getUserData(userId, setVariable) {
  const dbRef = ref(getDatabase());
  get(
    child(
      dbRef,
      `${process.env.REACT_APP_ENVIRONMENT || 'production'}/userData/${userId}`,
    ),
  )
    .then(snapshot => {
      if (snapshot.exists()) {
        setVariable(snapshot.val());
      } else {
        return 'No data available';
      }
    })
    .catch(err => {});
}
