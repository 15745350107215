import React from 'react';
import * as Icon from 'react-feather';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
} from '../dashboard/mainContent/fundWallet/style';
import { SplitUsersWrap, SplitAvatar } from './style';
import { BeatLoader } from 'react-spinners';

const CollabDeleteWarning = ({ handleAction, closeFunc, item, onProcess }) => {
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <h3
            style={{
              width: '20rem',
              margin: '2rem 0 0 0',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            Delete Recipient
          </h3>
          <p
            style={{
              width: '20rem',
              margin: '1rem 0 0 0',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            Are you sure you want to delete this recipient from the recipient
            list?
          </p>
          <SplitUsersWrap alignVertical="flex-start">
            <div
              style={{
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  width: '100%',
                }}
              >
                <SplitAvatar>{item.name ? item.name[0] : ''}</SplitAvatar>
                <div
                  style={{
                    width: '70%',
                  }}
                >
                  <h3
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '80%',
                    }}
                  >
                    {item.name ? `${item.name}        ` : ''}
                    <span>{`.        ${item.email ? item.email : ''}`}</span>
                  </h3>
                  <p>
                    {item.accountInfo
                      ? `${item.accountInfo.bankName}        -`
                      : ''}
                    <span>{`        ${
                      item.accountInfo ? item.accountInfo.accountNumber : ''
                    }`}</span>
                  </p>
                </div>
              </div>
            </div>
          </SplitUsersWrap>
          <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
              }}
            >
              {'Cancel'}
            </button>
            <button
              onClick={() => handleAction()}
              style={{ width: '9rem', margin: '2rem 0 0 0' }}
            >
              {onProcess ? <BeatLoader color="#fff" /> : 'Continue'}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default CollabDeleteWarning;
