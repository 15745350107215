// import { ProductImg } from 'app/components/merch/productList/styles';
import AppHeader from './../../storeComp/mainContent/header/header';
import { MainWrapper } from './../../storeComp/mainContent/styles';
import {
  FlexArea,
  InfoIcons,
  InfoPart,
  // ProductInfo,
  // ProductWrap,
} from './customersCont/styles';
import { useParams } from 'react-router';

const MainContent = () => {
  type ParamsData = {
    id: any;
    email: any;
    phone: any;
    firstName: string;
    lastName: string;
  };

  const { firstName, lastName, email, phone } = useParams<ParamsData>();

  return (
    <>
      <MainWrapper>
        <AppHeader showBack={true} pageName="Audience" />
        <hr style={{ margin: '2rem 0', borderTop: '1px solid #f2f2f2' }} />
        <InfoPart>
          <FlexArea>
            <div>
              <h3>Customer</h3>
              <p>
                {firstName} {lastName}
              </p>
              <p>{email}</p>
              <p>{phone}</p>
            </div>
            <InfoIcons>
              <div>
                <a href={`tel:${phone}`}>
                  <i className="fi fi-rr-call-outgoing"></i>
                </a>
              </div>
              <div>
                <a href={`mailto:${email}`}>
                  <i className="fi fi-rr-envelope"></i>
                </a>
              </div>
            </InfoIcons>
          </FlexArea>
        </InfoPart>
        <hr style={{ margin: '2rem 0', borderTop: '1px solid #f2f2f2' }} />
        <div>
          <h3 style={{ fontSize: '1rem' }}>Products Purchased</h3>

          {/* For when API is ready to implement. */}
          {/* <ProductWrap>
            <FlexArea>
              <ProductInfo>
                <ProductImg src="/test/hoodie.png" alt="Product Image" />
                <div>
                  <p>Product Name</p>
                  <p>1 x $34,000</p>
                </div>
              </ProductInfo>
              <h3>
                $420 <i className="fas fa-angle-right"></i>
              </h3>
            </FlexArea>
          </ProductWrap> */}
          <p
            style={{
              color: '#757575',
              margin: '2rem 0 0 0 ',
              fontSize: '0.9rem',
            }}
          >
            Nothing to show yet!
          </p>
        </div>
      </MainWrapper>
    </>
  );
};

export default MainContent;
