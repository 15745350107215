import styled from 'styled-components';

export const GenericTextarea = styled.textarea`
  width: 100%;
  padding: 2% 2.5%;
  background: #ffffff;
  border: 0.1px solid #dadada;
  border-radius: 5px;
  height: 10rem;
  resize: none;
  font-family: 'Satoshi', 'sans-serif';
  font-size: 0.9rem;
  font-weight: 400;
  color: #757575;
`;

export const AddPhotoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const AddPhotoBox = styled.div`
  width: 48%;
  padding: 2%;
  display: flex;
  align-items: Center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 5px;
  height: 10rem;
  margin: 0.3rem 0;
  position: relative;

  label {
    display: flex;
    align-items: Center;
    width: 80%;
    margin: 0 auto;
    justify-content: center;
    text-align: Center;
    flex-direction: column;
    color: #757575;

    > p {
      margin: 1rem 0 0 0;
      font-size: 0.9rem;
    }

    span {
      color: #007aff;
    }
  }

  > div {
    position: absolute;
    width: 3rem;
    height: 3rem;
    background: #c82b38;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    z-index: 1000;
    opacity: 1;
    cursor: pointer;

    :hover {
      opacity: 0.7;
    }
  }
`;

export const PreviewImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
`;

interface propArgs {
  margin?: any;
}

export const BelowInputDiv = styled.div<propArgs>`
  display: flex;
  margin: ${p => p.margin || '0.3rem 0 0 0'};
  flex-wrap: wrap;

  > p {
    padding: 0.2rem 4%;
    background: #333;
    font-size: 0.7rem;
    margin: 0 4% 0.5rem 0;
    color: #ffffff;
    border-radius: 4px;
  }
`;

export const QuantityToggleWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  // right: 74%;

  @media (max-width: 1028px) {
    right: 0;
  }

  > p {
    background: #f3f3f3;
    padding: 1% 3%;
    margin: 0 4% 0 0;
  }

  > button {
    padding: 1% 3%;
    background: #191919;
    color: white;
    margin: 0 4% 0 0;
    font-size: 0.7rem;

    :hover {
      background: white;
      border: 1px solid #191919;
      color: #191919;
    }
  }
`;
