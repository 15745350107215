import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import { FloatLogOption } from './SignUp/styles';
import {
  AuthHeader,
  MainWrapper,
  FormWidget,
  MerchroLogo,
} from './Login/styles';
import { Link } from 'react-router-dom';
import PopUpModal from 'app/components/merch/popupModal';
import { FixedWrap } from './Onboarding/style';
import { BounceLoader, BeatLoader } from 'react-spinners';

const ConfirmPin = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;

  const inputStyle = {
    width: width > 728 ? '1rem' : '1rem',
    height: width > 728 ? '1rem' : '1rem',
    borderRadius: '10rem',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 8,
    display: 'flex',
    alignitems: 'Center',
    justifyContent: 'center',
  };

  let setpin = JSON.parse(localStorage.getItem('setpin') || '{}');
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [pin, setPin] = useState('');
  const [clientErr, setClientErr] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [showSettingUp, setShowSettingUp] = useState<boolean>(false);

  const handleNext = async () => {
    if (pin && pin.length === 4) {
      if (pin === setpin) {
        await axios
          .patch(
            `${process.env.REACT_APP_BASE_ENDPOINT}/users/${loggedUser.user.id}`,
            { pin },
            {
              headers: {
                Authorization: Authorization,
              },
            },
          )
          .then(res => {
            setOnProcess(false);
            setShowSettingUp(true);
            localStorage.removeItem('setpin');
            setTimeout(() => {
              navigate(`/`);
            }, 4000);
          })
          .catch(err => {
            setOnProcess(false);
            setErrorMessage(err.response.data.message);
            setPin('');
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
          });
      } else {
        setClientErr('Pin does not match!');
        setPin('');
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else {
      setClientErr('Pin must be four characters!');
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  const logOut = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <>
      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={() => setErrorMessage('')}
          msg="Operation unsuccessful"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="#c82b32"
        />
      ) : null}
      {showSettingUp ? (
        <FixedWrap>
          <div>
            <BounceLoader color={'#c7c7c7'} size={90} />
            <h3>Setting up your account</h3>
            <p>Please wait while we do some magic</p>
          </div>
        </FixedWrap>
      ) : null}
      <MainWrapper>
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/login" onClick={() => logOut()}>
              Log out
            </Link>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <div className="px-4">
            <h1
              className="text-2xl font-bold text-baseDarkk"
              style={{
                textAlign: 'center',
                fontFamily: 'Satoshi',
                fontWeight: 800,
                color: '#191919',
              }}
            >
              Confirm PIN
            </h1>
            {clientErr && (
              <p
                className="text-sm text-red-500 font-medium my-1"
                style={{
                  textAlign: 'center',
                }}
              >
                {clientErr}
              </p>
            )}
            <p
              style={{
                margin: '0 0 2rem 0',
                color: 'grey',
                fontSize: '12px',
                textAlign: 'center',
              }}
            >
              <br />
              Confirm your 4-digit pin.
            </p>
            <PinInputWrap>
              <OtpInput
                value={pin}
                onChange={code => setPin(code)}
                numInputs={4}
                inputStyle={inputStyle}
                isInputNum={true}
                shouldAutoFocus={true}
                // isInputSecure={true}
                className="otp-input"
              />
            </PinInputWrap>
            <br />
            <br />
            <Button onClick={() => handleNext()}>
              {onProcess ? <BeatLoader color="white" /> : 'Next'}
            </Button>
          </div>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

const PinInputWrap = styled.div`
  width: 10rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 0 0;
  background: #f3f3f3;
  border-radius: 35.6541px;

  @media (max-width: 728px) {
    width: 10remrea;
  }
`;

export const Button = styled.button`
  width: 100%;
  background: #191919;
  padding: 3% 2%;
  border: none;
  color: #fff;
  border-radius: 0.3rem;
  cursor: pointer;
  margin: 1rem 0 0 0;

  :hover {
    border: 1px solid #111c30;
    opacity: 0.8;
  }

  @media (max-width: 728px) {
    padding: 3% 2%;
    margin: 1rem 0 1rem 0;
  }
`;

export default ConfirmPin;
