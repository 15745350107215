import React from 'react';
import { BounceLoader } from 'react-spinners';
import styled from 'styled-components';

interface PropsArgs {
  text?: string;
}

const Loader = ({ text }: PropsArgs) => {
  return (
    <>
      <EmptyMainWrap>
        <div>
          <BounceLoader size={75} color="#757575" />
          <p>{text ? text : 'Fetching Data...'}</p>
        </div>
      </EmptyMainWrap>
    </>
  );
};

export default Loader;

export const EmptyMainWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: Center;
  margin: 6rem 0 2rem 0;

  > div {
    display: flex;
    flex-direction: column;
    align-items: Center;
    gap: 20px;
  }
`;
