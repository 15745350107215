import styled from 'styled-components';

export const FixedWrap = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  justify-content: Center;
  align-items: Center;
  z-index: 100000;

  > div {
    width: 50%;
    text-align: Center;
    display: flex;
    flex-direction: column;
    align-items: Center;

    > h3 {
      font-size: 24px;
      margin: 2rem 0 0 0;
      font-weight: 700;
    }

    > p {
      font-size: 14px;
      color: #2b2b2b;
    }

    > img {
      width: 5rem;
    }

    @media (max-width: 728px) {
      width: 80%;
    }
  }
`;
