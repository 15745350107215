import React, { useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  CloseBtn,
  Flexed,
  RightPart,
  InfoText,
  BankDetailsWrap,
  CopyBtn,
} from './style';
import Alert from './alert';
import * as Icon from 'react-feather';
import CopiedNotifier from 'app/components/reusable/copied';

const BankTransfer = ({ closeFunc, bankName, accountName, accountNo }) => {
  const [copied, setCopied] = useState(false);
  function handleCopy(text) {
    navigator.clipboard.writeText(text);
    setCopied(!copied);
    setTimeout(() => {
      setCopied(false);
    }, 7000);
  }

  // Success Response and Error Management

  const [sucess, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const closeModal = () => {
    setSuccess(false);
    setError(false);
  };

  // End of Success Response and Error Management

  // This is the configuration section for the flutterwave integration when we are ready for card payment

  // const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  // const public_key = `${process.env.REACT_APP_FLUTTERWAVE_KEY}`;

  // const config = {
  //   public_key: public_key,
  //   tx_ref: Date.now().toString(),
  //   amount: amount,
  //   currency: 'NGN',
  //   payment_options: 'card',
  //   customer: {
  //     email: loggedUser.user.email,
  //     phonenumber: loggedUser.user.phoneNumber,
  //     name: `${loggedUser.user.firstName} ${loggedUser.user.lastName}`,
  //   },
  //   customizations: {
  //     title: 'Cash Transfer',
  //     description: '',
  //     logo: 'https://www.merchro.com/logo-black.png',
  //   },
  // };

  // const handleFlutterPayment = useFlutterwave(config);

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Add by Transfer</Header>
          <RightPart>
            <InfoText>
              <p>
                Use the details below to send money to your Mage account from
                any bank’s app through internet banking.
              </p>
            </InfoText>
            <BankDetailsWrap>
              <p>Bank</p>
              <h3>{bankName}</h3>
            </BankDetailsWrap>
            <BankDetailsWrap>
              <p>Acount Name</p>
              <h3>{accountName}</h3>
            </BankDetailsWrap>
            <BankDetailsWrap>
              <p>Account number</p>
              <Flexed>
                <h3>{accountNo}</h3>
                <CopyBtn onClick={() => handleCopy(accountNo)}>Copy</CopyBtn>
              </Flexed>
            </BankDetailsWrap>

            {/* The commented code below is for when the card payment is allowed */}

            {/* <hr /> */}
            {/* <h1
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                margin: '1rem 0',
              }}
            >
              Or{' '}
            </h1>
            <BigBtn>
              <button
                onClick={() => {
                  handleFlutterPayment({
                    callback: response => {
                      closePaymentModal(); // this will close the modal programmatically
                      if (response.status === 'successful') {
                        setSuccess(true);
                        setTimeout(() => {
                          setSuccess(false);
                          navigate('/');
                        }, 5000);
                      } else {
                        setError(true);
                        setTimeout(() => {
                          setError(false);
                        }, 5000);
                      }
                    },
                    onClose: () => {
                      navigate('/');
                    },
                  });
                }}
              >
                Pay with card
              </button>
            </BigBtn> */}
          </RightPart>
        </ModalChild>
        {copied ? <CopiedNotifier /> : null}
      </ModalArea>

      {sucess ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Transaction successful!"
          miniMessage="You have successfully funded your mage account."
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occured"
          miniMessage="An Error occured while funding your account. Please try again!"
        />
      ) : null}
    </>
  );
};

export default BankTransfer;
