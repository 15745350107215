import { MainWrapper } from './../../storeComp/mainContent/styles';
import PreferenceCont from './preferenceContent/preferenceCont';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';

const MainContent = () => {
  return (
    <>
      <MainWrapper left="0" style={{ width: '100%' }}>
        <FragmentHeader showBack={true} pageName="My Store" />
        <PreferenceCont />
      </MainWrapper>
    </>
  );
};

export default MainContent;
