import React from 'react';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';
import MainContent from 'app/components/Benefits';

const CreateBenefits = () => {
  return (
    <>
      <MainWrapper
        style={{
          background: '#fff',
        }}
      >
        <MainContent />
      </MainWrapper>
    </>
  );
};

export default CreateBenefits;
