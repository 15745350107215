import styled from 'styled-components';

export const MainArea = styled.div`
  position: relative;
  margin: 4rem 0 0 0;

  @media (max-width: 1028px) {
    margin: 2rem 0 0 0;
  }
`;

export const BoxCard = styled.div`
  padding: 0.7rem 0.8rem;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin: 0rem 0 1rem 0;

  :hover {
    background: #191919;
    color: #ffffff;
  }

  :hover h3 {
    color: white;
  }

  :hover div:nth-of-type[1] {
    border: 1px solid #ffffff;
  }

  :hover p {
    color: #ffffff;
  }

  h3 {
    color: #000000;
    font-weight: 700;
  }

  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
  }
`;

export const BoxFlex = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
`;

export const UserInfoTextWrap = styled.div`
  width: 90%;

  @media (max-width: 1028px) {
    width: 75%;
  }
`;

export const UserAvatar = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: Center;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const HLine = styled.hr`
  margin: 1rem 0;
`;

export const NavSection = styled.div`
  margin: 5rem 0 2rem 0;
`;
