import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../invoice/mainInvoice/style';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from './../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import {
  InputWrap,
  BigBtn,
  SubHeader,
  SubHeaderChild,
} from './../dashboard/mainContent/fundWallet/style';
import Select from 'react-select';
import axios from 'axios';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import { ClipLoader } from 'react-spinners';
import EnterPin from './../dashboard/mainContent/fundWallet/enterPin';
import uuid from 'react-uuid';
import CommaNumber from 'comma-number';
import { numOnly } from 'utils/numOnly';
import { CustomStyles } from 'utils/selectCustomStyle';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { roundTo } from 'utils/roundFloat';
import { getRequest } from 'utils/request';

const NGNTransferComp = () => {
  const { amount, sourceAmount, point } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const currencyPair = searchParams.get('currencyPair');
  const exchangeType = searchParams.get('exchangeType');

  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [balance, setBalance] = useState<any>('');
  const [selectedBank, setSelectedBank] = useState<string>('');
  const [selectedBankId, setSelectedBankId] = useState<string>('');
  const [purpose, setPurpose] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountName, setAccountName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [clientErr, setClientErr] = useState('');
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  const [banks, setBanks] = useState<any>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/bank-list?`, {
        headers: { Authorization },
      })
      .then(res => {
        setBanks(res.data);
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [Authorization, navigate]);

  // Get Balance
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/account-info?`, {
        headers: { Authorization },
      })
      .then(resp => {
        // setUserAccount(resp.data)
        setBalance(resp.data.balance.naira);
        localStorage.setItem('accountInfo', JSON.stringify(resp.data));
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [Authorization, navigate]);

  // Collect Information from Inputs
  const handleInput = (e, setParam, amount: boolean = false) => {
    if (amount) {
      let value = e.target.value;
      if (value.toString().indexOf('0') === 0) value = Number(value).toString();
      setParam(value);
    } else setParam(e.target.value);
  };
  // Collect Frequent Beneficiary if exists
  const freqData: any = JSON.parse(
    localStorage.getItem('selectedFreqBen') || '{}',
  );

  useEffect(() => {
    if (Object.keys(freqData).length > 0) {
      setAccountName(freqData.accountName);
      setSelectedBankId(freqData.bankId);
      setSelectedBank(freqData.bankName);
      setAccountNumber(freqData.accountNumber);
      localStorage.removeItem('selectedFreqBen');
    }
  }, [freqData]);

  // Payment Parameters
  const paymentParams: any = {
    accountName,
    bankName: selectedBank,
    bankId: selectedBankId,
    accountNumber: accountNumber,
    purpose: purpose,
    targetAmount: `${amount}`,
    idempotentKey: uuid(),
    sourceAmount: sourceAmount,
    sourceCurrency: point,
    targetCurrency: 'NGN',
    recipientType: 'ngn-account',
  };

  // Enter Pin for transaction

  // Get User Data from Database
  const [pin, setPin] = useState<any>('');

  // Set Pin
  const handlePinChange = pin => {
    setPin(pin);
  };

  const [pinModal, setPinModal] = useState(false);

  const handleClosePinModal = () => {
    setPinModal(false);
  };

  // Read and Write User to Beneficiaries Function
  const [addToFB, setAddToFB] = useState<boolean>(false);
  const [beneficiaryData, setBeneficiaryData] = useState<any>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    getRequest(`payments/beneficiaries?paginate=true&limit=80`)
      .then(res => {
        setBeneficiaryData(res.results);
      })
      .catch(err => {});
  }, [Authorization]);

  const beneficiariesPayload = {
    bankId: selectedBankId,
    bankName: selectedBank,
    accountNumber,
    accountName,
    currency: 'NGN',
  };

  const handleAddBeneficiaries = () => {
    if (addToFB) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/beneficiaries`,
          beneficiariesPayload,
          headers,
        )
        .then(res => {})
        .catch(err => {});
    }
  };

  // Check accountNUmber in Beneficiary Data
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    if (accountNumber.length === 10) {
      if (beneficiaryData && beneficiaryData.length > 0) {
        for (let i = 0; i < beneficiaryData.length; i++) {
          if (beneficiaryData[i].accountNumber.includes(accountNumber)) {
            setIsExist(true);
            break;
          } else {
            setIsExist(false);
          }
        }
      }
    }
  }, [accountNumber, beneficiaryData]);

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [result, setResult] = useState<boolean | string>(false);

  const closeMessage = () => {
    setResult(false);
  };

  const [finalOnProcess, setFinalOnProcess] = useState(false);
  const handleFinalPay = () => {
    if (currencyPair !== 'null') {
      paymentParams['currencyPair'] = currencyPair;
    }

    if (exchangeType !== 'null') {
      paymentParams['exchangeType'] = exchangeType;
    }

    setFinalOnProcess(true);
    handleAddBeneficiaries();
    if (pin && pin.length === 4) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/auth/confirm-pin`,
          {
            pin,
            userId: loggedUser.user.id,
          },
          headers,
        )
        .then(res => {
          setPin('');
          if (res.data.status) {
            axios
              .post(
                `${process.env.REACT_APP_BASE_ENDPOINT}/payments/withdraw`,
                paymentParams,
                headers,
              )
              .then(res => {
                setFinalOnProcess(false);
                setSuccess(true);
                localStorage.removeItem('selectedFreqBen');
              })
              .catch(err => {
                setFinalOnProcess(false);
                setClientErr(err.response.data.message);
                setTimeout(() => {
                  setClientErr('');
                }, 4000);
              });
          } else {
            setErrorMessage("We couldn't confirm transaction pin");
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
            setFinalOnProcess(false);
          }
        })
        .catch(err => {
          setFinalOnProcess(false);
          setErrorMessage(err.response.data.message);
          setPin('');
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('Transaction pin is not correct!');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
      setPin('');
      setFinalOnProcess(false);
    }
  };

  const handlePayment = async () => {
    setOnProcess(true);
    if (Number(amount) > 0) {
      if (Number(amount) && Number(amount) <= balance) {
        if (selectedBankId !== '' && accountNumber !== '' && purpose !== '') {
          if (accountName !== '') {
            setPinModal(true);
            setOnProcess(false);
          } else {
            setClientErr(`Account name can't be empty!`);
            setOnProcess(false);
            setTimeout(() => {
              setClientErr('');
            }, 2000);
          }
        } else {
          setClientErr('Please fill all input fields!');
          setOnProcess(false);
          setTimeout(() => {
            setClientErr('');
          }, 2000);
        }
      } else {
        setOnProcess(false);
        setClientErr(
          'You do not have sufficient balance to make this transaction',
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else {
      setOnProcess(false);
      setClientErr(`Amount can't be empty and must be greater than 0`);
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  const closeModal = () => {
    setError('');
  };

  const closeSuccess = () => {
    setSuccess(false);
    localStorage.removeItem('selectedFreqBen');
    setPinModal(false);
    navigate('/');
  };

  // Account Validation
  const ValidateParams = {
    bankId: selectedBankId,
    accountNumber: accountNumber,
  };

  useEffect(() => {
    if (pin.length === 4) handleFinalPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const [nameProcess, setNameProcess] = useState(false);

  const handleGetAccName = e => {
    if (
      selectedBankId &&
      accountNumber &&
      selectedBankId.length > 0 &&
      accountNumber.length === 10
    ) {
      setAccountName('');
      setNameProcess(true);
      setTimeout(() => {
        setNameProcess(false);
      }, 15000);
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/validate-account`,
          ValidateParams,
          headers,
        )
        .then(res => {
          setAccountName(res.data.accountName);
          setNameProcess(false);
          setNameError(false);
        })
        .catch(err => {
          if (err.response.data.code === 400) {
            setNameProcess(false);
            setNameError(true);
            setTimeout(() => {
              setNameError(false);
            }, 6000);
          }
        });
    }
  };
  return (
    <>
      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
      >
        <FragmentHeader showBack={true} pageName="Send Money" />
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              top="-2rem"
              mobileTop="-5rem"
              style={{ margin: '0 auto' }}
            >
              <SubHeader>
                <SubHeaderChild customBd="#191919">
                  Bank account details
                </SubHeaderChild>
                <SubHeaderChild
                  customBd=""
                  onClick={() =>
                    navigate(
                      `/beneficiaries/${point}/${sourceAmount}/${amount}?exchangeType=${exchangeType}&currencyPair=${currencyPair}`,
                    )
                  }
                >
                  Beneficiaries
                </SubHeaderChild>
              </SubHeader>
              <div>
                <InputWrap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label>Amount - &#8358;{CommaNumber(amount)}</label>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        letterSpacing: '-0.02em',
                        color: '#ABABAB',
                      }}
                    >
                      Balance: &#8358;{CommaNumber(roundTo(balance, 2))}
                    </p>
                  </div>
                </InputWrap>
                <>
                  <InputWrap>
                    <label>Bank</label>
                    <Select
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.uuid}
                      options={banks}
                      placeholder="Search..."
                      styles={CustomStyles}
                      isSearchable
                      value={banks.filter(
                        option => option.name === selectedBank,
                      )}
                      onChange={(e: any) => {
                        setSelectedBankId((e as any).uuid);
                        setSelectedBank((e as any).name);
                      }}
                    />
                  </InputWrap>
                  <InputWrap>
                    <label>Account number</label>
                    <input
                      type="number"
                      placeholder="Account number"
                      maxLength={10}
                      value={accountNumber}
                      onKeyPress={e => numOnly(e)}
                      onChange={e => {
                        handleInput(e, setAccountNumber);
                        if (e.target.value.length < 10) {
                          setAccountName('');
                          setIsExist(false);
                        }
                      }}
                      onKeyUp={e => handleGetAccName(e)}
                    />
                  </InputWrap>
                  {nameProcess ? (
                    <ClipLoader size={15} />
                  ) : (
                    <>
                      {accountName !== '' ? (
                        <p style={{ color: '#c82b38', fontSize: '0.8rem' }}>
                          {accountName}
                        </p>
                      ) : null}

                      {nameError ? (
                        <p style={{ color: '#c82b38', fontSize: '0.8rem' }}>
                          Account not found!
                        </p>
                      ) : null}
                    </>
                  )}
                </>
                {/* )} */}

                <InputWrap>
                  <label>Purpose</label>
                  <input
                    type="text"
                    placeholder="Purpose"
                    onChange={e => handleInput(e, setPurpose)}
                  />
                </InputWrap>

                {/* This is for adding beneficiary for payments which would be integrated very soon. */}
                {!isExist ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={e => setAddToFB(e.target.checked)}
                    />
                    <label
                      style={{
                        color: '#474747',
                        margin: '0 0 0 0.5rem',
                        fontSize: '0.8rem',
                      }}
                    >
                      Add to Beneficiary
                    </label>
                  </div>
                ) : null}
              </div>
              <p style={{ width: '20rem', fontSize: '0.8rem', color: 'red' }}>
                {clientErr}
              </p>
              <BigBtn>
                <button
                  onClick={e => handlePayment()}
                  disabled={onProcess ? true : false}
                >
                  {onProcess ? <ClipLoader color="#fff" /> : 'Send Money'}
                </button>
              </BigBtn>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>
      </MainWrapper>

      {pinModal && !success ? (
        <EnterPin
          closeFunc={handleClosePinModal}
          prevFunc={handleClosePinModal}
          propPin={pin}
          errorMessage={errorMessage}
          handlePinChange={handlePinChange}
          handleFinalPay={handleFinalPay}
          onProcess={finalOnProcess}
          bank={selectedBank}
          accountName={accountName}
          accountNo={accountNumber}
          amount={amount}
          mode="ngn"
          currency={'NGN'}
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="Transaction Unsuccessful!!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeSuccess}
          img="/success.png"
          message="Transaction successful"
          miniMessage={'Funds are on the way to the recipient. '}
        />
      ) : null}

      {result === '' ? (
        <Alert
          closeFunc={closeMessage}
          img="/failed.png"
          message="Changes not successful!"
          miniMessage="Encountered an error while creating your transaction PIN"
        />
      ) : null}
    </>
  );
};

export default NGNTransferComp;
