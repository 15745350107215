import React from 'react';
import {
  CollabSuggestWrap,
  FixedRightBottom,
  SplitUsersWrap,
  SplitAvatar,
} from './style';
import { FlexedApart } from '../storeComp/sideNav/style';
import { BlueText } from '../editProfile/style';
import { TicketInput } from '../paymentLink/style';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';

const SelectCollaborator = ({
  closeFunc,
  handleInputFocus,
  setOpenAdd,
  handleSearch,
  collabsModified,
  setShowEditModal,
  setSelectedRecipient,
  setShowDeleteCollabWarning,
  pushCollaborator,
  openCreate,
  addCollabToWait,
}) => {
  return (
    <FixedRightBottom onClick={() => closeFunc()}>
      <div onClick={e => e.stopPropagation()}>
        <CollabSuggestWrap
          style={{
            position: 'relative',
            left: '0%',
            margin: 0,
          }}
        >
          <FlexedApart
            style={{
              position: 'relative',
              top: '0',
              width: '100%',
              border: 'none',
              padding: '0 0 15px 0',
            }}
          >
            <h2>
              <b>Add collaborators</b>
            </h2>
            <BlueText
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                cursor: 'pointer',
                margin: '1rem 0',
              }}
              onClick={() => {
                openCreate(true);
                closeFunc();
              }}
            >
              <Icon.Plus size={20} style={{ margin: '0 0.5rem 0 0' }} />
              New Collaborator
            </BlueText>
          </FlexedApart>
          <TicketInput>
            <div
              style={{
                width: '100%',
              }}
            >
              <p>Search</p>
              <input
                onClick={e => {
                  e.stopPropagation();
                  handleInputFocus();
                }}
                onChange={e => handleSearch(e)}
              />
            </div>
          </TicketInput>
          <div
            style={{
              position: 'relative',
              top: 0,
              maxHeight: '65vh',
              width: '100%',
            }}
          >
            <BlueText
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '13px',
                cursor: 'pointer',
                margin: '1rem 0',
              }}
              onClick={() => {
                openCreate(true);
                closeFunc();
              }}
            >
              <Icon.Plus size={20} style={{ margin: '0 0.5rem 0 0' }} />
              New Collaborator
            </BlueText>
            {collabsModified && collabsModified.length > 0 ? (
              collabsModified.map((item, index) => (
                <SplitUsersWrap
                  alignVertical="flex-start"
                  key={index}
                  style={{
                    cursor: 'pointer',
                    padding: '0.8rem 3%',
                    width: '100%',
                  }}
                  onClick={() => {
                    if (!item.added) {
                      pushCollaborator(item);
                    }
                    addCollabToWait(index);
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        // justifyContent: 'space-between',
                        gap: '10px',
                        width: '100%',
                      }}
                    >
                      <SplitAvatar>
                        {item.name ? item.name[0] : item.email[0]}
                      </SplitAvatar>
                      <div
                        style={{
                          width: '70%',
                        }}
                      >
                        <h3
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            width: '95%',
                          }}
                        >
                          {item.name ? `${item.name}` : ''}
                          <span>{`${
                            item.email && item.name ? item.email : ''
                          }`}</span>
                        </h3>
                        <h3>{!item.name && item.email ? item.email : null}</h3>
                        <p>
                          {item.accountInfo.bankName
                            ? `${item.accountInfo.bankName}        -`
                            : ''}
                          <span>{`        ${
                            item.accountInfo.accountNumber
                              ? item.accountInfo.accountNumber
                              : ''
                          }`}</span>
                        </p>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          gap: '10px',
                          width: '12%',
                        }}
                      >
                        <IconSax.TickCircle
                          variant="Bold"
                          color={item.added ? '#34A853' : '#dadada'}
                        />
                      </div>
                    </div>
                  </div>
                </SplitUsersWrap>
              ))
            ) : (
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '14px',
                  color: '#757575',
                  margin: '1rem 0 0 0',
                }}
              >
                No Option.
              </p>
            )}
          </div>
        </CollabSuggestWrap>
      </div>
    </FixedRightBottom>
  );
};

export default SelectCollaborator;
