import { BreadcrumbArea } from 'app/components/orderComp/mainContent/orderContent/styles';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  EachPreferenceBlock,
  GenericInput,
  Important,
  LeftPart,
  MiddlePart,
  MiniHeader,
  PreferenceBlock,
  RightPart,
  SaveButtonWrap,
} from '../../Preference/mainContent/preferenceContent/styles';
import { MainWrapper } from '../../storeComp/mainContent/styles';
import {
  AddPhotoBox,
  AddPhotoWrapper,
  BelowInputDiv,
  GenericTextarea,
  PreviewImg,
  QuantityToggleWrap,
} from '../addMerch/styles';
import PopUpModal from '../popupModal';
import { BeatLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import { preOptions, durations } from '../addMerch/addMerchComp';
import { useNavigate } from 'react-router';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { handleFileUpload } from 'utils/imageUpload';

const EditMerchComp = () => {
  const navigate = useNavigate();

  const [itemName, setItemName] = useState<any>('');
  const [response, setResponse] = useState<any>(false);
  const [description, setDescription] = useState<string>('');
  const [images, setImages] = useState<any>([]);
  const [returnedImages, setReturnedImages] = useState<any>([]);
  const [price, setPrice] = useState<any>(0);
  const [quantity, setQuantity] = useState<any>();
  const [prodDuration, setProdDuration] = useState<any>('');
  const [maximumLimit, setMaximumLimit] = useState<any>(0);

  // Pre-order Functionalities

  const [enabledPre, setEnabledPre] = useState<any>(false);

  const handleTogglePre = e => {
    setEnabledPre((e as HTMLSelectElement).value);
  };

  const IncrementQuantity = () => {
    if (quantity >= 0) {
      setQuantity(quantity + 1);
    }
  };

  function DecrementQuantity() {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  }

  var creator = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const [sizes, setSizes] = useState<any>([]);
  const [colours, setColours] = useState<any>([]);

  const APIObject = {
    name: itemName,
    quantity: quantity,
    description: description,
    price: {
      currency: 'NGN',
      amount: price,
    },
    cost: {
      currency: 'NGN',
      amount: price,
    },
    metadata: {
      sizes: sizes,
      colors: colours,
    },
    images: returnedImages,
    preOrder: {
      enabled: enabledPre,
      maxNumOfPreOrders: maximumLimit,
    },
  };

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${creator.tokens.access.token}`,
      mode: 'cors',
    },
  };

  const selectedItem = JSON.parse(localStorage.getItem('editItem') || '{}');

  useEffect(() => {
    setQuantity(selectedItem.quantity);

    // eslint-disable-line react-hooks/exhaustive-deps
  }, [selectedItem.quantity]);

  useEffect(() => {
    const selectedItem = JSON.parse(localStorage.getItem('editItem') || '{}');
    if (selectedItem.preOrder) {
      setEnabledPre(selectedItem.preOrder.enabled);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedItem = JSON.parse(localStorage.getItem('editItem') || '{}');

    if (selectedItem.preOrder) {
      setMaximumLimit(selectedItem.preOrder.maxNumOfPreOrders);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedItem = JSON.parse(localStorage.getItem('editItem') || '{}');
    if (selectedItem.preOrder) {
      setProdDuration(selectedItem.preOrder.productionDuration);
    }

    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setItemName(selectedItem.name);

    // eslint-disable-line react-hooks/exhaustive-deps
  }, [selectedItem.name]);

  useEffect(() => {
    const selectedItem = JSON.parse(localStorage.getItem('editItem') || '{}');
    let item: any = [];

    if (selectedItem.images && selectedItem.images.length > 0) {
      for (let i = 0; i < selectedItem.images.length; i++) {
        item.push(selectedItem.images[i]);
      }
      setReturnedImages(item);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDescription(selectedItem.description);

    // eslint-disable-line react-hooks/exhaustive-deps
  }, [selectedItem.description]);

  useEffect(() => {
    setPrice(selectedItem.price.amount);

    // eslint-disable-line react-hooks/exhaustive-deps
  }, [selectedItem.price.amount]);

  let imageUrls: any = [...returnedImages];

  const extraImageFunc = data => {
    imageUrls.push(data);
  };

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setError: setErrorMessage,
      setReturnedImages,
      setImages,
      extraFunc: extraImageFunc,
    });
  };

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleEdit = () => {
    if (prodDuration !== '') {
      APIObject.preOrder['productionDuration'] = prodDuration;
    }

    if (itemName !== '' && price > 0) {
      if (returnedImages && returnedImages.length > 0) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 15000);
        const data = JSON.stringify(APIObject);
        axios
          .patch(
            process.env.REACT_APP_BASE_ENDPOINT + '/merches/' + selectedItem.id,
            data,
            option,
          )
          .then(res => {
            setLoading(false);
            setResponse(true);
            setTimeout(() => {
              setResponse(false);
              navigate('/product-list');
            }, 4000);
          })
          .catch(err => {
            setErrorMessage(err.response.data.message);
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
            setLoading(false);
          });
      } else {
        setErrorMessage('Please add at least one image!!!');
        setTimeout(() => {
          setErrorMessage('');
        }, 4000);
      }
    } else {
      setErrorMessage('Please fill all required fields!');
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  // Remove Image

  const handleRemoveImage = async (index: number) => {
    const mockArray: any = [];

    for (let i = 0; i < returnedImages.length; i++) {
      if (i !== index) {
        mockArray.push(returnedImages[i]);
      }
    }

    await setReturnedImages(mockArray);
  };

  const handleClose = () => {
    setResponse(false);
    setErrorMessage('');
  };

  const handleSizes = (e: any) => {
    if (e.key === 'Enter' && sizes.length < 5) {
      var joined = sizes.concat(e.target.value.toLowerCase());
      setSizes(joined);
      e.target.value = '';
    }
  };

  const handleColor = (e: any) => {
    if (e.key === 'Enter' && colours.length < 5) {
      var joined = colours.concat(e.target.value.toLowerCase());
      setColours(joined);
      e.target.value = '';
    }
  };

  const handleRemoveColor = selected => {
    colours.splice(selected, 1);
    setColours([...colours]);
  };

  const handleRemoveSizes = selected => {
    sizes.splice(selected, 1);
    setSizes([...sizes]);
  };

  function dropHandler(ev) {
    ev.preventDefault();

    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          let mockData: any = [];
          const file = item.getAsFile();
          mockData.push(file);
          handleFile(mockData);
        }
      });
    } else {
      [...ev.dataTransfer.files].forEach((file, i) => {
        handleFile(file);
      });
    }
  }

  function dragOverHandler(ev) {
    ev.preventDefault();
  }

  return (
    <>
      <MainWrapper left="9%">
        <FragmentHeader showBack={true} pageName="Edit Product" />
        {response ? (
          <PopUpModal
            handleClose={handleClose}
            msg="Your Merch has been edited sucessfully"
            extraMsg="Changes to your merch have been made."
            icon="fas fa-check"
            iconColor="green"
          />
        ) : null}

        {errorMessage !== '' ? (
          <PopUpModal
            handleClose={handleClose}
            msg="An Error occured!"
            extraMsg={errorMessage}
            icon="fas fa-exclamation"
            iconColor="red"
          />
        ) : null}

        <BreadcrumbArea>
          <MiniHeader>
            <h2>Edit Product</h2>
            <p>Make some changes to your existing Product</p>
          </MiniHeader>
          <PreferenceBlock>
            {/* Add Photos */}
            <EachPreferenceBlock align="flex-start">
              <LeftPart>
                Product images<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <AddPhotoWrapper>
                  {images.length < 4 ? (
                    <AddPhotoBox
                      id="drop_zone"
                      onDrop={e => dropHandler(e)}
                      onDragOver={e => dragOverHandler(e)}
                    >
                      <label htmlFor="main-input">
                        <Icon.Image color="#757575" />
                        <p>
                          Drop your image here or <span>upload</span>
                        </p>
                      </label>

                      <input
                        id="main-input"
                        type="file"
                        multiple={false}
                        style={{ display: 'none' }}
                        onChange={e => {
                          handleFile(e.target.files);
                        }}
                      ></input>
                    </AddPhotoBox>
                  ) : null}

                  {images && returnedImages.length < 1
                    ? images.map((item, index) => (
                        <AddPhotoBox key={index}>
                          <div onClick={() => handleRemoveImage(index)}>
                            <Icon.Trash2 color="#fff" />
                          </div>
                          <PreviewImg src={URL.createObjectURL(item)} />
                        </AddPhotoBox>
                      ))
                    : null}

                  {returnedImages && returnedImages.length > 0
                    ? returnedImages.map((item, index) => (
                        <AddPhotoBox key={index}>
                          <div onClick={() => handleRemoveImage(index)}>
                            <Icon.Trash2 color="#fff" />
                          </div>
                          <PreviewImg src={item} />
                        </AddPhotoBox>
                      ))
                    : null}
                </AddPhotoWrapper>
              </MiddlePart>
              <RightPart>
                {returnedImages.length > 0 ? (
                  <>
                    {returnedImages.length < 4 && returnedImages.length > 0
                      ? `
                          You have added ${
                            returnedImages.length
                          } image, you can still add ${
                          4 - returnedImages.length
                        } image. Pay attention to the quality of the images you add
                        `
                      : 'You have added maximum number of images. '}
                  </>
                ) : (
                  'You can add up to 4 images. Pay attention to the quality of the images you add'
                )}
              </RightPart>
            </EachPreferenceBlock>

            <EachPreferenceBlock align="flex-start">
              <LeftPart>
                Product name<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <GenericInput
                  placeholder="Item Name"
                  defaultValue={selectedItem.name}
                  maxLength={40}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                    setItemName(ev.target.value)
                  }
                />
              </MiddlePart>
              <RightPart>Do not exceed 20 characters.</RightPart>
            </EachPreferenceBlock>

            {/* Description */}

            <EachPreferenceBlock align="flex-start">
              <LeftPart>Description</LeftPart>
              <MiddlePart>
                <GenericTextarea
                  placeholder="Item Description"
                  maxLength={300}
                  defaultValue={selectedItem.description}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void =>
                    setDescription(e.target.value)
                  }
                ></GenericTextarea>
              </MiddlePart>
              <RightPart>Do not exceed 100 characters.</RightPart>
            </EachPreferenceBlock>

            {/* Size Value */}
            <EachPreferenceBlock align="flex-start">
              <LeftPart>Size (Value)</LeftPart>
              <MiddlePart>
                <GenericInput
                  type="text"
                  placeholder="Choose a scale"
                  onKeyUp={e => handleSizes(e)}
                />
                <BelowInputDiv>
                  {sizes.length !== 0
                    ? sizes.map((item, index) => (
                        <p onClick={() => handleRemoveSizes(index)} key={index}>
                          {item}
                        </p>
                      ))
                    : null}
                </BelowInputDiv>
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>
            {/* Size Value */}
            <EachPreferenceBlock align="flex-start">
              <LeftPart>Colours</LeftPart>
              <MiddlePart>
                <GenericInput
                  type="text"
                  placeholder="Select availabale colours for your merch."
                  onKeyUp={e => handleColor(e)}
                />
                <BelowInputDiv>
                  {colours.length !== 0
                    ? colours.map((item, index) => (
                        <p onClick={() => handleRemoveColor(index)} key={index}>
                          {item}
                        </p>
                      ))
                    : null}
                </BelowInputDiv>
              </MiddlePart>
              <RightPart>
                Please use color codes for extensive colors.
              </RightPart>
            </EachPreferenceBlock>

            {/* Price  */}
            <EachPreferenceBlock align="flex-start">
              <LeftPart>
                Price<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <GenericInput
                  type="number"
                  placeholder="N"
                  defaultValue={selectedItem.price.amount}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>): void =>
                    setPrice(ev.target.value)
                  }
                />
              </MiddlePart>
              <RightPart>Remember to factor in production costs.</RightPart>
            </EachPreferenceBlock>

            {/* Quantity  */}
            <EachPreferenceBlock align="flex-start">
              <LeftPart>
                Quantity<Important>*</Important>
              </LeftPart>
              <MiddlePart>
                <QuantityToggleWrap>
                  <button onClick={e => DecrementQuantity()}>
                    <i className="fas fa-minus"></i>
                  </button>
                  <input
                    type="number"
                    placeholder="0"
                    value={quantity}
                    style={{
                      width: '6rem',
                      textAlign: 'center',
                      margin: '0 1rem 0 0',
                    }}
                    onChange={e => setQuantity(e.target.value)}
                  />
                  <button onClick={e => IncrementQuantity()}>
                    <i className="fas fa-plus"></i>
                  </button>
                </QuantityToggleWrap>
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>

            {/* Pre Order options  */}
            <EachPreferenceBlock align="center">
              <LeftPart>
                Enable pre-order<Important></Important>
              </LeftPart>
              <MiddlePart>
                <Select
                  options={preOptions}
                  getOptionLabel={p => p.name}
                  value={preOptions.filter(p => p.value === enabledPre)}
                  onChange={e => handleTogglePre(e)}
                />
              </MiddlePart>
              <RightPart></RightPart>
            </EachPreferenceBlock>

            {enabledPre ? (
              <>
                <EachPreferenceBlock align="center">
                  <LeftPart
                    style={
                      enabledPre
                        ? { color: '#000' }
                        : { color: 'rgba(71, 71, 71, 0.5)' }
                    }
                  >
                    Maximum pre-orders<Important></Important>
                  </LeftPart>
                  <MiddlePart>
                    <GenericInput
                      placeholder={'1'}
                      disabled={enabledPre ? false : true}
                      type="number"
                      defaultValue={maximumLimit}
                      onChange={e => setMaximumLimit(e.target.value)}
                    />
                  </MiddlePart>
                  <RightPart></RightPart>
                </EachPreferenceBlock>

                <EachPreferenceBlock align="center">
                  <LeftPart
                    style={
                      enabledPre
                        ? { color: '#000' }
                        : { color: 'rgba(71, 71, 71, 0.5)' }
                    }
                  >
                    Production duration<Important></Important>
                  </LeftPart>
                  <MiddlePart>
                    <Select
                      options={durations}
                      value={durations.filter(p => p.value === prodDuration)}
                      getOptionLabel={p => p.name}
                      isDisabled={enabledPre ? false : true}
                      onChange={(e: any) =>
                        setProdDuration((e as HTMLSelectElement).value)
                      }
                    />
                  </MiddlePart>
                  <RightPart></RightPart>
                </EachPreferenceBlock>
              </>
            ) : null}
          </PreferenceBlock>

          {/* Save  */}
          <SaveButtonWrap>
            <button onClick={() => handleEdit()}>
              {loading ? (
                <BeatLoader size={20} color={'black'} />
              ) : (
                'Save & Continue'
              )}
            </button>
          </SaveButtonWrap>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default EditMerchComp;
