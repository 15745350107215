import React, { useState, useEffect } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from '.././transactions/style';
import { CardFlex, InfoCard } from '../dashboard/mainContent/style';
import { SearchArea } from '../orderComp/mainContent/sectionOne/styles';
import { FlexApart } from '../invoice/mainInvoice/preview-style';
import moment from 'moment';
import {
  FlexArea,
  ProductInfo,
  ProductWrap,
} from './../customers/mainContent/customersCont/styles';
import { UserAvatar } from '../storeComp/sideNav/style';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';

const MainContent = () => {
  const selTicket = JSON.parse(localStorage.getItem('selticket') || '{}');
  const store = JSON.parse(localStorage.getItem('store') || '{}');

  const [tickets, setTickets] = useState<any>([]);

  useEffect(() => {
    const selTicket = JSON.parse(localStorage.getItem('selticket') || '{}');
    if (selTicket.eventPayment) setTickets(selTicket.eventPayment.tickets);
  }, []);

  // Get Purchases

  const [purchases, setPurchases] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalTicketSold, setTotalTicketSold] = useState(0);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    fetch(
      `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/payment-link/${selTicket.paymentCode}/purchased?sortBy=createdAtDesc`,
      {
        headers: { Authorization },
      },
    )
      .then(res => {
        res.json().then(resp => {
          setPurchases(resp.peopleThatPaid);
          setTotalAmount(resp.totalAmountMadeFromSales);
          setTotalTicketSold(resp.totalTicketsSold);
        });
      })
      .catch(err => {});
  }, [selTicket.paymentCode]);

  return (
    <>
      <MainWrapper
        left="9%"
        top="2rem"
        mobileTop="3rem"
        style={{ background: '#fff' }}
      >
        <FragmentHeader
          showBack={true}
          pageName={`${selTicket.pageName}`}
          backLink="/events"
        />
        <SearchPos position="fixed">
          <SearchArea>
            <i className="fi fi-rr-search"></i>
            <input type="text" placeholder="Search" />
          </SearchArea>
        </SearchPos>
        <BreadcrumbArea>
          <CardFlex
            style={{ margin: '2rem 0 0 0' }}
            display="flex"
            padding="1rem"
          >
            <div>
              <InfoCard customBg="#fff">
                <h3>{selTicket.pageName}</h3>
                <p
                  style={{
                    textDecoration: 'underline',
                    letterSpacing: '-0.02em',
                    color: '#757575',
                    fontSize: '14px',
                    margin: '0.7rem 0 0 0',
                  }}
                >
                  <a
                    href={`https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/p/${selTicket.slug}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {`https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/p/${selTicket.slug}`}
                  </a>
                </p>
                <p
                  style={{
                    letterSpacing: '-0.02em',
                    color: '#757575',
                    fontSize: '14px',
                    margin: '0.7rem 0 0 0',
                  }}
                >
                  {moment(selTicket.updatedAt).format('LLL')}
                </p>
                <h3
                  style={{
                    margin: '0.7rem 0 0 0',
                    fontSize: '14px',
                  }}
                >
                  Total Amount Recieved: {totalAmount}
                </h3>
                <h3
                  style={{
                    margin: '0.7rem 0 0 0',
                    fontSize: '14px',
                  }}
                >
                  Total Tickets Sold: {totalTicketSold}
                </h3>
              </InfoCard>
              <InfoCard customBg="#fff">
                <h3>Tickets</h3>

                {tickets && tickets.length > 0
                  ? tickets.map((item, index) => (
                      <FlexApart key={index}>
                        <p
                          style={{
                            letterSpacing: '-0.02em',
                            color: '#757575',
                            fontSize: '14px',
                            margin: '1rem 0 0 0',
                          }}
                        >
                          {item.ticketType}({item.ticketQuantity})
                        </p>
                        <p
                          style={{
                            letterSpacing: '-0.02em',
                            color: '#757575',
                            fontSize: '14px',
                            margin: '1rem 0 0 0',
                          }}
                        >
                          &#8358;{item.ticketPrice}
                        </p>
                      </FlexApart>
                    ))
                  : null}
                <div></div>
              </InfoCard>
            </div>
          </CardFlex>
          <ProductWrap>
            <h3
              style={{
                margin: '2rem 0 ',
              }}
            >
              Tickets purchased
            </h3>
            {purchases && purchases.length > 0
              ? purchases.map((item, index) => (
                  <FlexArea key={index}>
                    <ProductInfo>
                      <UserAvatar
                        style={{
                          width: '3rem',
                          height: '3rem',
                          fontSize: '14px',
                          background: 'rgba(200, 43, 50, 0.08)',
                          color: '#71191D',
                          fontWeight: 'bolder',
                          border: 'none',
                        }}
                      >
                        {item.clientFirstName[0]} {item.clientLastName[0]}
                      </UserAvatar>
                      <div>
                        <p>
                          {item.clientFirstName} {item.clientLastName}
                        </p>
                        {/* <p>date</p> */}
                      </div>
                    </ProductInfo>
                    <div>
                      <div key={index}>
                        <h3>&#8358;{item.amount}</h3>

                        {item.eventMetaDetails.ticketType &&
                        item.eventMetaDetails.ticketType.length > 0 ? (
                          <>
                            {item.eventMetaDetails.ticketType.map(
                              (itemP, index) => (
                                <>
                                  <p
                                    style={{
                                      letterSpacing: '-0.02em',
                                      fontSize: '14px',
                                      color: '#757575',
                                    }}
                                  >
                                    {itemP.type} x {itemP.quantity}
                                  </p>
                                </>
                              ),
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </FlexArea>
                ))
              : null}
          </ProductWrap>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default MainContent;
