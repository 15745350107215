import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import { createRequest } from 'utils/request';

interface PropsArgs {
  closeFunc: any;
  errorMessage?: any;
  onProcess: any;
  note?: any;
  fee?: string;
  buttonText?: string;
  onConfirm: any;
}

const ConfirmPin = ({
  closeFunc,
  errorMessage,
  note = 'Please enter your PIN to continue',
  onConfirm,
  fee,
  buttonText = 'Continue',
}: PropsArgs) => {
  const inputStyle = {
    width: '1rem',
    height: '1rem',
    borderRadius: '10rem',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 8,
    display: 'flex',
    alignitems: 'Center',
    justifyContent: 'center',
  };

  const [pin, setPin] = useState<any>('');
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState('');
  const pinCheck = (e: any) => {
    setError('');
    setPin(e);
    if (e.length === 4) {
      setOnProcess(true);
      createRequest('auth/confirm-pin', {
        userId: '__active.id',
        pin: e,
      })
        .then(res => {
          if (res.status) {
            onConfirm(true);
          }
        })
        .catch(err => {
          setError(err.message);
          setPin('');
          setOnProcess(false);
        });
    }
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc(true)}>
            <Icon.X />
          </CloseBtn>
          {/* <Header></Header> */}
          <div>
            <h1
              className="my-5  text-center text-xl font-bold text-baseDark"
              style={{ fontFamily: 'Raleway' }}
            >
              Enter PIN
            </h1>
            {note && (
              <p
                style={{
                  width: '20rem',
                  margin: '0 0 2rem 0',
                  color: 'grey',
                  textAlign: 'center',
                }}
              >
                {note}
              </p>
            )}
            {fee && (
              <p
                style={{
                  width: '10rem',
                  margin: '0 auto 2rem auto',
                  color: '#898A8D',
                  fontSize: '12px',
                  textAlign: 'center',
                  background: '#F4F6F6',
                  borderRadius: '50px',
                  padding: '0.3rem',
                }}
              >
                <b>Transfer Fee: {fee} </b>
              </p>
            )}
            <PinInputWrap>
              <OtpInput
                value={pin}
                onChange={e => {
                  pinCheck(e);
                }}
                numInputs={4}
                inputStyle={inputStyle}
                isInputNum={true}
                shouldAutoFocus={true}
                className="otp-input"
                focusStyle={{ color: 'rgba(0, 0, 0, 0)', opacity: 0.5 }}
              />
            </PinInputWrap>
            <p
              style={{
                color: '#c82b38',
                textAlign: 'center',
                fontSize: 14,
                marginTop: 10,
              }}
            >
              {errorMessage}
            </p>
          </div>
          {error && (
            <p
              style={{
                color: '#C82B32CC',
                fontSize: 12,
                marginTop: 20,
                textAlign: 'center',
              }}
            >
              {error}
            </p>
          )}
          <BigBtn>
            <button disabled={onProcess || pin.length < 4 ? true : false}>
              {onProcess ? <ClipLoader color="#fff" /> : buttonText}
            </button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default ConfirmPin;

const PinInputWrap = styled.div`
  width: 10rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 0 0;
  background: #f3f3f3;
  border-radius: 35.6541px;
  @media (max-width: 728px) {
    width: 10remrea;
  }
`;

interface ModalProps {
  bg?: string;
}

export const ModalArea = styled.div<ModalProps>`
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 1028px) {
    align-items: flex-start;
    background: #ffffff;
    overflow: auto;
  }

  ::before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url('${p => p.bg || ''}');
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(100px);
    -webkit-filter: blur(100px);

    @media (max-width: 728px) {
      filter: blur(30px);
      -webkit-filter: blur(30px);
      background-image: url('${p => p.bg || ''}');
    }
  }
`;

export const ModalChild = styled.div`
  width: auto;
  max-height: 90%;
  padding: 2% 3%;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  overflow-x: hidden;
  animation-duration: 1s;
  aniamtion-name: fadeIn;

  > form {
    > div {
      max-height: 50vh;
      overflow-y: auto;
    }
  }

  @media (max-width: 1028px) {
    width: 100%;
    z-index: 10000000000000;
    margin: 0 auto;
    padding: 2rem 7%;
    position: fixed;
    left: 0;
    top: 0;
    border: none;
    border-radius: 0;
    height: 100%;
    box-shadow: none;
    max-height: 100%;

    > form {
      > div {
        max-height: 70vh;
        overflow-y: auto;
      }
    }
  }
`;

export const CloseBtn = styled.i`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: #676879;

  @media (max-width: 728px) {
    top: 1rem;
    font-size: 1.5rem;
    right: 1rem;
  }
`;

export const Header = styled.h1`
  margin: 0 0 2rem 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: Center;
  gap: 5px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #161616;
`;

interface InputWrapProps {
  width?: string;
}

export const BigBtn = styled.section<InputWrapProps>`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: ${p => p.width || '20rem'};

  > button {
    width: 100%;
    background: #191919;
    border-radius: 16px;
    color: #ffffff;
    padding: 0.7rem 5%;
    margin: 2rem 0 0 0;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  @media (max-width: 728px) {
    width: 100%;
  }
`;
