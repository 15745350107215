import AppHeader from './header/header';
import SectionOne from './sectionOne/sectionOne';
import StoreCards from './storeCards/storeCards';
import { MainWrapper } from './styles';

const MainContent = () => {
  return (
    <>
      <MainWrapper>
        <AppHeader showBack={true} pageName="My Store" />
        <SectionOne />
        <StoreCards />
      </MainWrapper>
    </>
  );
};

export default MainContent;
