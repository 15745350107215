import SecurityMain from 'app/components/editProfile/security';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const Security = () => {
  return (
    <>
      <MainWrapper>
        <SecurityMain />
      </MainWrapper>
    </>
  );
};

export default Security;
