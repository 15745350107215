import React from 'react';
import * as Icon from 'react-feather';
import { BeatLoader } from 'react-spinners';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
} from 'app/components/dashboard/mainContent/fundWallet/style';
import { ProductInfo } from 'app/components/merch/productList/styles';
import { CustomerName } from 'app/components/orderComp/mainContent/orderContent/styles';
import { FlexedApart } from 'app/components/storeComp/sideNav/style';

const CreateWarning = ({
  handleAction,
  deleteInProcess,
  closeFunc,
  message,
  actionText,
  clientName,
  grandTotal,
}) => {
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <p
            style={{
              width: '20rem',
              margin: '2rem 0 0 0',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            {message}
          </p>
          <br />{' '}
          {clientName !== '' ? (
            <FlexedApart>
              <ProductInfo style={{ justifyContent: 'flex-start' }}>
                {clientName && clientName !== '' ? (
                  <div>
                    <CustomerName>
                      <b>{clientName}</b>
                    </CustomerName>
                    <p>&#8358;{grandTotal}</p>
                  </div>
                ) : null}
              </ProductInfo>
            </FlexedApart>
          ) : null}
          <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
              }}
            >
              {'Cancel'}
            </button>
            <button
              onClick={() => handleAction()}
              style={{ width: '9rem', margin: '2rem 0 0 0' }}
            >
              {deleteInProcess ? <BeatLoader /> : actionText}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default CreateWarning;
