import React from 'react';
import * as Icon from 'react-feather';
import { BeatLoader } from 'react-spinners';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
} from '../dashboard/mainContent/fundWallet/style';

const PauseWarning = ({
  status,
  handleAction,
  actionInProcess,
  closeFunc,
  actionText,
}) => {
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <h3
            style={{
              width: '20rem',
              margin: '2rem 0 0 0',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            {status === 'DISABLED' ? 'Resume' : 'Pause'} Transactions
          </h3>
          <p
            style={{
              width: '20rem',
              margin: '1rem 0 0 0',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            Are you sure you want to{' '}
            {status === 'DISABLED' ? 'resume' : 'pause'} transactions on this
            card?
          </p>
          <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
              }}
            >
              {'Cancel'}
            </button>
            <button
              onClick={() => handleAction()}
              style={{ width: '9rem', margin: '2rem 0 0 0' }}
            >
              {actionInProcess ? <BeatLoader color="#fff" /> : actionText}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default PauseWarning;
