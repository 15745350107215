import React from 'react';
import MainContent from 'app/components/paymentLink/createLink';
// import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const CreateLinkPage = () => {
  return (
    <>
      <MainWrapper
        style={{
          background: '#fff',
        }}
      >
        {/* <SideNav mobileShow="none" /> */}
        <MainContent />
      </MainWrapper>
    </>
  );
};

export default CreateLinkPage;
