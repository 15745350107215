import React from 'react';
import * as Icon from 'react-feather';
import { BeatLoader } from 'react-spinners';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
} from 'app/components/dashboard/mainContent/fundWallet/style';
import { ProductInfo } from 'app/components/merch/productList/styles';
// import { CustomerName } from 'app/components/orderComp/mainContent/orderContent/styles';
import { FlexedApart } from 'app/components/storeComp/sideNav/style';
import { Centered } from './style';

const PaymentWarning = ({
  linkForAction,
  handleAction,
  deleteInProcess,
  closeFunc,
  message,
  actionText,
}) => {
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <Centered>
            <img
              src="/warning.png"
              alt="Warning Icon"
              style={{ width: '2rem', height: '2rem' }}
            />
          </Centered>
          <p
            style={{
              width: '20rem',
              margin: '2rem 0 0 0',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            {message}
          </p>
          <br />{' '}
          {linkForAction ? (
            <FlexedApart>
              <ProductInfo style={{ justifyContent: 'center' }}>
                {linkForAction && linkForAction !== '' ? (
                  <div>
                    <h3>{linkForAction.pageName}</h3>
                    <h5
                      style={{
                        fontSize: '0.9rem',
                        color: '#757575',
                      }}
                    >
                      Payment Type - {linkForAction.paymentType}
                    </h5>
                    {linkForAction.paymentType !== 'event' ? (
                      <p>Amount - {linkForAction.amount}</p>
                    ) : null}
                  </div>
                ) : null}
              </ProductInfo>
            </FlexedApart>
          ) : null}
          <ActionBtn style={{ justifyContent: 'center' }}>
            <button
              onClick={() => handleAction()}
              style={{ width: '9rem', margin: '2rem 1rem 0 0' }}
            >
              {deleteInProcess ? <BeatLoader color="#fff" /> : actionText}
            </button>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
              }}
            >
              {'Cancel'}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default PaymentWarning;
