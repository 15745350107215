import SubMain from 'app/components/profile/subMain';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const StoreHome = () => {
  return (
    <>
      <MainWrapper className="no-scrollbar">
        <SubMain />
      </MainWrapper>
    </>
  );
};

export default StoreHome;
