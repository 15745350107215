import styled from 'styled-components';

export const GenericAppHeader = styled.div`
  display: Flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e7e7e7;
  padding: 1.3rem 0%;
  font-weight: 800;
  cursor: pointer;

  i {
    font-size: 1.8rem;
    cursor: pointer;
  }

  svg {
    cursor: pointer;
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const UserWidget = styled.div`
  text-align: center;
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const UserAvatar = styled.div`
  width: 5rem;
  height: 5rem;
  border-radius: 10rem;
  border: 1px solid #757575;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const BlueText = styled.p`
  color: #2c9aff;
  font-size: 0.7rem;
  cursor: pointer;
`;

export const TierDisplay = styled.div`
  width: auto;
  background: #f4f4f4;
  padding: 1% 3%;
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.5);
`;

interface BreadWidth {
  width?: string;
}

export const BreadcrumbArea = styled.div<BreadWidth>`
  width: ${({ width = '70%' }) => width};
  margin: 0 auto;

  @media (max-width: 1028px) {
    width: 100%;
  }
`;

export const BoxCard = styled.div`
  width: 70%;
  padding: 0rem 0.8rem;
  border-radius: 10px;
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 1rem auto 1rem auto;
  cursor: pointer;

  h3 {
    color: #000000;
    font-weight: 600;
  }

  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
  }

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const BoxFlex = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
  margin: 0.5rem 0;

  > div > i {
    margin: 0 0 0 1%;
    color: #757575;
  }
`;

export const ExtraText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  line-height: 1.2rem;
  width: 70%;
  margin: 0 auto;
  margin-top: 2rem;
  text-align: center;
`;

export const UserInfoTextWrap = styled.div`
  width: 85%;

  @media (max-width: 1028px) {
    width: 75%;
  }
`;

export const Chart = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  background: #f0eeee;
  display: flex;
  align-items: Center;
  justify-content: Center;
`;

export const BtnsFlex = styled.div`
  display: flex;
  align-items: Center;
  justify-content: flex-end;
  width: 70%;
`;

export const Notifier = styled.div`
  width: 6rem;
  text-align: Center;
  padding: 1% 0;
  margin: 0 2% 0 0;
  color: #ffffff;
  background: #f2c523;
  border-radius: 10rem;
`;

interface PropsArgs {
  margin?: any;
}

export const GenericHeader = styled.div<PropsArgs>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 500px) {
    margin: ${p => p.margin || ''};
  }

  > div {
    width: auto;
    padding: 1% 5% 1% 2%;
  }

  @media (max-width: 728px) {
    > div {
      width: auto;
      padding: 1% 7% 1% 7%;
    }
  }
`;

export const CountryCode = styled.select`
  position: Absolute;
  top: 50%;
  transform: translate(0, -40%);
  left: 2%;
  color: #757575;
  font-weight: 400;
`;

export const GenericBtn = styled.div`
  width: 100%;
  padding: 0.5rem 5%;
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 5px;
  color: #757575;
  margin: 0.3rem 0 0 0;
  font-family: 'Satoshi', 'sans-serif';
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: Center;

    span {
      font-size: 10px;
      color: #babbb9;
      display: block;
      position: relative;
      line-height: 14px;
    }
  }

  :hover {
    opacity: 0.5;
  }
`;

export const ImagePreviewWrap = styled.div`
  position: relative;
  width: 20rem;

  img {
    width: 20rem;
    object-fit: cover;
    border-radius: 4px;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 3rem;
    background: #c82b38;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    z-index: 1000;
    opacity: 1;
    cursor: pointer;

    :hover {
      opacity: 0.7;
    }
  }
`;

export const TierSystem = styled.div`
  position: absolute;
  top: 1rem;
  right: 4rem;
  padding: 0.5rem 2rem;
  background: rgba(255, 102, 38, 0.52);
  border-radius: 5px;
  z-index: 100;
  color: #fff;
  display: flex;
  align-items: Center;

  > p {
    margin: 0 1rem 0 0;
  }

  @media (max-width: 500px) {
    top: 6rem;
    right: 1rem;
  }
`;
