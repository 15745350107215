import styled from 'styled-components';

interface Props {
  active: string;
}

interface BackG {
  bg?: any;
}

export const BreadcrumbArea = styled.div`
  margin: 5rem 0;

  @media (max-width: 728px) {
    margin: 0;
    position: relative;
    top: -2rem;
  }
`;

export const FlexApart = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddWrapper = styled.div`
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 0.8rem;

    @media (max-width: 1028px) {
      font-size: 0.8rem;
    }
  }

  div {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10rem;
    background: rgba(240, 240, 240, 0.5);
  }

  i {
    color: #757575;
    border-radius: 10rem;
  }

  @media (max-width: 1028px) {
    width: 42%;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: Center;
  width: 70%;
  padding: 2% 0;
  border-bottom: 1px solid #f1f1f1;

  @media (max-width: 1028px) {
    width: 50%;
  }
`;

export const TableHeaderItem = styled.h4<Props>`
  font-size: 0.9rem;
  margin: 0 5% 0 0;
  color: ${p => p.active || '#b0b0b0'};
`;

export const OrderWidget = styled.div`
  margin: 2rem 0;
`;

export const OrderCard = styled.div`
  padding: 1% 1%;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const CustomerName = styled.h4`
  font-size: 0.9rem;
  font-weight: 400;

  @media (max-width: 728px) {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Price = styled.p`
  font-size: 0.8rem;
  font-weight: 600;
`;

export const OrderInfo = styled.div`
  width: 30%;
  display: flex;
  align-items: center;

  > p {
    font-size: 0.8rem;
    color: #757575;
    margin-right: 4%;

    @media (max-width: 1028px) {
      margin-right: 0;
    }
  }

  @media (max-width: 1028px) {
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
  }
`;

export const OrderSecondLine = styled.div`
  margin: 0.5rem 0 0 0;
`;

export const StateCard = styled.div<BackG>`
  border: ${p => (p.bg ? '1.5px solid ' + p.bg : 'none')};
  color: ${p => p.bg};
  padding: 0.4rem 0.6rem;
  font-size: 11px;
  border-radius: 8px;
  display: inline-block;

  @media (max-width: 728px) {
    font-size: 10px;
  }
`;

export const FlexedArea = styled.div`
  display: flex;
  justify-content: space-between;
`;
