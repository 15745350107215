import React, { useState, useEffect, createRef } from 'react';
import AppHeader from './../../storeComp/mainContent/header/header';
import { MainWrapper } from './../../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../../orderComp/mainContent/orderContent/styles';
import {
  HeaderFlex,
  LeftWrap,
  TableWrap,
  TableHeader,
  NameWrap,
  PurposeWrap,
  DateWrap,
  StatusWrap,
  AmountWrap,
  MenuWrap,
  MenuContent,
  FilterSelect,
  SearchPos,
  MobileHistory,
} from './../../transactions/style';
import {
  RightWidget,
  RightCards,
  HistoryWidget,
  HistoryItemCard,
  HistoryInfoSec,
  HistoryAmount,
  HistoryTextWrap,
  HistoryItemIconWrap,
} from '../../dashboard/mainContent/style';
import {
  PaginationWrapper,
  PaginationCont,
  PaginationTextWrap,
} from '../../merch/productList/styles';
import { SearchArea } from '../../orderComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
// import axios from 'axios';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { MoonLoader } from 'react-spinners';
import moment from 'moment';
import { Link } from 'react-router-dom';
import generatePDF from 'react-to-pdf';
import { titleCase } from 'utils/capitalizeFirst';
import CommaNumber from 'comma-number';
import ReportInvoice from './report';
import EmptyState from 'app/components/emptyState/emptyState';
import axios from 'axios';
import Update from './update';
import Reminder from './reminder';
import Preview from './preview';
import InvoiceWarning from 'reusable/invoiceWarning';
import Alert from 'app/components/dashboard/mainContent/fundWallet/alert';
import { handleStatusBg, handleColor } from './colorHandle';
import { MobileButton } from './style';
import { useNavigate } from 'react-router';
import { InAppHeader } from './../../storeComp/mainContent/styles';
import Blocked from '../../transactions/blocked';
import { getRequest } from 'utils/request';

const MainContent = () => {
  const navigate = useNavigate();
  const [toggleActive, setToggleActive] = useState(false);
  const [toggleId, setToggleId] = useState('');

  // Get All Invoice
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  // Handle CLients
  const [invoice, setInvoice] = useState<any>('');
  const [invoiceResult, setInvoiceResult] = useState<any>([]);
  var [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [totalResults, setTotalResult] = useState<any>(0);
  const [loadError, setLoadError] = useState('');
  const [blocked, setBlocked] = useState(false);
  const [bvnKycStatus, setBvnKycStatus] = useState<any>({});
  const [idKycStatus, setIdKycStatus] = useState<any>({});

  const handleToggleActive = id => {
    setToggleActive(true);
    setToggleId(id);
  };

  useEffect(() => {
    const kyc = JSON.parse(localStorage.getItem('cky') || '{}');
    if (kyc && Object.keys(kyc)) {
      setBvnKycStatus(kyc.bvn);
      setIdKycStatus(kyc.document);
    }
  }, []);

  const getInvoices = () => {
    getRequest(`invoices?include=client&sortBy=createdAt:desc`)
      .then(res => {
        setInvoice(res);
        setInvoiceResult(res.results);
        setPage(res.page);
        setTotalPages(res.totalPages);
        setTotalResult(res.totalResults);
      })
      .catch(err => {
        setLoadError(err.message);
      });
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const [type, setType] = useState('all');

  // Handle Search

  const handleSearch: any = e => {
    let new_item: any = [];
    if (invoice !== '' && invoice.results.length !== 0) {
      for (let i = 0; i < invoice.results.length; i++) {
        if (
          invoice.results[i].client.name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          invoice.results[i].invoiceCode
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(invoice.results[i]);
        } else if (e.target.value === '') {
          setInvoiceResult(invoice.results);
          setTotalResult(invoice.results.length);
        }
      }
      setInvoiceResult(new_item);
      setTotalResult(new_item.length);
    }
  };

  // Report Tab

  const [selected, setSelected] = useState<any>({});
  const [client, setClient] = useState<any>({});
  const [updateModal, setUpdateModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);

  const toggleReportModal = item => {
    setReportModal(!reportModal);
    setSelected(item);
  };

  const toggleUpdateModal = item => {
    setUpdateModal(!updateModal);
    setSelected(item);
  };

  //Details Tab
  const [detailsModal, setDetailsModal] = useState(false);

  const toggleDetailsModal = item => {
    setDetailsModal(!detailsModal);
  };

  // PDF Download

  const ref: any = createRef();

  const handleSelect = item => {
    setSelected(item);
    localStorage.setItem('selectedInvoice', JSON.stringify(item));
    setClient(item.client);
  };

  const handlePdf = (action: any) => {
    action();
  };
  // Handle Deletes
  const [warning, setWarning] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  const openDelete = item => {
    setSelected(item);
    setClient(item);
    setWarning(true);
  };

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const handleDelete = async () => {
    setOnProcess(true);
    await axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/${selected.id}`,
        option,
      )
      .then(res => {
        setOnProcess(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          closeModal();
        }, 4000);
        getInvoices();
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const closeModal = () => {
    setError('');
    setSuccess(false);
    setWarning(false);
  };

  const closeWarning = () => {
    setWarning(false);
  };

  const handleIncrement = () => {
    if (page !== totalPages) {
      setPage(++page);
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/invoices?include=client&page=${page}&sortBy=createdAt:desc`,
          {
            headers: { Authorization },
          },
        )
        .then(res => {
          setInvoice(res.data);
          setTotalPages(res.data.totalPages);
          setInvoiceResult(res.data.results);
        })
        .catch(err => {});
    }
  };

  const handleDecrement = () => {
    if (page > 1) {
      setPage(--page);
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/invoices?include=client&page=${page}&sortBy=createdAt:desc`,
          {
            headers: { Authorization },
          },
        )
        .then(res => {
          setInvoice(res.data);
          setTotalPages(res.data.totalPages);
          setInvoiceResult(res.data.results);
        })
        .catch(err => {});
    }
  };

  // Handle Filter

  const handleFilter = e => {
    setInvoice('');
    if (e && e !== 'All') {
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/invoices?include=client${
            e && e !== 'All' ? `&status=${e}` : ''
          }&sortBy=createdAt:desc`,
          { headers: { Authorization } },
        )
        .then(res => {
          setInvoice(res.data);
          setInvoiceResult(res.data.results);
          setTotalPages(res.data.totalPages);
        })
        .catch(err => {});
    } else {
      axios
        .get(
          `${process.env.REACT_APP_BASE_ENDPOINT}/invoices?include=client&sortBy=createdAt:desc`,
          {
            headers: { Authorization },
          },
        )
        .then(res => {
          setInvoice(res.data);
          setInvoiceResult(res.data.results);
          setPage(res.data.page);
          setTotalPages(res.data.totalPages);
          setTotalResult(res.data.totalResults);
        })
        .catch(err => {});
    }
  };

  // Reminder Section
  const [openReminder, setOpenReminder] = useState(false);

  const toggleReminder = item => {
    setOpenReminder(!openReminder);
    setSelected(item);
  };

  const [dateDiff, setDateDiff] = useState(0);

  useEffect(() => {
    try {
      let modifiedDate: any = new Date(selected.dueDate ? selected.dueDate : 0);
      let createdDate: any = new Date();
      const diffTime = Math.abs(createdDate - modifiedDate);
      setDateDiff(Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
    } catch {}
  }, [selected.dueDate]);

  return (
    <>
      <MainWrapper onClick={() => setToggleActive(false)} mobileTop="5rem">
        <AppHeader showBack={true} pageName={`Invoice(${totalResults})`} />
        <InAppHeader>
          <h3>Invoices</h3>
        </InAppHeader>
        <SearchPos hideMobile={true}>
          <button
            style={{
              margin: '0 1rem 0 0',
              color: '#fff',
              padding: '0.5rem 2rem',
              background: '#191919',
              borderRadius: '4px',
              fontSize: '0.8rem',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={() => {
              if (
                bvnKycStatus.status.toLowerCase() === 'verified' &&
                idKycStatus.status.toLowerCase() === 'verified'
              ) {
                navigate('/setup-invoice');
              } else {
                setBlocked(true);
              }
            }}
          >
            <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
            New Invoice
          </button>
          <SearchArea hideMobile={true}>
            <i>
              <Icon.Search />
            </i>
            <input
              type="text"
              placeholder="Search your invoices"
              onChange={e => handleSearch(e)}
            />
          </SearchArea>
        </SearchPos>
        <BreadcrumbArea>
          <HeaderFlex>
            <LeftWrap
              margin="0rem 0 0 0"
              style={{
                justifyContent: 'space-between',
              }}
            >
              <div>
                <FilterSelect
                  onChange={e => {
                    setType(e.target.value);
                    handleFilter(e.target.value);
                  }}
                  value={type}
                >
                  <option>All</option>
                  <option value="unpaid">UNPAID</option>
                  <option value="partially_paid">PARTIALLY PAID</option>
                  <option value="fully_paid">PAID IN FULL</option>
                </FilterSelect>
              </div>
              <Link to="/setup-invoice">
                <MobileButton
                  onClick={() => {
                    if (
                      bvnKycStatus.status.toLowerCase() === 'verified' &&
                      idKycStatus.status.toLowerCase() === 'verified'
                    ) {
                      navigate('/setup-invoice');
                    } else {
                      setBlocked(true);
                    }
                  }}
                >
                  <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
                  New Invoice
                </MobileButton>
              </Link>
            </LeftWrap>
          </HeaderFlex>
          <TableWrap>
            <TableHeader bg="" border="none" style={{ margin: '3rem 0 0 0' }}>
              <NameWrap>RECIPIENT</NameWrap>
              <PurposeWrap>INVOICE NO.</PurposeWrap>
              <DateWrap>DATE ISSUED</DateWrap>
              <StatusWrap>STATUS</StatusWrap>
              <AmountWrap>AMOUNT</AmountWrap>
              <MenuWrap></MenuWrap>
            </TableHeader>
            {invoice !== '' ? (
              <>
                {invoiceResult.length > 0 && invoice.results.length > 0 ? (
                  <>
                    {invoiceResult.map((item, index) => (
                      <>
                        <TransactionCard
                          item={item}
                          key={index}
                          recipient={item.client.name}
                          status={item.status}
                          updatedAt={item.updatedAt}
                          id={item.id}
                          amount={item.totalAmount}
                          invoiceNo={item.invoiceCode}
                          openReport={toggleReportModal}
                          openUpdate={toggleUpdateModal}
                          openDetails={toggleDetailsModal}
                          openReminder={toggleReminder}
                          openDelete={openDelete}
                          handlePdf={handlePdf}
                          handleSelect={handleSelect}
                          propRefs={ref}
                          toggleId={toggleId}
                          setToggleActive={setToggleActive}
                          setToggleId={setToggleId}
                          toggleActive={toggleActive}
                          toggleMenu={handleToggleActive}
                        />
                      </>
                    ))}

                    {/* Mobile Section of History */}

                    <MobileHistory>
                      <RightWidget>
                        <RightCards>
                          <HistoryWidget>
                            {invoiceResult.map((item, index) => (
                              <MobileTransCard
                                item={item}
                                key={index}
                                id={item.id}
                                updatedAt={item.updatedAt}
                                status={item.status}
                                recipient={item.client.name}
                                amount={item.totalAmount}
                                invoiceNo={item.invoiceCode}
                                openReport={toggleReportModal}
                                openUpdate={toggleUpdateModal}
                                openReminder={toggleReminder}
                                openDetails={toggleDetailsModal}
                                openDelete={openDelete}
                                handlePdf={handlePdf}
                                handleSelect={handleSelect}
                                propRefs={ref}
                                toggleId={toggleId}
                                setToggleActive={setToggleActive}
                                setToggleId={setToggleId}
                                toggleActive={toggleActive}
                                toggleMenu={handleToggleActive}
                              />
                            ))}
                          </HistoryWidget>
                        </RightCards>
                      </RightWidget>
                    </MobileHistory>

                    {/* Commented for when Pagination is Ready */}

                    {totalPages > 1 && (
                      <PaginationWrapper>
                        <PaginationCont>
                          <p onClick={() => handleDecrement()}>
                            <Icon.ChevronLeft />
                          </p>
                          <PaginationTextWrap>
                            <h4>{page}</h4>
                            <span>of</span>
                            <h4>{totalPages}</h4>
                          </PaginationTextWrap>
                          <p onClick={() => handleIncrement()}>
                            <Icon.ChevronRight />
                          </p>
                        </PaginationCont>
                      </PaginationWrapper>
                    )}
                  </>
                ) : (
                  <EmptyState
                    img="/invoice.svg"
                    header="Time to get paid!"
                    link="/new-invoice"
                    linkMask="Send an invoice"
                    extraText="and get paid easily"
                  />
                )}
              </>
            ) : (
              <>
                {loadError && loadError !== '' ? (
                  <CenteredAnimeWrap>
                    {loadError}
                    <br />
                    Please Refresh the page
                  </CenteredAnimeWrap>
                ) : (
                  <CenteredAnimeWrap>
                    <MoonLoader size={40} color="#969696" />
                  </CenteredAnimeWrap>
                )}
              </>
            )}
          </TableWrap>
        </BreadcrumbArea>

        {reportModal ? (
          <ReportInvoice
            props={selected}
            closeFunc={toggleReportModal}
            header="Report Issue"
            subText="Tell us what went wrong with this invoice"
          />
        ) : null}

        {updateModal ? (
          <Update
            props={selected}
            closeFunc={toggleUpdateModal}
            header="Update Invoice"
            subText="Make changes to this invoice."
            setFunc={getInvoices}
          />
        ) : null}

        {openReminder ? (
          <Reminder
            closeFunc={toggleReminder}
            header="Set Reminder"
            props={selected}
            dueDate={selected.dueDate}
            dateDiff={dateDiff}
          />
        ) : null}

        {warning ? (
          <InvoiceWarning
            closeFunc={closeWarning}
            invoiceForAction={selected}
            handleAction={handleDelete}
            deleteInProcess={onProcess}
            message="Are you sure you want to delete this invoice?"
            actionText="Confirm Delete"
          />
        ) : null}

        {error !== '' ? (
          <Alert
            closeFunc={closeModal}
            img="/failed.png"
            message="An error occurred!"
            miniMessage={error}
          />
        ) : null}

        {success ? (
          <Alert
            closeFunc={closeModal}
            img="/success.png"
            message="Deleted successfully!!!"
            miniMessage={'You have successfully deleted this invoice'}
          />
        ) : null}

        {selected && client ? (
          <Preview
            propRef={ref}
            clientName={client.name}
            email={client.email}
            address={client.address}
            state={client.state}
            dueDate={selected.dueDate}
            country={client.country}
            shipping={selected.shipping}
            discount={selected.discount}
            phone={client.phoneNumber}
            tax={selected.tax}
            items={selected.items}
            taxPercent={0}
            discountPercent={0}
            fulfilmentLink={selected.paymentLink}
            grandTotal={selected.totalAmount}
            invoiceCode={selected.id}
            refNo={selected.id}
            invoiceNote={selected.invoiceNote}
          />
        ) : null}
        {blocked ? <Blocked closeFunc={() => setBlocked(false)} /> : null}
      </MainWrapper>
    </>
  );
};

export default MainContent;

interface PropsArgs {
  item: any;
  key: any;
  updatedAt?: any;
  status?: any;
  id?: any;
  recipient: any;
  invoiceNo?: any;
  amount?: any;
  openReport?: any;
  openReminder?: any;
  openUpdate?: any;
  openDetails?: any;
  openDelete?: any;
  handlePdf?: any;
  propRefs?: any;
  options?: any;
  handleSelect?: any;
  toggleActive: any;
  toggleId: any;
  setToggleId: any;
  setToggleActive: any;
  toggleMenu: any;
}

const MobileTransCard = ({
  item,
  key,
  updatedAt,
  recipient,
  amount,
  openReport,
  id,
  status,
  invoiceNo,
  openDetails,
  openReminder,
  openUpdate,
  openDelete,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  toggleId,
  setToggleId,
  setToggleActive,
  toggleMenu,
}: PropsArgs) => {
  const navigate = useNavigate();

  return (
    <>
      <HistoryItemCard
        key={key}
        onClick={() => {
          handleSelect(item);
          navigate('/single-invoice');
        }}
      >
        <HistoryItemIconWrap
          customBg="rgba(200, 43, 50, 0.08)"
          style={{
            color: '#71191D',
          }}
        >
          {recipient[0]}
        </HistoryItemIconWrap>
        <HistoryTextWrap>
          <h4>{recipient ? titleCase(recipient) : 'No name attached'}</h4>
          <p>{moment(updatedAt).format('LL')}</p>
        </HistoryTextWrap>
        <HistoryInfoSec>
          <HistoryAmount>&#8358;{CommaNumber(amount)}</HistoryAmount>
          <StatusWrap
            style={{
              background: handleStatusBg(status),
              color: handleColor(status),
            }}
          >
            <div style={{ background: handleStatusBg(status) }}>
              {status
                .replace('_', ' ')
                .replace(status[0], status[0].toUpperCase())}
            </div>
          </StatusWrap>
        </HistoryInfoSec>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                handleSelect(item);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="2rem"
            onClick={e => {
              setToggleId(false);
              e.stopPropagation();
            }}
          >
            <p
              onClick={() => {
                generatePDF(propRefs, {
                  filename: `invoice-${id}.pdf`,
                });
              }}
            >
              Download invoice
            </p>
            <p onClick={() => openUpdate(item)}>Update invoice</p>
            <p>
              <a href="mailto:hello@trymage.com">Report issue</a>
            </p>
            {/* <p onClick={() => openReminder(item.id)}>Set Reminder</p> */}
            <p onClick={() => openDelete(item)}>
              <span style={{ color: '#c82b38' }}>Delete invoice</span>
            </p>
          </MenuContent>
        ) : null}
      </HistoryItemCard>
    </>
  );
};

const TransactionCard = ({
  item,
  key,
  id,
  updatedAt,
  recipient,
  status,
  amount,
  invoiceNo,
  openReport,
  openReminder,
  openUpdate,
  openDelete,
  handlePdf,
  propRefs,
  options,
  handleSelect,
  toggleActive,
  toggleId,
  setToggleId,
  setToggleActive,
  toggleMenu,
}: PropsArgs) => {
  const navigate = useNavigate();

  return (
    <>
      <TableHeader
        bg="#fff"
        border=""
        key={key}
        onClick={() => {
          handleSelect(item);
          navigate('/single-invoice');
        }}
      >
        <NameWrap>
          <h4>{recipient ? titleCase(recipient) : 'No name attached'}</h4>
        </NameWrap>
        <PurposeWrap>
          {'invoiceCode' in item ? invoiceNo : 'No memo attached'}
        </PurposeWrap>
        <DateWrap>{moment(updatedAt).format('LL')}</DateWrap>
        <StatusWrap>
          <div
            style={{
              background: handleStatusBg(status),
              color: handleColor(status),
            }}
          >
            {status
              .replace('_', ' ')
              .replace(status[0], status[0].toUpperCase())}
          </div>
        </StatusWrap>
        <AmountWrap>&#8358;{CommaNumber(amount)}</AmountWrap>
        <MenuWrap>
          <i>
            <Icon.MoreVertical
              strokeWidth={1}
              onClick={e => {
                toggleMenu();
                setToggleId(item.id);
                handleSelect(item);
                e.stopPropagation();
              }}
            />
          </i>
        </MenuWrap>
        {toggleActive && toggleId === item.id ? (
          <MenuContent
            right="2rem"
            onClick={e => {
              setToggleId(false);
              e.stopPropagation();
            }}
          >
            <p
              onClick={() => {
                generatePDF(propRefs, {
                  filename: `invoice-${id}.pdf`,
                });
              }}
            >
              Download invoice
            </p>
            <p onClick={() => openUpdate(item)}>Update invoice</p>
            <p>
              <a href="mailto:hello@trymage.com">Report issue</a>
            </p>
            {/* <p onClick={() => openReminder(item.id)}>Set Reminder</p> */}
            <p onClick={() => openDelete(item)}>
              <span style={{ color: '#c82b38' }}>Delete invoice</span>
            </p>
          </MenuContent>
        ) : null}
      </TableHeader>
    </>
  );
};
