import React, { useState } from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
  InputWrap,
  ReportActionBtn,
  ReportBtn,
} from './style';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';
import Select from 'react-select';
import { CustomStyles } from 'utils/selectCustomStyle';

interface PropsArgs {
  props: any;
  closeFunc: any;
  header: any;
  subText?: any;
}

const ReportTransaction = ({
  props,
  closeFunc,
  header,
  subText,
}: PropsArgs) => {
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const apiPayload = {
    transaction: props.id,
    reason: reason,
    info: description,
  };

  const handleSendIssue = async () => {
    if (reason !== '' && description !== '') {
      setOnProcess(true);
      await axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/report`,
          apiPayload,
          option,
        )
        .then(res => {
          setOnProcess(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            closeFunc();
          }, 4000);
        })
        .catch(err => {
          setOnProcess(false);
          setError(err.response.data.message);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    } else {
      setError('Please fill all input fields!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const closeModal = () => {
    setError('');
    setSuccess(false);
  };

  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3>{header}</h3>
            <p>{subText}</p>
          </HeaderWrap>
          <ModalMain>
            <InputWrap>
              <label>Reason for reporting</label>
              <Select
                options={reporting}
                getOptionLabel={p => p.name}
                styles={CustomStyles}
                onChange={(e: any) => setReason((e as HTMLSelectElement).name)}
              />
            </InputWrap>
            <InputWrap>
              <label>Additional Information</label>
              <textarea
                onChange={e => setDescription(e.target.value)}
              ></textarea>
            </InputWrap>
            <ReportActionBtn>
              <ReportBtn bg="" border="" cl="" onClick={() => closeFunc()}>
                Cancel
              </ReportBtn>
              <ReportBtn
                bg="#c82b38"
                border="none"
                cl="#fff"
                onClick={() => handleSendIssue()}
              >
                {onProcess ? <BeatLoader /> : 'Submit'}
              </ReportBtn>
            </ReportActionBtn>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>

      {error !== '' ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occurred!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Sent successfully!!!"
          miniMessage={
            'Your issue have been sent successfully. It will be addressed as soon as possible.!  '
          }
        />
      ) : null}
    </>
  );
};

export default ReportTransaction;

const reporting = [
  { name: 'Debited but not received at counterparty end' },
  { name: 'Delayed transactions' },
  { name: 'Transaction processing but debit has occurred' },
  { name: 'Payment processing failed' },
  { name: 'Successful transaction with no debit on' },
  { name: 'Others' },
];
