import SingleContent from 'app/components/orderComp/mainContent/single';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const SingleOrder = () => {
  return (
    <>
      <MainWrapper>
        <SingleContent />
      </MainWrapper>
    </>
  );
};

export default SingleOrder;
