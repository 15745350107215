import axios from 'axios';
import { useEffect, useState } from 'react';
import EmptyState from './../../../emptyState/emptyState';
import NavSection from './navSection/navSection';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { MoonLoader } from 'react-spinners';
import {
  TableWrap,
  TableHeader,
  IconWrap,
  NameWrap,
  PurposeWrap,
  DateWrap,
  AmountWrap,
  MenuWrap,
  MobileHistory,
  FilterActionBtn,
  SearchPos,
} from 'app/components/transactions/style';
import {
  HistoryItemIconWrap,
  HistoryItemCard,
  HistoryTextWrap,
} from 'app/components/dashboard/mainContent/style';
import {
  RightCards,
  RightWidget,
  HistoryWidget,
} from 'app/components/dashboard/mainContent/style';
import Select from 'react-select';
import moment from 'moment';
import Details from './details';
import * as Icon from 'react-feather';
import { CSVLink } from 'react-csv';
import { InAppHeader } from 'app/components/storeComp/mainContent/styles';

const CustomersCont = () => {
  const categories = [
    { name: 'Payment Link' },
    { name: 'Invoice' },
    { name: 'Store' },
  ];

  const [channel, setChannel] = useState('Payment Link');

  const sizeStyle = {
    control: (provided, state) => ({
      ...provided,
      border: '0.5px solid #dadada',
      padding: '0.2rem 2%',
      margin: '0.2rem 0 0.2rem 0',
      borderRadius: '5px',
      background: '#ffffff',
      width: '12rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '16px',
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        background: '#fff',
        color: '#191919',
        fontSize: '16px',
      };
    },
  };

  const [customers, setCustomers] = useState<any>([]);
  const [storeCustomers, setStoreCustomers] = useState<any>([]);
  const [paymentLinkCustomers, setPaymentLinkCustomers] = useState<any>([]);
  const [invoiceCustomers, setInvoiceCustomers] = useState<any>([]);
  const [invoiceMutable, setInvoiceMutable] = useState<any>([]);
  const [paymentMutable, setPaymentMutable] = useState<any>([]);
  const [storeMutable, setStoreMutable] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const creator = JSON.parse(localStorage.getItem('logged_user') || '{}');

  useEffect(() => {
    if (customers.length === 0) {
      setLoading(true);
      axios
        .get(process.env.REACT_APP_BASE_ENDPOINT + `/creator-page/customers`, {
          headers: {
            'Content-type': 'Application/json',
            Authorization: `Bearer ${creator.tokens.access.token}`,
          },
        })
        .then(res => {
          setLoading(false);
          setCustomers(res.data);
          setInvoiceCustomers(res.data.invoiceCustomers);
          setInvoiceMutable(res.data.invoiceCustomers);
          setStoreMutable(res.data.storeClients);
          setStoreCustomers(res.data.storeClients);
          setPaymentMutable(res.data.paymentLinkClients);
          setPaymentLinkCustomers(res.data.paymentLinkClients);
        })
        .catch();
    }
  }, [customers.length, creator.tokens.access.token]);

  const handleSearch: any = e => {
    let new_item: any = [];

    if (invoiceCustomers !== '' && invoiceCustomers.length > 0) {
      for (let i = 0; i < invoiceCustomers.length; i++) {
        if (
          invoiceCustomers[i].name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          invoiceCustomers[i].email
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          invoiceCustomers[i].phoneNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(invoiceCustomers[i]);
        } else if (e.target.value === '') {
          setInvoiceMutable(invoiceCustomers);
        }
      }
      setInvoiceMutable(new_item);
    }

    // Store Filter
    let store_item: any = [];
    if (storeCustomers !== '' && storeCustomers.length !== 0) {
      for (let i = 0; i < storeCustomers.length; i++) {
        if (
          storeCustomers[i].firstName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          storeCustomers[i].lastName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          storeCustomers[i].email
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          storeCustomers[i].phoneNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          store_item.push(storeCustomers[i]);
        } else if (e.target.value === '') {
          setStoreMutable(storeCustomers);
        }
      }
      setStoreMutable(store_item);
    }

    // Store Filter
    let payment_item: any = [];
    if (paymentLinkCustomers !== '' && paymentLinkCustomers.length > 0) {
      for (let i = 0; i < paymentLinkCustomers.length; i++) {
        if (
          paymentLinkCustomers[i].clientFirstName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          paymentLinkCustomers[i].clientLastName
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          paymentLinkCustomers[i].clientEmail
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          paymentLinkCustomers[i].clientPhoneNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          payment_item.push(paymentLinkCustomers[i]);
        } else if (e.target.value === '') {
          setPaymentMutable(paymentLinkCustomers);
        }
      }
      setPaymentMutable(payment_item);
    }
  };

  // Details Section

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [firstInitial, setFirstInitial] = useState('');
  const [secondInitial, setSecondInitial] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  const [detailsModal, setDetailsModal] = useState(false);

  const toggleDetailsModal = item => {
    setDetailsModal(!detailsModal);

    if (channel === 'Store') {
      setName(`${item.firstName} ${item.lastName}`);
      setEmail(item.email);
      setFirstInitial(item.firstName[0]);
      setSecondInitial(item.lastName[0]);
      setPhoneNo(item.phoneNumber);
    } else if (channel === 'Payment Link') {
      setName(`${item.clientFirstName} ${item.clientLastName}`);
      setEmail(item.clientEmail);
      setPhoneNo(item.clientPhoneNumber);
      setFirstInitial(item.clientFirstName[0]);
      setSecondInitial(item.clientLastName[0]);
    } else if (channel === 'Invoice') {
      setName(item.name);
      setEmail(item.email);
      setPhoneNo(item.phoneNumber);
      setFirstInitial(item.name.split(' ')[0][0]);
      setSecondInitial(item.name.split(' ')[1][0]);
    }
  };

  // Handle Current Data

  const [currentData, setCurrentData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    let mockData: any = [];

    if (channel === 'Store' && storeMutable.length > 0) {
      setIsDisabled(false);
      for (let i = 0; i < storeMutable.length; i++) {
        mockData.push({
          Name: `${storeMutable[i].firstName} ${storeMutable[i].firstName}`,
          Email: `${storeMutable[i].email}`,
        });
      }
      setCurrentData(mockData);
    } else if (channel === 'Store' && storeMutable.length < 1) {
      setIsDisabled(true);
    }

    if (channel === 'Payment Link' && paymentMutable.length > 0) {
      setIsDisabled(false);
      for (let i = 0; i < paymentMutable.length; i++) {
        mockData.push({
          Name: `${paymentMutable[i].clientFirstName} ${paymentMutable[i].clientLastName}`,
          Email: `${paymentMutable[i].clientEmail}`,
        });
      }
      setCurrentData(mockData);
    } else if (channel === 'Payment Link' && paymentMutable.length < 1) {
      setIsDisabled(true);
    }

    if (channel === 'Invoice' && invoiceMutable.length > 0) {
      setIsDisabled(false);
      for (let i = 0; i < invoiceMutable.length; i++) {
        mockData.push({
          Name: `${invoiceMutable[i].name}`,
          Email: `${invoiceMutable[i].email}`,
        });
      }
      setCurrentData(mockData);
    } else if (channel === 'Invoice' && invoiceMutable.length < 1) {
      setIsDisabled(true);
    }
  }, [channel, storeMutable, invoiceMutable, paymentMutable]);

  return (
    <>
      <InAppHeader>
        <h3>Customers</h3>
      </InAppHeader>
      <SearchPos>
        <Select
          options={categories}
          getOptionLabel={p => p.name}
          styles={sizeStyle}
          placeholder={channel}
          onChange={(e: any) => setChannel((e as HTMLSelectElement).name)}
        />
        <CSVLink data={currentData} filename={`Audience - ${channel}.csv`}>
          <FilterActionBtn
            bg="#191919"
            color="#fff"
            style={
              isDisabled
                ? { cursor: 'not-allowed', margin: '0 0 0 2rem' }
                : { cursor: 'pointer', margin: '0 0 0 2rem' }
            }
            disabled={isDisabled}
          >
            <Icon.Download size={15} />
            Download CSV
          </FilterActionBtn>
        </CSVLink>
      </SearchPos>
      <NavSection handleSearch={handleSearch} />
      <TableWrap>
        <TableHeader bg="" border="none" style={{ margin: '3rem 0 0 0' }}>
          <IconWrap></IconWrap>
          <NameWrap>FULL NAME</NameWrap>
          <PurposeWrap>EMAIL ADDRESS</PurposeWrap>
          <AmountWrap>CHANNEL</AmountWrap>
          <DateWrap>DATE ADDED</DateWrap>
          <MenuWrap></MenuWrap>
        </TableHeader>
      </TableWrap>

      {!loading ? (
        <>
          <>
            {channel === 'Store' ? (
              <>
                {storeMutable &&
                channel === 'Store' &&
                storeMutable.length > 0 ? (
                  <>
                    {storeMutable.map((item, index) => (
                      <LineCard
                        key={index}
                        item={item}
                        name={`${item.firstName} ${item.lastName}`}
                        firstName={item.firstName}
                        lastName={item.lastName}
                        email={item.email}
                        channel={channel}
                        firstInitial={item.firstName.split('')[0]}
                        secondInitial={item.lastName.split('')[0]}
                        date={item.createdAt}
                        toggleDetails={toggleDetailsModal}
                      />
                    ))}

                    <MobileHistory>
                      <RightWidget>
                        <RightCards>
                          <HistoryWidget>
                            {storeMutable.map((item, index) => (
                              <MobileCard
                                key={index}
                                item={item}
                                name={`${item.firstName} ${item.lastName}`}
                                firstName={item.firstName}
                                lastName={item.lastName}
                                email={item.email}
                                channel={channel}
                                firstInitial={item.firstName.split('')[0]}
                                secondInitial={item.lastName.split('')[0]}
                                date={item.createdAt}
                                toggleDetails={toggleDetailsModal}
                              />
                            ))}
                          </HistoryWidget>
                        </RightCards>
                      </RightWidget>
                    </MobileHistory>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0 auto',
                    }}
                  >
                    <EmptyState
                      img={'customer.svg'}
                      linkMask=" "
                      extraText=" "
                    />
                  </div>
                )}
              </>
            ) : null}

            {channel === 'Payment Link' ? (
              <>
                {paymentMutable && paymentMutable.length > 0 ? (
                  <>
                    {paymentMutable.map((item, index) => (
                      <LineCard
                        key={index}
                        item={item}
                        name={`${item.clientFirstName} ${item.clientLastName}`}
                        firstName={item.clientFirstName}
                        lastName={item.clientLastName}
                        email={item.clientEmail}
                        channel={channel}
                        firstInitial={item.clientFirstName.split('')[0]}
                        secondInitial={item.clientLastName.split('')[0]}
                        date=""
                        toggleDetails={toggleDetailsModal}
                      />
                    ))}

                    <MobileHistory>
                      <RightWidget>
                        <RightCards>
                          <HistoryWidget>
                            {paymentMutable.map((item, index) => (
                              <MobileCard
                                key={index}
                                item={item}
                                name={`${item.clientFirstName} ${item.clientLastName}`}
                                firstName={item.clientFirstName}
                                lastName={item.clientLastName}
                                email={item.clientEmail}
                                channel={channel}
                                firstInitial={item.clientFirstName.split('')[0]}
                                secondInitial={item.clientLastName.split('')[0]}
                                date=""
                                toggleDetails={toggleDetailsModal}
                              />
                            ))}
                          </HistoryWidget>
                        </RightCards>
                      </RightWidget>
                    </MobileHistory>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0 auto',
                    }}
                  >
                    <EmptyState
                      img={'customer.svg'}
                      linkMask=" "
                      extraText=" "
                    />
                  </div>
                )}
              </>
            ) : null}

            {channel === 'Invoice' ? (
              <>
                {invoiceMutable &&
                channel === 'Invoice' &&
                invoiceMutable.length > 0 ? (
                  <>
                    {invoiceMutable.map((item, index) => (
                      <LineCard
                        key={index}
                        item={item}
                        name={`${item.name}`}
                        firstName={item.name.split(' ')[0]}
                        lastName={item.name.split(' ')[1]}
                        email={item.email}
                        channel={channel}
                        date={item.createdAt}
                        firstInitial={item.name[0]}
                        secondInitial={''}
                        toggleDetails={toggleDetailsModal}
                      />
                    ))}
                    <MobileHistory>
                      <RightWidget>
                        <RightCards>
                          <HistoryWidget>
                            {invoiceMutable.map((item, index) => (
                              <MobileCard
                                key={index}
                                item={item}
                                name={`${item.name}`}
                                firstName={item.name.split(' ')[0]}
                                lastName={item.name.split(' ')[1]}
                                email={item.email}
                                channel={channel}
                                date={item.createdAt}
                                firstInitial={item.name[0]}
                                secondInitial={''}
                                toggleDetails={toggleDetailsModal}
                              />
                            ))}
                          </HistoryWidget>
                        </RightCards>
                      </RightWidget>
                    </MobileHistory>
                  </>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: '0 auto',
                    }}
                  >
                    <EmptyState
                      img={'customer.svg'}
                      linkMask=" "
                      extraText=" "
                    />
                  </div>
                )}
              </>
            ) : null}

            {(storeCustomers.length > 0 ||
              invoiceCustomers.length > 0 ||
              paymentLinkCustomers.length > 0) &&
            invoiceMutable.length < 1 &&
            paymentMutable.length < 1 &&
            storeMutable.length < 1 ? (
              <p
                style={{
                  textAlign: 'center',
                }}
              >
                No search result found!
              </p>
            ) : null}
          </>
        </>
      ) : (
        <CenteredAnimeWrap>
          <MoonLoader size={40} color="#969696" />
        </CenteredAnimeWrap>
      )}

      {detailsModal ? (
        <Details
          name={name}
          email={email}
          firstInitial={firstInitial}
          secondInitial={secondInitial}
          phoneNo={phoneNo}
          closeFunc={toggleDetailsModal}
        />
      ) : null}
    </>
  );
};

export default CustomersCont;

interface PropsArgs {
  item: any;
  key: any;
  name: string;
  firstName: string;
  lastName: string;
  channel: string;
  email: string;
  firstInitial: string;
  secondInitial: string;
  date: string;
  toggleDetails: any;
}

const LineCard = ({
  item,
  name,
  firstName,
  lastName,
  channel,
  email,
  key,
  firstInitial,
  secondInitial,
  date,
  toggleDetails,
}: PropsArgs) => {
  return (
    <>
      <TableHeader
        bg="#fff"
        border=""
        key={key}
        onClick={() => toggleDetails(item)}
      >
        <IconWrap>
          <HistoryItemIconWrap customBg={'#FDB394'}>
            {firstInitial} {secondInitial}
          </HistoryItemIconWrap>
        </IconWrap>
        <NameWrap>
          <p>{name}</p>
        </NameWrap>
        <PurposeWrap>{email}</PurposeWrap>
        <AmountWrap>{channel}</AmountWrap>
        <DateWrap>
          {date !== '' ? moment(date).format('LLL') : 'Not specified'}
        </DateWrap>
        <MenuWrap></MenuWrap>
      </TableHeader>
    </>
  );
};

const MobileCard = ({
  item,
  name,
  firstName,
  lastName,
  channel,
  email,
  key,
  firstInitial,
  secondInitial,
  date,
  toggleDetails,
}: PropsArgs) => {
  return (
    <>
      <HistoryItemCard key={key} onClick={() => toggleDetails(item)}>
        <HistoryItemIconWrap customBg={'#FDB394'}>
          {firstInitial} {secondInitial}
        </HistoryItemIconWrap>
        <HistoryTextWrap
          style={{
            width: '82%',
          }}
        >
          <h4>{name}</h4>
          <p>{email}</p>
          <p>
            {date !== '' ? moment(date).format('LL') : 'Date not specified'}
          </p>
        </HistoryTextWrap>
      </HistoryItemCard>
    </>
  );
};
