// import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';
import MainContent from '../../components/Preference/mainContent/main';

const Order = () => {
  return (
    <>
      <MainWrapper>
        {/* <SideNav mobileShow="none" /> */}
        <MainContent />
      </MainWrapper>
    </>
  );
};

export default Order;
