import CompliancePageMain from 'app/components/editProfile/compliance';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const CompliancePage = () => {
  return (
    <>
      <MainWrapper>
        <CompliancePageMain />
      </MainWrapper>
    </>
  );
};

export default CompliancePage;
