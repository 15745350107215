import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { useState } from 'react';
import * as Icon from 'react-feather';
import {
  HeaderIconsWrap,
  HeaderNavWrap,
  MainHeadWrap,
  HeaderBackground,
  UserSection,
  HeaderPopUp,
} from './../../storeComp/mainContent/header/styles';
import { MerchroLogo } from 'app/pages/Login/styles';
import {
  PaddedArea,
  FlexedApart,
  UserAvatar,
} from 'app/components/storeComp/sideNav/style';
import { Link } from 'react-router-dom';
import Warning from 'reusable/warning';
import { useNavigate } from 'react-router-dom';

const DashboardHeader = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  let logged_user = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const [showPopUp, setShowPopUp] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  // Log out Functions
  const [warning, setWarning] = useState(false);

  const toggleWarning = () => {
    setWarning(!warning);
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate('/login');
  };

  return (
    <>
      <HeaderBackground onClick={() => setShowPopUp(false)}>
        <MainHeadWrap>
          <MerchroLogo src="/logosvg.svg" alt="Mongoro" />
          <HeaderIconsWrap>
            <div></div>
            <div className="mobile-nav" onClick={toggleModal}>
              <p>Menu</p>
              <Icon.Menu size={20} strokeWidth="1.5px" />
            </div>
          </HeaderIconsWrap>

          {/* Header Nav */}

          {showModal ? (
            <HeaderNavWrap onClick={toggleModal}>
              <span>Menu</span>
              <i onClick={toggleModal}>
                <span>Menu</span>
                <Icon.Menu
                  style={{
                    transform: 'rotate(90deg)',
                  }}
                />
              </i>
              <SideNav mobileShow="block" />
            </HeaderNavWrap>
          ) : null}
        </MainHeadWrap>
        <UserSection>
          {/* <Link to="/contact">
            <Icon.HelpCircle color="#fff" />
          </Link> */}
          <PaddedArea>
            <FlexedApart
              onClick={e => {
                e.stopPropagation();
                setShowPopUp(!showPopUp);
              }}
            >
              {logged_user.user && logged_user.user.avatar ? (
                <UserAvatar>
                  {/* {active && (
                    <>
                      <div>
                        <HiFire color="#8C52FF" size={15} />
                      </div>
                    </>
                  )} */}
                  <img
                    src={
                      logged_user.user.avatar
                        ? logged_user.user.avatar
                        : '/test-avatar.png'
                    }
                    alt={logged_user.user.avatar}
                  />
                </UserAvatar>
              ) : (
                <UserAvatar>
                  {/* {active && (
                    <>
                      <div>
                        <HiFire color="#8C52FF" size={15} />
                      </div>
                    </>
                  )} */}
                  <h4>
                    {logged_user.user
                      ? logged_user.user.firstName.split('')[0]
                      : ''}
                  </h4>
                  <h4>
                    {logged_user.user
                      ? logged_user.user.lastName.split('')[0]
                      : ''}
                  </h4>
                </UserAvatar>
              )}
            </FlexedApart>
          </PaddedArea>
          {showPopUp ? (
            <HeaderPopUp>
              {/* NOTE: Creator page disabled for now */}
              {/* <a
                href={`https://${myStore.slug}${process.env.REACT_APP_PAGE_LINK}?token=${logged_user.tokens.access.token}`}
                target="_blank"
                rel="noreferrer"
              >
                <p
                  style={{
                    fontWeight: 400,
                    border: '0.2px solid rgba(0, 0, 0, 0.1)',
                    padding: '0.2rem',
                    borderRadius: '5px',
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {myStore.slug}
                  {process.env.REACT_APP_PAGE_LINK}
                </p>
              </a> */}
              {/* <p>
                <StatusWrap
                  style={{
                    color: '#c82b32',
                    background: '#F7C3C4',
                    width: '5rem',
                    height: '1.5rem',
                    textAlign: 'center',
                    fontSize: '10px',
                    padding: '0rem 0',
                    borderRadius: '10px',
                  }}
                >
                  Username
                </StatusWrap>
                {myStore.slug}
              </p>
              <p>{logged_user.user ? logged_user.user.email : ''}</p> */}
              {/* <Line
                style={{
                  margin: '0.5rem 0',
                }}
              /> */}
              {/* NOTE: Subscription hidden for now */}
              {/* {active ? (
                <>
                  <Link to="/upgrade/plan">
                    <p>
                      <HiFire color="#8c52ff" size={18} />
                      Spark
                    </p>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="/upgrade/plan">
                    <p>
                      <HiFire color="#444444" size={18} />
                      Upgrade
                    </p>
                  </Link>
                </>
              )} */}
              <Link to="/profile">
                <p>
                  <Icon.User color="#444444" size={16} />
                  Account Details
                </p>
              </Link>
              <Link to="/contact">
                <p>
                  <Icon.HelpCircle color="#444" size={16} />
                  Help & Support
                </p>
              </Link>
              <p onClick={() => toggleWarning()}>
                <Icon.LogOut color="#444444" size={16} />
                Log Out
              </p>
            </HeaderPopUp>
          ) : null}
        </UserSection>
      </HeaderBackground>

      {warning ? (
        <Warning
          closeFunc={toggleWarning}
          productForDelete={''}
          handleDelete={handleLogout}
          header="Sign Out"
          deleteInProcess={false}
          message="Are you sure you want to sign out from your account?"
          actionText="Sign out"
        />
      ) : null}
    </>
  );
};

export default DashboardHeader;
