import React, { useEffect, useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
  SubHeader,
  SubHeaderChild,
  UserAvatarWrap,
  UserCont,
  UserInfo,
} from './style';
import EnterPin from './enterPin';
import { numOnly } from 'utils/numOnly';
import * as Icon from 'react-feather';
import Alert from './alert';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import CommaNumber from 'comma-number';
import { roundTo } from 'utils/roundFloat';
import * as IconSax from 'iconsax-react';

const BuyArtime = ({ closeFunc, balance }) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [active, setAcitve] = useState(0);
  const [amount, setAmount] = useState<string>('0');
  const [phoneNo, setPhoneNo] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [clientErr, setClientErr] = useState('');
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [beneficiaryData, setBeneficiaryData] = useState<any>([]);

  // Enter Pin for transaction

  const [pin, setPin] = useState<any>('');

  // Set Pin
  const handlePinChange = pin => {
    setPin(pin);
  };

  const [pinModal, setPinModal] = useState(false);

  const handleClosePinModal = () => {
    setPinModal(false);
  };

  const [result, setResult] = useState<boolean | string>(false);

  // Check Recent purchsers
  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_ENDPOINT
        }/payments/transactions?include=user&sortBy=createdAt:desc&text=${'airtime purchase'}`,
        {
          headers: {
            Authorization: `Bearer ${loggedUser.tokens.access.token}`,
          },
        },
      )
      .then(res => {
        setBeneficiaryData(res.data.results);
      })
      .catch(err => {});
  }, [loggedUser]);

  const handlePayment = () => {
    setOnProcess(true);
    if (Number(amount) > 0) {
      if (amount && Number(amount) <= balance) {
        if (Number(amount) > 0 && phoneNo !== '') {
          setPinModal(true);
          setOnProcess(false);
        } else {
          setClientErr('Please fill all input fields!');
          setOnProcess(false);
          setTimeout(() => {
            setClientErr('');
          }, 2000);
        }
      } else {
        setOnProcess(false);
        setClientErr(
          'You do not have sufficient balance to make this transaction',
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    } else {
      setOnProcess(false);
      setClientErr(`Amount can't be empty and must be greater than 0`);
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  // Payment Parameters
  const paymentParams: any = {
    amount: amount,
    phoneNumber: phoneNo,
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [finalOnProcess, setFinalOnProcess] = useState(false);
  const handleFinalPay = () => {
    setFinalOnProcess(true);
    if (pin && pin.length > 0) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/auth/confirm-pin`,
          {
            pin,
            userId: loggedUser.user.id,
          },
          headers,
        )
        .then(res => {
          if (res.data.status) {
            setFinalOnProcess(false);
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              setPinModal(false);
              closeFunc();
            }, 5000);
            axios
              .post(
                `${process.env.REACT_APP_BASE_ENDPOINT}/payments/buy-airtime`,
                paymentParams,
                headers,
              )
              .then(res => {
                setFinalOnProcess(false);
                setSuccess(true);
                setTimeout(() => {
                  setSuccess(false);
                  setPinModal(false);
                  closeFunc();
                }, 5000);
              })
              .catch(err => {
                setFinalOnProcess(false);
                setError(err.response.data.message);
                setTimeout(() => {
                  setError('');
                }, 4000);
              });
          } else {
            setFinalOnProcess(false);
            setErrorMessage('Unable to confirm pin at the moment');
            setPin('');
            setTimeout(() => {
              setErrorMessage('');
            }, 4000);
          }
        })
        .catch(err => {
          setFinalOnProcess(false);
          setErrorMessage(err.response.data.message);
          setPin('');
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('Transaction pin is not correct!');
      setPin('');
      setFinalOnProcess(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  useEffect(() => {
    if (pin.length === 4) handleFinalPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);

  const closeModal = () => {
    setError('');
  };

  const closeSuccess = () => {
    setSuccess(false);
    setPinModal(false);
    closeFunc();
  };

  const closeMessage = () => {
    setResult(false);
  };

  return (
    <>
      <ModalArea
        style={pinModal ? { visibility: 'hidden' } : { visibility: 'visible' }}
      >
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Buy Airtime</Header>
          <SubHeader>
            {header.map((item, index) => (
              <SubHeaderChild
                customBd={active === index ? '#191919' : ''}
                key={index}
                onClick={() => setAcitve(index)}
              >
                {item.name}
              </SubHeaderChild>
            ))}
          </SubHeader>
          {active === 0 ? (
            <>
              <div>
                <InputWrap>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label>Enter Amount (&#8358;)</label>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        letterSpacing: '-0.02em',
                        color: '#ABABAB',
                      }}
                    >
                      Balance: &#8358;{CommaNumber(roundTo(balance, 2))}
                    </p>
                  </div>
                  <input
                    type="number"
                    placeholder="Amount"
                    onKeyPress={e => numOnly(e)}
                    onChange={e => setAmount(e.target.value)}
                  />
                </InputWrap>
                <InputWrap>
                  <label>Phone number</label>
                  <input
                    type="text"
                    maxLength={11}
                    placeholder="Phone number"
                    value={phoneNo}
                    onKeyPress={e => numOnly(e)}
                    onChange={e => setPhoneNo(e.target.value)}
                  />
                </InputWrap>
              </div>
              <p style={{ width: '20rem', fontSize: '0.8rem', color: 'red' }}>
                {clientErr}
              </p>
              <BigBtn>
                <button
                  onClick={e => handlePayment()}
                  disabled={onProcess ? true : false}
                >
                  {onProcess ? <ClipLoader color="#fff" /> : 'Buy airtime'}
                </button>
              </BigBtn>
            </>
          ) : (
            <div style={{ width: '20rem' }}>
              {beneficiaryData && beneficiaryData.length > 0 ? (
                <>
                  {beneficiaryData.slice(0, 3).map((item, index) => (
                    <UserCont
                      key={index}
                      onClick={() => {
                        setPhoneNo(item.meta.phoneNumber);
                        setAcitve(0);
                      }}
                    >
                      <UserInfo>
                        <UserAvatarWrap>
                          <IconSax.TicketDiscount />
                        </UserAvatarWrap>
                        <div
                          style={{
                            width: '85%',
                          }}
                        >
                          <h3>+{item.meta.phoneNumber}</h3>
                        </div>
                      </UserInfo>
                    </UserCont>
                  ))}
                </>
              ) : (
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '0.7rem',
                    fontWeight: '100',
                  }}
                >
                  No recent airtime purchase.
                </p>
              )}
            </div>
          )}
        </ModalChild>
      </ModalArea>
      {pinModal ? (
        <EnterPin
          closeFunc={handleClosePinModal}
          prevFunc={handleClosePinModal}
          propPin={pin}
          errorMessage={errorMessage}
          handlePinChange={handlePinChange}
          handleFinalPay={handleFinalPay}
          onProcess={finalOnProcess}
          amount={amount}
          type="airtime"
          phoneNumber={phoneNo}
          mode={'airtime'}
        />
      ) : null}

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="Transaction Unsuccessful!!!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeSuccess}
          img="/success.png"
          message="Transaction successful!!!"
          miniMessage={'You have successfully purchased the airtime. '}
        />
      ) : null}

      {result === '' ? (
        <Alert
          closeFunc={closeMessage}
          img="/failed.png"
          message="Changes not successful!"
          miniMessage="Encountered an error while creating your transaction PIN"
        />
      ) : null}
    </>
  );
};

export default BuyArtime;

// Commented for when network provider would be collected in payload.

// const NetworkProviders = [
//   { name: 'Airtel' },
//   { name: 'Glo' },
//   { name: 'MTN' },
//   { name: '9mobile' },
// ];

const header = [
  {
    name: 'Enter Details',
  },
  {
    name: 'Recent Purchases',
  },
];
