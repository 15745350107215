import React, { useEffect, useState } from 'react';
import { MainWrapper } from '../storeComp/mainContent/styles';
import AppHeader from '../storeComp/mainContent/header/header';
import { BreadcrumbArea } from '../editProfile/style';
import {
  ApproveCongratulation,
  AprrovePayment,
  ReferenceNumber,
  SuccessContainer,
} from './style';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Loader from '../reusable/loader';

const SuccessPage = () => {
  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trxref = queryParams.get('trxref');

  const [planReference, setPlanReference] = useState('');
  const [isLoading, setisLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const payload = {
    reference: trxref,
  };

  useEffect(() => {
    setisLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/subscriptions/finalize`,
        payload,
        {
          headers: { Authorization },
        },
      )
      .then(res => {
        setisLoading(false);
        setSuccess(true);
        setPlanReference(res.data.reference);
        setTimeout(() => {
          navigate('/');
        }, 5000);
      })
      .catch(err => {
        setisLoading(false);
        setError(true);
        setTimeout(() => {
          navigate('/upgrade/plan');
        }, 5000);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Authorization, navigate]);

  return (
    <>
      <MainWrapper padding="1px" style={{ width: '100%' }} left="0" top="0rem">
        <AppHeader showBack={true} pageName={''} />
        <BreadcrumbArea
          style={{
            marginTop: '6rem',
          }}
        >
          <SuccessContainer>
            <>
              {isLoading ? (
                <>
                  <Loader text="Validating Subscription" />
                </>
              ) : (
                <>
                  {success && (
                    <>
                      <AprrovePayment>
                        <div>
                          <img src="/mark.png" alt="checked" />
                        </div>
                      </AprrovePayment>
                      <ApproveCongratulation>
                        <h3>Congratulations.</h3>
                        <h3>You’re subscribed to Mage Spark</h3>
                      </ApproveCongratulation>
                      <ReferenceNumber>
                        <p>Reference Number: #{planReference}</p>
                      </ReferenceNumber>
                      {/* <BorderBottom /> */}
                    </>
                  )}

                  {error && (
                    <>
                      <AprrovePayment>
                        <img src="/failed.png" alt="failed" />
                      </AprrovePayment>
                      <ApproveCongratulation>
                        <h3>Failed.</h3>
                        <h3>Your Subscription for Mage Spark Failed!</h3>
                      </ApproveCongratulation>
                      <ReferenceNumber>
                        <p>Reference Number: #{trxref}</p>
                      </ReferenceNumber>
                    </>
                  )}
                </>
              )}
            </>
          </SuccessContainer>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default SuccessPage;
