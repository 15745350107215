import { FlexedApart } from 'app/components/storeComp/sideNav/style';
import axios from 'axios';
import { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import AppHeader from '../../storeComp/mainContent/header/header';
import { MainWrapper } from '../../storeComp/mainContent/styles';
import EmptyState from './../../emptyState/emptyState';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import {
  BreadcrumbArea,
  CustomerName,
  Price,
} from './../../orderComp/mainContent/orderContent/styles';
import NavSection from './navSection';
import {
  EditButton,
  PaginationCont,
  PaginationTextWrap,
  PaginationWrapper,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductWidget,
} from './styles';
import { MoonLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import Alert from 'app/components/dashboard/mainContent/fundWallet/alert';
import Warning from 'reusable/warning';
import { useNavigate } from 'react-router';
import { InAppHeader } from '../../storeComp/mainContent/styles';

const ProductListComp = () => {
  const navigate = useNavigate();
  const store = JSON.parse(localStorage.getItem('store') || '{}');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  type User = {
    user: any;
    setUser: any;
  };

  // States
  const [user, setUser] = useState<any>({});
  const [merches, setMerches] = useState<any>('');
  const [merchResult, setMerchResult] = useState<any>([]);

  type Product = {
    id: any;
  };

  function StoreItem(item) {
    localStorage.setItem('editItem', JSON.stringify(item));
  }

  let [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [totalResults, setTotalResult] = useState<any>(0);

  const handleSearch: any = e => {
    let new_item: any = [];
    if (merches !== '' && merches.results.length !== 0) {
      for (let i = 0; i < merches.results.length; i++) {
        if (
          merches.results[i].name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(merches.results[i]);
        } else if (e.target.value === '') {
          setMerchResult(merches.results);
          setTotalResult(merches.results.length);
        }
      }
      setMerchResult(new_item);
      setTotalResult(new_item.length);
    }
  };

  // Redirect to Single Merch
  const toSingle = (itemId: any) => {
    navigate(`/product/${itemId}`);
  };

  // Pagination Widget

  useEffect(() => {
    if (!user.id) {
      axios
        .get<User[], any>(process.env.REACT_APP_BASE_ENDPOINT + '/users/me', {
          headers: { Authorization },
        })
        .then(res => {
          setUser(res.data);
          if (res.data != null) {
            axios
              .get<Product[], any>(
                process.env.REACT_APP_BASE_ENDPOINT +
                  `/creator-page/${res.data.creatorPage}/merches?sortBy=createdAt:desc`,
              )
              .then(resp => {
                setMerches(resp.data);
                setPage(resp.data.page);
                setTotalPages(resp.data.totalPages);
                setTotalResult(resp.data.totalResults);
                setMerchResult(resp.data.results);
              });
          }
        })
        .catch();
    }
  }, [user, Authorization]);

  const handleIncrement = () => {
    if (page !== totalPages) {
      setPage(++page);
      axios
        .get<Product[], any>(
          process.env.REACT_APP_BASE_ENDPOINT +
            `/creator-page/${loggedUser.user.creatorPage}/merches?page=${page}&sortBy=createdAt:desc`,
        )
        .then(resp => {
          setMerches(resp.data);
          // setPage(resp.data.page);
          setTotalPages(resp.data.totalPages);
          // setTotalResult(resp.data.totalResults);
          setMerchResult(resp.data.results);
        })
        .catch(err => {});
    }
  };

  const handleDecrement = () => {
    if (page > 1) {
      setPage(--page);
      axios
        .get<Product[], any>(
          process.env.REACT_APP_BASE_ENDPOINT +
            `/creator-page/${loggedUser.user.creatorPage}/merches?page=${page}&sortBy=createdAt:desc`,
        )
        .then(resp => {
          setMerches(resp.data);
          setTotalPages(resp.data.totalPages);
          setMerchResult(resp.data.results);
        })
        .catch(err => {});
    }
  };

  // Deletion of Items

  const [warning, setWarning] = useState(false);
  const [productForDelete, setProductForDelete] = useState<any>({});
  const [deleteInProcess, setDeleteInProcess] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleWarningModal = item => {
    setProductForDelete(item);
    setWarning(true);
  };

  const closeFunc = () => {
    setWarning(false);
  };

  const handleDelete = () => {
    // Delete Process
    setDeleteInProcess(true);
    setTimeout(() => {
      setDeleteInProcess(false);
    }, 15000);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/merches/${productForDelete.id}`,
        { headers: { Authorization } },
      )
      .then(res => {
        setSuccess(true);
        setWarning(false);
        axios
          .get<Product[], any>(
            process.env.REACT_APP_BASE_ENDPOINT +
              `/creator-page/${loggedUser.user.creatorPage}/merches?sortBy=createdAt:desc`,
          )
          .then(resp => {
            setDeleteInProcess(false);
            setMerches(resp.data);
            setPage(resp.data.page);
            setTotalPages(resp.data.totalPages);
            setTotalResult(resp.data.totalResults);
            setMerchResult(resp.data.results);
          });
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      })
      .catch(err => {
        setDeleteInProcess(false);
      });
  };

  const closeModal = () => {
    setSuccess(false);
  };

  return (
    <>
      <MainWrapper>
        <AppHeader showBack={true} pageName={`Products(${totalResults})`} />
        <InAppHeader>
          <i>
            <Icon.ArrowLeft onClick={() => navigate(-1)} />
          </i>

          <h3>Products</h3>
        </InAppHeader>
        <NavSection handleSearch={handleSearch} />
        <BreadcrumbArea>
          <ProductWidget>
            {merches !== '' ? (
              <>
                {merches.results.length !== 0 && merchResult.length !== 0 ? (
                  <>
                    {merchResult.map((item, index) => (
                      <ProductCard
                        key={index}
                        onClick={() => toSingle(item.id)}
                      >
                        <FlexedApart>
                          <ProductInfo>
                            {item.images.length === 0 ||
                            item.images === null ||
                            item.images[0] === null ||
                            item.images[0].length === 0 ? (
                              <p>No Image</p>
                            ) : (
                              <ProductImg src={item.images[0]} />
                            )}
                            <div>
                              <CustomerName>{item.name}</CustomerName>
                              <p>{item.quantity} Items in stock.</p>
                            </div>
                          </ProductInfo>
                          <section>
                            <Price>
                              &#8358;
                              {item.price.amount}
                            </Price>
                            <EditButton onClick={e => e.stopPropagation()}>
                              <div className="go-icon">
                                <Link to="/edit-merch">
                                  <Icon.Edit2
                                    onClick={() => StoreItem(item)}
                                    size={20}
                                    strokeWidth={1}
                                  />
                                </Link>
                              </div>
                              <div className="go-icon">
                                <a
                                  href={`https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/product?id=${item.id}`}
                                  target="_blank"
                                  rel="no-referral noreferrer"
                                >
                                  <Icon.LogOut size={20} strokeWidth={1} />
                                </a>
                              </div>
                              <div className="go-icon">
                                <Icon.Trash2
                                  size={20}
                                  color="#c82b38"
                                  strokeWidth={1}
                                  onClick={() => handleWarningModal(item)}
                                />
                              </div>
                            </EditButton>
                          </section>
                        </FlexedApart>
                      </ProductCard>
                    ))}

                    {totalPages > 1 && (
                      <PaginationWrapper>
                        <PaginationCont>
                          <p onClick={() => handleDecrement()}>
                            <Icon.ChevronLeft />
                          </p>
                          <PaginationTextWrap>
                            <h4>{page}</h4>
                            <span>of</span>
                            <h4>{totalPages}</h4>
                          </PaginationTextWrap>
                          <p onClick={() => handleIncrement()}>
                            <Icon.ChevronRight />
                          </p>
                        </PaginationCont>
                      </PaginationWrapper>
                    )}
                  </>
                ) : (
                  <EmptyState img={'product.svg'} />
                )}
              </>
            ) : (
              <CenteredAnimeWrap>
                <MoonLoader size={40} color="#969696" />
              </CenteredAnimeWrap>
            )}
          </ProductWidget>
        </BreadcrumbArea>
      </MainWrapper>

      {warning ? (
        <Warning
          closeFunc={closeFunc}
          productForDelete={productForDelete}
          handleDelete={handleDelete}
          deleteInProcess={deleteInProcess}
          header="Delete Product"
          message="Are you sure you want to delete the added merch from your products?"
          actionText="Confirm Delete"
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Success!"
          miniMessage={'You have deleted an item from the store successfully!'}
        />
      ) : null}
    </>
  );
};

export default ProductListComp;
