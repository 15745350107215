import React from 'react';
import { MainWrapper } from './../invoice/mainInvoice/style';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from './../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import {
  InputWrap,
  SubHeader,
  SubHeaderChild,
} from './../dashboard/mainContent/fundWallet/style';
import { ActionBtn } from './../dashboard/mainContent/fundWallet/style';
import { useNavigate } from 'react-router';

const SenderInformationComp = () => {
  const navigate = useNavigate();

  return (
    <>
      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
      >
        <FragmentHeader
          showBack={true}
          pageName="USD Transfer"
          backLink="/transactions"
        />
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              top="-2rem"
              mobileTop="-2rem"
              style={{ margin: '0 auto' }}
            >
              <SubHeader>
                <SubHeaderChild customBd="#191919">
                  Bank account details
                </SubHeaderChild>
                <SubHeaderChild customBd="">Beneficiaries</SubHeaderChild>
              </SubHeader>
              <div>
                <div className="hide-amount-input">
                  {/* <NumericFormatProps
                    thousandSeparator={true}
                  /> */}
                </div>
                <InputWrap>
                  <label>Sender's First Name</label>
                  <input type="text" placeholder="First Name" />
                </InputWrap>
                <InputWrap>
                  <label>Sender's Last Name</label>
                  <input type="text" placeholder="Last Name" />
                </InputWrap>
                <InputWrap>
                  <label>Sender's Phone Number</label>
                  <input type="text" placeholder="Phone Number" />
                </InputWrap>
                <InputWrap>
                  <label>Sender's Address</label>
                  <input type="text" placeholder="Address" />
                </InputWrap>
                <InputWrap>
                  <label>Sender's Country</label>
                  <input type="text" placeholder="Country" />
                </InputWrap>
              </div>
              <ActionBtn
                style={{
                  justifyContent: 'center',
                  gap: '20px',
                  width: '20rem',
                }}
              >
                <button
                  style={{
                    width: '8rem',
                    background: '#fff',
                    color: '#191919',
                    border: 'none',
                  }}
                  onClick={() => {
                    navigate('/reciever-information');
                  }}
                >
                  Back
                </button>
                <button style={{ width: '8rem', margin: '2rem 0 0 0' }}>
                  Next
                </button>
              </ActionBtn>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default SenderInformationComp;
