import React, { useEffect, useState } from 'react';
import { MainWrapper } from '../invoice/mainInvoice/style';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import { Constraint } from '../invoice/mainInvoice/style';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { InputWrap, BigBtn } from '../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { numOnly } from 'utils/numOnly';
import CommaNumber from 'comma-number';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';
import {
  MobileInputWrap,
  MobileInput,
  MobileSelect,
} from './../../pages/SignUp/styles';
import {
  InputActPlacehold,
  CollabSuggestWrap,
  SplitUsersWrap,
} from './../../components/split/style';
import ReactCountryFlag from 'react-country-flag';
import uuid from 'react-uuid';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';
import { fixedNum, roundTo } from 'utils/roundFloat';
import { NumericFormat } from 'react-number-format';
import { InputTag, SwapInfoCurrency } from './style';
import { handleZero } from 'utils/handleZero';

const SwapCurrency = () => {
  const { currency } = useParams();
  const navigate = useNavigate();
  let loggedUser: any = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [nairaBalance, setNairaBalance] = useState<any>('');
  const [dollarBalance, setDollarBalance] = useState<any>('');
  const [poundBalance, setPoundBalance] = useState<string | number>('');
  const [euroBalance, setEuroBalance] = useState<string | number>();

  // Get Balance
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/account-info?`, {
        headers: { Authorization },
      })
      .then(resp => {
        setNairaBalance(resp.data.balance.naira);
        setDollarBalance(resp.data.balance.dollar);
        setPoundBalance(resp.data.balance.pound);
        setEuroBalance(resp.data.balance.euro);
        localStorage.setItem('accountInfo', JSON.stringify(resp.data));
      })
      .catch(err => {
        if (err.response.data.code === 401) {
          localStorage.clear();
          navigate('/login');
        }
      });
  }, [Authorization, navigate]);

  // Get Country Flag
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [secondInputFocus, setSecondInputFocus] = useState(false);
  const [firstFlag, setFirstFlag] = useState('NG');
  const [firstCurrency, setFirstCurrency] = useState('NGN');
  const [secondFlag, setSecondFlag] = useState('US');
  const [secondCurrency, setSecondCurrency] = useState('USD');
  const [sourceAmount, setSourceAmount] = useState<any>(0);
  const [sourcePayloadAmount, setSourcePayloadAmount] = useState<number>(
    sourceAmount.toString(),
  );

  const [targetAmount, setTargetAmount] = useState<any>(0);
  const [targetPayloadAmount, setTargetPayloadAmount] = useState<number>(
    targetAmount.toString(),
  );
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [baseRate, setBaseRate] = useState<any>({});
  const [rate, setRate] = useState<any>({});
  const [changePoint, setChangePoint] = useState('');

  // Get Default Currency

  useEffect(() => {
    if (currency !== 'NGN') {
      setSecondCurrency(`${currency}`);
      setSecondFlag(currency ? `${currency[0]} ${currency[1]}` : '');
    }
  }, [currency]);

  // Reformat Amount
  useEffect(() => {
    // if (Number(sourceAmount) >) {
    setSourcePayloadAmount(
      Number(
        sourceAmount.toLocaleString().includes(',')
          ? sourceAmount.toLocaleString().replace(new RegExp(',', 'g'), '')
          : sourceAmount,
      ),
    );
    // }
  }, [sourceAmount]);

  // Reformat Amount
  useEffect(() => {
    // if (Number(targetAmount) > 0) {
    setTargetPayloadAmount(
      Number(
        targetAmount.toLocaleString().includes(',')
          ? targetAmount.toLocaleString().replace(new RegExp(',', 'g'), '')
          : targetAmount,
      ),
    );
    // }
  }, [targetAmount]);

  // Get Currency Pair
  const getPair = () => {
    if (Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)) {
      return `${firstCurrency}/${secondCurrency}`;
    } else {
      return `${secondCurrency}/${firstCurrency}`;
    }
  };

  const getAction = () => {
    if (Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)) {
      return `buy`;
    } else {
      return `sell`;
    }
  };

  const payload = {
    sourceAmount: Number(sourcePayloadAmount),
    targetAmount: Number(targetPayloadAmount),
    currencyPair: getPair(),
    action: getAction(),
    idempotentKey: uuid(),
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/exchange-rate`, {
        headers: { Authorization },
      })
      .then(res => {
        setBaseRate(res.data);
        setRate(res.data['NGN/USD']);
      })
      .catch(err => {});
  }, [Authorization]);

  // Rate based on currency pair
  useEffect(() => {
    try {
      if (firstCurrency === 'NGN') {
        setRate(baseRate[`NGN/${secondCurrency}`]);
      } else if (secondCurrency === 'NGN') {
        setRate(baseRate[`NGN/${firstCurrency}`]);
      } else if (firstCurrency !== 'NGN' && secondCurrency !== 'NGN') {
        if (
          Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)
        ) {
          setRate(baseRate[`${firstCurrency}/${secondCurrency}`]);
        } else {
          setRate(baseRate[`${secondCurrency}/${firstCurrency}`]);
        }
      }
    } catch {}
  }, [firstCurrency, secondCurrency, baseRate]);

  // Get and Calculate Target Value

  useEffect(() => {
    if (changePoint === 'first') {
      if (
        Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)
      ) {
        if (sourceAmount && sourceAmount.includes(',')) {
          setTargetAmount(
            fixedNum(Number(sourcePayloadAmount) * (1 / rate.buy), 2),
          );
        } else {
          setTargetAmount(fixedNum(Number(sourceAmount) * (1 / rate.buy), 2));
        }
      } else if (
        Object.keys(baseRate).includes(`${secondCurrency}/${firstCurrency}`)
      ) {
        if (sourceAmount && sourceAmount.includes(',')) {
          setTargetAmount(fixedNum(Number(sourcePayloadAmount) * rate.sell, 2));
        } else {
          setTargetAmount(fixedNum(Number(sourceAmount) * rate.sell, 2));
        }
      }
    }
  }, [
    baseRate,
    changePoint,
    firstCurrency,
    rate,
    secondCurrency,
    sourceAmount,
    sourcePayloadAmount,
  ]);

  const secondCalculation = e => {
    if (Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)) {
      if (e && e.includes(',')) {
        setSourceAmount(
          fixedNum(Number(e.replace(new RegExp(',', 'g'), '')) * rate.buy, 2),
        );
      } else {
        setSourceAmount(fixedNum(Number(e) * rate.buy, 2));
      }
    } else if (
      Object.keys(baseRate).includes(`${secondCurrency}/${firstCurrency}`)
    ) {
      if (e && e.includes(',')) {
        setSourceAmount(
          fixedNum(
            Number(e.replace(new RegExp(',', 'g'), '')) * (1 / rate.sell),
            2,
          ),
        );
      } else {
        setSourceAmount(fixedNum(Number(e) * (1 / rate.sell), 2));
      }
    }
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const postFunction = () => {
    setOnProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/payments/currency-swap`,
        payload,
        headers,
      )
      .then(res => {
        setOnProcess(false);
        setSuccess(true);
        setTimeout(() => {
          navigate('/');
        }, 6000);
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };
  const swapCurrency = e => {
    e.preventDefault();
    if (firstCurrency === 'NGN') {
      if (Number(sourcePayloadAmount) <= Number(nairaBalance)) {
        postFunction();
      } else {
        setError(`Sorry, you don't have enough to perform this transaction!`);
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    } else if (firstCurrency === 'USD') {
      if (Number(sourcePayloadAmount) <= Number(dollarBalance)) {
        postFunction();
      } else {
        setError(`Sorry, you don't have enough to perform this transaction!`);
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    } else if (firstCurrency === 'GBP') {
      if (Number(sourcePayloadAmount) <= Number(poundBalance)) {
        postFunction();
      } else {
        setError(`Sorry, you don't have enough to perform this transaction!`);
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    } else if (firstCurrency === 'EUR') {
      if (Number(sourcePayloadAmount) <= Number(euroBalance)) {
        postFunction();
      } else {
        setError(`Sorry, you don't have enough to perform this transaction!`);
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    }
  };

  // Get Balance Display
  const getBalance = () => {
    try {
      switch (firstCurrency) {
        case 'USD':
          return dollarBalance;
        case 'NGN':
          return nairaBalance;
        case 'GBP':
          return poundBalance;
        case 'EUR':
          return euroBalance;
        default:
          break;
      }
    } catch {}
  };

  const getSign = currency => {
    try {
      switch (currency) {
        case 'USD':
          return '$';
        case 'NGN':
          return '₦';
        case 'GBP':
          return '£';
        case 'EUR':
          return '€';
        default:
          break;
      }
    } catch {}
  };

  const renderRateInfo = () => {
    if (
      rate &&
      Object.keys(rate).length > 0 &&
      Object.keys(baseRate).length > 0
    ) {
      if (
        Object.keys(baseRate).includes(`${firstCurrency}/${secondCurrency}`)
      ) {
        return `${getSign(firstCurrency)}1 = ${getSign(
          secondCurrency,
        )}${roundTo(1 / rate.buy, 5)}`;
      }
      if (
        Object.keys(baseRate).includes(`${secondCurrency}/${firstCurrency}`)
      ) {
        return `${getSign(firstCurrency)}1 = ${getSign(secondCurrency)}${
          rate.sell
        }`;
      }
    }
  };

  return (
    <>
      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            navigate('/');
          }}
          img="/success.png"
          message="Success!"
          miniMessage={`Your currency swap from ${firstCurrency} to ${secondCurrency} was successful! `}
        />
      ) : null}

      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
        onClick={() => {
          setIsInputFocus(false);
          setSecondInputFocus(false);
        }}
      >
        <FragmentHeader showBack={true} pageName="Swap Currency" />
        <BreadcrumbArea>
          <form onSubmit={e => swapCurrency(e)}>
            <PreferenceBlock>
              <Constraint
                top="-2rem"
                mobileTop="-2rem"
                style={{ margin: '0 auto' }}
              >
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <label></label>
                    <p
                      style={{
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '16px',
                        letterSpacing: '-0.02em',
                        color: '#ABABAB',
                      }}
                    >
                      <span>
                        Balance: {getSign(firstCurrency)}
                        {CommaNumber(roundTo(getBalance(), 2))}
                      </span>
                    </p>
                  </div>
                  <MobileInputWrap>
                    <MobileSelect>
                      <div
                        style={{
                          width: '6rem',
                        }}
                      >
                        <InputActPlacehold
                          onClick={e => {
                            setIsInputFocus(!isInputFocus);
                            e.stopPropagation();
                          }}
                          onBlur={() => setIsInputFocus(false)}
                        >
                          <div>
                            <ReactCountryFlag
                              style={{
                                fontSize: '1.3rem',
                                borderRadius: '50%',
                              }}
                              countryCode={firstFlag}
                              svg
                              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                              cdnSuffix="svg"
                            />
                            <p
                              style={{
                                color: '#191919',
                              }}
                            >
                              {firstCurrency}
                            </p>
                          </div>
                          <i>
                            <Icon.ChevronDown size={15} strokeWidth={3} />
                          </i>
                        </InputActPlacehold>
                      </div>
                    </MobileSelect>
                    <CollabSuggestWrap
                      style={{
                        margin: '0rem 0 0 0',
                        width: '8rem',
                        zIndex: '1000000000',
                        top: '2rem',
                      }}
                    >
                      {isInputFocus ? (
                        <div>
                          {Countries && Countries.length > 0 ? (
                            Countries.slice(0, 2).map((item, index) => (
                              <SplitUsersWrap
                                alignVertical="flex-start"
                                key={index}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (item.code !== secondFlag) {
                                    setFirstFlag(item.code);
                                    setFirstCurrency(item.name);
                                  } else {
                                    setFirstFlag(item.code);
                                    setSecondFlag(firstFlag);
                                    setFirstCurrency(item.name);
                                    setSecondCurrency(firstCurrency);
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '10px',
                                      width: '100%',
                                    }}
                                  >
                                    <ReactCountryFlag
                                      style={{
                                        fontSize: '1.3rem',
                                        borderRadius: 'none',
                                      }}
                                      countryCode={item.code}
                                      svg
                                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                      cdnSuffix="svg"
                                      title={item.code}
                                    />
                                    <h3
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '25%',
                                      }}
                                    >
                                      {item.name ? `${item.name}        ` : ''}
                                    </h3>
                                  </div>
                                </div>
                              </SplitUsersWrap>
                            ))
                          ) : (
                            <p
                              style={{
                                textAlign: 'center',
                                fontSize: '14px',
                                color: '#757575',
                                margin: '1rem 0 0 0',
                              }}
                            >
                              No Option.
                            </p>
                          )}
                        </div>
                      ) : null}
                    </CollabSuggestWrap>
                    <div className="hide-amount-input">
                      <NumericFormat
                        value={sourceAmount}
                        thousandSeparator={true}
                        onValueChange={e => setSourceAmount(e.formattedValue)}
                      />
                    </div>
                    <MobileInput
                      type="text"
                      value={sourceAmount}
                      onKeyPress={e => {
                        numOnly(e);
                        handleZero(e);
                      }}
                      onChange={e => setSourceAmount(e.target.value)}
                      onFocus={() => setChangePoint('first')}
                      required={true}
                      autoComplete={'off'}
                    />
                    <InputTag>You pay</InputTag>
                  </MobileInputWrap>
                  <SwapInfoCurrency>
                    <section></section>
                    <div>
                      <hr />
                      <div>
                        <IconSax.Repeat size={11} color="#ababab" />
                      </div>
                      <p>{renderRateInfo()}</p>
                    </div>
                    <div>
                      <hr />
                      <div>
                        <Icon.X size={11} color="#ababab" />
                      </div>
                      <p>Fee = ₦0</p>
                    </div>
                  </SwapInfoCurrency>
                  {/* Other Currency (Swap To) */}
                  <InputWrap>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <label></label>
                    </div>
                  </InputWrap>
                  <MobileInputWrap>
                    <MobileSelect>
                      <div
                        style={{
                          width: '6rem',
                        }}
                      >
                        <InputActPlacehold
                          onClick={e => {
                            setSecondInputFocus(!secondInputFocus);
                            e.stopPropagation();
                          }}
                          onBlur={() => setSecondInputFocus(false)}
                        >
                          <div>
                            <ReactCountryFlag
                              style={{
                                fontSize: '1.3rem',
                                borderRadius: '50%',
                              }}
                              countryCode={secondFlag}
                              svg
                              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                              cdnSuffix="svg"
                            />
                            <p
                              style={{
                                color: '#191919',
                              }}
                            >
                              {secondCurrency}
                            </p>
                          </div>
                          <i>
                            <Icon.ChevronDown size={15} strokeWidth={3} />
                          </i>
                        </InputActPlacehold>
                      </div>
                    </MobileSelect>
                    <div className="hide-amount-input">
                      <NumericFormat
                        value={targetAmount}
                        thousandSeparator={true}
                        onValueChange={e => setTargetAmount(e.formattedValue)}
                      />
                    </div>
                    <CollabSuggestWrap
                      style={{
                        margin: '0rem 0 0 0',
                        width: '8rem',
                        zIndex: '1000000000',
                        top: '2rem',
                      }}
                    >
                      {secondInputFocus ? (
                        <div>
                          {Countries && Countries.length > 0 ? (
                            Countries.slice(0, 2).map((item, index) => (
                              <SplitUsersWrap
                                alignVertical="flex-start"
                                key={index}
                                style={{
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  if (item.code !== firstFlag) {
                                    setSecondFlag(item.code);
                                    setSecondCurrency(item.name);
                                  } else {
                                    setSecondFlag(item.code);
                                    setFirstFlag(secondFlag);
                                    setSecondCurrency(item.name);
                                    setFirstCurrency(secondCurrency);
                                  }
                                }}
                              >
                                <div
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '10px',
                                      width: '100%',
                                    }}
                                  >
                                    <ReactCountryFlag
                                      style={{
                                        fontSize: '1.3rem',
                                        borderRadius: 'none',
                                      }}
                                      countryCode={item.code}
                                      svg
                                      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                      cdnSuffix="svg"
                                      title={item.code}
                                    />
                                    <h3
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '25%',
                                      }}
                                    >
                                      {item.name ? `${item.name}        ` : ''}
                                    </h3>
                                  </div>
                                </div>
                              </SplitUsersWrap>
                            ))
                          ) : (
                            <p
                              style={{
                                textAlign: 'center',
                                fontSize: '14px',
                                color: '#757575',
                                margin: '1rem 0 0 0',
                              }}
                            >
                              No Option.
                            </p>
                          )}
                        </div>
                      ) : null}
                    </CollabSuggestWrap>
                    <MobileInput
                      type="text"
                      value={targetAmount}
                      onKeyPress={e => {
                        numOnly(e);
                        handleZero(e);
                      }}
                      onChange={e => {
                        setTargetAmount(e.target.value);
                        secondCalculation(e.target.value);
                      }}
                      onFocus={() => setChangePoint('second')}
                      required={true}
                      autoComplete={'off'}
                    />
                    <InputTag>You receive</InputTag>
                  </MobileInputWrap>
                </div>
                {error ? (
                  <p
                    style={{
                      color: '#c82b32',
                      fontSize: '12px',
                      margin: '2rem 0 0 0',
                    }}
                  >
                    {error}
                  </p>
                ) : null}
                <BigBtn width="100%">
                  <button type="submit" disabled={onProcess}>
                    {onProcess ? <BeatLoader color="#fff" /> : 'Swap Currency'}
                  </button>
                </BigBtn>
              </Constraint>
            </PreferenceBlock>
          </form>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default SwapCurrency;
export const Countries = [
  {
    name: 'NGN',
    code: 'NG',
  },
  {
    name: 'USD',
    code: 'US',
  },
  {
    name: 'GBP',
    code: 'GB',
  },
  {
    name: 'EUR',
    code: 'EU',
  },
];
