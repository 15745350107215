import React, { useEffect, useState } from 'react';
import { ModalArea, ModalChild, Header } from './style';
import { BounceLoader } from 'react-spinners';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import axios from 'axios';

interface PropsArgs {
  action: any;
}

const ReLoginPin = ({ action }: PropsArgs) => {
  const inputStyle = {
    width: '1rem',
    height: '1rem',
    borderRadius: '10rem',
    textAlign: 'center',
    fontWeight: 'bold',
    margin: 8,
    display: 'flex',
    alignitems: 'Center',
    justifyContent: 'center',
  };

  const [pin, setPin] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [clientErr, setClientErr] = useState('');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const TimeoutTime = 86400000;

  const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  useEffect(() => {
    if (pin && pin.length === 4) {
      try {
        const basicPayload = {
          userId: loggedUser.user.id,
          pin,
        };
        const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
        if (pin && pin.length === 4) {
          setOnProcess(true);
          axios
            .post(
              `${process.env.REACT_APP_BASE_ENDPOINT}/auth/pin-login`,
              basicPayload,
              {
                headers: {
                  Authorization: Authorization,
                },
              },
            )
            .then(res => {
              setOnProcess(false);
              loggedUser.tokens = res.data.tokens;
              localStorage.setItem('logged_user', JSON.stringify(loggedUser));
              localStorage.setItem('dhixabu', JSON.stringify(false));
              localStorage.setItem(
                'expire_time',
                JSON.stringify(Date.now() + TimeoutTime),
              );
              action();
            })
            .catch(err => {
              setOnProcess(false);
              setPin('');
              setClientErr(
                'An error occurred, please try again or check your PIN!',
              );
              setTimeout(() => {
                setClientErr('');
              }, 4000);
            });
        } else {
          setPin('');
          setClientErr('PIN must be four characters!');
          setTimeout(() => {
            setClientErr('');
          }, 4000);
        }
      } catch {}
    }
  }, [action, loggedUser, pin]);

  return (
    <>
      <ModalArea
        style={{
          display: 'none',
        }}
      >
        <ModalChild>
          <Header></Header>
          {!onProcess ? (
            <>
              <div>
                <h1
                  className="my-5  text-center text-xl font-bold text-baseDark"
                  style={{ fontFamily: 'Raleway' }}
                >
                  Enter your PIN
                </h1>
                <p
                  style={{
                    width: '20rem',
                    margin: '0 0 2rem 0',
                    color: 'grey',
                    textAlign: 'center',
                  }}
                >
                  Use your PIN code to log into your dashboard
                </p>
                <PinInputWrap>
                  <OtpInput
                    value={pin}
                    onChange={code => setPin(code)}
                    numInputs={4}
                    inputStyle={inputStyle}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    // isInputSecure={true}
                    className="otp-input"
                  />
                </PinInputWrap>
              </div>
              {clientErr && (
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '13px',
                    color: '#c82b38',
                    margin: '1rem 0 0 0',
                  }}
                >
                  {clientErr}
                </p>
              )}
            </>
          ) : null}
          {onProcess ? (
            <div
              style={{
                position: 'relative',
                margin: '1rem auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '20rem',
                gap: '20px',
              }}
            >
              <BounceLoader color={'#c7c7c7'} size={70} />
              <p>Logging you in...</p>
            </div>
          ) : null}
          {!onProcess ? (
            <p
              style={{
                textAlign: 'center',
                margin: '2rem 0 0 0',
                cursor: 'pointer',
              }}
              onClick={() => logout()}
            >
              Logout
            </p>
          ) : null}
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default ReLoginPin;

const PinInputWrap = styled.div`
  width: 10rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 0 0;
  background: #f3f3f3;
  border-radius: 35.6541px;

  @media (max-width: 728px) {
    width: 10remrea;
  }
`;
