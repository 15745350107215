import React, { useState, useEffect } from 'react';
import AppHeader from '../storeComp/mainContent/header/header';
import { MainWrapper } from '../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { InAppHeader } from '../storeComp/mainContent/styles';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { useNavigate } from 'react-router';
import uuid from 'react-uuid';
import Blocked from '../transactions/blocked';
import * as IconSax from 'iconsax-react';
import styled from 'styled-components';
import { Form, Input, Button, Select, Typography } from 'antd';
import Store from 'utils/store';
import UsFlag from 'img/us-flag.png';
import formatCurrency from 'utils/formatCurrency';
import { numOnly } from 'utils/numOnly';
import { DollarSign } from 'react-feather';
import WaitLoader from '../actual-components/WaitLoader';

const RequestCard = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [fetching, setFetching] = useState(false);
  const [cardType, setCardType] = useState<String>('personal');
  const [cardBrand, setCardBrand] = useState<String>('Mastercard');
  const [step, setStep] = useState('step 1');
  const [newCard, setNewCard] = useState<any>({});
  const loggedUser = Store.get('logged_user');
  const accountInfo = Store.get('accountInfo');

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [blocked, setBlocked] = useState(false);
  const [tier, setTier] = useState('');
  // const [fundAmount, setFundAmount] = useState<any>('');
  const [totalAmount, setTotalAmount] = useState<any>(3);
  const kyc = JSON.parse(localStorage.getItem('cky') || '{}');

  useEffect(() => {
    if (kyc && Object.keys(kyc)) {
      setTier(kyc.level);
    }
  }, [kyc]);

  // Create Card Function
  const idempotentKey = uuid();

  const createCard = () => {
    const payload = {
      wallet: 'USD',
      brand: cardBrand,
      businessCard: cardType !== 'personal',
      cardName: newCard.cardName,
      idempotentKey,
    };
    console.log(payload);
    Object.keys(payload).forEach((key: string) => {
      if (!payload[key]) delete payload[key];
    });
    if (tier.toLowerCase() === 'tier 3') {
      setFetching(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/payments/cards`,
          payload,
          headers,
        )
        .then(res => {
          setFetching(false);
          setSuccess(true);
          if (Store.get('cards')) {
            Store.pushItem('cards', res);
          }
        })
        .catch(err => {
          setError(err.response.data.message);
          setFetching(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    } else {
      setBlocked(true);
    }
  };

  const nextStep = values => {
    const newCardUpdate = { ...newCard, ...values };
    setNewCard(newCardUpdate);
    if (step === 'step 1') {
      setStep('step 2');
    } else {
      // Create card
      createCard();
    }
  };

  return (
    <>
      {error ? (
        <Alert
          closeFunc={() => {
            setError('');
            setFetching(false);
          }}
          img="/failed.png"
          message="An error occurred"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={() => {
            navigate('/virtual-cards');
          }}
          img="/success.png"
          message="Card created!"
          miniMessage={
            'Your virtual card is been processed and would be ready within an hour!'
          }
        />
      ) : null}

      <MainWrapper mobileTop="4rem">
        <AppHeader showBack={true} pageName={`Card`} />
        <InAppHeader
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          mobileTop="-1rem"
        >
          <h3
            style={{ cursor: 'pointer' }}
            onClick={() => navigate('/virtual-cards')}
          >
            Cards
          </h3>
        </InAppHeader>
        <hr style={{ marginTop: 80, position: 'relative' }} />
        <BreadcrumbArea
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 0,
          }}
        >
          {/* First step of card creation process */}
          {step === 'step 1' && (
            <CardBox>
              <div className="header">
                <h1>Customize your card</h1>
                <p>Suitable for all your online transactions</p>
              </div>
              <Form
                layout="vertical"
                onFinish={nextStep}
                className="create-form"
                style={{
                  margin: '0 auto',
                  padding: '20px',
                  marginTop: 50,
                }}
              >
                <Form.Item
                  label="Card Type"
                  name="cardType"
                  rules={[
                    {
                      required: false,
                      message: 'Please select your card type!',
                    },
                  ]}
                  initialValue={'personal'}
                >
                  <Select
                    className="select"
                    placeholder="Select a card type"
                    defaultValue={'personal'}
                    variant="filled"
                    onChange={e => setCardType(e)}
                  >
                    <Select.Option
                      style={{ fontSize: 16, padding: 10 }}
                      value="personal"
                      selected={true}
                    >
                      Personal Card
                    </Select.Option>
                    <Select.Option
                      style={{ fontSize: 16, padding: 10 }}
                      value="business"
                    >
                      Business Card
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Card Name"
                  name="cardName"
                  rules={[
                    {
                      required: false,
                      message: 'Please input your card name!',
                    },
                  ]}
                  initialValue={`${loggedUser.user.firstName} ${loggedUser.user.lastName}`}
                >
                  <Input
                    variant="filled"
                    className="input"
                    placeholder="Company Expense"
                    disabled={cardType === 'personal'}
                    defaultValue={`${loggedUser.user.firstName} ${loggedUser.user.lastName}`}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{ backgroundColor: 'black', borderColor: 'black' }}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </CardBox>
          )}
          {/* End of first step of card creation process */}
          {/* Second step of card creation process */}
          {step === 'step 2' && (
            <CardBox>
              <div className="header">
                <h1>Create Virtual Card</h1>
                <p>Suitable for all your online transactions</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  marginBottom: 20,
                  marginTop: 20,
                  border: '1px solid #D8D8D8',
                  padding: '10px 20px',
                  borderRadius: 10,
                }}
              >
                <div style={{ marginRight: 20 }}>
                  <span
                    style={{
                      position: 'relative',
                      height: 30,
                      width: 30,
                      background: 'black',
                      borderRadius: '50%',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      top: 1,
                    }}
                  >
                    <DollarSign style={{ width: '20px', color: '#fff' }} />
                  </span>
                  <Typography.Text style={{ position: 'relative', top: -6 }}>
                    {' '}
                    $0.5 transaction fees
                  </Typography.Text>
                </div>
                <div>
                  <span
                    style={{
                      position: 'relative',
                      height: 30,
                      width: 30,
                      background: 'black',
                      borderRadius: '50%',
                      display: 'inline-flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                      top: 1,
                    }}
                  >
                    <IconSax.Card style={{ width: '20px', color: '#fff' }} />
                  </span>
                  <Typography.Text style={{ position: 'relative', top: -6 }}>
                    {' '}
                    $3 card creation fee
                  </Typography.Text>
                </div>
              </div>

              <Form layout="vertical" onFinish={nextStep}>
                <Form.Item
                  name={'cardBrand'}
                  label="Card Brand"
                  initialValue={cardBrand}
                >
                  <Select
                    variant="filled"
                    className="select"
                    defaultValue={cardBrand}
                    onChange={value => setCardBrand(value)}
                  >
                    <Select.Option value="Visa">VISA</Select.Option>
                    <Select.Option value="Mastercard">MasterCard</Select.Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item label="Fund Card" name={'amount'}>
                  <div
                    style={{
                      textAlign: 'right',
                      marginTop: 5,
                      position: 'absolute',
                      right: 0,
                      top: -35,
                    }}
                  >
                    <Typography.Text style={{ color: '#ABABAB' }}>
                      USD Balance:{' '}
                      {formatCurrency(accountInfo.balance.dollar, 'USD')}
                    </Typography.Text>
                  </div>
                  <div
                    style={{
                      width: 80,
                      background: '#e0e0e0',
                      height: 50,
                      position: 'absolute',
                      left: 0,
                      borderRadius: '5px 0px 0px 5px',
                      display: 'flex',
                      flexDirection: 'column',
                      zIndex: 10,
                    }}
                  >
                    <img
                      src={UsFlag}
                      alt="flag"
                      style={{
                        width: 28,
                        padding: 0,
                        position: 'relative',
                        left: 8,
                        top: 'calc(50% - 10px)',
                      }}
                    />{' '}
                    <span style={{ marginLeft: 42, marginTop: -5 }}>USD</span>
                  </div>
                  <Input
                    placeholder="0.00"
                    variant="filled"
                    value={fundAmount}
                    style={{
                      paddingLeft: 85,
                      fontSize: 16,
                      height: 50,
                      position: 'relative',
                      zIndex: 5,
                    }}
                    onKeyPress={e => numOnly(e)}
                    onChange={e => {
                      setFundAmount(e.target.value);
                    }}
                    onMouseLeave={() => setTotalAmount(fundAmount + 3)}
                  />
                </Form.Item> */}
                <Typography.Text
                  style={{
                    top: -20,
                    position: 'relative',
                    borderRadius: 25,
                    background: '#f4f4f6',
                    padding: 5,
                    color: '#898A8D',
                    margin: '10px 0',
                    display: 'inline-block',
                  }}
                >
                  Card Creation fee: $3
                </Typography.Text>
                {accountInfo.balance.dollar < totalAmount && (
                  <Typography.Text
                    style={{
                      color: 'brown',
                      fontSize: 12,
                      display: 'block',
                      textAlign: 'center',
                      position: 'relative',
                      top: -10,
                    }}
                  >
                    Not enough balance for card creation and funding
                  </Typography.Text>
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{
                      backgroundColor: 'black',
                      borderColor: 'black',
                      color: '#fff',
                      fontSize: 16,
                    }}
                    disabled={accountInfo.balance.dollar < totalAmount}
                  >
                    {fetching ? <WaitLoader /> : 'Create Card'}
                  </Button>
                </Form.Item>
              </Form>
            </CardBox>
          )}
          {/* End of second step of card creation process */}
        </BreadcrumbArea>
        {blocked ? <Blocked closeFunc={() => setBlocked(false)} /> : null}
      </MainWrapper>
    </>
  );
};

export default RequestCard;

const CardBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 20px;
  flex-direction: column;
  width: auto;
  margin-top: 50px;
  > .header {
    text-align: center;
    h1 {
      font-size: 24px;
      font-weight: bold;
    }
  }
  Form {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 30px;
    padding: 20px;
    font-size: 16px;
    box-shadow: '0 2px 8px rgba(0, 0, 0, 0.1)';
    min-width: 440px;
    @media (max-width: 560px) {
      min-width: 350px;
    }
    @media (max-width: 400px) {
      min-width: 320px;
    }
    @media (max-width: 340px) {
      min-width: 300px;
    }
    @media (max-width: 320px) {
      min-width: 280px;
    }
    @media (max-width: 300px) {
      min-width: 260px;
    }
    Input {
      height: 50px;
      font-size: 16px;
      border: none;
      &:active,
      &:hover {
        border: 1px solid #f2f2f2;
        box-shadow: none;
      }
    }
    Button {
      height: 50px;
    }
  }
  .select {
    height: 50px;
    font-size: 16px;
    border: none;
    &:active,
    &:hover {
      border: none;
      box-shadow: none;
    }
  }
`;

export const cardArray = [
  {
    name: 'Mastercard',
    img: '/mastercard.png',
  },
  {
    name: 'Visa',
    img: '/visa.png',
  },
];
