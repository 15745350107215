import { useState, useEffect } from 'react';
import FileToUrl from 'utils/apis/fileToUrl';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MerchroImg from '../../../img/merchro-logo.png';
import PopUpModal from 'app/components/merch/popupModal';
import { GenericInput } from 'app/components/Preference/mainContent/preferenceContent/styles';
import Select from 'react-select';
import { nationalities } from './nationalties';
import { GenericBtn } from 'app/components/editProfile/style';
import { BeatLoader } from 'react-spinners';
import NextOfKinModal from 'app/components/editProfile/nextOfKin';
import AddressModal from 'app/components/editProfile/addressModal';

const KYC1 = () => {
  const history = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [clientErr, setClientErr] = useState('');

  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [nationality, setNationality] = useState<any>('');
  const [maritalStatus, setMaritalStatus] = useState('');

  // next of kin section

  const [relationship, setRelationship] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [address, setAddress] = useState('');

  // address section
  const [street, setStreet] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');

  const [onProcess, setOnProcess] = useState(false);

  const [nations, setNations] = useState<any>([]);

  useEffect(() => {
    const newNation: any = [];
    for (let i = 0; i < nationalities.length; i++) {
      newNation.push({ value: nationalities[i] });
    }
    setNations([...newNation]);
  }, []);

  // Modal Opening and close
  const [nextModal, setNextModal] = useState(false);
  const [addressModal, setAddressmodal] = useState(false);
  const toggleNextModal = e => {
    e.preventDefault();
    setNextModal(!nextModal);
  };
  const toggleAddressModal = () => {
    setAddressmodal(!addressModal);
  };

  // verification section

  const patchData = {
    gender: gender,
    dob: dob,
    nationality: nationality,
    maritalStatus: maritalStatus,
    address: {
      street: street,
      city: city,
      state: state,
      country: country,
    },
    nextOfKin: {
      firstName: firstName,
      lastName: lastName,
      relationship: relationship,
      email: email,
      phoneNumber: phoneNo,
      address: address,
    },
  };

  var loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
  const token = loggedUser.tokens.access.token;
  const onboard = JSON.parse(localStorage.getItem('onboard') || '{}');

  let profilePicture: any = '';
  const handleOnboard = async () => {
    setOnProcess(true);
    if (onboard.profilePictureDataImg) {
      profilePicture = await FileToUrl({
        file: onboard.profilePictureDataImg,
        token,
      });
    }
    const storeInfo: any = {
      name: onboard.username,
      metadata: {
        description: onboard.bio,
      },
      avatar: profilePicture,
    };

    await axios
      .post(`${process.env.REACT_APP_BASE_ENDPOINT}/creator-page`, storeInfo, {
        headers: {
          Authorization: Authorization,
        },
      })
      .then(res => {
        axios
          .get(`${process.env.REACT_APP_BASE_ENDPOINT}/users/me`, {
            headers: {
              Authorization: Authorization,
            },
          })
          .then(resp => {
            localStorage.setItem('logged_user', JSON.stringify(resp.data));
            history(`/`);
          })
          .catch(err => {});
      })
      .catch(err => {
        setOnProcess(false);
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 4000);
      });
  };

  const handleAll = () => {
    if (
      gender !== '' &&
      dob !== '' &&
      nationality !== '' &&
      maritalStatus !== '' &&
      street !== '' &&
      city !== '' &&
      state !== '' &&
      country !== '' &&
      firstName !== '' &&
      lastName !== '' &&
      relationship !== '' &&
      email !== '' &&
      phoneNo !== '' &&
      address !== ''
    ) {
      setOnProcess(true);
      setTimeout(() => {
        setOnProcess(false);
      }, 4000);
      axios
        .patch(
          process.env.REACT_APP_BASE_ENDPOINT + '/users/' + loggedUser.user.id,
          patchData,
          {
            headers: { Authorization },
          },
        )
        .then(res => {
          setOnProcess(false);
          handleOnboard();
        })
        .catch(err => {
          setOnProcess(false);
        });
    } else {
      setClientErr('Please fill out all form!!!');
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  const handleClose = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Helmet>
        <title>Set up your wallet</title>
        <meta name="description" content="create your store" />
      </Helmet>
      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="Operation unsuccessful"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="#c82b32"
        />
      ) : null}
      <MainWrapper>
        <LogoImage src={MerchroImg} alt="logo" />
        <Inner>
          <H1>Set up my account</H1>
          <HeaderP>
            It takes less than two minutes to set your account up. Let's get
            started.
          </HeaderP>

          <FormLabelHeader htmlFor="username">
            Gender<span style={{ color: 'red' }}> * </span>
            <Select
              options={genders}
              getOptionLabel={p => p.value}
              onChange={e => setGender((e as HTMLSelectElement).value)}
            />
          </FormLabelHeader>
          <FormLabelHeader htmlFor="username">
            Date of birth<span style={{ color: 'red' }}> * </span>
            <GenericInput type="date" onChange={e => setDob(e.target.value)} />
          </FormLabelHeader>
          <FormLabelHeader htmlFor="username">
            Nationality<span style={{ color: 'red' }}> * </span>
            <Select
              options={nations}
              getOptionLabel={p => (p as HTMLSelectElement).value}
              onChange={e => setNationality((e as HTMLSelectElement).value)}
            />
          </FormLabelHeader>
          <FormLabelHeader htmlFor="username">
            Marital status<span style={{ color: 'red' }}> * </span>
            <Select
              options={mStatus}
              getOptionLabel={p => p.value}
              onChange={e => setMaritalStatus((e as HTMLSelectElement).value)}
            />
          </FormLabelHeader>
          <FormLabelHeader htmlFor="username">
            Address<span style={{ color: 'red' }}> * </span>
            <GenericBtn onClick={() => toggleAddressModal()}>
              {city !== '' && state !== '' && country !== '' && street !== ''
                ? street + ', ' + state
                : 'Address'}
            </GenericBtn>
          </FormLabelHeader>
          <FormLabelHeader htmlFor="username">
            Next of Kin<span style={{ color: 'red' }}> * </span>
            <GenericBtn onClick={e => toggleNextModal(e)}>
              {firstName !== '' &&
              lastName !== '' &&
              phoneNo !== '' &&
              email !== '' &&
              relationship !== '' &&
              address !== ''
                ? `${firstName} ${lastName}`
                : 'Next of Kin'}
            </GenericBtn>
          </FormLabelHeader>
          <SendButton onClick={() => handleAll()}>
            {onProcess ? <BeatLoader color="white" /> : 'Next'}
          </SendButton>
          <p style={{ color: '#c82b38' }}>{clientErr}</p>
        </Inner>
      </MainWrapper>

      {nextModal ? (
        <NextOfKinModal
          closeFunc={toggleNextModal}
          setRelationship={setRelationship}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
          setPhoneNo={setPhoneNo}
          setAddress={setAddress}
        />
      ) : null}

      {addressModal ? (
        <AddressModal
          closeFunc={toggleAddressModal}
          setStreet={setStreet}
          setState={setState}
          setCity={setCity}
          state={''}
          country={''}
          setCountry={setCountry}
        />
      ) : null}
    </>
  );
};

export default KYC1;

const genders = [{ value: 'Male' }, { value: 'Female' }];

const mStatus = [
  { value: 'Single' },
  { value: 'Married' },
  { value: 'Divorced' },
  { value: 'Separated' },
  { value: 'Widowed' },
];

const LogoImage = styled.img`
  position: absolute;
  left: 20px;
  top: 20px;
  height: 3rem;

  @media screen and (max-width: 600px) {
    left: 33%;
    top: 35px;
    transform: left(-25%);
  }
  @media screen and (max-width: 400px) {
    transform: scale(0.8) translateX(-15%) translateY(-20%);
  }
`;

const MainWrapper = styled.div`
  padding: 100px 10px;
  @media screen and (max-width: 400px) {
    padding: 100px 0px;
  }
`;

const Inner = styled.div`
  background: #fdfdfd33;
  border: 0.5px solid #0000000d;
  border-radius: 10px;
  width: 600px;
  margin: 0 auto 0;
  padding: 30px 80px 80px;

  @media screen and (max-width: 900px) {
    width: 500px;
    padding: 30px 60px 80px;
  }
  @media screen and (max-width: 600px) {
    width: 400px;
    padding: 30px 30px 60px;
  }
  @media screen and (max-width: 400px) {
    width: 100%;
    padding: 40px 20px 50px;
    border: none;
    border-radius: 0;
    border-top: 0.5px solid #0000000d;
    border-bottom: 0.5px solid #0000000d;
  }
`;

const H1 = styled.h1`
  font-family: Inter;
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #000;
  margin-bottom: 12px;

  @media screen and (max-width: 600px) {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 400px) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }
`;

const HeaderP = styled.p`
  font-family: Inter;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: left;
  color: #2b2b2b;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 13px;
  }
`;

const FormLabelHeader = styled.label`
  display: block;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  color: #000;
  margin-bottom: 2rem;
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 2rem;
  }
  @media screen and (max-width: 400px) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 2rem;
  }
`;

const SendButton = styled.button`
  width: 100%;
  height: 60px;
  background: #181818;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #ffffff;

  @media screen and (max-width: 900px) {
    height: 55px;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  @media screen and (max-width: 400px) {
    font-size: 10px;
  }
`;
