import React from 'react';
import { MainWrapper, BillSection } from '../invoice/mainInvoice/preview-style';
import { FlexApart } from '../invoice/mainInvoice/preview-style';
import { LogoImage, DateInfo, Extra, Center, Line } from './style';
import moment from 'moment';
import CommaNumber from 'comma-number';
import { getSign } from 'utils/getCurrencySign';

interface PropsArgs {
  props: any;
  accountNumber: any;
  accountBank: any;
  firstName: string;
  lastName: string;
  propRef: any;
}

const Receipt = ({
  firstName,
  lastName,
  accountNumber,
  accountBank,
  props,
  propRef,
}: PropsArgs) => {
  return (
    <>
      <MainWrapper ref={propRef}>
        <FlexApart style={{ alignItems: 'center' }}>
          <LogoImage src="/logoblac.svg" alt="Mage" />
          <BillSection></BillSection>
        </FlexApart>
        <Center>
          <h3 style={{ fontSize: '0.9rem' }}>Transaction amount</h3>
          <h2 style={{ fontSize: '1.3rem' }}>
            {getSign(props.currency)}
            {CommaNumber(props.amount)}
          </h2>
        </Center>
        {props.type === 'credit' ? (
          <>
            <DateInfo>
              <FlexApart>
                <h3>Sender details</h3>
                <div>
                  <h3>
                    {props.type === 'credit' ? props.meta.payerName : null}{' '}
                  </h3>
                  <p
                    style={{
                      color: '#c4c4c4',
                      fontSize: '0.9rem',
                      textAlign: 'right',
                    }}
                  >
                    {props.type === 'credit'
                      ? `${props.meta.bankName} |`
                      : null}
                    {props !== undefined && props.type === 'credit'
                      ? props.meta.accountNumber
                      : null}
                  </p>
                </div>
              </FlexApart>
            </DateInfo>
            <Line />
          </>
        ) : null}

        {props.type === 'debit' ? (
          <>
            <DateInfo>
              <FlexApart>
                <h3>Beneficiary details</h3>
                <div>
                  <h3>
                    {props.type === 'debit' ? props.meta.accountName : null}{' '}
                  </h3>
                  <p
                    style={{
                      color: '#c4c4c4',
                      fontSize: '0.9rem',
                      textAlign: 'right',
                    }}
                  >
                    {props !== undefined && props.type === 'debit'
                      ? props.meta.accountNumber
                      : null}
                  </p>
                </div>
              </FlexApart>
            </DateInfo>
            <Line />
          </>
        ) : null}

        <DateInfo>
          <FlexApart>
            <h3>
              {props.type === 'debit'
                ? 'Sender Details'
                : 'Beneficiary details'}
            </h3>
            <div>
              <h3>
                {firstName} {lastName}
              </h3>
              <p
                style={{
                  color: '#c4c4c4',
                  fontSize: '0.9rem',
                  textAlign: 'right',
                }}
              >
                {accountBank} | {accountNumber}
              </p>
            </div>
          </FlexApart>
        </DateInfo>
        <Line />
        <DateInfo>
          <FlexApart>
            <h3>Paid on</h3>
            <div>
              <h3>
                {
                  moment(props.updatedAt)
                    .format('MMMM Do YYYY, h:mm:ss a')
                    .split(',')[0]
                }
              </h3>
              <p
                style={{
                  color: '#c4c4c4',
                  fontSize: '0.9rem',
                  textAlign: 'right',
                }}
              >
                {
                  moment(props.updatedAt)
                    .format('MMMM Do YYYY, h:mm:ss a')
                    .split(',')[1]
                }
              </p>
            </div>
          </FlexApart>
        </DateInfo>
        <Line />
        <DateInfo>
          <FlexApart>
            <h3>Memo for recipient</h3>
            <div>
              <h3>
                {props !== undefined && props.type === 'debit'
                  ? props.purpose
                  : 'No memo attached'}
              </h3>
            </div>
          </FlexApart>
        </DateInfo>
        <DateInfo>
          <FlexApart>
            <h3>Reference No:</h3>
            <div>
              <h3>{props !== undefined ? props.reference : null}</h3>
            </div>
          </FlexApart>
        </DateInfo>
        <Line />
        <DateInfo>
          <FlexApart>
            <h3>Total Amount</h3>
            <h3>
              {getSign(props.currency)}
              {CommaNumber(
                props.amount + (props.type === 'debit' ? props.meta.fee : 0),
              )}
            </h3>
          </FlexApart>
        </DateInfo>
        <Extra>
          <h3
            style={{
              fontSize: '10px',
              textAlign: 'center',
              color: '#ABABAB',
            }}
          >
            Send an email to hello@trymage.com if you have any isssues with your
            transfer.
          </h3>
        </Extra>
      </MainWrapper>
    </>
  );
};

export default Receipt;
