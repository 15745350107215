import { FlexedArea } from './../../../../storeComp/mainContent/sectionOne/styles';

const NavSection = ({ handleSearch }) => {
  return (
    <>
      <FlexedArea>
        {/* <FloatSearch>
          <SearchArea>
            <i className="fi fi-rr-search"></i>
            <input
              type="text"
              placeholder="Search your audience"
              onChange={handleChange}
            />
          </SearchArea>
        </FloatSearch> */}
      </FlexedArea>
    </>
  );
};

export default NavSection;
