import React, { useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';
import { TicketInput } from '../paymentLink/style';
import * as Icon from 'react-feather';

const PasswordResetModal = ({ closeFunc }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnprocess] = useState(false);
  const changeParams = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const options = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const handlePatch = () => {
    setOnprocess(true);
    if (
      oldPassword.length > 0 &&
      newPassword.length > 0 &&
      confirmPassword.length > 0
    ) {
      if (oldPassword !== newPassword) {
        if (newPassword === confirmPassword) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_ENDPOINT}/users/${loggedUser.user.id}/change-password`,
              changeParams,
              options,
            )
            .then(res => {
              setOnprocess(false);
              setSuccess(true);
              setTimeout(() => {
                setSuccess(false);
              }, 4000);
            })
            .catch(err => {
              setOnprocess(false);
              setError(err.response.data.message);
              setTimeout(() => {
                setError('');
              }, 4000);
            });
        } else {
          setOnprocess(false);
          setError("New Password doesn't match with confirmed password!");
          setTimeout(() => {
            setError('');
          }, 4000);
        }
      } else {
        setOnprocess(false);
        setError("Sorry, you can't use your old password!");
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    } else {
      setOnprocess(false);
      setError('Please fill all input fields');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const closeModal = () => {
    setSuccess(false);
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Change Password</Header>
          <div>
            <TicketInput>
              <div>
                <p>Old Password</p>
                <input
                  type="password"
                  placeholder="Old Password"
                  onChange={e => setOldPassword(e.target.value)}
                />
              </div>
            </TicketInput>
            <TicketInput>
              <div>
                <p>New Password</p>
                <input
                  type="password"
                  placeholder="New Password"
                  onChange={e => setNewPassword(e.target.value)}
                />
              </div>
            </TicketInput>
            <TicketInput>
              <div>
                <p>Confirm Password</p>
                <input
                  type="password"
                  placeholder="Confirm password"
                  onChange={e => setConfirmPassword(e.target.value)}
                />
              </div>
            </TicketInput>
            {error !== '' ? (
              <p style={{ color: 'red', width: '20rem' }}>{error}</p>
            ) : null}
          </div>
          <BigBtn>
            <button onClick={() => handlePatch()}>
              {onProcess ? <BeatLoader color="#fff" /> : 'Save Changes'}
            </button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Password changed!"
          miniMessage="You have successfully changed your password."
        />
      ) : null}
    </>
  );
};

export default PasswordResetModal;
