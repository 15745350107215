import React, { useEffect, useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
} from '../../dashboard/mainContent/fundWallet/style';
import Select from 'react-select';
import axios from 'axios';
import Countries from 'countries.json';
import Alert from 'app/components/dashboard/mainContent/fundWallet/alert';
import { CustomStyles } from 'utils/selectCustomStyle';
import { BeatLoader } from 'react-spinners';
import { numOnly } from 'utils/numOnly';
import * as Icon from 'react-feather';

const NewClient = ({
  closeFunc,
  propSetName,
  propSetId,
  propSetPhone,
  propSetEmail,
  propSetAddress,
  propSetState,
  propSetCountry,
  editProps,
  mode,
}) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const [id, setId] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhoneNumber, setClientPhoneNumber] = useState('');
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState<any>('Nigeria');

  const [selectedCountry, setSelectedCountry] = useState<any>(
    Countries.find((countryItem: any) => countryItem.name === country),
  );

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  useEffect(() => {
    if (mode === 'edit') {
      setClientName(editProps.name);
      setClientEmail(editProps.email);
      setClientPhoneNumber(editProps.phoneNumber);
      setAddress(editProps.address);
      setState(editProps.state);
      setCountry(editProps.country);
      setId(editProps.id);
    }
  }, [
    editProps.address,
    editProps.country,
    editProps.email,
    editProps.id,
    editProps.name,
    editProps.phoneNumber,
    editProps.state,
    mode,
  ]);

  // Handle Add Client to database
  const handleAddClient = async e => {
    e.preventDefault();
    setOnProcess(true);

    const apiData = {
      name: clientName,
      email: clientEmail,
      phoneNumber: clientPhoneNumber,
      country: country,
      state: state,
      address: address,
    };
    Object.keys(apiData).forEach(key => {
      if (!apiData[key]) delete apiData[key];
    });

    if (mode === 'edit') {
      await axios
        .patch(
          process.env.REACT_APP_BASE_ENDPOINT + `/invoices/client/${id}`,
          apiData,
          {
            headers: { Authorization },
          },
        )
        .then(res => {
          setOnProcess(false);
          setSuccess(true);
          propSetName(res.data.name);
          propSetId(res.data.id);
          propSetAddress(res.data.address);
          propSetEmail(res.data.email);
          propSetPhone(res.data.phoneNumber);
          propSetState(res.data.state);
          propSetCountry(res.data.country);
          setTimeout(() => {
            setSuccess(false);
            closeFunc();
          }, 4000);
        })
        .catch(err => {
          setOnProcess(false);
          setError(err.response.data.message);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    } else {
      await axios
        .post(
          process.env.REACT_APP_BASE_ENDPOINT + '/invoices/client',
          apiData,
          {
            headers: { Authorization },
          },
        )
        .then(res => {
          setOnProcess(false);
          setSuccess(true);
          propSetName(res.data.name);
          propSetId(res.data.id);
          propSetAddress(res.data.address);
          propSetEmail(res.data.email);
          propSetPhone(res.data.phoneNumber);
          propSetState(res.data.state);
          propSetCountry(res.data.country);
          setTimeout(() => {
            setSuccess(false);
            closeFunc();
          }, 4000);
        })
        .catch(err => {
          setOnProcess(false);
          setError(err.response.data.message);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    }
  };

  const closeModal = () => {
    setError('');
    setSuccess(false);
    closeFunc();
  };

  const closeSuccess = () => {
    closeFunc();
    setError('');
    setSuccess(false);
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>{mode === 'edit' ? 'Edit Client' : 'Add new client'}</Header>
          <div>
            <form onSubmit={e => handleAddClient(e)}>
              <InputWrap>
                <input
                  type="text"
                  placeholder="Client name"
                  required
                  value={clientName}
                  onChange={e => setClientName(e.target.value)}
                  maxLength={25}
                />
              </InputWrap>
              <InputWrap>
                <input
                  type="text"
                  value={clientEmail}
                  required
                  placeholder="Client email"
                  onChange={e => setClientEmail(e.target.value)}
                />
              </InputWrap>

              <InputWrap>
                <input
                  type="text"
                  placeholder="Phone number"
                  value={clientPhoneNumber}
                  onChange={e => setClientPhoneNumber(e.target.value)}
                  maxLength={11}
                  onKeyPress={e => {
                    numOnly(e);
                  }}
                />
              </InputWrap>
              <InputWrap>
                <label>Country</label>
                <Select
                  options={Countries}
                  getOptionLabel={p => p.name}
                  styles={CustomStyles}
                  value={Countries.filter(option => option.name === country)}
                  onChange={(e: any) => {
                    setCountry((e as HTMLSelectElement).name);
                    setSelectedCountry(
                      Countries.find(
                        (countryItem: any) =>
                          countryItem.name === (e as HTMLSelectElement).name,
                      ),
                    );
                  }}
                />
              </InputWrap>
              <InputWrap>
                <label>State</label>
                <Select
                  options={selectedCountry.states}
                  getOptionLabel={p => p.name}
                  value={selectedCountry.states.filter(p => p.name === state)}
                  styles={CustomStyles}
                  onChange={(e: any) => setState((e as HTMLSelectElement).name)}
                />
              </InputWrap>
              <InputWrap>
                <input
                  type="text"
                  placeholder="Address"
                  required
                  value={address}
                  onChange={e => setAddress(e.target.value)}
                />
              </InputWrap>
              {error && <p>{error}</p>}
              <BigBtn>
                <button type="submit">
                  {onProcess ? (
                    <BeatLoader />
                  ) : (
                    <>{mode === 'edit' ? 'Save Changes' : ' Add client'}</>
                  )}
                </button>
              </BigBtn>
            </form>
          </div>
        </ModalChild>
      </ModalArea>

      {/* {error !== '' ? (
        <Alert
          closeFunc={closeSuccess}
          img="/failed.png"
          message="An error occurred!"
          miniMessage={error}
        />
      ) : null} */}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message={`Client ${
            mode === 'edit' ? 'modified' : 'created'
          } successfully!!!`}
          miniMessage={`You have successfully ${
            mode === 'edit' ? 'modified this' : 'created a new'
          } client!`}
        />
      ) : null}
    </>
  );
};

export default NewClient;
