import React, { useState } from 'react';
import { MainWrapper } from './../invoice/mainInvoice/style';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { SaveButtonWrap } from '../Preference/mainContent/preferenceContent/styles';
import {
  SelectCentralized,
  CardOptionHolder,
  TextWrap,
  ImageHolder,
} from './style';
import { useNavigate } from 'react-router';
import PopUpModal from '../merch/popupModal';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';

const SelectType = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState<number>(0);
  const handleSelectedOption = (id: number) => {
    setActive(id);
  };

  // Handle Next Page and Type selection
  const [selectedType, setSelectedType] = useState('');
  const [error, setError] = useState<string>('');

  const handleSetType = item => {
    setSelectedType(item);
  };

  const handleNext = async item => {
    setSelectedType(item);
    if (item && item !== '') {
      navigate('/create-payment-link');
      localStorage.setItem('selectedType', JSON.stringify(item));
    } else {
      setError('Please select a payment type to continue!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
    return selectedType;
  };

  const handleClose = () => {
    setError('');
  };

  return (
    <>
      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      <MainWrapper style={{ width: '100%' }}>
        <FragmentHeader showBack={true} pageName="Go back" />
        <BreadcrumbArea>
          <SelectCentralized>
            <h3>Select a payment link type to continue</h3>
            <CardOptionHolder>
              {options.map((item, index) => (
                <OptionCard
                  active={active}
                  handleSelectedOption={handleSelectedOption}
                  handleSetType={handleSetType}
                  handleNext={handleNext}
                  buttonInscription={item.buttonInscription}
                  index={index}
                  value={item.value}
                  id={item.id}
                  image={item.image}
                  name={item.name}
                  text={item.text}
                />
              ))}
            </CardOptionHolder>
          </SelectCentralized>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default SelectType;

export const OptionCard = ({
  handleSelectedOption,
  handleSetType,
  index,
  active,
  handleNext,
  id,
  image,
  value,
  buttonInscription,
  name,
  text,
}) => {
  return (
    <div
      key={index}
      style={active === id ? { border: '2px solid #D71B1B' } : { border: '' }}
      onClick={() => {
        handleSelectedOption(id);
        handleSetType(value);
      }}
    >
      <ImageHolder>
        <img src={image} alt="Icon label" />
      </ImageHolder>
      <TextWrap>
        <h4>{name}</h4>
        <p>{text}</p>
      </TextWrap>
      <SaveButtonWrap>
        <button
          style={
            active === id
              ? { background: '#c82b38', width: '20rem' }
              : { background: 'rgba(171, 171, 171, 0.9)', width: '20rem' }
          }
          onClick={async () => {
            await handleNext(value);
          }}
        >
          {buttonInscription}
        </button>
      </SaveButtonWrap>
    </div>
  );
};

const options = [
  {
    id: 1,
    name: 'One time payments',
    value: 'one_time',
    text: 'Add items to sell to your audience on your personal store.',
    image: '/1.png',
    buttonInscription: 'Create a one time payment',
  },
  {
    id: 2,
    name: 'Recurring payments',
    value: 'subscription',
    text: 'View your order history, merch dales and other insights. ',
    image: '/2.png',
    buttonInscription: 'Create a recurring payment',
  },
];
