import AppHeader from './../../storeComp/mainContent/header/header';
import { MainWrapper } from './../../storeComp/mainContent/styles';
import SubMain from './subMain';

const MainContent = () => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const deviceWidth = window.innerWidth;

  return (
    <>
      <MainWrapper
        className="no-scrollbar"
        bg="#161616"
        padding="0"
        mobileTop="0rem"
      >
        {deviceWidth < 500 ? (
          <AppHeader
            showBack={false}
            pageName={'Hi, ' + loggedUser.user.firstName}
            className="hide-header"
          />
        ) : null}
        <SubMain />
      </MainWrapper>
    </>
  );
};

export default MainContent;
