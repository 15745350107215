import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from '../dashboard/mainContent/fundWallet/style';
import { numOnly } from 'utils/numOnly';
import * as Icon from 'react-feather';
import { TicketInput } from './style';

const TicketTierSetting = ({
  closeFunc,
  name,
  ticketQuantity,
  ticketPrice,
  setTicketName,
  setTicketPrice,
  setTicketQuantity,
  setFunc,
  absorbFees,
  setAbsorbFees,
}) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header
            style={{
              textAlign: 'left',
            }}
          >
            Create Ticket Tier
          </Header>
          <form onSubmit={setFunc}>
            <div>
              <h3
                style={{
                  margin: '0 0 0.5rem 0',
                }}
              >
                Ticket Tier
              </h3>

              <TicketInput>
                <div>
                  <p>Ticket name</p>
                  <input
                    onChange={e => setTicketName(e.target.value)}
                    required={true}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Available Quantity</p>
                  <input
                    onChange={e => setTicketQuantity(e.target.value)}
                    required={true}
                    onKeyPress={e => numOnly(e)}
                    type="number"
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Price(&#8358;)</p>
                  <input
                    onChange={e => setTicketPrice(e.target.value)}
                    required={true}
                    onKeyPress={e => numOnly(e)}
                    type="number"
                  />
                </div>
                <p>Set price at ₦0 for free event.</p>
              </TicketInput>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '1rem 0 0 0',
              }}
            >
              <input
                type="checkbox"
                checked={absorbFees}
                onChange={e => setAbsorbFees(e.target.checked)}
              />
              <label
                style={{
                  margin: '0 0 0 0.5rem',
                  fontSize: '13px',
                  letterSpacing: '-0.01em',
                  color: '#757575',
                  width: '19rem',
                }}
              >
                Absorb fees: Deduct ticketing fees from my ticket revenue.
              </label>
            </div>
            <BigBtn>
              <button type="submit">Save Ticket</button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default TicketTierSetting;
