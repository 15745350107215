import React from 'react';

const CopiedNotifier = () => {
  const style: any = {
    width: '20rem',
    fontSize: '0.8rem',
    textAlign: 'center',
    position: 'fixed',
    bottom: '2rem',
    left: '50%',
    color: 'white',
    background: '#121212',
    transform: 'translate(-50%, 0)',
    boxShadow: '0 0 4px #cbced1',
    padding: '0.8rem 0',
    zIndex: '10000000000000000000',
    borderRadius: '8px',
  };

  return (
    <>
      <p style={style}>
        <i style={{ margin: '0 5% 0 0' }} className="fi fi-rr-check"></i>
        Copied to clipboard
      </p>
    </>
  );
};

export default CopiedNotifier;
