import axios from 'axios';
import { useEffect, useState } from 'react';
import SubmitButton from 'app/components/SubmitButton';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState } from 'state/global';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/react';
import styled from 'styled-components';
import OtpInput from 'react-otp-input';
import { FloatLogOption } from '../SignUp/styles';
import {
  AuthHeader,
  MainWrapper,
  FormWidget,
  MerchroLogo,
} from '../Login/styles';
import { Link } from 'react-router-dom';
import { BlueText } from 'app/components/editProfile/style';
import PopUpModal from 'app/components/merch/popupModal';

const override: any = css`
  margin-top: 1rem;
`;

const ConfirmEmail = () => {
  const width = window.innerWidth;

  const inputStyle = {
    width: width > 728 ? '2.5rem' : '2.5rem',
    height: width > 728 ? '2.5rem' : '2.5rem',
    background: '#fff',
    border: '1px solid #191919',
    borderRadius: '4px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginRight: 15,
    color: 'black',
  };

  const setUser = useRecoilState(userState)[1];
  const history = useNavigate();
  const [clientErr, setClientErr] = useState<any>('');
  const [countDown, setCountDown] = useState<any>(0);
  const [runTimer, setRunTimer] = useState<any>(false);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 2);
      timerId = setInterval(() => {
        setCountDown(countDown => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const togglerTimer: any = () => setRunTimer(t => !t);

  const seconds: any = String(countDown % 60);
  const minutes = String(Math.floor(countDown / 60));

  useEffect(() => {
    togglerTimer();
  }, []);

  type ParamsData = {
    id: any;
    firstName: any;
    email: any;
  };
  const { id, email } = useParams<ParamsData>();
  const [onProcess, setOnProcess] = useState(false);

  const [verificationCode, setVerificationCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const verifyHandler: any = e => {
    e.preventDefault();
    if (verificationCode && verificationCode.length === 6) {
      setOnProcess(true);
      const data: { token: string; userId: string } = {
        token: verificationCode,
        userId: id,
      };

      axios
        .post(process.env.REACT_APP_BASE_ENDPOINT + '/auth/verify-email', data)
        .then(res => {
          setUser(res.data);
          // Set UID Cookie
          let date = new Date();
          const duration = 30 * 24 * 60 * 60 * 1000;
          date.setTime(date.getTime() + duration);
          document.cookie = `UID=${
            res.data.id
          }; expires=${date.toUTCString()}; path=/`;
          localStorage.setItem('logged_user', JSON.stringify(res.data));
          history('/create-pin');
        })
        .catch(err => {
          setOnProcess(false);
          setClientErr(err.response.data.message);
        });
    } else {
      setClientErr('Code must be six digit.');
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  const resend = () => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/auth/resend-email-verification`,
        { email: email },
      )
      .then(res => {
        return;
      })
      .catch(err => {});
  };

  const logOut = () => {
    localStorage.clear();
    history('/login');
  };
  return (
    <>
      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={() => setErrorMessage('')}
          msg="Operation unsuccessful"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="#c82b32"
        />
      ) : null}
      <MainWrapper>
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/login" onClick={() => logOut()}>
              Log out
            </Link>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <form onSubmit={verifyHandler}>
            <div className="px-4">
              <h1
                className="text-2xl font-bold text-baseDarkk"
                style={{
                  textAlign: 'center',
                  fontFamily: 'Satoshi',
                  fontWeight: 800,
                  color: '#191919',
                }}
              >
                Verify your email
              </h1>
              {clientErr && (
                <p
                  className="text-sm text-red-500 font-medium my-1"
                  style={{ textAlign: 'center' }}
                >
                  {clientErr}
                </p>
              )}
              <p
                style={{
                  margin: '0 0 2rem 0',
                  color: 'grey',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                <br />
                We just sent a temporary sign up code to <b>{email}</b>.
              </p>
              <PinInputWrap>
                <OtpInput
                  value={verificationCode}
                  onChange={code => setVerificationCode(code)}
                  numInputs={6}
                  inputStyle={inputStyle}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  isInputSecure={false}
                />
              </PinInputWrap>
              <p
                style={{
                  margin: '0rem 0 1rem 0',
                  color: 'grey',
                  fontSize: '12px',
                  textAlign: 'center',
                }}
              >
                <br />
                {Number(minutes) > 0 || Number(seconds) > 0 ? (
                  <>
                    Code expires in{' '}
                    <b>
                      {minutes}:{seconds}
                    </b>
                    .
                  </>
                ) : (
                  <>Code has expired.</>
                )}
              </p>
              <BlueText
                style={{
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <button
                  type="button"
                  disabled={countDown > 0 ? true : false}
                  style={
                    countDown > 0
                      ? {
                          cursor: 'not-allowed',
                          fontSize: '12px',
                          opacity: '0',
                        }
                      : { cursor: 'pointer', fontSize: '12px' }
                  }
                  onClick={() => {
                    resend();
                    togglerTimer();
                  }}
                >
                  Resend Code
                </button>
              </BlueText>
              <SubmitButton
                text={
                  onProcess ? (
                    <BeatLoader color="white" css={override} />
                  ) : (
                    'Next'
                  )
                }
                disabled={onProcess}
                style={{ cursor: onProcess ? 'not-allowed' : 'pointer' }}
              />

              {/* <p className="my-5">
              We are excited that you chose the best payment collection
              experience for you.
            </p> */}
            </div>

            {/* <div className="flex items-center justify-between border-t border-gray-200 p-4">
            <section>
              <p>CONNECT WITH US</p>
            </section>
            <section className="flex w-24 justify-between">
              <div className="rounded-3xl border bg-gray-100 py-2 px-3 hover:bg-gray-200">
                <Link to="/#">
                  <i className="bi bi-twitter"></i>
                </Link>
              </div>

              <div className="rounded-3xl border bg-gray-100 py-2 px-3 hover:bg-gray-200">
                <Link to="/#">
                  <i className="bi bi-instagram"></i>
                </Link>
              </div>
            </section>
          </div> */}
          </form>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

const PinInputWrap = styled.div`
  width: 20rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 1.5rem 0;

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export default ConfirmEmail;
