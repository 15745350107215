export const getSign = currency => {
  try {
    switch (currency) {
      case 'USD':
        return '$';
      case 'NGN':
        return '₦';
      case 'GBP':
        return '£';
      case 'EUR':
        return '€';
      default:
        break;
    }
  } catch {}
};
