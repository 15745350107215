import styled from 'styled-components';

interface PropsArgs {
  padding?: string;
  width?: string;
}

export const MainWrapper = styled.div<PropsArgs>`
  width: ${p => p.width || '85%'};
  padding: ${p => p.padding || '3rem 1.5%'};
  margin: 0 auto;

  @media (max-width: 1028px) {
    width: 100%;
    margin: 0 auto;
    padding: 7rem 5% 2rem 5%;
  }
`;

export const RecordWidget = styled.div`
  margin: 2rem 0;
`;

export const DateWrap = styled.div`
  padding: 1% 3%;
  background: #dadada;
  border-radius: 5px;

  > h4 {
    font-weight: bold;
    font-size: 0.8rem;
  }

  @media (max-width: 1028px) {
    padding: 3% 3%;
  }
`;

export const Status = styled.div`
  color: #d71b1b;
  font-weight: 400;
  font-size: 0.8rem;
  font-style: normal;
  margin-left: 4%;

  @media (max-width: 1028px) {
    margin-left: 0%;
  }
`;

export const ActionIconWrap = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;

  > i {
    margin: 0 0 0 2rem;
  }
`;

export const StoreAvatar = styled.img`
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  object-fit: cover;
`;

interface PropsArg {
  customCl: any;
}

export const GenericHeader = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  margin: 0 auto;

  @media (max-width: 1028px) {
    width: 100%;
  }

  h4 {
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
  }

  i {
    margin: 0 0.6rem 0 0;
    color: #d71b1b;
  }
`;

export const GenericHeaderItem = styled.div<PropsArg>`
  display: flex;
  justify-content: center;
  width: 33%;
  padding: 0.5rem 0;
  font-size: 12px;
  color: #191919;
  border-bottom: 2px solid ${p => p.customCl || '#f1f1f1'};
  text-align: center;

  @media (max-width: 728px) {
    width: 33%;
  }
`;

export const FlexApart = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HalfWidget = styled.div`
  width: 45%;
  margin: 2rem 0;

  > h6 {
    font-size: 0.8rem;
  }

  input {
    padding: 0.5rem 2%;
  }
`;

export const SmallText = styled.h4`
  font-size: 0.85rem;
  margin: 1rem 0 0.6rem 0;
`;

export const HeaderConstraint = styled.div<ConstraintProps>`
  width: ${p => p.width || '100%'};
  padding: 0.5rem 0%;
  border-radius: 5px;
  margin: 1rem auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  p {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    color: #191919;
  }

  > div {
    display: flex;
    align-items: Center;
    gap: 5px;
  }

  @media (max-width: 728px) {
    width: 100%;
    padding: 0rem 0 0.5rem 0;
    margin: 8rem 0 0 0;
    border: none;
    top: ${p => p.mobileTop || '0'};
  }
`;

interface ConstraintProps {
  width?: string;
  top?: string;
  mobileTop?: string;
}

export const Constraint = styled.div<ConstraintProps>`
  width: ${p => p.width || '30%'};
  border: none;
  background: rgba(249, 249, 249, 0.1);
  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid #dfdfdf;
  padding: 2rem 2% 2rem 2%;
  border-radius: 10px;
  position: relative;
  top: ${p => p.top || '0'};
  margin: 100px auto !important;

  @media (max-width: 728px) {
    width: 100%;
    padding: 2rem 3% 2rem 3%;
    border: 1px solid #dfdfdf;
    top: ${p => p.mobileTop || '0'};
  }
`;

export const Line = styled.hr`
  border-top: 0.1px solid rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
`;

export const BlueText = styled.h4`
  color: #007aff;
  font-size: 0.85rem;
  cursor: pointer;
  text-align: center;
`;

export const GenericTextArea = styled.textarea`
  width: 100%;
  height: 8rem;
  background: #ffffff;
  border: 0.1px solid #dadada;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  font-size: 14px;
  resize: none;
  font-weight: 400;

  @media (max-width: 1028px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const Upload = styled.div`
  width: 40%;
  padding: 0.5rem 5%;
  border: 0.5px dashed #000000;
  border-radius: 20px;
  background: rgba(230, 233, 239, 0.4);
  cursor: pointer;

  @media (max-width: 1028px) {
    width: 70%;
  }

  > label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  i {
    border-right: 0.5px dashed #000000;
    padding: 0 5% 0 0;
  }
`;

export const HeaderFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1028px) {
    flex-direction: column;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 4rem;
`;

export const GridItem = styled.div`
  height: 20rem;
  padding: 1rem;
  border-radius: 12px;
  border: 1px solid #eaecee;
  cursor: pointer;
`;

export const GridItemImg = styled.img`
  width: 100%;
  heoght: 70%;
  object-fit: cover;
`;

export const GridTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 2rem 0;
  padding: 0 0.5rem;

  h2 {
    width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  > p {
    color: #4f5b67;
    font-size: 0.9rem;

    > i {
      margin: 0 2% 0 0;
    }
  }
`;

export const TableHeader = styled.div`
  display: flex;
  align-items: Center;
  width: 70%;
  padding: 2% 0;
  border-bottom: 1px solid #f1f1f1;

  @media (max-width: 1028px) {
    width: 100%;
    margin: 0 0 2rem 0;
  }
`;

interface Props {
  active: string;
}

export const TableHeaderItem = styled.h4<Props>`
  font-size: 0.9rem;
  margin: 0 5% 0 0;
  color: ${p => p.active || '#b0b0b0'};
`;

export const SixtyDiv = styled.div`
  width: 60%;

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const ImageSetUp = styled.div`
  margin: 3rem auto;
  display: grid;
  justify-content: center;
  gap: 10px;
`;

export const DeleteText = styled.p`
  text-align: right;
  color: #c82b38;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const MobileButton = styled.button`
  display: none;

  @media (max-width: 728px) {
    display: flex;
    margin: 0;
    padding: 0.5rem 2rem;
    background: #191919;
    color: #fff;
    border-radius: 4px;
    font-size: 0.8rem;
    align-items: center;
  }
`;

export const MobileInputWrap = styled.div`
  position: relative;
`;

interface PropsArgs2 {
  width?: any;
  border?: any;
}

export const MobileInput = styled.input<PropsArgs2>`
  width: ${p => p.width || '100%'};
  padding: 0.7rem 1rem 0.7rem 6rem;
  margin: 1rem 0 0 0;
  background: #ffffff;
  border: ${p => p.border || '0.5px solid #DADADA'};
  border-radius: 5px;
`;

export const MobileSelect = styled.select`
  border: none;
  background: transparent;
  color: #757575;
  position: absolute;
  top: 50%;
  width: 5rem;
  height: 50%;
  transform: translate(0%, -20%);
  left: 0.5rem;
  border-right: 0.1px solid #dadada;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
`;

export const SplitMembrane = styled.div`
  background: #fbfbfb;
  border: 0.2px solid #f0f0f0;
  box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 1rem 5%;
  margin: 1rem 0 1rem 0;

  @media (max-width: 728px) {
    padding: 1rem 0 0 0;
  }
`;
