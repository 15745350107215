import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  msg: any;
  extraMsg: any;
  icon: string;
  iconColor: string;
  handleClose(): any;
}

const PopUpModal: FC<Props> = props => {
  return (
    <>
      <PopCard icon={props.icon}>
        <FlexArea>
          <CloseButton>
            <i className="fas fa-times" onClick={() => props.handleClose()}></i>
          </CloseButton>
          <NotifyIcon>
            <i className={props.icon} style={{ color: props.iconColor }}></i>
          </NotifyIcon>
          <MsgWrap>
            {/* <h4>{props.msg}</h4> */}
            <p>{props.extraMsg}</p>
          </MsgWrap>
        </FlexArea>
      </PopCard>
    </>
  );
};

export default PopUpModal;

interface PropsArgs {
  icon: string;
}

const PopCard = styled.div<PropsArgs>`
  width: 30%;
  position: fixed;
  top: 4rem;
  right: 3%;
  padding: 2%;
  border-radius: 0.2rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
  z-index: 10000000000000000000000000000;
  background: ${p => (p.icon === 'fas fa-exclamation' ? '#c82b32' : '#258750')};
  color: #fff;

  @media (max-width: 1028px) {
    width: 90%;
    padding: 8% 3%;
  }
`;

const FlexArea = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
`;

const NotifyIcon = styled.div`
  width: 3rem;
  height: 3rem;
  // background: #f3f3f3;
  border-radius: 10rem;
  display: flex;
  align-items: Center;
  justify-content: center;

  > i {
    color: #fff;
  }
`;

const MsgWrap = styled.div`
  width: 80%;

  > h4 {
    font-weight: 500;
    font-size: 0.9rem;
  }

  > p {
    font-size: 0.8rem;
    color: #fff;
  }
`;

const CloseButton = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  background: #f3f3f3;
  position: absolute;
  top: 5%;
  right: 2%;
  border-radius: 10rem;

  > i {
    color: #161616;
  }
`;
