import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getRequest } from 'utils/request';
import DealTable from './DealTable';

const DealList = () => {
  const [dealData, setDealData] = useState([]);
  useEffect(() => {
    getRequest('deals?paginate=false')
      .then(res => {
        setDealData(res);
      })
      .catch(err => {
        console.log(err.message);
      });
  }, []);
  return (
    <Wrapper>
      <DealTable data={dealData} />
    </Wrapper>
  );
};

export default DealList;

const Wrapper = styled.div`
  display: flex;
  margin-top: 100px;
  position: relative;
  width: 100%;
  border-radius: 5px;
`;
