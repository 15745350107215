import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Store from 'utils/store';
import {
  InAppHeader,
  MainWrapper,
} from 'app/components/storeComp/mainContent/styles';
import SideNav from 'app/components/storeComp/sideNav/sideNav';
import AppHeader from 'app/components/storeComp/mainContent/header/header';
import { getRequest } from 'utils/request';
import DealTable from './DealTable';

const MyDealsDisplay = () => {
  const [dealData, setDealData] = useState([]);
  const [user] = useState(Store.get('logged_user').user);

  useEffect(() => {
    getRequest(`/deals?paginate=false&claimer=${user.id}`)
      .then(res => {
        setDealData(res);
      })
      .catch(error => {
        // alert('Error fetching deals');
      });
  }, [user]);
  return (
    <Wrapper>
      <DealTable data={dealData} />
    </Wrapper>
  );
};

const MyDeals = () => {
  const [size, setSize] = useState(window.innerWidth);
  useEffect(() => {
    setSize(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);
  return (
    <div style={{ background: '#151515', height: '100vh' }}>
      <MainWrapper className="no-scrollbar" style={{ marginLeft: 11.5 }}>
        {size > 1028 && <SideNav mobileShow="none" />}
        <AppHeader pageName="My Deals" showBack={true} />
        <InAppHeader
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h3 style={{ width: '100%' }}>My Deals</h3>
          <p style={{ color: '#161616', fontSize: 14 }}>
            Here are the deals you're currently funding.
          </p>
        </InAppHeader>
        <br /> <br />
        <MyDealsDisplay />
      </MainWrapper>
    </div>
  );
};

export default MyDeals;

const Wrapper = styled.div`
  display: flex;
  margin-top: 100px;
  position: relative;
  width: 100%;
  border-radius: 5px;
`;
