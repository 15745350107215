import styled from 'styled-components';

interface data {
  text: any;
  disabled: boolean;
  style?: any;
}

const SubmitButton = ({ text, disabled, style }: data) => {
  return (
    <>
      <Button type="submit" disabled={disabled} style={style}>
        {text}
      </Button>
    </>
  );
};

export default SubmitButton;

export const Button = styled.button`
  width: 100%;
  background: #191919;
  padding: 3% 2%;
  border: none;
  color: #fff;
  border-radius: 0.3rem;
  cursor: pointer;
  margin: 1rem 0 0 0;

  :hover {
    border: 1px solid #111c30;
    opacity: 0.8;
  }

  @media (max-width: 728px) {
    padding: 3% 2%;
    margin: 1rem 0 1rem 0;
  }
`;
