import { Link, useNavigate } from 'react-router-dom';
import InputElement from 'app/components/InputElement';
import SubmitButton from 'app/components/SubmitButton';
import { useEffect, useState } from 'react';
import userSchema from './userSchema';
import {
  InputFlex,
  HeadText,
  PwdGuideWrap,
  // NavTypeWrap,
  // NavTypeItem,
  MobileInputWrap,
  MobileInput,
  MobileSelect,
  FloatLogOption,
  // FlagDisplay
} from './styles';
import {
  FormWidget,
  MainWrapper,
  MerchroLogo,
  AuthHeader,
} from './../Login/styles';
import axios from 'axios';
import { BeatLoader } from 'react-spinners';
import * as Icon from 'react-feather';
import { numOnly } from 'utils/numOnly';
import { Countries } from 'utils/countryCode';
// import { supportedCountries } from 'utils/supportedCountries';
import {
  InputActPlacehold,
  CollabSuggestWrap,
  SplitUsersWrap,
} from './../../components/split/style';
import ReactCountryFlag from 'react-country-flag';
import { CustomButtonLight } from 'app/components/storeComp/mainContent/sectionOne/styles';
// import { CustomButtonLight } from 'app/components/storeComp/mainContent/sectionOne/styles';

const SignUp = () => {
  // const [country, setCountry] = useState<any>('Nigeria');
  const [showGuidelines, setGuidelines] = useState(false);
  const [eigthChar, setEightChar] = useState(false);
  const [lowerChar, setLowerChar] = useState(false);
  const [upperChar, setUpperChar] = useState(false);
  const [numberChar, setNumberChar] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [activeType, setactiveType] = useState('creator');

  const handleCreateBusines = () => {
    setactiveType('business');
  };

  const handlePassword = e => {
    if (e.target.value.length > 0) {
      setGuidelines(true);
      // Check If character is equal or greater than 8
      if (e.target.value.length >= 8) {
        setEightChar(true);
      } else setEightChar(false);

      // Check if character has Uppercase
      if (/[A-Z]/.test(e.target.value) === true) {
        setUpperChar(true);
      } else setUpperChar(false);

      // Check if character has lowercase
      if (/[a-z]/.test(e.target.value) === true) {
        setLowerChar(true);
      } else setLowerChar(false);

      // Check if character has number
      if (/[0-9]/.test(e.target.value) === true) {
        setNumberChar(true);
      } else setNumberChar(false);

      // Check if character has special character
      if (/[`!@#$%^*()&._-]/.test(e.target.value)) {
        setSpecialChar(true);
      } else setSpecialChar(false);
    } else {
      setGuidelines(false);
    }
  };

  const [revealPass, setRevealPass] = useState(false);
  const [revealMatchPass, setRevealMatchPass] = useState(false);
  const toggleHandler = (e, elem) => {
    e.preventDefault();
    if (elem === 'pass')
      revealPass ? setRevealPass(false) : setRevealPass(true);
    else if (elem === 'matchPass')
      revealMatchPass ? setRevealMatchPass(false) : setRevealMatchPass(true);
  };

  const [phoneCode, setPhoneCode] = useState('+234');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [onProcess, setOnProcess] = useState(false);

  const [clientErr, setClientErr] = useState('');

  const inputModified = () => {
    setClientErr('');
  };

  const history = useNavigate();
  const signUpHandler = async e => {
    e.preventDefault();
    if (!eigthChar || !lowerChar || !upperChar || !numberChar || !specialChar) {
      setClientErr('Password is missing some required characters');
      return;
    }

    // validate phone number
    // Check if it starts with 70 80 81 90 91
    if (
      !['70', '80', '81', '90', '91'].includes(`${userPhone[0]}${userPhone[1]}`)
    ) {
      setClientErr('Please enter a valid phone number');
      return;
    }

    setOnProcess(true);

    const userData: userSchema = {
      firstName: userFirstName,
      lastName: userLastName,
      role: 'creator',
      email: userEmail,
      phoneNumber: userPhone,
      countryCode: phoneCode,
      password: userPassword,
    };

    if (activeType === 'business') {
      userData['isBusiness'] = true;
      userData['businessData'] = {};
      userData.businessData['name'] = businessName;
    }

    await axios
      .get(
        process.env.REACT_APP_BASE_ENDPOINT +
          `/auth/check-data?email=${userEmail}&phoneNumber=${
            phoneCode + '-' + userPhone
          }`,
      )
      .then(res => {
        if (res.data.email === false && res.data.phoneNumber === false) {
          axios
            .post(
              process.env.REACT_APP_BASE_ENDPOINT + '/auth/sign-up',
              userData,
            )
            .then(res => {
              if (res.statusText !== 'Created') {
                setClientErr('Error creating account');
                setOnProcess(false);
              }
              setOnProcess(false);

              // Redirect to the email confirmaion
              history(
                `/email-confirmation/${res.data.firstName}/${res.data.id}/${res.data.email}`,
              );
            })
            .catch(err => {
              setOnProcess(false);
              if (err.response.data.code === 400)
                setClientErr(err.response.data.message);
            });
        } else {
          setOnProcess(false);
          setClientErr('Email or Phone Number already exists!');
        }
      })
      .catch(err => {
        setOnProcess(false);
        if (err.response.data.code === 400)
          setClientErr(err.response.data.message);
      });
  };

  const handleZero = e => {
    if (e.target.value.charAt(0) === '0') {
      e.target.value = e.target.value.slice(1);
    }
    if (e.target.value.length === 10) {
      e.target.value = e.target.value.slice(0, 10);
    }
  };

  useEffect(() => {
    setactiveType('creator');
  }, []);

  useEffect(() => {
    if (businessName) {
      setBusinessName(text => text.replace(/\s+/g, ' ').replace('.', ''));
    }
  }, [businessName]);

  // Get Country Flag
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [flag, setFlag] = useState('NG');

  return (
    <>
      <MainWrapper
        className="no-scrollbar"
        onClick={() => setIsInputFocus(false)}
      >
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/login">Login</Link>
            <CustomButtonLight>
              <button
                onClick={() => {
                  if (activeType === 'creator') {
                    handleCreateBusines();
                  } else {
                    setactiveType('creator');
                  }
                }}
              >
                Get a {activeType === 'business' ? 'personal' : 'business'}{' '}
                account
              </button>
            </CustomButtonLight>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <form onSubmit={signUpHandler}>
            <div>
              <HeadText
                style={{
                  fontFamily: 'Satoshi',
                  fontWeight: 800,
                  color: '#191919',
                }}
              >
                Create an account
              </HeadText>
              <p
                style={{
                  textAlign: 'center',
                  color: '#757575',
                  margin: '-0.5rem 0 2rem 0',
                }}
              >
                Start your journey with us.
              </p>
            </div>
            <InputFlex>
              <input
                type="text"
                placeholder="First name"
                required={true}
                value={userFirstName}
                onChange={e => {
                  inputModified();
                  setUserFirstName(e.target.value.trim());
                }}
                onKeyPress={e => {
                  if (e.key === ' ') {
                    e.preventDefault();
                  }
                }}
              />
              <input
                type="text"
                placeholder="Last name"
                value={userLastName}
                onChange={e => {
                  inputModified();
                  setUserLastName(e.target.value.trim());
                }}
                onKeyPress={e => {
                  if (e.key === ' ') {
                    e.preventDefault();
                  }
                }}
                required={true}
              />
            </InputFlex>
            {activeType === 'business' ? (
              <InputElement
                required
                inputType="text"
                defaultText="Business name"
                value={businessName}
                onChange={e => {
                  inputModified();
                  setBusinessName(e.target.value);
                }}
              />
            ) : null}

            {/* <div
              style={{
                margin: '0 0 1rem 0',
              }}
            >
              <Select
                options={supportedCountries}
                styles={CustomStyles}
                placeholder="Country"
                getOptionLabel={(p: any) => (p as HTMLSelectElement).name}
                value={supportedCountries.filter(p => p.name === country)}
                onChange={(e: any) => {
                  setCountry((e as HTMLSelectElement).name);
                  setPhoneCode((e as any).mobileCode);
                }}
              />
            </div> */}
            <InputElement
              inputType="email"
              defaultText="Email address"
              value={userEmail}
              onChange={e => {
                inputModified();
                setUserEmail(e.target.value.trim(''));
              }}
              onKeyPress={e => {
                if (e.key === ' ') {
                  e.preventDefault();
                }
              }}
            />
            <MobileInputWrap>
              <MobileSelect>
                <div
                  style={{
                    width: '6rem',
                  }}
                >
                  <InputActPlacehold
                    onClick={e => {
                      setIsInputFocus(!isInputFocus);
                      e.stopPropagation();
                    }}
                    onBlur={() => setIsInputFocus(false)}
                  >
                    <div>
                      <ReactCountryFlag
                        style={{
                          fontSize: '1.3rem',
                          borderRadius: '50%',
                        }}
                        countryCode={flag}
                        svg
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        cdnSuffix="svg"
                      />
                      <p
                        style={{
                          color: '#191919',
                        }}
                      >
                        {phoneCode}
                      </p>
                    </div>
                    <i>
                      <Icon.ChevronDown size={15} strokeWidth={3} />
                    </i>
                  </InputActPlacehold>
                </div>
              </MobileSelect>
              <CollabSuggestWrap
                style={{
                  margin: '0rem 0 0 0',
                  width: '8rem',
                  zIndex: '1000000000',
                  top: '2rem',
                }}
              >
                {isInputFocus ? (
                  <div>
                    {Countries && Countries.length > 0 ? (
                      Countries.map((item, index) => (
                        <SplitUsersWrap
                          alignVertical="flex-start"
                          key={index}
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setPhoneCode(item.mobileCode);
                            setFlag(item.code);
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                width: '100%',
                              }}
                            >
                              <ReactCountryFlag
                                style={{
                                  fontSize: '1.3rem',
                                  borderRadius: 'none',
                                }}
                                countryCode={item.code}
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                cdnSuffix="svg"
                                title={item.code}
                              />
                              <h3
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  width: '25%',
                                }}
                              >
                                {item.code ? `${item.code}        ` : ''}
                              </h3>
                              <div
                                style={{
                                  width: '70%',
                                }}
                              >
                                <h3
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '70%',
                                  }}
                                >
                                  {item.mobileCode
                                    ? `${item.mobileCode}        `
                                    : ''}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </SplitUsersWrap>
                      ))
                    ) : (
                      <p
                        style={{
                          textAlign: 'center',
                          fontSize: '14px',
                          color: '#757575',
                          margin: '1rem 0 0 0',
                        }}
                      >
                        No Option.
                      </p>
                    )}
                  </div>
                ) : null}
              </CollabSuggestWrap>
              <MobileInput
                type="text"
                maxLength={10}
                value={userPhone}
                onChange={e => {
                  inputModified();
                  setUserPhone(e.target.value);
                }}
                onKeyPress={e => {
                  handleZero(e);
                  numOnly(e);
                }}
                required={true}
                autoComplete={'off'}
              />
            </MobileInputWrap>
            <div className="relative">
              <InputElement
                inputType={revealPass ? 'text' : 'password'}
                defaultText="Password"
                value={userPassword}
                minLength={8}
                onChange={e => {
                  inputModified();
                  setUserPassword(e.target.value);
                  handlePassword(e);
                }}
                onKeyPress={e => {
                  if (e.key === ' ') {
                    e.preventDefault();
                  }
                }}
              />
              <button
                className="absolute bottom-8 right-6"
                onClick={e => toggleHandler(e, 'pass')}
              >
                <i>
                  {revealPass ? (
                    <Icon.EyeOff size={18} />
                  ) : (
                    <Icon.Eye size={18} />
                  )}
                </i>
              </button>
            </div>
            <PwdGuideWrap>
              {showGuidelines ? (
                <>
                  <PasswordGuide
                    text={'One lowercase character'}
                    metCondition={lowerChar}
                  />
                  <PasswordGuide
                    text={'One number'}
                    metCondition={numberChar}
                  />
                  <PasswordGuide
                    text={'One uppercase character'}
                    metCondition={upperChar}
                  />
                  <PasswordGuide
                    text={'8 characters minimum'}
                    metCondition={eigthChar}
                  />
                  <PasswordGuide
                    text={'One special character(!@#$%^*()&._-)'}
                    metCondition={specialChar}
                  />
                </>
              ) : null}
            </PwdGuideWrap>
            <p className="my-4 ml-1 text-sm text-gray-500">
              By signing up you agree with all our <Link to="/#">Terms</Link>{' '}
              and <Link to="/#">Conditions</Link>
            </p>
            {clientErr && (
              <p
                className="text-sm text-red-500 font-medium ml-1 my-1"
                style={{
                  fontSize: '12px',
                  margin: '1rem 0 0 0',
                }}
              >
                {clientErr}
              </p>
            )}
            <SubmitButton
              disabled={onProcess}
              text={onProcess ? <BeatLoader color="white" /> : 'Sign up'}
              style={
                onProcess ? { cursor: 'not-allowed' } : { cursor: 'pointer' }
              }
            />
          </form>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

export default SignUp;

// const signAs = [
//   {
//     name: 'Individual',
//     borderRadius: '10px 10px 10px 10px',
//   },
//   {
//     name: 'Business',
//     borderRadius: '10px 10px 10px 10px',
//   },
// ];

interface PwdProps {
  text: string;
  metCondition?: boolean;
}

export const PasswordGuide = ({ text, metCondition }: PwdProps) => {
  return (
    <>
      <div>
        <div
          style={
            metCondition
              ? { background: 'rgba(107, 211, 43, 0.4)' }
              : { background: '#f2f2f2' }
          }
        ></div>
        <p>{text}</p>
      </div>
    </>
  );
};
