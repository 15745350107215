import React from 'react';
import * as Icon from 'react-feather';
import { BeatLoader } from 'react-spinners';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
} from 'app/components/dashboard/mainContent/fundWallet/style';
import { SplitUsersWrap, SplitAvatar, SingleInfoFlex } from './style';
import CommaNumber from 'comma-number';
import { getSign } from 'utils/getCurrencySign';

const CreateWarning = ({
  handleAction,
  actionInProcess,
  closeFunc,
  message,
  actionText,
  splitName,
  splitType,
  limitValue,
  frequency,
  avatar,
  collaborators,
  currency,
}) => {
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <p
            style={{
              width: '20rem',
              margin: '2rem 0 0 0',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            {message}
          </p>
          <br />{' '}
          {splitName !== '' ? (
            <SingleInfoFlex>
              <section>
                <img
                  src={
                    avatar
                      ? avatar
                      : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                  }
                  alt="Display"
                />
              </section>
              <div>
                <h4>{splitName}</h4>
                {splitType === 'time' ? (
                  <p>
                    <span>
                      <Icon.Zap
                        size={15}
                        style={{
                          position: 'relative',
                          top: '0.05rem',
                        }}
                      />
                      {frequency}
                    </span>
                  </p>
                ) : null}
                {splitType === 'approval' ? (
                  <p>
                    <span>
                      <Icon.Zap
                        size={15}
                        style={{
                          position: 'relative',
                          top: '0.05rem',
                        }}
                      />
                      On Approval
                    </span>
                  </p>
                ) : null}
                {splitType === 'instantly' ? (
                  <p>
                    <span>
                      <Icon.Zap
                        size={15}
                        style={{
                          position: 'relative',
                          top: '0.05rem',
                        }}
                      />
                      {splitType}
                    </span>
                  </p>
                ) : null}
              </div>
            </SingleInfoFlex>
          ) : null}
          {collaborators && collaborators.length > 0
            ? collaborators.map((item, index) => (
                <SplitUsersWrap key={index}>
                  <SplitAvatar>
                    {item.name ? item.name[0] : item.email[0]}
                  </SplitAvatar>
                  <div
                    style={{
                      width: '55%',
                    }}
                  >
                    <h3>
                      {item.name} {index === 0 ? <span> . Owner</span> : ''}
                    </h3>
                    {!item.name && item.email ? (
                      <h3>
                        {item.email} {index === 0 ? <span> . Owner</span> : ''}
                      </h3>
                    ) : null}
                    <p>{item.email && item.name ? item.email : ''}</p>
                  </div>
                  {item.percentage || item.percentage > 0 ? (
                    <p>{item.percentage}%</p>
                  ) : (
                    <p>
                      {getSign(currency)}
                      {CommaNumber(item.amount)}
                    </p>
                  )}
                </SplitUsersWrap>
              ))
            : null}
          <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
              }}
            >
              {'Cancel'}
            </button>
            <button
              onClick={() => handleAction()}
              style={{ width: '9rem', margin: '2rem 0 0 0' }}
            >
              {actionInProcess ? <BeatLoader color="#fff" /> : actionText}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default CreateWarning;
