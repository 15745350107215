import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { useState } from 'react';
import styled from 'styled-components';
import Store from 'utils/store';
import Helper from 'utils/helper';
import { getSign } from 'utils/getCurrencySign';
import ConfirmPin from './ConfirmPin';
import { ClipLoader } from 'react-spinners';
import BankTransfer from '../dashboard/mainContent/fundWallet/bankTransfer';
import { updateRequest } from 'utils/request';
import Alert from '../dashboard/mainContent/fundWallet/alert';
const PaymentScreen = props => {
  const dealCode = window.location.href.split('dealCode=')[1].split('/fund')[0];
  const [data, setData] = useState<any>(Store.get('activeDeal'));
  const account: {
    balance: { naira: number; dollar: number };
    accountInfo: {
      accountNumber: string;
      accountName: string;
      bankName: string;
    };
  } = Store.get('accountInfo');
  const navigate = useNavigate();

  if (data.code !== dealCode) {
    navigate('/deals');
  }
  const insufficientBalance = data.amount > account.balance.naira;

  const [processing, setProcessing] = useState(false);
  const [confirmPin, setConfirmPin] = useState(false);
  const [fundWallet, setFundWallet] = useState(false);
  const [_alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [activeAlertImage, setActiveAlertImage] = useState('/failed.png');

  const handleFundDeal = () => {
    setProcessing(true);
    updateRequest(`deals/${data.id}`, { status: 'funded' })
      .then(res => {
        const updatedData = { ...data };
        updatedData.status = 'funded';
        setData(updatedData);
        setActiveAlertImage('/success.png');
        setProcessing(false);
        Store.updateObject('activeDeal', { status: 'funded' });
        setAlertMessage('You successfully funded this deal');
        setAlert(true);
      })
      .catch(err => {
        setAlertMessage(err.message);
        setAlert(true);
        setActiveAlertImage('/failed.png');
        setProcessing(false);
      });
  };

  const returnToDeal = () => {
    setAlert(false);
    navigate('./../');
  };

  return (
    <DealWrapper className="deal-view">
      {_alert && (
        <Alert
          closeFunc={() => returnToDeal()}
          message={
            activeAlertImage === '/success.png'
              ? 'Successful'
              : 'An error occured'
          }
          miniMessage={alertMessage}
          img={activeAlertImage}
        />
      )}
      <DealHeader>
        <Icon.X
          size={24}
          style={{
            position: 'relative',
            left: '0.5rem',
            marginRight: '2rem',
          }}
          className="close-icon"
          onClick={() => {
            navigate('./..');
          }}
        />
        <h1 style={{ fontSize: 20, marginTop: -2.5, fontWeight: 500 }}>
          {data.name}
        </h1>
      </DealHeader>
      <DealBody>
        <MainBox>
          <PaymentBox className="content">
            {insufficientBalance && (
              <p
                style={{
                  textAlign: 'center',
                  fontSize: 12,
                  color: 'rgba(255, 50, 50)',
                  margin: 20,
                  width: '60%',
                  position: 'relative',
                  left: 'calc(20% - 20px)',
                }}
              >
                Not enough balance to fund this deal. Click {''}
                <span
                  style={{
                    color: '#007aff',
                    fontWeight: 500,
                    cursor: 'pointer',
                    fontSize: 14,
                    textDecoration: 'underline',
                  }}
                  onClick={() => setFundWallet(true)}
                >
                  here
                </span>{' '}
                to fund your wallet now.
              </p>
            )}
            <div className="form">
              <span className="right">
                Balance: {Helper.formatCurrency(account.balance.naira)}
              </span>
              <label htmlFor="">Amount</label>
              <p className="input">
                {getSign('NGN')} &nbsp; {Helper.formatMoney(data.amount)}
              </p>
            </div>
            <div className="form">
              <label htmlFor="">Deal</label>
              <p className="select">{data.name}</p>
            </div>
            <button
              disabled={insufficientBalance || processing}
              onClick={() => setConfirmPin(true)}
            >
              {processing ? <ClipLoader color="#fff" /> : 'Fund Deal'}
            </button>
          </PaymentBox>
          {confirmPin && (
            <ConfirmPin
              closeFunc={() => setConfirmPin(false)}
              onProcess={false}
              onConfirm={e => handleFundDeal()}
              note={
                <>
                  Confirm funding of{' '}
                  <span style={{ color: '#C82B32CC', fontWeight: 500 }}>
                    {data.name}'s
                  </span>{' '}
                  request of {''}
                  <span style={{ color: '#C82B32CC', fontWeight: 500 }}>
                    {Helper.formatCurrency(data.amount)}
                  </span>
                </>
              }
            />
          )}
          {fundWallet && (
            <BankTransfer
              accountName={account.accountInfo.accountName}
              accountNo={account.accountInfo.accountNumber}
              bankName={account.accountInfo.bankName}
              closeFunc={() => setFundWallet(false)}
            />
          )}
        </MainBox>
      </DealBody>
    </DealWrapper>
  );
};

export default PaymentScreen;

const DealWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .close-icon {
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const DealHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 50px 20px 30px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
`;

const DealBody = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
`;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  height: calc(100vh - 78px);
`;

const PaymentBox = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 380px;
  @media (max-width: 460px) {
    width: 300px;
  }
  @media (max-width: 340px) {
    width: 250px;
  }
  @media (max-width: 280px) {
    width: 200px;
  }
  min-height: 200px;
  flex-direction: column;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #0000000d;
  box-shadow: 0 2px 12px #f0f0f0;
  padding: 20px;
  font-size: 14px;
  .form {
    position: relative;
    label {
      color: #474747;
    }
    p {
      color: #ababab;
      padding: 10px 10px;
      margin: 10px 0;
      background: #f9f9f9;
    }
    padding: 10px;
  }
  .right {
    color: #ababab;
    position: absolute;
    right: 10px;
  }
  button {
    background: #161616;
    color: #ffffff;
    font-weight: 500;
    width: calc(100% - 20px);
    margin: 10px;
    text-align: center;
    font-size: 16px;
    padding: 10px 50px;
    border-radius: 2.5px;
    &:hover {
      opacity: 0.5;
    }
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`;
