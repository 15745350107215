import axios from 'axios';
import { useEffect, useState } from 'react';
import PopUpModal from '../../../merch/popupModal';
// import Countries from '../../../../../countries.json';
import {
  MainWrapper,
  PreferenceBlock,
  // BlockImage,
  // CameraIconWrap,
  // ImageDisplay,
} from './styles';
import Select from 'react-select';
import { BeatLoader } from 'react-spinners';
// import { UserAvatar } from 'app/components/profile/style';
import {
  Constraint,
  // BlueText,
  // ImageSetUp,
  // StoreAvatar,
} from '../../../invoice/mainInvoice/style';
import { TicketInput } from 'app/components/paymentLink/style';
import { GenericBtn } from 'app/components/paymentLink/style';
import * as Icon from 'react-feather';
import { HeaderConstraint } from '../../../invoice/mainInvoice/style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import {
  UsernameFormbox,
  UsernameFormWrap,
  UsernameFormboxP,
  UsernameInput,
} from './styles';
import ContactSettings from './contact';
import { SearchPos } from 'app/components/transactions/style';
import { CustomStyles } from 'utils/selectCustomStyle';

const PreferenceCont = () => {
  const store = JSON.parse(localStorage.getItem('store') || '{}');
  const [storeName, setStoreName] = useState<any>('');
  const [response, setResponse] = useState<any>(false);
  const [address, setAddress] = useState<any>('');
  const [phone, setPhoneNo] = useState<any>('');
  const [currency, setCurrency] = useState<any>('NGN');
  const [city, setCity] = useState('');
  // const [images, setImages] = useState<any>([]);
  // const [returnedImages, setReturnedImages] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>('Nigeria');
  // const [selectedState, setSelectedState] = useState<any>({});
  // const [storeState, setStoreState] = useState<any>('');

  // Modal Toggling
  const [openContactModal, setOpenContactModal] = useState(false);

  const toggleContactModal = () => {
    setOpenContactModal(!openContactModal);
  };

  // Set Store Information to store.
  useEffect(() => {
    const store = JSON.parse(localStorage.getItem('store') || '{}');
    if (store.length !== 0) {
      setStoreName(store.storeInfo.name);
      setCurrency(store.storeInfo.currency);
      // setSelectedState(store.storeInfo.state);
      setAddress(store.storeInfo.address);
      setPhoneNo(store.storeInfo.phone);
      setCity(store.storeInfo.city);
      setSelectedCountry(store.storeInfo.country);
    }
  }, []);

  var creator = JSON.parse(localStorage.getItem('logged_user') || '{}');
  var loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  // const selected = Countries.find(p => p.name === selectedCountry) || '[]';

  // useEffect(() => {
  //   setSelectedState(selected);
  // }, [selected, storeState]);

  const SelectInputsArray = [
    {
      title: 'Store Country',
      options: [{ name: 'Nigeria' }],
      info: `Didn't see your country? We might not be available there right now.`,
      handleInputFunc: setSelectedCountry,
      defValue: selectedCountry,
      state: selectedCountry,
    },
    // {
    //   title: 'State',
    //   options: selectedState ? selectedState.states : [],
    //   handleInputFunc: setStoreState,
    //   state: storeState,
    // },
  ];

  const APIData = {
    storeInfo: {
      name: storeName,
      currency: currency,
      country: selectedCountry,
      address: address,
      phone: phone,
    },
  };

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${creator.tokens.access.token}`,
      mode: 'cors',
    },
  };

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handlePatch = e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 15000);
    axios
      .patch(
        process.env.REACT_APP_BASE_ENDPOINT +
          `/creator-page/${creator.user.creatorPage}`,
        APIData,
        option,
      )
      .then(res => {
        if (res.status === 200) {
          setResponse(true);
          setLoading(false);
          localStorage.setItem('store', JSON.stringify(res.data));
          setTimeout(() => {
            setResponse(false);
          }, 4000);
        } else if (res.data.code === 400) {
          setErrorMessage(res.data.message);
          setLoading(false);
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        }
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage('An error just occured, please try again!');
      });
  };

  const handleClose = () => {
    setResponse(false);
    setErrorMessage('');
  };

  const currencies = [{ value: 'NGN' }, { value: 'USD' }];

  return (
    <>
      {openContactModal ? (
        <ContactSettings
          closeFunc={toggleContactModal}
          setFunc={toggleContactModal}
          phone={phone}
          address={address}
          city={city}
          setCity={setCity}
          setAddress={setAddress}
          setPhone={setPhoneNo}
          SelectInputsArray={SelectInputsArray}
        />
      ) : null}
      <MainWrapper>
        {response ? (
          <PopUpModal
            handleClose={handleClose}
            msg="Your store have been updated successfully!"
            extraMsg="Changes to your store have been made."
            icon="fas fa-check"
            iconColor="green"
          />
        ) : null}

        {errorMessage !== '' ? (
          <PopUpModal
            handleClose={handleClose}
            msg="An Error occured!"
            extraMsg={errorMessage}
            icon="fas fa-exclamation"
            iconColor="red"
          />
        ) : null}
        <SearchPos position="fixed" hideMobile={true}>
          <button
            style={{
              margin: '0 1rem 0 0',
              color: '#fff',
              padding: '0.5rem 2rem',
              background: '#191919',
              borderRadius: '4px',
              fontSize: '0.8rem',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={e => handlePatch(e)}
          >
            {loading ? <BeatLoader color="#fff" /> : 'Save Changes'}
          </button>
        </SearchPos>
        <PreferenceBlock>
          <HeaderConstraint width="30%" mobileTop="2rem">
            <div
              style={{
                position: 'relative',
                bottom: '1rem',
                left: '0.5rem',
              }}
            >
              <p>My store</p>
              <a
                href={
                  'https://' +
                  store.slug +
                  process.env.REACT_APP_PAGE_LINK +
                  '/store?token=' +
                  loggedUser.tokens.access.token
                }
                target="_blank"
                rel="noreferrer"
              >
                <Icon.Eye size={12} color="#474747" />
              </a>
            </div>
          </HeaderConstraint>
          <Constraint
            top="-2rem"
            mobileTop="-7rem"
            style={{ margin: '0 auto' }}
          >
            <form onSubmit={e => handlePatch(e)}>
              {/* <ImageSetUp>
                {images && images.length > 0 && returnedImages === '' ? (
                  <StoreAvatar src={URL.createObjectURL(images[0])} />
                ) : null}

                {images.length < 1 && returnedImages === '' ? (
                  <UserAvatar
                    style={{
                      margin: '0 auto',
                    }}
                  >
                    {storeName ? storeName[0] : null}
                  </UserAvatar>
                ) : null}

                {returnedImages !== '' ? (
                  <StoreAvatar src={returnedImages} />
                ) : null}
                <label htmlFor="main-input">
                  <BlueText
                    style={{
                      margin: 0,
                      padding: 0,
                    }}
                  >
                    {'Add Store Logo'}
                  </BlueText>
                </label>

                <input
                  id="main-input"
                  type="file"
                  multiple={false}
                  style={{ display: 'none' }}
                  onChange={e => {
                    handleFile(e.target.files);
                  }}
                ></input>
              </ImageSetUp> */}
              <div>
                <TicketInput style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <p>Store name</p>
                    <input
                      type="text"
                      onChange={e => setStoreName(e.target.value)}
                      required={true}
                      value={storeName}
                    />
                  </div>
                </TicketInput>
                <UsernameFormWrap>
                  <UsernameInput
                    name="username"
                    id="username"
                    type="text"
                    placeholder="username"
                    disabled={true}
                    value={store.slug}
                  />
                  <UsernameFormbox>
                    <UsernameFormboxP>
                      {process.env.REACT_APP_PAGE_LINK}/store
                    </UsernameFormboxP>
                  </UsernameFormbox>
                </UsernameFormWrap>
                <GenericBtn
                  onClick={() => toggleContactModal()}
                  style={
                    phone || address || selectedCountry
                      ? { border: '1px solid rgba(51, 163, 41, 0.7)' }
                      : {}
                  }
                >
                  Contact Details
                  <i>
                    <Icon.ChevronRight size={20} />
                  </i>
                </GenericBtn>
                <div
                  style={{
                    margin: '0.9rem 0 0 0',
                  }}
                >
                  <label
                    style={{
                      color: '#191919',
                      fontSize: '14px',
                      position: 'relative',
                      bottom: '0.2rem',
                    }}
                  >
                    Currency
                  </label>
                  <Select
                    defaultValue={currency}
                    defaultInputValue={currency}
                    placeholder="Search..."
                    styles={CustomStyles}
                    onChange={(e: any) =>
                      setCurrency((e as HTMLSelectElement).value)
                    }
                    options={currencies}
                    getOptionLabel={p => p.value}
                    isSearchable
                  />
                </div>

                <BigBtn>
                  <button type="submit">
                    {loading ? <BeatLoader color="#fff" /> : 'Save Changes'}
                  </button>
                </BigBtn>
              </div>
            </form>
          </Constraint>
        </PreferenceBlock>
      </MainWrapper>
    </>
  );
};

export default PreferenceCont;
