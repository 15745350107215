import React, { useEffect, useState } from 'react';
import { BlueText, ImageSetUp, MainWrapper, StoreAvatar } from './style';
import { BreadcrumbArea } from './../../orderComp/mainContent/orderContent/styles';
import {
  MiniHeader,
  PreferenceBlock,
} from '../../Preference/mainContent/preferenceContent/styles';
import { Constraint } from './style';
import { TicketInput } from 'app/components/paymentLink/style';
import { ActionBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import PopUpModal from 'app/components/merch/popupModal';
import { useNavigate } from 'react-router';
import { UserAvatar } from 'app/components/profile/style';
import { BeatLoader } from 'react-spinners';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import { numOnly } from 'utils/numOnly';
import { handleFileUpload } from 'utils/imageUpload';

const Setup = () => {
  const navigate = useNavigate();

  const [collectedData, setCollectedData] = useState<any>({});
  const [userDetails, setUserDetails] = useState<any>({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState('');
  const [images, setImages] = useState<any>([]);
  const [returnedImages, setReturnedImages] = useState<any>('');

  // Get Invoice Setup Data

  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  useEffect(() => {
    let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const option = {
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${loggedUser.tokens.access.token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/invoices/setup`, option)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 1) {
          setCollectedData(res.data);
          setEmail(res.data.email);
          setPhoneNo(res.data.phoneNumber);
          if (res.data.logo) {
            setReturnedImages(res.data.logo);
          }
          setName(res.data.name);
        }
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    setUserDetails(loggedUser.user);
  }, []);

  useEffect(() => {
    if (Object.keys(userDetails).length > 0) {
      setEmail(userDetails.email);
      setPhoneNo(userDetails.phoneNumber);
    }
  }, [userDetails]);

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setImages,
      setError,
      setReturnedImages,
    });
  };

  const handleClose = () => {
    setError('');
  };

  const apiPayload = {
    name: name,
    email: email,
    countryCode: '+234',
    phoneNumber: phoneNo,
  };

  const handleNext = async e => {
    if (returnedImages && returnedImages !== '') {
      apiPayload['logo'] = returnedImages;
    }

    e.preventDefault();
    setOnProcess(true);
    setTimeout(() => {
      setOnProcess(false);
    }, 30000);

    (await collectedData) && Object.keys(collectedData).length > 0
      ? axios
          .patch(
            `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/setup`,
            apiPayload,
            option,
          )
          .then(res => {
            navigate('/new-invoice');
            setOnProcess(false);
          })
          .catch(err => {
            setError(err.response.data.message);
            setTimeout(() => {
              setError('');
            }, 4000);
          })
      : axios
          .post(
            `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/setup`,
            apiPayload,
            option,
          )
          .then(res => {
            navigate('/new-invoice');
            setOnProcess(false);
          })
          .catch(err => {
            setError(err.response.data.message);
            setTimeout(() => {
              setError('');
            }, 4000);
          });
  };

  return (
    <>
      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      <MainWrapper
        width="100%"
        style={{
          background: '#fff',
        }}
      >
        <FragmentHeader
          showBack={true}
          pageName="Invoice setup"
          backLink="/invoice"
        />
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              top="-2rem"
              mobileTop="-2rem"
              style={{ margin: '0 auto' }}
            >
              <MiniHeader>
                <h2>Set up your Invoice</h2>
                <p>Customise your invoice details</p>
              </MiniHeader>
              <form onSubmit={e => handleNext(e)}>
                <ImageSetUp>
                  {images && images.length > 0 && returnedImages === '' ? (
                    <StoreAvatar src={URL.createObjectURL(images[0])} />
                  ) : null}

                  {images.length < 1 && returnedImages === '' ? (
                    <UserAvatar
                      style={{
                        margin: '0 auto',
                      }}
                    >
                      {userDetails && userDetails.firstName
                        ? userDetails.firstName[0]
                        : null}
                    </UserAvatar>
                  ) : null}

                  {returnedImages !== '' ? (
                    <StoreAvatar src={returnedImages} />
                  ) : null}
                  <label htmlFor="main-input">
                    <BlueText
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      {'Add Invoice Logo'}
                    </BlueText>
                  </label>

                  <input
                    id="main-input"
                    type="file"
                    multiple={false}
                    style={{ display: 'none' }}
                    onChange={e => {
                      handleFile(e.target.files);
                    }}
                  ></input>
                </ImageSetUp>
                <div>
                  <TicketInput style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      <p>Your brand name</p>
                      <input
                        type="text"
                        onChange={e => setName(e.target.value)}
                        required={true}
                        value={name}
                      />
                    </div>
                  </TicketInput>
                  <TicketInput style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      <p>Your email address</p>
                      <input
                        type="email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        required={true}
                      />
                    </div>
                  </TicketInput>
                  <TicketInput style={{ width: '100%' }}>
                    <div style={{ width: '100%' }}>
                      <p>Phone number</p>
                      <input
                        type="text"
                        maxLength={11}
                        value={phoneNo}
                        onKeyPress={e => numOnly(e)}
                        onChange={e => setPhoneNo(e.target.value)}
                        required={true}
                      />
                    </div>
                  </TicketInput>
                  <ActionBtn
                    style={{
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      gap: '20px',
                    }}
                  >
                    <div
                      style={{
                        width: '10.5rem',
                        background: '#fff',
                        color: '#191919',
                        border: '1px solid #191919',
                        borderRadius: '4px',
                        padding: '0.4rem 0',
                        textAlign: 'center',
                      }}
                      onClick={() => navigate(-1)}
                    >
                      {'Cancel'}
                    </div>
                    <button
                      type="submit"
                      style={{ width: '9rem', margin: '2rem 0 0 0' }}
                    >
                      {onProcess ? <BeatLoader color="#fff" /> : 'Finish setup'}
                    </button>
                  </ActionBtn>
                </div>
              </form>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default Setup;
