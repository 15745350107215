const RESERVED_NAMES = [
  'account',
  'accounts',
  'api',
  'apis',
  'app',
  'apps',
  'blog',
  'blogs',
  'book',
  'books',
  'campaign',
  'campaigns',
  'channel',
  'channels',
  'contact',
  'content',
  'contents',
  'coupon',
  'coupons',
  'dashboard',
  'demo',
  'demos',
  'dev',
  'discount',
  'discounts',
  'event',
  'events',
  'help',
  'helpcenter',
  'helpcentre',
  'info',
  'learn',
  'learning',
  'link',
  'mail',
  'merch',
  'merchandise',
  'merchro',
  'money',
  'owner',
  'page',
  'pay',
  'payment',
  'payments',
  'port',
  'portal',
  'promo',
  'promos',
  'school',
  'shop',
  'shops',
  'staging',
  'store',
  'stores',
  'support',
  'survey',
  'surveys',
  'user',
  'users',
  'tool',
  'tools',
  'tutorial',
  'tutorials',
  'vlog',
  'vlogs',
  'video',
  'videos',
  'web',
  'www',
];

export default RESERVED_NAMES;
