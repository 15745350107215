import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  CloseBtn,
  ServiceFlex,
  Service,
} from './style';
import * as Icon from 'react-feather';

const PayBill = ({ closeFunc, openNext }) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Pay your bill</Header>
          <div>
            <InputWrap>
              <label>Data services</label>{' '}
            </InputWrap>
          </div>
          <ServiceFlex>
            {dataArray.map(item => (
              <Service onClick={() => openNext()}>
                <img src={item.img} alt={`${item.name} logo`} />
                <h3>{item.name}</h3>
                <p>{item.type}</p>
              </Service>
            ))}
          </ServiceFlex>
          <div>
            <InputWrap>
              <label>Internet services</label>{' '}
            </InputWrap>
          </div>
          <ServiceFlex>
            {internetArray.map(item => (
              <Service onClick={() => openNext()}>
                <img src={item.img} alt={`${item.name} logo`} />
                <h3>{item.name}</h3>
                <p>{item.type}</p>
              </Service>
            ))}
          </ServiceFlex>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default PayBill;

const dataArray = [
  {
    name: '9mobile',
    type: 'Data bundle',
    img: 'test/etisalat.png',
  },
  {
    name: 'MTN',
    type: 'Data bundle',
    img: 'test/mtn.png',
  },
  {
    name: 'Airtel',
    type: 'Data bundle',
    img: 'test/airtel.png',
  },
  {
    name: 'Glo',
    type: 'Data bundle',
    img: 'test/glo.png',
  },
];

const internetArray = [
  {
    name: 'Spectranet',
    type: 'Internet',
    img: 'test/spectranet.png',
  },
  {
    name: 'Swift',
    type: 'Internet',
    img: 'test/swift.png',
  },
  {
    name: 'Smile',
    type: 'Internet',
    img: 'test/smile.png',
  },
  {
    name: 'ipNX',
    type: 'Internet',
    img: 'test/ipnx.png',
  },
];
