import React, { useEffect, useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from '../dashboard/mainContent/fundWallet/style';
import * as Icon from 'react-feather';
import { TicketInput } from './../paymentLink/style';
import Select from 'react-select';
import axios from 'axios';
import { numOnly } from 'utils/numOnly';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import { useNavigate } from 'react-router';
import { BeatLoader } from 'react-spinners';
import { CustomStyles } from 'utils/selectCustomStyle';

interface PropsArgs {
  closeFunc: any;
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  type: string;
  limitValue: number;
  frequency: string;
}

const EditSplit = ({
  closeFunc,
  id,
  name,
  startDate,
  endDate,
  type,
  limitValue,
  frequency,
}: PropsArgs) => {
  const navigate = useNavigate();

  const [splitName, setSplitName] = useState('');
  const [splitStartDate, setSplitStartDate] = useState('');
  const [splitEndDate, setSplitEndDate] = useState('');
  const [splitType, setSplitType] = useState('');
  const [splitLimitValue, setSplitLimitValue] = useState<number>();
  const [splitFrequency, setSplitFrequency] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  useEffect(() => {
    setSplitName(name);
    setSplitStartDate(startDate);
    setSplitEndDate(endDate);
    setSplitType(type);
    setSplitFrequency(frequency);
    setSplitLimitValue(limitValue);
  }, [endDate, frequency, limitValue, name, startDate, type]);

  // User Authentication
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  // Edit Split

  const basicPayload = {
    name: splitName,
    startDate: splitStartDate,
    endDate: splitEndDate,
    splitType,
  };

  const editSplit = async e => {
    e.preventDefault();
    try {
      setOnProcess(true);
      if (endDate && endDate !== '') {
        basicPayload['endDate'] = endDate;
      }
      if (splitType === 'limit') {
        basicPayload['limitValue'] = splitLimitValue;
      } else if (splitType === 'time') {
        basicPayload['frequency'] = splitFrequency;
      }

      await axios
        .patch(
          `${process.env.REACT_APP_BASE_ENDPOINT}/splits/${id}`,
          basicPayload,
          headers,
        )
        .then(res => {
          setSuccess(true);
          setOnProcess(false);
          setTimeout(() => {
            navigate('/split-payment');
          }, 4000);
        })
        .catch(err => {
          setError(err.response.data.message);
          setOnProcess(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    } catch {}
  };

  const handleClose = () => {
    setError('');
    setSuccess(false);
  };

  useEffect(() => {
    if (splitStartDate) {
      setSplitStartDate(`${splitStartDate.split('T')[0]}`);
    }
    if (splitEndDate) {
      setSplitEndDate(`${splitEndDate.split('T')[0]}`);
    }
  }, [splitStartDate, splitEndDate]);

  return (
    <>
      {error ? (
        <Alert
          closeFunc={handleClose}
          img="/failed.png"
          message="An error occurred"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={handleClose}
          img="/success.png"
          message="Split edited successfully"
          miniMessage={'You have successfully edited your split. '}
        />
      ) : null}

      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header
            style={{
              textAlign: 'left',
            }}
          >
            Edit Split
          </Header>
          <p
            style={{
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '19px',
              margin: '0 0 1rem 0',
              letterSpacing: '-0.01em',
              color: '#ABABAB',
            }}
          >
            Make changes to your split
          </p>
          <form onSubmit={e => editSplit(e)}>
            <div>
              <TicketInput>
                <div>
                  <p>Split name</p>
                  <input
                    required={true}
                    value={splitName}
                    onChange={e => setSplitName(e.target.value)}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>Start Date</p>
                  <input
                    required={true}
                    type="date"
                    value={splitStartDate}
                    onChange={e => setSplitStartDate(e.target.value)}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>End Date</p>
                  <input
                    type="date"
                    value={splitEndDate}
                    onChange={e => setSplitStartDate(e.target.value)}
                  />
                </div>
              </TicketInput>
              <p
                style={{
                  fontSize: '12px',
                  color: '#757575',
                  margin: '0.4rem 0 0 0',
                }}
              >
                Split Method
              </p>
              <Select
                options={sTypes}
                getOptionLabel={p => p.name}
                styles={CustomStyles}
                value={sTypes.filter(p => p.value === splitType)}
                onChange={e => setSplitType((e as HTMLSelectElement).value)}
              />
              {splitType === 'time' ? (
                <select
                  style={{
                    width: '100%',
                    border: '1px solid #dadada',
                    margin: '1rem 0 0 0',
                    padding: '10px 5px',
                    borderRadius: '4px',
                  }}
                  value={splitFrequency}
                  required
                  onChange={e =>
                    setSplitFrequency(e.target.value.toLocaleLowerCase())
                  }
                >
                  <option>Daily</option>
                  <option>Weekly</option>
                  <option>Monthly</option>
                  <option>Quaterly</option>
                  <option>Yearly</option>
                </select>
              ) : null}

              {splitType === 'limit' ? (
                <TicketInput
                  style={{
                    width: '100%',
                    margin: '1rem 0 0 0',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <p>Limit(&#8358;)</p>
                    <input
                      type="number"
                      required
                      value={splitLimitValue}
                      onChange={e => setSplitLimitValue(Number(e.target.value))}
                      onKeyPress={e => numOnly(e)}
                    />
                  </div>
                </TicketInput>
              ) : null}
            </div>
            <BigBtn>
              <button type="submit">
                {onProcess ? <BeatLoader color="#fff" /> : 'Save Split'}
              </button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default EditSplit;

const sTypes = [
  {
    name: 'Time based',
    value: 'time',
  },
  {
    name: 'Limit Based',
    value: 'limit',
  },
  {
    name: 'Instant',
    value: 'instant',
  },
];
