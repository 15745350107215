import React, { useState } from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
  InputWrap,
} from './../../transactions/style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import Select from 'react-select';
import { TicketInput } from 'app/components/paymentLink/style';
import { numOnly } from 'utils/numOnly';

interface PropsArgs {
  closeFunc: any;
  header: any;
  subText?: any;
  discount?: any;
  discountPercent?: any;
  tax?: any;
  taxPercent?: any;
  shipping?: any;
  setTaxUnit: any;
  discountUnit: any;
  taxUnit: any;
  setDiscountUnit: any;
  setTax: any;
  setDiscount: any;
  setShipping: any;
}

const OptionalCost = ({
  closeFunc,
  header,
  subText,
  discount,
  discountPercent,
  tax,
  taxPercent,
  shipping,
  discountUnit,
  taxUnit,
  setTaxUnit,
  setDiscountUnit,
  setTax,
  setDiscount,
  setShipping,
}: PropsArgs) => {
  const [discountError, setDiscountError] = useState('');
  const [taxError, setTaxError] = useState('');

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      fontWeight: 300,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      fontSize: '16px',
      background: 'transparent',
      color: '@161616',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const handleSet = () => {
    closeFunc();
  };

  const validateDiscountInput = () => {
    if (discountUnit === '%') {
      if (Number(discount) > 100 || Number(discount < 0)) {
        setDiscount('0');
        setDiscountError(
          'Discount percentage cannot be lower than 0 or higher than 100',
        );
        setTimeout(() => {
          setDiscountError('');
        }, 4000);
      }
    }
  };

  const validateTaxInput = () => {
    if (taxUnit === '%') {
      if (Number(tax) > 100 || Number(tax < 0)) {
        setTax('0');
        setTaxError('Tax percentage cannot be lower than 0 or higher than 100');
        setTimeout(() => {
          setTaxError('');
        }, 4000);
      }
    }
  };

  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3>{header}</h3>
            <p>{subText}</p>
          </HeaderWrap>
          <ModalMain borderTp="none" margin="none" padding="none">
            <form onSubmit={handleSet}>
              <InputWrap>
                <h3
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '161616',
                    margin: '0 0 10px 0',
                  }}
                >
                  Discount
                </h3>
                <Select
                  options={currency}
                  getOptionLabel={p => p.name}
                  styles={customStyles}
                  value={currency.filter(p => p.name === discountUnit)}
                  onChange={(e: any) =>
                    setDiscountUnit((e as HTMLSelectElement).name)
                  }
                />
              </InputWrap>
              <TicketInput style={{ width: '100%' }} top="1rem">
                <div style={{ width: '100%' }}>
                  <p>Discount offered</p>
                  <input
                    placeholder="0"
                    type="number"
                    onKeyPress={e => numOnly(e)}
                    onChange={e => setDiscount(Number(e.target.value))}
                    value={discount}
                    onBlur={() => validateDiscountInput()}
                  />
                </div>
              </TicketInput>
              {discountError !== '' ? (
                <p
                  style={{
                    fontSize: '12px',
                    color: '#c82b32',
                  }}
                >
                  {discountError}
                </p>
              ) : null}
              <InputWrap>
                <h3
                  style={{
                    fontSize: '16px',
                    fontWeight: '500',
                    color: '161616',
                    margin: '40px 0 10px 0',
                  }}
                >
                  Taxes
                </h3>
                <Select
                  options={currency}
                  getOptionLabel={p => p.name}
                  styles={customStyles}
                  value={currency.filter(p => p.name === taxUnit)}
                  onChange={(e: any) =>
                    setTaxUnit((e as HTMLSelectElement).name)
                  }
                />
              </InputWrap>
              <TicketInput style={{ width: '100%' }} top="1rem">
                <div style={{ width: '100%' }}>
                  <p>Tax collected </p>
                  <input
                    placeholder="0"
                    type="number"
                    onChange={e => setTax(Number(e.target.value))}
                    value={tax}
                    onKeyPress={e => numOnly(e)}
                    onBlur={() => validateTaxInput()}
                  />
                </div>
              </TicketInput>
              {taxError !== '' ? (
                <p
                  style={{
                    fontSize: '12px',
                    color: '#c82b32',
                  }}
                >
                  {taxError}
                </p>
              ) : null}
              <TicketInput style={{ width: '100%' }} top="2rem">
                <div style={{ width: '100%' }}>
                  <p>Shipping(&#8358;)</p>
                  <input
                    type="number"
                    placeholder="0"
                    defaultValue={shipping}
                    onKeyPress={e => numOnly(e)}
                    onChange={e => setShipping(Number(e.target.value))}
                  />
                </div>
              </TicketInput>
              <BigBtn>
                <button type="submit">Save Optional Cost</button>
              </BigBtn>
            </form>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>
    </>
  );
};

export default OptionalCost;

const currency = [
  {
    name: '%',
  },
  {
    name: 'NGN',
  },
];
