import InputElement from 'app/components/InputElement';
import SubmitButton from 'app/components/SubmitButton';
import axios from 'axios';
import {
  AuthHeader,
  FormWidget,
  MainWrapper,
  MerchroLogo,
} from './../Login/styles';
import { useState } from 'react';
import { HeadText } from './../SignUp/styles';
import { BeatLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import { FloatLogOption } from '../SignUp/styles';
import { Link } from 'react-router-dom';

const ChangePwd = () => {
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [clientErr, setClientErr] = useState('');
  const [onProcess, setOnProcess] = useState(false);
  const [alert, setAlert] = useState('');

  const { email } = useParams();

  const userParams: any = {
    password: newPassword,
    token: verificationCode,
    email: email,
  };

  const navigate = useNavigate();

  const submitHandler = e => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      setOnProcess(true);
      axios
        .post(
          process.env.REACT_APP_BASE_ENDPOINT + '/auth/reset-password',
          userParams,
        )
        .then(res => {
          if (res.status === 200) {
            setOnProcess(false);
            setAlert(
              'Password changed successfully, you will be redirected to login.',
            );
            setTimeout(() => {
              navigate('/login');
            }, 4000);
          }
        })
        .catch(err => {
          setClientErr(err.response.data.message);
          setTimeout(() => {
            setClientErr('');
          }, 4000);
          setOnProcess(false);
        });
    } else {
      setClientErr('Password must match!!!');
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };
  return (
    <>
      <MainWrapper>
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/sign-up">Sign Up</Link>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <form className="my-5 mx-4 pt-5" onSubmit={submitHandler}>
            <div className="mb-5 ml-1">
              <HeadText>Reset Password</HeadText>
              {clientErr && (
                <p className="text-sm text-red-500 font-medium ml-1 my-1">
                  {clientErr}
                </p>
              )}
              {alert !== '' ? (
                <p className="text-sm text-green-500 font-medium ml-1 my-1">
                  {alert}{' '}
                </p>
              ) : null}
            </div>
            <p className="my-4 ml-1 text-center text-lg text-gray-500">
              Enter the Verification code sent to the email you entered and
              reset your password.
            </p>
            <InputElement
              inputtype="number"
              defaultText="Verification Code"
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
            />
            <InputElement
              inputType="password"
              defaultText="New password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <InputElement
              inputType="password"
              defaultText="Confirm Password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />

            <SubmitButton
              text={onProcess ? <BeatLoader color="white" /> : 'Reset Password'}
              disabled={onProcess}
              style={{ cursor: onProcess ? 'not-allowed' : 'pointer' }}
            />
          </form>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

export default ChangePwd;
