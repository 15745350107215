import React, { useState, useEffect, createRef } from 'react';
import {
  MainWrapper,
  // Upload,
} from './style';
import { BreadcrumbArea } from './../../orderComp/mainContent/orderContent/styles';
import { PreferenceBlock } from '../../Preference/mainContent/preferenceContent/styles';
import {
  ButtonFlex,
  ButtonWrap,
  CustomButtonDark,
  CustomButtonInverse,
} from './../../storeComp/mainContent/sectionOne/styles';
import { Line, SearchPos } from 'app/components/transactions/style';
import {
  InvoiceInfo,
  ItemSection,
  MainFlex,
  PairSection,
  Avatar,
} from './singleStyle';
import { StatusWrap } from 'app/components/transactions/style';
import moment from 'moment';
import { handleStatusBg, handleColor } from './colorHandle';
import Preview from './preview';
import generatePDF from 'react-to-pdf';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';
import Store from 'utils/store';
import ProgressWarning from 'app/components/actual-components/Warning';
import { updateRequest } from 'utils/request';
import styled from 'styled-components';

const SingleInvoiceComp = () => {
  const [selected, setSelected] = useState<any>({});
  const [client, setClient] = useState<any>({});
  const [item, setItem] = useState<any>({});
  const [earlyPay, setEarlyPay] = useState<boolean>(false);
  const [funded, setFunded] = useState<boolean>(false);

  const ref: any = createRef();

  function sum(input) {
    if (toString.call(input) !== '[object Array]') return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }

  let newItem: any = [];

  if (item && item.length > 0) {
    for (var i = 0; i < item.length; i++) {
      newItem.push(item[i].amount * item[i].quantity);
    }
  }

  useEffect(() => {
    const fromStorage = Store.get('selectedInvoice') || {};

    if (fromStorage && Object.keys(fromStorage).length > 0) {
      setClient(fromStorage.client);
      setSelected(fromStorage);
      setItem(fromStorage.items);
      setFunded(fromStorage.fundInvoice);
    }
  }, []);

  const requestEarlyPay = () => {
    if (!funded) setEarlyPay(true);
  };

  const confirmEarlyPayRequest = () => {
    updateRequest(`invoices/${selected.id}`, { fundInvoice: true }).then(
      resp => {
        if (resp.fundInvoice) setFunded(true);
        setEarlyPay(false);
      },
    );
  };

  return (
    <>
      <MainWrapper width="100%">
        {earlyPay && (
          <ProgressWarning
            title="Get paid early for this invoice?"
            text={`${client.name} - ${selected.totalAmount}`}
            actionText={'Request Early Pay'}
            canceltext={'Cancel'}
            handleAction={() => confirmEarlyPayRequest()}
            closeFunc={() => setEarlyPay(false)}
          />
        )}
        <FragmentHeader
          showBack={true}
          pageName={client.name}
          backLink="/invoice"
        />
        <SearchPos position="fixed">
          <ButtonWrap mobileHide={false}>
            <CustomButtonInverse>
              <ButtonFlex
                cursor={funded ? 'not-allowed' : 'pointer'}
                opacity={funded ? '0.5' : '1'}
                onClick={requestEarlyPay}
              >
                Request Early Pay
              </ButtonFlex>
            </CustomButtonInverse>
          </ButtonWrap>
          <ButtonWrap mobileHide={false}>
            <CustomButtonDark>
              <ButtonFlex
                onClick={() => {
                  generatePDF(ref, { filename: `invoice-${selected.id}.pdf` });
                }}
              >
                View Invoice
              </ButtonFlex>
            </CustomButtonDark>
          </ButtonWrap>
        </SearchPos>
        <BreadcrumbArea>
          <PreferenceBlock>
            <MainFlex>
              <PairSection>
                <div>
                  <InvoiceInfo>
                    <Avatar>{client.name ? client.name[0] : ''}</Avatar>
                    <div>
                      <h3>{client.email}</h3>
                      <p>{selected.invoiceCode} </p>
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                        }}
                      >
                        <p>
                          <b>Sent:</b> {moment(selected.updatedAt).format('LL')}{' '}
                        </p>
                        <p>
                          <span>
                            <b>Due:</b> {moment(selected.dueDate).format('LL')}
                          </span>
                        </p>
                      </div>
                    </div>
                  </InvoiceInfo>
                  <Line />
                  <ItemSection>
                    <div>
                      <h3>Products/Services</h3>
                      <h3>Amount</h3>
                    </div>
                    {item && item.length > 0
                      ? item.map((item, index) => (
                          <div key={index}>
                            <p>{item.itemDetails}</p>
                            <p>
                              &#8358;{item.amount} X {item.quantity}
                            </p>
                          </div>
                        ))
                      : null}

                    <div>
                      <h4>Sub Total</h4>
                      <h4>&#8358;{sum(newItem)}</h4>
                    </div>

                    {selected.discount > 0 ? (
                      <div>
                        <span>Discount</span>
                        <span>&#8358;{selected.discount}</span>
                      </div>
                    ) : null}

                    {selected.tax > 0 ? (
                      <div>
                        <span>Tax</span>
                        <span>&#8358;{selected.tax}</span>
                      </div>
                    ) : null}

                    {selected.shipping > 0 ? (
                      <div>
                        <span>Shipping</span>
                        <span>&#8358;{selected.shipping}</span>
                      </div>
                    ) : null}
                    <div>
                      <h3>Total</h3>
                      <h3>&#8358;{selected.totalAmount}</h3>
                    </div>
                  </ItemSection>

                  {selected.invoiceNote ? (
                    <>
                      <Line />
                      <ItemSection>
                        <div>
                          <h3>Invoice Note</h3>
                        </div>
                        <div>
                          <p>{selected.invoiceNote}</p>
                        </div>
                      </ItemSection>
                    </>
                  ) : null}
                </div>

                {funded && (
                  <PendingFund>
                    Your funding request is being processed
                  </PendingFund>
                )}
              </PairSection>
              <PairSection>
                <div>
                  <ItemSection>
                    <div>
                      <h1>Payments</h1>
                      <StatusWrap
                        style={{
                          background: handleStatusBg(selected.status),
                          color: handleColor(selected.status),
                          width: '6rem',
                        }}
                      >
                        <div>
                          {selected.status
                            ? selected.status
                                .replace('_', ' ')
                                .replace(
                                  selected.status[0],
                                  selected.status[0].toUpperCase(),
                                )
                            : null}
                        </div>
                      </StatusWrap>
                    </div>
                    <p>No Data</p>
                    {/* <DataTable>
                                <div>
                                    <h3>Date</h3>
                                </div>
                                <div>
                                    <h3>Paid</h3>
                                </div>
                                <div>
                                    <h3>Pending</h3>
                                </div>
                            </DataTable>
                            <DataTable>
                                <div>
                                    <p>22/11/2022</p>
                                </div>
                                <div>
                                    <h3>&#8358;200</h3>
                                </div>
                                <div>
                                    <h3>&#8358;2000</h3>
                                </div>
                            </DataTable> */}
                  </ItemSection>
                </div>
              </PairSection>
            </MainFlex>
          </PreferenceBlock>
        </BreadcrumbArea>

        {selected && client ? (
          <Preview
            propRef={ref}
            clientName={client.name}
            email={client.email}
            address={client.address}
            state={client.state}
            dueDate={selected.dueDate}
            country={client.country}
            shipping={selected.shipping}
            discount={selected.discount}
            phone={client.phoneNumber}
            tax={selected.tax}
            items={selected.items}
            taxPercent={0}
            discountPercent={0}
            fulfilmentLink={selected.paymentLink}
            grandTotal={selected.totalAmount}
            invoiceCode={selected.id}
            refNo={selected.id}
            invoiceNote={selected.invoiceNote}
          />
        ) : null}
      </MainWrapper>
    </>
  );
};

export default SingleInvoiceComp;

const PendingFund = styled.h1`
  background: #8975f334;
  display: flex;
  padding: 15px;
  justify-content: center;
  border-radius: 10px;
  color: #00000090;
`;
