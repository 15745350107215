import React, { useEffect, useState, useMemo } from 'react';
import {
  ContractCentered,
  ContractMainWrap,
  DateCard,
  SignCard,
  SignSection,
} from './style';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const SplitContractSheet = ({
  splitCollaborators,
  selectedCollaborator,
  pdf,
  type,
}) => {
  const [numPages, setNumPages] = useState<number>();
  const [showCount, setShowCount] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let options = {
    cMapUrl: pdf,
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const renderPdf = useMemo(() => {
    return (
      <Document
        file={{ url: pdf }}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {Array.apply(null, Array(numPages))
          .map((x, i) => i + 1)
          .map(page => (
            <div
              style={{
                margin: '2rem 0 0 0',
              }}
            >
              <Page pageNumber={page} />
            </div>
          ))}
      </Document>
    );
  }, [numPages, options, pdf]);

  useEffect(() => {
    setShowCount(value => ++value);
  }, [pdf]);

  return (
    <>
      <ContractMainWrap>
        <h4
          style={{
            textAlign: 'center',
          }}
        >
          <b>
            {type === 'uploaded'
              ? 'Uploaded file'
              : 'Publishing split sheet (no sample)'}
          </b>
        </h4>
        <ContractCentered
          style={
            type === 'uploaded'
              ? {
                  background: 'transparent',
                }
              : {}
          }
        >
          {type !== 'uploaded' ? (
            <>
              <h3>Split Sheet</h3>
              <p>Terms and Conditions</p>
              <br />
              <hr />
              <br />
              <p>Dear {selectedCollaborator.email}</p>
              <br />
              <p>
                This is to confirm that we, the sole writers of the composition
                listed below ( the “Composition”), hereby agree between
                ourselves to the following writers’ divisions.
              </p>
              <br />
              <h3>Composition: SELF</h3>
              <table>
                <tr>
                  <th>Name</th>
                  <th>Role</th>
                  <th>Ownership(%)</th>
                  <th>PRO</th>
                </tr>
                {splitCollaborators && splitCollaborators.length > 0
                  ? splitCollaborators.map((item, index) => (
                      <tr key={index}>
                        <td>{item.email}</td>
                        <td>
                          {item.role && item.role.length > 0
                            ? item.role[0]
                            : ''}
                        </td>
                        <td>{item.percentage}</td>
                        <td>{item.pro ? item.pro : '...'}</td>
                      </tr>
                    ))
                  : null}
              </table>
              <br />
              <p>
                The writers hereby warrant, represent and agree that there are
                no samples, interpolations, replays, or other third party
                copyrighted material (individually and collectively, “Sample(s)”
                contained in the Composition. If a Sample should become the
                subject of a copyright claim in connection with the Composition
                and/or payment of monies attributable to the Composition, then
                we agree that our own shares in the copyright and/or monies
                attributable to the Composition shall not be reduced unless we
                are the individual party responsible for furnishing such
                Sample(s).
              </p>
              <br />
              <p>
                The undersigned acknowledge that they have read and understood
                this Split Sheet and agree to the terms herein. The undersigned
                further agree that this Split Sheet represents the entire
                agreement between the parties and supersedes all prior
                negotiations, agreements, and understandings, if any.
              </p>
              <SignSection>
                {splitCollaborators && splitCollaborators.length > 0
                  ? splitCollaborators.map((item, index) => (
                      <div key={index}>
                        <SignCard>
                          <hr />
                          <p>{item.email}</p>
                        </SignCard>
                        <DateCard>
                          <hr />
                          <p>Date Signed</p>
                        </DateCard>
                      </div>
                    ))
                  : null}
              </SignSection>
            </>
          ) : (
            <>{pdf && showCount === 1 ? <>{renderPdf}</> : null}</>
          )}
        </ContractCentered>
      </ContractMainWrap>
    </>
  );
};

export default SplitContractSheet;
