import React, { useState } from 'react';
import {
  DetailsModal,
  DetailsChild,
  FixedBottom,
  CloseIcon,
  MainPart,
} from './../../components/transactions/style';
import * as Icon from 'react-feather';
import { FundOverview } from './style';
import {
  SplitAvatar,
  SplitUsersWrap,
  ApproveBtn,
  AccountToolTip,
} from './style';
import CommaNumber from 'comma-number';
import { Header } from '../dashboard/mainContent/fundWallet/style';
import ApproveWarning from './approveWarning';
import axios from 'axios';
import uuid from 'react-uuid';
import { getSign } from 'utils/getCurrencySign';

interface PropsArgs {
  closeFunc: any;
  collaborators: any;
  mainData: any;
  balance: any;
  splitName: string;
  setSuccess: any;
  afterSuccess: any;
  transactions: any;
  currency: any;
}

const ApproveModal = ({
  closeFunc,
  collaborators,
  balance,
  splitName,
  mainData,
  setSuccess,
  afterSuccess,
  currency,
}: PropsArgs) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  // States

  const activeItem = useState(-1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState<any>([]);
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState('');
  const [clientErr, setClientErr] = useState('');

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const getPayload = () => {
    return {
      idempotentKey: uuid(),
      collaborators:
        selectedCollaborator.length > 0
          ? selectedCollaborator.map(c => c.id)
          : collaborators.map(c => c.id),
    };
  };

  const handleOpenWarning = selectedCollab => {
    let approvedTotalAmount = 0;
    const activeCollaboratorsList =
      selectedCollab.length > 0 ? selectedCollab : collaborators;
    activeCollaboratorsList.forEach(collab => {
      // TODO:
      // 1. Get the split balance currency
      // 2. Check individual collab currency
      // 3. Check the current exchange
      // 4. Use this to calculate whether the Split has enough balance to really fund the recipients.
      // NOTE:
      // Currently assuming that split currency and all recipient's currency are all the same.
      approvedTotalAmount += collab.amount;
    });
    if (balance >= approvedTotalAmount) {
      setShowModal(true);
    } else {
      setClientErr(
        `Not enough balance to disburse to recipient${
          selectedCollab.length === 0 || selectedCollab.length > 1 ? 's' : ''
        }. Please fund your split account`,
      );
      setTimeout(() => {
        setClientErr('');
      }, 4000);
    }
  };

  const handleApproveFunds = async () => {
    setOnProcess(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/${mainData.id}/approve`,
        getPayload(),
        headers,
      )
      .then(res => {
        setOnProcess(false);
        afterSuccess();
        setSuccess(true);
        setShowModal(false);
        closeFunc();
      })
      .catch(err => {
        setError(err.response.data.message);
        setOnProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const updateActiveCollaborator = collaborator => {
    const index = selectedCollaborator.findIndex(x => x.id === collaborator.id);
    if (index > -1) {
      const prevCollaborators = selectedCollaborator;
      prevCollaborators.splice(index, 1);
      setSelectedCollaborator(prevCollaborators);
    } else setSelectedCollaborator([...selectedCollaborator, collaborator]);
  };

  return (
    <>
      {showModal ? (
        <ApproveWarning
          selectedCollaborator={selectedCollaborator}
          handleAction={() => handleApproveFunds()}
          actionInProcess={onProcess}
          closeFunc={() => {
            setShowModal(false);
            setSelectedCollaborator([]);
          }}
          balance={balance}
          actionText={'Approve'}
          splitName={splitName}
          error={error}
          currency={currency}
        />
      ) : null}
      <DetailsModal onClick={closeFunc}>
        <DetailsChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <MainPart>
            <Header>Approve Splits</Header>
            <FundOverview>
              <div>
                <p>Funds available</p>
                <h3>
                  {' '}
                  {getSign(currency)}
                  {CommaNumber(balance)}
                </h3>
              </div>
              <div>
                <p>Recipients</p>
                <h3>
                  {collaborators && collaborators.length > 0
                    ? collaborators.length
                    : null}
                </h3>
              </div>
            </FundOverview>
            {clientErr ? (
              <p
                style={{
                  width: '20rem',
                  color: '#c82b32',
                  fontSize: '12px',
                  margin: '1rem 0',
                }}
              >
                {clientErr}
              </p>
            ) : null}
            {collaborators && collaborators.length > 0
              ? collaborators.map((item, index) => (
                  <SplitUsersWrap key={index}>
                    <SplitAvatar>
                      {item.name ? item.name[0] : item.email[0]}
                    </SplitAvatar>
                    <div
                      style={{
                        width: '58%',
                      }}
                    >
                      <h3>
                        {item.name ? item.name : item.email}{' '}
                        {loggedUser.user.id === item.id ? (
                          <span> . Owner</span>
                        ) : null}
                        <div
                          style={{
                            width: '5px',
                            height: '5px',
                            background: '#ababab',
                            borderRadius: '10rem',
                          }}
                        ></div>
                        <span>
                          {item.percentage ? `${item.percentage}%` : null}
                          {item.amount ? (
                            <span>
                              {' '}
                              {getSign(currency)}
                              {CommaNumber(item.amount)}
                            </span>
                          ) : null}
                        </span>
                      </h3>
                      <p>{item.email}</p>
                    </div>
                    {activeItem === index && item.transactionCount > 0 ? (
                      <AccountToolTip
                        style={{
                          left: '25%',
                          top: '2rem',
                          padding: '5px',
                        }}
                      >
                        <p
                          style={{
                            color: '#fff',
                          }}
                        >
                          This payment has
                          <br /> been approved
                          <br />{' '}
                          {item.transactionCount === 1
                            ? 'once'
                            : `${item.transactionCount} times`}
                        </p>
                      </AccountToolTip>
                    ) : null}
                    <input
                      type="checkbox"
                      name="selectedCollaborator"
                      onChange={() => updateActiveCollaborator(item)}
                      checked={
                        selectedCollaborator.find(x => x.id === item.id) > -1
                      }
                    />
                  </SplitUsersWrap>
                ))
              : null}
          </MainPart>
          <FixedBottom>
            <ApproveBtn
              style={{
                width: '100%',
                padding: '15px',
              }}
              onClick={() => handleOpenWarning(selectedCollaborator)}
            >
              Approve All
            </ApproveBtn>
          </FixedBottom>
        </DetailsChild>
      </DetailsModal>
    </>
  );
};

export default ApproveModal;
