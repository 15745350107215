import styled from 'styled-components';

export const EmptyWrapper = styled.div`
  width: 100%;
  display: Flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 728px) {
    margin: 7rem 0 0 0;
  }
`;

export const MainWrapper = styled.div`
  margin: 3rem 0;
  text-align: center;
  display: Flex;
  flex-direction: column;
  align-items: center;
`;

export const MoonImage = styled.img`
  width: 10rem;
`;

export const TextWrap = styled.div`
  text-align: Center;
  margin: 1rem 0 0 0;
`;

export const BlueText = styled.span`
  color: #007aff;
  letter-spacing: -0.04em;
`;
