import React from 'react';
import {
  DetailsModal,
  DetailsChild,
  CloseIcon,
  MainPart,
  UserInfo,
  Avatar,
  MainSection,
} from './../../components/transactions/style';
import * as Icon from 'react-feather';
import { ModalDataTable } from './style';
import moment from 'moment';
import CommaNumber from 'comma-number';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { StatusWrap } from 'app/components/transactions/style';
import {
  handleStatusBg,
  handleColor,
} from '../invoice/mainInvoice/colorHandle';

interface PropsArgs {
  closeFunc: any;
  props: any;
  openFunc: any;
}

const TransactionDetails = ({ props, closeFunc, openFunc }: PropsArgs) => {
  return (
    <>
      <DetailsModal onClick={closeFunc}>
        <DetailsChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <MainPart>
            <UserInfo>
              <Avatar
                style={{
                  background: '#72CEEA',
                  color: '#fff',
                }}
              >
                {props.name ? props.name[0] : ''}
              </Avatar>
              <h3>{props.name ? props.name : ''}</h3>
              <h3>&#8358;{CommaNumber(props.totalAmount)}</h3>
            </UserInfo>
            <MainSection>
              <ModalDataTable>
                <section
                  style={{
                    width: '25%',
                  }}
                >
                  <h3>Date</h3>
                </section>
                <section
                  style={{
                    width: '25%',
                  }}
                >
                  <h3>%/&#8358;</h3>
                </section>
                <section
                  style={{
                    width: '25%',
                  }}
                >
                  <h3>Received</h3>
                </section>
                <section
                  style={{
                    width: '4rem',
                  }}
                >
                  <h3>Status</h3>
                </section>
              </ModalDataTable>
              {props && props.trxRecords.length > 0 ? (
                props.trxRecords.map((item, index) => (
                  <ModalDataTable
                    key={index}
                    onClick={() => openFunc(item, index)}
                  >
                    <section
                      style={{
                        width: '25%',
                      }}
                    >
                      <p>{moment(item.updatedAt).format('L')}</p>
                    </section>
                    <section
                      style={{
                        width: '25%',
                      }}
                    >
                      <p>
                        {props.splitData.percentage
                          ? props.splitData.percentage
                          : props.splitData.amount}
                      </p>
                    </section>
                    <section
                      style={{
                        width: '25%',
                      }}
                    >
                      <p>&#8358;{CommaNumber(item.amount)}</p>
                    </section>
                    {'status' in item ? (
                      <StatusWrap
                        style={{
                          width: '4rem',
                          top: '-1rem',
                          margin: 0,
                        }}
                      >
                        <div
                          style={{
                            background: handleStatusBg(item.status),
                            color: handleColor(item.status),
                            top: '-1rem',
                            margin: 0,
                          }}
                        >
                          {item.status
                            .replace('_', ' ')
                            .replace(
                              item.status[0],
                              item.status[0].toUpperCase(),
                            )}
                        </div>
                      </StatusWrap>
                    ) : (
                      <StatusWrap></StatusWrap>
                    )}
                  </ModalDataTable>
                ))
              ) : (
                <p>No data available</p>
              )}
            </MainSection>
          </MainPart>
          <CenteredAnimeWrap
            style={{
              position: 'absolute',
              bottom: '1rem',
              left: '50%',
              transform: 'translate(-50%, -0)',
            }}
          >
            <StatusWrap
              style={{
                background: handleStatusBg('inactive'),
                color: handleColor('inactive'),
                width: '10rem',
                padding: '0.6rem 1rem',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              <div>
                <a href="mailto:hello@trymage.com">Report issues</a>
              </div>
            </StatusWrap>
          </CenteredAnimeWrap>
        </DetailsChild>
      </DetailsModal>
    </>
  );
};

export default TransactionDetails;
