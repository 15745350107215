import Benefit from 'app/components/Benefits/interface';
import { Card } from './interface';

// Action
export const setBenefits = (
  benefits: Benefit[],
): { type: 'SET_BENEFITS'; payload: Benefit[] } => {
  return {
    type: 'SET_BENEFITS',
    payload: benefits,
  };
};

export const setSelectedBenefit = (selectedBenefit: Benefit) => {
  return {
    type: 'SET_SELECTED_BENEFIT',
    payload: selectedBenefit,
  } as const;
};

export const SaveMastercard = (mastercard: Card) => {
  return {
    type: 'MASTERCARD',
    payload: mastercard,
  } as const;
};

export const SaveVisa = (visacard: Card) => {
  return {
    type: 'VISA',
    payload: visacard,
  } as const;
};
