import styled from 'styled-components';

export const MainWidget = styled.div`
  margin: 2rem 0 0 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media (max-width: 1028px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const UserCard = styled.div`
  margin: 0 0 0.5rem 0;
  padding: 2rem 5%;
  border: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
`;

export const FlexArea = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
  margin: 0 0 1rem 0;
`;
export const UserImage = styled.img`
  width: 4rem;
  height: 4rem;
  onject-fit: cover;
`;

export const AvatarWrap = styled.section`
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: Center;
  border-radius: 10rem;
  border: 1px solid #f2f2f2;

  > h4 {
    font-size: 1rem;
    text-transform: uppercase;
  }
`;
export const UserInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: Center;

  @media (max-width: 1028px) {
    width: 70%;
  }

  > div {
    margin: 0 0 0 5%;
    width: 80%;
    width: auto;
    display: inline;
    margin: 1rem 0 0 0;

    > h4 {
      font-size: 0.9rem;
      font-weight: 400;
      color: #191919;
    }
  }

  > div > p {
    font-size: 0.7rem;
    color: #757575;
  }
`;

export const ContactIcons = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1028px) {
    width: 40%;
  }

  > div {
    width: 3rem;
    height: 3rem;
    border-radius: 10rem;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    color: #757575;
  }
`;

export const InfoPart = styled.div`
  width: 100%;

  h3 {
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }
  p {
    font-size: 0.9rem;
    color: #757575;
  }

  > div > div {
    width: 50%;
  }
`;

export const InfoIcons = styled.div`
  width: 90%;
  display: flex;
  align-items: Center;
  gap: 20px;
  margin: 0 0 1rem 0;

  @media (max-width: 1028px) {
    width: 100%;
  }

  > div {
    width: 3rem;
    height: 3rem;
    border-radius: 10rem;
    margin: 0 0 0 2%;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    color: #757575;
  }
`;

export const ProductWrap = styled.div`
  margin: 2rem 0 0 0;
`;

export const ProductInfo = styled.div`
  width: 70%;
  display: flex;
  align-items: center;

  > div {
    margin: 0 0 0 2%;
    color: #757575;

    p {
      font-size: 14px;
    }
  }
`;

export const ProductImg = styled.img`
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border-radius: 5px;
`;
