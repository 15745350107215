import React from 'react';
import MainContent from 'app/components/paymentLink/single';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const SinglePage = () => {
  return (
    <>
      <MainWrapper style={{ background: '#fff' }}>
        <MainContent />
      </MainWrapper>
    </>
  );
};

export default SinglePage;
