import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
  InputWrap,
} from './../../transactions/style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import Select from 'react-select';
import { BeatLoader } from 'react-spinners';
import Alert from '../../dashboard/mainContent/fundWallet/alert';
import axios from 'axios';
import { CustomStyles } from 'utils/selectCustomStyle';

interface PropsArgs {
  closeFunc: any;
  header: any;
  subText?: any;
  props?: any;
  dueDate: any;
  dateDiff?: any;
}

const Reminder = ({
  closeFunc,
  header,
  subText,
  dateDiff,
  props,
}: PropsArgs) => {
  const [beforeDueDate, setBeforeDueDate] = useState<any>('');
  const [onDueDate, setOnDueDate] = useState('');
  const [afterDueDate, setAfterDueDate] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const apiPayload: any = {
    beforeDueDate: beforeDueDate,
    onDueDate: onDueDate,
    afterDueDate: afterDueDate,
  };

  const option = {
    headers: {
      'Content-type': 'Application/json',
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
    body: JSON.stringify(apiPayload),
  };

  const handleSendReminder = async () => {
    setOnProcess(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASE_ENDPOINT}/invoices/reminder/${props}`,
        option,
      )
      .then(res => {
        setOnProcess(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          closeFunc();
        }, 4000);
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const closeModal = () => {
    setError('');
    setSuccess(false);
  };

  // Get Before Date Condition
  const [modifiedBeforeEnt, setModifiedBeforeEnt] = useState<any>(before);

  useEffect(() => {
    let mockData: any = [];

    if (dateDiff && dateDiff >= 0) {
      for (let i = 0; i < before.length; i++) {
        if (before[i].value <= dateDiff) {
          mockData.push(before[i]);
        }
      }
      setModifiedBeforeEnt(mockData);
    }
  }, [dateDiff]);

  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3>{header}</h3>
            <p>{subText}</p>
          </HeaderWrap>
          <ModalMain borderTp="none" margin="none" padding="none">
            <InputWrap>
              <label>Before due date</label>
              <Select
                options={modifiedBeforeEnt}
                getOptionLabel={p => p.name}
                styles={CustomStyles}
                onChange={(e: any) =>
                  setBeforeDueDate((e as HTMLSelectElement).name)
                }
              />
            </InputWrap>
            <InputWrap>
              <label>On due date</label>
              <Select
                options={onDate}
                getOptionLabel={p => p.name}
                styles={CustomStyles}
                onChange={(e: any) =>
                  setOnDueDate((e as HTMLSelectElement).name)
                }
              />
            </InputWrap>
            <InputWrap>
              <label>After due date</label>
              <Select
                options={after}
                getOptionLabel={p => p.name}
                styles={CustomStyles}
                onChange={(e: any) =>
                  setAfterDueDate((e as HTMLSelectElement).name)
                }
              />
            </InputWrap>
            <BigBtn onClick={() => handleSendReminder()}>
              <button>
                {onProcess ? <BeatLoader color="white" /> : 'Save Reminder'}
              </button>
            </BigBtn>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>

      {error !== '' ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occurred!"
          miniMessage={error}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Reminder set successfully!!!"
          miniMessage={
            'You have succesfully set reminder for this invoice. Your client would be notified on set time. '
          }
        />
      ) : null}
    </>
  );
};

export default Reminder;

const before = [
  { name: '14 days before', value: 14 },
  { name: '7 days before', value: 7 },
  { name: '3 days before', value: 3 },
];

const onDate = [
  {
    name: 'On due date',
  },
];

const after = [
  { name: '3 days after' },
  { name: '7 days after' },
  { name: '14 days after' },
];
