import styled from 'styled-components';

interface Props {
  mobileShow: string;
}

export const MainSideWrapper = styled.div<Props>`
  width: 14%;
  height: 100vh;
  background: #191919;
  color: #fff;
  font-weight: 600;
  font-family: 'Satoshi', sans-serif;
  position: fixed;
  top: 5.5rem;
  left: 1%;
  padding: 0 0.5rem;
  border-radius: 10px;

  @media (max-width: 728px) {
    display: ${p => p.mobileShow};
    width: 30%;
    top: 0;
    background: #191919;
    border-radius: 0;
    left: 0;
    color: #fff;
  }
`;

export const FlexedApart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0% 0;

  @media (max-width: 728px) {
    margin-top: 30px;
  }
`;

export const PaddedArea = styled.div`
  padding: 0;
`;

export const UserImage = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserAvatar = styled.div`
  width: 3rem;
  height: 3rem;
  /* margin: 0 1% 0 3%; */
  border-radius: 50%;
  border: 1px solid #191919;
  background: #fff;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    position: absolute;
    margin-top: -37px;
    margin-right: -37px;
    width: 20px;
    height: 20px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    border-radius: 10rem;
  }
`;

export const UserTexts = styled.div`
  width: calc(100% - 3rem);
  padding: 0 0 0 3px;

  > h4 {
    font-size: 12px;
  }

  > p {
    font-size: 9px;
    line-height: 11px;
    color: #ababab;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
export const SideNavWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 3.5rem;

  @media (max-width: 728px) {
    margin: 8rem 0 0 0;
    width: 70%;
  }
`;

export const SideNavItemCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: Center;
  margin: 1rem 0;
  padding: 0.5rem 6% 0.5rem 15%;
`;

export const SideItemIcon = styled.i`
  /* width: 10%; */
  font-size: 16px;
`;

export const SideItemText = styled.h4`
  /* width: 80%; */
  margin-left: 10px;
  font-size: 13px;
  display: Flex;
  align-items: Center;
`;

export const SideItemDesc = styled.h4`
  margin-left: 10px;
  font-size: 13px;
  display: Flex;
  align-items: Center;
  color: #bdbdbd;
  padding: 15px 0 0 10%;
`;

export const SubItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
  margin-top: -0.5rem;
  margin-left: 2rem;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #fff;

  p {
    font-size: 13px;
    padding: 0.2rem 0.5rem;
    font-weight: 400;
  }

  @media (max-width: 728px) {
    color: #fff;
  }
`;

export const CenterIconWrap = styled.div`
  position: absolute;
  left: 50%;
  bottom: 12%;

  > div {
    width: 3rem;
    height: 3rem;
    border-radius: 10rem;
    border: 2px solid #fff;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: -50%;
  }

  i {
    margin: 0.3rem 0 0 0;
  }
`;

export const MerchroLogo = styled.img`
  height: 2rem;
  display: block;
  margin: 1rem auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 7%;
`;

export const SignOut = styled.p`
  font-size: 0.9rem;
  color: red;
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
  font-weight: 200;
  display: none;

  @media (max-width: 728px) {
    display: block;
  }
`;

export const TopDiv = styled.div`
  display: none;
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  padding: 0 10%;

  @media (max-width: 728px) {
    display: block;
  }
`;
