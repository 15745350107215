import styled from 'styled-components';

export const ProductWidget = styled.div`
  margin: 2rem 0;
  width: 100%;

  @media (max-width: 728px) {
    margin: 8rem 0 2rem 0;
  }
`;

export const ProductCard = styled.div`
  width: 100%;
  padding: 2% 1%;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  @media (max-width: 1028px) {
    padding: 4% 0%;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  width: 70%;

  p {
    font-size: 0.8rem;
    margin: 0.5rem 0 0 0;
    color: #757575;
  }

  > div {
    margin: 0 0 0 3%;
  }
`;

export const ProductImg = styled.img`
  width: 4rem;
  height: 4rem;
  border-radius: 0.2rem;
  object-fit: Cover;
`;

export const EditButton = styled.div`
  padding: 5% 5%;
  width: 80%;
  background: none;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 0.8rem;
  margin: 1rem 0 0 0;
  color: #757575;

  > div {
    margin: 0 0 0 0.9rem;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  position: Relative;
  margin: 0 0 2rem 0;

  @media (max-width: 1028px) {
    margin: 2rem 0;
  }
`;

export const RecentWrap = styled.div`
  position: relative;
  margin-top: 1rem;
`;

export const RecentCard = styled.div`
  padding: 0%;
  margin: 0 1rem;
  box-shadow: 0px 0px 3px #dadada;

  h4 {
    color: #000000;
    font-size: 0.8rem;
    padding: 2% 5%;
    margin: 1rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p {
    font-size: 0.7rem;
    padding: 2% 5%;
    color: #757575;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 7rem;
  object-fit: cover;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 4rem 0 0 0;

  @media (max-width: 728px) {
    width: 60%;
    margin: 3rem auto 0 auto;
  }
`;

export const PaginationCont = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;

  > P {
    font-weight: 400;
    cursor: pointer;

    :hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 1028px) {
    width: 90%;
  }
`;

export const PaginationTextWrap = styled.div`
  width: 40%;
  display: flex;
  align-items: Center;
  justify-content: space-between;
`;
