import styled from 'styled-components';

export const CenteredAnimeWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 0 0;

  @media (max-width: 728px) {
    margin: 7rem 0 0 0;
  }
`;
