import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  AlignedFlex,
  BasicCard,
  CloseBtn,
  OptionImg,
} from './../dashboard/mainContent/fundWallet/style';
import * as Icon from 'react-feather';
import { Link } from 'react-router-dom';

const DollarTransferOption = ({ closeFunc }) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header
            style={{
              justifyContent: 'center',
            }}
          >
            <p>Send a payment</p>
          </Header>
          <p
            style={{
              width: '20rem',
              fontSize: '14px',
              fontWeight: 500,
              color: '#161616',
            }}
          >
            Send funds to
          </p>
          <div>
            <Link to="/dom-transfer">
              <BasicCard>
                <AlignedFlex>
                  <OptionImg>
                    <p>&#8358;</p>
                  </OptionImg>
                  <div
                    style={{
                      width: '80%',
                    }}
                  >
                    <p>DOM</p>
                    <span>Transfer funds to a Domiciliary account</span>
                  </div>
                </AlignedFlex>
              </BasicCard>
            </Link>
            <Link to="#">
              <BasicCard>
                <AlignedFlex>
                  <OptionImg>
                    <i>
                      <Icon.Zap size={16} />
                    </i>
                  </OptionImg>
                  <div
                    style={{
                      width: '80%',
                    }}
                  >
                    <p>Wire Transfer</p>
                    <span>Transfer funds through wire transfer</span>
                  </div>
                </AlignedFlex>
              </BasicCard>
            </Link>
          </div>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default DollarTransferOption;
