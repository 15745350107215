import { Card } from './interface';
import { SaveVisa } from './benefitAction';

export interface VisacardState {
  visa: Card;
}

const initialState: VisacardState = {
  visa: {
    id: '',
    name: '',
    card_number: '',
    masked_pan: '',
    expiry: '',
    cvv: '',
    status: '',
    type: '',
    issuer: '',
    currency: '',
    balance: '',
    balance_updated_at: '',
    address: {
      street: '',
      city: '',
      state: '',
      postal_code: '',
      country: '',
    },
    created_at: '',
    updated_at: '',
  },
};

type ActionTypes = ReturnType<typeof SaveVisa>;

const visacardReducer = (
  state: VisacardState = initialState,
  action: ActionTypes,
): VisacardState => {
  switch (action.type) {
    case 'VISA':
      return { ...state, visa: action.payload };
    default:
      return state;
  }
};

export default visacardReducer;
