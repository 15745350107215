export function handleBg(state: string) {
  if (state === 'Fulfilled'.toLowerCase()) {
    return '#327d57';
  } else if (state === 'Enroute'.toLowerCase()) {
    return '#FBC011';
  } else if (state === 'To pickup'.toLowerCase()) {
    return '#FF6626';
  } else if (state === 'Pre-order'.toLowerCase()) {
    return '#A5D0FF';
  } else if (state === 'Pending'.toLowerCase()) {
    return '#D0F4FF';
  } else if (state === 'Processing'.toLowerCase()) {
    return '#D0F4FF';
  } else if (state === 'Completed'.toLowerCase()) {
    return '#33DF34';
  } else if (state === 'Music'.toLowerCase()) {
    return '#A5D0FF';
  } else if (state === 'General'.toLowerCase()) {
    return '#FF6626';
  } else if (state === 'Completed'.toLowerCase()) {
    return '#33DF34';
  } else if (
    state === 'Unfulfilled'.toLowerCase() ||
    state === 'failed'.toLowerCase()
  ) {
    return '#c82b28';
  }
}
