import axios from 'axios';

const uninterceptedAxiosInstance = axios.create();

let mockArray: any = [];
let imageArray: any = [];
const fileLimit: any = process.env.REACT_APP_IMAGE_SIZE_LIMIT || 10000000;

interface PropsArgs {
  files: any;
  setImages?: any;
  setError: any;
  setReturnedImages?: any;
  setSecondReturnedImages?: any;
  setThirdReturnedImages?: any;
  uploadCount?: number;
  extraFunc?: any;
  returnedData?: Object;
  setUploadInProcess?: any;
}
export const handleFileUpload = async ({
  files,
  setImages,
  setReturnedImages,
  setSecondReturnedImages,
  setThirdReturnedImages,
  setError,
  uploadCount,
  extraFunc,
  returnedData,
  setUploadInProcess,
}: PropsArgs) => {
  const formData = new FormData();

  for (let i = 0; i < files.length; i++) {
    if (imageArray.length <= 0) {
      if (files && files[0].size <= fileLimit) {
        mockArray.push(files[0]);

        if (setImages) {
          setImages(mockArray);
        }

        formData.append('file', files[i]);
        formData.append(
          'upload_preset',
          `${process.env.REACT_APP_UPLOAD_PRESET}`,
        );

        if (setUploadInProcess) {
          setUploadInProcess(true);
        }

        uninterceptedAxiosInstance
          .post(
            `${process.env.REACT_APP_CLOUDINARY_URL}/${process.env.REACT_APP_CLOUDINARY_USERNAME}/image/upload`,
            formData,
          )
          .then(res => {
            if (setReturnedImages) {
              setReturnedImages(res.data.secure_url);
            }
            if (setUploadInProcess) {
              setUploadInProcess(false);
            }

            if (extraFunc) {
              extraFunc(res.data.secure_url);
            }
          })
          .catch(err => {
            if (err.response) {
              setError('Image upload failed');
              setTimeout(() => {
                setError('');
              }, 4000);
              setUploadInProcess(false);
            }
          });
      } else {
        setUploadInProcess(false);
        setError(`You can't upload images with size more than 10mb`);
        setTimeout(() => {
          setError('');
        }, 4000);
      }
    }
  }

  return;
};
