import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const PrivateRoute = () => {
  var login = localStorage.getItem('logged_user');

  return login ? <Outlet /> : <Navigate to="/login" />;
};

export const PreventToAuth = () => {
  var login = localStorage.getItem('logged_user');

  return login ? <Navigate to="/" /> : <Outlet />;
};

export const NotOnboarding = () => {
  var login = JSON.parse(localStorage.getItem('logged_user'));

  return login && 'creatorPage' in login.user ? (
    <Outlet />
  ) : (
    <Navigate to="/onboarding" />
  );
};
