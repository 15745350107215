import { useState, useEffect, useRef } from 'react';
import { MainWrapper } from './../invoice/mainInvoice/style';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import {
  GenericInput,
  Important,
  PreferenceBlock,
  SaveButtonWrap,
} from '../Preference/mainContent/preferenceContent/styles';
import {
  GenericTextarea,
  AddPhotoWrapper,
  AddPhotoBox,
  PreviewImg,
} from '../merch/addMerch/styles';
import { EventGrid, InputLabel, GenericBtn } from './style';
import Select from 'react-select';
import axios from 'axios';
import * as Icon from 'react-feather';
import PopUpModal from '../merch/popupModal';
import { BeatLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router';
import TicketTierSetting from './setTickets';
import { intervalOption } from './intervalOption';
import validator from 'validator/index';
import { usePlacesWidget } from 'react-google-autocomplete';
import { SearchPos } from '../transactions/style';
import { Editor } from '@tinymce/tinymce-react';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { handleFileUpload } from 'utils/imageUpload';

const CreateLink = () => {
  const navigate = useNavigate();
  const { selectedType } = useParams();
  const width = window.innerWidth;
  const [ticketTier, setTicketTier] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [images, setImages] = useState<any>([]);
  const [returnedImages, setReturnedImages] = useState<any>('');
  const [ticketName, setTicketName] = useState('');
  const [ticketQuantity, setTicketQuantity] = useState('');
  const [ticketPrice, setTicketPrice] = useState('');

  const store = JSON.parse(localStorage.getItem('store') || '{}');

  // toggle Ticket Tier
  const toggleTicketTier = () => {
    setTicketTier(!ticketTier);
  };

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setImages,
      setError,
      setReturnedImages,
    });
  };

  // Remove Image

  const handleRemoveImage = index => {
    images.splice(index, 1);
    setImages([...images]);
    if (returnedImages.length === images.length) {
      returnedImages.splice(index, 1);
      setReturnedImages([...returnedImages]);
    }
  };

  const handleClose = () => {
    setError('');
    setCreateError('');
    setResponse(false);
  };

  const [urlValid, setUrlValid] = useState('');

  const validate = value => {
    if (value.length > 0) {
      if (validator.isURL(value)) {
        setUrlValid('');
      } else {
        setUrlValid('Not a valid URL');
      }
    } else {
      setUrlValid('');
    }
  };

  // Handling Tier Inputs
  const [itemArray, setItemsArray] = useState<any>([]);

  const handleDelete = async index => {
    let mockArray: any = [];
    for (let i = 0; i < itemArray.length; i++) {
      if (i !== index) {
        mockArray.push(itemArray[i]);
      }
    }

    await setItemsArray(mockArray);
  };

  // Get Input
  let mockTickets: any = [...itemArray];

  const handleAddTicket = () => {
    mockTickets.push({
      ticketType: ticketName,
      ticketPrice: ticketPrice,
      ticketQuantity: ticketQuantity,
    });
    setItemsArray(mockTickets);
    toggleTicketTier();
  };

  // Main Operation and Event Creation.

  // const [paymentType, setPaymentType] = useState<string>('')
  const [pageName, setPageName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [pageRedirectUrl, setPageRedirectUrl] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [interval, setInterval] = useState<any>('');
  const [frequency, setFrequency] = useState<number>(0);
  const [location, setLocation] = useState('');
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [absorbFees, setAbsorbFees] = useState(true);

  const [onProcess, setOnProcess] = useState(false);
  const [response, setResponse] = useState(false);
  const [createError, setCreateError] = useState('');

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  // Google Map Places API

  const { ref }: any = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: place => {
      setLocation(place.formatted_address);
    },
  });

  // One time payment

  const oneTimePayload = {
    paymentType: selectedType,
    pageName: pageName,
    pageDescription: description,
    amount: amount,
    absorbFees: absorbFees,
  };

  const subscriptionPayload = {
    paymentType: selectedType,
    pageName: pageName,
    pageDescription: description,
    absorbFees: absorbFees,
    recurringPayment: {
      type: true,
      interval: interval,
      frequency: frequency,
    },
    amount: amount,
  };

  const eventPayload = {
    paymentType: selectedType,
    pageName: pageName,
    pageImage: returnedImages,
    pageDescription: description,
    absorbFees: absorbFees,
    eventPayment: {
      type: true,
      location: location,
      date: {
        from: from,
        to: to,
      },
      tickets: itemArray,
    },
  };

  const handleOnetimePayment = async () => {
    if (pageRedirectUrl && pageRedirectUrl !== '') {
      oneTimePayload['pageRedirectUrl'] = pageRedirectUrl;
    } else {
      oneTimePayload[
        'pageRedirectUrl'
      ] = `https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/response`;
    }

    await axios
      .post(
        process.env.REACT_APP_BASE_ENDPOINT + '/invoices/payment-link/',
        oneTimePayload,
        {
          headers: { Authorization },
        },
      )
      .then(res => {
        setOnProcess(false);
        setResponse(true);
        setTimeout(() => {
          setResponse(false);
          navigate('/payment-link');
        }, 4000);
      })
      .catch(err => {
        setOnProcess(false);
        setCreateError(err.response.data.message);
        setTimeout(() => {
          setCreateError('');
        }, 4000);
      });
  };

  const handleSubscriptionPayment = async () => {
    if (pageRedirectUrl && pageRedirectUrl !== '') {
      subscriptionPayload['pageRedirectUrl'] = pageRedirectUrl;
    } else {
      subscriptionPayload[
        'pageRedirectUrl'
      ] = `https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/response`;
    }

    await axios
      .post(
        process.env.REACT_APP_BASE_ENDPOINT + '/invoices/payment-link/',
        subscriptionPayload,
        {
          headers: { Authorization },
        },
      )
      .then(res => {
        setResponse(true);
        setOnProcess(false);
        setTimeout(() => {
          setResponse(false);
          navigate('/payment-link');
        }, 4000);
      })
      .catch(err => {
        setOnProcess(false);
        setCreateError(err.response.data.message);
        setTimeout(() => {
          setCreateError('');
        }, 4000);
      });
  };

  const [imageError, setImageError] = useState(false);
  const [ticketError, setTicketError] = useState(false);

  useEffect(() => {
    if (returnedImages && returnedImages !== '') {
      setImageError(false);
    }
  }, [returnedImages]);

  const handleEventPayment = async () => {
    if (pageRedirectUrl && pageRedirectUrl !== '') {
      eventPayload['pageRedirectUrl'] = pageRedirectUrl;
    } else {
      eventPayload[
        'pageRedirectUrl'
      ] = `https://${store.slug}${process.env.REACT_APP_PAGE_LINK}/response`;
    }

    if (returnedImages && returnedImages !== '') {
      if (itemArray && itemArray.length > 0) {
        if (
          from &&
          to &&
          location &&
          location !== '' &&
          from !== '' &&
          to !== ''
        ) {
          await axios
            .post(
              process.env.REACT_APP_BASE_ENDPOINT + '/invoices/payment-link/',
              eventPayload,
              {
                headers: { Authorization },
              },
            )
            .then(res => {
              setResponse(true);
              setOnProcess(false);
              setTimeout(() => {
                setResponse(false);
                navigate('/events');
              }, 4000);
            })
            .catch(err => {
              setOnProcess(false);
              setCreateError(err.response.data.message);
              setTimeout(() => {
                setCreateError('');
              }, 4000);
            });
        } else {
          setOnProcess(false);
          setCreateError('Please fill all inputs!');
          setTimeout(() => {
            setCreateError('');
          }, 4000);
        }
      } else {
        setOnProcess(false);
        setTicketError(true);
        setCreateError('Please add at least one ticket type!');
        setTimeout(() => {
          setCreateError('');
        }, 4000);
      }
    } else {
      setOnProcess(false);
      setImageError(true);
      setCreateError('Please add image for event!');
      setTimeout(() => {
        setCreateError('');
      }, 4000);
    }
  };

  const handlePaymentCreate = e => {
    e.preventDefault();
    if (urlValid === '') {
      setOnProcess(true);
      if (selectedType === 'one_time') {
        handleOnetimePayment();
      } else if (selectedType === 'subscription') {
        handleSubscriptionPayment();
      } else if (selectedType === 'event') {
        handleEventPayment();
      }
    } else {
      setError('Redirect URL is not valid!');
    }
  };

  const editorRef: any = useRef<any>(null);
  const log = (content: any) => {
    setDescription(`${content}`);
  };

  return (
    <>
      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      {response ? (
        <PopUpModal
          handleClose={handleClose}
          msg={
            selectedType === 'event'
              ? 'Your event has been created'
              : 'Your payment link has been created'
          }
          extraMsg="You can now share the link to your customers."
          icon="fas fa-check"
          iconColor="green"
        />
      ) : null}

      {createError !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={createError}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}

      <MainWrapper style={{ width: '100%' }} padding="3rem 5%">
        <FragmentHeader
          showBack={true}
          pageName={`Create ${
            selectedType === 'event' ? 'Event' : 'Payment link'
          }`}
          backLink="/events"
        />
        <BreadcrumbArea>
          <form onSubmit={handlePaymentCreate}>
            <PreferenceBlock>
              <EventGrid>
                <div>
                  {selectedType === 'event' ? (
                    <div>
                      <InputLabel>
                        Event Image<Important>*</Important>
                      </InputLabel>
                      <AddPhotoWrapper>
                        {images.length !== 4 ? (
                          <AddPhotoBox
                            style={
                              imageError
                                ? {
                                    border: '0.5px solid #c82b38',
                                  }
                                : { border: '0.5px solid #dadada' }
                            }
                          >
                            <label htmlFor="main-input">
                              <Icon.Image color="#757575" />
                              <p>
                                Drop your image here or <span>upload</span>
                              </p>
                            </label>

                            <input
                              id="main-input"
                              type="file"
                              multiple={false}
                              style={{ display: 'none' }}
                              onChange={e => {
                                handleFile(e.target.files);
                              }}
                            ></input>
                          </AddPhotoBox>
                        ) : null}
                        {images && images.length > 0 ? (
                          <>
                            {images.map((item, index) => (
                              <AddPhotoBox key={index}>
                                <div>
                                  <Icon.Trash2
                                    color="#fff"
                                    onClick={() => handleRemoveImage(item)}
                                  />
                                </div>
                                <PreviewImg src={URL.createObjectURL(item)} />
                              </AddPhotoBox>
                            ))}
                          </>
                        ) : null}
                      </AddPhotoWrapper>
                    </div>
                  ) : null}

                  <div>
                    <InputLabel>
                      {selectedType === 'event' ? 'Event Title' : 'Name'}
                      <Important>*</Important>
                    </InputLabel>
                    <GenericInput
                      placeholder={
                        selectedType === 'event' ? 'Event Name' : 'Name'
                      }
                      onChange={e => setPageName(e.target.value)}
                      required={true}
                    />
                  </div>

                  {selectedType === 'event' ? (
                    <div>
                      <InputLabel>Event Description</InputLabel>
                      <Editor
                        apiKey={
                          'kbj2x5a39gexwf21jwt5n43oc51bob2gldsx1qk9q3pys8jm'
                        }
                        onInit={(evt: any, editor: any) =>
                          (editorRef.current = editor)
                        }
                        initialValue="<p></p>"
                        init={{
                          height: 300,
                          menubar: false,
                          plugins: [
                            'advlist',
                            'autolink',
                            'lists',
                            'link',
                            'image',
                            'charmap',
                            'preview',
                            'anchor',
                            'searchreplace',
                            'visualblocks',
                            'code',
                            'fullscreen',
                            'insertdatetime',
                            'media',
                            'table',
                            'code',
                            'help',
                            'wordcount',
                          ],
                          toolbar:
                            'undo redo | blocks | ' +
                            'bold italic forecolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                        onEditorChange={log}
                      />
                    </div>
                  ) : null}

                  {selectedType !== 'event' ? (
                    <div>
                      <InputLabel>
                        {selectedType === 'event'
                          ? 'Event Description'
                          : 'Description'}
                        <Important>*</Important>
                      </InputLabel>
                      <GenericTextarea
                        placeholder="Description"
                        onChange={e => setDescription(e.target.value)}
                        required={true}
                      ></GenericTextarea>
                    </div>
                  ) : null}
                </div>

                <div>
                  {selectedType === 'event' ? (
                    <>
                      <div>
                        <InputLabel>
                          Event Starts<Important>*</Important>
                        </InputLabel>
                        <GenericInput
                          type="datetime-local"
                          onChange={e => setFrom(e.target.value)}
                          required={true}
                        />
                      </div>

                      <div>
                        <InputLabel>
                          Event Ends<Important>*</Important>
                        </InputLabel>
                        <GenericInput
                          type="datetime-local"
                          onChange={e => setTo(e.target.value)}
                          required={true}
                        />
                      </div>
                      <div>
                        <InputLabel>
                          Location<Important>*</Important>
                        </InputLabel>
                        <GenericInput
                          placeholder="Where"
                          onChange={e => setLocation(e.target.value)}
                          required={true}
                          ref={ref}
                        />
                      </div>

                      <div>
                        <InputLabel>
                          Ticket<Important>*</Important>
                        </InputLabel>

                        <GenericBtn
                          style={
                            ticketError
                              ? {
                                  border: '0.5px solid #c82b38',
                                }
                              : { border: '0.5px solid #dadada' }
                          }
                          onClick={() => toggleTicketTier()}
                        >
                          Add ticket
                          <i>
                            <Icon.ChevronRight size={20} />
                          </i>
                        </GenericBtn>

                        {itemArray && itemArray.length > 0
                          ? itemArray.map((item, index) => (
                              <div
                                key={index}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  margin: '1rem 0',
                                  gap: '40px',
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: '14px',
                                    color: '#757575',
                                  }}
                                >
                                  {item.ticketType} - {item.ticketPrice}
                                </p>
                                <Icon.Trash2
                                  size={15}
                                  color="#c82b32"
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => handleDelete(index)}
                                />
                              </div>
                            ))
                          : null}
                      </div>
                    </>
                  ) : null}

                  {selectedType === 'subscription' ? (
                    <>
                      <div>
                        <InputLabel>
                          Interval<Important>*</Important>
                        </InputLabel>
                        <Select
                          options={intervalOption}
                          getOptionLabel={p => p.name}
                          onChange={(e: any) =>
                            setInterval((e as HTMLSelectElement).value)
                          }
                        />
                      </div>

                      <div>
                        <InputLabel>
                          Frequency<Important>*</Important>
                        </InputLabel>
                        <GenericInput
                          type="number"
                          placeholder="0"
                          onChange={e => setFrequency(Number(e.target.value))}
                          required={true}
                        />
                      </div>
                    </>
                  ) : null}

                  {selectedType !== 'event' ? (
                    <div>
                      <InputLabel>
                        Amount<Important>*</Important>
                      </InputLabel>
                      <GenericInput
                        type="number"
                        placeholder="&#8358;"
                        onChange={e => setAmount(Number(e.target.value))}
                        required={true}
                      />
                    </div>
                  ) : null}

                  <div>
                    <InputLabel>Redirect to</InputLabel>
                    <GenericInput
                      type="text"
                      placeholder=""
                      onChange={e => {
                        setPageRedirectUrl(e.target.value);
                        validate(e.target.value);
                      }}
                    />
                    <p
                      style={{
                        color: '#c82b38',
                        fontSize: '13px',
                        margin: '0.5rem 0 0 0',
                      }}
                    >
                      {urlValid}
                    </p>
                  </div>
                  {selectedType !== 'event' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '1rem 0 0 0',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={absorbFees}
                        onChange={e => setAbsorbFees(e.target.checked)}
                      />
                      <label
                        style={{
                          margin: '0 0 0 0.5rem',
                          fontSize: '13px',
                          letterSpacing: '-0.01em',
                          color: '#757575',
                          width: '100%',
                        }}
                      >
                        Absorb fees: Deduct payment fees from my payment
                        revenue.
                      </label>
                    </div>
                  ) : null}
                </div>
              </EventGrid>
            </PreferenceBlock>
            {/* Save  */}
            {width < 728 ? (
              <SaveButtonWrap>
                <button type="submit" disabled={onProcess ? true : false}>
                  {onProcess ? <BeatLoader color="#fff" /> : 'Create link'}
                </button>
              </SaveButtonWrap>
            ) : null}

            {width > 728 ? (
              <SearchPos position="fixed">
                <button
                  style={{
                    margin: '0 4rem 0 0',
                    color: '#fff',
                    padding: '0.5rem 2rem',
                    background: '#191919',
                    borderRadius: '4px',
                    fontSize: '0.8rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  type="submit"
                  disabled={onProcess ? true : false}
                >
                  {onProcess ? <BeatLoader color="#fff" /> : 'Create link'}
                </button>
              </SearchPos>
            ) : null}
          </form>
        </BreadcrumbArea>
      </MainWrapper>

      {ticketTier ? (
        <TicketTierSetting
          absorbFees={absorbFees}
          setAbsorbFees={setAbsorbFees}
          closeFunc={toggleTicketTier}
          name={ticketName}
          ticketPrice={ticketPrice}
          ticketQuantity={ticketQuantity}
          setTicketName={setTicketName}
          setTicketPrice={setTicketPrice}
          setTicketQuantity={setTicketQuantity}
          setFunc={handleAddTicket}
        />
      ) : null}
    </>
  );
};

export default CreateLink;
