import { SearchArea } from 'app/components/orderComp/mainContent/sectionOne/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { titleCase } from 'utils/capitalizeFirst';
import formatCurrency from 'utils/formatCurrency';
import Store from 'utils/store';
const DealTable = (props: any) => {
  const [data, setData] = useState(props.data);
  const [searchValue, setSearchValue] = useState('');
  const [size, setSize] = useState(window.innerWidth);
  const [columnWidth, setColumnWidth] = useState([
    '30%',
    '18%',
    '18%',
    '18%',
    '16%',
  ]);
  const [titles, setTitles] = useState([
    'applicant',
    'loan type',
    'applied on',
    'seeking',
    'status',
  ]);
  const [fields, setFields] = useState([
    'name',
    'loanType',
    'createdAt',
    'amount',
    'status',
  ]);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setSize(window.innerWidth);
    if (size < 540) {
      setFields(['name', 'amount', 'status']);
      setTitles(['applicant', 'seeking', 'status']);
      setColumnWidth(['45%', '35%', '20%']);
    } else if (size < 730) {
      setFields(['name', 'amount', 'createdAt', 'status']);
      setTitles(['applicant', 'seeking', 'applied on', 'status']);
      setColumnWidth(['35%', '25%', '30', '20%']);
    } else {
      setFields(['name', 'loanType', 'createdAt', 'amount', 'status']);
      setTitles(['applicant', 'loan type', 'applied on', 'seeking', 'status']);
      setColumnWidth(['30%', '18%', '18%', '18%', '16%']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);
  const navigate = useNavigate();
  const handleSearch = e => {
    const newArray = props.data
      .filter(x => {
        let state = false;
        Object.keys(x).forEach(item => {
          if (
            x[item] &&
            x[item].toString().toLowerCase().indexOf(e.target.value) > -1
          ) {
            state = true;
          }
        });
        return state ? x : null;
      })
      .filter(y => y !== null);
    setData(newArray);
  };

  const openDeal = dealData => {
    Store.create('activeDeal', dealData);
    navigate(`/deals/dealCode=${dealData.code}`);
  };
  return (
    <>
      <Table>
        <FilterLayer>
          <SearchArea style={{ marginBottom: 20 }} mobWidth="50%">
            <i>
              <Icon.Search />
            </i>
            <input
              value={searchValue}
              type="text"
              placeholder="Search leads"
              onChange={e => handleSearch(e)}
            />
          </SearchArea>
          <button
            style={{
              margin: `${size < 732 ? '-4rem' : '0'} 1rem 0 0`,
              color: '#fff',
              padding: '0.75rem 1rem',
              background: '#191919',
              borderRadius: '4px',
              fontSize: '0.8rem',
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              right: -15,
            }}
            onClick={() => {
              setSearchValue('');
              setData(props.data);
            }}
          >
            <Icon.RefreshCw size={15} style={{ margin: `0 0.5rem 0 0` }} />
            Refresh Deals
          </button>
        </FilterLayer>
        {data.length > 0 ? (
          <>
            <TableHeader>
              <Row className="header-row">
                {titles.map((item, index) => {
                  return (
                    <Column style={{ width: columnWidth[index] }} key={index}>
                      {item.toUpperCase()}
                    </Column>
                  );
                })}
              </Row>
            </TableHeader>
            <TableContent>
              {data.length > 0
                ? data.map((deal, index) => {
                    return (
                      <Row className="item-row" onClick={() => openDeal(deal)}>
                        {fields.map((item, index) => {
                          return (
                            <Column
                              style={{ width: columnWidth[index] }}
                              key={index}
                              className="item-column"
                            >
                              {item === 'status' ? (
                                <span
                                  style={{
                                    background: '#fff',
                                    padding: '5px 25px 5px 10px',
                                    borderRadius: 2.5,
                                    position: 'relative',
                                  }}
                                >
                                  <span
                                    style={{
                                      height: size < 420 ? 10 : 6,
                                      width: size < 420 ? 10 : 6,
                                      background: `${
                                        deal[item] === 'declined'
                                          ? '#C82B32'
                                          : deal[item] === 'funded'
                                          ? '#34A853'
                                          : '#FFC327'
                                      }`,
                                      borderRadius: '50%',
                                      position: 'absolute',
                                      right: 10,
                                      top: 'calc(50% - 3px)',
                                    }}
                                  >
                                    &nbsp;
                                  </span>
                                  {size > 420 && titleCase(deal[item])}
                                </span>
                              ) : item === 'amount' ? (
                                formatCurrency(deal[item], 'NGN')
                              ) : item === 'createdAt' ? (
                                moment(deal[item]).format('MMM DD, YYYY')
                              ) : (
                                deal[item]
                              )}
                            </Column>
                          );
                        })}
                      </Row>
                    );
                  })
                : ''}
            </TableContent>
          </>
        ) : (
          <p style={{ marginTop: 50, textAlign: 'center' }}>
            No deals available at the moment, please refresh or check back later
          </p>
        )}
      </Table>
    </>
  );
};

export default DealTable;

const Table = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const TableHeader = styled.div`
  display: flex;
  min-width: 100%;
`;

const TableContent = styled.div`
  margin: 0 auto;
  display: flex;
  min-width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid whitesmoke;
  padding: 20px 10px;
  &.header-row {
    background: rgba(230, 233, 239, 0.3);
    font-size: 13px;
    border: 1px solid whitesmoke;
    padding: 10px;
  }
  &.item-row {
    :hover {
      cursor: pointer;
      background: whitesmoke;
      .item-column {
        :first-child {
          color: #007aff;
        }
      }
    }
  }
`;

const Column = styled.div`
  display: flex;
  width: auto;
  padding: 10px;
  margin: auto;
  font-size: 14px;
  text-align: left;
  overflow: ellipsis;
`;

const FilterLayer = styled.div`
  display: flex;
  flex-direction: row;
`;
