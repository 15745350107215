import NewMain from 'app/components/editProfile/newMain';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const StoreHome = () => {
  return (
    <>
      <MainWrapper>
        {/* <SideNav mobileShow="none" /> */}
        <NewMain />
      </MainWrapper>
    </>
  );
};

export default StoreHome;
