import React from 'react';
import AppHeader from 'app/components/storeComp/mainContent/header/header';
import { MainWrapper } from '../../components/storeComp/mainContent/styles';
import { BigConstraint, Card } from 'app/components/merch/singleMerch/style';
import { GreetingWrap } from 'app/components/dashboard/mainContent/style';
import { ActionBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import * as Icon from 'react-feather';
import { useNavigate } from 'react-router';

const Contact = () => {
  const navigate = useNavigate();
  const width = window.innerWidth;

  return (
    <>
      <MainWrapper
        style={{ width: '100%', background: '#fafafa' }}
        left="0"
        top="1.5rem"
      >
        <AppHeader showBack={true} pageName={`Contact`} />
        <BigConstraint>
          <GreetingWrap
            style={{
              padding: '2rem 0 5rem 0',
              display: width > 728 ? 'flex' : 'none',
              alignItems: 'center',
              gap: '10px',
              cursor: 'pointer',
            }}
            onClick={() => navigate(-1)}
          >
            <i>
              <Icon.ArrowLeft />
            </i>
            <h1>Contact Us</h1>
          </GreetingWrap>
          <Card
            style={{
              border: 'none',
              textAlign: 'center',
              padding: '1rem 5%',
              background: '#fdfdfd',
              boxShadow: 'none',
            }}
          >
            <img
              src="tele.png"
              alt="Telephone"
              style={{
                width: '18rem',
                display: 'block',
                margin: '2rem auto',
              }}
            />
            <h3
              style={{
                color: '#000',
                fontSize: '22px',
              }}
            >
              We're here to help.
            </h3>
            <p
              style={{
                color: '#161616',
                width: '100%',
                lineHeight: '25px',
                justifyContent: 'center',
                fontWeight: 400,
                fontSize: '16px',
              }}
            >
              Our support hours are 9am - 6pm WAT Monday through Friday.
              <br />
              For urgent requests, please email us at hello@trymage.com.
            </p>
            <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
              <a
                href="https://merchro-help.notion.site/Merchro-Manual-e68dff465b404309890aca7598c32fb5"
                target="_blank"
                rel="noreferrer"
              >
                <button
                  style={{
                    width: '9rem',
                    background: '#fff',
                    color: '#191919',
                    border: 'none',
                  }}
                >
                  {'FAQ'}
                </button>
              </a>
              <a
                href="mailto:hello@trymage.com"
                target="_blank"
                rel="noreferrer"
              >
                <button style={{ width: '9rem', margin: '2rem 0 0 0' }}>
                  Contact Us
                </button>
              </a>
            </ActionBtn>
          </Card>
        </BigConstraint>
      </MainWrapper>
    </>
  );
};

export default Contact;
