import React from 'react';
import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';
import CardList from 'app/components/card';

const CardPage = () => {
  return (
    <>
      <MainWrapper className="no-scrollbar">
        <SideNav mobileShow="none" />
        <CardList />
      </MainWrapper>
    </>
  );
};

export default CardPage;
