import React, { useState } from 'react';
import {
  BottomMusicPlayer,
  MusicInfo,
  MusicPlaybackWrap,
  MusicPlayerActionWrap,
  MusicProgress,
  MusicProgressWrap,
  MusicVolumeSection,
  PlayButton,
} from './style';
import * as IconSax from 'iconsax-react';
import * as Icon from 'react-feather';
import { formatTime } from 'utils/formatTime';

interface PropsArgs {
  audioRef: any;
  isPlaying: boolean;
  setIsPlaying?: any;
  togglePlayPause: any;
  currentSong: any;
  progressBarRef: any;
  timeProgress: number | string;
  duration: number | string;
  setTimeProgress: any;
  playAnimationRef: any;
  skipForward: any;
  skipBackward: any;
  volume: number;
  setVolume: any;
}

const PlaybackInterface = ({
  isPlaying,
  audioRef,
  togglePlayPause,
  currentSong,
  progressBarRef,
  timeProgress,
  playAnimationRef,
  setTimeProgress,
  duration,
  skipForward,
  skipBackward,
  volume,
  setVolume,
}: PropsArgs) => {
  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  const [inFull, setInFull] = useState(false);

  const toggleFullSceen = (): void => {
    setInFull(!inFull);
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const closePlayback = () => {
    localStorage.removeItem('asl');
    localStorage.removeItem('asi');
    localStorage.removeItem('spv');
  };

  return (
    <>
      <BottomMusicPlayer>
        <MusicInfo width="25%" textColor="#fff">
          <Icon.X
            color="#fff"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => closePlayback()}
          />
          <img
            src={currentSong.albumArt ? currentSong.albumArt : 'music-grey.png'}
            alt="Music Art"
          />
          <div>
            <h3>{currentSong && currentSong.title}</h3>
            <p>{currentSong && currentSong.artistName}</p>
          </div>
        </MusicInfo>
        <MusicPlayerActionWrap>
          <MusicPlaybackWrap>
            {/* <IconSax.Shuffle color="#fff" /> */}
            <Icon.SkipBack color="#fff" onClick={() => skipBackward()} />
            <PlayButton onClick={togglePlayPause}>
              {isPlaying ? (
                <IconSax.Pause variant="Bold" size={20} />
              ) : (
                <IconSax.Play variant="Bold" size={20} />
              )}
            </PlayButton>
            <Icon.SkipForward onClick={() => skipForward()} color="#fff" />
            {/* <IconSax.Repeat color="#fff" /> */}
          </MusicPlaybackWrap>
          <MusicProgressWrap>
            <p>{formatTime(timeProgress)}</p>
            <MusicProgress>
              <input
                type="range"
                defaultValue={'0'}
                ref={progressBarRef}
                onChange={handleProgressChange}
              />
            </MusicProgress>
            <p>{formatTime(duration)}</p>
          </MusicProgressWrap>
        </MusicPlayerActionWrap>
        <MusicVolumeSection>
          <div>
            <MusicProgress>
              <input
                type="range"
                min={0}
                max={100}
                value={volume}
                onChange={e => setVolume(e.target.value)}
              />
            </MusicProgress>
            {!inFull ? (
              <Icon.Maximize2
                onClick={() => toggleFullSceen()}
                size={12}
                color="#fff"
              />
            ) : (
              <Icon.Minimize2
                onClick={() => toggleFullSceen()}
                size={12}
                color="#fff"
              />
            )}
          </div>
        </MusicVolumeSection>
      </BottomMusicPlayer>
    </>
  );
};

export default PlaybackInterface;
