import { useState } from 'react';
import SubmitButton from 'app/components/SubmitButton';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { BeatLoader } from 'react-spinners';
import { css } from '@emotion/react';
import PopUpModal from 'app/components/merch/popupModal';
import OtpInput from 'react-otp-input';

const override: any = css`
  margin-top: 1rem;
`;

const PinSet = () => {
  const history = useNavigate();

  const [onProcess, setOnProcess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [pin, setPin] = useState('');

  // Set Pin
  const handlePinChange = pin => {
    setPin(pin);
  };

  const inputStyle = {
    width: '4rem',
    height: '3rem',
    background: 'rgba(230, 233, 239, 0.4)',
    borderRadius: '4px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginRight: 15,
  };

  // verification section

  const handleOnboard = async e => {
    e.preventDefault();
    setOnProcess(true);
    if (pin && pin !== '' && pin.length === 4) {
      history('/confirm-pin');
      setOnProcess(false);
    } else {
      setErrorMessage('Pin cannot be less than 4!!!');
      setOnProcess(false);
      setTimeout(() => {
        setErrorMessage('');
      }, 4000);
    }
  };

  const handleClose = () => {
    setErrorMessage('');
  };
  return (
    <>
      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="Operation unsuccessful"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="#c82b32"
        />
      ) : null}
      <Container style={{ height: '99vh', background: '#ffffff' }}>
        <Link to="/">
          <MerchroLogo src="/logoblac.svg" alt="merchro" />
        </Link>
        <Section>
          <form onSubmit={handleOnboard}>
            <div className="px-4">
              <h1
                className="my-5  text-center text-xl font-bold text-baseDark"
                style={{ fontFamily: 'Raleway' }}
              >
                Create a PIN
              </h1>
              <p
                style={{
                  margin: '0 0 2rem 0',
                  color: 'grey',
                  textAlign: 'center',
                }}
              >
                Enter a 4 digit PIN that will be used to authorise transactions
              </p>
              <PinInputWrap>
                <OtpInput
                  value={pin}
                  onChange={handlePinChange}
                  numInputs={4}
                  // separator={<span> - </span>}
                  inputStyle={inputStyle}
                />
              </PinInputWrap>
              <SubmitButton
                text={
                  onProcess ? (
                    <BeatLoader color="white" css={override} />
                  ) : (
                    'Create PIN'
                  )
                }
                disabled={false}
              />
            </div>
          </form>
        </Section>
      </Container>
    </>
  );
};

const Container = tw.section`
    bg-gray-200
    p-5
    flex
    flex-col
    justify-center
    items-center
`;
const Section = tw.section`
    rounded
    py-4
    px-0
    bg-white
    w-11/12
    sm:w-11/12
    xl:w-4/12
    2xl:w-4/12
`;
// const CodeInput = tw.input`
//     text-3xl
//     font-bold
//     border-b-2
//     border-gray-200
//     w-32
//     text-center
//     outline-none
//     focus:border-red-600
// `;

export default PinSet;

const MerchroLogo = styled.img`
  height: 2.5rem;
  position: absolute;
  top: 2rem;
  left: 5%;

  @media (max-width: 1028px) {
    top: 1rem;
    left: 3%;
  }
`;

const PinInputWrap = styled.div`
  width: 15rem;
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 1.5rem 0;
`;
