import React from 'react';
import * as Icon from 'react-feather';
import { BeatLoader } from 'react-spinners';
import {
  ModalArea,
  ModalChild,
  CloseBtn,
  ActionBtn,
  Header,
} from 'app/components/dashboard/mainContent/fundWallet/style';
import { Checklist, JoinSplitImage } from './style';

const JoinSplit = ({
  handleAction,
  discardAction,
  actionInProcess,
  discardInProcess,
  closeFunc,
  actionText,
  splitData,
  collaboratorData,
  error,
}) => {
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <JoinSplitImage
            src={
              splitData.avatar
                ? splitData.avatar
                : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
            }
            alt="Split"
          />
          <Header
            style={{
              justifyContent: 'center',
              margin: '1rem 0 0 0',
              width: '20rem',
            }}
          >
            "{splitData.name ? splitData.name : ''}" Split
          </Header>
          <p
            style={{
              textAlign: 'center',
              width: '20rem',
              margin: '1rem 0 0 0',
              fontWeight: '400',
            }}
          >
            You’ve been invited to join the split sheet for "
            {splitData.name ? splitData.name : ''}" as a contributor
          </p>
          <Checklist>
            {collaboratorData.amount && collaboratorData.amount > 0 ? (
              <div>
                <Icon.Check />
                <p>
                  &#8358;{collaboratorData.amount} will be initially paid for
                  recoupments
                </p>
              </div>
            ) : null}
            <div>
              <Icon.Check />
              <p>
                You will be paid{' '}
                {collaboratorData.percentage ? collaboratorData.percentage : ''}
                % of royalty from this split
              </p>
            </div>
            {/* <div>
              <Icon.Check />
              <p>By joining you agree to this contract.</p>
            </div> */}
          </Checklist>
          {error ? (
            <p
              style={{
                color: '#c82b32',
                fontSize: '12px',
                margin: '1remn 0',
              }}
            >
              {error}
            </p>
          ) : null}
          <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => discardAction()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #161616',
              }}
            >
              {discardInProcess ? <BeatLoader color="#d0d5dd" /> : 'Discard'}
            </button>
            <button
              onClick={() => handleAction()}
              style={{
                width: '9rem',
                margin: '2rem 0 0 0',
                background: '#7F56D9',
              }}
            >
              {actionInProcess ? <BeatLoader color="#fff" /> : actionText}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default JoinSplit;
