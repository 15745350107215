import React from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
  BackNavigate,
} from './style';
import Select from 'react-select';
import { numOnly } from 'utils/numOnly';
import * as Icon from 'react-feather';

const BillTransferOption = ({ closeFunc, prevFunc }) => {
  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>
            <BackNavigate onClick={() => prevFunc()}>
              <Icon.ArrowLeft />
            </BackNavigate>
            Select transfer option
          </Header>
          <div>
            <InputWrap>
              <label>Select Data Bundle</label>
              <Select
                options={bundleArray}
                getOptionLabel={p => p.name}
                placeholder="Search..."
              />
            </InputWrap>
            <InputWrap>
              <label>Enter Amount</label>
              <input
                type="number"
                placeholder="Amount"
                onKeyPress={e => numOnly(e)}
              />
            </InputWrap>
            <InputWrap>
              <label>Mobile number</label>
              <input type="text" placeholder="Mobile number" />
            </InputWrap>
            <InputWrap>
              <label>Purpose</label>
              <input type="text" placeholder="Purpose" />
            </InputWrap>
            <InputWrap>
              <label>One Time</label>
              <input type="text" placeholder="One time" />
            </InputWrap>
          </div>
          <BigBtn>
            <button>Send Money</button>
          </BigBtn>
        </ModalChild>
      </ModalArea>
    </>
  );
};

export default BillTransferOption;

const bundleArray = [{ name: 'Data bundle' }];
