import React from 'react';
import {
  MainCardWrappers,
  ItemCard,
  TextWrap,
  ButtonWrap,
  MainWrap,
} from './styles';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { HistoryItemIconWrap } from 'app/components/dashboard/mainContent/style';

const StoreCards = () => {
  return (
    <>
      <MainWrap>
        <MainCardWrappers>
          {CardItemsArray.map(item => (
            <Link to={item.link} key={item.title}>
              <ItemCard customBg={item.customBg} customImg="">
                <HistoryItemIconWrap customBg={'#474747'}>
                  {React.createElement(item.icon, {
                    color: '#fff',
                  })}
                </HistoryItemIconWrap>
                <TextWrap>
                  <h4>{item.title}</h4>
                  <p>{item.text} </p>
                  <ButtonWrap>
                    <button>
                      <Link to={item.link}>Manage {item.title}</Link>
                    </button>
                  </ButtonWrap>
                </TextWrap>
              </ItemCard>
            </Link>
          ))}
        </MainCardWrappers>
      </MainWrap>
    </>
  );
};

export default StoreCards;

const CardItemsArray: any = [
  {
    title: 'Orders',
    text: 'View your order history, merch deals and other insights.  ',
    img: '/Rectangle.svg',
    link: '/order',
    customBg: '#fff',
    customIconBg: '#FF6D66',
    customColor: 'white',
    icon: Icon.ShoppingBag,
  },
  {
    title: 'Products',
    text: 'Add items to sell to your audience on your personal store. ',
    img: '/Rectangle1.svg',
    link: '/product-list',
    customBg: '#fff',
    customColor: 'white',
    icon: Icon.Box,
    customIconBg: 'rgba(215, 36, 131, 0.5)',
  },
  // {
  //   title: 'Discounts',
  //   text: 'Help your store reach a wider audience.',
  //   img: '/Rectangle1.svg',
  //   link: '#',
  //   customBg: '#fff',
  //   customColor: 'white',
  //   icon: Icon.Scissors,
  //   customIconBg: '#4AD5FF',
  // },
  // {
  //   title: 'Collections',
  //   text: 'Help your store reach a wider audience.',
  //   img: '/Rectangle1.svg',
  //   link: '#',
  //   customBg: '#fff',
  //   customColor: 'white',
  //   icon: Icon.Sliders,
  //   customIconBg: '#FF6626',
  // },
  // {
  //   title: 'Customers',
  //   text: 'View people that have bought products from your store.',
  //   img: '/Rectangle8.svg',
  //   link: '/customers',
  //   customBg: '#BAEFFF;',
  //   customColor: 'white',
  // },
];
