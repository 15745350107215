import React, { FC, useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import ReLoginPin from './dashboard/mainContent/fundWallet/reLoginPin';

const IdleTimerContainer: FC<any> = () => {
  const expireTime = JSON.parse(localStorage.getItem('expire_time') || '{}');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const activeState = JSON.parse(localStorage.getItem('dhixabu') || '{}');
  const [count, setCount] = useState<number>(0);
  const [remaining, setRemaining] = useState<number>(0);

  const onIdle = () => {
    if (loggedUser && Object.keys(loggedUser).length > 0) {
      localStorage.setItem('dhixabu', JSON.stringify(true));
    }
  };

  const onActive = () => {
    return remaining;
  };

  const onAction = () => {
    setCount(count + 1);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: Number(process.env.REACT_APP_INACTIVITY_TIMEOUT) || 10000 * 60,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  useEffect(() => {
    if (Date.now() >= expireTime) {
      localStorage.clear();
      window.location.href = '/login';
    }
  });

  const afterLoginAction = () => {};

  return (
    <>
      {activeState && typeof activeState == 'boolean' ? (
        <ReLoginPin
          action={() => {
            afterLoginAction();
          }}
        />
      ) : null}
    </>
  );
};

export default IdleTimerContainer;
