import { useState, useEffect } from 'react';
import { MainWrapper } from '../storeComp/mainContent/styles';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { GenericBtn, GenericAppHeader } from './style';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import * as Icon from 'react-feather';
import AppHeader from '../storeComp/mainContent/header/header';
import { useNavigate } from 'react-router';
import { Constraint } from './../invoice/mainInvoice/style';
import { InputWrap } from '../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import CACDocument from './cacDocument';
import BusinessDocumentModal from './businessDocumentUpload';

const Compliance = () => {
  const history = useNavigate();
  const [openCAC, setOpenCAC] = useState<any>(false);
  const [openId, setOpenId] = useState<any>(false); // address section
  const [cacKycStatus, setCACKycStatus] = useState<any>({});
  const [documentKycStatus, setDocumentKycStatus] = useState<any>({});
  const [noClickBvn, setNoClickBvn] = useState(false);
  const [noClickKyc, setNoClickKyc] = useState(false);

  function toggleCAC() {
    if (
      cacKycStatus.status === 'rejected' ||
      cacKycStatus.status === 'pending'
    ) {
      setOpenCAC(!openCAC);
    } else {
      setNoClickBvn(true);
    }
  }
  function toggleId() {
    if (
      documentKycStatus.status === 'rejected' ||
      documentKycStatus.status === 'pending'
    ) {
      setOpenId(!openId);
    } else {
      setNoClickKyc(true);
    }
  }

  const getStatusColor = (string: string) => {
    if (string && string.toLowerCase() === 'pending') {
      return '#FBBC04';
    } else if (string && string.toLowerCase() === 'verified') {
      return '#34A853';
    } else {
      return '#757575';
    }
  };

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getStatus = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/users/business-kyc-status`, {
        headers: { Authorization },
      })
      .then(res => {
        setCACKycStatus(res.data.cac);
        setDocumentKycStatus(res.data.document);
      })
      .catch(err => {});
  };

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  const backNav = () => {
    history(-1);
  };

  const [success, setSuccess] = useState(false);

  return (
    <>
      <MainWrapper style={{ width: '100%' }} left="0" bg="#fff">
        <AppHeader showBack={true} pageName={`Compliance`} />
        <GenericAppHeader>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '10px',
            }}
          >
            <Icon.ChevronLeft onClick={() => backNav()} />
            <h3>Compliance</h3>
          </div>
        </GenericAppHeader>
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              width="35%"
              top="-4rem"
              style={{
                boxShadow: 'none',
              }}
            >
              <InputWrap style={{ width: '100%' }}>
                <GenericBtn
                  onClick={() => toggleCAC()}
                  style={
                    noClickBvn
                      ? {
                          cursor: 'not-allowed',
                        }
                      : {
                          cursor: 'pointer',
                        }
                  }
                >
                  <div>
                    <p>
                      CAC Document
                      <span
                        style={{
                          color: getStatusColor('pending'),
                        }}
                      >
                        {cacKycStatus.status}
                      </span>
                    </p>
                    <Icon.ChevronRight />
                  </div>
                </GenericBtn>
              </InputWrap>
              <InputWrap style={{ width: '100%' }}>
                <GenericBtn
                  onClick={() => toggleId()}
                  style={
                    noClickKyc
                      ? {
                          cursor: 'not-allowed',
                        }
                      : {
                          cursor: 'pointer',
                        }
                  }
                >
                  <div>
                    <p>
                      Upload Document
                      <span
                        style={{
                          color: getStatusColor('pending'),
                        }}
                      >
                        {documentKycStatus.status}
                      </span>
                    </p>
                    <Icon.ChevronRight />
                  </div>
                </GenericBtn>
              </InputWrap>
              {/* <InputWrap style={{ width: '100%' }}>
                <GenericBtn onClick={() => toggleAddress()}>
                  <div>
                    <p>
                      Address
                      <span>
                        {(street !== '' && street !== null) ||
                        (city !== '' && city !== null) ||
                        (state !== '' && state !== null) ||
                        (country !== '' && country !== null)
                          ? state + ', ' + country
                          : 'Not set'}
                      </span>
                    </p>
                    <Icon.ChevronRight />
                  </div>
                </GenericBtn>
              </InputWrap> */}
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>

        {/* Modals */}
        {openCAC ? (
          <CACDocument
            closeFunc={() => {
              toggleCAC();
              getStatus();
            }}
          />
        ) : null}
        {openId ? (
          <BusinessDocumentModal
            closeFunc={() => {
              toggleId();
              getStatus();
            }}
          />
        ) : null}
      </MainWrapper>

      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
          }}
          img="/success.png"
          message="Success!"
          miniMessage="You have successfully sent your captured image for review."
        />
      ) : null}
    </>
  );
};

export default Compliance;
