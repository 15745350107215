import { useState, useEffect } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import {
  AccountNoCenter,
  ProgressBar,
  ProgressLine,
  TypeCard,
} from '../split/style';
import { MainFlex, PairSection } from '../invoice/mainInvoice/singleStyle';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setBenefits } from 'store/benefitAction';
import Benefit from 'app/components/Benefits/interface';
import { setSelectedBenefit } from 'store/benefitAction';

interface SelectedBenefitState extends Benefit {}

const MainContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [benefitsTypes, setBenefitsTypes] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<Benefit | null>(null);

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/benefits/benefit-types`, {
        headers: { Authorization },
      })
      .then(res => {
        dispatch(setBenefits(res.data));
        setBenefitsTypes(res.data);
      })
      .catch(err => {});
  }, [Authorization, dispatch]);

  const handleSelectBenefit = (selectedItem: SelectedBenefitState) => {
    setSelectedItem(selectedItem);
    dispatch(setSelectedBenefit(selectedItem));
  };

  const handleNext = () => {
    navigate('/benefits/config');
  };

  return (
    <>
      <MainWrapper style={{ width: '100%' }} left="0" top="4.5rem">
        <FragmentHeader showBack={true} pageName={`Select benefits`} />
        <BreadcrumbArea
          style={{
            margin: '4rem 0 10rem 0',
          }}
        >
          <div>
            <ProgressBar>
              <ProgressLine bg="#191919"></ProgressLine>
              <ProgressLine></ProgressLine>
              <ProgressLine></ProgressLine>
            </ProgressBar>
            <AccountNoCenter
              style={{
                margin: '0',
              }}
            >
              <div>
                <h3>Select Benefits choice</h3>
                <p
                  style={{
                    margin: '0',
                    padding: '0.5rem 0',
                  }}
                >
                  List of benefits below
                </p>
              </div>
            </AccountNoCenter>
            <TypeCard>
              {benefitsTypes.map((item, index) => (
                <div
                  key={index}
                  onClick={() => handleSelectBenefit(item)}
                  style={{
                    border:
                      selectedItem && selectedItem.id === item.id
                        ? '1px solid blue'
                        : '1px solid #dadada',
                  }}
                >
                  <img src={item.avatar || '/artist.png'} alt={item.name} />
                  <div>
                    <h3>{item.name}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))}
            </TypeCard>
            <MainFlex
              style={{
                margin: '0',
              }}
            >
              <PairSection width="35%" mobileMargin="0">
                <div
                  style={{
                    boxShadow: 'none',
                    background: 'none',
                  }}
                >
                  <BigBtn style={{ width: '100%' }}>
                    <button onClick={handleNext}>Continue</button>
                  </BigBtn>
                </div>
              </PairSection>
            </MainFlex>
          </div>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default MainContent;
