import SingleMerchComp from 'app/components/merch/singleMerch';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const SingleMerch = () => {
  return (
    <>
      <MainWrapper>
        <SingleMerchComp />
      </MainWrapper>
    </>
  );
};

export default SingleMerch;
