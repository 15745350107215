/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */
import { App } from 'app';
import FontFaceObserver from 'fontfaceobserver';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
// Use consistent styling
import 'sanitize.css/sanitize.css';
import { configureAppStore } from 'store/configureStore';
import './index.css';
// Initialize languages
import './locales/i18n';
import axios from 'axios';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';

// Observe loading of Inter (to remove 'Satoshi', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Satoshi', {});

// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});
const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

// Request interceptor
axios.interceptors.request.use(
  (config: any) => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const hmacDigest = Base64.stringify(
      hmacSHA512(
        JSON.stringify(config.data),
        process.env.REACT_APP_SIGNATURE || 'iuhk',
      ),
    );

    config.headers = {
      'x-payload-signature': hmacDigest,
    };
    if (loggedUser && Object.keys(loggedUser).length > 0) {
      config.headers[
        'Authorization'
      ] = `Bearer ${loggedUser.tokens.access.token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <Provider store={store}>
    {/* <ThemeProvider> */}
    <HelmetProvider>
      <React.StrictMode>
        <div className="no-scrollbar">
          <App />
        </div>
      </React.StrictMode>
    </HelmetProvider>
    {/* </ThemeProvider> */}
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
