import React, { useState, useEffect } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  InputWrap,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';
import Select from 'react-select';
import Countries from 'countries.json';
import * as Icon from 'react-feather';
import { TicketInput } from '../paymentLink/style';
import { CustomStyles } from 'utils/selectCustomStyle';
import axios from 'axios';
import Alert from './../dashboard/mainContent/fundWallet/alert';
import { BeatLoader } from 'react-spinners';

interface PropsArgs {
  state: any;
  country: string;
  city?: string;
  street?: string;
  setStreet: any;
  setState: any;
  setCity: any;
  setCountry: any;
  closeFunc: any;
}

const AddressModal = ({
  closeFunc,
  setStreet,
  setState,
  state,
  country,
  setCity,
  setCountry,
  street,
  city,
}: PropsArgs) => {
  const [states, setInStates] = useState<any>({});
  useEffect(() => {
    const country = 'Nigeria';
    for (let i = 0; i < Countries.length; i++) {
      if (Countries[i].name === country) {
        setInStates(Countries[i].states);
      }
    }
  }, []);

  const [error, setError] = useState<any>('');
  const [msg, setMsg] = useState<any>(false);
  const [onProcess, setOnProcess] = useState(false);

  const apiData = {
    address: {},
  };

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const handlePatch = async () => {
    setOnProcess(true);
    setTimeout(() => {
      setOnProcess(false);
    }, 15000);

    if (state && state !== '' && country && country !== '') {
      apiData.address['street'] = street;
      apiData.address['city'] = city;
      apiData.address['state'] = state;
      apiData.address['country'] = country;
    }

    await axios
      .patch(
        process.env.REACT_APP_BASE_ENDPOINT + '/users/' + loggedUser.user.id,
        apiData,
        {
          headers: { Authorization },
        },
      )
      .then(res => {
        setOnProcess(false);
        if (res.status === 200) {
          setMsg(true);
          if (res.data) {
            loggedUser['user'] = res.data;
            localStorage.setItem('logged_user', JSON.stringify(loggedUser));
          }
          setTimeout(() => {
            setMsg(false);
          }, 4000);
        }
      })
      .catch(err => {
        setOnProcess(false);
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const closeMessage = () => {
    setMsg('');
    closeFunc();
  };

  return (
    <>
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>Address</Header>
          <form onSubmit={closeFunc}>
            <div>
              <TicketInput>
                <div>
                  <p>Street</p>
                  <input
                    type="text"
                    placeholder="Street"
                    onChange={e => setStreet(e.target.value)}
                    required={true}
                    value={street}
                  />
                </div>
              </TicketInput>
              <TicketInput>
                <div>
                  <p>City</p>
                  <input
                    type="text"
                    placeholder="City"
                    onChange={e => setCity(e.target.value)}
                    required={true}
                    value={city}
                  />
                </div>
              </TicketInput>
              <InputWrap>
                <label>Country</label>
                <Select
                  options={availCountries}
                  isSearchable={true}
                  placeholder={'Search...'}
                  getOptionLabel={p => p.name}
                  onChange={(e: any) => setCountry(e.name)}
                  styles={CustomStyles}
                  value={availCountries.filter(p => p.name === country)}
                />
                <input
                  tabIndex={-1}
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    visibility: 'hidden',
                    height: 0,
                    position: 'absolute',
                    top: '2.5rem',
                  }}
                  value={country}
                  required={true}
                />
              </InputWrap>
              <InputWrap>
                <label>State</label>
                <Select
                  options={states}
                  isSearchable={true}
                  placeholder={'Search...'}
                  getOptionLabel={p => (p as HTMLSelectElement).name}
                  onChange={(e: any) => setState(e.name)}
                  styles={CustomStyles}
                  value={
                    states.length > 0
                      ? states.filter(p => p.name === state)
                      : ''
                  }
                />
                <input
                  // tabIndex={-1}
                  autoComplete="off"
                  style={{
                    opacity: 0,
                    visibility: 'hidden',
                    height: 0,
                    position: 'absolute',
                    top: '2.5rem',
                  }}
                  value={state}
                  required={true}
                />
              </InputWrap>
            </div>
            {error !== '' ? (
              <p style={{ color: 'red', width: '20rem' }}>{error}</p>
            ) : null}
            <BigBtn>
              <button onClick={() => handlePatch()}>
                {onProcess ? (
                  <BeatLoader size={15} color={'#fff'} />
                ) : (
                  'Save and continue'
                )}
              </button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>

      {msg ? (
        <Alert
          closeFunc={closeMessage}
          img="success.png"
          message="Changes made successfully!"
          miniMessage="You have successfully updated your profile informations."
        />
      ) : null}
    </>
  );
};

export default AddressModal;

const availCountries = [
  {
    name: 'Nigeria',
  },
];
