import { useState, useEffect } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import {
  GenericAppHeader,
  UserWidget,
  UserAvatar,
  BlueText,
  BreadcrumbArea,
  BoxCard,
  BoxFlex,
  UserInfoTextWrap,
  Chart,
} from './../editProfile/style';
import { useNavigate } from 'react-router-dom';
import AppHeader from '../storeComp/mainContent/header/header';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PopUpModal from '../merch/popupModal';
import { handleFileUpload } from 'utils/imageUpload';

const MainContent = () => {
  const [user, setUser] = useState<any>({});
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [accountInformation, setAccountInformation] = useState<any>({});
  const myStore = JSON.parse(localStorage.getItem('store') || '{}');
  const history = useNavigate();

  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  useEffect(() => {
    setAccountInformation(loggedUser.user);
  }, [loggedUser]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getUser = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/users/me`, headers)
      .then(res => {
        setUser(res.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const backNav = () => {
    history(-1);
  };

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [images, setImages] = useState<any>([]);
  const [returnedImages, setReturnedImages] = useState<any>('');

  // User Authentication

  const extraImageFunc = data => {
    const mockUser = loggedUser;
    axios
      .patch(
        `${process.env.REACT_APP_BASE_ENDPOINT}/users/${loggedUser.user.id}`,
        { avatar: data },
        headers,
      )
      .then(res => {
        setSuccess(true);
        mockUser.user['avatar'] = res.data.avatar;
        localStorage.setItem('logged_user', JSON.stringify(mockUser));
      })
      .catch(err => {
        setError(err.response.data.message);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setError,
      setImages,
      setReturnedImages,
      extraFunc: extraImageFunc,
    });
  };

  const handleClose = () => {
    setError('');
    setSuccess(false);
  };

  const logOut = () => {
    localStorage.clear();
    history('/login');
  };

  return (
    <>
      {success ? (
        <PopUpModal
          handleClose={handleClose}
          msg="Avatar updated!"
          extraMsg="Your user avatar has been updated successfully."
          icon="fas fa-check"
          iconColor="green"
        />
      ) : null}

      {error !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={error}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}
      <MainWrapper
        className="no-scrollbar"
        style={{ width: '100%' }}
        left="0"
        bg="#fff"
        mobileTop="2rem"
      >
        <AppHeader showBack={true} pageName={`Profile`} />
        <GenericAppHeader onClick={() => backNav()}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '10px',
            }}
          >
            <Icon.ChevronLeft />
            <h3>Account Details</h3>
          </div>
        </GenericAppHeader>
        <UserWidget>
          {images && images.length > 0 && returnedImages === '' ? (
            <UserAvatar>
              <img src={URL.createObjectURL(images[0])} alt={user.firstName} />
            </UserAvatar>
          ) : null}

          {images.length < 1 && returnedImages === '' ? (
            <UserAvatar>
              <img
                src={user.avatar ? user.avatar : '/test-avatar.png'}
                alt={user.firstName}
              />
            </UserAvatar>
          ) : null}

          {returnedImages !== '' ? (
            <UserAvatar>
              <img src={returnedImages} alt={user.firstName} />
            </UserAvatar>
          ) : null}
          <label htmlFor="main-input">
            <BlueText
              style={{
                margin: 0,
                padding: 0,
                cursor: 'pointer',
              }}
            >
              {'Edit Avatar'}
            </BlueText>
          </label>

          <input
            id="main-input"
            type="file"
            accept={'.png, .jpg, .jpeg, .webp'}
            multiple={false}
            style={{ display: 'none' }}
            onChange={e => {
              handleFile(e.target.files);
            }}
          ></input>
          <h3
            style={{
              background: '#FFFFFF',
              border: '0.5px solid rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
              padding: '0.4rem 1rem',
            }}
          >
            @{myStore.slug}
          </h3>
        </UserWidget>
        <BreadcrumbArea>
          <BoxCard>
            <Link to="/edit-profile">
              <BoxFlex>
                <Chart>
                  <IconSax.UserSquare />
                </Chart>
                <UserInfoTextWrap>
                  <h3>Personal details</h3>
                  {/* <p>Not verified</p> */}
                </UserInfoTextWrap>
                <i className="fi fi-rr-angle-right"></i>
              </BoxFlex>
            </Link>
          </BoxCard>
          <BoxCard>
            <Link to="/set-identity">
              <BoxFlex>
                <Chart>
                  <IconSax.SecurityUser />
                </Chart>
                <UserInfoTextWrap>
                  <h3>Identity Verification</h3>
                  {/* <p>Not verified</p> */}
                </UserInfoTextWrap>
                <i className="fi fi-rr-angle-right"></i>
              </BoxFlex>
            </Link>
          </BoxCard>
          {accountInformation.isBusiness ? (
            <BoxCard>
              <Link to="/compliance">
                <BoxFlex>
                  <Chart>
                    <IconSax.DocumentText />
                  </Chart>
                  <UserInfoTextWrap>
                    <h3>Compliance</h3>
                  </UserInfoTextWrap>
                  <i className="fi fi-rr-angle-right"></i>
                </BoxFlex>
              </Link>
            </BoxCard>
          ) : null}
          <BoxCard>
            <Link to="/set-security">
              <BoxFlex>
                <Chart>
                  <IconSax.Lock1 />
                </Chart>
                <UserInfoTextWrap>
                  <h3>Security</h3>
                  {/* <p>Not verified</p> */}
                </UserInfoTextWrap>
                <i className="fi fi-rr-angle-right"></i>
              </BoxFlex>
            </Link>
          </BoxCard>
          <BoxCard onClick={logOut}>
            <BoxFlex>
              <Chart>
                <IconSax.LogoutCurve />
              </Chart>
              <UserInfoTextWrap>
                <h3>Sign out</h3>
                {/* <p>Not verified</p> */}
              </UserInfoTextWrap>
              <i className="fi fi-rr-angle-right"></i>
            </BoxFlex>
          </BoxCard>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default MainContent;
