import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-feather';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Store from 'utils/store';
import { titleCase } from 'utils/capitalizeFirst';
import Button from 'app/components/actual-components/Button';
import formatCurrency from 'utils/formatCurrency';
import Helper from 'utils/helper';
import { getRequest, updateRequest } from 'utils/request';
import Alert from 'app/components/dashboard/mainContent/fundWallet/alert';
const DealTransactions = (props: any) => {
  const [data, setData] = useState(props.data);
  let [style, setStyle] = useState(props.style);
  if (!style) style = {};
  const columnWidth = ['25%', '25%', '25%', '25%'];

  useEffect(() => {
    setData(props.data);
    setStyle(props.style);
  }, [props]);
  return (
    <>
      <Table
        position={style.position || 'relative'}
        top={style.top || 0}
        bg={style.bg}
      >
        <h1>
          Transactions <span>&nbsp;</span>
        </h1>
        {data && data.length > 0 ? (
          <>
            <TableHeader>
              <Row className="header-row">
                {['name', 'type', 'date', 'amount'].map((item, index) => {
                  return (
                    <Column style={{ width: columnWidth[index] }} key={index}>
                      {item.toUpperCase()}
                    </Column>
                  );
                })}
              </Row>
            </TableHeader>
            <TableContent>
              {data.map((trx, index) => {
                return (
                  <Row className="item-row">
                    {['name', 'type', 'createdAt', 'amount'].map(
                      (item, index) => {
                        return (
                          <Column
                            style={{
                              width: columnWidth[index],
                              fontWeight: item === 'amount' ? 500 : 400,
                            }}
                            key={index}
                            className="item-column"
                          >
                            {item === 'type'
                              ? titleCase(trx[item])
                              : item === 'amount'
                              ? formatCurrency(trx[item])
                              : trx[item]}
                          </Column>
                        );
                      },
                    )}
                  </Row>
                );
              })}
            </TableContent>
          </>
        ) : (
          <p>No transaction data found</p>
        )}
      </Table>
    </>
  );
};
const DealView = props => {
  const dealCode = window.location.href.split('dealCode=')[1];
  const navigate = useNavigate();
  const [data, setData] = useState(Store.get('activeDeal'));
  const [transactions, setTransactions] = useState([]);
  const activeUser = Store.get('logged_user').user;

  if (!data || (data.code !== dealCode && `${data.code}/` !== dealCode)) {
    navigate('/deals');
  }

  useEffect(() => {
    if (data.status === 'funded') {
      getRequest(`deals/${data.id}/transactions?paginate=false&include=user`)
        .then(res => {
          setTransactions(
            res.map(t => {
              t.name = `${t.user.firstName} ${t.user.lastName}`;
              return t;
            }),
          );
        })
        .catch(err => {
          console.log(err.message);
        });
    }
  }, [data]);

  const [showActions, setShowActions] = useState(false);
  const [_alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [size, setSize] = useState(window.innerWidth);
  const [declining, setDeclining] = useState(false);
  const [activeAlertImage, setActiveAlertImage] = useState('/failed.png');

  useEffect(() => {
    setSize(window.innerWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.innerWidth]);

  const viewAnalysis = (link: string) => {
    window.open(
      link || `https://api.chartmetric.com/artist/${data.code.split('-')[1]}`,
    );
  };

  const declineDeal = () => {
    if (!declining) {
      setDeclining(true);
      updateRequest(`deals/${data.id}`, { status: 'declined' })
        .then(res => {
          const updatedData = { ...data };
          updatedData.status = 'declined';
          setData(updatedData);
          setAlertMessage('You successfully declined this deal');
          setAlert(true);
          setActiveAlertImage('/success.png');
        })
        .catch(err => {
          setAlertMessage(err.message);
          setAlert(true);
          setActiveAlertImage('/failed.png');
        });
    }
  };
  return (
    <DealWrapper className="deal-view">
      {_alert && (
        <Alert
          closeFunc={() => setAlert(false)}
          message={
            activeAlertImage === '/success.png'
              ? 'Successful'
              : 'An error occured'
          }
          miniMessage={alertMessage}
          img={activeAlertImage}
        />
      )}

      <DealHeader>
        <Icon.X
          size={24}
          style={{
            position: 'relative',
            left: '0.5rem',
            marginRight: '2rem',
          }}
          className="close-icon"
          onClick={() => {
            navigate('/deals');
          }}
        />
        <h1 style={{ fontSize: 20, marginTop: -2.5, fontWeight: 500 }}>
          {Helper.clipTextV2(data.name, size > 768 ? 100 : 12)}
        </h1>
        <ActionSide>
          <Status status={data.status}>
            <span>&nbsp;</span>
            {size > 560 && titleCase(data.status)}
          </Status>
          {data.status !== 'declined' && (
            <Button
              text="Actions"
              icon={showActions ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
              iconPlacement="right"
              onClick={() => setShowActions(!showActions)}
              style={{ padding: '5px 10px' }}
            />
          )}
          {showActions ? (
            <>
              <ActionDetail>
                {data.status === 'pending' ? (
                  <>
                    <div>
                      <p onClick={() => navigate('../fund')}>Fund deal</p>
                    </div>
                    <div>
                      <p
                        onClick={() => {
                          declineDeal();
                        }}
                      >
                        Decline deal
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div>
                      <p>Get statement</p>
                    </div>
                    <div>
                      <p>Raise Concern</p>
                    </div>
                  </>
                )}
              </ActionDetail>
              <Overlay onClick={() => setShowActions(false)}></Overlay>
            </>
          ) : (
            ''
          )}
        </ActionSide>
      </DealHeader>
      <DealBody>
        <LeftSide>
          <DealRate>
            <DealAmount>
              <span>Amount</span>
              <h1>{formatCurrency(data.amount)}</h1>
            </DealAmount>
            <p>
              Interest rate: <span>{data.interestRate || '5%'}</span>
            </p>
            <p>
              Duration: <span>{data.duration || '6'} months</span>
            </p>
          </DealRate>
          <DealMoreInfo>
            <MoreInfoImage>
              <img
                src={
                  data.image ||
                  'https://th.bing.com/th/id/OIP.0KsBbX2GlbMXixH9aXK-LQHaHa?rs=1&pid=ImgDetMain'
                }
                alt=""
              />
            </MoreInfoImage>
            <p onClick={() => {}} className="stage-name">
              {data.name}
            </p>
            <p onClick={() => viewAnalysis(data.infoLink)}>
              View loan analysis
            </p>
          </DealMoreInfo>
        </LeftSide>
        <MainBox>
          {data.status === 'funded' && data.claimer === activeUser.id ? (
            <DealTransactions
              style={{ position: 'absolute', top: 0 }}
              data={transactions}
            />
          ) : data.status === 'pending' ? (
            <div
              className="empty-state"
              style={{ margin: 50, color: '#181818' }}
            >
              <p style={{ fontSize: 22 }}>Fund this deal</p>
              <p style={{ color: '#161616cc' }}>
                <span onClick={() => navigate('../fund')}>Click here</span> to
                fund this deal and earn interest
              </p>
            </div>
          ) : data.status === 'declined' ? (
            <div
              className="empty-state"
              style={{ margin: 50, color: '#181818', textAlign: 'center' }}
            >
              You declined this offer. <br />
              Please go back to deals page to find more suitable options.
            </div>
          ) : (
            <div
              className="empty-state"
              style={{ margin: 50, color: '#181818', textAlign: 'center' }}
            >
              Oops! Someone beat you to this <br />
              Please go back to deals page to find other suitable options.
            </div>
          )}
        </MainBox>
      </DealBody>
    </DealWrapper>
  );
};

export default DealView;

const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 2;
`;

const DealWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media max-width(1028px) {
    flex-direction: row;
    background: red;
  }
  .close-icon {
    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
`;

const DealHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 50px 20px 30px;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
`;

const ActionSide = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 30px;
  bottom: 20px;
`;

const Status = styled.div<{ status: string }>`
  position: relative;
  font-size: 14px;
  span {
    background: ${props =>
      props.status === 'pending'
        ? '#FFC327'
        : props.status === 'funded'
        ? '#34A853'
        : '#C82B32'};
    border-radius: 50%;
    height: 7px;
    width: 7px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
    top: 7.5px;
  }
  background: ${props =>
    props.status === 'pending'
      ? '#FFC32721'
      : props.status === 'funded'
      ? '#34A85321'
      : '#C82B321'};
  color: ${props =>
    props.status === 'pending'
      ? '#FFC327'
      : props.status === 'funded'
      ? '#34A853'
      : '#C82B32'};
  border: 1px solid
    ${props =>
      props.status === 'pending'
        ? '#FFC327'
        : props.status === 'funded'
        ? '#34A853'
        : '#C82B32'};
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
`;

const ActionDetail = styled.div`
  display: flex;
  position: absolute;
  background: #fff;
  box-shadow: 0 0 5px #ccc;
  flex-direction: column;
  height: auto;
  min-width: 180px;
  border-radius: 5px;
  right: 15px;
  top: 40px;
  z-index: 999;
  div {
    p {
      border-bottom: 1px solid #d6d6d6;
      width: 100%;
      padding: 15px 20px;
      cursor: pointer;
    }
    &:last-child {
      p {
        border-bottom: none;
      }
    }
    :hover {
      background: whitesmoke;
      cursor: pointer;
      border-radius: 5px;
    }
  }
`;

const DealBody = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dcdcdc;
  width: 25%;
  @media (max-width: 768px) {
    width: 100%;
  }
  height: calc(100vh - 80px);
`;

const DealRate = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
  padding: 30px 50px;
  p {
    margin-top: 10px;
    font-weight: 500;
    color: #525252;
    font-size: 14px;
    span {
      font-weight: 400;
    }
  }
`;

const DealAmount = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 14px;
    color: #828282;
  }
  h1 {
    font-size: 32px;
    @media (max-width: 1028px) {
      font-size: 24px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
    }
    color: #161616;
    font-weight: 500;
  }
`;

const DealMoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  p {
    margin: 20px 10px;
    text-align: left;
    &.stage-name {
      color: #007aff;
    }
    cursor: pointer;
  }
`;

const MoreInfoImage = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-direction: row;
  img {
    border-radius: 30px;
    width: 100%;
    height: 100%;
    border: 15px solid #ababab1a;
  }
`;

const MainBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  div.empty-state {
    display: flex;
    justify-content: center;
    position: relative;
    width: 50%;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    span {
      color: #007aff;
      cursor: pointer;
    }
  }
`;
const Table = styled.div<{ position?: string; top?: string; bg?: string }>`
  display: flex;
  width: calc(100% - 100px);
  flex-direction: column;
  justify-content: space-between;
  margin: 50px;
  position: ${p => p.position || 'relative'};
  background: ${p => p.bg || '#fff'};
  top: ${p => p.top || 0};
  h1 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 50px;
    position: relative;
    color: #161616;
    span {
      position: absolute;
      width: 25px;
      height: 3px;
      background: #161616;
      left: 0;
      bottom: -1px;
    }
  }
`;

const TableHeader = styled.div`
  display: flex;
  min-width: 100%;
`;

const TableContent = styled.div`
  margin: 0 auto;
  display: flex;
  min-width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid whitesmoke;
  padding: 10px;
  &.header-row {
    background: rgba(230, 233, 239, 0.3);
    font-size: 13px;
    border: 1px solid whitesmoke;
    padding: 0;
  }
`;

const Column = styled.div`
  display: flex;
  width: auto;
  padding: 10px;
  margin: auto;
  font-size: 14px;
  text-align: left;
  overflow: ellipsis;
`;
