const Button = props => {
  let { style } = props;
  const defaultStyles = {
    margin: '0 1rem 0 0',
    color: '#fff',
    padding: '0.75rem 1rem',
    background: '#191919',
    borderRadius: '4px',
    fontSize: '0.8rem',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  };
  if (style) {
    Object.keys(style).forEach(key => {
      if (Object.keys(defaultStyles).includes(key)) {
        delete defaultStyles[key];
      }
    });
  } else style = {};
  const styles = { ...defaultStyles, ...style };
  const onClickHandler = () => {
    return props.onClick(true);
  };
  return (
    <button style={styles} onClick={() => onClickHandler()}>
      {!props.iconPlacement || props.iconPlacement === 'left' ? props.icon : ''}
      {props.text}
      {props.iconPlacement && props.iconPlacement === 'right' ? props.icon : ''}
    </button>
  );
};

export default Button;
