import styled from 'styled-components';

export const MainWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 728px) {
    flex-direction: column;
    margin: 2rem 0 0 0;
  }
`;

export const Leftpart = styled.div`
  width: 30%;

  > h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.02em;
    color: #191919;
  }

  p {
    color: #c82b38;
    font-size: 16px;
    margin: 1rem 0 0 0;
    display: flex;
    align-items: Center;
    gap: 5px;

    :hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 728px) {
    width: 100%;
    text-align: center;

    > h2 {
      text-align: center;
    }
  }
`;

export const MainCardWrappers = styled.div`
  width: 100%;
  margin: 5rem 0 2rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 728px) {
    width: 100%;

    a {
      width: 100%;
    }

    margin: 3rem 0 2rem 0;
  }
`;

interface PropsArgs {
  customBg: any;
  customImg: any;
}

export const ItemCard = styled.div<PropsArgs>`
  width: 20rem;
  // height: 12rem;
  padding: 2rem 2rem 5rem 2rem;
  margin: 0 0rem 1rem 0;
  background: ${p => p.customBg || '#ffffff'};
  background-image: url(${p => p.customImg});
  color: #000000;
  border: 0.2px solid #dadada;
  border-radius: 8px;
  position: relative;

  :hover {
    box-shadow: 0px 0px 10px #cbced1;
  }

  @media (max-width: 1028px) {
    width: 100%;
    height: 12rem;
    padding: 10% 2rem 5rem 2rem;
    margin: 0 0 0.1rem 0%;
  }
`;

export const TextWrap = styled.div`
  > h4 {
    margin: 1rem 0 0 0;
    font-size: 0.9rem;
    font-weight: 600;
  }

  p {
    font-size: 0.8rem;
    font-weight: 400;
    width: 95%;
    margin: 1rem 0 0 0;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0 0 0;
  position: absolute;
  bottom: 1rem;
  right: 2rem;
  width: 10rem;

  button {
    padding: 0.6rem 5%;
    background: #fff;
    color: #191919;
    font-size: 0.7rem;
    border-radius: 0.2rem;
    border: none;

    @media (max-width: 1028px) {
      padding: 5% 5%;
    }

    :hover {
      background: #fff;
      color: #191919;
    }
  }
`;
