import styled from 'styled-components';

export const FlexedArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: Center;
  margin: 2rem 0;

  @media (max-width: 1028px) {
    flex-direction: column;
    gap: 30px;
  }
`;

export const StoreLink = styled.p`
  background: #ececec;
  color: #868686;
  font-size: 0.75rem;
  padding: 1% 2%;
  border-radius: 0.2rem;

  @media (max-width: 1028px) {
    width: 100%;
    padding: 3.5% 2%;
  }
`;

interface PropsArgs {
  margin?: any;
  mobileHide?: boolean;
}

export const ButtonWrap = styled.div<PropsArgs>`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 728px) {
    display: ${p => (p.mobileHide ? 'none' : 'flex')};
    width: calc(40% - 20px);
    margin: ${p => p.margin || '2.5rem 0 0 0'};
    justify-content: center;
    gap: 10px;
  }
`;
export const CustomButtonLight = styled.button`
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 20px;
  width: auto;
  color: #191919;
  padding: 0.5rem 1.7rem;
  font-size: 0.7rem;
  margin: 0 3% 0 0;

  button {
    font-weight: 700;
  }

  @media (max-width: 1028px) {
    width: 46%;
    padding: 3.5% 1%;
  }

  i {
    margin: 0 5% 0 0;
  }
`;

interface CustomBtnProp {
  bg?: string;
  color?: string;
}

export const CustomButtonDark = styled.button<CustomBtnProp>`
  background: ${p => p.bg || '#191919'};
  color: ${p => p.color || '#fff'};
  max-width: 120px;
  width: 120px;
  padding: 0.7rem;
  font-size: 0.7rem;
  margin: 0 30px 0 0;
  border-radius: 20px;

  @media (max-width: 728px) {
    position: absolute;
    right: calc(50% - 5px);
    top: 18px;
    padding: 0.85rem;
  }

  button {
    font-weight: 700;
  }

  i {
    margin: 0 5% 0 0;
  }
`;

export const CustomButtonInverse = styled.button<CustomBtnProp>`
  background: ${p => p.bg || '#fff'};
  color: ${p => p.color || '#8975f3'};
  max-width: 150px;
  width: 150px;
  padding: 0.7rem;
  font-size: 0.7rem;
  margin: 0 3% 0 0;
  border-radius: 20px;
  border: 1px solid #8975f3;
  position: absolute;
  right: 150px;
  &:hover {
    background: #0f0f0f;
    color: #f0f0f0;
    border: 1px solid black;
  }
  @media (max-width: 728px) {
    left: calc(50% - 15px);
  }
  button {
    font-weight: 700;
  }

  i {
    margin: 0 5% 0 0;
  }
`;

export const ButtonFlex = styled.span<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${p => p.cursor || 'pointer'};
  opacity: ${p => p.opacity || '1'};
`;

export const CopyFlex = styled.div`
  display: flex;
  width: 25rem;
  align-items: center;
  justify-content: flex-end;
  padding: 0 3rem 0 0;
  margin: 0 0 0rem 0;

  @media (max-width: 500px) {
    width: 100%;
    margin: 0 auto;
    margin-top: 2rem;
    padding: 0;
    justify-content: center;
  }
`;

export const CopyIconsWrap = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 10rem;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 2%;
  cursor: pointer;
`;

export const PageLink = styled.p`
  background: #ffffff;
  border: 1px solid #f2f2f2;
  color: #000000;
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1028px) {
    width: 75%;
  }
`;

export const FloatSearch = styled.div`
  position: fixed;
  top: 1rem;
  right: 0;
  width: 30rem;
  display: flex;
  z-index: 10000;
  justify-content: flex-end;

  @media (max-width: 728px) {
    width: 95%;
    margin: 0 auto;
    padding: 0 2.5%;
    top: 5rem;
    right: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
