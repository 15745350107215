import styled from 'styled-components';

export const MainFlex = styled.div`
  display: Flex;
  align-items: flex-start;
  justify-content: Center;
  gap: 20px;

  @media (max-width: 728px) {
    flex-direction: column;
    gap: 0px;

    > div:nth-child(2) {
      margin: 1rem 0 0 0;
    }
  }
`;

export const Avatar = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 10rem;
  display: flex;
  align-items: Center;
  justify-content: Center;
  background: linear-gradient(
    212.36deg,
    rgba(200, 43, 50, 0.63) 15.39%,
    rgba(200, 43, 50, 0.63) 87.6%
  );
  color: #fff;
  font-weight: 900;
  font-size: 36px;
  font-family: Nunito, 'sans-serif';

  @media (max-width: 728px) {
    width: 3rem;
    height: 3rem;
    font-size: 25px;
  }
`;

interface PairProps {
  width?: string;
  mobileMargin?: string;
}

export const PairSection = styled.div<PairProps>`
  width: ${p => p.width || '40%'};

  > div {
    background: #ffffff;
    // border: 0.3px solid #DADADA;
    box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 1rem 6%;
    margin: 0 0 1.5rem 0;

    > h3,
    > div > h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.01em;
      color: #191919;
    }
  }

  @media (max-width: 728px) {
    width: 100%;
    margin: ${p => p.mobileMargin || '5rem 0 0 0'};

    > div {
      padding: 1rem 0;
      box-shadow: none;
    }
  }
`;

export const InvoiceInfo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
  padding: 0 0 1rem 0;

  > img {
    width: 4rem;
    border-ra
    dius: 10rem;
    object-fit: Cover;
  }

  > div {
    position: Relative;
    display: grid;
    gap: 10px;

    > h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      color: #757575;
    }
  }

  @media (max-width: 728px) {
    align-items: flex-start;
  }
`;

export const ItemSection = styled.div`
  position: relative;
  padding: 0rem 0 1rem 0;

  > div {
    display: Flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.3rem 0 0.9rem 0;

    > h1 {
      font-weight: 700;
      font-size: 16px;
      letter-spacing: -0.01em;
      color: #474747;
    }

    > h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #191919;
    }

    > h4 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #191919;
    }

    > p {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #191919;
    }

    > span {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.01em;
      color: #474747;
    }
  }
`;

export const DataTable = styled.div`
  display: flex;
  align-items: Center;
  justify-content: space-between;
  width: 100%;

  > div:first-child {
    text-align: left;
  }
  > div:nth-child(2) {
    text-align: center;
  }
  > div:nth-child(3) {
    text-align: right;
  }

  > div {
    flex: 1;
    width: 30%;

    > h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #191919;
    }

    p {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.01em;
      color: #191919;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const SpaceHeader = styled.div`
  display: flex;
  align-items: Center;
  gap: 10px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #474747;
    width: 30px;
    height: 30px;
    color: #fff;
    border-radius: 10rem;
  }

  > h1 {
    font-weight: 700;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: #474747;
  }
`;

export const SmallAvatar = styled.section`
  background: linear-gradient(
    203.81deg,
    #83e2ff 15.31%,
    rgba(0, 93, 121, 0.82) 85.77%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 10rem;
  font-size: 12px;
`;

export const AvatarNameWrap = styled.section`
  display: flex;
  width: 30%;
  align-items: Center;
  gap: 5px;

  > p {
    width: 70%;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: #191919;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
