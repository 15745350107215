import { FlexedArea } from './../../../storeComp/mainContent/sectionOne/styles';
import { FilterArea } from './styles';
import Select from 'react-select';

const SectionOne = ({ handleSearch, handleFilter }) => {
  const handleSelect = (e: any) => {
    handleFilter(e as HTMLSelectElement);
  };

  const status = [
    { name: 'All' },
    { name: 'Fulfilled' },
    { name: 'Enroute' },
    { name: 'To pickup' },
    { name: 'Pre-order' },
    { name: 'Pending' },
    { name: 'Completed' },
    { name: 'Unfulfilled' },
    { name: 'Processing' },
  ];

  const sizeStyle = {
    control: (provided, state) => ({
      ...provided,
      border: '0.5px solid #dadada',
      padding: '0.2rem 2%',
      margin: '0.4rem 0 0.2rem 0',
      borderRadius: '5px',
      background: '#ffffff',
      width: '10rem',
      fontSize: '16px',
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'default',
        background: '#fff',
        color: '#191919',
        fontSize: '16px',
      };
    },
  };

  return (
    <>
      <FlexedArea>
        <FilterArea>
          <span>Filter by:</span>
          <Select
            options={status}
            getOptionLabel={p => p.name}
            styles={sizeStyle}
            placeholder="Search..."
            onChange={handleSelect}
          />
        </FilterArea>
      </FlexedArea>
    </>
  );
};

export default SectionOne;
