import Setup from 'app/components/invoice/mainInvoice/setup';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const SetUpInv = () => {
  return (
    <>
      <MainWrapper
        className="no-scrollbar"
        style={{
          background: '#fff',
        }}
      >
        <Setup />
      </MainWrapper>
    </>
  );
};

export default SetUpInv;
