/* eslint-disable react-hooks/exhaustive-deps */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { GlobalStyle } from '../styles/global-styles';
import '../styles/index.css';
// import { useState } from 'react';
// import { ProtectedRoute } from './../protectedRoute';
import {
  PrivateRoute,
  PreventToAuth,
  NotOnboarding,
} from './../protectedRoute';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import Customers from './pages/customers/customers';
import SingleAudience from './pages/customers/singleAudience';
import Dashboard from './pages/dashboard/dashboard';
import ForgotPassword from './pages/ForgotPassword.tsx/ForgotPassword';
import Login from './pages/Login/Login';
import AddMerch from './pages/merch/addMerch';
import EditMerch from './pages/merch/editMerch';
import ProductList from './pages/merch/product-list';
// import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import Onboarding from './pages/Onboarding';
import KYC1 from './pages/Onboarding/kyc1';
import Order from './pages/order/order';
import SingleOrder from './pages/order/singleOrder';
import Preference from './pages/preference/preference';
import SignUp from './pages/SignUp/SignUp';
import StoreHome from './pages/storeHome/storeHome';
// import Welcome from './pages/Welcome/Welcome';
import Profile from './pages/profile/profile';
import EditProfile from './pages/edit-profile/edit-profile';
import Identity from './pages/edit-profile/identity';
import CompliancePage from './pages/edit-profile/compliance';
import Security from './pages/edit-profile/security';
import Invoice from './pages/invoice/invoiceMain';
import SetUpInv from './pages/invoice/setup';
import CreateInv from './pages/invoice/createInv';
import NewInvoice from './pages/invoice/new-invoice';
import Documents from './pages/invoice/documents';
import ChangePwd from './pages/changePwd/changePwd';
// import PreviewInv from './pages/invoice/preview';
import TransactionsPage from './pages/transactions';
import PinSet from './pages/Onboarding/pinSet';
import PaymentLinkPage from './pages/paymentLinks';
import CreateLinkPage from './pages/paymentLinks/createLink';
import EditLink from './components/paymentLink/editLink';
import SelectTypePage from './pages/paymentLinks/selectType';
import EventPage from './pages/paymentLinks/event';
import SinglePage from './pages/paymentLinks/single';
import SingleMerch from './pages/merch/singleMerch';
import SingleInvoice from './pages/invoice/single';
import SplitPage from './pages/split';
import SplitSinglePage from './pages/split/single';
import CreateSplitFirstPage from './pages/split/createSplitFirst';
import CreateSplitSecondPage from './pages/split/createSplitSecond';
import EditSplitFirstPage from './pages/split/editSplitFirst';
import EditSplitSecondPage from './pages/split/editSplitSecond';
import SetCurrency from './pages/split/setCurrency';
import EditCurrency from './pages/split/edit-currency';
import Contact from './pages/contact';
import NGNTransfer from './pages/transactions/NGNTransfer';
import Beneficiary from './pages/transactions/beneficiary';
import SplitTransfer from './pages/transactions/splitPayment';
import MutualWallet from './pages/transactions/mutualWalletTransfer';
import CreatePin from './pages/createPin';
import ConfirmPin from './pages/confirmPin';
import IdleTimerContainer from './components/idleTimerContainer';
import DOMTransferComp from './components/transactions/dom-transfer';
import WireTransferComp from './components/transactions/wireTransfer';
import SenderInformationComp from './components/transactions/senderInformation';
import RecieverInformationComp from './components/transactions/recieverInformation';
import SwapCurrency from './components/transactions/swapCurrency';
import CardPage from './pages/card';
import FundCardComp from './components/card/fundCard';
import WithdrawFundComp from './components/card/withdraw';
import RequestCardPage from './pages/card/requestCard';
import CardTransactionPage from './pages/card/transactions';
import WithdrawPoint from './components/transactions/selectWithdrawPoint';
import MusicSplitSetup from './pages/split/musisSplit';
import SelectSplitType from './components/split/selectSplitType';
import PayoutSettings from './components/split/payoutSettings';
import JoinSplitIdentity from './components/split/identity';
import ContractDisplay from './components/split/pdfDisplay';
import AddData from './components/split/add-data';
// NOTE: Song is hidden for now
// import SongIndexPge from './pages/songs';
// import ArtistIndexPage from './pages/artiste';
// import AddArtist from './components/Artiste/addArtist';
// import Distributor from './pages/distributor';
// import DistributeMusic from './components/distributors/addArtiste';
// import UploadAudio from './components/songs/uploadAudio';
// import SingleSong from './components/songs/single';
import PlaybackInterface from './components/songs/playback';
// import AddServices from './components/distributors/addServices';
// import AddReleaseSongs from './components/distributors/releaseUpload';
import ProjectSplitSetup from './pages/split/projectSplit';
import CreateBenefits from './pages/Benefits';
import BenefitComps from './components/Benefits/benefitsComp';
import BenefitSingle from './pages/Benefits/singleBenefit';
// import PlanPage from './pages/Plan';
import PaymentSuccess from './pages/paymentSuccess';
import Deal from './pages/deals';
import DealView from './pages/deals/DealView';
import MyDeals from './pages/deals/MyDeals';
import PaymentScreen from './components/actual-components/PaymentScreen';
import CardViewPage from './pages/card/cardViewPage';

export function App() {
  const { i18n } = useTranslation();
  let songsResult = JSON.parse(localStorage.getItem('asl') || '{}');
  const [key, setKey] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setKey(key => ++key);
    }, 1000);
  }, []);

  let activeSongIndex = JSON.parse(localStorage.getItem('asi') || '{}');
  let showPlayBackState = JSON.parse(localStorage.getItem('spv') || '{}');

  // Song Playback States and Functions

  const audioRef: any = useRef();
  const progressBarRef: any = useRef();
  const [activeSong, setActiveSong] = useState<any>({});
  const [volume, setVolume] = useState(100);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [timeProgress, setTimeProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showPlayback, setShowPlayback] = useState(false);

  useEffect(() => {
    setActiveSong(songsResult[activeSongIndex]);
    setShowPlayback(showPlayBackState);
    if (
      showPlayback === false ||
      showPlayback === undefined ||
      showPlayback === null
    ) {
      setIsPlaying(false);
    }
  }, [key]);

  const playAnimationRef: any = useRef();

  useEffect(() => {
    if (audioRef && audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume, audioRef]);

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(progressBarRef.current.value / Number(duration)) * 100}%`,
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  const togglePlayPause = () => {
    setIsPlaying((prev: boolean) => !prev);
  };

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    if (progressBarRef && progressBarRef.current) {
      progressBarRef.current.max = seconds;
    }
  };

  // Play Song
  useEffect(() => {
    if (audioRef && audioRef.current) {
      let current = audioRef.current;
      if (isPlaying) {
        current.play();
        playAnimationRef.current = requestAnimationFrame(repeat);
      } else {
        current.pause();
        cancelAnimationFrame(playAnimationRef.current);
      }
    }
  }, [isPlaying, audioRef, repeat]);

  // Skip forward or backward

  const SkipForward = () => {
    if (activeSongIndex < songsResult.length - 1) {
      localStorage.setItem('asi', JSON.stringify(++activeSongIndex));
      setActiveSong(songsResult[activeSongIndex]);
    } else if (activeSongIndex + 1 === songsResult.length) {
      localStorage.setItem('asi', JSON.stringify(0));
      setActiveSong(songsResult[0]);
    }
    setIsPlaying(true);
  };

  const SkipBackward = () => {
    setActiveSong(songsResult[activeSongIndex]);
    if (activeSongIndex > 0) {
      localStorage.setItem('asi', JSON.stringify(--activeSongIndex));
      setActiveSong(songsResult[activeSongIndex]);
    } else {
      localStorage.setItem('asi', JSON.stringify(songsResult.length - 1));
      setActiveSong(songsResult[songsResult.length - 1]);
    }
    setIsPlaying(true);
  };

  return (
    <RecoilRoot>
      <IdleTimerContainer></IdleTimerContainer>
      <BrowserRouter>
        <Helmet
          titleTemplate="%s - Mage"
          defaultTitle="Mage"
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta charSet="UTF-8" />
          <meta
            name="description"
            content="Mage - We make sure Creators get the larger share of the pie"
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;600;700&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="https://cdn-uicons.flaticon.com/uicons-regular-rounded/css/uicons-regular-rounded.css"
          />
          <script src="https://kit.fontawesome.com/4a8768cdc7.js"></script>
        </Helmet>
        <Routes>
          <Route path={process.env.PUBLIC_URL + '/'} element={<PrivateRoute />}>
            <Route
              // index={true}
              path={process.env.PUBLIC_URL + '/'}
              element={<Dashboard />}
            />
          </Route>
          <Route path={'/sign-up'} element={<PreventToAuth />}>
            <Route path="/sign-up" element={<SignUp />} />
          </Route>
          <Route path={'/login'} element={<PreventToAuth />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path={'/forgot-password'} element={<PreventToAuth />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route path={'/change-password/:email'} element={<PreventToAuth />}>
            <Route path="/change-password/:email" element={<ChangePwd />} />
          </Route>
          <Route path={'/create-pin'} element={<PrivateRoute />}>
            <Route path="/create-pin" element={<CreatePin />} />
          </Route>
          <Route path={'/confirm-pin'} element={<PrivateRoute />}>
            <Route path="/confirm-pin" element={<ConfirmPin />} />
          </Route>
          <Route
            path={'/email-confirmation/:firstName/:id/:email'}
            element={<PreventToAuth />}
          >
            <Route
              path="/email-confirmation/:firstName/:id/:email"
              element={<ConfirmEmail />}
            />
          </Route>
          <Route path={'/store'} element={<NotOnboarding />}>
            <Route path="/store" element={<StoreHome />} />
          </Route>
          <Route path={'/order'} element={<NotOnboarding />}>
            <Route path="/order" element={<Order />} />
          </Route>
          <Route path={'/single-order'} element={<NotOnboarding />}>
            <Route path="/single-order/:id" element={<SingleOrder />} />
          </Route>
          <Route path={'/update-store'} element={<NotOnboarding />}>
            <Route path="/update-store" element={<Preference />} />
          </Route>
          <Route path={'/customers'} element={<NotOnboarding />}>
            <Route path="/customers" element={<Customers />} />
          </Route>
          <Route
            path={'/single-audience/:id/:firstName/:lastName/:email/:phone'}
            element={<NotOnboarding />}
          >
            <Route
              path="/single-audience/:id/:firstName/:lastName/:email/:phone"
              element={<SingleAudience />}
            />
          </Route>
          <Route path={'/product-list'} element={<NotOnboarding />}>
            <Route path="/product-list" element={<ProductList />} />
          </Route>
          <Route path={'/add-merch'} element={<NotOnboarding />}>
            <Route path="/add-merch" element={<AddMerch />} />
          </Route>
          <Route path={'/edit-merch'} element={<NotOnboarding />}>
            <Route path="/edit-merch" element={<EditMerch />} />
          </Route>
          <Route path={'/onboarding'} element={<PrivateRoute />}>
            <Route path="/onboarding" element={<Onboarding />} />
          </Route>
          <Route path={'/set-transaction-pin'} element={<PrivateRoute />}>
            <Route path="/set-transaction-pin" element={<PinSet />} />
          </Route>
          <Route path={'/confirm-pin'} element={<PrivateRoute />}>
            <Route path="/confirm-pin" element={<ConfirmPin />} />
          </Route>
          <Route path={'/setup-account'} element={<PrivateRoute />}>
            <Route path="/setup-account" element={<KYC1 />} />
          </Route>
          <Route path={'/profile'} element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path={'/edit-profile'} element={<NotOnboarding />}>
            <Route path="/edit-profile" element={<EditProfile />} />
          </Route>
          <Route path={'/set-identity'} element={<NotOnboarding />}>
            <Route path="/set-identity" element={<Identity />} />
          </Route>
          <Route path={'/compliance'} element={<NotOnboarding />}>
            <Route path="/compliance" element={<CompliancePage />} />
          </Route>
          <Route path={'/set-security'} element={<NotOnboarding />}>
            <Route path="/set-security" element={<Security />} />
          </Route>
          <Route path={'/invoice'} element={<NotOnboarding />}>
            <Route path="/invoice" element={<Invoice />} />
          </Route>
          <Route path={'/setup-invoice'} element={<NotOnboarding />}>
            <Route path="/setup-invoice" element={<SetUpInv />} />
          </Route>
          <Route path={'/create-invoice'} element={<NotOnboarding />}>
            <Route path="/create-invoice" element={<CreateInv />} />
          </Route>
          <Route path={'/new-invoice'} element={<NotOnboarding />}>
            <Route path="/new-invoice" element={<NewInvoice />} />
          </Route>
          <Route path={'/single-invoice'} element={<NotOnboarding />}>
            <Route path="/single-invoice" element={<SingleInvoice />} />
          </Route>
          <Route path={'/documents'} element={<NotOnboarding />}>
            <Route path="/documents" element={<Documents />} />
          </Route>
          <Route path={'/transactions'} element={<NotOnboarding />}>
            <Route path="/transactions" element={<TransactionsPage />} />
          </Route>
          <Route path={'/payment-link'} element={<NotOnboarding />}>
            <Route path="/payment-link" element={<PaymentLinkPage />} />
          </Route>
          <Route
            path={'/create-payment-link/:selectedType'}
            element={<NotOnboarding />}
          >
            <Route
              path="/create-payment-link/:selectedType"
              element={<CreateLinkPage />}
            />
          </Route>
          <Route path={'/edit-payment-link'} element={<NotOnboarding />}>
            <Route path="/edit-payment-link" element={<EditLink />} />
          </Route>
          <Route path={'/select-payment-type'} element={<NotOnboarding />}>
            <Route path="/select-payment-type" element={<SelectTypePage />} />
          </Route>
          <Route path={'/events'} element={<NotOnboarding />}>
            <Route path="/events" element={<EventPage />} />
          </Route>
          <Route path={'/single-event'} element={<NotOnboarding />}>
            <Route path="/single-event" element={<SinglePage />} />
          </Route>
          <Route path={'/product/:id'} element={<NotOnboarding />}>
            <Route path="/product/:id" element={<SingleMerch />} />
          </Route>
          <Route path={'/split-payment'} element={<NotOnboarding />}>
            <Route path="/split-payment" element={<SplitPage />} />
          </Route>
          <Route path={'/split/:id'} element={<NotOnboarding />}>
            <Route path="/split/:id" element={<SplitSinglePage />} />
          </Route>
          <Route path={'/add-collaborators'} element={<NotOnboarding />}>
            <Route
              path="/add-collaborators"
              element={<CreateSplitFirstPage />}
            />
          </Route>
          <Route path={'/create-split'} element={<NotOnboarding />}>
            <Route path="/create-split" element={<CreateSplitSecondPage />} />
          </Route>
          <Route path={'/split/add-data'} element={<NotOnboarding />}>
            <Route path="/split/add-data" element={<AddData />} />
          </Route>

          <Route path={'/music-split'} element={<NotOnboarding />}>
            <Route path="/music-split" element={<MusicSplitSetup />} />
          </Route>

          <Route path={'/project-split'} element={<NotOnboarding />}>
            <Route path="/project-split" element={<ProjectSplitSetup />} />
          </Route>

          <Route path={'/select-split-type'} element={<NotOnboarding />}>
            <Route path="/select-split-type" element={<SelectSplitType />} />
          </Route>
          <Route path={'/payout-settings'} element={<NotOnboarding />}>
            <Route path="/payout-settings" element={<PayoutSettings />} />
          </Route>
          <Route path={'/split/verify/:id'} element={<NotOnboarding />}>
            <Route path="/split/verify/:id" element={<JoinSplitIdentity />} />
          </Route>
          <Route path={'/split/contract/:id'} element={<NotOnboarding />}>
            <Route path="/split/contract/:id" element={<ContractDisplay />} />
          </Route>
          <Route path={'/edit-collaborators'} element={<NotOnboarding />}>
            <Route
              path="/edit-collaborators"
              element={<EditSplitFirstPage />}
            />
          </Route>
          <Route path={'/edit-split'} element={<NotOnboarding />}>
            <Route path="/edit-split" element={<EditSplitSecondPage />} />
          </Route>
          <Route path={'/split/set-currency'} element={<NotOnboarding />}>
            <Route path="/split/set-currency" element={<SetCurrency />} />
          </Route>
          <Route path={'/split/edit-currency'} element={<NotOnboarding />}>
            <Route path="/split/edit-currency" element={<EditCurrency />} />
          </Route>
          <Route path={'/contact'} element={<NotOnboarding />}>
            <Route path="/contact" element={<Contact />} />
          </Route>

          {/* Transfer Routes */}
          <Route
            path={'/ngn-transfer/:point/:sourceAmount/:amount'}
            element={<NotOnboarding />}
          >
            <Route
              path="/ngn-transfer/:point/:sourceAmount/:amount"
              element={<NGNTransfer />}
            />
          </Route>
          <Route
            path={'/dom-transfer/:point/:sourceAmount/:amount'}
            element={<NotOnboarding />}
          >
            <Route
              path="/dom-transfer/:point/:sourceAmount/:amount"
              element={<DOMTransferComp />}
            />
          </Route>
          <Route
            path={'/wire-transfer/:point/:sourceAmount/:amount'}
            element={<NotOnboarding />}
          >
            <Route
              path="/wire-transfer/:point/:sourceAmount/:amount"
              element={<WireTransferComp />}
            />
          </Route>
          <Route path={'/sender-information'} element={<NotOnboarding />}>
            <Route
              path="/sender-information"
              element={<SenderInformationComp />}
            />
          </Route>
          <Route path={'/reciever-information'} element={<NotOnboarding />}>
            <Route
              path="/reciever-information"
              element={<RecieverInformationComp />}
            />
          </Route>
          <Route path={'/swap-currency/:currency'} element={<NotOnboarding />}>
            <Route path="/swap-currency/:currency" element={<SwapCurrency />} />
          </Route>
          <Route
            path={'/beneficiaries/:point/:sourceAmount/:amount'}
            element={<NotOnboarding />}
          >
            <Route
              path="/beneficiaries/:point/:sourceAmount/:amount"
              element={<Beneficiary />}
            />
          </Route>
          <Route path={'/select-currency'} element={<NotOnboarding />}>
            <Route path="/select-currency" element={<WithdrawPoint />} />
          </Route>
          <Route path={'/split-transfer'} element={<NotOnboarding />}>
            <Route path="/split-transfer" element={<SplitTransfer />} />
          </Route>
          <Route
            path={'/mage-transfer/:point/:sourceAmount/:amount'}
            element={<NotOnboarding />}
          >
            <Route
              path="/mage-transfer/:point/:sourceAmount/:amount"
              element={<MutualWallet />}
            />
          </Route>

          {/* <Route path={'/preview'} element={<NotOnboarding />}>
            <Route path="/preview" element={<PreviewInv />} />
          </Route> */}

          {/* Card Routes */}
          <Route path={'/virtual-cards'} element={<NotOnboarding />}>
            <Route path="/virtual-cards" element={<CardPage />} />
          </Route>
          <Route path={'/request-card'} element={<NotOnboarding />}>
            <Route path="/request-card" element={<RequestCardPage />} />
          </Route>
          <Route path={'/view-card'} element={<NotOnboarding />}>
            <Route path="/view-card" element={<CardViewPage />} />
          </Route>
          <Route path={'/fund-card/:id'} element={<NotOnboarding />}>
            <Route path="/fund-card/:id" element={<FundCardComp />} />
          </Route>
          <Route path={'/withdraw-card/:id'} element={<NotOnboarding />}>
            <Route path="/withdraw-card/:id" element={<WithdrawFundComp />} />
          </Route>
          <Route path={'/card/transactions/:id'} element={<NotOnboarding />}>
            <Route
              path="/card/transactions/:id"
              element={<CardTransactionPage />}
            />
          </Route>

          {/* NOTE: Hidden for now, might be used later in the future or removed */}
          {/* Songs Section */}
          {/* <Route path={'/songs'} element={<NotOnboarding />}>
            <Route path="/songs" element={<SongIndexPge />} />
          </Route>
          <Route path={'/song/create/upload-audio'} element={<NotOnboarding />}>
            <Route path="/song/create/upload-audio" element={<UploadAudio />} />
          </Route>
          <Route path={'/song/:id'} element={<NotOnboarding />}>
            <Route path="/song/:id" element={<SingleSong />} />
          </Route> */}

          {/* Artiste */}
          {/* <Route path={'/artiste'} element={<NotOnboarding />}>
            <Route path="/artiste" element={<ArtistIndexPage />} />
          </Route>

          <Route path={'/artiste/add'} element={<NotOnboarding />}>
            <Route path="/artiste/add" element={<AddArtist />} />
          </Route> */}

          {/* Distributors */}
          {/* <Route path={'/distributor'} element={<NotOnboarding />}>
            <Route path="/distributor" element={<Distributor />} />
          </Route>

          <Route path={'/distributor/add-artiste'} element={<NotOnboarding />}>
            <Route
              path="/distributor/add-artiste"
              element={<DistributeMusic />}
            />
          </Route>

          <Route path={'/distributor/add-services'} element={<NotOnboarding />}>
            <Route path="/distributor/add-services" element={<AddServices />} />
          </Route>

          <Route path={'/distributor/add-songs'} element={<NotOnboarding />}>
            <Route
              path="/distributor/add-songs"
              element={<AddReleaseSongs />}
            />
          </Route> */}

          {/* Artiste */}
          {/* <Route path={'/artiste'} element={<NotOnboarding />}>
            <Route path="/artiste" element={<ArtistIndexPage />} />
          </Route>

          <Route path={'/artiste/add'} element={<NotOnboarding />}>
            <Route path="/artiste/add" element={<AddArtist />} />
          </Route> */}

          {/* Benefits */}

          <Route path={'/benefits/add'} element={<NotOnboarding />}>
            <Route path="/benefits/add" element={<CreateBenefits />} />
          </Route>

          <Route path={'/benefits/config'} element={<NotOnboarding />}>
            <Route path="/benefits/config" element={<BenefitComps />} />
          </Route>

          <Route path={'/benefits/single/:id'} element={<NotOnboarding />}>
            <Route path="/benefits/single/:id" element={<BenefitSingle />} />
          </Route>

          {/* Plan */}
          {/* NOTE: Subscription hidden for now */}
          {/* <Route path={'/upgrade/plan'} element={<NotOnboarding />}>
            <Route path="/upgrade/plan" element={<PlanPage />} />
          </Route> */}

          {/* Plan */}

          <Route path={'/subscription'} element={<NotOnboarding />}>
            <Route path="/subscription" element={<PaymentSuccess />} />
          </Route>

          <Route path={'/deals'} element={<NotOnboarding />}>
            <Route path="/deals" element={<Deal />} />
          </Route>

          <Route path={'/my-deals'} element={<NotOnboarding />}>
            <Route path="/my-deals" element={<MyDeals />} />
          </Route>

          <Route path={'/deals/dealCode=:dealCode'} element={<NotOnboarding />}>
            <Route path="/deals/dealCode=:dealCode" element={<DealView />} />
          </Route>

          <Route
            path={'/deals/dealCode=:dealCode/fund'}
            element={<NotOnboarding />}
          >
            <Route
              path="/deals/dealCode=:dealCode/fund"
              element={<PaymentScreen />}
            />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        {showPlayback === true ? (
          <>
            <PlaybackInterface
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              togglePlayPause={togglePlayPause}
              currentSong={activeSong}
              progressBarRef={progressBarRef}
              audioRef={audioRef}
              duration={duration}
              timeProgress={timeProgress}
              playAnimationRef={playAnimationRef}
              setTimeProgress={setTimeProgress}
              skipForward={SkipForward}
              skipBackward={SkipBackward}
              volume={volume}
              setVolume={setVolume}
            />
            <audio
              src={songsResult.length > 0 && songsResult[activeSongIndex].link}
              ref={audioRef}
              onLoadedMetadata={onLoadedMetadata}
            />
          </>
        ) : null}

        <GlobalStyle />
      </BrowserRouter>
    </RecoilRoot>
  );
}
