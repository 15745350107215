import React, { useState, useEffect } from 'react';
import { ProductImg } from 'app/components/merch/productList/styles';
import { MainWrapper, Section, PriceSection, InfoIcons } from './styles';
import { FlexApart } from './orderContent/styles';
import {
  BigConstraint,
  Card,
  FlexedApart,
  PaddedArea,
} from 'app/components/merch/singleMerch/style';
import {
  FlexArea,
  ProductInfo,
  ProductWrap,
} from './../../customers/mainContent/customersCont/styles';
import { useParams } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import * as Icon from 'react-feather';
import FragmentHeader from 'app/components/storeComp/mainContent/header/fragmentHeader';

const SingleContent = () => {
  type ParamsData = {
    id: string;
  };

  const { id } = useParams<ParamsData>();
  const orders = JSON.parse(localStorage.getItem('orders') || '{}');
  const pageOrder = orders.find(p => p.id === id);
  const [merches, setMerches] = useState<any>([]);
  const [shippingAddress, setShippingAddress] = useState<any>({});

  // Fetch All Product Information

  useEffect(() => {
    var creator = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const option = {
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${creator.tokens.access.token}`,
      },
    };

    const orders = JSON.parse(localStorage.getItem('orders') || '{}');
    const pageOrder = orders.find(p => p.id === id);
    setShippingAddress(pageOrder.user.shippingAddress);

    if (pageOrder) {
      for (let i = 0; i < pageOrder.merches.length; i++) {
        axios
          .get(
            `${process.env.REACT_APP_BASE_ENDPOINT}/merches/${pageOrder.merches[i].merchId}`,
            option,
          )
          .then(res => {
            setMerches(merches =>
              merches.concat({
                name: res.data.name,
                image: res.data.images[0],
                quantity: pageOrder.merches[i].quantity,
                color: pageOrder.merches[i].meta
                  ? pageOrder.merches[i].meta.color
                  : '',
                size: pageOrder.merches[i].meta
                  ? pageOrder.merches[i].meta.size
                  : '',
                baseAmount: res.data.price.amount,
                purchaseAmount:
                  res.data.price.amount * pageOrder.merches[i].quantity,
              }),
            );
          })
          .catch(err => {});
      }
    }
  }, [id]);

  return (
    <>
      <MainWrapper style={{ width: '100%' }}>
        <FragmentHeader
          showBack={true}
          pageName={`Order ${
            pageOrder && pageOrder.orderCode ? pageOrder.orderCode : ''
          }`}
          showHeaderCard={true}
          status={pageOrder.status}
        />
        <BigConstraint>
          <Card>
            <PaddedArea>
              <FlexedApart>
                <h2>Order Details</h2>
                <p>{moment(pageOrder && pageOrder.updatedAt).format('LL')}</p>
              </FlexedApart>
              <Section>
                {merches && merches.length > 0
                  ? merches.map((item, index) => (
                      <ProductWrap key={index}>
                        <FlexArea>
                          <ProductInfo>
                            <ProductImg src={item.image} alt="Product Image" />
                            <div>
                              <h4>{item.name}</h4>
                              <p>
                                {item.quantity} x &#8358;{item.baseAmount}
                              </p>
                            </div>
                          </ProductInfo>
                          <h3>
                            &#8358;{item.purchaseAmount}{' '}
                            <i className="fas fa-angle-right"></i>
                            <br />
                            <p>
                              {item.color && typeof item.color === 'string'
                                ? item.color
                                : ''}

                              {item.color ? ',' : ''}

                              {item.size && typeof item.size === 'string'
                                ? item.size
                                : ''}
                            </p>
                          </h3>
                          <p></p>
                        </FlexArea>
                      </ProductWrap>
                    ))
                  : null}
                <PriceSection>
                  <div>
                    <h5>SubTotal</h5>
                    <h5>
                      &#8358;
                      {pageOrder.totalAmount
                        ? pageOrder.totalAmount.price
                        : null}
                    </h5>
                  </div>
                  <div>
                    <h4>Total</h4>
                    <h4>
                      &#8358;
                      {pageOrder.totalAmount
                        ? pageOrder.totalAmount.price
                        : null}
                    </h4>
                  </div>
                </PriceSection>
              </Section>
            </PaddedArea>
          </Card>

          <Card>
            <PaddedArea>
              {/* <InfoPart> */}
              <FlexedApart>
                <h3>Customer</h3>
                <h5
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  View Customer
                  <Icon.ChevronRight
                    size={18}
                    style={{
                      position: 'relative',
                      top: '0rem',
                    }}
                  />
                </h5>
              </FlexedApart>
              <h3
                style={{
                  fontSize: '14px',
                }}
              >
                Details
              </h3>
              <FlexedApart>
                <div
                  style={{
                    width: '50%',
                  }}
                >
                  <p
                    style={{
                      margin: '0 0 0.5rem 0',
                    }}
                  >
                    {pageOrder && pageOrder.user.firstName
                      ? pageOrder.user.firstName
                      : ''}{' '}
                    {pageOrder && pageOrder.user.lastName
                      ? pageOrder.user.lastName
                      : ''}
                  </p>
                  <p
                    style={{
                      margin: '0 0 0.5rem 0',
                    }}
                  >
                    {pageOrder && pageOrder.user.email
                      ? pageOrder.user.email
                      : ''}
                  </p>
                  <p
                    style={{
                      margin: '0 0 0.5rem 0',
                    }}
                  >
                    {pageOrder && pageOrder.user.phoneNumber
                      ? pageOrder.user.phoneNumber
                      : ''}
                  </p>
                </div>
                <InfoIcons>
                  <div>
                    <a
                      href={`tel:${
                        pageOrder && pageOrder.user.phoneNumber
                          ? pageOrder.user.phoneNumber
                          : ''
                      }`}
                    >
                      <i className="fi fi-rr-call-outgoing"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      href={`mailto:${
                        pageOrder && pageOrder.user.email
                          ? pageOrder.user.email
                          : ''
                      }`}
                    >
                      <i className="fi fi-rr-envelope"></i>
                    </a>
                  </div>
                </InfoIcons>
              </FlexedApart>
              {/* </InfoPart> */}
            </PaddedArea>
          </Card>
          <Card>
            <PaddedArea>
              <FlexApart>
                <h3>Shipping</h3>
                <select
                  style={{
                    padding: '0.5rem 2%',
                    borderRadius: '4px',
                    border: '0.5px solid #cbced1',
                  }}
                >
                  <option>in-production</option>
                  <option>ready for shipping</option>
                  <option>enroute delivery</option>
                  <option>delivered</option>
                </select>
              </FlexApart>
              <h4
                style={{
                  margin: '2rem 0 0 0',
                  fontSize: '14px',
                }}
              >
                Shipping Address
              </h4>
              <p
                style={{
                  margin: '0.5rem 0 0 0',
                }}
              >
                {shippingAddress.address ? shippingAddress.address : null}
              </p>
              <p
                style={{
                  margin: '0.5rem 0 0 0',
                }}
              >
                {shippingAddress.state ? shippingAddress.state : null}
              </p>
              <p
                style={{
                  margin: '0.5rem 0 0 0',
                }}
              >
                {shippingAddress.country ? shippingAddress.country : null}
              </p>
            </PaddedArea>
          </Card>
          {/* <Section>
            <FlexApart>
              <p>Shipping Address</p>
              <div>
                <p>Jack Jones</p>
                <p>48 zen street</p>
                <p>Lagos , Nigeria</p>
                <p>08020883002</p>
              </div>
            </FlexApart>
          </Section>
          <hr style={{ borderBottom: '0.01px solid #c8c8c8' }} /> */}
          <Section></Section>
        </BigConstraint>
      </MainWrapper>
    </>
  );
};

export default SingleContent;
