import styled from 'styled-components';

export const Layer = styled.div`
  background: #f9f4ff;
  width: 100%;
  display: flex;
  justify-content: Center;
  padding: 2rem 0;

  @media (max-width: 1028px) {
    background: #ffffff;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const MainWrapper = styled.div`
  width: 36rem;
  position: relative;
  padding: 1rem 1rem 0 1rem;
  background: #ffffff;
  box-shadow: 0px 0px 1px 2px rgba(244, 248, 255, 0.05);
  border-radius: 8px;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 100%;
`;

export const StatementHeader = styled.div`
  background: linear-gradient(
    269.52deg,
    #edbec0 0.09%,
    rgba(237, 190, 192, 0.3) 100%
  );
  padding: 1rem 5%;
  display: flex;
  align-items: Center;
  justify-content: space-between;
`;

export const InvoiceNoWrap = styled.div`
  width: 20rem;
  position: absolute;
  top: 7.5rem;
  right: 5%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px 10px;

  > div {
    width: 48%;
  }

  h2 {
    font-size: 10px;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const UserInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 2rem 0 0 0;
  gap: 5px;

  > img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }

  > h2 {
    font-size: 1.2rem;
    font-weight: bolder;
  }

  p {
    font-size: 0.8rem;
    color: #757575;
  }
`;

export const BillSection = styled.div`
  margin: 5rem 0 0 0;

  > div {
    margin: 1rem 0 0 0;
    width: 20rem;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 20px 10px;

    > div {
      width: 48%;
    }
  }

  h2 {
    font-size: 10px;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const FlexApart = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemsSection = styled.div`
  margin: 3rem 0;
`;
interface Props {
  customBg: any;
}

export const ItemRow = styled.div<Props>`
  display: Flex;
  justify-content: space-between;
  background: ${p => p.customBg || '#ffffff'};
  margin: 1rem 0 0 0;
  border-radius: 8px;
  color: #161616;

  h2 {
    font-size: 8px;
    font-weight: 500;
  }

  p {
    font-size: 12px;
  }
`;

export const Description = styled.div`
  width: 25%;
  padding: 2%;
`;

export const SmallColumn = styled.div`
  width: 10%;
  padding: 2%;
  text-align: center;
`;

export const BillingInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  > div {
    width: 40%;

    > div {
      width: 100%;
      margin: 0 0 0.4rem 0;
      display: flex;
      justify-content: space-between;

      > p {
        font-size: 12px;
        font-weight: 400;
      }

      > h2 {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
`;

export const ActionBtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: Center;
  font-size: 0.9rem;
  margin: 6rem 0 0 0;
`;
interface PropsArgs {
  customBg: any;
  customCl: any;
}

export const ActionBtn = styled.div<PropsArgs>`
  width: 35%;
  border-radius: 8px;
  padding: 0.5rem 5%;
  text-align: Center;
  cursor: pointer;
  background: ${p => p.customBg || '#ffffff'};
  color: ${p => p.customCl || '#000000'};
`;

export const TradeMark = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 5%;

  > img {
    height: 7rem;
  }

  P {
    color: #757575;
    font-size: 0.8rem;
  }
`;

export const UnderText = styled.p`
  padding: 3rem 5%;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  text-align: center;
  color: #ababab;
`;
