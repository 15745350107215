import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from 'app/components/transactions/style';
import { AccountNoCenter, TypeCard } from './style';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { useNavigate } from 'react-router-dom';

const SelectSplitType = () => {
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(-1);

  useEffect(() => {
    localStorage.setItem('deixn', JSON.stringify(0));
  }, []);

  // To next page
  const toNext = () => {
    if (activeItem === 1) {
      navigate('/music-split');
    } else if (activeItem === 2) {
      navigate('/benefits/add');
    } else {
      navigate('/project-split');
    }
  };

  return (
    <>
      <MainWrapper style={{ width: '100%' }} left="0" top="4.5rem">
        <FragmentHeader
          showBack={true}
          pageName={`Select split type`}
          backLink="/split-payment"
        />
        <form>
          <SearchPos position="fixed"></SearchPos>
          <BreadcrumbArea
            style={{
              margin: '6rem 0 10rem 0',
            }}
          >
            <AccountNoCenter
              style={{
                margin: '0',
              }}
            >
              <div>
                <h3>Select Split Type</h3>
                <p>What kind of split do you want to create?</p>
              </div>
            </AccountNoCenter>
            <TypeCard>
              {types.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setActiveItem(index)}
                  style={
                    activeItem === index
                      ? {
                          border: '1px solid #8975F3',
                        }
                      : {}
                  }
                >
                  <img src={item.image} alt={item.image} />
                  <div>
                    <h3>{item.name}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </TypeCard>
            <MainFlex
              style={{
                margin: '0',
              }}
            >
              <PairSection width="35%" mobileMargin="0">
                <div
                  style={{
                    boxShadow: 'none',
                    background: 'none',
                  }}
                >
                  <BigBtn
                    style={{
                      width: '100%',
                    }}
                  >
                    <button
                      disabled={activeItem < 0 ? true : false}
                      style={
                        activeItem < 0
                          ? {
                              background: '#757575',
                            }
                          : {}
                      }
                      onClick={() => toNext()}
                    >
                      Continue
                    </button>
                  </BigBtn>
                </div>
              </PairSection>
            </MainFlex>
          </BreadcrumbArea>
        </form>
      </MainWrapper>
    </>
  );
};

export default SelectSplitType;

export const splitNav = [
  {
    name: 'Percentage',
    borderRadius: '4px 0 0 4px',
  },
  {
    name: 'Amount',
    borderRadius: '0 4px 4px 0',
  },
];

const types = [
  {
    name: 'Project ',
    text: 'Create a custom split for collaboration.',
    image: '/box.png',
  },
  {
    name: 'Music Royalties',
    text: 'Split royalties earned from distros.',
    image: '/music.png',
  },
  // {
  //   name: 'Benefits',
  //   text: 'Add an Health Insurance subscription.',
  //   image: '/benefits.png',
  // },
];
