import { useState } from 'react';
import axios from 'axios';
import { MainWrapper } from './../storeComp/mainContent/styles';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from '../transactions/style';
import { ButtonWrap } from '../storeComp/mainContent/sectionOne/styles';
import {
  AccountNoCenter,
  ProgressBar,
  ProgressLine,
  TypeCard,
} from '../split/style';
import { useNavigate } from 'react-router-dom';
import BenefitsRevenue from './benefitsRevenue';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import CreateWarning from '../split/createWarning';
import SplitSuccess from '../split/success';
import { useSelector } from 'react-redux';
import { RootState } from 'store/configureStore';
import Benefit from 'app/components/Benefits/interface';

const BenefitComps = () => {
  const navigate = useNavigate();

  const selectedBenefit = useSelector(
    (state: RootState) => state.selectedBenefit,
  ) as Benefit;

  const [openBenefitsRevenue, setBenefitsRevenue] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [selectedFrequency, setSelectedFrequency] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openWarning, setOpenWarning] = useState(false);
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);

  const payload = {
    amount: selectedAmount,
    frequency: selectedFrequency,
    startDate: startDate,
    ...(endDate && { endDate: endDate }),
    benefitType: selectedBenefit?.id,
    ...(selectedId && { option: selectedId }),
  };

  const createBenefit = () => {
    setOnProcess(true);
    axios
      .post(`${process.env.REACT_APP_BASE_ENDPOINT}/benefits`, payload)
      .then(res => {
        setOnProcess(false);
        setOpenWarning(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
          navigate('/');
        }, 2000);
      })
      .catch(err => {});
  };

  return (
    <>
      {success ? (
        <>
          <SplitSuccess
            closeFunc={() => navigate('/')}
            message={`${
              selectedBenefit?.name || 'Benefit'
            } has been created successfully`}
            img="/success.png"
            accNo={''}
            accName={''}
            bank={''}
          />
        </>
      ) : null}
      {openWarning ? (
        <>
          <CreateWarning
            closeFunc={() => setOpenWarning(false)}
            actionInProcess={onProcess}
            handleAction={createBenefit}
            message={`Are you sure you want to create ${selectedBenefit?.name}?`}
            actionText={`Create Benefit`}
            splitName={selectedBenefit?.name}
            splitType={''}
            limitValue={''}
            frequency={''}
            avatar={selectedBenefit?.avatar}
            collaborators={''}
            currency={undefined}
          />
        </>
      ) : null}
      <MainWrapper style={{ width: '100%' }} left="0" top="4.5rem">
        <FragmentHeader showBack={true} pageName={`Benefits Configuration`} />
        <SearchPos position="fixed">
          <ButtonWrap mobileHide={false}></ButtonWrap>
        </SearchPos>
        <BreadcrumbArea
          style={{
            margin: '4rem 0 10rem 0',
          }}
        >
          <ProgressBar>
            <ProgressLine
              onClick={() => {
                navigate('/benefits/add');
              }}
            ></ProgressLine>
            <ProgressLine bg="#191919"></ProgressLine>
            <ProgressLine></ProgressLine>
          </ProgressBar>
          <AccountNoCenter
            style={{
              margin: '0',
            }}
          >
            <div>
              <h3>Select Benefits choice</h3>
              <p
                style={{
                  margin: '0',
                  padding: '0.5rem 0',
                }}
              >
                List of Benefits Below
              </p>
            </div>
          </AccountNoCenter>
          <TypeCard>
            {selectedBenefit && (
              <>
                <div
                  onClick={e => {
                    e.stopPropagation();
                    setBenefitsRevenue(true);
                  }}
                >
                  <img
                    src={selectedBenefit.avatar || '/artist.png'}
                    alt={selectedBenefit.name}
                  />
                  <div>
                    <h3>{selectedBenefit.name}</h3>
                    <p>{selectedBenefit.description}</p>
                  </div>
                  <p>&#8358;{selectedAmount.toLocaleString()}</p>
                </div>
              </>
            )}
          </TypeCard>
          {openBenefitsRevenue ? (
            <>
              <BenefitsRevenue
                closeFunc={() => {
                  setBenefitsRevenue(false);
                }}
                item={{
                  option: selectedOption,
                  id: selectedId,
                  amount: selectedAmount,
                  frequency: selectedFrequency,
                  startDate: startDate,
                }}
                setSelectedId={setSelectedId}
                setSelectedOption={setSelectedOption}
                setSelectedAmount={setSelectedAmount}
                setSelectedFrequency={setSelectedFrequency}
                addStartDate={setStartDate}
                addEndDate={setEndDate}
              />
            </>
          ) : null}

          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <BigBtn>
              <button onClick={() => setOpenWarning(true)}>Continue</button>
            </BigBtn>
          </div>
        </BreadcrumbArea>
      </MainWrapper>
    </>
  );
};

export default BenefitComps;
