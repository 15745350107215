import styled from 'styled-components';

export const UploadArea = styled.div`
  margin: 2rem auto 0 auto;
  border: 2px dashed #dfe4eb;
  padding: 25px 20px;
  width: 45%;
  // border-image-source:url("https://i.stack.imgur.com/wLdVc.png");
  border-image-width: 1;
  border-image-slice: 1;
  // border-image-repeat: round;
  // border-image-background: red;
  border-radius: 10px;

  @media (max-width: 728px) {
    width: 100%;
    margin-top: 50px;
  }
`;

export const UploadBoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: Center;
  gap: 10px;

  button {
    background: #161616;
    padding: 8px 25px;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
  }

  > img {
    width: 5rem;
  }

  p {
    color: #161616;
  }

  > label {
    > p {
      background: #161616;
      padding: 8px 25px;
      color: #fff;
      border-radius: 8px;
      font-size: 12px;
      cursor: pointer;

      :hover {
        opacity: 0.6;
      }
    }
  }
`;

export const ProgressLine = styled.progress`
  width: 100%;
`;

export const BottomMusicPlayer = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px 2%;
  // height:4rem;
  background: #161616;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    width: 100%;
  }

  @media (max-width: 728px) {
    padding: 15px 2% 50px 2%;
  }
`;

interface MusicInfoProps {
  imgSize?: string;
  textColor?: string;
  width?: string;
}

export const MusicInfo = styled.div<MusicInfoProps>`
  display: flex;
  align-items: Center;
  gap: 10px;
  width: ${p => p.width || '95%'};

  > img {
    width: ${p => p.imgSize || '48px'};
    height: 48px;
    border-radius: 4px;
    object-fit: cover;
  }

  > div {
    width: 80%;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: ${p => p.textColor || '#161616'};
  }

  p {
    font-size: 12px;
    color: #b2b2b2;
    font-weight: 300;
  }

  @media (max-width: 728px) {
    width: 50%;
  }
`;

export const MusicPlayerActionWrap = styled.div`
  width: 40%;
  // position: relative;
  display: flex;
  flex-direction: column;
  align-items: Center;

  @media (max-width: 728px) {
    width: 40%;
    position: none;
  }
`;

export const MusicPlaybackWrap = styled.div`
  display: flex;
  align-items: Center;
  gap: 15px;

  > svg {
    cursor: pointer;

    :hover {
      opacity: 0.6;
    }
  }
`;

export const PlayButton = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: Center;
  justify-content: Center;
  border-radius: 10rem;
  background: #fff;
  cursor: pointer;

  :hover {
    opacity: 0.6;
  }
`;

export const MusicVolumeSection = styled.div`
  width: 25%;
  display: flex;
  justify-content: flex-end;

  > div {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 50%;
  }

  svg {
    cursor: pointer;

    :hover {
      opacity: 0.6;
    }
  }

  @media (max-width: 728px) {
    display: none;
  }
`;

export const MusicProgressWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0.5rem 0 0 0;

  p {
    font-size: 12px;
    color: #fff;
    width: 7%;
  }

  @media (max-width: 728px) {
    position: absolute;
    bottom: 0.5rem;
    justify-content: center;
    left: 0;
    padding: 0 5%;

    p {
      width: 15%;
    }
  }
`;

export const MusicProgress = styled.div`
  width: 95%;
  border-radius: 4px;

  > div {
    height: inherit;
    border-radius: inherit;
    width: 40%;
    background: #b2b2b2;
  }
`;

export const ShareHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.2px solid #d9d9d9;
  margin: 2rem 0 0 0;
  padding: 0 0 10px 0;

  > div {
    text-align: center;
    font-size: 12px;
    color: #ababab;
    padding: 0 15%;
  }
`;

export const ShareLine = styled.div`
  display: flex;
  align-items: Center;
  margin: 1rem 0 0 0;
  justify-content: space-between;
  position: relative;
`;

export const ShareInfo = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  gap: 5px;

  > div:first-child {
    width: 50px;
    height: 50px;
    border-radius: 10rem;
    background: rgba(246, 244, 255, 0.8);
    position: relative;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > div:nth-child(2) {
    width: 70%;

    > h3 {
      font-size: 14px;
    }

    > p {
      font-size: 12px;
      color: #010d1c50;
    }
  }
`;

export const PermissionSelector = styled.div`
  display: flex;
  gap: 10px;
`;

interface ProgressText {
  text: string;
}

export const BottomProgress = styled.div<ProgressText>`
  position: fixed;
  height: 3rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fbf5ff;

  > progress[value] {
    height: 3rem;
    margin: 0;
    background: #f3e1fc;
    border-radius: 0 5px 5px 0;
  }

  > progress[value]::-webkit-progress-bar {
    border-radius: 0 5px 5px 0;
    background: #fbf5ff;
  }

  > progress[value]::-webkit-progress-value {
    border-radius: 0 5px 5px 0;
    background: #f3e1fc;
  }

  > progress[value]::-moz-progress-bar {
    border-radius: 0 5px 5px 0;
    background: #f3e1fc;
  }

  > p {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    width: 25%;
    display: flex;
    color: #765cff;
    justify-content: flex-start;
    padding-left: 50px;
    font-weight: 500;
  }

  @media (max-width: 728px) {
    > p {
      width: 70%;
    }
  }
`;

export const LyricsTextBox = styled.div`
  width: 40%;
  margin: 4rem auto 2rem auto;
  box-shadow: 0px 4px 50px 0px #0000001a;
  border: 1px solid #fdfdfd;
  border-radius: 10px;
  height: 50vh;

  @media (max-width: 728px) {
    width: 100%;
  }
`;
