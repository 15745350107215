import { useState, useEffect, useCallback } from 'react';
import { MainWrapper } from '../storeComp/mainContent/styles';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { GenericBtn, GenericAppHeader } from './style';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import BVNModal from './bvn';
import Id from './id';
import * as Icon from 'react-feather';
import AppHeader from '../storeComp/mainContent/header/header';
import { useNavigate } from 'react-router';
import { Constraint } from './../invoice/mainInvoice/style';
import { InputWrap } from '../dashboard/mainContent/fundWallet/style';
import AddressModal from './addressModal';
import axios from 'axios';
import CaptureImage from './CaptureImage/captureImage';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { handleFileUpload } from 'utils/imageUpload';

const Identity = () => {
  const history = useNavigate();
  const [openBVN, setOpenBVN] = useState<any>(false);
  const [openId, setOpenId] = useState<any>(false); // address section
  const [street, setStreet] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [openAddress, setOpenAddress] = useState<any>(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [bvnKycStatus, setBvnKycStatus] = useState<any>({});
  const [idKycStatus, setIdKycStatus] = useState<any>({});
  const [noClickBvn, setNoClickBvn] = useState(false);
  const [noClickKyc, setNoClickKyc] = useState(false);

  function toggleAddress() {
    setOpenAddress(!openAddress);
  }

  function toggleUpload() {
    setOpenUpload(!openUpload);
  }

  function toggleBVN() {
    if (
      bvnKycStatus.status.toLowerCase() === 'rejected' ||
      bvnKycStatus.status.toLowerCase() === 'pending'
    ) {
      setOpenBVN(!openBVN);
      return `${city} ${street}`;
    } else {
      setNoClickBvn(true);
    }
  }
  function toggleId() {
    if (
      (idKycStatus.status === 'rejected' || idKycStatus.status === 'pending') &&
      bvnKycStatus.status.toLowerCase() === 'verified'
    ) {
      toggleUpload();
    } else {
      setNoClickKyc(true);
    }
  }

  const getStatusColor = (string: string) => {
    if (string && string.toLowerCase() === 'pending') {
      return '#FBBC04';
    } else if (string && string.toLowerCase() === 'verified') {
      return '#34A853';
    } else {
      return '#757575';
    }
  };

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const getStatus = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/users/kyc-status`, {
        headers: { Authorization },
      })
      .then(res => {
        setBvnKycStatus(res.data.bvn);
        setIdKycStatus(res.data.document);
      })
      .catch(() => {});
  }, [Authorization]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('logged_user') || '{}');
    if (userData.user.address && 'address' in userData.user) {
      setStreet(userData.user.address.street);
      setCity(userData.user.address.city);
      setState(userData.user.address.state);
      setCountry(userData.user.address.country);
    }

    getStatus();
  }, [getStatus]);

  const backNav = () => {
    history(-1);
  };

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  // User Authentication
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [uploadInProcess, setUploadInProcess] = useState(false);

  const extraImageFunc = useCallback(
    data => {
      axios
        .patch(
          `${process.env.REACT_APP_BASE_ENDPOINT}/users/${loggedUser.user.id}`,
          { avatar: data },
          headers,
        )
        .then(res => {
          setOpenUpload(false);
          setOpenId(true);
          setUploadInProcess(false);
        })
        .catch(err => {
          setUploadInProcess(false);
          setError(err.response.data.message);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    },
    [headers, loggedUser],
  );

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setError,
      extraFunc: extraImageFunc,
      setUploadInProcess,
    });
  };

  return (
    <>
      <MainWrapper style={{ width: '100%' }} left="0" bg="#fff">
        <AppHeader showBack={true} pageName={`Identity`} backLink="/profile" />
        <GenericAppHeader onClick={() => backNav()}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '10px',
            }}
          >
            <Icon.ChevronLeft />
            <h3>Identity</h3>
          </div>
        </GenericAppHeader>
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              width="35%"
              top="-4rem"
              style={{
                boxShadow: 'none',
              }}
            >
              <InputWrap style={{ width: '100%' }}>
                <GenericBtn
                  onClick={() => toggleBVN()}
                  style={
                    noClickBvn
                      ? {
                          cursor: 'not-allowed',
                        }
                      : {
                          cursor: 'pointer',
                        }
                  }
                >
                  <div>
                    <p>
                      BVN
                      <span
                        style={{
                          color: getStatusColor(
                            bvnKycStatus.status &&
                              bvnKycStatus.status.toLowerCase(),
                          ),
                        }}
                      >
                        {bvnKycStatus.status &&
                          bvnKycStatus.status.toLowerCase()}
                      </span>
                    </p>
                    <Icon.ChevronRight />
                  </div>
                </GenericBtn>
              </InputWrap>
              <InputWrap style={{ width: '100%' }}>
                <GenericBtn
                  onClick={() => toggleId()}
                  style={
                    noClickKyc
                      ? {
                          cursor: 'not-allowed',
                        }
                      : {
                          cursor: 'pointer',
                        }
                  }
                >
                  <div>
                    <p>
                      ID Document
                      <span
                        style={{
                          color: getStatusColor(
                            idKycStatus.status &&
                              idKycStatus.status.toLowerCase(),
                          ),
                        }}
                      >
                        {idKycStatus.status && idKycStatus.status.toLowerCase()}
                      </span>
                    </p>
                    <Icon.ChevronRight />
                  </div>
                </GenericBtn>
              </InputWrap>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>

        {/* Modals */}
        {openBVN ? (
          <BVNModal
            closeFunc={() => {
              toggleBVN();
              getStatus();
            }}
          />
        ) : null}
        {openId ? (
          <Id
            closeFunc={() => {
              setOpenId(false);
              getStatus();
            }}
            nextFunc={() => setSuccess(true)}
          />
        ) : null}
        {openAddress ? (
          <AddressModal
            closeFunc={toggleAddress}
            setStreet={setStreet}
            setState={setState}
            setCity={setCity}
            state={state}
            country={country}
            setCountry={setCountry}
          />
        ) : null}
        {openUpload ? (
          <CaptureImage
            closeFunc={() => {
              setOpenUpload(false);
              getStatus();
            }}
            onImage={image => {}}
            onSubmit={handleFile}
            onProcess={uploadInProcess}
            error={error}
          />
        ) : null}
      </MainWrapper>

      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            getStatus();
          }}
          img="/success.png"
          message="Success!"
          miniMessage="You have successfully uploaded your Id for verification."
        />
      ) : null}
    </>
  );
};

export default Identity;
