import styled from 'styled-components';

interface MenuHeaderProps {
  color?: string;
  border?: string;
}

interface MusicInfoProps {
  imgSize?: string;
  textColor?: string;
  width?: string;
}

export const MenuHeader = styled.div`
  display: flex;
  align-items: Center;
  gap: 30px;

  @media (max-width: 728px) {
    margin: 1rem 0 0 0;
  }
`;

export const TextWrapper = styled.div`
  width: auto;

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const MenuHeaderText = styled.div<MenuHeaderProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  padding: 0.3rem 0;
  color: ${p => p.color || '#b0b0b0'};
  border-bottom: ${p => p.border || 'none'};
  transition: 0.2s;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const DisplayPages = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 20px;
  margin-top: 10px;
`;

export const MusicInfo = styled.div<MusicInfoProps>`
  display: flex;
  align-items: Center;
  gap: 10px;
  width: ${p => p.width || '95%'};

  > img {
    width: ${p => p.imgSize || '48px'};
    height: 48px;
    border-radius: 4px;
    object-fit: cover;
  }

  > div {
    width: 80%;
  }

  h3 {
    font-size: 14px;
    font-weight: 500;
    color: ${p => p.textColor || '#161616'};
  }

  p {
    font-size: 12px;
    color: #b2b2b2;
    font-weight: 300;
  }
`;

export const DistributeLink = styled.div`
  width: 95%;
  height: 35px;
  border: 2px solid #8975f3;
  border-radius: 5px;
  background-color: rgba(163, 146, 255, 0.13);
  display: flex;
  align-items: center;
  justify-content: space-around;

  p {
    margin-top: -3px;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  img {
    cursor: pointer;

    :hover {
      opacity: 0.6;
    }
  }
`;

export const FormFilled = styled.form`
  width: 70%;
  margin: 0 auto;

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const IsrcInputPart = styled.main`
  margin-top: 20px;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const ArtistBandname = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 16px;
  background: #fff;
  border: 0.2px solid #dadada;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  border-style: solid;
  border-color: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 55%;

  p {
    color: #161616;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }

  @media (max-width: 728px) {
    margin-top: 40px;
    width: 100%;
    border-radius: 10px;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 10px;
    height: auto;

    > div > input {
      width: 100%;
    }
  }
`;

export const StreamingPlatform = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 16px;
  background: #fff;
  border: 0.2px solid #dadada;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  gap: 110px;

  @media (max-width: 728px) {
    margin-top: 40px;
    width: 100%;
    padding: 20px 10px;
    gap: 20px;
    border-radius: 10px;
    flex-direction: column;
    height: auto;
  }
`;

export const PlatFormPart = styled.div`
  width: 40%;
  padding-bottom: 20px;

  @media (max-width: 728px) {
    width: 90%;
  }
`;

export const ArtisArea = styled.div`
  width: 40%;
  padding-bottom: 20px;

  @media (max-width: 728px) {
    width: 90%;
  }

  > div {
    margin-left: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 3px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    p {
      font-size: 12px;

      > span {
        font-size: 16px;
      }
    }
  }

  span {
    color: #007aff;
  }

  input#text {
    width: 90%;
    margin-left: 30px;
  }

  p {
    font-size: 18px;
  }
`;

export const PlatFormImage = styled.div`
  width: auto;
  height: auto;
  padding: 10px;

  img {
    width: 100px;
  }
`;

export const PlatFormDescription = styled.div`
  margin-left: 3px;
  p {
    font-size: 15px;
  }
`;

export const ServicePartArea = styled.div`
  margin-top: 20px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 16px;
  border: 0.2px solid #dadada;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.02);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const ServiceArea = styled.div`
  width: auto;
  padding: 0 40px;
  margin-bottom: -10px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0 10px;
  }
`;

export const ServiceTextfilled = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media (max-width: 728px) {
    margin-left: 10px;
  }

  h3 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
  }

  p {
    color: #ababab;
  }
`;

export const AddPlatform = styled.div`
  width: 100%;
  height: auto;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
`;
export const QuestionForm = styled.div`
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 728px) {
    padding: 2px;
  }
`;

export const QuestionFormtext = styled.div`
  width: 45%;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #161616;
  display: flex;
  align-items: center;

  p {
    font-size: 18px;
  }

  @media (max-width: 728px) {
    p {
      font-size: 14px;
    }
  }
`;
export const QuestionFormBullet = styled.div`
  width: 20%;
  padding-top: 5px;
  padding-bottom: 5px;

  @media (max-width: 728px) {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const OtherInputOption = styled.div`
  width: 45%;
  padding-top: 5px;
  padding-bottom: 5px;
  /* border: 1px solid red; */

  @media (max-width: 728px) {
    width: 100%;
    padding: 10px;
  }
`;

export const Reducer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #215aff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  span {
  }

  div:last-child {
    width: 40px;
    height: 40px;
    background-color: #215aff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const ButtonOption = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #f1f1f1;
  border-radius: 5px;

  button {
    width: 45%;
    height: 80%;
    border-radius: 5px;
    background-color: none;
  }
`;

export const TopAddContent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 5px;
`;

export const AddedTag = styled.div`
  width: 19.5%;
  padding: 20px;
  height: 40px;
  border-radius: 25px;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  margin-bottom: 20px;
  background-color: black;

  > p {
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media (max-width: 728px) {
    width: 49%;
  }
`;

export const ButtonAddtag = styled.div`
  width: 140px;
  height: 45px;
  border: 2px solid #007aff;
  background-color: #d7ebff;
  color: #007aff;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;

  @media (max-width: 728px) {
  }
`;

export const BottomAddContent = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 728px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: baseline;
    gap: 5px;
  }
`;

export const StraightLine = styled.div`
  width: 100%;
  border-bottom: 0.2px solid #dadada;
`;

export const UploadArea = styled.div`
  width: 100%;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: -15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 50px;

  @media (max-width: 728px) {
    flex-direction: column;
  }
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: Center;
  justify-content: center;
  gap: 10px;
  border: 2px dashed #dfe4eb;
  padding: 20px 50px;
  background-color: #f5f5f5;
  width: 100%;

  button {
    background: #161616;
    padding: 8px 25px;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
  }

  > img {
    width: 6rem;
  }

  p {
    width: 100%;
    text-align: Center;
    color: #8e9cab;
    font-size: 10px;
  }

  > label {
    > p {
      background: #161616;
      padding: 8px 25px;
      color: #fff;
      border-radius: 8px;
      font-size: 12px;
      cursor: pointer;

      :hover {
        opacity: 0.6;
      }
    }
  }
`;

export const UploadDescription = styled.div`
  width: 700px;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  /* border: 1px solid black; */
  color: #757575;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const UploadBoxDiv = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  gap: 30px;
  border-radius: 20px;
  background-color: #f5f5f5;
  align-items: center;

  @media (max-width: 728px) {
    flex-direction: column;
  }

  button {
    background: #161616;
    padding: 8px 25px;
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
  }

  > img {
    width: 5rem;
  }

  p {
    color: #8e9cab;
  }

  > label {
    > p {
      background: #161616;
      padding: 8px 25px;
      color: #fff;
      border-radius: 8px;
      font-size: 12px;
      cursor: pointer;

      :hover {
        opacity: 0.6;
      }
    }
  }
`;

export const InputFleWrap = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: 728px) {
    flex-direction: column;
  }
`;

export const BottomActionWrap = styled.div`
  padding: 15px 5% 15px 5%;
  background: #161616;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 100000000;
`;

export const ProgressStatic = styled.div`
  display: flex;
  align-items: Center;
  gap: 10px;

  > p {
    font-size: 14px;
  }

  > div {
    width: 50px;
    height: 50px;
  }
`;
