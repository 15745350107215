import React, { useState } from 'react';
import {
  ModalArea,
  ModalChild,
  Header,
  BigBtn,
  CloseBtn,
} from './../dashboard/mainContent/fundWallet/style';
import axios from 'axios';
import PopUpModal from '../merch/popupModal';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import * as Icon from 'react-feather';
import { TicketInput } from '../paymentLink/style';
import { AddPhotoWrapper, AddPhotoBox } from '../merch/addMerch/styles';
import { BeatLoader } from 'react-spinners';
import { handleFileUpload } from 'utils/imageUpload';

const CACDocument = ({ closeFunc }) => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  const [rcNumber, setRcNumber] = useState('');
  const [tin, setTin] = useState('');

  // Image Format and more.
  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [onProcess, setOnProcess] = useState(false);

  // const [images, setImages] = useState<any>([]);
  const [selectPoint, setSelectPoint] = useState('');
  const [returnedImages, setReturnedImages] = useState<any>('');

  const extraImageFunc = data => {
    if (selectPoint === 'first') {
      setReturnedImages(data);
    }
  };

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setError: setErrorMessage,
      setReturnedImages,
      extraFunc: extraImageFunc,
    });
  };

  const handleClose = () => {
    setErrorMessage('');
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const basicPayload = {
    rcNumber,
    tin,
    image: `${returnedImages}`,
  };

  // Verification of Id and Sending
  const handleUpdateId = e => {
    e.preventDefault();
    if (rcNumber && tin && returnedImages && returnedImages !== '') {
      setOnProcess(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/users/business-kyc-cac-update`,
          basicPayload,
          headers,
        )
        .then(res => {
          closeFunc();
          setSuccess(true);
          setOnProcess(false);
        })
        .catch(err => {
          setErrorMessage(err.response.data.message);
          setOnProcess(false);
          setTimeout(() => {
            setErrorMessage('');
          }, 4000);
        });
    } else {
      setErrorMessage('Please fill all input fields!');
    }
  };
  return (
    <>
      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured!"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}
      <ModalArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Header>CAC Document</Header>
          <form onSubmit={e => handleUpdateId(e)}>
            <TicketInput>
              <div>
                <p>RC Number</p>
                <input
                  type="number"
                  placeholder="RC Number"
                  onChange={e => setRcNumber(e.target.value)}
                  value={rcNumber}
                  required={true}
                />
              </div>
            </TicketInput>
            <TicketInput>
              <div>
                <p>TIN</p>
                <input
                  type="number"
                  placeholder="TIN"
                  onChange={e => setTin(e.target.value)}
                  value={tin}
                  required={true}
                />
              </div>
            </TicketInput>
            <AddPhotoWrapper
              style={
                returnedImages !== ''
                  ? {
                      backgroundImage: `url(${returnedImages})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      margin: '0 0 0.5rem 0',
                      width: '100%',
                      justifyContent: 'center',
                      height: '10rem',
                      position: 'relative',
                    }
                  : {
                      margin: '0 0 0.5rem 0',
                      width: '100%',
                      justifyContent: 'center',
                      height: '7rem',
                      position: 'relative',
                      background: '#f9f9f9',
                    }
              }
            >
              <AddPhotoBox
                id="drop_zone"
                style={{
                  height: '3rem',
                  width: '3rem',
                  background: 'rgba(0, 0, 0, 0.4)',
                  border: 'none',
                  borderRadius: '10rem',
                  position: 'absolute',
                  top: '40%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  cursor: 'pointer',
                }}
              >
                <label
                  htmlFor="main-input"
                  onClick={() => setSelectPoint('first')}
                >
                  <Icon.Camera color="#fff" />
                </label>
                <input
                  id="main-input"
                  type="file"
                  multiple={false}
                  style={{ display: 'none' }}
                  onChange={e => {
                    handleFile(e.target.files);
                  }}
                ></input>
              </AddPhotoBox>
              <p
                style={{
                  position: 'absolute',
                  bottom: '1rem',
                  fontSize: '12px',
                }}
              >
                Upload image of CAC Document
              </p>
            </AddPhotoWrapper>
            {errorMessage !== '' ? (
              <p style={{ color: 'red', width: '20rem' }}>{errorMessage}</p>
            ) : null}
            <BigBtn>
              <button type="submit">
                {onProcess ? <BeatLoader color="#fff" /> : 'Submit'}
              </button>
            </BigBtn>
          </form>
        </ModalChild>
      </ModalArea>

      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            closeFunc();
          }}
          img="success.png"
          message="Changes made successfully!"
          miniMessage="You have successfully updated your profile informations."
        />
      ) : null}
    </>
  );
};

export default CACDocument;
