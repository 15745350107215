import { useEffect, useState } from 'react';
import {
  FixedRightBottom,
  ActionHeader,
  RoleCardWrap,
  RoleCard,
  ContractTypeList,
  InputWrap,
  InputActPlacehold,
  CollabSuggestWrap,
  SplitUsersWrap,
} from './style';
import { FlexedApart } from '../storeComp/sideNav/style';
import { BlueText } from '../editProfile/style';
import { TicketInput } from '../paymentLink/style';
import * as Icon from 'react-feather';
import * as IconSax from 'iconsax-react';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { Line } from '../invoice/mainInvoice/style';
import { SplitAvatar } from './style';
import { BeatLoader, ClipLoader } from 'react-spinners';
import { handleZero } from 'utils/handleZero';
import { numOnly } from 'utils/numOnly';
import RoleInput from './roleInput';
import SplitContractSheet from './contractInterface';
import { handleFileUpload } from 'utils/imageUpload';
import {
  MobileInput,
  MobileInputWrap,
  MobileSelect,
} from 'app/pages/SignUp/styles';
import ReactCountryFlag from 'react-country-flag';

const RevenueSettings = ({
  closeFunc,
  item,
  index,
  addPriorityAmount,
  addPercentage,
  mode,
  type,
  patchCollaborator,
  onProcess,
  addRole,
  deleteRole,
  splitCollaborators,
  addContract,
  deleteContract,
  addFrequency,
  addStartDate,
  addWeek,
  addCycleEnd,
  addPaymentDue,
  addPayoutDate,
  addCurency,
  addTime,
}) => {
  const [showRevenue, setShowRevenue] = useState(true);
  const [showRole, setShowRole] = useState(false);
  const [showContract, setShowContract] = useState(false);
  const [showRecoup, setShowRecoup] = useState(false);
  const [roleOption, setRoleOption] = useState(false);
  const [showContractOptions, setShowContractOptions] = useState(false);
  const [pdf, setPdf] = useState<string>('');
  const [pdfMask, setPdfMask] = useState('');
  const [error, setError] = useState('');
  const [selectedContractOption, setSelectedContractOption] = useState<
    string | number
  >(0);
  const [fetching, setFetching] = useState(false);
  const [showContractSheet, setShowContractSheet] = useState(false);
  const [firstFlag, setFirstFlag] = useState('NG');
  const [firstCurrency, setFirstCurrency] = useState('NGN');
  const [isInputFocus, setIsInputFocus] = useState(false);

  useEffect(() => {
    if (item.amount) {
      setShowRecoup(true);
    }
  }, [item.amount]);

  const getContractType = () => {
    try {
      switch (selectedContractOption) {
        case 0:
          return 'Upload a contract';
        case 1:
          return 'Publishing split agreement (sample used)';
        case 2:
          return 'Recording agreement (single song)';
        case 3:
          return 'Recording agreement (profit sharing)';
      }
    } catch {}
  };

  const handleFile = (files: any) => {
    setPdfMask(files[0].name);
    handleFileUpload({
      files,
      setError,
      setReturnedImages: setPdf,
      setUploadInProcess: setFetching,
    });
  };

  useEffect(() => {
    if (pdf) {
      addContract(pdf, pdfMask, index);
    }
  }, [addContract, index, pdf, pdfMask]);

  const getTodayDate = () => {
    const today = new Date();
    let month = '' + (today.getMonth() + 1);
    let day = '' + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  };

  return (
    <FixedRightBottom
      onClick={e => {
        e.stopPropagation();
        closeFunc();
        setRoleOption(false);
      }}
    >
      {showContractSheet && showContract ? (
        <SplitContractSheet
          selectedCollaborator={item}
          splitCollaborators={splitCollaborators}
          pdf={item.contract}
          type={selectedContractOption === 0 ? 'uploaded' : 'generic'}
        />
      ) : null}
      <div
        onClick={e => {
          e.stopPropagation();
          setRoleOption(false);
        }}
      >
        <main>
          <FlexedApart
            style={{
              position: 'relative',
              top: '0',
              width: '100%',
              border: 'none',
              padding: '15px 5%',
              justifyContent: 'flex-start',
              gap: '8px',
            }}
          >
            <SplitAvatar>
              {item.name ? item.name[0] : item.email[0]}
            </SplitAvatar>
            <h2>
              <b>Revenue Settings</b>
            </h2>
          </FlexedApart>
          <Line />
          <div>
            <ActionHeader>
              <i onClick={() => setShowRevenue(!showRevenue)}>
                {showRevenue ? (
                  <Icon.ChevronUp color="#474747" />
                ) : (
                  <Icon.ChevronDown color="#474747" />
                )}
              </i>
              <div onClick={() => setShowRevenue(!showRevenue)}>
                <h3>Revenue Share</h3>
              </div>
              {/* <i><IconSax.TickCircle color='#34A853' /></i> */}
            </ActionHeader>
            {showRevenue ? (
              <>
                {type === 'project' && mode === 'edit' ? (
                  <>
                    <TicketInput>
                      <div
                        style={{
                          paddingBottom: '1px',
                          border: 'none',
                          padding: '1px',
                        }}
                      >
                        <MobileInputWrap>
                          <MobileSelect>
                            <div
                              style={{
                                width: '6rem',
                              }}
                            >
                              <InputActPlacehold
                                onClick={e => {
                                  setIsInputFocus(!isInputFocus);
                                  e.stopPropagation();
                                }}
                                onBlur={() => setIsInputFocus(false)}
                              >
                                <div>
                                  <ReactCountryFlag
                                    style={{
                                      fontSize: '1.3rem',
                                      borderRadius: '50%',
                                    }}
                                    countryCode={firstFlag}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                  />
                                  <p
                                    style={{
                                      color: '#191919',
                                    }}
                                  >
                                    {firstCurrency}
                                  </p>
                                </div>
                                <i>
                                  <Icon.ChevronDown size={15} strokeWidth={3} />
                                </i>
                              </InputActPlacehold>
                            </div>
                          </MobileSelect>
                          <CollabSuggestWrap
                            style={{
                              margin: '0rem 0 0 0',
                              width: '8rem',
                              zIndex: '1000000000',
                              top: '2rem',
                            }}
                          >
                            {isInputFocus ? (
                              <>
                                <div>
                                  {Countries && Countries.length > 0 ? (
                                    <>
                                      {Countries.slice(0, 2).map(
                                        (item, index) => (
                                          <SplitUsersWrap
                                            key={index}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              setIsInputFocus(!isInputFocus);
                                              setFirstFlag(item.code);
                                              setFirstCurrency(item.name);
                                              addCurency(item.name);
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: '100%',
                                                overflow: 'hidden',
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  gap: '10px',
                                                  width: '8rem',
                                                }}
                                              >
                                                <ReactCountryFlag
                                                  style={{
                                                    fontSize: '1.3rem',
                                                    borderRadius: 'none',
                                                  }}
                                                  countryCode={item.code}
                                                  svg
                                                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                  cdnSuffix="svg"
                                                  title={item.code}
                                                />
                                                <h3
                                                  style={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    width: '25%',
                                                  }}
                                                >
                                                  {item.name
                                                    ? `${item.name}        `
                                                    : ''}
                                                </h3>
                                              </div>
                                            </div>
                                          </SplitUsersWrap>
                                        ),
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <p
                                        style={{
                                          textAlign: 'center',
                                          fontSize: '14px',
                                          color: '#757575',
                                          margin: '1rem 0 0 0',
                                        }}
                                      >
                                        No Option.
                                      </p>
                                    </>
                                  )}
                                </div>
                              </>
                            ) : null}
                          </CollabSuggestWrap>
                          <MobileInput
                            width="103%"
                            type="text"
                            value={item.amount}
                            onChange={e =>
                              addPriorityAmount(e.target.value, index)
                            }
                            onKeyPress={e => {
                              handleZero(e);
                              numOnly(e);
                            }}
                            required={true}
                            style={{
                              height: '3rem',
                              paddingLeft: '8rem',
                              border: '0.5px solid #dadada',
                            }}
                          />
                        </MobileInputWrap>
                      </div>
                    </TicketInput>

                    {/* <TicketInput labelPosition="right">
                      <div style={{ width: '100%' }}>
                        <p>Naira</p>
                        <label></label>
                        <input
                          type="number"
                          value={item.amount}
                          onChange={e =>
                            addPriorityAmount(e.target.value, index)
                          }
                          onKeyPress={e => {
                            handleZero(e);
                            numOnly(e);
                          }}
                        />
                      </div>
                    </TicketInput> */}
                  </>
                ) : (
                  <>
                    <TicketInput labelPosition="right">
                      <div style={{ width: '100%' }}>
                        <p>Percentage(%)</p>
                        <label>%</label>
                        <input
                          type="text"
                          value={item.percentage}
                          disabled={mode === 'edit' && item.locked}
                          onChange={e => addPercentage(e, index)}
                          onKeyPress={e => {
                            handleZero(e);
                            numOnly(e);
                          }}
                          maxLength={3}
                        />
                      </div>
                    </TicketInput>
                  </>
                )}

                {/* {(mode !== 'edit' && showRecoup) ||
                (mode !== 'edit' && item.amount) ||
                (type === 'music' && showRecoup) ? (
                  <>
                    {type === 'music' && (
                      <>
                        <TicketInput hasLabel>
                          <div style={{ width: '100%' }}>
                            {type === 'music' ? (
                              <>
                                <p>First recouperrr</p>
                                <label
                                  style={{
                                    fontWeight: 100,
                                  }}
                                >
                                  ₦
                                </label>
                                <input
                                  type="number"
                                  value={item.amount}
                                  onChange={e =>
                                    addPriorityAmount(e.target.value, index)
                                  }
                                  onKeyPress={e => {
                                    handleZero(e);
                                    numOnly(e);
                                  }}
                                />
                              </>
                            ) : null}
                          </div>
                        </TicketInput>
                      </>
                    )}
                  </>
                ) : null}

                {/* {type === 'project' ? (
                  <>
                    {(mode === 'edit' && item.amount) ||
                    (mode === 'edit' && showRecoup) ? (
                      <TicketInput hasLabel={true}>
                        <div style={{ width: '100%' }}>
                          {type === 'project' && (
                            <>
                              <p>Fixed Cash</p>
                            </>
                          )}

                          <label>₦</label>
                          <input
                            type="number"
                            value={item.amount}
                            disabled={mode === 'edit' && item.locked}
                            onChange={e =>
                              addPriorityAmount(e.target.value, index)
                            }
                            onKeyPress={e => {
                              handleZero(e);
                              numOnly(e);
                            }}
                          />
                        </div>
                      </TicketInput>
                    ) : null}
                    
                    {mode !== 'edit' ||
                    (mode === 'edit' && item.percentage === 0) ||
                    (mode === 'edit' && !item.locked) ||
                    (mode === 'edit' && showRecoup) ? (
                      <>
                        {!showRecoup ? (
                          <BlueText
                            onClick={() => {
                              setShowRecoup(true);
                            }}
                          >
                            Add Fixed Cash
                          </BlueText>
                        ) : (
                          <BlueText
                            onClick={() => {
                              setShowRecoup(false);
                              addPriorityAmount(0, index);
                            }}
                            style={{
                              color: '#c82b32',
                              cursor: 'pointer',
                            }}
                            onKeyPress={e => {
                              handleZero(e);
                            }}
                          >
                            Remove Fixed Cash
                          </BlueText>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null} */}

                {type === 'music' ? (
                  <>
                    {(mode === 'edit' && item.amount) ||
                    (mode === 'edit' && showRecoup) ? (
                      <TicketInput hasLabel={true}>
                        <div style={{ width: '100%' }}>
                          <p>First recoup</p>
                          <label>₦</label>
                          <input
                            type="number"
                            value={item.amount}
                            disabled={mode === 'edit' && item.locked}
                            onChange={e =>
                              addPriorityAmount(e.target.value, index)
                            }
                            onKeyPress={e => {
                              handleZero(e);
                              numOnly(e);
                            }}
                          />
                        </div>
                      </TicketInput>
                    ) : null}
                    {mode !== 'edit' ||
                    (mode === 'edit' && item.percentage === 0) ||
                    (mode === 'edit' && !item.locked) ||
                    (mode === 'edit' && showRecoup) ? (
                      <>
                        {!showRecoup ? (
                          <BlueText
                            onClick={() => {
                              setShowRecoup(true);
                            }}
                          >
                            Add recoupment
                          </BlueText>
                        ) : (
                          <BlueText
                            onClick={() => {
                              setShowRecoup(false);
                              addPriorityAmount(0, index);
                            }}
                            style={{
                              color: '#c82b32',
                              cursor: 'pointer',
                            }}
                            onKeyPress={e => {
                              handleZero(e);
                            }}
                          >
                            Remove recoupment
                          </BlueText>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}

                <div></div>
                {type === 'project' ||
                (mode === 'edit' && type === 'general') ? (
                  <>
                    {mode !== 'edit' || mode === 'edit' ? (
                      <>
                        <InputWrap>
                          <h4>Payment Frequency</h4>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between',
                            }}
                          >
                            <select
                              style={{
                                width: '100%',
                                margin: '0.1rem 0 0 0',
                              }}
                              value={item.frequency}
                              onChange={e => addFrequency(e, index)}
                              required
                            >
                              <option value="select">
                                Select payment frequency
                              </option>
                              <option value="one-off">One time</option>
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="bi-weekly">Bi-Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                          </div>
                        </InputWrap>
                        {['one-off'].includes(item.frequency) && (
                          <>
                            <InputWrap>
                              <h4>Payment due</h4>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <select
                                  style={{
                                    width: '100%',
                                    margin: '0.1rem 0 0 0',
                                  }}
                                  value={item.paymentDue}
                                  onChange={e => addPaymentDue(e, index)}
                                  required
                                >
                                  <option>Select due date</option>
                                  <option value="0">Same day</option>
                                  <option value="1">1 day later</option>
                                  <option value="3">3 days later</option>
                                  <option value="5">5 days later</option>
                                </select>
                              </div>
                            </InputWrap>
                          </>
                        )}

                        {['daily'].includes(item.frequency) && (
                          <>
                            <InputWrap>
                              <h4>Time</h4>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <input
                                  type="time"
                                  value={item.time}
                                  id="appt"
                                  name="appt"
                                  min="00:00"
                                  max="23:59"
                                  onChange={e => addTime(e, index)}
                                />
                              </div>
                            </InputWrap>
                          </>
                        )}

                        {/* {['weekly'].includes(item.frequency) && (
                          <>
                            <InputWrap>
                              <h4>Invoice cycle ends</h4>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <select
                                  style={{
                                    width: '100%',
                                    margin: '0.1rem 0 0 0',
                                  }}
                                  required
                                  value={item.cycleEnd}
                                  onChange={e => addCycleEnd(e, index)}
                                >
                                  <option value="">Select end day</option>
                                  <option value="sunday">Sunday</option>
                                  <option value="monday">Monday</option>
                                  <option value="tuesday">Tuesday</option>
                                  <option value="wednesday">Wednesday</option>
                                  <option value="thursday">Thursday</option>
                                  <option value="friday">Friday</option>
                                  <option value="saturday">Saturday</option>
                                </select>
                              </div>
                            </InputWrap>
                          </>
                        )}

                        {['bi-weekly'].includes(item.frequency) && (
                          <>
                            <InputWrap>
                              <h4>Invoice cycle ends</h4>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <select
                                  style={{
                                    width: '100%',
                                    margin: '0.1rem 0 0 0',
                                  }}
                                  required
                                  value={item.cycleEnd}
                                  onChange={e => addCycleEnd(e, index)}
                                >
                                  <option value="">Select end day</option>
                                  <option value="sunday">
                                    Every other Sunday
                                  </option>
                                  <option value="monday">
                                    Every other Monday
                                  </option>
                                  <option value="tuesday">
                                    Every other Tuesday
                                  </option>
                                  <option value="wednesday">
                                    Every other Wednesday
                                  </option>
                                  <option value="thursday">
                                    Every other Thursday
                                  </option>
                                  <option value="friday">
                                    Every other Friday
                                  </option>
                                  <option value="saturday">
                                    Every other Saturday
                                  </option>
                                </select>
                              </div>
                            </InputWrap>
                          </>
                        )} */}

                        {[
                          'weekly',
                          'monthly',
                          'bi-weekly',
                          'quarterly',
                          'yearly',
                        ].includes(item.frequency) && (
                          <>
                            <InputWrap>
                              <h4>Payment due</h4>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <select
                                  style={{
                                    width: '100%',
                                    margin: '0.1rem 0 0 0',
                                  }}
                                  value={item.paymentDue}
                                  onChange={e => addPaymentDue(e, index)}
                                  required
                                >
                                  <option>Select due date</option>
                                  <option value="0">Same day</option>
                                  <option value="1">1 day later</option>
                                  <option value="3">3 days later</option>
                                  <option value="5">5 days later</option>
                                </select>
                              </div>
                            </InputWrap>

                            <>
                              <InputWrap>
                                <h4>Define first payment date</h4>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <input
                                    type="date"
                                    value={item.startDate}
                                    onChange={e => addStartDate(e, index)}
                                    min={getTodayDate()}
                                  />
                                </div>
                              </InputWrap>
                            </>
                          </>
                        )}
                      </>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          <br />
          {type === 'music' ? (
            <div>
              <ActionHeader>
                <i onClick={() => setShowRole(!showRole)}>
                  {showRole ? (
                    <Icon.ChevronUp color="#474747" />
                  ) : (
                    <Icon.ChevronDown color="#474747" />
                  )}
                </i>
                <div onClick={() => setShowRevenue(!showRole)}>
                  <h3>Set Role</h3>
                </div>
                <i>
                  <IconSax.TickCircle
                    variant="Bold"
                    color={item.role.length > 0 ? '#34A853' : '#dadada'}
                  />
                </i>
              </ActionHeader>
              {showRole ? (
                <RoleInput
                  addRole={addRole}
                  isInputFocus={roleOption}
                  setInputFocus={setRoleOption}
                  RoleItems={item.role}
                  index={index}
                  deleteRole={deleteRole}
                />
              ) : null}
            </div>
          ) : null}
          <br />
          {type === 'music' ? (
            <div>
              <ActionHeader>
                <i onClick={() => setShowContract(!showContract)}>
                  {showContract ? (
                    <Icon.ChevronUp color="#474747" />
                  ) : (
                    <Icon.ChevronDown color="#474747" />
                  )}
                </i>
                <div onClick={() => setShowRevenue(!showContract)}>
                  <h3>Add Contract</h3>
                </div>
                <i>
                  <IconSax.TickCircle
                    variant="Bold"
                    color={item.contract ? '#34A853' : '#dadada'}
                  />
                </i>
              </ActionHeader>
              {showContract ? (
                <RoleCardWrap>
                  {!item.contract ? (
                    <>
                      {showContract && selectedContractOption === -1 ? (
                        <RoleCard
                          color="#007AFF"
                          bg="fafafa"
                          border="1px solid #007AFF"
                          onClick={() => setShowContractOptions(true)}
                        >
                          <Icon.Plus size={17} />
                          Add Contract
                        </RoleCard>
                      ) : null}
                      {Number(selectedContractOption) > -1 ? (
                        <>
                          <Icon.Trash2
                            size={20}
                            color="#c82b32"
                            onClick={() => {
                              setSelectedContractOption(-1);
                            }}
                          />
                          <label htmlFor="main-input">
                            <RoleCard
                              onClick={() => {
                                if (selectedContractOption === 0) {
                                  setPdfMask('');
                                  setFetching(false);
                                }
                              }}
                            >
                              {getContractType()}
                              {selectedContractOption !== 0 ? (
                                <>
                                  {showContractSheet ? (
                                    <Icon.EyeOff
                                      size={17}
                                      onClick={() =>
                                        setShowContractSheet(!showContractSheet)
                                      }
                                    />
                                  ) : (
                                    <Icon.Eye
                                      size={17}
                                      onClick={() =>
                                        setShowContractSheet(!showContractSheet)
                                      }
                                    />
                                  )}
                                </>
                              ) : null}
                            </RoleCard>
                          </label>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {error ? (
                    <p
                      style={{
                        color: '#c82b32',
                        fontSize: '12px',
                      }}
                    >
                      {error}
                    </p>
                  ) : null}
                  {item.contract ? (
                    <>
                      <Icon.Trash2
                        size={20}
                        color="#c82b32"
                        onClick={() => {
                          deleteContract(index);
                        }}
                      />
                      <RoleCard
                        onClick={e => {
                          e.stopPropagation();
                          setShowContractSheet(!showContractSheet);
                        }}
                      >
                        <IconSax.DocumentText1 color="#fff" />
                        <p>{item.contractFileName || pdfMask}</p>
                        {showContractSheet ? (
                          <Icon.EyeOff size={17} />
                        ) : (
                          <Icon.Eye size={17} />
                        )}
                      </RoleCard>
                    </>
                  ) : null}

                  <input
                    id="main-input"
                    type="file"
                    accept="application/pdf"
                    multiple={false}
                    style={{ display: 'none' }}
                    onChange={e => {
                      handleFile(e.target.files);
                    }}
                  ></input>

                  {fetching ? <ClipLoader color="#161616" /> : null}
                </RoleCardWrap>
              ) : null}
              {showContractOptions ||
              (showContractOptions && selectedContractOption === -1) ? (
                <ContractTypeList>
                  {contractOptions.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        setSelectedContractOption(index);
                        setShowContractOptions(false);
                      }}
                    >
                      <IconSax.NoteText size={17} />
                      <p>{item.name}</p>
                    </div>
                  ))}
                </ContractTypeList>
              ) : null}
            </div>
          ) : null}
        </main>
        <BigBtn
          style={{
            width: '90%',
            position: 'absolute',
            bottom: '1rem',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <button
            type="button"
            onClick={e => {
              e.stopPropagation();
              mode !== 'edit' ? closeFunc() : patchCollaborator(item);
            }}
            disabled={mode === 'edit' && item.locked}
            style={
              mode === 'edit' && item.locked
                ? {
                    background: '#757575',
                  }
                : {}
            }
          >
            {onProcess ? <BeatLoader color="#fff" /> : 'Save Changes'}
          </button>
        </BigBtn>
      </div>
    </FixedRightBottom>
  );
};

export default RevenueSettings;

const contractOptions = [
  {
    name: 'Upload a contract',
  },
  // {
  //   name: 'Publishing split agreement (sample used)',
  // },
  // {
  //   name: 'Recording agreement (single song)',
  // },
  // {
  //   name: 'Recording agreement (profit sharing)',
  // },
];

// addFrequency, addPayoutDate, addCycleEnd, addpaymentDue

export const Countries = [
  {
    name: 'NGN',
    code: 'NG',
  },
  {
    name: 'USD',
    code: 'US',
  },
  {
    name: 'GBP',
    code: 'GB',
  },
  {
    name: 'EUR',
    code: 'EU',
  },
];
