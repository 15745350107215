import { useEffect, useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
// import CreateStore from 'utils/apis/createStore';
import IsUsernameAvailable from 'utils/apis/IsUsernameAvailable';
// import ImageCropper from './ImageCropper';
import { BeatLoader } from 'react-spinners';
import PopUpModal from 'app/components/merch/popupModal';
import InputElement from 'app/components/InputElement';
import { BelowInputDiv } from 'app/components/merch/addMerch/styles';
import RESERVED_NAMES from 'reservedNames';
import constraints from 'utils/constraints';
import { FixedWrap } from './style';
import { BounceLoader } from 'react-spinners';
import { FloatLogOption } from '../SignUp/styles';
import {
  AuthHeader,
  FormWidget,
  MerchroLogo,
  MainWrapper,
} from '../Login/styles';

const MainContent = () => {
  const history = useNavigate();
  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const [isAvailable, setIsAvailable] = useState(false);
  const [isValidatingUsername, setIsValidatingUsername] = useState(false);

  // const [showImageCropper, setShowImageCropper] = useState<boolean>(false);
  const [showSettingUp, setShowSettingUp] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [usernameSlug, setUsernameSlug] = useState<string>('');
  const [occupations, setOccupations] = useState<any>([]);
  const [occupationStr, setOccupationStr] = useState<any>('');
  const [lengthErr, setLengthErr] = useState<any>(false);

  const handleOccupation = (e: any) => {
    const index = occupations.findIndex(
      x => x.toLowerCase() === e.target.value.toLowerCase(),
    );
    if (
      e.target.value &&
      e.target.value.length > 4 &&
      index === -1 &&
      occupations.length < 5
    ) {
      let value = e.target.value.split('');
      let occupation = `${value[0].toUpperCase()}`;
      value.splice(0, 1);
      occupation += value.join('');
      setOccupations(occupations.concat(occupation.trim()));
    } else if (e.target.value && e.target.value.length < 5) {
      setLengthErr(true);
      return;
    }
    e.target.value = '';
  };

  useEffect(() => {
    if (occupations && occupations.length > 0) {
      setOccupationStr(occupations.join('|'));
    }
  }, [occupations]);

  const handleRemoveOccupation = selected => {
    occupations.splice(selected, 1);
    setOccupations([...occupations]);
  };

  // const [profilePictureInput, setProfilePictureInput] =
  //   useState<HTMLInputElement>();
  // const [profilePictureDataImg, setProfilePictureDataImg] =
  //   useState<string>('');

  // useEffect(() => {
  //   axios.post(`${process.env.REACT_APP_BASE_ENDPOINT}`)
  // })

  // const handleProfilePictureChange = (input: HTMLInputElement) => {
  //   setProfilePictureInput(input);
  //   setShowImageCropper(true);
  // };

  // const [selectedImage, setSelectedImage] = useState<any>([]);
  // const handleImagePreview = e => {
  //   let image: any = [];
  //   image.push(e.target.files[0]);
  //   setSelectedImage(image);
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkUsernameAvailability = async e => {
    if (!e) return;
    e = e.trim();
    if (e.length < 4 || RESERVED_NAMES.includes(e.toLowerCase())) {
      setIsAvailable(false);
      setIsValidatingUsername(true);
      return;
    }
    const isAvailable = await IsUsernameAvailable(e.toLowerCase());
    setIsAvailable(isAvailable);
    setIsValidatingUsername(true);
  };

  useEffect(() => {
    let slug = username
      .trim()
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('/', '')
      .replaceAll('.', '')
      .toLowerCase()
      .replace(/ /g, '-');
    if (slug[slug.length - 1] === '-') {
      let slug_ = slug.split('');
      slug_.pop();
      slug = slug_.join('');
    }
    setUsernameSlug(slug);
    checkUsernameAvailability(slug);
  }, [username]);

  const [onProcess, setOnProcess] = useState(false);
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const handlePatch = () => {
    const apiData = {
      occupation: occupationStr,
    };
    if (occupationStr && occupationStr !== '') {
      axios
        .patch(
          process.env.REACT_APP_BASE_ENDPOINT + '/users/' + loggedUser.user.id,
          apiData,
          {
            headers: { Authorization },
          },
        )
        .then(res => {})
        .catch(err => {});
    }
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handleOnboard = async () => {
    handlePatch();
    const pageInfo: any = {
      name: username,
    };

    await axios
      .post(`${process.env.REACT_APP_BASE_ENDPOINT}/creator-page`, pageInfo, {
        headers: {
          Authorization: Authorization,
        },
      })
      .then(res => {
        setOnProcess(false);
        loggedUser.user['creatorPage'] = res.data.id;
        localStorage.setItem('logged_user', JSON.stringify(loggedUser));
        setShowSettingUp(true);
        setTimeout(() => {
          history('/');
        }, 4000);
      })
      .catch(err => {
        setOnProcess(false);
        setErrorMessage(err.response.data.message);
        setTimeout(() => {
          setErrorMessage('');
        }, 4000);
      });
  };

  const handleNext = async () => {
    setOnProcess(true);
    if (isValidatingUsername) {
      handleOnboard();
      setTimeout(() => {
        setOnProcess(false);
      }, 30000);
    } else {
      setErrorMessage(
        'An error occurred, Please fill all forms and try again!!!',
      );
      setOnProcess(false);
    }
  };

  const handleClose = () => {
    setErrorMessage('');
  };

  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

  return (
    <>
      <Helmet>
        <title>Onboarding</title>
        <meta name="description" content="create your page" />
      </Helmet>

      {errorMessage !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="Operation unsuccessful"
          extraMsg={errorMessage}
          icon="fas fa-exclamation"
          iconColor="#c82b32"
        />
      ) : null}

      {showSettingUp ? (
        <FixedWrap>
          <div>
            <BounceLoader color={'#c7c7c7'} size={70} />
            <h3>Setting up your account</h3>
            <p>Please wait while we do some magic</p>
          </div>
        </FixedWrap>
      ) : null}
      <MainWrapper>
        <AuthHeader>
          <MerchroLogo src="/logosvg.svg" alt="Merchro Logo" />
          <FloatLogOption>
            <Link to="/login">Login</Link>
          </FloatLogOption>
        </AuthHeader>
        <FormWidget>
          <H1
            style={{
              textAlign: 'center',
              fontFamily: 'Satoshi',
              fontWeight: 800,
              color: '#191919',
            }}
          >
            Set up your page
          </H1>
          <HeaderP style={{ textAlign: 'center', color: '#ababab' }}>
            Choose a username and share what you do
          </HeaderP>
          {/* </UsernameFormWrap> */}
          {/* <FormLabelHeader htmlFor="username">
            Username <span style={{ color: 'red' }}> * </span>
          </FormLabelHeader> */}
          <UsernameFormWrap>
            <UsernameInput
              name="username"
              id="username"
              value={username}
              onKeyPress={e => {
                if (constraints.exemptChars(numbers).includes(e.key)) {
                  e.preventDefault();
                } else if (
                  ['-', '/', '(', ')', ' '].includes(e.key) &&
                  (`${username}${e.key}`.indexOf(`${e.key}${e.key}`) !== -1 ||
                    !username)
                ) {
                  e.preventDefault();
                }
              }}
              onChange={e => {
                setUsername(e.target.value);
              }}
              type="text"
              placeholder="username"
            />
            <UsernameFormbox>
              <UsernameFormboxP>
                {process.env.REACT_APP_PAGE_LINK}
              </UsernameFormboxP>
            </UsernameFormbox>
          </UsernameFormWrap>
          {isValidatingUsername && username && (
            <UsernameValidationWrap>
              <UVUserName>
                {usernameSlug}
                {process.env.REACT_APP_PAGE_LINK}
              </UVUserName>
              &nbsp;
              <UVUserNameAvailability isAvailable={isAvailable}>
                {isAvailable ? 'is available' : 'is not available'}
              </UVUserNameAvailability>
            </UsernameValidationWrap>
          )}
          {/* <FormLabelHeader htmlFor="occupation">
            What do you do?<span style={{ color: 'red' }}> * </span>
          </FormLabelHeader> */}
          <InputElement
            placeholder="Enter occupation"
            onChange={e => setLengthErr(false)}
            onKeyPress={e => {
              if (constraints.hiddenChars.includes(e.key)) {
                e.preventDefault();
              } else if (
                ['-', '/', '(', ')', ' '].includes(e.key) &&
                (e.target.value.length < 3 ||
                  `${e.target.value}${e.key}`.indexOf(`${e.key}${e.key}`) !==
                    -1)
              ) {
                e.preventDefault();
              }
              if (e.key === 'Enter' && occupations.length < 5) {
                handleOccupation(e);
              }
            }}
            onBlur={e => handleOccupation(e)}
          />
          {lengthErr ? (
            <span
              style={{
                color: 'red',
                fontSize: 10,
                top: -15,
                position: 'relative',
              }}
            >
              Length cannot be less than 5
            </span>
          ) : null}
          <BelowInputDiv margin="-0.5rem 0 1rem 0">
            {occupations.length !== 0
              ? occupations.map((item, index) => (
                  <p onClick={() => handleRemoveOccupation(index)} key={index}>
                    {item}
                  </p>
                ))
              : null}
          </BelowInputDiv>
          <SendButton
            onClick={() => handleNext()}
            disabled={
              onProcess || !isAvailable || occupations.length === 0
                ? true
                : false
            }
          >
            {onProcess ? <BeatLoader color="white" /> : 'Next'}
          </SendButton>
        </FormWidget>
      </MainWrapper>
    </>
  );
};

export default MainContent;

export const LogoImage = styled.img`
  position: absolute;
  left: 30px;
  top: 30px;
  height: 2.5rem;

  @media screen and (max-width: 600px) {
    left: 5%;
    top: 35px;
  }
  @media screen and (max-width: 400px) {
    transform: scale(0.8) translateX(-15%) translateY(-20%);
  }
`;

const H1 = styled.h1`
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #000;
  margin-bottom: 12px;

  @media screen and (max-width: 600px) {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 400px) {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 5px;
  }
`;

const HeaderP = styled.p`
  font-family: Inter;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  text-align: left;
  color: #2b2b2b;
  margin-bottom: 30px;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 17px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 400px) {
    font-size: 12px;
    line-height: 13px;
  }
`;

export const UsernameFormWrap = styled.div`
  width: 100%;
  // border-radius: 10px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  margin-bottom: 25px;

  @media screen and (max-width: 600px) {
    height: 55px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 400px) {
    margin-bottom: 20px;
  }
`;

export const UsernameInput = styled.input`
  width: 75%;
  // height: 100%;
  background: #fff;
  border: 1px solid #dadada;
  border-right: none;
  border-radius: 5px 0 0 5px;
  /* margin: 10px 0; */
  padding: 14px 20px;
  color: #2b2b2b;
  font-size: 14px;
  font-weight: 500;

  &::placeholder {
    color: #2b2b2b4d;
    font-weight: 400;
  }

  &:focus {
    outline-color: #b8b8b8;
  }

  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
    font-size: 11px;
  }
  @media screen and (max-width: 400px) {
    width: 70%;
    font-size: 10px;
  }
`;

export const UsernameFormbox = styled.div`
  border: 0.5px solid #dadada;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #ffffff;
  padding: 15px 8px;
  display: flex;
  align-items: center;
  width: 25%;

  @media screen and (max-width: 400px) {
    width: 30%;
    padding: 12px 20px;
  }
`;

export const UsernameFormboxP = styled.p`
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #2b2b2b;

  @media screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
    font-size: 11px;
  }
  @media screen and (max-width: 400px) {
    font-size: 10px;
  }
`;

export const UsernameValidationWrap = styled.p`
  display: flex;
  transform: translateY(-30px);
  @media screen and (max-width: 600px) {
    transform: translateY(-20px);
  }
  @media screen and (max-width: 400px) {
    transform: translateY(-15px);
  }
`;

export const UVUserName = styled.span`
  font-family: 'Satoshi', sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  color: #191919;
  margin: 1.5rem 0 0 0;
  @media (max-width: 600px) {
    margin: 0;
  }
`;

export const UVUserNameAvailability = styled.span<{ isAvailable: boolean }>`
  font-family: 'Satoshi', sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  letter-spacing: 0em;
  margin: 1.5rem 0 0 0;
  @media (max-width: 600px) {
    margin: 0;
  }
  color: ${props => (props.isAvailable ? '#66bb6a' : 'red')};
`;

// const ProfilePictureInput = styled.input`
//   width: 0.1px;
//   height: 0.1px;
//   opacity: 0;
//   position: absolute;
//   z-index: -1000;

//   &::placeholder {
//   }

//   &:focus + label {
//     outline-color: #b8b8b8;
//   }

//   &::file-selector-button {
//   }
// `;

// const ProfilePictureWrap = styled.label`
//   padding: 0;
//   margin: 0;
// `;
// const ProfilePictureInputDiv = styled.label`
//   width: 100%;
//   height: 60px;
//   background: #f9f9f9;
//   padding: 12px 20px;
//   color: #2b2b2b;
//   border: 0.5px solid #e8e8e8;
//   border-radius: 10px;
//   margin-bottom: 30px;

//   display: flex;
//   align-items: center;

//   font-size: 14px;
//   font-weight: 500;
//   line-height: 17px;

//   @media screen and (max-width: 900px) {
//     font-size: 12px;
//   }
//   @media screen and (max-width: 600px) {
//     height: 55px;
//     margin-bottom: 30px;
//     font-size: 11px;
//   }
//   @media screen and (max-width: 400px) {
//     margin-bottom: 20px;
//     font-size: 10px;
//     padding: 10px;
//   }
// `;

// const ChooseFileBtn = styled.div`
//   border: 1px dashed #000000;
//   background: #ffffff;
//   padding: 5px;
//   width: 120px;
//   height: 40px;

//   display: flex;
//   align-items: center;
//   justify-content: center;

//   font-family: Inter;
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 19px;
//   letter-spacing: 0em;
//   color: #2b2b2b;
//   @media screen and (max-width: 600px) {
//     width: 100px;
//     height: 30px;
//     font-size: 12px;
//     font-size: 12px;
//   }
//   @media screen and (max-width: 400px) {
//     width: 80px;
//     font-size: 10px;
//   }
// `;

// const FileChosenP = styled.p``;

// const BioTextarea = styled.textarea`
//   height: 100px;
//   width: 100%;
//   background: #f9f9f9;
//   border: 0.5px solid #e8e8e8;
//   border-radius: 10px;
//   /* margin: 10px 0; */
//   padding: 10px;
//   color: #2b2b2b;
//   margin-bottom: 30px;

//   font-size: 14px;
//   font-weight: 500;
//   line-height: 17px;
//   resize: none;

//   &::placeholder {
//     color: #2b2b2b4d;
//     font-weight: 400;
//   }

//   &:focus {
//     outline-color: #b8b8b8;
//   }

//   @media screen and (max-width: 900px) {
//     font-size: 12px;
//   }
//   @media screen and (max-width: 600px) {
//     font-size: 11px;
//   }
//   @media screen and (max-width: 400px) {
//     font-size: 10px;
//   }
// `;

// const SendUpdatesWrap = styled.div`
//   display: flex;
//   align-items: center;
//   margin-bottom: 30px;

//   @media screen and (max-width: 400px) {
//     margin-bottom: 20px;
//     display: block;
//   }
// `;

// const SendUpdatesText = styled.p`
//   font-family: 'Satoshi', sans-serif;
//   font-size: 10px;
//   font-weight: 600;
//   line-height: 12px;
//   letter-spacing: 0em;
//   color: #000000b2;
// `;

// const ToggleButton = styled.div`
//   position: relative;
//   display: inline-block;
//   width: 30px;
//   height: 16px;
//   margin-right: 10px;
// `;

// const ToggleSpan = styled.span<{ toUpdate: boolean }>`
//   position: absolute;
//   cursor: pointer;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   transition: 0.4s;
//   border-radius: 34px;
//   background-color: ${props => (props.toUpdate ? '#66BB6A' : '#ccc')};

//   &:focus {
//     box-shadow: 0 0 1px #66bb6a;
//   }

//   &:before {
//     position: absolute;
//     content: '';
//     height: 15px;
//     width: 15px;
//     left: 0px;
//     bottom: 50%;
//     background: #000000cc;
//     border: 2px solid #969696;
//     border-radius: 50%;
//     transform: ${props =>
//       props.toUpdate ? 'translate(15px, 50%)' : 'translate(0px, 50%)'};
//     transition: 0.4s;
//   }
// `;

const SendButton = styled.button`
  width: 100%;
  height: 50px;
  background: #191919;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #ffffff;

  @media screen and (max-width: 900px) {
    height: 55px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
