import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import {
  ReportModalWidget,
  ReportModalChild,
  CloseIcon,
  HeaderWrap,
  ModalMain,
  InputWrap,
} from './../../transactions/style';
import { BigBtn } from 'app/components/dashboard/mainContent/fundWallet/style';
import Select from 'react-select';

interface PropsArgs {
  closeFunc: any;
  header: any;
  subText?: any;
  beforeDueDate?: any;
  onDueDate?: any;
  afterDueDate?: any;
  setBeforeDueDate?: any;
  setOnDueDate?: any;
  setAfterDueDate?: any;
  dateDiff?: any;
}

const CreateReminder = ({
  closeFunc,
  header,
  subText,
  beforeDueDate,
  onDueDate,
  afterDueDate,
  setBeforeDueDate,
  setOnDueDate,
  setAfterDueDate,
  dateDiff,
}: PropsArgs) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: '16px',
      fontWeight: 300,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: 10,
      fontSize: '16px',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const handleReminder = () => {
    if (beforeDueDate !== '' || onDueDate !== '' || afterDueDate !== '') {
      closeFunc();
    }
  };

  // Get Before Date Condition
  const [modifiedBeforeEnt, setModifiedBeforeEnt] = useState<any>(before);

  useEffect(() => {
    let mockData: any = [];

    for (let i = 0; i < before.length; i++) {
      if (before[i].value <= dateDiff) {
        mockData.push(before[i]);
      }
    }
    setModifiedBeforeEnt(mockData);
  }, [dateDiff]);

  return (
    <>
      <ReportModalWidget onClick={closeFunc}>
        <ReportModalChild onClick={e => e.stopPropagation()}>
          <CloseIcon>
            <Icon.X strokeWidth={1} onClick={closeFunc} />
          </CloseIcon>
          <HeaderWrap>
            <h3>{header}</h3>
            <p>{subText}</p>
          </HeaderWrap>
          <ModalMain borderTp="none" margin="none" padding="none">
            <InputWrap>
              <label>Before due date</label>
              <Select
                options={modifiedBeforeEnt}
                getOptionLabel={p => p.name}
                styles={customStyles}
                value={before.filter(p => p.value === beforeDueDate)}
                onChange={(e: any) =>
                  setBeforeDueDate((e as HTMLSelectElement).value)
                }
              />
            </InputWrap>
            <InputWrap>
              <label>On due date</label>
              <Select
                options={onDate}
                getOptionLabel={p => p.name}
                styles={customStyles}
                value={onDate.filter(p => p.value === onDueDate)}
                onChange={(e: any) =>
                  setOnDueDate((e as HTMLSelectElement).value)
                }
              />
            </InputWrap>
            <InputWrap>
              <label>After due date</label>
              <Select
                options={after}
                getOptionLabel={p => p.name}
                styles={customStyles}
                value={after.filter(p => p.value === afterDueDate)}
                onChange={(e: any) =>
                  setAfterDueDate((e as HTMLSelectElement).value)
                }
              />
            </InputWrap>
            <BigBtn onClick={() => handleReminder()}>
              <button>Save Reminder</button>
            </BigBtn>
          </ModalMain>
        </ReportModalChild>
      </ReportModalWidget>
    </>
  );
};

export default CreateReminder;

const before = [
  {
    name: '14 days before',
    value: 14,
  },
  { name: '7 days before', value: 7 },
  { name: '3 days before', value: 3 },
];

const onDate = [
  {
    name: 'on due date',
    value: 0,
  },
];

const after = [
  { name: '3 days after', value: 3 },
  { name: '7 days after', value: 7 },
  { name: '14 days after', value: 14 },
];
