import MainContent from 'app/components/storeComp/mainContent/main';
import SideNav from 'app/components/storeComp/sideNav/sideNav';
import { MainWrapper } from 'app/components/storeComp/styles/mainStyles';

const StoreHome = () => {
  return (
    <>
      <MainWrapper>
        <SideNav mobileShow="none" />
        <MainContent />
      </MainWrapper>
    </>
  );
};

export default StoreHome;
