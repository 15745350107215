import React, { useState, useEffect } from 'react';
import {
  MainWrapper,
  InvoiceNoWrap,
  UserInfo,
  BillSection,
  BillingInfo,
  FlexApart,
  ItemsSection,
  ItemRow,
  Description,
  SmallColumn,
} from './preview-style';
import moment from 'moment';
import { UserAvatar } from '../../storeComp/sideNav/style';
import axios from 'axios';

interface PropsArgs {
  propRef: any;
  clientName: string;
  email: string;
  address: string;
  dueDate: any;
  phone: string;
  state: string;
  country: string;
  discount: any;
  tax: any;
  shipping: any;
  discountPercent: any;
  discountType?: string;
  fulfilmentLink?: string;
  taxType?: string;
  taxPercent: any;
  grandTotal: number;
  items: any;
  invoiceCode: string;
  refNo: string;
  invoiceNote: string;
}

const Preview = ({
  propRef,
  clientName,
  email,
  address,
  phone,
  state,
  dueDate,
  country,
  discount,
  tax,
  shipping,
  fulfilmentLink,
  discountPercent,
  taxType,
  discountType,
  taxPercent,
  grandTotal,
  items,
  invoiceCode,
  invoiceNote,
  refNo,
}: PropsArgs) => {
  // Get Invoice Setup Data
  const [name, setName] = useState('');
  const [invoiceEmail, setInvoiceEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [returnedImages, setReturnedImages] = useState<any>('');

  useEffect(() => {
    let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const option = {
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${loggedUser.tokens.access.token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/invoices/setup`, option)
      .then(res => {
        if (res.data && Object.keys(res.data).length > 1) {
          setInvoiceEmail(res.data.email);
          setPhoneNo(res.data.phoneNumber);
          if (res.data.logo) {
            setReturnedImages(res.data.logo);
          }
          setName(res.data.name);
        }
      })
      .catch(err => {});
  }, []);

  const date = new Date();

  function sum(input) {
    if (toString.call(input) !== '[object Array]') return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }

  let newItem: any = [];

  if (items && items.length > 0) {
    for (var i = 0; i < items.length; i++) {
      newItem.push(items[i].amount * items[i].quantity);
    }
  }

  // Get All Total
  const subTotal: any = sum(newItem);
  const taxPercentage = (tax / 100) * subTotal;
  const discountPercentage = (discount / 100) * subTotal;

  return (
    <>
      <MainWrapper id="divToPrint" ref={propRef}>
        <InvoiceNoWrap>
          <div style={{ alignItems: 'flex-end' }}>
            <h2>
              INVOICE DATE:{' '}
              <span>
                {date.getDate()}/
                {String(date.getMonth() + 1).length > 1
                  ? date.getMonth() + 1
                  : `0${date.getMonth() + 1}`}
                /{date.getFullYear()}
              </span>
            </h2>
            <h2>
              DUE DATE: <span>{moment(dueDate).format('DD/MM/YYYY')}</span>
            </h2>
          </div>
        </InvoiceNoWrap>
        <UserInfo>
          {returnedImages && returnedImages !== '' ? (
            <img src={returnedImages} alt="User" />
          ) : (
            <UserAvatar>
              {clientName && clientName !== '' ? clientName[0] : ''}
            </UserAvatar>
          )}
          <h2>{name}</h2>
          <p>
            {phoneNo}
            <br />
            {invoiceEmail}
          </p>
        </UserInfo>
        <BillSection>
          <FlexApart>
            <div>
              <h2>BILL TO:</h2>
              <h3>{clientName}</h3>
              <h3>{phone}</h3>
              <h3>{email}</h3>
              <h3>{address}</h3>
            </div>
          </FlexApart>
        </BillSection>
        <ItemsSection>
          <ItemRow customBg="#ffffff">
            <Description>
              <h2>Description</h2>
            </Description>
            <SmallColumn>
              <h2>Rate</h2>
            </SmallColumn>
            <SmallColumn>
              <h2>Qty</h2>
            </SmallColumn>
            <SmallColumn>
              <h2>Amount</h2>
            </SmallColumn>
          </ItemRow>
          {items && items.length > 0 ? (
            items.map((item, index) => (
              <ItemRow customBg="#f9f9f9" key={index}>
                <Description>
                  <p>{item.itemDetails}</p>
                </Description>
                <SmallColumn>
                  <p>&#8358;{item.amount}</p>
                </SmallColumn>
                <SmallColumn>
                  <p>{item.quantity}</p>
                </SmallColumn>
                <SmallColumn>
                  <p>&#8358;{item.amount * item.quantity}</p>
                </SmallColumn>
              </ItemRow>
            ))
          ) : (
            <p>No Item entered!</p>
          )}
        </ItemsSection>
        <BillingInfo>
          <div>
            <div>
              <p>Subtotal</p>
              <p>&#8358;{sum(newItem)}</p>
            </div>
            {shipping > 0 ? (
              <div>
                <p>Shipping</p>
                <p>&#8358;{shipping}</p>
              </div>
            ) : null}
            {discountPercent > 0 || discountPercentage > 0 ? (
              <div>
                <p>Discount</p>
                <p>
                  &#8358;
                  {discountPercent ? discountPercent() : discountPercentage}
                </p>
              </div>
            ) : null}
            {taxPercent > 0 || taxPercentage > 0 ? (
              <div>
                <p>Tax</p>
                <p>&#8358;{taxPercent ? taxPercent() : taxPercentage}</p>
              </div>
            ) : null}
            <div>
              <h2>Total</h2>
              <h2>&#8358;{grandTotal}</h2>
            </div>
          </div>
        </BillingInfo>
        {invoiceNote ? (
          <>
            <h3
              style={{
                fontWeight: 700,
                color: '#121212',
                margin: '2rem 0 0.5rem 0',
              }}
            >
              Invoice Note
            </h3>
            <p
              style={{
                fontWeight: 300,
                color: '#121212',
                width: '80%',
                whiteSpace: 'pre-line',
              }}
            >
              {invoiceNote}
            </p>
          </>
        ) : null}

        {/* <TradeMark>
          <p>INVOICING POWERED BY</p>
          <img src="logo.png" alt="Merchro Logo" />
        </TradeMark> */}
      </MainWrapper>
      {/* </Layer>
      </Wrapper> */}
    </>
  );
};

export default Preview;
