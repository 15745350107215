import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from 'app/components/transactions/style';
import {
  AccountNoCenter,
  AmountInput,
  SmallInputWrap,
  SplitUserMainWrap,
  SplitUsersWrap,
  SplitAvatar,
  ProgressBar,
  ProgressLine,
  // CollabSuggestWrap,
  AccountToolTip,
  PercentageInput,
  // DualWrap,
  // DualTypeWrap,
  // DualTypeItem,
  Disclaimer,
} from './style';
import {
  ButtonWrap,
  // CustomButtonDark,
  // ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import { BlueText } from '../emptyState/style';
import { numOnly } from 'utils/numOnly';
import AddCollaborator from './createCollaborator';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import PopUpModal from '../merch/popupModal';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import CloseWarning from './closeWarning';
import CollabDeleteWarning from './collaboratorDeleteWarning';
import EditCollaborator from './editCollaborator';
import { NumericFormat } from 'react-number-format';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import SelectCollaborator from './selectCollaborator';
import CollaboratorSettings from './collaboratorSetting';
import { useSearchParams } from 'react-router-dom';
import RevenueSettings from './revenueShare';
import CommaNumber from 'comma-number';
import { getRequest } from 'utils/request';
// import { getSign } from 'utils/getCurrencySign';

const MainContent = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const navigate = useNavigate();
  const [openAdd, setOpenAdd] = useState(false);
  const [activeCollaborator, setActiveCollaborator] = useState(-1);
  const [selectedCollaborator, setSelectedCollaborator] = useState<any>({});
  const [openCollaboratorSettings, setOpenCollaboratorSettings] =
    useState(false);
  const [openRevenueSetting, setOpenRevenueSetting] = useState(false);
  const splitData = JSON.parse(localStorage.getItem('collabData') || '{}');
  const [percentageError, setPercentageError] = useState('');
  const [newCollabEmail, setNewCollabEmail] = useState('');
  const [newCollabRole, setNewCollabRole] = useState('');

  const [error, setError] = useState('');
  const [activeItem, setActiveItem] = useState(-1);

  // const [collaborators, setCollaborators] = useState<any>([]);

  // Get All collaborators
  const [gottenCollabs, setGottenCollabs] = useState<boolean>(false);
  const [savedCollabs, setSavedCollabs] = useState<any>([]);
  const [collabsModified, setCollabsModified] = useState<any>([]);
  const [newCollaborator, setNewCollaborator] = useState<any>([]);

  useEffect(() => {
    getRequest(`splits/collaborators?paginate=false&sortBy=createdAt:desc`)
      .then(res => {
        setSavedCollabs(res);
        setCollabsModified(res);
        setGottenCollabs(true);
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    // User Authentication
    const headers = {
      headers: {
        Authorization: `Bearer ${loggedUser.tokens.access.token}`,
      },
    };

    const payload = {
      email: loggedUser.user.email,
    };
    const email =
      savedCollabs.find(p => p.email === loggedUser.user.email) || {};

    if (splitData && splitData.length > 0) {
      setNewCollaborator(splitData);
    } else {
      if (gottenCollabs && savedCollabs && savedCollabs.length > 0) {
        if (Object.keys(email).length > 0) {
          setNewCollaborator((item: string | any[]) =>
            item.concat({
              ...email,
              canView: false,
              percentage: 0,
              mode: 0,
              role: [],
              frequency: '',
              cycleEnd: '',
              paymentDue: 0,
              payoutDate: 0,
              week: 0,
              time: '18:00',
            }),
          );
          setGottenCollabs(false);
          collabsModified[0].added = true;
          setCollabsModified([...collabsModified]);
        }
        if (Object.keys(email).length < 1) {
          axios
            .post(
              `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators`,
              payload,
              headers,
            )
            .then(resp => {
              setNewCollaborator(item =>
                item.concat({
                  ...resp.data,
                  canView: false,
                  percentage: 0,
                  mode: 0,
                  role: [],
                  frequency: '',
                  cycleEnd: '',
                  paymentDue: 0,
                  payoutDate: 0,
                  week: 0,
                  time: '18:00',
                }),
              );
              setGottenCollabs(false);
              collabsModified[0].added = true;
              setCollabsModified([...collabsModified]);
            })
            .catch(err => {});
        }
      }

      if (gottenCollabs && savedCollabs.length < 1) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators`,
            payload,
            headers,
          )
          .then(resp => {
            setNewCollaborator(item =>
              item.concat({
                ...resp.data,
                canView: false,
                percentage: 0,
                mode: 0,
                role: [],
                frequency: '',
                cycleEnd: '',
                paymentDue: 0,
                payoutDate: 0,
                week: 0,
                time: '18:00',
              }),
            );
            setCollabsModified(item =>
              item.concat({
                ...resp.data,
                canView: false,
                percentage: 0,
                mode: 0,
                role: [],
                added: true,
                frequency: '',
                cycleEnd: '',
                paymentDue: 0,
                payoutDate: 0,
                week: 0,
              }),
            );
            setGottenCollabs(false);
            collabsModified[0].added = true;
            setCollabsModified([...collabsModified]);
          })
          .catch(err => {});
      }
    }
  }, [savedCollabs, gottenCollabs]); //eslint-disable-line

  // Save Collaborator
  const pushCollaborator = e => {
    const mockData = [...newCollaborator];
    if (newCollaborator.length === 0) {
      mockData.push({
        ...e,
        canView: false,
        percentage: 0,
        mode: 0,
        role: [],
        frequency: '',
        cycleEnd: '',
        paymentDue: 0,
        payoutDate: 0,
        week: 0,
      });
    } else {
      for (let i = 0; i < newCollaborator.length; i++) {
        if (newCollaborator[i].email !== e.email) {
          mockData.push({
            ...e,
            canView: type === 'music' || type === 'project' ? true : false,
            canNegotiate: type === 'music' || type === 'project' ? true : false,
            percentage: 0,
            mode: 0,
            role: [],
            frequency: '',
            cycleEnd: '',
            paymentDue: 0,
            payoutDate: 0,
            week: 0,
            time: '18:00',
          });
          break;
        }
      }
    }
    setNewCollaborator(mockData);
  };

  // Percentage Functions
  const addPrecentage = (e, index) => {
    newCollaborator[index].percentage = Number(e.target.value);
    setNewCollaborator([...newCollaborator]);
  };

  // add frequency
  const addFrequency = (e, index) => {
    newCollaborator[index].frequency = e.target.value;
    setNewCollaborator([...newCollaborator]);
  };

  // add payoutdate
  const addStartDate = (e, index) => {
    if (e.target.value) {
      newCollaborator[index].startDate = e.target.value;
    } else {
      delete newCollaborator[index].startDate;
    }
    setNewCollaborator([...newCollaborator]);
  };

  // add Week
  const addCycleEnd = (e, index) => {
    newCollaborator[index].cycleEnd = e.target.value;
    setNewCollaborator([...newCollaborator]);
  };

  const addpaymentDue = (e, index) => {
    newCollaborator[index].paymentDue = Number(e.target.value);
    setNewCollaborator([...newCollaborator]);
  };

  const addPayoutDate = (e, index) => {
    newCollaborator[index].payoutDate = Number(e.target.value);
    setNewCollaborator([...newCollaborator]);
  };

  const addTime = (e, index) => {
    newCollaborator[index].time = e.target.value;
    setNewCollaborator([...newCollaborator]);
  };

  const addWeek = (e, index) => {
    newCollaborator[index].week = Number(e.target.value);
    setNewCollaborator([...newCollaborator]);
  };

  // Check Maximum Percentage
  useEffect(() => {
    let mockData: any = [];

    for (let i = 0; i < newCollaborator.length; i++) {
      mockData.push(newCollaborator[i].percentage);
    }
    let totalPercent: number = Number(sum(mockData));

    if (totalPercent <= 100) {
      setPercentageError('');
    } else {
      setPercentageError('Percentage cannot be greater than 100');
      setTimeout(() => {
        setPercentageError('');
      }, 4000);
    }
  }, [newCollaborator]);

  // Add canView
  const checkCanView = (e, index) => {
    newCollaborator[index].canView = e.target.checked;
    if (type === 'music' || type === 'project') {
      newCollaborator[index].canNegotiate = e.target.checked;
    }
    setNewCollaborator([...newCollaborator]);
  };

  // Cap Function
  const addCapAmount = (e, index) => {
    newCollaborator[index].cap = Number(e.target.value);
    setNewCollaborator([...newCollaborator]);
  };

  // Priority Function
  const addPriorityAmount = (e, index) => {
    newCollaborator[index].amount = Number(e);
    setNewCollaborator([...newCollaborator]);
  };

  // currency
  // const addCurrency = () => {};

  // Add check for selected collaborator
  const addCollabToWait = index => {
    if (index > 0) {
      if (!collabsModified[index].added) {
        collabsModified[index].added = true;
        setCollabsModified([...collabsModified]);
      } else {
        collabsModified[index].added = false;
        setNewCollaborator(newCollaborator =>
          newCollaborator.filter(
            p =>
              p.email !==
              newCollaborator.find(
                p => p.email === collabsModified[index].email,
              ).email,
          ),
        );
      }
    }
  };

  // Add Role
  const addRole = (item, index) => {
    let mockData = [...newCollaborator];
    if (mockData && !mockData[index]['role'].includes(item)) {
      mockData[index]['role'].push(item);
      setNewCollaborator(mockData);
      setSelectedCollaborator(mockData[index]);
    }
  };

  // Add Contract
  const addContract = (item, itemName, index) => {
    let mockData = [...newCollaborator];
    if (mockData) {
      mockData[index]['contract'] = item;
      mockData[index]['contractFileName'] = itemName;
      setNewCollaborator(mockData);
      setSelectedCollaborator(mockData[index]);
    }
  };

  // Remove Contract
  const deleteContract = index => {
    let mockData = [...newCollaborator];
    if (mockData) {
      mockData[index]['contract'] = undefined;
      mockData[index]['contractFileName'] = undefined;
      setNewCollaborator(mockData);
      setSelectedCollaborator(mockData[index]);
    }
  };

  // Add Role
  const deleteRole = (itemIndex, index) => {
    let mockData = [...newCollaborator];
    if (mockData[index].role) {
      mockData[index].role.splice(itemIndex, 1);
    }
    setNewCollaborator(mockData);
    setSelectedCollaborator(mockData[index]);
  };

  // Delete Collaborator
  const deleteCollaborator = index => {
    let mockData: Array<object> = [];
    if (index > 0) {
      for (let i = 0; i < newCollaborator.length; i++) {
        if (i !== index) {
          const mockArray = [...collabsModified];
          mockData.push(newCollaborator[i]);
          let newObjIndex = mockArray.findIndex(
            obj => obj.email === newCollaborator[index].email,
          );
          mockArray[newObjIndex].added = false;
          setCollabsModified(mockArray);
        }
      }
      setNewCollaborator(mockData);
    }
  };

  function sum(input) {
    if (toString.call(input) !== '[object Array]') return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }

  // Check Percentageb
  const checkPercent = e => {
    e.preventDefault();
    let valueCount = 0;
    let mockData: any = [];
    for (let i = 0; i < newCollaborator.length; i++) {
      mockData.push(newCollaborator[i].percentage);
    }
    let totalPercent: number = Number(sum(mockData));
    if (totalPercent <= 100) {
      newCollaborator.forEach(element => {
        // Note: Contract is no longer required
        // if (!element.contract && type === 'music') {
        //   setError('Please add contract for all collaborators');
        //   setTimeout(() => {
        //     setError('');
        //   }, 4000);
        // } else {
        return valueCount++;
        // }
      });
      if (valueCount === newCollaborator.length) {
        toFinal();
      } else if (type !== 'music') {
        toFinal();
      }
    } else {
      setError('Percentage cannot be greater than 100');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  // To FInal Split Creation
  const toFinal = () => {
    if (newCollaborator.length > 0) {
      localStorage.setItem('collabData', JSON.stringify(newCollaborator));
      if (type === 'music') {
        navigate(`/payout-settings?type=${type}`);
      } else if (type === 'project') {
        navigate(`/payout-settings?type=${type}`);
      } else {
        navigate(`/create-split?type=${type}`);
      }
    } else {
      setError('The minimum collaborators required for a split is one!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const closeModal = () => {
    setError('');
    setPercentageError('');
    setSuccess(false);
  };

  const [isInputFocus, setIsInputFocus] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocus(true);
  };

  const handleSearch: any = e => {
    let new_item: any = [];
    if (savedCollabs !== '' && savedCollabs.length !== 0) {
      for (let i = 0; i < savedCollabs.length; i++) {
        if (
          (savedCollabs[i].name &&
            savedCollabs[i].name
              .toLowerCase()
              .includes(e.target.value.toLowerCase())) ||
          savedCollabs[i].email
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(savedCollabs[i]);
        } else if (e.target.value === '') {
          setCollabsModified(savedCollabs);
        }
      }
      setCollabsModified(new_item);
    }
  };

  const setAfterCreate = item => {
    setNewCollaborator(data =>
      data.concat({
        ...item,
        percentage: 0,
        canView: type === 'music' ? true : false,
        canNegotiate: type === 'music' ? true : false,
        mode: 0,
        role: [],
        frequency: '',
        cycleEnd: '',
        paymentDue: 0,
        payoutDate: 0,
        week: 0,
      }),
    );
    getRequest(`splits/collaborators?paginate=false`)
      .then(res => {
        setSavedCollabs(res);
        setCollabsModified(res);
        setGottenCollabs(false);
      })
      .catch(err => {});
  };

  const setAfterEdit = item => {
    getRequest(`splits/collaborators?paginate=false`)
      .then(res => {
        setSavedCollabs(res.data);
        setCollabsModified(res.data);
      })
      .catch(err => {});
  };

  const [closePageWarning, setClosePageWarning] = useState(false);

  const closeAction = () => {
    navigate('/split-payment');
    localStorage.removeItem('collabData');
    localStorage.removeItem('music');
  };

  // Editing and Deleting of Recipient

  const [selectedRecipient, setSelectedRecipient] = useState<any>({});
  const [showDeleteCollabWarning, setShowDeleteCollabWarning] = useState(false);
  const [deleteInProcess, setDeleteInProcess] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleDeleteRecipient = () => {
    // Delete Process
    setDeleteInProcess(true);
    setTimeout(() => {
      setDeleteInProcess(false);
    }, 15000);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators/${selectedRecipient.id}`,
        { headers: { Authorization } },
      )
      .then(res => {
        setSuccess(true);
        setShowDeleteCollabWarning(false);
        axios
          .get(
            `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators?paginate=false`,
            {
              headers: { Authorization },
            },
          )
          .then(res => {
            setSavedCollabs(res.data);
            setCollabsModified(res.data);
            setDeleteInProcess(false);
          })
          .catch(err => {});
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      })
      .catch(err => {
        setDeleteInProcess(false);
      });
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const [createOnProcess, setCreateOnProcess] = useState(false);

  const [accountInfo, setAccountInfo] = useState<any>({});
  const handleAccountDetails = bankInfo => {
    setAccountInfo(bankInfo);
  };

  const createCollaborator = () => {
    setCreateOnProcess(true);
    if (newCollabEmail === '') {
      setCreateOnProcess(false);
      return;
    }

    interface PayloadType {
      email: string;
      name?: string;
      accountInfo?: {
        accountNumber: string;
        accountName: string;
        bankName: string;
        bankId: string;
      };
    }

    const payload: PayloadType = {
      email: newCollabEmail,
    };

    if (accountInfo.name && accountInfo.name.trim().length > 0) {
      payload.name = accountInfo.name.trim();
    }

    if (
      accountInfo.selectedBankId &&
      accountInfo.selectedBankId.trim().length > 0
    ) {
      payload.name = accountInfo.name;
      payload.accountInfo = {
        accountNumber: accountInfo.number,
        accountName: accountInfo.name,
        bankName: accountInfo.selectedBank,
        bankId: accountInfo.selectedBankId.trim(),
      };
    }

    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators`,
        {
          email: newCollabEmail,
          name: accountInfo.name,
          accountInfo: {
            accountNumber: accountInfo.number,
            accountName: accountInfo.name,
            bankName: accountInfo.selectedbank,
            bankId: accountInfo.selectedBankId,
          },
        },
        headers,
      )
      .then(res => {
        setCreateOnProcess(false);
        setAfterCreate(res.data);
        setNewCollabEmail('');
        setOpenCollaboratorSettings(false);
      })
      .catch(err => {
        setError(err.response.data.message);
        setNewCollabEmail('');
        setAccountInfo({});
        setCreateOnProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  return (
    <>
      {showEditModal ? (
        <EditCollaborator
          closeFunc={() => setShowEditModal(false)}
          setFunc={setAfterEdit}
          item={selectedRecipient}
        />
      ) : null}
      {openAdd ? (
        <AddCollaborator
          setFunc={setAfterCreate}
          closeFunc={() => setOpenAdd(false)}
        />
      ) : null}

      {closePageWarning ? (
        <CloseWarning
          handleAction={closeAction}
          closeFunc={() => setClosePageWarning(false)}
        />
      ) : null}

      {showDeleteCollabWarning ? (
        <CollabDeleteWarning
          closeFunc={() => setShowDeleteCollabWarning(false)}
          handleAction={handleDeleteRecipient}
          item={selectedRecipient}
          onProcess={deleteInProcess}
        />
      ) : null}

      {success ? (
        <Alert
          closeFunc={closeModal}
          img="/success.png"
          message="Success!"
          miniMessage={
            'You have deleted this recipient from the recipient list successfully!'
          }
        />
      ) : null}

      <MainWrapper
        style={{ width: '100%', background: '#D9D9D933' }}
        left="0"
        top="4.5rem"
        onClick={() => {
          setIsInputFocus(false);
          setOpenCollaboratorSettings(false);
          setOpenRevenueSetting(false);
        }}
      >
        <FragmentHeader
          showBack={true}
          pageName={`Create Split`}
          setCloseWarning={() => setClosePageWarning(true)}
        />
        <form
          onSubmit={e => {
            checkPercent(e);
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        >
          <SearchPos position="fixed">
            <ButtonWrap mobileHide={false}>
              {/* <CustomButtonDark type="submit">
                <ButtonFlex>Setup Split</ButtonFlex>
              </CustomButtonDark> */}
            </ButtonWrap>
          </SearchPos>
          <BreadcrumbArea
            style={{
              margin: '4rem 0 10rem 0',
            }}
          >
            <ProgressBar>
              <ProgressLine
                onClick={() => {
                  if (type === 'music') {
                    navigate('/music-split');
                  } else if (type === 'project') {
                    navigate('/project-split');
                  } else {
                    navigate('/select-split-type');
                  }
                }}
                style={{
                  cursor: 'pointer',
                }}
              ></ProgressLine>
              <ProgressLine bg="#191919"></ProgressLine>
              <ProgressLine></ProgressLine>
            </ProgressBar>
            <AccountNoCenter>
              <div>
                {type === 'project' ? (
                  <>
                    <h3>Add Participants</h3>
                    <p>Invite up to 30 people to this project</p>
                  </>
                ) : (
                  <>
                    <h3>Add Recipients</h3>
                    <p>
                      Invite co-writers and rights holders to join this split
                    </p>
                  </>
                )}
              </div>
            </AccountNoCenter>
            <MainFlex>
              <PairSection width="40%" mobileMargin="0">
                <div
                  style={{
                    boxShadow: 'none',
                    background: 'none',
                  }}
                >
                  {newCollaborator && newCollaborator.length > 0
                    ? newCollaborator.map((item, index) => (
                        <SplitUserMainWrap
                          key={index}
                          onClick={e => {
                            e.stopPropagation();
                            setActiveCollaborator(index);
                            setOpenRevenueSetting(true);
                            setSelectedCollaborator(item);
                          }}
                          style={
                            openCollaboratorSettings &&
                            activeCollaborator === index
                              ? {
                                  border: '1px solid #8975F3',
                                }
                              : {}
                          }
                        >
                          {activeItem === index ? (
                            <AccountToolTip>
                              <p>
                                {item.accountInfo
                                  ? `${item.accountInfo.accountNumber}`
                                  : ''}
                                <span>
                                  <br />
                                  {`${
                                    item.accountInfo
                                      ? item.accountInfo.bankName
                                      : ''
                                  }`}
                                </span>
                              </p>
                            </AccountToolTip>
                          ) : null}
                          <SplitUsersWrap alignVertical="center">
                            {newCollaborator.length > 1 ? (
                              <section
                                style={{
                                  width: '1.3rem',
                                  cursor: 'pointer',
                                }}
                              >
                                {index > 0 ? (
                                  <Icon.X
                                    onClick={e => {
                                      e.stopPropagation();
                                      deleteCollaborator(index);
                                    }}
                                    size={15}
                                    color="#757575"
                                  />
                                ) : null}
                              </section>
                            ) : null}
                            <div
                              onMouseOver={() => {
                                setActiveItem(index);
                              }}
                              onMouseLeave={() => setActiveItem(-1)}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                }}
                              >
                                <SplitAvatar>
                                  {item.name ? item.name[0] : ''}
                                  {!item.name && item.email
                                    ? item.email[0].toUpperCase()
                                    : ''}
                                </SplitAvatar>
                                <h3>
                                  {item.name}{' '}
                                  {!item.name && item.email ? item.email : ''}
                                  {type === 'project' ? (
                                    <>
                                      <span>{index === 0 ? '' : ''}</span>
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        {index === 0 ? '. Owner' : ''}
                                      </span>
                                    </>
                                  )}
                                </h3>
                              </div>
                            </div>
                            <SmallInputWrap>
                              <div>
                                <div className="hide-amount-input">
                                  <NumericFormat
                                    value={item.amount}
                                    thousandSeparator={true}
                                    onValueChange={e =>
                                      addPriorityAmount(e.formattedValue, index)
                                    }
                                  />
                                </div>
                                {item.priorExist || type === 'project' ? (
                                  <AmountInput>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row-reverse',
                                      }}
                                    >
                                      <p>{CommaNumber(item.amount || '0')}</p>
                                    </div>
                                    {/* <label>Amount (NGN)</label> */}
                                  </AmountInput>
                                ) : (
                                  <>
                                    {type === 'project' ? (
                                      <>
                                        <AmountInput>
                                          <div
                                            style={{
                                              border: '1px solid black',
                                            }}
                                          >
                                            <p>{item.amount || '0'}</p>
                                          </div>
                                          {/* <label>Amount (NGN)</label> */}
                                        </AmountInput>
                                      </>
                                    ) : (
                                      <>
                                        <PercentageInput>
                                          <p
                                            onClick={e => {
                                              e.stopPropagation();
                                              setOpenRevenueSetting(true);
                                              setActiveCollaborator(index);
                                              setSelectedCollaborator(item);
                                            }}
                                            onChange={e => {
                                              e.stopPropagation();
                                              addPrecentage(e, index);
                                            }}
                                          >
                                            {item.percentage}%
                                          </p>
                                        </PercentageInput>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </SmallInputWrap>
                            {openCollaboratorSettings ? (
                              <CollaboratorSettings
                                item={selectedCollaborator}
                                checkCanView={checkCanView}
                                index={activeCollaborator}
                                email={newCollabEmail}
                                accountDetails={handleAccountDetails}
                                setEmail={setNewCollabEmail}
                                role={newCollabRole}
                                setRole={setNewCollabRole}
                                createOnProcess={createOnProcess}
                                handleCreate={createCollaborator}
                                closeFunc={e => {
                                  setOpenCollaboratorSettings(false);
                                  setActiveCollaborator(-1);
                                  e.stopPropagation();
                                }}
                                openCreate={() => setIsInputFocus(true)}
                              />
                            ) : null}
                            {openRevenueSetting ? (
                              <RevenueSettings
                                item={selectedCollaborator}
                                index={activeCollaborator}
                                addRole={addRole}
                                addPriorityAmount={addPriorityAmount}
                                addPercentage={addPrecentage}
                                mode="create"
                                type={type}
                                onProcess={false}
                                patchCollaborator={() => {}}
                                closeFunc={() => {
                                  setOpenRevenueSetting(false);
                                  setActiveCollaborator(-1);
                                }}
                                deleteRole={deleteRole}
                                splitCollaborators={newCollaborator}
                                addContract={addContract}
                                deleteContract={deleteContract}
                                addWeek={e => addWeek(e, activeCollaborator)}
                                addFrequency={e =>
                                  addFrequency(e, activeCollaborator)
                                }
                                addStartDate={e =>
                                  addStartDate(e, activeCollaborator)
                                }
                                addCycleEnd={e =>
                                  addCycleEnd(e, activeCollaborator)
                                }
                                addPaymentDue={e =>
                                  addpaymentDue(e, activeCollaborator)
                                }
                                addPayoutDate={addPayoutDate}
                                addCurency={() => {}}
                                addTime={e => addTime(e, activeCollaborator)}
                              />
                            ) : null}
                          </SplitUsersWrap>
                          {item.capExist && !item.priorExist ? (
                            <SplitUsersWrap>
                              <div></div>
                              <SmallInputWrap>
                                <div>
                                  <AmountInput>
                                    <div>
                                      <span
                                        style={{
                                          color: '#ababab',
                                        }}
                                      >
                                        &#8358;
                                      </span>
                                      <input
                                        required
                                        type="number"
                                        onKeyPress={e => numOnly(e)}
                                        onChange={e => addCapAmount(e, index)}
                                      />
                                    </div>
                                    <label>Earning Cap</label>
                                  </AmountInput>
                                </div>
                              </SmallInputWrap>
                            </SplitUsersWrap>
                          ) : null}
                        </SplitUserMainWrap>
                      ))
                    : null}
                  {isInputFocus ? (
                    <SelectCollaborator
                      handleInputFocus={handleInputFocus}
                      setOpenAdd={setOpenAdd}
                      handleSearch={handleSearch}
                      collabsModified={collabsModified}
                      setShowEditModal={setShowEditModal}
                      setSelectedRecipient={setSelectedRecipient}
                      setShowDeleteCollabWarning={setShowDeleteCollabWarning}
                      pushCollaborator={pushCollaborator}
                      closeFunc={() => setIsInputFocus(false)}
                      openCreate={setOpenCollaboratorSettings}
                      addCollabToWait={addCollabToWait}
                    />
                  ) : null}
                  {type === 'project' ? (
                    <>
                      <BlueText
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '13px',
                          cursor: 'pointer',
                          margin: '1rem 0',
                        }}
                        onClick={e => {
                          setIsInputFocus(true);
                          e.stopPropagation();
                        }}
                      >
                        <Icon.Plus
                          size={20}
                          style={{ margin: '0 0.5rem 0 0' }}
                        />
                        Add another participant
                      </BlueText>
                    </>
                  ) : (
                    <>
                      <BlueText
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '13px',
                          cursor: 'pointer',
                          margin: '1rem 0',
                        }}
                        onClick={e => {
                          setIsInputFocus(true);
                          e.stopPropagation();
                        }}
                      >
                        <Icon.Plus
                          size={20}
                          style={{ margin: '0 0.5rem 0 0' }}
                        />
                        Add another collaborator
                      </BlueText>
                    </>
                  )}

                  <BigBtn
                    style={{
                      width: '100%',
                    }}
                  >
                    <button>Continue</button>
                  </BigBtn>
                </div>

                {newCollaborator && newCollaborator.length > 0 ? (
                  <>
                    {newCollaborator.slice(0, 1).map((item, index) =>
                      item.mode === 1 ? (
                        <Disclaimer
                          width="16rem"
                          key={index}
                          style={{
                            margin: '0 auto',
                            justifyContent: 'center',
                          }}
                        >
                          <Icon.Info />
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <p>"Fixed amount are usually paid out first"</p>
                          </div>
                        </Disclaimer>
                      ) : null,
                    )}
                  </>
                ) : null}
              </PairSection>
            </MainFlex>
          </BreadcrumbArea>
        </form>
      </MainWrapper>

      {error ? (
        <Alert
          closeFunc={closeModal}
          img="/failed.png"
          message="An error occurred!!"
          miniMessage={error}
        />
      ) : null}

      {percentageError ? (
        <PopUpModal
          handleClose={closeModal}
          msg="An Error occured!"
          extraMsg={percentageError}
          icon="fas fa-exclamation"
          iconColor="red"
        />
      ) : null}
    </>
  );
};

export default MainContent;

// export const splitNav = [
//   {
//     name: 'Percentage',
//     borderRadius: '4px 0 0 4px',
//   },
//   {
//     name: 'Amount',
//     borderRadius: '0 4px 4px 0',
//   },
// ];
