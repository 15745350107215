const hiddenChars = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
  ',',
  '*',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '_',
  '+',
  '=',
  '`',
  ';',
  ':',
  '[',
  ']',
  '{',
  '}',
  '|',
  '~',
  `'`,
  '"',
  '\\',
];

const exemptChars = (arr: any = []) => {
  if (arr.length > 0) {
    const restrictedChars = hiddenChars.filter((x: string) => !arr.includes(x));
    return restrictedChars;
  } else return hiddenChars;
};

const Constraints = {
  hiddenChars,
  exemptChars,
};

export default Constraints;
