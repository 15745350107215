import React, { useEffect, useState } from 'react';
import AppHeader from './../storeComp/mainContent/header/header';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from 'app/components/transactions/style';
import { SearchArea } from '../orderComp/mainContent/sectionOne/styles';
import {
  ItemGrid,
  ItemBox,
  ImageStack,
  SplitUsersPopup,
  SplitUsersWrap,
  SplitAvatar,
  MenuHeader,
  MenuHeaderText,
  DotNotifier,
} from './style';
import { FlexedApart } from '../merch/singleMerch/style';
import * as Icon from 'react-feather';
import axios from 'axios';
import {
  PaginationWrapper,
  PaginationCont,
  PaginationTextWrap,
} from '../merch/productList/styles';
import EmptyState from '../emptyState/emptyState';
import { CenteredAnimeWrap } from 'styles/animationStyle';
import { MoonLoader } from 'react-spinners';
import { useNavigate } from 'react-router';
import { InAppHeader } from './../storeComp/mainContent/styles';
import Blocked from '../transactions/blocked';
import JoinSplit from './joinSplit';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import { getRequest } from 'utils/request';

const MainContent = () => {
  const width = window.innerWidth;
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState(-1);
  // const [summary, setSummary] = useState<any>({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [openJoin, setOpenJoin] = useState(false);
  const [inviteSplitData, setInviteSplitData] = useState<any>({});
  const [inviteSplitCollaboratorData, setInviteSplitCollaboratorData] =
    useState<any>({});
  const [discardInprocess, setDiscardInProcess] = useState(false);
  const [acceptSuccess, setAcceptSuccess] = useState(false);
  const [discardSuccess, setDiscardSuccess] = useState(false);
  const [error, setError] = useState('');

  const [blocked, setBlocked] = useState(false);
  const [bvnKycStatus, setBvnKycStatus] = useState<any>({});
  const [idKycStatus, setIdKycStatus] = useState<any>({});

  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  useEffect(() => {
    const kyc = JSON.parse(localStorage.getItem('cky') || '{}');
    if (kyc && Object.keys(kyc)) {
      setBvnKycStatus(kyc.bvn);
      setIdKycStatus(kyc.document);
    }
    if (activeIndex === 0) {
      localStorage.setItem('deixn', JSON.stringify(activeIndex));
    }
  }, [activeIndex]);

  const handleSetIndex = (index: number) => {
    setHoveredItem(index);
  };

  const handleRemoveIndex = () => {
    setHoveredItem(-1);
  };

  // Fetch Each Collaborator
  useEffect(() => {
    getRequest(`splits/collaborators?sortBy=createdAt:desc&paginate=false`)
      .then(res => {
        setAllCollaborators(res);
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/splits/summary`)
      .then(res => {
        // setSummary(res);
      })
      .catch(err => {});
  }, [Authorization]);

  const userBankAccount = JSON.parse(
    localStorage.getItem('accountInfo') || '{}',
  );

  const [finalCollaborators, setFinalCollaborators] = useState<any>([]);
  const [allCollaborators, setAllCollaborators] = useState<any>('');

  const fetchCollabDetails = item => {
    let mockData: Array<object> = [];
    if (allCollaborators && item && item.length > 0) {
      for (let i = 0; i < item.length; i++) {
        if (item[i].collaboratorId === loggedUser.user.id) {
          mockData.unshift({
            name: userBankAccount.accountInfo.accountName,
            email: loggedUser.user.email,
            id: userBankAccount.user,
            accountInfo: {
              accountName: userBankAccount.accountInfo.accountName,
              accountNumber: userBankAccount.accountInfo.accountNumber,
              bankName: userBankAccount.accountInfo.bankName,
            },
            percentage: item[0].percentage,
            amount: item[0].amount,
          });
        }

        for (let k = 0; k < allCollaborators.length; k++) {
          if (allCollaborators[k].id === item[i].collaboratorId) {
            mockData.push({
              name: allCollaborators[k].name,
              email: allCollaborators[k].email,
              percentage: item[i].percentage,
              amount: item[i].amount,
            });
          }
        }
      }
      setFinalCollaborators(mockData);
    }
  };

  // Fetch Splits

  const [splits, setSplits] = useState<any>('');
  const [splitsMutable, setSplitsMutable] = useState<any>([]);
  let [page, setPage] = useState<any>(1);
  const [totalPages, setTotalPages] = useState<any>(0);
  const [totalResults, setTotalResult] = useState<any>(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSplit = () => {
    getRequest(
      `splits?include=collaborators.collaboratorId&sortBy=createdAt:desc&limit=12&type=${
        activeIndex === 0 ? 'owner' : 'added'
      }`,
    )
      .then(res => {
        setSplits(res);
        setSplitsMutable(res.results);
        setTotalPages(res.totalPages);
        setTotalResult(res.totalResults);
        setPage(res.page);
      })
      .catch(err => {});
  };

  const [benefit, setBenefit] = useState<any>([]);

  useEffect(() => {
    getRequest(`benefits?include=benefitType`)
      .then(res => {
        setBenefit(res.results);
        setTotalPages(res.totalPages);
        setTotalResult(res.totalResults);
        setPage(res.page);
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    getSplit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const handleIncrement = () => {
    if (page !== totalPages) {
      setPage(++page);
      getRequest(
        `splits?include=collaborators.collaboratorId&sortBy=createdAt:desc&page=${page}&limit=12`,
      )
        .then(res => {
          setSplits(res);
          setSplitsMutable(res.results);
          setTotalPages(res.totalPages);
          setTotalResult(res.totalResults);
          setPage(res.page);
        })
        .catch(err => {});
    }
  };

  const handleDecrement = () => {
    if (page > 1) {
      setPage(--page);
      getRequest(
        `splits?include=collaborators.collaboratorId&sortBy=createdAt:desc&page=${page}&limit=12`,
      )
        .then(res => {
          setSplits(res);
          setSplitsMutable(res.results);
          setTotalPages(res.totalPages);
          setTotalResult(res.totalResults);
          setPage(res.page);
        })
        .catch(err => {});
    }
  };

  const handleSearch: any = e => {
    let new_item: any = [];
    if (splits !== '' && splits.results.length !== 0) {
      for (let i = 0; i < splits.results.length; i++) {
        if (
          splits.results[i].name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(splits.results[i]);
        } else if (e.target.value === '') {
          setSplitsMutable(splits.results);
          setTotalResult(splits.results.length);
        }
      }
      setSplitsMutable(new_item);
      setTotalResult(new_item.length);
    }
  };

  // Save Single Split Data

  const handleInviteSplit = mainItem => {
    if (activeIndex === 0) {
      localStorage.setItem('deixn', JSON.stringify(activeIndex));
      navigate(`/split/${mainItem.id}`);
    } else if (activeIndex === 1) {
      // eslint-disable-next-line array-callback-return
      mainItem.collaborators.map(item => {
        if (
          item.collaboratorId.email === loggedUser.user.email &&
          item.status === 'negotiating'
        ) {
          setOpenJoin(true);
          handleInviteSplitData(mainItem);
          handleInviteSplitCollaboratorData(item);
        } else if (
          item.collaboratorId.email === loggedUser.user.email &&
          item.status === 'accepted'
        ) {
          localStorage.setItem('deixn', JSON.stringify(activeIndex));
          navigate(`/split/${mainItem.id}`);
        }
      });
    }
  };

  const handleInviteSplitData = item => {
    setInviteSplitData(item);
  };

  const handleInviteSplitCollaboratorData = item => {
    setInviteSplitCollaboratorData(item);
  };

  const discardSplit = () => {
    setDiscardInProcess(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborations/${inviteSplitCollaboratorData.id}/agreement`,
        {
          status: 'rejected',
        },
        headers,
      )
      .then(res => {
        setDiscardInProcess(false);
        setDiscardSuccess(true);
        setOpenJoin(false);
      })
      .catch(err => {
        setError(err.response.data.message);
        setDiscardInProcess(false);
        setTimeout(() => {
          setError('');
        }, 4000);
      });
  };

  const continueToEnterInfo = () => {
    navigate(
      `/split/verify/${inviteSplitCollaboratorData.id}?cId=${inviteSplitCollaboratorData.collaboratorId.id}`,
    );
  };

  const handleClose = () => {
    setAcceptSuccess(false);
    setDiscardSuccess(false);
  };

  // To Benefit Single
  const handleToBenefitSingle = id => {
    navigate(`/benefits/single/${id}`);
  };

  return (
    <>
      {acceptSuccess ? (
        <Alert
          closeFunc={() => {
            handleClose();
            getSplit();
          }}
          img="/success.png"
          message="Hooray!!! 🎉"
          miniMessage={`You have successfully joined ${inviteSplitData.name} split`}
        />
      ) : null}
      {discardSuccess ? (
        <Alert
          closeFunc={() => {
            handleClose();
            getSplit();
          }}
          img="/litter.png"
          message="Discarded Successfully"
          miniMessage={`You have successfully discarded ${inviteSplitData.name} split`}
        />
      ) : null}
      <MainWrapper className="no-scrollbar" mobileTop="-2rem">
        <AppHeader
          showBack={true}
          pageName={`Split Payment (${totalResults})`}
        />
        <InAppHeader>
          <h3>Splits</h3>
        </InAppHeader>
        {width > 728 ? (
          <SearchPos>
            <button
              style={{
                margin: '0 1rem 0 0',
                color: '#fff',
                padding: '0.5rem 2rem',
                background: '#191919',
                borderRadius: '4px',
                fontSize: '0.8rem',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                if (
                  bvnKycStatus.status.toLowerCase() === 'verified' &&
                  idKycStatus.status.toLowerCase() === 'verified'
                ) {
                  navigate('/select-split-type');
                } else {
                  setBlocked(true);
                }
              }}
            >
              <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
              New Split
            </button>
            <SearchArea>
              <i>
                <Icon.Search />
              </i>
              <input
                type="text"
                placeholder="Search your split payments"
                onChange={e => handleSearch(e)}
              />
            </SearchArea>
          </SearchPos>
        ) : null}
        <BreadcrumbArea
          style={{
            margin: width > 728 ? '3rem 0 0 0' : '8rem 0 0 0',
          }}
        >
          <FlexedApart
            style={{
              alignItems: 'center',
              width: '100%',
            }}
          >
            <MenuHeader>
              {filterArray.map((item, index) => (
                <MenuHeaderText
                  key={index}
                  onClick={() => setActiveIndex(index)}
                  color={activeIndex === index ? '#b0b0b0' : '#000'}
                  border={activeIndex === index ? '3px solid #191919' : 'none'}
                >
                  {item.name}
                </MenuHeaderText>
              ))}
            </MenuHeader>
            {width < 728 ? (
              <button
                style={{
                  margin: '0 0 0 0',
                  color: '#fff',
                  padding: '0.5rem 2rem',
                  background: '#191919',
                  borderRadius: '4px',
                  fontSize: '0.8rem',
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  top: '0.5rem',
                }}
                onClick={() => {
                  if (
                    bvnKycStatus.status.toLowerCase() === 'verified' &&
                    idKycStatus.status.toLowerCase() === 'verified'
                  ) {
                    navigate('/select-split-type');
                  } else {
                    setBlocked(true);
                  }
                }}
              >
                <Icon.Plus size={15} style={{ margin: '0 0.5rem 0 0' }} />
                New Split
              </button>
            ) : null}
          </FlexedApart>
          {activeIndex === 2 ? (
            <>
              <ItemGrid>
                {benefit.length > 0 ? (
                  <>
                    {benefit.map((item, index) => (
                      <>
                        <ItemBox
                          key={index}
                          onClick={() => handleToBenefitSingle(item.id)}
                        >
                          <img
                            src={
                              item.avatar ||
                              'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                            }
                            alt={item.description || 'Benefit Description'}
                          />
                          <div>
                            <FlexedApart>
                              <h3>{item.benefitType.name || 'Benefit Name'}</h3>
                            </FlexedApart>
                          </div>
                          <FlexedApart>
                            <ImageStack>
                              <p>₦{item.amount.toLocaleString()}</p>
                            </ImageStack>

                            <div
                              style={{
                                background: 'rgba(223, 168, 116, 0.2)',
                                color: '#D58D49',
                                display: 'inline-block',
                              }}
                            >
                              <div
                                style={{
                                  background: 'rgba(223, 168, 116, 0.2)',
                                  fontSize: '10px',
                                  padding: '3px 5px',
                                  borderRadius: '4px',
                                }}
                              >
                                <span
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <Icon.Zap
                                    size={12}
                                    style={{
                                      position: 'relative',
                                      top: '0.05rem',
                                    }}
                                  />
                                  {item.frequency}
                                </span>
                              </div>
                            </div>
                          </FlexedApart>
                        </ItemBox>
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <EmptyState
                      img="/invoice.svg"
                      header="Time to get paid!"
                      link="/add-collaborators"
                      linkMask="Create Split Payment"
                      extraText="and get paid easily"
                    />
                  </>
                )}
              </ItemGrid>
            </>
          ) : (
            <>
              {splits !== '' ? (
                <>
                  {splits.results.length !== 0 && splitsMutable.length !== 0 ? (
                    <>
                      <ItemGrid>
                        {splitsMutable.map((item, index) => (
                          <ItemBox
                            onClick={() => handleInviteSplit(item)}
                            key={index}
                          >
                            {item.collaborators.map((item, index) =>
                              (item.collaboratorId
                                ? item.collaboratorId.email
                                : '') === loggedUser.user.email &&
                              item.status === 'negotiating' ? (
                                <DotNotifier key={index}>
                                  <div></div>
                                </DotNotifier>
                              ) : null,
                            )}

                            <img
                              src={
                                item.avatar
                                  ? item.avatar
                                  : 'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png'
                              }
                              alt={item.name}
                            />
                            <div>
                              <FlexedApart>
                                <h3
                                  style={{
                                    width: '60%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {item.name}
                                </h3>
                              </FlexedApart>
                            </div>
                            <FlexedApart>
                              <ImageStack
                                onMouseOver={() => {
                                  handleSetIndex(index);
                                  fetchCollabDetails(item.collaborators);
                                }}
                                onMouseLeave={() => handleRemoveIndex()}
                              >
                                <p>
                                  {item.collaborators
                                    ? item.collaborators.length
                                    : ''}{' '}
                                  {item.collaborators &&
                                  item.collaborators.length > 1
                                    ? 'recipients'
                                    : 'recipient'}
                                </p>
                              </ImageStack>

                              <div
                                style={{
                                  background: 'rgba(223, 168, 116, 0.2)',
                                  color: '#D58D49',
                                  display: 'inline-block',
                                }}
                              >
                                <div
                                  style={{
                                    background: 'rgba(223, 168, 116, 0.2)',
                                    fontSize: '10px',
                                    padding: '3px 5px',
                                    borderRadius: '4px',
                                  }}
                                >
                                  {item.splitCategory === 'general' ? (
                                    <span
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      project
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      {item.splitCategory}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </FlexedApart>
                            {hoveredItem === index ? (
                              <>
                                {finalCollaborators &&
                                finalCollaborators.length > 0 ? (
                                  <SplitUsersPopup>
                                    <h3>Split breakdown</h3>
                                    {finalCollaborators.map((item, index) => (
                                      <SplitUsersWrap key={index}>
                                        <SplitAvatar>
                                          {item.name
                                            ? item.name[0]
                                            : item.email}
                                        </SplitAvatar>
                                        <div>
                                          <h3>{item.name}</h3>
                                          {!item.name && item.email ? (
                                            <h3>{item.email}</h3>
                                          ) : null}
                                          <p>
                                            {item.name && item.email
                                              ? item.email
                                              : ''}
                                          </p>
                                        </div>
                                        {item.percentage || !item.amount ? (
                                          <p>{item.percentage}%</p>
                                        ) : (
                                          <p>&#8358;{item.amount}</p>
                                        )}
                                      </SplitUsersWrap>
                                    ))}
                                  </SplitUsersPopup>
                                ) : null}
                              </>
                            ) : null}
                          </ItemBox>
                        ))}
                      </ItemGrid>

                      {totalPages > 1 && (
                        <PaginationWrapper>
                          <PaginationCont>
                            <p onClick={() => handleDecrement()}>
                              <Icon.ChevronLeft />
                            </p>
                            <PaginationTextWrap>
                              <h4>{page}</h4>
                              <span>of</span>
                              <h4>{totalPages}</h4>
                            </PaginationTextWrap>
                            <p onClick={() => handleIncrement()}>
                              <Icon.ChevronRight />
                            </p>
                          </PaginationCont>
                        </PaginationWrapper>
                      )}
                    </>
                  ) : (
                    <EmptyState
                      img="/invoice.svg"
                      header="Time to get paid!"
                      link="/add-collaborators"
                      linkMask="Create Split Payment"
                      extraText="and get paid easily"
                    />
                  )}
                </>
              ) : (
                <CenteredAnimeWrap>
                  <MoonLoader size={40} color="#969696" />
                </CenteredAnimeWrap>
              )}
            </>
          )}
        </BreadcrumbArea>
        {blocked ? <Blocked closeFunc={() => setBlocked(false)} /> : null}
        {openJoin ? (
          <JoinSplit
            handleAction={continueToEnterInfo}
            discardAction={discardSplit}
            actionInProcess={false}
            discardInProcess={discardInprocess}
            error={error}
            splitData={inviteSplitData}
            collaboratorData={inviteSplitCollaboratorData}
            closeFunc={() => {
              setOpenJoin(false);
            }}
            actionText="Continue"
          />
        ) : null}
      </MainWrapper>
    </>
  );
};

export default MainContent;

const filterArray = [
  {
    name: 'Owned',
  },
  {
    name: 'Invited',
  },
  // {
  //   name: 'Benefit',
  // },
];
