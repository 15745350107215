import React, { useState, useEffect } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from 'app/components/transactions/style';
import {
  SplitUsersWrap,
  AccountNoCenter,
  MiniHeader,
  SingleInfoFlex,
  InputWrap,
  SplitAvatar,
  ProgressBar,
  ProgressLine,
  InputActPlacehold,
  CollabSuggestWrap,
  Disclaimer,
} from './style';
import {
  ButtonWrap,
  CustomButtonDark,
  ButtonFlex,
} from '../storeComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import { Line } from 'app/components/transactions/style';
import { numOnly } from 'utils/numOnly';
import { TicketInput } from '../paymentLink/style';
import axios from 'axios';
import CreateWarning from './createWarning';
import SplitSuccess from './success';
import { useNavigate } from 'react-router';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import CommaNumber from 'comma-number';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import CloseWarning from './closeWarning';
import ReactCountryFlag from 'react-country-flag';
import { Countries } from './setCurrency';
import { sum } from 'utils/sum';
import PopUpModal from '../merch/popupModal';
import { handleFileUpload } from 'utils/imageUpload';
import { useSearchParams } from 'react-router-dom';

const MainContent = () => {
  const navigate = useNavigate();
  const collaborators = JSON.parse(localStorage.getItem('collabData') || '{}');
  const [finalCollaborators, setFinalCollaborators] = useState([]);

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  // Modals Managament
  const [openWarning, setOpenWarning] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false);

  // Split Final Data
  const [name, setName] = useState('');
  const [splitType, setSplitType] = useState('time');
  const [limitValue, setLimitValue] = useState<any>(0);
  const [frequency, setFrequency] = useState('daily');
  const [returnedImages, setReturnedImages] = useState<any>(
    'http://res.cloudinary.com/merchro/image/upload/v1674223146/split1_jh701c.png',
  );
  const [totalAmount, setTotalAmount] = useState<any>();
  const [amountArray, setAmountArray] = useState<any>([]);
  const [error, setError] = useState('');
  const [clientErr, setClientErr] = useState('');
  const [success, setSuccess] = useState(false);
  const [hasPercent, setHasPercent] = useState(false);

  let loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');

  // Get Collaborator Data ready for Payload
  useEffect(() => {
    const collaborators = JSON.parse(
      localStorage.getItem('collabData') || '{}',
    );
    let mockData: any = [];

    if (collaborators && collaborators.length > 0) {
      for (let i = 0; i < collaborators.length; i++) {
        mockData.push({
          collaboratorId: collaborators[i].id,
          cap: collaborators[i].cap,
          amount: collaborators[i].amount,
          canView: collaborators[i].canView,
          canNegotiate: collaborators[i].canNegotiate,
          percentage: collaborators[i].amount ? 0 : collaborators[i].percentage,
        });

        if (collaborators[i].amount) {
          setAmountArray(item => item.concat(collaborators[i].amount));
        }
        if (collaborators[i].percentage > 0) {
          setHasPercent(true);
        }
      }
      setFinalCollaborators(mockData);
    }
  }, []);

  const [fee, setFee] = useState<any>({});

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/payments/fees`, {
        headers: { Authorization },
      })
      .then(res => {
        if (res.data) {
          setFee(res.data);
        }
      })
      .catch(err => {});
  }, []);

  useEffect(() => {
    if (hasPercent && amountArray && amountArray.length > 0) {
      setTotalAmount(Number(sum(amountArray)) + 2000);
    } else if (amountArray && amountArray.length > 0) {
      setTotalAmount(sum(amountArray));
    } else setTotalAmount(2000 + Number(fee.outboundTransfer | 0));
  }, [amountArray, fee, hasPercent]);

  useEffect(() => {
    if (finalCollaborators && finalCollaborators.length > 0) {
      if (totalAmount > limitValue && totalAmount >= 2000) {
        setLimitValue(
          totalAmount +
            finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      } else if (!totalAmount && !limitValue) {
        setLimitValue(
          2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      } else if (totalAmount > limitValue && totalAmount < 2000) {
        setLimitValue(
          2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
      }
    }
  }, [totalAmount, finalCollaborators, fee, limitValue]);

  const handleFile = async (files: any) => {
    handleFileUpload({
      files,
      setError: setClientErr,
      setReturnedImages,
    });
  };

  const handleClose = () => {
    setError('');
    setClientErr('');
    setSuccess(false);
  };

  // Create Split Functions

  const basicPayload = {
    name,
    avatar: returnedImages,
    splitType,
    currency: 'NGN',
    splitCategory: type === 'music' ? 'music' : 'general',
    collaborators: finalCollaborators,
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  // Check Amount Logic
  const [isValid, setIsValid] = useState<boolean | string>('');

  const createSplitWarning = e => {
    e.preventDefault();
    if (name.length >= 5) {
      if (!limitValue && !totalAmount) {
        setOpenWarning(true);
      } else if (limitValue && !totalAmount && limitValue >= 2000) {
        if (limitValue >= 2000) {
          setOpenWarning(true);
        } else {
          setClientErr('The minimum amount is NGN2,000 and the applicable fee');
          setLimitValue(
            2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
          setTimeout(() => {
            setClientErr('');
          }, 4000);
        }
      } else if (limitValue > 0 && limitValue >= 2000) {
        if (limitValue >= totalAmount) {
          setOpenWarning(true);
        } else {
          setClientErr(
            "The minimum amount cannot be than lower than the sum of recipient's amount",
          );
          setLimitValue(
            totalAmount +
              finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
          setTimeout(() => {
            setClientErr('');
          }, 4000);
        }
      } else if (
        limitValue > 0 &&
        limitValue < 2000 &&
        limitValue < totalAmount
      ) {
        if (
          limitValue <= totalAmount &&
          totalAmount < 2000 &&
          limitValue < 2000
        ) {
          setClientErr(
            `The minimum amount is NGN${CommaNumber(
              2000,
            )} and the applicable fee`,
          );
          setLimitValue(
            2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
        } else if (limitValue < totalAmount && totalAmount > 2000) {
          setClientErr(
            `The minimum amount is NGN${CommaNumber(
              totalAmount,
            )} and the applicable fee amount`,
          );
          setLimitValue(
            totalAmount +
              finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
        }
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      } else {
        setClientErr('The minimum amount is #2,000 and the applicable fee');
        setLimitValue(
          2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    }
  };

  const checkAmountValidation = () => {
    if (totalAmount || limitValue) {
      if (limitValue > 0 && limitValue >= 2000) {
        if (limitValue >= totalAmount) {
          setIsValid(true);
        } else {
          setClientErr(
            "The minimum amount cannot be lower than the sum of recipient's amount",
          );
          setLimitValue(
            totalAmount +
              finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
          setTimeout(() => {
            setClientErr('');
          }, 4000);
        }
      } else if (
        limitValue > 0 &&
        limitValue < 2000 &&
        limitValue < totalAmount
      ) {
        if (
          limitValue <= totalAmount &&
          totalAmount < 2000 &&
          limitValue < 2000
        ) {
          setClientErr(`The minimum amount is NGN${CommaNumber(2000)}`);
          setLimitValue(
            2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
        } else if (limitValue < totalAmount && totalAmount > 2000) {
          setClientErr(`The minimum amount is NGN${CommaNumber(totalAmount)}`);
          setLimitValue(
            totalAmount +
              finalCollaborators.length * Number(fee.outboundTransfer | 0),
          );
        }
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      } else {
        setClientErr('The minimum amount is #2,000');
        setLimitValue(
          2000 + finalCollaborators.length * Number(fee.outboundTransfer | 0),
        );
        setTimeout(() => {
          setClientErr('');
        }, 4000);
      }
    }
  };

  const [splitBank, setSplitBank] = useState('');
  const [splitAccNo, setSplitAccNo] = useState('');
  const [splitAccName, setSplitAccName] = useState('');
  const [onProcess, setOnProcess] = useState(false);

  const createSplit = async () => {
    setOnProcess(true);
    try {
      if (limitValue > 0) {
        basicPayload['limitValue'] = limitValue;
      }
      if (splitType === 'time') {
        basicPayload['frequency'] = frequency;
      }

      await axios
        .post(
          `${process.env.REACT_APP_BASE_ENDPOINT}/splits`,
          basicPayload,
          headers,
        )
        .then(res => {
          if (res.data.accountInfo) {
            setSplitAccNo(res.data.accountInfo.accountNumber);
            setSplitBank(res.data.accountInfo.bankName);
            setSplitAccName(res.data.accountInfo.accountName);
          }
          setOpenWarning(false);
          localStorage.removeItem('split');
          localStorage.removeItem('collabData');
          setSuccess(true);
          setOnProcess(false);
          setTimeout(() => {
            setSuccess(false);
            navigate('/split-payment');
          }, 1500);
        })
        .catch(err => {
          setError(err.response.data.message);
          setOnProcess(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    } catch {}
  };

  const [closePageWarning, setClosePageWarning] = useState(false);

  const closeAction = () => {
    navigate('/split-payment');
    localStorage.removeItem('collabData');
  };

  return (
    <>
      {success ? (
        <SplitSuccess
          closeFunc={() => navigate('/split-payment')}
          img="/success.png"
          message="Split created successfully!"
          accNo={splitAccNo}
          accName={splitAccName}
          bank={splitBank}
        />
      ) : null}

      {closePageWarning ? (
        <CloseWarning
          handleAction={closeAction}
          closeFunc={() => setClosePageWarning(false)}
        />
      ) : null}

      {clientErr !== '' ? (
        <PopUpModal
          handleClose={handleClose}
          msg="An Error occured"
          extraMsg={clientErr}
          icon="fas fa-exclamation"
          iconColor="#c82b32"
        />
      ) : null}

      {error !== '' ? (
        <Alert
          closeFunc={handleClose}
          message="An Error occured!"
          miniMessage={error}
          img="/failed.png"
        />
      ) : null}

      {openWarning ? (
        <CreateWarning
          closeFunc={() => setOpenWarning(false)}
          actionInProcess={onProcess}
          handleAction={() => createSplit()}
          message="Are you sure you want to create this Split?"
          actionText="Create Split"
          splitName={name}
          splitType={splitType}
          limitValue={limitValue}
          frequency={frequency}
          avatar={returnedImages}
          collaborators={collaborators}
          currency={'NGN'}
        />
      ) : null}

      <MainWrapper
        style={{ width: '100%', background: '#f9f9f9' }}
        left="0"
        top="2.5rem"
        onClick={() => setIsInputFocus(false)}
      >
        <FragmentHeader
          showBack={true}
          pageName={`Create Split`}
          setCloseWarning={() => setClosePageWarning(true)}
        />
        <form onSubmit={e => createSplitWarning(e)}>
          <SearchPos position="fixed">
            <ButtonWrap mobileHide={false}>
              <CustomButtonDark type="submit">
                <ButtonFlex>Create Split</ButtonFlex>
              </CustomButtonDark>
            </ButtonWrap>
          </SearchPos>
          <BreadcrumbArea>
            {/* <PreferenceBlock> */}
            <ProgressBar>
              <ProgressLine></ProgressLine>
              <ProgressLine
                onClick={() => navigate('/add-collaborators')}
                style={{ cursor: 'pointer' }}
              ></ProgressLine>
              <ProgressLine bg="#191919"></ProgressLine>
            </ProgressBar>
            <AccountNoCenter>
              <div>
                <h3>You’re almost done!</h3>
                <p>Just some final details left</p>
              </div>
            </AccountNoCenter>
            <MainFlex>
              <PairSection width="32%" mobileMargin="0">
                <div>
                  <h3>Split Details</h3>
                  <SingleInfoFlex>
                    <section>
                      <img src={returnedImages} alt="Display" />
                      <i>
                        <label htmlFor="file-input">
                          <Icon.Camera
                            size={20}
                            color="#fff"
                            style={{
                              cursor: 'pointer',
                            }}
                          />
                        </label>
                      </i>
                      <input
                        type="file"
                        id="file-input"
                        multiple={false}
                        style={{ display: 'none' }}
                        onChange={e => {
                          handleFile(e.target.files);
                        }}
                      />
                    </section>
                    <div>
                      <h4>{name}</h4>
                      {splitType === 'time' ? (
                        <p>
                          <span>
                            <Icon.Zap
                              size={15}
                              style={{
                                position: 'relative',
                                top: '0.05rem',
                              }}
                            />
                            {frequency}
                          </span>
                        </p>
                      ) : null}
                      {splitType === 'approval' ? (
                        <p>
                          <span>
                            <Icon.Zap
                              size={15}
                              style={{
                                position: 'relative',
                                top: '0.05rem',
                              }}
                            />
                            On Approval
                          </span>
                        </p>
                      ) : null}
                      {splitType === 'instantly' ? (
                        <p>
                          <span>
                            <Icon.Zap
                              size={15}
                              style={{
                                position: 'relative',
                                top: '0.05rem',
                              }}
                            />
                            {splitType}
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </SingleInfoFlex>
                  <Line />
                  <MiniHeader>Split breakdown</MiniHeader>
                  {collaborators && collaborators.length > 0
                    ? collaborators.map((item, index) => (
                        <SplitUsersWrap key={index}>
                          <SplitAvatar>
                            {item.name ? item.name[0] : ''}
                          </SplitAvatar>
                          <div
                            style={{
                              width: '58%',
                            }}
                          >
                            <h3>
                              {item.name}{' '}
                              {index === 0 ? <span> . Owner</span> : ''}
                            </h3>
                            <p>{item.email}</p>
                          </div>
                          {item.percentage || !item.amount ? (
                            <p>{item.percentage}%</p>
                          ) : (
                            <p>&#8358;{CommaNumber(item.amount)}</p>
                          )}
                        </SplitUsersWrap>
                      ))
                    : null}
                </div>
                <Disclaimer width="100%">
                  <Icon.Info />
                  <div>
                    <p>
                      Split to each recipient will cost{' '}
                      <b>&#8358;{Number(fee.outboundTransfer | 0)}</b>. The
                      total cost to split payments to{' '}
                      <b>{finalCollaborators.length}</b> recipients will be{' '}
                      <b>
                        &#8358;
                        {finalCollaborators.length *
                          Number(Number(fee.outboundTransfer | 0))}
                      </b>
                    </p>
                  </div>
                </Disclaimer>
              </PairSection>
              <PairSection width="32%">
                <div>
                  <TicketInput>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <p>Split Name</p>
                      <input
                        placeholder="Split name"
                        required
                        minLength={5}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                  </TicketInput>
                  <TicketInput>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      <p>Receive payments in</p>
                      <InputActPlacehold
                        onClick={e => {
                          setIsInputFocus(!isInputFocus);
                          e.stopPropagation();
                        }}
                        onBlur={() => setIsInputFocus(false)}
                      >
                        <div>
                          <ReactCountryFlag
                            style={{
                              fontSize: '18px',
                              borderRadius: '50%',
                            }}
                            countryCode="NG"
                            svg
                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                            cdnSuffix="svg"
                          />
                          <p
                            style={{
                              color: '#191919',
                              fontSize: '14px',
                            }}
                          >
                            NGN
                          </p>
                        </div>
                        <i>
                          <Icon.ChevronDown size={15} strokeWidth={3} />
                        </i>
                      </InputActPlacehold>
                    </div>
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#ababab',
                        textAlign: 'right',
                        position: 'relative',
                        top: '0rem',
                      }}
                    >
                      A unique NGN account will be generated
                    </p>
                  </TicketInput>

                  <CollabSuggestWrap
                    style={{
                      margin: '0',
                      top: '-2.3rem',
                      width: '100%',
                      zIndex: '1000',
                    }}
                  >
                    {isInputFocus ? (
                      <div>
                        {Countries && Countries.length > 0 ? (
                          Countries.map((item, index) => (
                            <SplitUsersWrap
                              alignVertical="flex-start"
                              key={index}
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    width: '100%',
                                  }}
                                >
                                  <ReactCountryFlag
                                    style={{
                                      fontSize: '1.3rem',
                                      borderRadius: 'none',
                                    }}
                                    countryCode={item.tag}
                                    svg
                                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                    cdnSuffix="svg"
                                    title={item.tag}
                                  />
                                  <div
                                    style={{
                                      width: '70%',
                                    }}
                                  >
                                    <h3
                                      style={{
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '80%',
                                      }}
                                    >
                                      {item.name ? `${item.name}        ` : ''}
                                    </h3>
                                  </div>
                                  {item.tag !== 'NG' ? (
                                    <p>Coming soon</p>
                                  ) : null}
                                </div>
                              </div>
                            </SplitUsersWrap>
                          ))
                        ) : (
                          <p
                            style={{
                              textAlign: 'center',
                              fontSize: '14px',
                              color: '#757575',
                              margin: '1rem 0 0 0',
                            }}
                          >
                            No Option.
                          </p>
                        )}
                      </div>
                    ) : null}
                  </CollabSuggestWrap>
                  <InputWrap>
                    <h4>Split Method</h4>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      <select
                        style={
                          splitType === 'time'
                            ? {
                                width: '47%',
                                margin: '0.1rem 0 0 0',
                              }
                            : {
                                width: '100%',
                                margin: '0.1rem 0 0 0',
                              }
                        }
                        onChange={e => setSplitType(e.target.value)}
                        required
                      >
                        <option value="time">Time based</option>
                        <option value="approval">On Approval</option>
                        <option value="instantly">Instantly</option>
                      </select>
                      {splitType === 'time' ? (
                        <select
                          style={{
                            margin: '0.1rem 0 0 0',
                          }}
                          required
                          value={frequency}
                          onChange={e =>
                            setFrequency(e.target.value.toLocaleLowerCase())
                          }
                        >
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                          <option value="quarterly">Quarterly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      ) : null}

                      {splitType === 'limit' ? (
                        <TicketInput>
                          <div
                            style={{
                              width: '100%',
                            }}
                          >
                            <p>Limit(&#8358;)</p>
                            <input
                              type="number"
                              required
                              onChange={e =>
                                setLimitValue(Number(e.target.value))
                              }
                              onKeyPress={e => numOnly(e)}
                            />
                          </div>
                        </TicketInput>
                      ) : null}
                    </div>
                  </InputWrap>
                  {splitType === 'instantly' ? (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#ababab',
                        textAlign: 'right',
                        position: 'relative',
                        top: '-0.8rem',
                      }}
                    >
                      Split is made immediately any amount is paid
                    </p>
                  ) : null}
                  {splitType === 'time' ? (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#ababab',
                        textAlign: 'right',
                        position: 'relative',
                        top: '-0.8rem',
                      }}
                    >
                      Split is made {frequency}
                    </p>
                  ) : null}
                  {splitType === 'limit' ? (
                    <p
                      style={{
                        fontSize: '12px',
                        color: '#ababab',
                        textAlign: 'right',
                        position: 'relative',
                        top: '-0.8rem',
                      }}
                    >
                      Split is made when the money in the account gets to
                      &#8358;{limitValue}
                    </p>
                  ) : null}
                  <TicketInput>
                    <div
                      style={
                        isValid || isValid === ''
                          ? {
                              border: '0.5px solid #dadada',
                              width: '100%',
                            }
                          : {
                              border: '0.5px solid #c82b38',
                              width: '100%',
                            }
                      }
                    >
                      <p>Minimum amount required</p>
                      <input
                        placeholder="Minimum amount"
                        type="number"
                        required
                        value={limitValue}
                        onKeyPress={e => numOnly(e)}
                        onBlur={() => checkAmountValidation()}
                        onChange={e => setLimitValue(Number(e.target.value))}
                      />
                    </div>
                  </TicketInput>
                  <p
                    style={{
                      fontSize: '12px',
                      color: '#ababab',
                      textAlign: 'right',
                      position: 'relative',
                      top: '-0.8rem',
                    }}
                  >
                    Minimum amount required to enable split is &#8358;
                    {limitValue}
                  </p>
                </div>
              </PairSection>
            </MainFlex>
            {/* </PreferenceBlock> */}
          </BreadcrumbArea>
        </form>
      </MainWrapper>
    </>
  );
};

export default MainContent;
