import React, { useEffect, useState } from 'react';
import { MainWrapper } from './../storeComp/mainContent/styles';
import { BreadcrumbArea } from '../orderComp/mainContent/orderContent/styles';
import { SearchPos } from 'app/components/transactions/style';
import {
  AccountNoCenter,
  AmountInput,
  SmallInputWrap,
  SplitUserMainWrap,
  SplitUsersWrap,
  SplitAvatar,
  AccountToolTip,
  PercentageInput,
  ProgressBar,
  ProgressLine,
} from './style';
import { ButtonWrap } from '../storeComp/mainContent/sectionOne/styles';
import * as Icon from 'react-feather';
import { MainFlex, PairSection } from './../invoice/mainInvoice/singleStyle';
import { BlueText } from '../emptyState/style';
import { numOnly } from 'utils/numOnly';
import AddCollaborator from './createCollaborator';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Alert from '../dashboard/mainContent/fundWallet/alert';
import PopUpModal from '../merch/popupModal';
import { FixedWrap } from 'app/pages/Onboarding/style';
import MerchroImg from '../../../img/merchro-logo.png';
import { LogoImage } from 'app/pages/Onboarding';
import { Link } from 'react-router-dom';
import FragmentHeader from '../storeComp/mainContent/header/fragmentHeader';
import CloseWarning from './closeWarning';
import CollabDeleteWarning from './collaboratorDeleteWarning';
import EditCollaborator from './editCollaborator';
import { Disclaimer } from './style';
import { NumericFormat } from 'react-number-format';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import SelectCollaborator from './selectCollaborator';
import CollaboratorSettings from './collaboratorSetting';
import RevenueSettings from './revenueShare';
import CommaNumber from 'comma-number';
import { getSign } from 'utils/getCurrencySign';
import { getRequest } from 'utils/request';

const MainContent = () => {
  const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
  const Authorization = `Bearer ${loggedUser.tokens.access.token}`;

  const activeSplitIndex = JSON.parse(localStorage.getItem('deixn') || '{}');
  const navigate = useNavigate();
  const [openAdd, setOpenAdd] = useState(false);
  const [type, setType] = useState('');
  const [activeCollaborator, setActiveCollaborator] = useState(-1);
  const [selectedCollaborator, setSelectedCollaborator] = useState<any>({});
  const [openCollaboratorSettings, setOpenCollaboratorSettings] =
    useState(false);
  const [openRevenueSetting, setOpenRevenueSetting] = useState(false);
  const [newCollabEmail, setNewCollabEmail] = useState('');
  const [newCollabRole, setNewCollabRole] = useState('');
  const accountInfo = JSON.parse(localStorage.getItem('accountInfo') || '{}');
  const [percentageError, setPercentageError] = useState('');
  const [activeItem, setActiveItem] = useState(-1);
  const [onProcess, setOnProcess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const currency = 'NGN';

  // Get All collaborators
  const [savedCollabs, setSavedCollabs] = useState<any>([]);
  const [collabsModified, setCollabsModified] = useState<any>([]);

  useEffect(() => {
    const loggedUser = JSON.parse(localStorage.getItem('logged_user') || '{}');
    const Authorization = `Bearer ${loggedUser.tokens.access.token}`;
    axios
      .get(`${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators`, {
        headers: { Authorization },
      })
      .then(res => {
        setSavedCollabs(res.data);
        setCollabsModified(res.data);
      })
      .catch(err => {});
  }, []);

  // Save Collaborator
  const pushCollaborator = e => {
    const mockData = [...finalCollaborators];
    if (finalCollaborators.length === 0) {
      mockData.push({
        ...e,
        canView: false,
        percentage: 0,
        mode: 0,
        role: [],
      });
    } else {
      for (let i = 0; i < finalCollaborators.length; i++) {
        if (finalCollaborators[i].email !== e.email) {
          mockData.push({
            ...e,
            canView: false,
            percentage: 0,
            mode: 0,
            role: [],
          });
          break;
        }
      }
    }
    setFinalCollaborators(mockData);
  };

  useEffect(() => {
    getRequest(`splits/collaborators?sortBy=createdAt:desc&paginate=false`)
      .then(res => {
        // setAllCollaborators(res);
      })
      .catch(err => {});
  }, []);

  const [finalCollaborators, setFinalCollaborators] = useState<any>([]);
  // const [allCollaborators, setAllCollaborators] = useState<any>('');

  useEffect(() => {
    const splitData = JSON.parse(localStorage.getItem('split') || '{}');
    const collaborators = splitData.collaborators;
    // setType(splitData.splitCategory);
    let mockData: Array<object> = [...collaborators];
    if (collaborators && collaborators.length > 0) {
      for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].collaboratorId.id === loggedUser.user.id) {
          mockData.unshift({
            name: accountInfo.accountInfo.accountName,
            email: loggedUser.user.email,
            id: accountInfo.user,
            accountInfo: {
              accountName: accountInfo.accountInfo.accountName,
              accountNumber: accountInfo.accountInfo.accountNumber,
              bankName: accountInfo.accountInfo.bankName,
            },
            percentage: splitData.collaborators[0].percentage,
          });
        }
      }
    }
    // setFinalCollaborators(mockData);
  }, [accountInfo, loggedUser]);

  // Percentage Functions
  const addPrecentage = (e, index) => {
    finalCollaborators[index].percentage = Number(e.target.value);
    setFinalCollaborators([...finalCollaborators]);
  };

  const addFrequency = (e, index) => {
    finalCollaborators[index].frequency = e.target.value;
    setFinalCollaborators([...finalCollaborators]);
  };

  // time

  const addTime = (e, index) => {
    finalCollaborators[index].time = e.target.value;
    setFinalCollaborators([...finalCollaborators]);
  };

  // add payoutdate
  const addStartDate = (e, index) => {
    if (e.target.value) {
      finalCollaborators[index].startDate = e.target.value;
    } else {
      delete finalCollaborators[index].startDate;
    }
    setFinalCollaborators([...finalCollaborators]);
  };

  // add Week
  const addCycleEnd = (e, index) => {
    finalCollaborators[index].cycleEnd = e.target.value;
    setFinalCollaborators([...finalCollaborators]);
  };

  const addpaymentDue = (e, index) => {
    finalCollaborators[index].paymentDue = Number(e.target.value);
    setFinalCollaborators([...finalCollaborators]);
  };

  const addPayoutDate = (e, index) => {
    finalCollaborators[index].payoutDate = Number(e.target.value);
    setFinalCollaborators([...finalCollaborators]);
  };

  const addWeek = (e, index) => {
    finalCollaborators[index].week = Number(e.target.value);
    setFinalCollaborators([...finalCollaborators]);
  };

  // Check Maximum Percentage
  useEffect(() => {
    const percentages: any = [];

    for (let i = 0; i < finalCollaborators.length; i++) {
      percentages.push(finalCollaborators[i].percentage);
    }
    let totalPercent: number = Number(sum(percentages));

    if (totalPercent <= 100) {
      setPercentageError('');
    } else {
      setPercentageError('Percentage cannot be greater than 100');
      setTimeout(() => {
        setPercentageError('');
      }, 4000);
    }
  }, [finalCollaborators]);

  // Add canView
  const checkCanView = (e, index) => {
    finalCollaborators[index].canView = e.target.checked;
    setFinalCollaborators([...finalCollaborators]);
  };

  // Cap Function
  const addCapAmount = (e, index) => {
    finalCollaborators[index].cap = Number(e.target.value);
    setFinalCollaborators([...finalCollaborators]);
  };

  // Priority Function
  const addPriorityAmount = (e, index) => {
    finalCollaborators[index].amount = Number(e);
    setFinalCollaborators([...finalCollaborators]);
  };

  // Add Role
  const addRole = (item, index) => {
    let mockData = [...finalCollaborators];
    if (mockData && !mockData[index]['role'].includes(item)) {
      mockData[index]['role'].push(item);
      setFinalCollaborators(mockData);
      setSelectedCollaborator(mockData[index]);
    }
  };

  // Add Contract
  const addContract = (item, itemName, index) => {
    let mockData = [...finalCollaborators];
    if (mockData) {
      mockData[index]['contract'] = item;
      mockData[index]['contractFileName'] = itemName;
      setFinalCollaborators(mockData);
      setSelectedCollaborator(mockData[index]);
    }
  };

  // Remove Contract
  const deleteContract = index => {
    let mockData = [...finalCollaborators];
    if (mockData) {
      mockData[index]['contract'] = undefined;
      mockData[index]['contractFileName'] = undefined;
      setFinalCollaborators(mockData);
      setSelectedCollaborator(mockData[index]);
    }
  };

  // Add Role
  const deleteRole = (itemIndex, index) => {
    let mockData = [...finalCollaborators];
    if (mockData[index].role) {
      mockData[index].role.splice(itemIndex, 1);
    }
    setFinalCollaborators(mockData);
    setSelectedCollaborator(mockData[index]);
  };

  // Delete Collaborator
  const deleteCollaborator = (item, index) => {
    if (item.canNegotiate) {
      let mockData: Array<object> = [];
      if (index > 0) {
        for (let i = 0; i < finalCollaborators.length; i++) {
          if (i !== index) {
            mockData.push(finalCollaborators[i]);
          }
        }
        setFinalCollaborators(mockData);
      }
    } else {
      setPercentageError('Sorry, you cannot delete this recipient!');
      setTimeout(() => {
        setPercentageError('');
      }, 4000);
    }
  };

  function sum(input) {
    if (toString.call(input) !== '[object Array]') return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }

  // Check Percentage
  const checkPercent = e => {
    e.preventDefault();
    let mockData: any = [];

    for (let i = 0; i < finalCollaborators.length; i++) {
      mockData.push(finalCollaborators[i].percentage);
    }
    let totalPercent: number = Number(sum(mockData));
    if (totalPercent <= 100) {
      toFinal();
    } else {
      setError('Percentage cannot be greater than 100');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  // To FInal Split Creation
  const toFinal = () => {
    if (finalCollaborators.length > 0) {
      localStorage.setItem(
        'editcollabData',
        JSON.stringify(finalCollaborators),
      );
      if (type === 'music') {
        navigate('/payout-settings?type=music&mode=edit');
      } else if (type === 'general') {
        navigate('/payout-settings?type=project&mode=edit');
      } else {
        navigate('/edit-split?type=basic');
      }
    } else {
      setError('The minimum collaborators required for a split is one!');
      setTimeout(() => {
        setError('');
      }, 4000);
    }
  };

  const closeModal = () => {
    setError('');
    setPercentageError('');
  };

  const [isInputFocus, setIsInputFocus] = useState(false);

  const handleInputFocus = () => {
    setIsInputFocus(true);
  };

  const handleSearch: any = e => {
    let new_item: any = [];
    if (savedCollabs !== '' && savedCollabs.length !== 0) {
      for (let i = 0; i < savedCollabs.length; i++) {
        if (
          savedCollabs[i].name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          savedCollabs[i].email
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          new_item.push(savedCollabs[i]);
        } else if (e.target.value === '') {
          setCollabsModified(savedCollabs);
        }
      }
      setCollabsModified(new_item);
    }
  };

  const setAfterCreate = item => {
    getRequest(`splits/collaborators?paginate=false`)
      .then(res => {
        setSavedCollabs(res);
        setCollabsModified(res);
        setFinalCollaborators(data =>
          data.concat({
            ...item,
            percentage: 0,
            canView: false,
            mode: 0,
            role: [],
          }),
        );
      })
      .catch(err => {});
  };

  const setAfterEdit = item => {
    getRequest(`splits/collaborators?paginate=false`)
      .then(res => {
        setSavedCollabs(res);
        setCollabsModified(res);
      })
      .catch(err => {});
  };

  const [closePageWarning, setClosePageWarning] = useState(false);

  const closeAction = () => {
    navigate('/split-payment');
    localStorage.removeItem('collabData');
  };

  // Editing and Deleting of Recipient

  const [selectedRecipient, setSelectedRecipient] = useState<any>({});
  const [showDeleteCollabWarning, setShowDeleteCollabWarning] = useState(false);
  const [deleteInProcess, setDeleteInProcess] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleDeleteRecipient = () => {
    // Delete Process
    setDeleteInProcess(true);
    setTimeout(() => {
      setDeleteInProcess(false);
    }, 15000);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborators/${selectedRecipient.id}`,
        { headers: { Authorization } },
      )
      .then(res => {
        setShowDeleteCollabWarning(false);
        getRequest(`splits/collaborators?paginate=false`)
          .then(res => {
            setSavedCollabs(res);
            setCollabsModified(res);
            setDeleteInProcess(false);
          })
          .catch(err => {});
        setTimeout(() => {}, 4000);
      })
      .catch(err => {
        setDeleteInProcess(false);
      });
  };

  // User Authentication
  const headers = {
    headers: {
      Authorization: `Bearer ${loggedUser.tokens.access.token}`,
    },
  };

  const patchCollaborator = item => {
    if (!item.locked) {
      setOnProcess(true);
      axios
        .patch(
          `${process.env.REACT_APP_BASE_ENDPOINT}/splits/collaborations/${item.collaborationId}`,
          {
            amount: item.amount,
            percentage: item.percentage,
            canView: item.canView,
            frequency: item.frequency,
            time: item.time,
            payoutDate: item.payoutDate,
          },
          headers,
        )
        .then(res => {
          setOnProcess(false);
          setSuccess(true);
          setOpenRevenueSetting(false);
          setTimeout(() => {
            setSuccess(false);
          }, 4000);
        })
        .catch(err => {
          setError(err.response.data.message);
          setOnProcess(false);
          setTimeout(() => {
            setError('');
          }, 4000);
        });
    }
  };

  return (
    <>
      {activeSplitIndex === 0 ? (
        <>
          {showEditModal ? (
            <EditCollaborator
              closeFunc={() => setShowEditModal(false)}
              item={selectedRecipient}
              setFunc={setAfterEdit}
            />
          ) : null}
          {showDeleteCollabWarning ? (
            <CollabDeleteWarning
              closeFunc={() => setShowDeleteCollabWarning(false)}
              handleAction={handleDeleteRecipient}
              item={selectedRecipient}
              onProcess={deleteInProcess}
            />
          ) : null}
          {openAdd ? (
            <AddCollaborator
              setFunc={setAfterCreate}
              closeFunc={() => setOpenAdd(false)}
            />
          ) : null}
          {success ? (
            <PopUpModal
              handleClose={() => setSuccess(false)}
              msg="Modified successfully!"
              extraMsg="You have successfully modified this collaborator's revenue"
              icon="fas fa-check"
              iconColor="green"
            />
          ) : null}
          {closePageWarning ? (
            <CloseWarning
              handleAction={closeAction}
              closeFunc={() => setClosePageWarning(false)}
            />
          ) : null}

          <MainWrapper
            className="no-scrollbar"
            style={{ width: '100%', background: '#D9D9D933' }}
            left="0"
            top="4.5rem"
            onClick={() => {
              setIsInputFocus(false);
              setOpenCollaboratorSettings(false);
            }}
          >
            <FragmentHeader
              showBack={true}
              pageName={`Update Split`}
              setCloseWarning={() => setClosePageWarning(true)}
            />
            <form
              onSubmit={e => checkPercent(e)}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
            >
              <SearchPos position="fixed">
                <ButtonWrap mobileHide={false}>
                  {/* <CustomButtonDark type="submit">
                <ButtonFlex>Setup Split</ButtonFlex>
              </CustomButtonDark> */}
                </ButtonWrap>
              </SearchPos>
              <BreadcrumbArea
                style={{
                  margin: '4rem 0 10rem 0',
                }}
              >
                <ProgressBar>
                  <ProgressLine bg="#191919"></ProgressLine>
                  <ProgressLine></ProgressLine>
                </ProgressBar>
                <AccountNoCenter>
                  <div>
                    <h3>Update Recipients</h3>
                    <p>
                      Invite co-owners and rights holders to join this split
                    </p>
                  </div>
                </AccountNoCenter>
                <MainFlex>
                  <PairSection width="40%" mobileMargin="0">
                    <div
                      style={{
                        boxShadow: 'none',
                        background: 'none',
                      }}
                    >
                      {finalCollaborators && finalCollaborators.length > 0
                        ? finalCollaborators.map((item, index) => (
                            <SplitUserMainWrap
                              key={index}
                              onClick={e => {
                                e.stopPropagation();
                                setActiveCollaborator(index);
                                setOpenRevenueSetting(true);
                                setSelectedCollaborator(item);
                              }}
                              style={
                                openCollaboratorSettings &&
                                activeCollaborator === index
                                  ? {
                                      border: '1px solid #8975F3',
                                    }
                                  : {}
                              }
                            >
                              {activeItem === index ? (
                                <AccountToolTip>
                                  <p>
                                    {item.accountInfo
                                      ? `${item.accountInfo.accountNumber}`
                                      : ''}
                                    <span>
                                      <br />
                                      {`        ${
                                        item.accountInfo
                                          ? item.accountInfo.bankName
                                          : ''
                                      }`}
                                    </span>
                                  </p>
                                </AccountToolTip>
                              ) : null}
                              <SplitUsersWrap alignVertical="center">
                                {finalCollaborators.length > 1 ? (
                                  <section
                                    style={{
                                      width: '1.3rem',
                                      margin: 'em 0 0 0',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    {index > 0 ? (
                                      <Icon.X
                                        onClick={e => {
                                          e.stopPropagation();
                                          deleteCollaborator(item, index);
                                        }}
                                        size={15}
                                        color="#757575"
                                      />
                                    ) : null}
                                  </section>
                                ) : null}
                                <div
                                  onMouseOver={() => {
                                    setActiveItem(index);
                                  }}
                                  onMouseLeave={() => setActiveItem(-1)}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '5px',
                                    }}
                                  >
                                    <SplitAvatar>
                                      {item.name ? item.name[0] : ''}
                                      {!item.name && item.email
                                        ? item.email[0]
                                        : ''}
                                    </SplitAvatar>
                                    <h3>
                                      {item.name}{' '}
                                      {!item.name && item.email
                                        ? item.email
                                        : ''}
                                      <span>
                                        {index === 0 ? '. Owner' : ''}
                                      </span>
                                    </h3>
                                  </div>
                                </div>
                                <SmallInputWrap>
                                  <div>
                                    <div className="hide-amount-input">
                                      <NumericFormat
                                        value={item.amount}
                                        thousandSeparator={true}
                                        onValueChange={e =>
                                          addPriorityAmount(
                                            e.formattedValue,
                                            index,
                                          )
                                        }
                                      />
                                    </div>
                                    {item.priorExist && type !== 'music' ? (
                                      <AmountInput>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row-reverse',
                                          }}
                                        >
                                          <p>
                                            {getSign(currency)}
                                            {CommaNumber(item.amount || '0')}
                                          </p>
                                        </div>
                                        {/* <label>Amount (NGN)</label> */}
                                      </AmountInput>
                                    ) : (
                                      <PercentageInput>
                                        <p
                                          onClick={e => {
                                            e.stopPropagation();
                                            setOpenRevenueSetting(true);
                                            setActiveCollaborator(index);
                                            setSelectedCollaborator(item);
                                          }}
                                        >
                                          {item.percentage}%
                                        </p>
                                      </PercentageInput>
                                    )}
                                  </div>
                                </SmallInputWrap>
                                {openRevenueSetting ? (
                                  <RevenueSettings
                                    item={selectedCollaborator}
                                    index={activeCollaborator}
                                    addPriorityAmount={addPriorityAmount}
                                    addPercentage={addPrecentage}
                                    mode="edit"
                                    patchCollaborator={patchCollaborator}
                                    onProcess={onProcess}
                                    type={type}
                                    closeFunc={() => {
                                      setOpenRevenueSetting(false);
                                      setActiveCollaborator(-1);
                                    }}
                                    addRole={addRole}
                                    deleteRole={deleteRole}
                                    splitCollaborators={finalCollaborators}
                                    addContract={addContract}
                                    deleteContract={deleteContract}
                                    addWeek={e =>
                                      addWeek(e, activeCollaborator)
                                    }
                                    addFrequency={e =>
                                      addFrequency(e, activeCollaborator)
                                    }
                                    addStartDate={e =>
                                      addStartDate(e, activeCollaborator)
                                    }
                                    addCycleEnd={e =>
                                      addCycleEnd(e, activeCollaborator)
                                    }
                                    addPaymentDue={e =>
                                      addpaymentDue(e, activeCollaborator)
                                    }
                                    addPayoutDate={addPayoutDate}
                                    addCurency={undefined}
                                    addTime={e =>
                                      addTime(e, activeCollaborator)
                                    }
                                  />
                                ) : null}
                                {openCollaboratorSettings ? (
                                  <CollaboratorSettings
                                    item={selectedCollaborator}
                                    checkCanView={checkCanView}
                                    index={activeCollaborator}
                                    email={newCollabEmail}
                                    accountDetails={''}
                                    setEmail={setNewCollabEmail}
                                    role={newCollabRole}
                                    setRole={setNewCollabRole}
                                    createOnProcess={false}
                                    handleCreate={() => {}}
                                    openCreate={() => setIsInputFocus(true)}
                                    closeFunc={() => {
                                      setOpenCollaboratorSettings(false);
                                      setActiveCollaborator(-1);
                                    }}
                                  />
                                ) : null}
                              </SplitUsersWrap>
                              {item.capExist && !item.priorExist ? (
                                <SplitUsersWrap>
                                  <div></div>
                                  <SmallInputWrap>
                                    <div>
                                      <AmountInput>
                                        <div>
                                          <span
                                            style={{
                                              color: '#ababab',
                                            }}
                                          >
                                            &#8358;
                                          </span>
                                          <input
                                            required
                                            type="number"
                                            onKeyPress={e => numOnly(e)}
                                            onChange={e =>
                                              addCapAmount(e, index)
                                            }
                                          />
                                        </div>
                                        <label>Earning Cap</label>
                                      </AmountInput>
                                    </div>
                                  </SmallInputWrap>
                                </SplitUsersWrap>
                              ) : null}
                            </SplitUserMainWrap>
                          ))
                        : null}
                      {isInputFocus ? (
                        <SelectCollaborator
                          handleInputFocus={handleInputFocus}
                          setOpenAdd={setOpenAdd}
                          handleSearch={handleSearch}
                          collabsModified={collabsModified}
                          setShowEditModal={setShowEditModal}
                          setSelectedRecipient={setSelectedRecipient}
                          setShowDeleteCollabWarning={
                            setShowDeleteCollabWarning
                          }
                          pushCollaborator={pushCollaborator}
                          closeFunc={() => setIsInputFocus(false)}
                          openCreate={setOpenCollaboratorSettings}
                          addCollabToWait={() => {}}
                        />
                      ) : null}
                      <BlueText
                        style={{
                          alignItems: 'center',
                          fontSize: '13px',
                          cursor: 'pointer',
                          margin: '1rem 0',
                          display: 'none',
                        }}
                        onClick={e => {
                          setIsInputFocus(false);
                          e.stopPropagation();
                        }}
                      >
                        <Icon.Plus
                          size={20}
                          style={{ margin: '0 0.5rem 0 0', display: 'inline' }}
                        />
                        Add another collaborator
                      </BlueText>
                      <BigBtn
                        style={{
                          width: '100%',
                        }}
                      >
                        <button>Continue</button>
                      </BigBtn>
                    </div>

                    {finalCollaborators && finalCollaborators.length > 0 ? (
                      <>
                        {finalCollaborators.slice(0, 1).map((item, index) =>
                          item.mode === 1 ? (
                            <Disclaimer
                              width="16rem"
                              key={index}
                              style={{
                                margin: '0 auto',
                                justifyContent: 'center',
                              }}
                            >
                              <Icon.Info />
                              <div
                                style={{
                                  width: '100%',
                                }}
                              >
                                <p>"Fixed amount are usually paid out first"</p>
                              </div>
                            </Disclaimer>
                          ) : null,
                        )}
                      </>
                    ) : null}
                  </PairSection>
                </MainFlex>
              </BreadcrumbArea>
            </form>
          </MainWrapper>

          {error ? (
            <Alert
              closeFunc={closeModal}
              img="/failed.png"
              message="An error occurred!!"
              miniMessage={error}
            />
          ) : null}

          {percentageError ? (
            <PopUpModal
              handleClose={closeModal}
              msg="An Error occured!"
              extraMsg={percentageError}
              icon="fas fa-exclamation"
              iconColor="red"
            />
          ) : null}
        </>
      ) : (
        <FixedWrap>
          <Link to="/">
            <LogoImage src={MerchroImg} alt="logo" />
          </Link>
          <div>
            <img src="/failed.png" alt="Unauthorized" />
            <h3>No Access</h3>
            <p>Sorry, you don't have access to this page.</p>
            <Link to="/split-payment">
              <button
                style={{
                  display: 'block',
                  margin: '2rem auto',
                  padding: '10px 50px',
                  background: '#c82b38',
                  color: '#fff',
                  fontWeight: '600',
                  cursor: 'pointer',
                  borderRadius: '10px',
                }}
              >
                Go Back
              </button>
            </Link>
          </div>
        </FixedWrap>
      )}
    </>
  );
};

export default MainContent;
