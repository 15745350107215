import styled from 'styled-components';

interface ModalProps {
  bg?: string;
}

export const ModalArea = styled.div<ModalProps>`
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 1028px) {
    align-items: flex-start;
    background: #ffffff;
    overflow: auto;
  }

  ::before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url('${p => p.bg || ''}');
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(100px);
    -webkit-filter: blur(100px);

    @media (max-width: 728px) {
      filter: blur(30px);
      -webkit-filter: blur(30px);
      background-image: url('${p => p.bg || ''}');
    }
  }
`;

export const AlertArea = styled.div`
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000000000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 1028px) {
    background: #ffffff;
    overflow: auto;
  }
`;

export const ModalChild = styled.div`
  width: auto;
  max-height: 90%;
  padding: 2% 3%;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  overflow-x: hidden;
  animation-duration: 1s;
  aniamtion-name: fadeIn;

  > form {
    > div {
      max-height: 50vh;
      overflow-y: auto;
    }
  }

  @media (max-width: 1028px) {
    width: 100%;
    z-index: 10000000000000;
    margin: 0 auto;
    padding: 2rem 7%;
    position: fixed;
    left: 0;
    top: 0;
    border: none;
    border-radius: 0;
    height: 100%;
    box-shadow: none;
    max-height: 100%;

    > form {
      > div {
        max-height: 70vh;
        overflow-y: auto;
      }
    }
  }
`;

export const CloseBtn = styled.i`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: #676879;

  @media (max-width: 728px) {
    top: 1rem;
    font-size: 1.5rem;
    right: 1rem;
  }
`;

export const Header = styled.h1`
  margin: 0 0 2rem 0;
  text-align: center;
  position: relative;
  display: flex;
  align-items: Center;
  gap: 5px;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.02em;
  color: #161616;
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: Center;
  justify-content: flex-start;
  margin: 2rem 0;
`;
interface Props {
  customBd: any;
}

export const SubHeaderChild = styled.h3<Props>`
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0 2rem 0 0;
  padding: 1% 0;
  cursor: pointer;
  border-bottom: 2px solid ${p => p.customBd || '#e6e9ef'};
`;

interface InputWrapProps {
  width?: string;
}

export const InputWrap = styled.div<InputWrapProps>`
  width: ${p => p.width || '20rem'};
  margin: 0.5rem 0;
  position: relative;

  > h4 {
    position: absolute;
    top: 2.4rem;
    left: 5%;

    @media (max-width: 728px) {
      top: 2.4rem;
    }
  }

  @media (max-width: 1028px) {
    width: 100%;
  }

  label {
    font-size: 13px;
    font-weight: 500;
    color: #474747;
  }

  > input {
    width: 100%;
    padding: 0.5rem 5%;
    font-size: 14px;
    margin-top: 0.4rem;
    margin-bottom: 0.2rem;
    background: #ffffff;
    border: 0.5px solid #dadada;
    border-radius: 5px;

    @media (max-width: 1028px) {
      padding: 0.6rem 5%;
      font-size: 16px;
    }
  }

  select {
    width: 100%;
    border-radius: 4px;
    padding: 0.5rem 5%;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem;
    // border: 1px solid #c5c7d0;
    box-shadow: 0 0 3px #cbced1;

    @media (max-width: 1028px) {
      padding: 0.6rem 5%;
      font-size: 16px;
    }
  }
`;

export const ActionBtn = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  button {
    width: 40%;
    background: #191919;
    border-radius: 4px;
    color: #ffffff;
    padding: 0.5rem;
    margin: 2rem 0 0 0;

    :hover {
      box-shadow: 0 0 2px #191919;
    }
  }
`;

export const SmallBtn = styled.section`
  display: flex;
  justify-content: center;
  cursor: pointer;

  > button {
    background: #191919;
    border-radius: 4px;
    color: #ffffff;
    padding: 0.5rem 2rem;
    margin: 2rem 0 0 0;
  }
`;

export const BigBtn = styled.section<InputWrapProps>`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  width: ${p => p.width || '20rem'};

  > button {
    width: 100%;
    background: #191919;
    border-radius: 16px;
    color: #ffffff;
    padding: 0.7rem 5%;
    margin: 2rem 0 0 0;
  }

  @media (max-width: 728px) {
    width: 100%;
  }
`;

export const Flexed = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1028px) {
    flex-direction: column;
  }
`;

export const LeftPart = styled.div`
  width: 15rem;
  margin: 0.5rem 5% 0 0;

  @media (max-width: 1028px) {
    width: 100%;
  }
`;
export const RightPart = styled.div`
  width: 22rem;
  margin: 0.5rem 5% 0 0;

  @media (max-width: 1028px) {
    width: 100%;
  }
`;

export const InfoText = styled.div`
  width: 100%;
  line-height: 1.3rem;
  font-size: 0.8rem;
  color: #ababab;
  display: flex;
  align-items: flex-start;
`;

export const AlignedFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    font-size: 0.8rem;
    color: #191919;
  }

  span {
    color: #353535;
    font-size: 10px;
  }
`;

interface PropsArgs {
  customBg: any;
}

export const Navigation = styled.h3<PropsArgs>`
  padding: 6% 10%;
  background: ${p => p.customBg};
  font-size: 0.9rem;
  cursor: pointer;
`;

interface BankDetailsProps {
  width?: any;
  margin?: any;
  mobileWidth?: any;
}

export const BankDetailsWrap = styled.div<BankDetailsProps>`
  width: ${p => p.width || '100%'};
  margin: ${p => p.margin || '1rem 0'};

  > p {
    font-size: 12px;
    color: #474747;
    font-weight: 400;
  }

  h3 {
    font-size: 0.9rem;
    color: #161616;
    font-weight: 500;
  }

  @media (max-width: 728px) {
    width: ${p => p.mobileWidth || '100%'};
  }
`;

export const CopyBtn = styled.p`
  background: #f4c3cb;
  padding: 1% 3%;
  font-size: 0.8rem;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 1028px) {
    width: 20%;
    padding: 3%;
    margin: 1rem 0;
  }
`;

export const BasicCard = styled.div`
  padding: 2% 4%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border: 0.5px solid rgba(0, 0, 0, 0.02);
  margin: 2rem 0;
  width: 20rem;
  cursor: pointer;
`;

export const OptionImg = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  background: #191919;

  img {
    width: 2rem;
    height: 23rem;
    border-radius: 50%;
  }

  i,
  p {
    color: white;
  }
`;

export const ServiceFlex = styled.div`
  display: Flex;
  flex-wrap: wrap;
`;

export const Service = styled.div`
  width: 23%;
  margin: 1rem 2% 1rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  text-align: center;

  > img {
    height: 2rem;
    object-fit: contain;
  }

  > h3 {
    font-size: 0.8rem;
    font-weight: 700;
  }

  > p {
    font-size: 0.7rem;
    color: #474747;
  }
`;

export const Center = styled.div`
  width: 25rem;
  height: 100%;
  padding: 4% 5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: Center;
  text-align: center;

  @media (max-width: 1028px) {
    width: 100%;
    padding: 4% 2%;
  }

  img {
    width: 5rem;
    height: 5rem;
  }

  h3 {
    font-weight: bolder;
    margin: 2rem 0 0 0;
  }

  p {
    color: #757575;
    font-size: 0.8rem;
  }
`;

export const UserCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: Center;
  border-bottom: 1px solid #dadada;
  padding: 2% 0;
  cursor: pointer;
`;

export const UserInfo = styled.div`
  width: 90%;
  display: flex;
  align-items: Center;

  h3 {
    font-size: 0.9rem;
    font-weight: 500;
  }
  p {
    font-size: 0.8rem;
    color: #757575;
  }
`;

export const UserAvatarWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: Center;
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 5% 0 0;
  border-radius: 4px;
  color: white;
  font-weight: bold;
  background: #757575;
`;

export const BackNavigate = styled.i`
  margin: 0 1rem 0 0;
`;

export const SwipeFund = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: -1rem;

  p {
    cursor: pointer;
  }
`;

export const BankDetails = styled.div`
  margin-top: 1rem;
`;
