import axios from 'axios';

const FileToUrl = async ({ file, token }): Promise<any> => {
  try {
    const result = await axios.post(
      `https://merchro-api.herokuapp.com/v1/file-upload`,
      {
        files: [file],
      },
      {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      },
    );

    const data = result.data[0].secure_url;
    return data;
  } catch (e: any) {}
};

export default FileToUrl;
