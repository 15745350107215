import React, { useState } from 'react';
import {
  ActionHeader,
  CollabSuggestWrap,
  FixedRightBottom,
  InputActPlacehold,
  SplitAvatar,
  SplitUsersWrap,
} from '../split/style';
import { FlexApart } from '../invoice/mainInvoice/preview-style';
import { InputWrap } from '../split/style';
import * as Icon from 'react-feather';
import { TicketInput } from '../paymentLink/style';
import {
  MobileInput,
  MobileInputWrap,
  MobileSelect,
} from 'app/pages/SignUp/styles';
import ReactCountryFlag from 'react-country-flag';
import { BigBtn } from '../dashboard/mainContent/fundWallet/style';
import { Line } from '../transactions/style';
import { RootState } from 'store/configureStore';
import { useSelector } from 'react-redux';

interface Benefit {
  endDate: boolean;
  name: string;
  description: string;
  avatar: string;
  moreInfo: string;
  options: OptionsType;
  frequencies: string[];
  id: string;
  interestRate: number;
}

interface OptionsType {
  name: string;
  enum: EnumType[];
}

interface EnumType {
  name: string;
  amount: number;
  frequency: string;
  id: string;
}

const BenefitsRevenue = ({
  closeFunc,
  item,
  setSelectedOption,
  setSelectedAmount,
  setSelectedFrequency,
  setSelectedId,
  addStartDate,
  addEndDate,
}) => {
  const [firstFlag, setFirstFlag] = useState('NG');
  const [firstCurrency, setFirstCurrency] = useState('NGN');
  const [isInputFocus, setIsInputFocus] = useState(false);
  const benefit = useSelector(
    (state: RootState) => state.selectedBenefit,
  ) as unknown as Benefit;

  let selectedFrequency = '';
  if (benefit && benefit.options && item.option) {
    const selectedBenefit = benefit.options.enum.find(
      option => option.id === item.option,
    );
    if (selectedBenefit) {
      selectedFrequency = selectedBenefit.frequency;
    }
  }

  let selectedAmount = 0;
  if (benefit && benefit.options && item.option) {
    const selectedBenefit = benefit.options.enum.find(
      option => option.id === item.option,
    );
    if (selectedBenefit) {
      selectedAmount = selectedBenefit.amount;
    }
  }

  const openLinkInNewTab = (url: string) => {
    window.open(url, '_blank');
  };

  const getTodayDate = () => {
    const today = new Date();
    let month = '' + (today.getMonth() + 1);
    let day = '' + today.getDate();
    const year = today.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  };

  return (
    <>
      <FixedRightBottom
        onClick={e => {
          e.stopPropagation();
          closeFunc();
        }}
      >
        <div
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <main>
            <FlexApart
              style={{
                position: 'relative',
                top: '0',
                width: '100%',
                border: 'none',
                padding: '15px 5%',
                justifyContent: 'flex-start',
                gap: '8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SplitAvatar>
                <img
                  src={benefit?.avatar || '/artist.png'}
                  alt="Benefit Avatar"
                />
              </SplitAvatar>
              <h2>
                <b>{benefit?.name || 'Benefit'}</b>
              </h2>
            </FlexApart>
            <Line />
            <div>
              <ActionHeader>
                <i>{/* <Icon.ChevronDown /> */}</i>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <p>{benefit?.description || 'Your benefit for the future'}</p>
                </div>
              </ActionHeader>

              <>
                {benefit &&
                benefit.options &&
                benefit.options.enum &&
                benefit.options.enum[0].frequency ? (
                  <>
                    <TicketInput>
                      <InputWrap>
                        <h4>{benefit.options.name}</h4>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                          }}
                        >
                          <select
                            style={{
                              width: '100%',
                              margin: '0.1rem 0 0 0',
                            }}
                            value={item.option}
                            onChange={e => {
                              setSelectedOption(e.target.value);
                              const selectedOption = benefit.options.enum.find(
                                option => option.id === e.target.value,
                              );
                              if (selectedOption) {
                                setSelectedId(selectedOption.id);
                                setSelectedFrequency(selectedOption.frequency);
                                setSelectedAmount(selectedOption.amount);
                              }
                            }}
                            required
                          >
                            <option>Select Tier</option>
                            {benefit &&
                              benefit.options &&
                              benefit.options.enum.map(option => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        {benefit && benefit.moreInfo ? (
                          <span>
                            Learn More anout your coverage{' '}
                            <a
                              style={{
                                color: 'blue',
                              }}
                              href={benefit.moreInfo}
                              onClick={e => {
                                e.preventDefault();
                                openLinkInNewTab(benefit.moreInfo);
                              }}
                            >
                              here
                            </a>
                          </span>
                        ) : null}
                      </InputWrap>
                    </TicketInput>
                  </>
                ) : null}
              </>

              <>
                <TicketInput>
                  <InputWrap>
                    <h4>Payment Frequency</h4>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      {benefit &&
                      benefit.options &&
                      benefit.options.enum &&
                      benefit.options.enum[0].frequency ? (
                        <>
                          <select
                            value={selectedFrequency}
                            onChange={e => setSelectedFrequency(e.target.value)}
                            style={{
                              width: '100%',
                              margin: '0.1rem 0 0 0',
                            }}
                            disabled
                          >
                            <option value={selectedFrequency}>
                              {selectedFrequency.charAt(0).toUpperCase() +
                                selectedFrequency.slice(1)}
                            </option>
                          </select>
                        </>
                      ) : (
                        <>
                          <select
                            style={{
                              width: '100%',
                              margin: '0.1rem 0 0 0',
                            }}
                            value={item.frequency}
                            onChange={e => setSelectedFrequency(e.target.value)}
                          >
                            <option>Select Payment Frequency</option>
                            {benefit &&
                              benefit.frequencies.map(index => (
                                <option key={index} value={index}>
                                  {index.charAt(0).toUpperCase() +
                                    index.slice(1)}
                                </option>
                              ))}
                          </select>
                        </>
                      )}
                    </div>
                  </InputWrap>
                </TicketInput>
              </>

              <>
                <TicketInput>
                  <h4>Amount</h4>
                  <div
                    style={{
                      paddingBottom: '1px',
                      border: 'none',
                      padding: '1px',
                    }}
                  >
                    <MobileInputWrap>
                      <MobileSelect>
                        <div
                          style={{
                            width: '6rem',
                          }}
                        >
                          <InputActPlacehold
                            onClick={e => {
                              setIsInputFocus(!isInputFocus);
                              e.stopPropagation();
                            }}
                            onBlur={() => setIsInputFocus(false)}
                          >
                            <div>
                              <ReactCountryFlag
                                style={{
                                  fontSize: '1.3rem',
                                  borderRadius: '50%',
                                }}
                                countryCode={firstFlag}
                                svg
                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                cdnSuffix="svg"
                              />
                              <p
                                style={{
                                  color: '#191919',
                                }}
                              >
                                {firstCurrency}
                              </p>
                            </div>
                            <i>
                              <Icon.ChevronDown size={15} />
                            </i>
                          </InputActPlacehold>
                        </div>
                      </MobileSelect>
                      <CollabSuggestWrap
                        style={{
                          margin: '0rem 0 0 0',
                          width: '8rem',
                          zIndex: '1000000000',
                          top: '2rem',
                        }}
                      >
                        {isInputFocus ? (
                          <>
                            <div>
                              {Countries && Countries.length > 0 ? (
                                <>
                                  {Countries.slice(0, 1).map((item, index) => (
                                    <SplitUsersWrap
                                      key={index}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setFirstFlag(item.code);
                                        setFirstCurrency(item.name);
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '100%',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '10px',
                                            width: '8rem',
                                          }}
                                        >
                                          <ReactCountryFlag
                                            style={{
                                              fontSize: '1.3rem',
                                              borderRadius: 'none',
                                            }}
                                            countryCode={item.code}
                                            svg
                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                            cdnSuffix="svg"
                                            title={item.code}
                                          />
                                          <h3
                                            style={{
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap',
                                              width: '25%',
                                            }}
                                          >
                                            {item.name
                                              ? `${item.name}        `
                                              : ''}
                                          </h3>
                                        </div>
                                      </div>
                                    </SplitUsersWrap>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <p
                                    style={{
                                      textAlign: 'center',
                                      fontSize: '14px',
                                      color: '#757575',
                                      margin: '1rem 0 0 0',
                                    }}
                                  >
                                    No Option.
                                  </p>
                                </>
                              )}
                            </div>
                          </>
                        ) : null}
                      </CollabSuggestWrap>
                      <>
                        {benefit &&
                        benefit.options &&
                        benefit.options.enum &&
                        benefit.options.enum[0].frequency ? (
                          <>
                            <MobileInput
                              width="103%"
                              type="text"
                              value={selectedAmount}
                              style={{
                                height: '3rem',
                                paddingLeft: '8rem',
                                border: '0.5px solid #dadada',
                              }}
                              disabled
                            />
                          </>
                        ) : (
                          <>
                            <MobileInput
                              width="103%"
                              type="text"
                              required={true}
                              style={{
                                height: '3rem',
                                paddingLeft: '8rem',
                                border: '0.5px solid #dadada',
                              }}
                              value={item.amount}
                              onChange={e => setSelectedAmount(e.target.value)}
                            />
                          </>
                        )}
                      </>
                    </MobileInputWrap>
                  </div>
                </TicketInput>
              </>

              <>
                <TicketInput>
                  <InputWrap>
                    <h4>Start date</h4>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                      }}
                    >
                      <input
                        type="date"
                        value={item.startDate}
                        onChange={e => addStartDate(e.target.value)}
                        min={getTodayDate()}
                      />
                    </div>
                  </InputWrap>
                </TicketInput>
              </>

              {benefit.endDate ? (
                <>
                  <TicketInput>
                    <InputWrap>
                      <h4>End Date</h4>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <input
                          type="date"
                          value={item.endDate}
                          onChange={e => addEndDate(e.target.value)}
                          min={getTodayDate()}
                        />
                      </div>
                    </InputWrap>
                  </TicketInput>
                </>
              ) : (
                <></>
              )}

              <>
                {benefit?.interestRate ? (
                  <>
                    <p>Interest rate of {benefit.interestRate}% APR</p>
                  </>
                ) : null}
              </>
            </div>
          </main>
          <BigBtn
            style={{
              width: '90%',
              position: 'absolute',
              bottom: '1rem',
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
          >
            <button
              onClick={e => {
                e.stopPropagation();
                closeFunc();
              }}
              disabled={!item.amount || !item.startDate || !item.frequency}
            >
              Save Plan
            </button>
          </BigBtn>
        </div>
      </FixedRightBottom>
    </>
  );
};

export default BenefitsRevenue;

export const Countries = [
  {
    name: 'NGN',
    code: 'NG',
  },
  {
    name: 'USD',
    code: 'US',
  },
  {
    name: 'GBP',
    code: 'GB',
  },
  {
    name: 'EUR',
    code: 'EU',
  },
];
