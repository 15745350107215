import React from 'react';
import styled from 'styled-components';
import * as Icon from 'react-feather';

const ProgressWarning = ({
  handleAction,
  closeFunc,
  title,
  text,
  actionText,
  canceltext,
}) => {
  const clickAction = () => {
    handleAction(true);
  };
  return (
    <ModalArea>
      <ModalChild>
        <CloseBtn onClick={() => closeFunc()}>
          <Icon.X />
        </CloseBtn>
        <div>
          <h3
            style={{
              width: '20rem',
              margin: '2rem 0 0 0',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 700,
            }}
          >
            {title || 'Continue?'}
          </h3>
          <p
            style={{
              width: '20rem',
              margin: '1rem 0 0 0',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
            {text || 'Are you sure you want to proceed with this?'}
          </p>
          <ActionBtn style={{ justifyContent: 'center', gap: '20px' }}>
            <button
              onClick={() => closeFunc()}
              style={{
                width: '9rem',
                background: '#fff',
                color: '#191919',
                border: '1px solid #191919',
              }}
            >
              {canceltext || 'Cancel'}
            </button>
            <button
              onClick={clickAction}
              style={{ width: '9rem', margin: '2rem 0 0 0' }}
            >
              {actionText || 'Continue'}
            </button>
          </ActionBtn>
        </div>
      </ModalChild>
    </ModalArea>
  );
};

export default ProgressWarning;

interface ModalProps {
  bg?: string;
}
export const ModalArea = styled.div<ModalProps>`
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  @media (max-width: 1028px) {
    align-items: flex-start;
    background: #ffffff;
    overflow: auto;
  }

  ::before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background-image: url('${p => p.bg || ''}');
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(100px);
    -webkit-filter: blur(100px);

    @media (max-width: 728px) {
      filter: blur(30px);
      -webkit-filter: blur(30px);
      background-image: url('${p => p.bg || ''}');
    }
  }
`;

export const ModalChild = styled.div`
  width: auto;
  max-height: 90%;
  padding: 2% 3%;
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  overflow-x: hidden;
  animation-duration: 1s;
  aniamtion-name: fadeIn;

  > form {
    > div {
      max-height: 50vh;
      overflow-y: auto;
    }
  }

  @media (max-width: 1028px) {
    width: 100%;
    z-index: 10000000000000;
    margin: 0 auto;
    padding: 2rem 7%;
    position: fixed;
    left: 0;
    top: 0;
    border: none;
    border-radius: 0;
    height: 100%;
    box-shadow: none;
    max-height: 100%;

    > form {
      > div {
        max-height: 70vh;
        overflow-y: auto;
      }
    }
  }
`;

export const CloseBtn = styled.i`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  color: #676879;

  @media (max-width: 728px) {
    top: 1rem;
    font-size: 1.5rem;
    right: 1rem;
  }
`;

export const ActionBtn = styled.section`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  button {
    width: 40%;
    background: #191919;
    border-radius: 4px;
    color: #ffffff;
    padding: 0.5rem;
    margin: 2rem 0 0 0;

    :hover {
      box-shadow: 0 0 2px #191919;
    }
  }
`;
