import styled from 'styled-components';

export const SelectCentralized = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  gap: 50px;

  > h3 {
    letter-spacing: -0.02em;
    color: #191919;
    font-weight: 700;
    font-size: 24px;
  }
  @media (max-width: 728px) {
    height: auto;
  }
`;

export const CardOptionHolder = styled.div`
  width: 100%;
  display: Flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;

  > div {
    width: 25%;
    padding: 2rem 2rem;
    background: #ffffff;
    border: 0.2px solid #dadada;
    border-radius: 8px;
    color: #000000;
    position: relative;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: Center;

    :hover {
      box-shadow: 0 0 3px #cbced1;
    }

    @media (max-width: 728px) {
      width: 100%;
    }
  }
`;

export const TextWrap = styled.div`
  > h4 {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-size: 0.8rem;
    font-weight: 400;
    width: 75%;
    margin: 0 auto;
    margin-top: 1rem;
    text-align: center;
  }
`;

export const ImageHolder = styled.div`
  position: relative;
  margin: 1rem 0;

  > img {
    width: 254px;
  }
`;

interface TicketProps {
  border?: string;
  top?: string;
  hasLabel?: boolean;
  labelPosition?: string;
}

export const TicketInput = styled.section<TicketProps>`
  margin: 0rem 0 0 0;
  position: relative;
  top: ${p => p.top || 0};

  > div {
    width: 20rem;
    padding: 0.3rem 2%;
    background: #ffffff;
    border: ${p => p.border || '0.5px solid #dadada'};
    border-radius: 5px;
    line-height: 15px;
    margin-bottom: 1rem;

    @media (max-width: 728px) {
      width: 100%;
    }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #ababab;
  }

  label {
    position: absolute;
    bottom: 10%;
    font-weight: 200;
    left: ${p => (p.labelPosition === 'right' ? '92%' : '2%')};
  }

  input {
    border: none;
    width: 100%;
    letter-spacing: -0.02em;
    color: #191919;
    padding: ${p => (p.hasLabel ? '0.2rem 0 0.2rem 6%' : '0.2rem 0')};
    font-size: 14px;

    @media (max-width: 728px) {
      font-size: 16px;
    }
  }

  select {
    border: none;
    width: 100%;
    letter-spacing: -0.02em;
    color: #191919;
    padding: ${p => (p.hasLabel ? '0.2rem 0 0.2rem 7%' : '0.2rem 0')};
    font-size: 14px;

    @media (max-width: 728px) {
      font-size: 16px;
    }
  }
`;

export const EventGrid = styled.div`
  display: flex;
  grid-template-rows: auto;
  justify-content: space-between;
  background: #fff;
  padding: 2rem;

  > div {
    width: 50%;
    padding: 0 2.5%;
  }

  > div:nth-child(1) {
    border-right: 0.5px solid #dadada;
  }

  @media (max-width: 728px) {
    padding: 0 0 2rem 0;
    background: #fff;
    display: block;

    > div {
      width: 100%;
      padding: 0;
    }

    > div:nth-child(1) {
      border-right: none;
    }
  }
`;

export const InputLabel = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  padding: 2rem 0 0.4rem 0;
  letter-spacing: -0.01em;
  color: #191919;
`;

export const GenericBtn = styled.div`
  width: 100%;
  padding: 0.7rem 2.5%;
  background: #ffffff;
  border: 0.5px solid #dadada;
  border-radius: 5px;
  color: #757575;
  margin: 0.7rem 0 0 0;
  font-family: 'Satoshi', 'sans-serif';
  font-size: 0.9rem;
  display: flex;
  justify-content: flex-start;
  position: relative;
  cursor: pointer;

  > i {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 1rem;
  }

  :hover {
    opacity: 0.5;
  }

  @media (max-width: 728px) {
    padding: 0.8rem 2.5%;
  }
`;
