import { useState } from 'react';
import { MainWrapper } from '../storeComp/mainContent/styles';
import { BreadcrumbArea } from './../orderComp/mainContent/orderContent/styles';
import { GenericAppHeader, GenericBtn } from './style';
import { PreferenceBlock } from '../Preference/mainContent/preferenceContent/styles';
import PasswordResetModal from './passwordReset';
import BvnCheck from './bvnCheckModal';
import SetPin from './setTransPin';
import * as Icon from 'react-feather';
import AppHeader from '../storeComp/mainContent/header/header';
import { useNavigate } from 'react-router';
import { Constraint } from './../invoice/mainInvoice/style';
import { InputWrap } from '../dashboard/mainContent/fundWallet/style';
import VerifyModal from './codeVerify';

const Security = () => {
  const history = useNavigate();
  const [openPasswordReset, setOpenPasswordReset] = useState<any>(false);
  const [openBvnCheck, setOpenBvnCheck] = useState<any>(false);
  const [pinReset, setOpenPinReset] = useState<any>(false);
  const [mode, setMode] = useState('');
  const [openVerifyCheck, setOpenVerifyCheck] = useState(false);

  function togglePasswordReset() {
    setOpenVerifyCheck(true);
    setMode('password');
  }
  function toggleBvnCheck() {
    setOpenBvnCheck(!openBvnCheck);
  }

  // Open Pin Reset Modal
  const openPinReset = () => {
    setOpenVerifyCheck(true);
    setMode('pin');
  };

  const backNav = () => {
    history(-1);
  };

  const afterSend = () => {
    if (mode === 'password') {
      setOpenPasswordReset(!openPasswordReset);
    } else if (mode === 'pin') {
      setOpenPinReset(!pinReset);
    }
  };

  return (
    <>
      {openVerifyCheck ? (
        <VerifyModal
          closeFunc={() => {
            setOpenVerifyCheck(false);
            setMode('');
          }}
          afterSend={afterSend}
        />
      ) : null}
      <MainWrapper style={{ width: '100%' }} left="0" bg="#fff">
        <AppHeader showBack={true} pageName={`Security`} backLink="/profile" />
        <GenericAppHeader onClick={() => backNav()}>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              gap: '10px',
            }}
          >
            <Icon.ChevronLeft />
            <h3>Security</h3>
          </div>
        </GenericAppHeader>
        <BreadcrumbArea>
          <PreferenceBlock>
            <Constraint
              width="35%"
              top="-4rem"
              style={{
                boxShadow: 'none',
              }}
            >
              <InputWrap style={{ width: '100%' }}>
                <GenericBtn onClick={() => togglePasswordReset()}>
                  <div>
                    <p>Change Password</p>
                    <Icon.ChevronRight />
                  </div>
                </GenericBtn>
              </InputWrap>
              <InputWrap style={{ width: '100%' }}>
                <GenericBtn onClick={() => openPinReset()}>
                  <div>
                    <p>Change Transaction PIN</p>
                    <Icon.ChevronRight />
                  </div>
                </GenericBtn>
              </InputWrap>
            </Constraint>
          </PreferenceBlock>
        </BreadcrumbArea>

        {/* Modals */}
        {openPasswordReset ? (
          <PasswordResetModal closeFunc={() => setOpenPasswordReset(false)} />
        ) : null}
        {openBvnCheck ? (
          <BvnCheck closeFunc={toggleBvnCheck} openNext={openPinReset} />
        ) : null}
        {pinReset ? <SetPin closeFunc={() => setOpenPinReset(false)} /> : null}
      </MainWrapper>
    </>
  );
};

export default Security;
